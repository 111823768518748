import getNewFilename from './filename/getNewFilename';

interface Asset {
  title: string;
  file?: {
    file_name: string | null | undefined;
  } | null;
}

const getAssetDownloadName = (asset: Asset): string => {
  const filename = asset.file?.file_name;

  if (filename) {
    return getNewFilename(filename, asset.title);
  }

  return asset.title;
};

export default getAssetDownloadName;
