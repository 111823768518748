import { useDispatch, useSelector } from 'react-redux';

import playerSelector from 'redux/player/playerSelectors';
import {
  closePlayer,
  playerPause,
  playerPlay,
  setActivePlayerAsset,
  setActivePlayerTullyBeat,
  setActiveSharedAsset,
  setPlayerPlayList,
} from 'redux/player/playerActions';
import MediaType from 'common/hooks/usePlayerInstance/enums/mediaType';

interface UsePlayerInstanceRes {
  playingAssetId: number | null;
  playAsset: (assetId: number, mediaType?: MediaType) => void;
  setPlayList: (assetsIds: number[]) => void;
  pauseAsset: () => void;
  hidePlayer: () => void;
}

const usePlayerInstance = (): UsePlayerInstanceRes => {
  const dispatch = useDispatch();

  const { playList, playingAssetId, audio } = useSelector(playerSelector.state);

  const playAsset = (assetId: number, mediaType: MediaType = MediaType.Asset): void => {
    if (!playList.includes(assetId)) setPlayList([assetId]);

    audio.pause();

    switch (mediaType) {
      case MediaType.TullyBeat:
        dispatch(setActivePlayerTullyBeat({ value: assetId }));
        break;
      case MediaType.Shared:
        dispatch(setActiveSharedAsset({ value: assetId }));
        break;
      case MediaType.ImportedBeat:
      case MediaType.Asset:
      default:
        dispatch(setActivePlayerAsset({ value: assetId }));
    }

    dispatch(playerPlay({ value: assetId }));
  };

  const pauseAsset = (): void => {
    dispatch(playerPause());
  };

  const hidePlayer = (): void => {
    dispatch(closePlayer());
  };

  const setPlayList = (playList: number[]) => {
    dispatch(setPlayerPlayList({ value: playList }));
  };

  return {
    pauseAsset,
    playAsset,
    hidePlayer,
    playingAssetId,
    setPlayList,
  };
};

export default usePlayerInstance;
