import ContractsPaths from 'api/contracts/enums/contractsPaths';
import { IContractParty, IUploadedContract } from 'common/api/contractModels';
import { IUploadedContractLocalState } from 'common/components/Modals/contractAgreementFormDialog/contractAgreementFormState';
import POST from 'common/services/httpServices/post';

const postUploadedContract: (
  body: IUploadedContractLocalState
) => Promise<IUploadedContract> = async (body) => {
  const res = await POST(
    ContractsPaths.PostUploadedContracts,
    JSON.stringify({
      title: body.title,
      description: body.description,
      party: body.parties.map(
        (party): Partial<IContractParty> => ({
          partyType: party.role,
          name: party.name,
          email: party.email,
          advance: party.advance,
          royaltyLabel: party.royaltyLabel,
          royalty: party.royalty,
          recoup: party.recoup,
          recoupLabel: party.recoupLabel,
          publishing: party.publishing,
        })
      ),
      file: {
        id: body.file?.id,
      },
      masterId: body.masterId,
      createdAt: body.createdAt,
    } as Partial<IUploadedContract>)
  );

  return await res.json();
};

export default postUploadedContract;
