import initContentPermissions from 'redux/permissions/utils/initContentPermissions';
import ContentPermissions from 'common/interfaces/contentPermissions';

const initGuestPermissions = (): ContentPermissions => {
  return initContentPermissions({
    VIEW: true,
    DOWNLOAD: false,
    UPLOAD: false,
    EDIT: false,
    DELETE: false,
  });
};

export default initGuestPermissions;
