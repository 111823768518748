import { ReactElement } from 'react';

import SetFileBrowserOpenedProps from 'common/components/fileBrowser/interfaces/setFileBrowserOpenedProps';
import ActionPayload from 'common/interfaces/actionPayload';
import actionFn from 'common/utils/actionFn';

export enum FileBrowserActionTypes {
  SetFileBrowserOpened = 'SetFileBrowserOpened',
  SetFileBrowserClosed = 'SetFileBrowserClosed',
  SetFileBrowserContent = 'SetFileBrowserClosed',
}

export interface SetFileBrowserOpenedAction {
  type: FileBrowserActionTypes.SetFileBrowserOpened;
  payload: ActionPayload<SetFileBrowserOpenedProps>;
}

export const setFileBrowserOpened: (
  content?: ReactElement
) => SetFileBrowserOpenedAction = (content): SetFileBrowserOpenedAction => ({
  type: FileBrowserActionTypes.SetFileBrowserOpened,
  payload: {
    value: {
      isOpened: true,
      ...(content ? { content } : {}),
    },
  },
});

export interface SetFileBrowserClosedAction {
  type: FileBrowserActionTypes.SetFileBrowserClosed;
  payload: ActionPayload<false>;
}

export const setFileBrowserClosed = (): SetFileBrowserClosedAction => ({
  type: FileBrowserActionTypes.SetFileBrowserClosed,
  payload: {
    value: false,
  },
});

export interface SetFileBrowserContentAction {
  type: FileBrowserActionTypes.SetFileBrowserContent;
  payload: ActionPayload<ReactElement | null>;
}

export const setFileBrowserContent = actionFn<SetFileBrowserContentAction>(
  FileBrowserActionTypes.SetFileBrowserContent
);

export type FileBrowserActions =
  | SetFileBrowserContentAction
  | SetFileBrowserOpenedAction
  | SetFileBrowserClosedAction;
