import { AssetFieldsFragment } from 'generated/graphql';
import { NO_VALUE } from 'common/api/Constants';
import { ContentType, FolderItem, NoFolderContentType } from 'common/api/models';
import { IGqlContract } from 'common/api/contractModels';
import { DriveUtils } from 'common/api/utils';
import FileBrowserItemType from 'common/enums/fileBrowserItemType';
import DriveItem from 'redux/selectedFile/interfaces/driveItem';
import SharingItem from 'redux/selectedFile/interfaces/sharingItem';
import SelectedFileState from 'redux/selectedFile/types/selectedFileState';
import { CommonActions, CommonActionTypes } from 'redux/commonActions';
import { ISharedAsset } from 'modules/sharing/api/models';
import {
  ViewAssetAction,
  ViewFileActions,
  ViewDriveItemAction,
  SelectedFileTypes,
} from 'redux/selectedFile/selectedFileActions';

const initialState: SelectedFileState = null;

const sharedItemReducer = (action: ViewAssetAction): SharingItem => {
  const { asset, itemType, itemId, typeId, parentTitle = '' } = action.payload;

  if (itemType === FolderItem.MASTER_FILES) {
    const items = (asset.data as ISharedAsset[]).filter(
      (file) =>
        file.type_id === ContentType.MASTER_FILE_CLEAN ||
        file.type_id === ContentType.MASTER_FILE_EXPLICIT
    );

    return {
      __itemname: FileBrowserItemType.Sharing,
      typeId: typeId as NoFolderContentType,
      parentId: (asset.data[0] as ISharedAsset)?.parent_id || NO_VALUE.PARENT_ID,
      parentTitle,
      itemTypeId: itemType,
      id: itemId,
      items: items.sort(DriveUtils.cleanFirst),
    };
  }

  if (itemType === FolderItem.DOCUMENTS) {
    return {
      __itemname: FileBrowserItemType.Sharing,
      typeId: typeId as NoFolderContentType,
      parentId: (asset.data[0] as ISharedAsset)?.parent_id || NO_VALUE.PARENT_ID,
      parentTitle,
      itemTypeId: itemType,
      id: itemId,
      items: asset.data.filter(
        (file) =>
          file.type_id === ContentType.MASTER_DOCUMENT ||
          file.type_id === ContentType.MASTER_CONTRACT
      ),
    };
  }

  if (itemType === FolderItem.META) {
    return {
      __itemname: FileBrowserItemType.Sharing,
      typeId: typeId as NoFolderContentType,
      parentId: (asset.data[0] as { parent_id: number })?.parent_id || NO_VALUE.PARENT_ID,
      parentTitle,
      itemTypeId: itemType,
      id: itemId,
      items: asset.data,
      data: asset?.data?.[0],
    };
  }

  return {
    __itemname: FileBrowserItemType.Sharing,
    typeId: typeId as NoFolderContentType,
    parentId: (asset.data[0] as ISharedAsset)?.parent_id || NO_VALUE.PARENT_ID,
    parentTitle,
    itemTypeId: itemType,
    id: itemId,
    items: asset.data.filter((file) => file.type_id === typeId),
  };
};

const sharedAssetReducer = (state: SelectedFileState, action: ViewAssetAction) => {
  const itemType = action.payload.itemType;
  // Skip items that we don't know how to display
  // on right-hand panel
  if (itemType === FolderItem.BEAT || itemType === FolderItem.CONTRACTS) {
    return state;
  }

  return sharedItemReducer(action);
};

const driveItemReducer = (action: ViewDriveItemAction) => {
  const {
    assets,
    contracts,
    itemId,
    itemTypeId,
    typeId,
    masterId,
    catalogId,
    lyrics,
    projectId,
    projectTitle,
    projectBeat,
    locked,
    parentTitle,
  } = action.payload;

  const asDriveItem = <T extends Omit<DriveItem, '__itemname'>>(data: T): DriveItem => ({
    __itemname: FileBrowserItemType.Drive,
    ...data,
  });

  if (itemTypeId === FolderItem.MASTER_FILES) {
    const items = assets.filter(
      (asset) =>
        asset?.type_id === ContentType.MASTER_FILE_CLEAN ||
        asset?.type_id === ContentType.MASTER_FILE_EXPLICIT
    ) as AssetFieldsFragment[];

    return asDriveItem({
      id: itemId,
      typeId: typeId,
      itemTypeId: itemTypeId,
      masterId,
      catalogId,
      items: items.sort(DriveUtils.cleanFirst),
      locked,
      parentTitle,
    });
  }

  if (itemTypeId === FolderItem.META) {
    return asDriveItem({
      id: itemId,
      typeId: typeId,
      itemTypeId: itemTypeId,
      items: [],
      masterId: masterId,
      projectId: projectId,
      catalogId: catalogId,
      metaRows: action.payload.metaRows,
      locked,
      parentTitle,
    });
  }

  if (itemTypeId === FolderItem.LYRICS) {
    return asDriveItem({
      id: itemId,
      typeId: typeId,
      itemTypeId: itemTypeId,
      items: [],
      lyrics,
      projectId,
      projectTitle,
      projectBeat,
      catalogId: catalogId,
      locked,
      parentTitle,
    });
  }

  if (itemTypeId === FolderItem.DOCUMENTS) {
    return asDriveItem({
      id: itemId,
      itemTypeId,
      items: assets.filter((asset) => asset?.type_id === typeId) as AssetFieldsFragment[],
      contracts: contracts as IGqlContract[],
      typeId: typeId,
      masterId: masterId,
      catalogId: catalogId,
      locked,
      parentTitle,
    });
  }

  return asDriveItem({
    id: itemId,
    itemTypeId,
    items: assets.filter((asset) => asset?.type_id === typeId) as AssetFieldsFragment[],
    typeId: typeId,
    masterId: masterId,
    projectId,
    catalogId: catalogId,
    locked,
    parentTitle,
  });
};

export default function selectedFileReducer(
  state = initialState,
  action: ViewFileActions | CommonActions
): SelectedFileState {
  switch (action.type) {
    case SelectedFileTypes.DRIVE_ASSETS:
      return driveItemReducer(action);
    case SelectedFileTypes.SHARED_ASSET:
      return sharedAssetReducer(state, action) as SelectedFileState;
    case SelectedFileTypes.CLEAR_SELECTED_FILE:
    case CommonActionTypes.CloseFileBrowserAndUnselectFile:
      return initialState;
    default:
      return state;
  }
}
