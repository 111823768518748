import FilterChangeEvent from 'redux/filters/types/filterChangeEvent';
import Filter from 'redux/filters/enums/filter';

export enum FilterActionTypes {
  SET_FILTER = '@DRIVE/SET_FILTER',
  RESET_FILTER = '@DRIVE/RESET_FILTER',
}

export interface SetFilterAction {
  type: FilterActionTypes.SET_FILTER;
  payload: FilterChangeEvent;
}

export const setFilter = (payload: FilterChangeEvent): SetFilterAction => ({
  type: FilterActionTypes.SET_FILTER,
  payload,
});

export interface ResetFilterAction {
  type: FilterActionTypes.RESET_FILTER;
  payload: Filter;
}

export type FilterActions = ResetFilterAction | SetFilterAction;
