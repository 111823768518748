import { ContentType } from './api/models';

export const DefaultSelectProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: '192px',
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
} as const;

export const ContentTypesArray = [
  ContentType.BEATS_FREE,
  ContentType.BEATS_IMPORT,
  ContentType.PROJECT_BEAT,
  ContentType.PROJECT_IMPORT,
  ContentType.PROJECT_RECORDING,
  ContentType.PROJECT_MIX,
  ContentType.MASTER_FILE_CLEAN,
  ContentType.MASTER_360RA,
  ContentType.MASTER_FILE_EXPLICIT,
  ContentType.MASTER_STEM,
  ContentType.MASTER_360RA,
  ContentType.MASTER_DOCUMENT,
  ContentType.MASTER_CONTRACT,
  ContentType.PROJECT_FOLDER,
  ContentType.MASTER_META,
  ContentType.MASTER_FOLDER,
  ContentType.PROJECT_LYRICS,
  ContentType.PROJECT_META,
  ContentType.BEATS_FOLDER,
  ContentType.MASTER_FILE,
  ContentType.MASTER_MEDIA,
  ContentType.COLLECTION,
  ContentType.ALBUM_COVER,
  ContentType.RELEASES,
  ContentType.DROP_SUBMISSION,
] as const;

export const PlayableContentTypes = [
  ContentType.BEATS_FREE,
  ContentType.BEATS_IMPORT,

  ContentType.PROJECT_BEAT,
  ContentType.PROJECT_IMPORT,
  ContentType.PROJECT_RECORDING,
  ContentType.PROJECT_MIX,

  ContentType.MASTER_FILE,
  ContentType.MASTER_FILE_CLEAN,
  ContentType.MASTER_FILE_EXPLICIT,
  ContentType.MASTER_STEM,
];

export const UnDownloadableContentTypes = [
  ContentType.MASTER_FOLDER,
  ContentType.PROJECT_FOLDER,
  ContentType.BEATS_FOLDER,
];

export const PreviewableContentTypes = [ContentType.MASTER_DOCUMENT];

export const FilePreviewableContentTypes = [
  ContentType.MASTER_DOCUMENT,
  ContentType.MASTER_MEDIA,
];
