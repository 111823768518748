import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useGetFeaturesQuery } from 'generated/graphql';
import { Routes } from 'navigation/enums/routes';
import ArtistSettingsRotes from 'modules/profile/navigation/artistSettingsRoter/artistSettingsRotes';
import HeaderMenuItem from 'common/components/headerMenu/interfaces/headerMenuItem';
import HEADER_MENU_ITEMS from 'common/components/headerMenu/configs/headerMenuItems';
import HeaderMenuActionTypes from 'common/components/headerMenu/enums/HeaderMenuActionTypes';
import { logoutAction } from 'redux/login/loginActions';

interface UseHeaderMenuItemsRes {
  config: HeaderMenuItem[];
  isUserManagement: boolean;
  menuItemClickHandler: (actionType: HeaderMenuActionTypes) => void;
}

const useHeaderMenuItems: () => UseHeaderMenuItemsRes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: { features } = {} } = useGetFeaturesQuery();

  const menuItemClickHandler = (actionType: HeaderMenuActionTypes): void => {
    switch (actionType) {
      case HeaderMenuActionTypes.LogOut:
        dispatch(logoutAction());
        history.push('/');
        break;
      case HeaderMenuActionTypes.ArtistProfile:
        history.push(`${Routes.ArtistSettings}${ArtistSettingsRotes.Account}`);
        break;
      case HeaderMenuActionTypes.Roles:
        history.push(Routes.ManagementRoles);
        break;
      case HeaderMenuActionTypes.Users:
        history.push(Routes.ManagementUsers);
        break;
      default:
        return;
    }
  };

  return {
    config: HEADER_MENU_ITEMS,
    menuItemClickHandler,
    isUserManagement: Boolean(features?.userManagement),
  };
};

export default useHeaderMenuItems;
