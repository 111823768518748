enum UsersPaths {
  AcceptInvite = 'users/invite/accept',
  ChangePassword = 'users/password/reset',
  DeclineInvite = 'users/invite/decline',
  DeactivateUser = 'users/deactivate',
  GetUsersByEmail = 'users/lookup/email/',
  GetUsersByName = 'users/lookup/name/',
  Login = 'users/login',
  SendRecoveryLink = 'users/password/reset_request',
  SignUp = 'users/',
  UpdatePassword = 'users/password',
  UpdateProfileData = 'users/profile',
}

export default UsersPaths;
