import React, { FC } from 'react';
import clsx from 'clsx';
import UIDialog from '@material-ui/core/Dialog/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import useIsMobileUi from 'common/hooks/useIsMobileUi';
import { RootState } from 'redux/root-reducer';
import { clearDialogContent } from 'redux/dialog/dialogActions';

const useStyles = makeStyles(
  () => ({
    root: {},
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    hidden: {
      display: 'none',
    },
  }),
  { name: 'Modal' }
);

const Dialog: FC = () => {
  const dialogState = useSelector((state: RootState) => state.dialog);
  const dispatch = useDispatch();
  const isMobile = useIsMobileUi();
  const classes = useStyles();

  const exitedHandler = () => {
    dispatch(clearDialogContent());
  };

  return (
    <UIDialog
      className={classes.root}
      open={dialogState.isOpened}
      scroll="paper"
      fullScreen={isMobile}
      onExited={exitedHandler}
      disableBackdropClick
      maxWidth={false}
    >
      {dialogState.content.map(
        (item, index) =>
          (
            <div
              className={clsx(classes.content, {
                [classes.hidden]: index !== dialogState.content.length - 1,
              })}
              style={{ width: item.customWidth || 'auto' }}
              key={item.name}
            >
              {item?.element}
            </div>
          ) || null
      )}
    </UIDialog>
  );
};

export default Dialog;
