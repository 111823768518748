import { mapValues } from 'lodash';

import PrivilegesConfig from 'common/types/privilegesConfig';

const mergePrivileges = <T extends PrivilegesConfig = PrivilegesConfig>(
  alpha: T,
  beta: T
) => {
  const result: PrivilegesConfig = mapValues<T, boolean>(alpha, (_, privilege) => {
    return alpha[privilege] || beta[privilege];
  });

  return result;
};

export default mergePrivileges;
