import { Theme, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import Typography from 'uikit/typography/Typography';
import find from 'lodash/find';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(
  (theme: Theme) => ({
    sectionItem: {
      display: 'flex',
      alignItems: 'center',
      height: 48,
      padding: '0 24px',
      overflow: 'hidden',
      borderLeft: '3px solid transparent',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[50],
      },
      '&$active': {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.grey[100],
      },
    },
    active: {},
    sectionItemText: {
      marginLeft: theme.spacing(3),
    },
    sectionItemIcon: {
      flexShrink: 0,
    },
    external: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'SectionItem' }
);

type SectionItemProps = {
  loading: boolean;
  onClick?: () => void;
  to: string;
  isActive: boolean;
  isCollapsed: boolean;
  IconComponent: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  nestedLinks?: Array<{ label: string; to: string; isActive: boolean }>;
  isExternalLink?: boolean;
};

const SectionItem: React.FC<SectionItemProps> = ({
  loading,
  onClick,
  to,
  isActive,
  isCollapsed,
  IconComponent,
  label,
  nestedLinks,
  isExternalLink = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const linkStyleClass = clsx([
    classes.sectionItem,
    isActive ? classes.active : undefined,
  ]);

  const renderItem = React.useCallback(
    () => (
      <>
        <IconComponent
          fill={isActive ? '#17181d' : '#c4c4c4'}
          className={classes.sectionItemIcon}
        />
        {!isCollapsed && (
          <Typography variant={'subtitle1'} className={classes.sectionItemText}>
            {label}
          </Typography>
        )}
      </>
    ),
    [isActive, classes.sectionItemIcon, classes.sectionItemText, isCollapsed, label]
  );

  return loading ? (
    <Skeleton variant={'rect'} height={30} style={{ margin: theme.spacing(1, 2) }} />
  ) : (
    <>
      {isExternalLink ? (
        <a
          target="_blank"
          href={to}
          className={clsx(linkStyleClass, classes.external)}
          rel="noopener noreferrer"
        >
          {renderItem()}
        </a>
      ) : (
        <Link onClick={onClick} to={to} className={linkStyleClass}>
          {renderItem()}
        </Link>
      )}

      {nestedLinks &&
        (isActive || find(nestedLinks, { isActive: true })) &&
        nestedLinks.map((link) => (
          <Link
            key={link.to}
            to={link.to}
            className={clsx([
              classes.sectionItem,
              link.isActive ? classes.active : undefined,
            ])}
          >
            {!isCollapsed && <p>{link.label}</p>}
          </Link>
        ))}
    </>
  );
};

export default SectionItem;
