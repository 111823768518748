import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: '1 1 auto',
      ...theme.mixins.contentHeight,
      overflowY: 'auto',
    },
  }),
  { name: 'ContentBox' }
);

const ContentBox = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode; className?: string }
>(({ children, className }, ref) => {
  const classes = useStyles();
  return (
    <div ref={ref} className={clsx(classes.root, className && className)}>
      {children}
    </div>
  );
});

export default ContentBox;
