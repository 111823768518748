import store from 'redux/store';
import { logoutAction } from 'redux/login/loginActions';
import loadAccessToken from 'common/services/tokenServices/loadAccessToken';

const getAccessToken = async (): Promise<string | void> => {
  const token = loadAccessToken();
  if (token) {
    return token;
  }
  store.dispatch(logoutAction());
};

export default getAccessToken;
