import Dialog from '@material-ui/core/Dialog/Dialog';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import DNDArea from 'uikit/dragAndDropArea/DragAndDropArea';
import {
  IUContractAction,
  IUploadedContractLocalState,
  removeFileAction,
  setContractTypeAction,
  setDescriptionAction,
} from 'common/components/Modals/contractAgreementFormDialog/contractAgreementFormState';
import FilePreviewDialog from 'common/components/Modals/filePreviewDialog/FilePreviewDialog';
import Typography from 'uikit/typography/Typography';
import { DocumentBallIcon } from 'common/icons/content-types';
import { DeleteIconOld } from 'common/icons/system';
import { AcceptTypeString } from 'config/acceptTypes';
import { ContractType } from 'generated/graphql';
import React from 'react';
import TextField from 'uikit/textField/TextField';
import { IconButton } from 'uikit/iconButton/IconButton';

const useStyles = makeStyles((theme) => ({
  topSpace: {
    marginTop: theme.spacing(2),
  },
  fileNameLabel: {
    color: theme.palette.common.black,
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  fileNameActionSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  fileNameSection: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}));

const useDialogStyles = makeStyles(
  () => ({
    paper: {
      width: '70%',
      maxWidth: '100%',
      height: '80%',
    },
  }),
  { name: 'FileSubSection' }
);

interface ISummarySectionProps {
  dispatch: React.Dispatch<IUContractAction>;
  summary: IUploadedContractLocalState;
}

const SummarySection: React.FC<ISummarySectionProps> = ({ dispatch, summary }) => {
  const classes = useStyles();
  const classesDialog = useDialogStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [uploadedFile, setUploadedFile] = React.useState({
    url: summary.file?.downloadUrl,
    name: summary.file?.fileName,
    isUploaded: summary.fileIsUploaded,
  });

  const handleDeleteContractClick = () => {
    setUploadedFile({ name: '', url: '', isUploaded: false });
    dispatch(removeFileAction());
  };

  const handleFilesUpload = React.useCallback(
    (files: FileList | null) => {
      if (!files) return;

      const file = Array.from(files)?.[0];
      setUploadedFile({ name: file.name, url: '', isUploaded: true });

      dispatch({
        type: '@U_CONTRACT/SET_PUSH_FILE',
        payload: { file },
      });
    },
    [dispatch]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            variant={'outlined'}
            label={'Contract Type'}
            select
            value={summary.type || ContractType.UPLOADED_CONTRACT}
            className={classes.capitalize}
            onChange={(e) => dispatch(setContractTypeAction(e.target.value as string))}
          >
            <MenuItem value={ContractType.UPLOADED_CONTRACT}>
              <em>None</em>
            </MenuItem>
            {Object.values(ContractType).map(
              (contract) =>
                (contract as string) !== (ContractType.SPLIT_SHEET_AGREEMENT as string) &&
                (contract as string) !== (ContractType.UPLOADED_CONTRACT as string) && (
                  <MenuItem
                    className={classes.capitalize}
                    value={contract}
                    key={contract}
                  >
                    {contract.split('_').join(' ').toLowerCase().replace('agreement', '')}
                  </MenuItem>
                )
            )}
          </TextField>
        </FormControl>

        <TextField
          multiline
          optional
          fullWidth
          label={'Description'}
          value={summary.description}
          onChange={(e) => dispatch(setDescriptionAction(e.target.value))}
          rows={3}
          rowsMax={3}
          inputProps={{
            maxLength: 200,
          }}
          className={classes.topSpace}
        />
      </Grid>
      <Grid item xs={6}>
        {uploadedFile.isUploaded ? (
          <div className={classes.fileNameActionSection}>
            <div className={classes.fileNameSection}>
              <DocumentBallIcon />
              <Typography
                variant={'subtitle2'}
                className={classes.fileNameLabel}
                onClick={() => setOpenModal(true)}
              >
                {uploadedFile.name}
              </Typography>
            </div>
            <IconButton size={'small'} onClick={handleDeleteContractClick}>
              <DeleteIconOld />
            </IconButton>

            {!!uploadedFile.url && !!uploadedFile.name && (
              <Dialog classes={classesDialog} open={openModal}>
                <FilePreviewDialog
                  onClose={() => setOpenModal(false)}
                  uri={uploadedFile.url}
                  title={uploadedFile.name}
                />
              </Dialog>
            )}
          </div>
        ) : (
          <DNDArea
            inputProps={{ accept: AcceptTypeString.DOCUMENT }}
            onChange={handleFilesUpload}
            disabled={false}
            label={'File'}
            optional
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SummarySection;
