import * as ShareDialogActions from 'common/components/Modals/shareDialog/store/shareDialogActions';
import ShareDialogState from '../interfaces/shareDialogState';

const shareDialogReducer = (
  state: ShareDialogState,
  action: ShareDialogActions.ShareDialogAction
): ShareDialogState => {
  const actionTypes = ShareDialogActions.ShareDialogActionTypes;

  switch (action.type) {
    case actionTypes.SetRecipient:
      return {
        ...state,
        recipient: action.payload.value,
      };
    case actionTypes.SetMessage:
      return {
        ...state,
        message: action.payload.value,
      };
    case actionTypes.SetPermissions:
      return {
        ...state,
        permissions: action.payload.value,
      };
    case actionTypes.SetExpiration:
      return {
        ...state,
        expiration: action.payload.value,
      };
    case actionTypes.Set2FA:
      return {
        ...state,
        is2faEnabled: action.payload.value,
      };
    case actionTypes.SetValidation:
      return {
        ...state,
        validation: action.payload.value,
      };
    default:
      return state;
  }
};

export default shareDialogReducer;
