import ITransferState from 'redux/fileUpload/interfaces/iTransferState';
import TransferState from 'redux/fileUpload/types/transferState';
import FileUploadState from 'redux/fileUpload/types/fileUploadState';
import {
  FileUploadActions,
  FileUploadActionTypes,
  UploadingActionTypes,
} from 'redux/fileUpload/fileUploadActions';
import updateEntry from 'redux/fileUpload/utils/updateEntry';
import getUploadingProgress from 'redux/fileUpload/utils/getUploadingProgress';
import INIT_FILE_UPLOAD_STATE from 'redux/fileUpload/configs/initFileUploadState';
import calcFields from 'redux/fileUpload/utils/calcFields';
import prepare from 'redux/fileUpload/utils/prepare';
import removeFile from 'redux/fileUpload/utils/removeFile';

export function uploadingReducer(
  state: ITransferState,
  action: FileUploadActions
): ITransferState {
  switch (action.type) {
    case UploadingActionTypes.SET_PROGRESS: {
      return {
        ...state,
        entries: updateEntry(state.entries, action.payload.index, (entry) => ({
          ...entry,
          total: action.payload.total,
          loaded: action.payload.loaded,
          progress: getUploadingProgress(action.payload.loaded, action.payload.total),
        })),
      };
    }
    case UploadingActionTypes.SET_ERROR: {
      return {
        ...state,
        entries: updateEntry(state.entries, action.payload.index, (entry) => ({
          ...entry,
          completed: true,
          result: {
            ok: false,
            value: action.payload.value,
          },
        })),
      };
    }
    case UploadingActionTypes.SET_SUCCESS: {
      return {
        ...state,
        entries: updateEntry(state.entries, action.payload.index, (entry) => ({
          ...entry,
          completed: true,
          result: {
            ok: true,
            value: action.payload.value,
          } as const,
        })),
      };
    }
    case UploadingActionTypes.END: {
      return {
        ...state,
        finished: Date.now(),
        status: FileUploadActionTypes.DONE,
      };
    }
    default: {
      return state;
    }
  }
}

export default function fileUploadReducer(
  state = INIT_FILE_UPLOAD_STATE,
  action: FileUploadActions
): FileUploadState {
  if (
    state.status === FileUploadActionTypes.IDLE &&
    action.type === UploadingActionTypes.PREPARE
  ) {
    return calcFields(prepare(action)) as TransferState;
  }

  if (state.status === FileUploadActionTypes.PREPARING) {
    if (action.type === UploadingActionTypes.PREPARE) {
      return calcFields(prepare(action)) as TransferState;
    }

    if (action.type === UploadingActionTypes.REMOVE_FILE) {
      return calcFields(removeFile(state, action.payload.index)) as TransferState;
    }

    if (action.type === UploadingActionTypes.START) {
      return {
        ...state,
        started: Date.now(),
        autostart: false,
        status: FileUploadActionTypes.UPLOADING,
      };
    }

    if (action.type === UploadingActionTypes.RESET) {
      return { ...INIT_FILE_UPLOAD_STATE };
    }
  }

  if (state.status === FileUploadActionTypes.UPLOADING) {
    return uploadingReducer(state, action) as TransferState;
  }

  if (state.status === FileUploadActionTypes.DONE) {
    if (action.type === UploadingActionTypes.RESET) {
      return { ...INIT_FILE_UPLOAD_STATE };
    }
  }

  return state;
}
