import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import constructPath from 'api/utils/constructPath';
import { Asset } from 'common/api/models';
import POST from 'common/services/httpServices/post';
import { SomeRequired } from 'common/utilityTypes';

const createMasterAsset: (
  input: SomeRequired<Asset, 'title' | 'catalog' | 'type'>,
  params: {
    catalogId: number;
    masterId: number;
  }
) => Promise<Asset> = async (input, params) => {
  const { catalogId, masterId } = params;
  const endpoint = constructPath(CatalogsPaths.CreateMasterAsset, {
    catalogId: catalogId.toString(),
    masterId: masterId.toString(),
  });

  return await POST(endpoint, JSON.stringify(input)).then<Asset>((res) => res.json());
};

export default createMasterAsset;
