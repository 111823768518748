import apiFileService from 'api/file/apiFileService';
import getNewFilename from 'common/utils/filename/getNewFilename';
import filenameFromUrl from 'common/utils/filename/filenameFromUrl';
import { FileItem } from './interfaces/fileItem';
import { FileToSave } from './interfaces/fileToSave';

const getFileToSave = async (fileItem: FileItem): Promise<FileToSave> => {
  const { data } = await apiFileService.getBlob(fileItem.url);
  const originFilename = filenameFromUrl(fileItem.url);
  const filename = originFilename
    ? getNewFilename(originFilename, fileItem.title)
    : fileItem.title;

  return {
    data,
    name: filename,
  };
};

export default getFileToSave;
