import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { DragDropContext, Droppable, DragDropContextProps } from 'react-beautiful-dnd';

interface TrackListProps extends DragDropContextProps {
  listId: string;
  className?: string;
}

const useListStyles = makeStyles(
  (theme) => ({
    root: {
      maxHeight: '100%',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[50],
      transition: theme.transitions.create(['background']),
      [theme.breakpoints.up('md')]: {
        minHeight: 192,
      },
    },
  }),
  { name: 'TrackList' }
);

export const TrackList: FC<TrackListProps> = ({
  children,
  listId,
  className,
  ...contextProps
}) => {
  const classes = useListStyles();

  return (
    <DragDropContext {...contextProps}>
      <Droppable droppableId={listId}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            className={clsx(classes.root, className)}
            ref={provided.innerRef}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
