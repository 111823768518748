import * as React from 'react';
import clsx from 'clsx';
import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import { useEventCallback, useCaptureClickAway } from 'common/hooks';
import Typography, { ITypographyProps } from 'uikit/typography/Typography';
import { EditIcon } from 'common/icons/system';

export type ExitReason = 'keypress' | 'clickaway' | 'blur';

export type EdiphyProps = Omit<ITypographyProps, 'classes' | 'variant'> &
  Pick<TextFieldProps, 'value' | 'onChange' | 'defaultValue' | 'helperText' | 'error'> & {
    editing: boolean;
    variant: NonNullable<ITypographyProps['variant']>;
    onEditEnter: () => void;
    onEditExit: (reason: ExitReason) => void;
    oneLineText?: boolean;
    disableIconHide?: boolean;
    disableClickBubbling?: boolean;
    outlined?: boolean;
  };

const useStyles = makeStyles((theme) => ({
  inputBaseRoot: {
    height: 'auto',
    width: 'calc(100% + (2 * 8px))',
    position: 'relative',
    zIndex: theme.zIndex.speedDial,
    transition: theme.transitions.create('border', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  inputBaseRootOutlined: {
    height: 'auto',
    width: 'calc(100% + (2 * 8px))',
    position: 'relative',
    zIndex: theme.zIndex.speedDial,
    transition: theme.transitions.create('border', {
      duration: theme.transitions.duration.shortest,
    }),
    margin: '-6px -8px',
  },
  input: (props: EdiphyProps) => ({
    zIndex: 200,
    height: 'auto',
    ...(props.variant && {
      ...theme.typography[props.variant],
    }),
  }),
  text: {
    paddingRight: '1em',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& $iconBox': { opacity: 1 },
    },
  },
  oneLineText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
  },
  iconBox: {
    display: 'inline-block',
    position: 'absolute',
    margin: 'auto',
    top: 0,
    right: 0,
    bottom: 0,
    height: '1em',
    transition: theme.transitions.create('opacity'),
    opacity: 0,
    '$disableIconHide &': {
      opacity: 1,
    },
  },
  icon: {
    cursor: 'pointer',
    display: 'block',
  },
  helperText: {
    position: 'absolute',
    margin: 0,
    top: `calc(100% + 5px + ${theme.spacing(0.5)}px)`,
    left: theme.spacing(-1),
    padding: theme.spacing(0.5, 1),
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.shape.borderRadius,
    zIndex: 300,
  },
  disableIconHide: {},
}));

type Props = EdiphyProps & {
  classes?: Partial<ReturnType<typeof useStyles>>;
};

const ClickAway = ({ onClickAway, children }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  useCaptureClickAway(ref, onClickAway);

  return React.cloneElement(children, { ref: ref });
};

const Ediphy: React.FC<Props> = (props) => {
  const {
    value,
    defaultValue,
    onChange,
    onEditEnter,
    onEditExit,
    oneLineText = false,
    disableClickBubbling = false,
    editing,
    error,
    helperText,
    disableIconHide = false,
    outlined = true,
    // NOTE: We excluding classes on purpose to not pass theme to typography.
    classes: exludeFromProps, // eslint-disable-line no-unused-vars
    ...other
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const classes = useStyles(props);

  const exit = (reason: ExitReason) => {
    onEditExit(reason);
  };

  const handleKeyPress = useEventCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      exit('blur');
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      exit('keypress');
    }
  });

  const handleClick = useEventCallback((event: React.MouseEvent) => {
    if (disableClickBubbling) event.stopPropagation();
    onEditEnter();
  });

  React.useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.select();
    }
  }, [editing]);

  const InputProps = {
    autoFocus: true,
    classes: {
      root: outlined ? classes.inputBaseRootOutlined : classes.inputBaseRoot,
      input: classes.input,
    },
  };
  if (!outlined) {
    InputProps['disableUnderline'] = true;
  }
  return (
    <>
      {!editing && (
        <Typography
          {...other}
          className={clsx(classes.text, {
            [classes.oneLineText]: oneLineText,
            [classes.disableIconHide]: disableIconHide,
          })}
          onClick={handleClick}
        >
          {defaultValue}
          <span className={classes.iconBox}>
            <EditIcon fontSize="inherit" className={classes.icon} />
          </span>
        </Typography>
      )}
      {editing && (
        <ClickAway onClickAway={() => exit('clickaway')}>
          <TextField
            error={error}
            helperText={helperText}
            inputRef={inputRef}
            fullWidth
            onKeyDown={handleKeyPress}
            InputProps={InputProps}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            variant={outlined ? 'outlined' : 'standard'}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
              },
            }}
          />
        </ClickAway>
      )}
    </>
  );
};

export default Ediphy;
