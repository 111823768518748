import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import constructPath from 'api/utils/constructPath';
import { RestMaster } from 'common/api/models';
import POST from 'common/services/httpServices/post';
import { SomeRequired } from 'common/utilityTypes';

const createMaster: (
  input: SomeRequired<RestMaster, 'title'>,
  params: {
    catalogId: number;
  }
) => Promise<RestMaster> = async (input, params) => {
  const { catalogId } = params;
  const endpoint = constructPath(CatalogsPaths.CreateMaster, {
    catalogId: catalogId.toString(),
  });

  return await POST(endpoint, JSON.stringify(input)).then<RestMaster>((res) =>
    res.json()
  );
};

export default createMaster;
