import DriveItem from 'redux/selectedFile/interfaces/driveItem';
import { ContentType } from 'common/api/models';
import downloadSubfolder from 'modules/masters/services/downloadSubfolder';

const download = (selectedFile: DriveItem) => {
  switch (selectedFile.typeId as ContentType) {
    case ContentType.PROJECT_META:
    case ContentType.MASTER_META: {
      const rows = selectedFile.metaRows;
      let fileRows = '';

      if (!rows) return false;

      rows.map((row, _) => {
        row.forEach((field) => {
          fileRows = fileRows + `${field.name}: \t ${field.value}\n`;
        });
        return fileRows;
      });

      const element = document.createElement('a');
      const file = new Blob([fileRows], { type: 'text/plain;charset=utf-8' });
      element.href = URL.createObjectURL(file);
      element.download = `${rows[0][0].value}.meta.txt`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    }
    case ContentType.MASTER_FILE:
    case ContentType.MASTER_STEM:
    case ContentType.MASTER_MEDIA:
    case ContentType.MASTER_DOCUMENT:
    case ContentType.MASTER_CONTRACT:
    case ContentType.MASTER_FILE_CLEAN: {
      downloadSubfolder(selectedFile);
      return true;
    }
  }
};

export default download;
