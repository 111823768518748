import { removeMasterFromCollectionMutation } from 'apollo/mutationFns';
import { notify } from 'common/components/notifMessages/Notify';
import { ERROR_MESSAGES } from 'config/errorsConfig';

const removeMasterFromCollectionService = async (input: {
  collectionId: number;
  masterId: number;
}) => {
  if (typeof input.collectionId !== 'number' || typeof input.masterId !== 'number') {
    return;
  }

  const { collectionId, masterId } = input;

  try {
    await removeMasterFromCollectionMutation({
      variables: {
        collectionId,
        masterId,
      },
    });

    notify.enqueueSnackbar('Removed', { variant: 'success' });
  } catch (error) {
    notify.enqueueSnackbar(ERROR_MESSAGES.REMOVE_FROM_COLL, { variant: 'error' });
  }
};

export default removeMasterFromCollectionService;
