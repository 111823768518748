import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { IconButton } from 'uikit/iconButton/IconButton';
import useIsMobileUi from 'common/hooks/useIsMobileUi';
import { ChevronLeft } from 'common/icons/system';

import Typography, { ITypographyProps } from 'uikit/typography/Typography';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    header: {
      padding: theme.spacing(4, 0, 2),
    },
    backButton: {
      marginRight: theme.spacing(2),
      marginTop: 20,
    },
  }),
  { name: 'ContentHeader' }
);

interface ContentHeaderProps extends ITypographyProps {
  isBackButtonVisible?: boolean;
}

const ContentHeader: FC<ContentHeaderProps> = ({
  isBackButtonVisible = false,
  ...props
}) => {
  const isMobile = useIsMobileUi();
  const classes = useStyles();
  const isBackButton = isMobile && isBackButtonVisible;
  const history = useHistory();

  return (
    <div className={classes.root}>
      {isBackButton && (
        <IconButton
          size="small"
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          <ChevronLeft />
        </IconButton>
      )}
      <Typography variant="h4" component="h1" className={classes.header} {...props} />
    </div>
  );
};

export default ContentHeader;
