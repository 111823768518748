import {
  makeStyles,
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  emphasize,
} from '@material-ui/core/styles';
import * as React from 'react';
import { SnackbarProvider, VariantType } from 'notistack';
import clsx from 'clsx';
import { capitalize } from '@material-ui/core';

const contentStyles = (theme: Theme) => {
  const bgDefault = emphasize(
    theme.palette.background.default,
    theme.palette.type === 'light' ? 0.8 : 0.98
  );

  return createStyles({
    contentRoot: {
      ...theme.typography.body2,
      display: 'flex',
      flexWrap: 'wrap',
      flexGrow: 1,
      borderRadius: 16,
      backgroundColor: bgDefault,
      color: theme.palette.getContrastText(bgDefault),
      padding: '6px 16px',
      boxShadow:
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
      [theme.breakpoints.up('sm')]: {
        flexGrow: 'initial',
        minWidth: 288,
      },
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 0',
    },
    variantSuccess: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    variantError: {
      backgroundColor: `${theme.palette.error.main} !important`,
      color: `${theme.palette.error.contrastText} !important`,
    },
    variantWarning: {
      backgroundColor: `${theme.palette.warning.main} !important`,
      color: `${theme.palette.warning.contrastText} !important`,
    },
    variantInfo: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      color: `${theme.palette.secondary.contrastText} !important`,
    },
  });
};

const useStyles = makeStyles(contentStyles, { name: 'SnackBox' });

export type SnackBoxProps = {
  variant?: VariantType;
  message?: string | React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const SnackBox = withStyles(contentStyles, { name: 'SnackBox' })(
  React.forwardRef<HTMLDivElement, SnackBoxProps & WithStyles<typeof contentStyles>>(
    (
      {
        children,
        message,
        classes,
        className: classNameProp,
        variant = 'default',
        ...props
      },
      ref
    ) => {
      const className = clsx(
        classes.contentRoot,
        { [classes[`variant${capitalize(variant)}`]]: variant !== 'default' },
        classNameProp
      );

      return (
        <div {...props} className={className} ref={ref}>
          {message ? <div className={classes.message}>{message}</div> : children}
        </div>
      );
    }
  )
);

const Notifications: React.FC = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={10}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={useStyles()}
    >
      {children}
    </SnackbarProvider>
  );
};

export default Notifications;
