import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormControl,
  RadioGroup,
} from '@material-ui/core';

import { Routes } from 'navigation/enums/routes';
import Button from 'uikit/button/Button';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import Typography from 'uikit/typography/Typography';
import MuiRadio from 'uikit/radio/Radio';
import { ModalConfigActionTypes, modalOpen } from 'redux/modal/modalActions';
import { setDialogClosed } from 'redux/dialog/dialogActions';

const useStyles = makeStyles((theme: Theme) => ({
  subtittle: {
    marginBottom: theme.spacing(2),
  },
  radiobtn: {
    padding: theme.spacing(1, 1, 1, 1),
  },
}));

type Props = {
  onClose: () => void;
  disableSplitsheet: boolean;
  masterId: number;
  catalogId: number;
};

const CreateContractDialog = (props: Props) => {
  const history = useHistory();
  const { onClose, masterId, catalogId, disableSplitsheet } = props;
  const [diasbled, setDiasbled] = React.useState(true);
  const [contractType, setContractType] = React.useState('');
  const contractTypeMap = {
    split: 'splitSheet',
    contract: 'newContract',
  };
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (contractType === contractTypeMap.split) {
      history.push({
        pathname: Routes.SplitSheet,
        search: `?master_id=${masterId}`,
      });
      dispatch(setDialogClosed());
      onClose();
    } else {
      dispatch(
        modalOpen({
          type: ModalConfigActionTypes.UPLOAD_CONTRACT_CREATE,
          data: {
            initialSummary: {
              title: new Date().toLocaleString(),
              description: '',
              createdAt: new Date().toISOString(),
              file: {
                id: 0,
              },
              masterId: masterId,
              parties: [],
              catalogId: catalogId,
              deletedPartyIds: [],
              editedPartyIds: [],
            },
            isCreatingSummary: true,
          },
        })
      );
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiasbled(false);
    setContractType((event.target as HTMLInputElement).value);
  };

  const classes = useStyles();

  return (
    <>
      <DialogHeader title="Add New Contract" onClose={onClose} />
      <DialogContent>
        <Typography className={classes.subtittle} variant="body2">
          What kind of contract are you going to add?
        </Typography>
        <FormControl>
          <RadioGroup
            aria-label="contract"
            name="contractType"
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={contractTypeMap['split']}
              color={'primary'}
              className={classes.radiobtn}
              control={<MuiRadio disabled={disableSplitsheet} color={'primary'} />}
              label={<Typography variant={'body2'}>Split Sheet</Typography>}
            />
            <FormControlLabel
              value={contractTypeMap['contract']}
              color={'primary'}
              className={classes.radiobtn}
              control={<MuiRadio color={'primary'} />}
              label={<Typography variant={'body2'}>New Contract</Typography>}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" dialogActionBase>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={diasbled}
          dialogActionBase
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateContractDialog;
