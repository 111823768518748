import React, { FC } from 'react';

import WebViewLayout from 'modules/app/components/WebViewLayout';
import FileUploadEffects from 'modules/app/effects/FileUploadEffects';
import UserEffects from 'modules/app/components/UserEffects';
import PrivateEffects from 'modules/app/effects/PrivateEffects';
import WebViewRouter from 'navigation/routes/webViewRouter/WebViewRouter';
import getTokenFromSearchParams from 'modules/app/utils/getTokenFromSearchParams';
import { TOKEN_NAME } from 'config/appConstants';
import { useHistory, useLocation } from 'react-router';
import { Routes } from 'navigation/enums/routes';

const WebViewContent: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const token = getTokenFromSearchParams(location.search);

  if (token === undefined && location.pathname !== Routes.WebViewError) {
    history.push(Routes.WebViewError);
    return null;
  }

  if (token !== undefined) {
    localStorage.setItem(TOKEN_NAME, token);
  }

  return (
    <WebViewLayout>
      <FileUploadEffects />
      <UserEffects />
      <PrivateEffects />
      <WebViewRouter />
    </WebViewLayout>
  );
};

export default WebViewContent;
