import React, { FC } from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SHARED_LINK_EXPIRATION_LABELS from '../configs/sharedLinkExpirationLabels';
import SHARED_LINK_EXPIRATIONS from '../configs/sharedLinkExpirations';
import { SharedLinkExpiration } from 'common/models/sharing';
import noop from 'common/utils/noop';
import Typography from 'uikit/typography/Typography';
import clsx from 'clsx';

import { InputLabel } from '@material-ui/core';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: theme.spacing(1),
    },
    mainLabel: {
      flexBasis: '100%',
      color: theme.palette.common.black,
    },
    radioGroup: {
      flexBasis: '100%',
      flexDirection: 'row',

      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(-1.5),
      },
    },
    radioLabel: {
      margin: theme.spacing(1),
    },
    invalid: {
      color: theme.palette.error.main,
    },
  }),
  { name: 'ExpirationRadioSelector' }
);

interface ExpirationRadioSelectorProps {
  isValid: boolean;
  expiration: SharedLinkExpiration;
  onChange: (value: SharedLinkExpiration) => void;
}

const ExpirationRadioSelector: FC<ExpirationRadioSelectorProps> = ({
  expiration,
  isValid,
  onChange = noop,
}) => {
  const SharedLinkExpirationLabels = SHARED_LINK_EXPIRATION_LABELS;
  const classes = useStyles();

  const valueChangeHandler = (e: React.ChangeEvent<{ value: unknown }>) => {
    onChange(e.target.value as SharedLinkExpiration);
  };
  return (
    <div className={classes.root}>
      <InputLabel className={clsx(classes.mainLabel, !isValid && classes.invalid)}>
        <Typography variant={'subtitle2'} className={'MuiMainLabel-root'}>
          Expiration
        </Typography>
      </InputLabel>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={expiration * 1}
        className={classes.radioGroup}
        onChange={valueChangeHandler}
      >
        {SHARED_LINK_EXPIRATIONS.map((exp) => {
          return (
            <FormControlLabel
              className={classes.radioLabel}
              value={exp}
              control={<Radio size="small" color="primary" />}
              label={SharedLinkExpirationLabels[exp].label}
              key={exp}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
};

export default ExpirationRadioSelector;
