import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import POST from 'common/services/httpServices/post';
import constructPath from 'api/utils/constructPath';

const createLyrics: (
  input: {
    catalogId: number;
    projectId: number;
    content: string;
  },
  params: {
    catalogId: number;
    projectId: number;
  }
) => Promise<{ id: number; content: string; created_at: string }> = async (
  input,
  params
) => {
  const { catalogId, projectId } = params;
  const endpoint = constructPath(CatalogsPaths.CreateLyrics, {
    catalogId: catalogId.toString(),
    projectId: projectId.toString(),
  });
  return await POST(endpoint, JSON.stringify(input)).then<{
    id: number;
    content: string;
    created_at: string;
  }>((res) => res.json());
};

export default createLyrics;
