import * as React from 'react';
import {
  DialogActions,
  DialogContent,
  Box,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
  makeStyles,
} from '@material-ui/core';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import Preloader from 'uikit/preloader/Preloader';
import Typography from 'uikit/typography/Typography';
import Button from 'uikit/button/Button';
import { FormCheckableInput } from 'common/interfaces/formCheckableInput';
import NoItems from 'uikit/noItems/NoItems';

const useStyles = makeStyles(
  (theme) => ({
    content: {
      maxHeight: 300,
    },
    formLabel: {
      '&.Mui-focused': {
        color: theme.palette.text.secondary,
      },
    },
    formControlLabel: {
      marginLeft: -8,
      '& .MuiCheckbox-root': {
        padding: 4,
      },
      '& .MuiFormControlLabel-label': {
        display: 'flex',
      },
    },
  }),
  { name: 'CheckListDialog' }
);

export type CheckListDialogProps = {
  onClose: () => void;
  onSubmit: () => void;
  items: FormCheckableInput[];
  title: string;
  listTitle: React.ReactNode;
  submitLabel?: React.ReactNode;
  noItems?: React.ReactNode;
  loading?: boolean;
  onChange?: CheckboxProps['onChange'];
  CheckboxProps?: CheckboxProps;
};

const CheckListDialog = (props: CheckListDialogProps) => {
  const {
    onClose,
    onSubmit,
    items,
    title,
    listTitle,
    CheckboxProps,
    onChange,
    submitLabel = 'Submit',
    noItems = 'No items',
    loading = false,
  } = props;

  const classes = useStyles();

  return (
    <>
      <DialogHeader onClose={onClose} title={title} />
      <DialogContent className={classes.content}>
        {loading && <Preloader />}
        {!loading && !items.length && (
          <Box py={2}>
            <NoItems align="left">{noItems}</NoItems>
          </Box>
        )}
        {!loading && !!items.length && (
          <FormControl component="fieldset">
            <FormLabel className={classes.formLabel} component="legend">
              <Typography variant="subtitle2">{listTitle}</Typography>
            </FormLabel>
            <FormGroup>
              {items.map(({ id, label, checked }) => (
                <FormControlLabel
                  key={id}
                  className={classes.formControlLabel}
                  control={
                    <Checkbox
                      id={`${id}`}
                      color="primary"
                      {...CheckboxProps}
                      value={id}
                      checked={checked}
                      onChange={onChange}
                    />
                  }
                  label={
                    <Typography component="span" variant="body2">
                      {label}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={onClose} dialogAction>
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant={'contained'}
          color="primary"
          onClick={onSubmit}
          dialogAction
        >
          {submitLabel}
        </Button>
      </DialogActions>
    </>
  );
};

export default CheckListDialog;
