import * as vsh from 'common/formik/validationSchemas';
import { HUGE_MAX_IMAGE_SIZE } from 'config/network';
import * as yup from 'yup';

const albumValidationSchema = yup.object().shape({
  catalogId: vsh.catalogRequired,
  title: vsh.titleRequired,
  artist: vsh.artistNameRequired,
  year: vsh.albumYearRequired,
  artworkFileSize: yup.number().max(HUGE_MAX_IMAGE_SIZE, 'File too big'),
});

export default albumValidationSchema;
