import { ContentType } from 'common/api/models';

const PROJECT_CONTENT_TYPES: ContentType[] = [
  ContentType.PROJECT_META,
  ContentType.PROJECT_LYRICS,
  ContentType.PROJECT_RECORDING,
  ContentType.PROJECT_IMPORT,
  ContentType.PROJECT_MIX,
  ContentType.PROJECT_BEAT,
  ContentType.PROJECT_FOLDER,
];

export default PROJECT_CONTENT_TYPES;
