import CorePaths from 'api/core/enums/corePaths';
import constructPath from 'api/utils/constructPath';
import { IMedia } from 'common/api/models';
import GET from 'common/services/httpServices/get';

const getMediaURL = async (id: number): Promise<string> => {
  const res = await GET(constructPath(CorePaths.GetMediaUrl, { mediaId: id.toString() }));

  const json: IMedia = await res.json();

  return json.media_url;
};

export default getMediaURL;
