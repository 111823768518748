import * as React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import jssPluginCompose from 'jss-plugin-compose';
import { create } from 'jss';
import { createTheme } from 'theme';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import store from 'redux/store';

import { Notify } from 'common/components/notifMessages/Notify';
import SnackbarProvider from 'uikit/notifications/Notifications';
import ApolloProvider from 'modules/app/components/ApolloProvider';

const jss = create({
  plugins: [...jssPreset().plugins, jssPluginCompose()],
});

const theme = createTheme();

const RootProviders: React.FC = ({ children }): React.ReactElement => (
  <Provider store={store}>
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <ApolloProvider>
          <SnackbarProvider>
            <CssBaseline />
            <Notify />
            {children}
          </SnackbarProvider>
        </ApolloProvider>
      </ThemeProvider>
    </StylesProvider>
  </Provider>
);

export default RootProviders;
