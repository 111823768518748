import * as React from 'react';
import Select, { SelectProps } from 'uikit/select/Select';
import { useCatalogNamesQuery } from 'generated/graphql';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import Names, { IdName, NamesProps } from 'modules/advancedSearch/components/names/Names';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';

import { makeStyles } from '@material-ui/core/styles';
import { FC, useCallback, useEffect, useMemo } from 'react';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'CatalogSelect',
  }
);

type TItem = { id: number; name: string };

export type OwnProps = {
  value?: TItem;
  selectedId?: number;
  className?: string;
  onChange?: (value: TItem) => void;
  showNullItem?: boolean;
  nullItemLabel?: string;
  nullItemId?: number;
  placeholder?: string;
  label?: string;
  valuePersistence?: boolean;
  NamesProps?: Partial<NamesProps>;
};

export type CatalogSelectProps = Omit<SelectProps, 'classes' | 'text'> & OwnProps;

const adapter = <T extends { id: number }>({ id, ...other }: T) => ({
  id: `${id}`,
  ...other,
});

const CatalogSelect: FC<CatalogSelectProps> = ({
  className,
  disabled,
  value: valueProp,
  selectedId,
  onChange,
  showNullItem = false,
  nullItemId = -1,
  nullItemLabel = 'None',
  placeholder = '- Select Catalog -',
  label = 'Catalog',
  NamesProps,
  valuePersistence = false,
  ...other
}) => {
  const classes = useStyles();
  const notify = useSnackbar();
  const { data, loading, error } = useCatalogNamesQuery();
  const nullItem = { id: nullItemId, name: nullItemLabel };
  const catalogs = useMemo(() => data?.catalogs || [], [data]);
  const options = showNullItem ? [nullItem, ...catalogs] : catalogs;
  const idIndex = options.reduce<Record<string, TItem>>((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
  const getValue = () => {
    if (valueProp) return valueProp;
    if (selectedId && idIndex[selectedId]) return idIndex[selectedId];
  };

  const value = getValue();

  const [open, setOpen] = React.useState(false);

  const handleInputClick = React.useCallback(() => setOpen(true), []);

  const handleClose = React.useCallback(() => setOpen(false), []);

  const userEmail = JSON.stringify(
    useSelector((state: RootState) => state.user.data?.email)
  );
  const userId = useSelector((state: RootState) => state.user.data?.id);

  const catalogSelector = JSON.parse(localStorage.getItem('catalogSelector') || '[]');

  const isUsersCatalogSaved = !!catalogSelector.find(
    (userCatalog) => userCatalog[userEmail]
  );

  const catalogSelectorValue = isUsersCatalogSaved
    ? catalogSelector.find((userCatalog) => userCatalog[userEmail])[userEmail]
    : '';

  const handleChange = useCallback(
    (catalog: IdName) => {
      if (!disabled) {
        setOpen(false);

        onChange && onChange(idIndex[catalog.id]);
      }

      if (valuePersistence) {
        if (isUsersCatalogSaved) {
          catalogSelector.find((userCatalog) => userCatalog[userEmail])[
            userEmail
          ] = catalog;
        } else {
          catalogSelector.push({ [userEmail]: catalog });
        }

        localStorage.setItem('catalogSelector', JSON.stringify(catalogSelector));
      }
    },
    [
      catalogSelector,
      disabled,
      idIndex,
      isUsersCatalogSaved,
      onChange,
      userEmail,
      valuePersistence,
    ]
  );

  useEffect(() => {
    if (error) {
      notify.enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error' });
    }
  }, [notify, error]);
  useEffect(() => {
    if (error) {
      notify.enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error' });
    }
  }, [notify, error]);

  const initialHandleChange = () => {
    if (valuePersistence) {
      if (
        data &&
        ((isUsersCatalogSaved && catalogs.length > 0) || catalogs.length === 1)
      ) {
        const name = {
          id: catalogSelectorValue['id'] || catalogs[0]['id'],
          name: catalogSelectorValue['name'] || catalogs[0]['name'],
        };
        handleChange(name);
      }
    }
  };

  useEffect(initialHandleChange, [data, valuePersistence]);

  useEffect(() => {
    if (catalogs.length && !value) {
      const catalogToSelect =
        catalogs.find((catalog) => userId === catalog.userId) || catalogs[0];
      handleChange({
        id: catalogToSelect.id.toString(),
        name: catalogToSelect.name,
      });
    }
  }, [catalogs, catalogs.length, handleChange, userId, value]);

  return (
    <Select
      placeholder={placeholder}
      error={!!error}
      disabled={disabled || loading || !!error}
      title={label}
      className={clsx(classes.root, className)}
      open={open}
      text={value?.name || ''}
      onClose={handleClose}
      onInputClick={handleInputClick}
      {...other}
    >
      <Names
        flexSearch
        loading={loading}
        names={options.map(adapter)}
        activeNames={value ? [value].map(adapter) : []}
        handleChange={handleChange}
        {...NamesProps}
      />
    </Select>
  );
};

export default CatalogSelect;
