import validateEmail from 'common/utils/validateEmail';
import { IApproverData } from 'modules/splitSheet/redux/model';

// ACTION TYPES
interface IAddReviewerAction {
  type: '@SPLITSHEET/ADD_REVIEWER';
}
interface IDeleteReviewerAction {
  type: '@SPLITSHEET/DELETE_REVIEWER';
  payload: { id: string };
}
interface ISetReviewerNameAction {
  type: '@SPLITSHEET/SET_REVIEWER_NAME';
  payload: { id: string; name: string };
}
interface ISetReviewerEmailAction {
  type: '@SPLITSHEET/SET_REVIEWER_EMAIL';
  payload: { id: string; email: { address: string; isValid: boolean } };
}
interface ISetReviewerCanViewAction {
  type: '@SPLITSHEET/SET_REVIEWER_CAN_VIEW_ALL';
  payload: { id: string; canViewAll: boolean };
}
interface ISetReviewerTrackingApproverAction {
  type: '@SPLITSHEET/SET_REVIEWER_TRACKING_ID';
  payload: { id: string; trackedApprover: IApproverData | null };
}

export type IReviewerAction =
  | IAddReviewerAction
  | IDeleteReviewerAction
  | ISetReviewerNameAction
  | ISetReviewerEmailAction
  | ISetReviewerCanViewAction
  | ISetReviewerTrackingApproverAction;

// ACTIONS
export const addReviewerAction = (): IAddReviewerAction => ({
  type: '@SPLITSHEET/ADD_REVIEWER',
});
export const deleteReviewerAction = (id: string): IDeleteReviewerAction => ({
  type: '@SPLITSHEET/DELETE_REVIEWER',
  payload: { id },
});
export const setReviewerNameAction = (
  id: string,
  name: string
): ISetReviewerNameAction => ({
  type: '@SPLITSHEET/SET_REVIEWER_NAME',
  payload: { id, name },
});
export const setReviewerEmailAction = (
  id: string,
  email: string
): ISetReviewerEmailAction => ({
  type: '@SPLITSHEET/SET_REVIEWER_EMAIL',
  payload: {
    id,
    email: { address: email, isValid: email ? validateEmail(email) : true },
  },
});
export const setReviewerCanViewAllAction = (
  id: string,
  canViewAll: boolean
): ISetReviewerCanViewAction => ({
  type: '@SPLITSHEET/SET_REVIEWER_CAN_VIEW_ALL',
  payload: { id, canViewAll },
});
export const setReviewerTrackingApproverAction = (
  id: string,
  trackedApprover: IApproverData | null
): ISetReviewerTrackingApproverAction => ({
  type: '@SPLITSHEET/SET_REVIEWER_TRACKING_ID',
  payload: { id, trackedApprover },
});
