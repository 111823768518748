import DriveItem from 'redux/selectedFile/interfaces/driveItem';
import { isGqlSplitSheet } from 'common/api/utils';
import { ModalConfigActionTypes, modalOpen } from 'redux/modal/modalActions';

const createContract = ({ masterId, catalogId, contracts }: DriveItem) => {
  let hasSplitsheet = false;
  contracts?.forEach((contract) => {
    if (isGqlSplitSheet(contract)) hasSplitsheet = true;
  });
  return (
    !!masterId &&
    !!catalogId &&
    modalOpen({
      type: ModalConfigActionTypes.CREATE_CONTRACT,
      data: { masterId, catalogId, disableSplitsheet: hasSplitsheet },
    })
  );
};

export default createContract;
