import { SimpleAsset } from 'common/interfaces/simpleAsset';
import { ContentType } from 'common/api/models';
import updateBase from 'common/utils/filename/updateBase';
import { FileItem } from 'common/services/downloadServices/interfaces/fileItem';

export default function getMasterFilesFileItems(assets: SimpleAsset[]): FileItem[] {
  return assets.reduce((acc, asset) => {
    if (asset?.file?.media_url) {
      const typeName =
        asset.type_id === ContentType.MASTER_FILE_CLEAN ? 'Clean' : 'Explicit';
      return acc.concat({
        title: updateBase(asset.title, (base) => `${base} (${typeName})`),
        url: asset.file.media_url,
      });
    }

    return acc;
  }, [] as FileItem[]);
}
