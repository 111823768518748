import PlayerState from 'redux/player/interfaces/playerState';
import INIT_PLAYER_STATE from 'redux/player/configs/initPlayerState';
import { PlayerActions, PlayerActionTypes } from 'redux/player/playerActions';
import MediaType from 'common/hooks/usePlayerInstance/enums/mediaType';

const playerReducer = (
  state: PlayerState = INIT_PLAYER_STATE,
  action: PlayerActions
): PlayerState => {
  const actions = PlayerActionTypes;

  switch (action.type) {
    case actions.SetPlay:
      return {
        ...state,
        playingAssetId: action.payload.value,
      };
    case actions.SetSharedSource:
      return {
        ...state,
        sharedSource: action.payload.value,
      };
    case PlayerActionTypes.SetPlayerClosed:
      return {
        ...state,
        activeAssetId: null,
        activeTullyBeatId: null,
        activeImportedBeatId: null,
        activeSharedAssetId: null,
        playingAssetId: null,
      };
    case actions.SetActiveAssetId:
      return {
        ...state,
        activeMediaType: MediaType.Asset,
        activeAssetId: action.payload.value,
        activeTullyBeatId: null,
        activeImportedBeatId: null,
        activeSharedAssetId: null,
      };
    case actions.SetActiveTullyBeatId:
      return {
        ...state,
        activeMediaType: MediaType.TullyBeat,
        activeTullyBeatId: action.payload.value,
        activeAssetId: null,
        activeImportedBeatId: null,
        activeSharedAssetId: null,
      };
    case actions.SetActiveSharedId:
      return {
        ...state,
        activeMediaType: MediaType.Shared,
        activeTullyBeatId: null,
        activeAssetId: null,
        activeImportedBeatId: null,
        activeSharedAssetId: action.payload.value,
      };
    case actions.SetActiveImportedBeatId:
      return {
        ...state,
        activeMediaType: MediaType.ImportedBeat,
        activeTullyBeatId: null,
        activeAssetId: null,
        activeSharedAssetId: null,
        activeImportedBeatId: action.payload.value,
      };
    case actions.SetPlayList:
      return {
        ...state,
        playList: action.payload.value,
      };
    default:
      return state;
  }
};

export default playerReducer;
