import constructPath from 'api/utils/constructPath';
import DropsPaths from 'api/drops/enums/dropsPaths';
import POST from 'common/services/httpServices/post';
import { Asset } from 'common/api/models';

interface CreateDropSubmissionInput {
  trackTitle: string;
  fileId: number;
  catalogId: number;
}

const createDropSubmission: (
  input: CreateDropSubmissionInput,
  params: {
    dropId: number;
  }
) => Promise<Asset> = async (input, params) => {
  const { dropId } = params;
  const { trackTitle, fileId, catalogId } = input;
  const endpoint = constructPath(DropsPaths.CreateSubmission, {
    dropId: dropId.toString(),
  });
  const requestBody = JSON.stringify({
    catalog: catalogId,
    file: fileId,
    title: trackTitle,
    type: 24,
  });
  return await POST(endpoint, requestBody).then<Asset>((res) => res.json());
};

export default createDropSubmission;
