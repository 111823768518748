import ShareDialogState from '../interfaces/shareDialogState';
import ShareDialogValidation from '../interfaces/shareDialogValidation';
import validateEmail from 'common/utils/validateEmail';

const validateSharingEmail = (state: ShareDialogState): boolean => {
  if (state.recipient.userId) return true;

  return state.recipient.email && state.recipient.email.length > 0
    ? validateEmail(state.recipient.email)
    : false;
};

const validateSharingData = (state: ShareDialogState): ShareDialogValidation => {
  return {
    email: validateSharingEmail(state),
    expiration: state.expiration >= 0,
  };
};

export default validateSharingData;
