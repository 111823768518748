import FiltersState from 'redux/filters/interfaces/filtersState';
import { DRIVE_FILTERS_STORAGE_KEY, VERSION } from 'redux/filters/configs/configs';

const saveFilters = (state: FiltersState) => {
  try {
    const container = JSON.stringify({
      version: VERSION,
      data: state,
    });

    localStorage.setItem(DRIVE_FILTERS_STORAGE_KEY, container);
  } catch (error) {
    // FIXME: handle error;
  }
};

export default saveFilters;
