import React from 'react';
import { PlusIcon } from 'common/icons/system';
import { MenuList, MenuItem, ListItemIcon } from '@material-ui/core';
import Typography from 'uikit/typography/Typography';
import PartyRole from 'common/models/PartyRole';

type RolesProps = {
  onSelect: (role: PartyRole) => void;
  onCreateRequest: () => void;
};

const Roles: React.FC<RolesProps> = ({ onSelect, onCreateRequest }) => {
  return (
    <MenuList>
      <MenuItem onClick={() => onSelect(PartyRole.Songwriter)}>Songwriter</MenuItem>
      <MenuItem onClick={() => onSelect(PartyRole.Producer)}>Producer</MenuItem>
      <MenuItem onClick={() => onSelect(PartyRole.CoProducer)}>Co Producer</MenuItem>
      <MenuItem onClick={() => onSelect(PartyRole.Engineer)}>Engineer</MenuItem>
      <MenuItem onClick={onCreateRequest}>
        <ListItemIcon>
          <PlusIcon color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit" color="secondary">
          Add Role
        </Typography>
      </MenuItem>
    </MenuList>
  );
};

export default Roles;
