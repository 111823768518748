import * as React from 'react';
import { ApolloProvider as Provider } from '@apollo/client';

import { usePersistClient } from 'apollo/hooks/usePersistClient';
import Preloader from 'uikit/preloader/Preloader';

const ApolloProvider: React.FC = ({ children }) => {
  const client = usePersistClient();

  if (!client) {
    return <Preloader fillViewport />;
  }

  return <Provider client={client}>{children}</Provider>;
};

export default ApolloProvider;
