import { AssetType } from 'common/api/models';
import UploadingEntry from 'redux/fileUpload/interfaces/uploadingEntry';

export const createEntry = (file: File, typeId: AssetType): UploadingEntry => ({
  typeId,
  file,
  progress: 0,
  loaded: 0,
  total: file.size,
  completed: false,
  result: null,
});
