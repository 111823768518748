import TransferState from 'redux/fileUpload/types/transferState';
import { Dispatch } from 'redux';
import { RootState } from 'redux/root-reducer';
import * as fileAction from 'redux/fileUpload/fileUploadActions';
import { setBeatsTrue } from 'redux/refetch/refetchActions';
import { AttachObjectType, ContentType } from 'common/api/models';
import isProjectState from 'redux/fileUpload/utils/isProjectState';
import { promiseQueue } from 'common/utils/promiseQueue';
import apolloClient from 'apollo/client';
import {
  GetMasterAssetsDocument,
  GetMasterAssetsQuery,
  GetMasterAssetsQueryVariables,
  GetNewImportedBeatDocument,
  GetNewImportedBeatQuery,
  GetNewImportedBeatQueryVariables,
  GetProjectAssetsDocument,
  GetProjectAssetsQuery,
  GetProjectAssetsQueryVariables,
} from 'generated/graphql';
import MasterAssetState from 'redux/fileUpload/interfaces/masterAssetState';
import CommonAsset from 'redux/fileUpload/interfaces/commonAsset';
import apiShareService from 'api/share/apiShareService';
import ShareStorageService from 'modules/sharing/api/ShareStorageService';
import { NO_VALUE } from 'common/api/Constants';
import Catalog from 'common/api/Catalog';
import ProjectState from 'redux/fileUpload/interfaces/projectState';
import BeatsState from 'redux/fileUpload/interfaces/beatsState';
import apiCatalogsService from 'api/catalogs/apiCatalogsService';

type FileUploadFn<T> = {
  (params: {
    share: RootState['share'];
    state: T;
    file: File;
    index: number;
    onProgress: (event: ProgressEvent) => void;
    dispatch: Dispatch;
  }): Promise<true | false>;
};

const getMasterUpdate = (masterId: number) => {
  return apolloClient.query<GetMasterAssetsQuery, GetMasterAssetsQueryVariables>({
    query: GetMasterAssetsDocument,
    variables: { masterId },
    fetchPolicy: 'network-only',
  });
};

const getProjectUpdate = (projectId: number) => {
  return apolloClient.query<GetProjectAssetsQuery, GetProjectAssetsQueryVariables>({
    query: GetProjectAssetsDocument,
    variables: { projectId },
    fetchPolicy: 'network-only',
  });
};

const getNewImportedBeat = (catalogId: number) => {
  return apolloClient.query<GetNewImportedBeatQuery, GetNewImportedBeatQueryVariables>({
    query: GetNewImportedBeatDocument,
    variables: { catalogId },
    fetchPolicy: 'network-only',
  });
};

const uploadMasterAsset: FileUploadFn<MasterAssetState> = async (params) => {
  const { share, state, file, index, onProgress, dispatch } = params;

  try {
    let asset: CommonAsset;
    if (state.isSharing && share.slug && share.refreshFn) {
      const { id } = await apiShareService.createAsset(share.slug, {
        title: file.name,
        type: state.typeId,
        master: state.masterId,
      });

      await ShareStorageService.addFile(
        share.slug,
        {
          file: file,
          asset_type: 'MASTER_ASSET',
          parent_id: state.masterId,
          attach: true,
          attach_object_id: id,
          attach_object_type: AttachObjectType.ASSET,
        },
        { onProgress }
      );

      await share.refreshFn();

      asset = {
        id,
        title: file.name,
        type: state.typeId,
        catalog: NO_VALUE.CATALOG_ID,
        master: state.masterId,
        project: null,
      };
    } else {
      asset = await Catalog.uploadMasterAsset({
        file,
        typeId: state.typeId,
        catalogId: state.catalogId,
        masterId: state.masterId,
        onProgress,
      });

      await getMasterUpdate(state.masterId);
    }

    dispatch(
      fileAction.setSuccess({
        index,
        value: asset,
      })
    );

    return true;
  } catch (error) {
    dispatch(
      fileAction.setError({
        index,
        value: error,
      })
    );

    return false;
  }
};

const uploadProjectAsset: FileUploadFn<ProjectState> = async (params) => {
  const { share, state, file, index, onProgress, dispatch } = params;

  try {
    let asset: CommonAsset;
    if (state.isSharing && share.slug && share.refreshFn) {
      const { id } = await apiShareService.createAsset(share.slug, {
        title: file.name,
        type: state.typeId,
        project: state.projectId,
      });

      await ShareStorageService.addFile(
        share.slug,
        {
          file: file,
          asset_type: 'PROJECT_ASSET',
          parent_id: state.projectId,
          attach: true,
          attach_object_id: id,
          attach_object_type: AttachObjectType.ASSET,
        },
        { onProgress }
      );

      await share.refreshFn();

      asset = {
        id,
        title: file.name,
        type: state.typeId,
        catalog: NO_VALUE.CATALOG_ID,
        master: null,
        project: state.projectId,
      };
    } else {
      asset = await Catalog.uploadProjectAsset({
        file,
        typeId: state.typeId,
        catalogId: state.catalogId,
        projectId: state.projectId,
        onProgress,
      });

      await getProjectUpdate(state.projectId);
    }

    dispatch(
      fileAction.setSuccess({
        index,
        value: asset,
      })
    );

    return true;
  } catch (error) {
    dispatch(
      fileAction.setError({
        index,
        value: error,
      })
    );

    return false;
  }
};

const uploadBeat: FileUploadFn<BeatsState> = async (params) => {
  const { share, state, file, index, onProgress, dispatch } = params;

  try {
    let asset: CommonAsset;
    if (state.isSharing && share.slug && share.refreshFn) {
      const { id } = await apiShareService.createAsset(share.slug, {
        title: file.name,
        type: state.typeId,
        catalog: state.catalogId,
      });

      await ShareStorageService.addFile(
        share.slug,
        {
          file: file,
          asset_type: 'BEATS',
          catalog_id: state.catalogId,
          attach: true,
          attach_object_id: id,
          attach_object_type: AttachObjectType.ASSET,
        },
        { onProgress }
      );

      await share.refreshFn();

      asset = {
        id,
        title: file.name,
        type: state.typeId,
        catalog: state.catalogId,
        master: null,
        project: null,
      };
    } else {
      asset = await apiCatalogsService.uploadBeat({
        onProgress,
        file,
        catalogId: state.catalogId,
      });

      await getNewImportedBeat(state.catalogId);
    }

    dispatch(
      fileAction.setSuccess({
        index,
        value: asset,
      })
    );

    dispatch(setBeatsTrue());

    return true;
  } catch (error) {
    dispatch(
      fileAction.setError({
        index,
        value: error,
      })
    );

    return false;
  }
};

async function uploadFile(
  state: TransferState,
  dispatch: Dispatch,
  share: RootState['share']
) {
  const onProgress = (index: number) => (event: ProgressEvent) => {
    dispatch(
      fileAction.setProgress({
        index,
        loaded: event.loaded,
        total: event.total,
      })
    );
  };

  function uploadFile(state: TransferState, file: File, index: number) {
    if (state.typeId === ContentType.BEATS_IMPORT) {
      return uploadBeat({
        share,
        state,
        file,
        index,
        onProgress: onProgress(index),
        dispatch,
      });
    }

    if (isProjectState(state)) {
      return uploadProjectAsset({
        share,
        state,
        file,
        index,
        onProgress: onProgress(index),
        dispatch,
      });
    }

    return uploadMasterAsset({
      share,
      state,
      file,
      index,
      onProgress: onProgress(index),
      dispatch,
    });
  }

  const operations = state.files.map((file, index) => () =>
    uploadFile(state, file, index)
  );

  const { queue, start } = promiseQueue({ operations });

  start();

  const results = await Promise.all(queue);

  dispatch(
    fileAction.end({
      loaded: results.reduce((acc, result) => (result ? acc + 1 : acc), 0),
    })
  );
}

export default uploadFile;
