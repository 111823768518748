import { NODE_NAME_MAX } from 'config/appConstants';
import { EdiphyProps } from 'uikit/ediphy/Ediphy';
import { useRef, useState, useEffect } from 'react';

export type EdiphyState = {
  isPropsSync: boolean;
  error: string;
  editing: boolean;
  value: string;
  prev: string;
};

const isTooLong = (name: string, maxLength: number) => name.length > maxLength;

const isEmpty = (name: string) => name === '';

const validate = (name: string, maxLength?: number) => {
  maxLength = maxLength || NODE_NAME_MAX;
  if (isTooLong(name, maxLength)) {
    return `Name cannot be longer than ${maxLength} characters`;
  }

  if (isEmpty(name)) {
    return 'Name cannot be empty';
  }

  return '';
};

export default function useEdiphyState(
  initValue: string,
  onChange?: (prev: string, next: string) => void,
  maxLength?: number
) {
  const onChangeRef = useRef(onChange);
  const mounted = useRef(false);
  const inputValue = useRef('');
  const isPropsSync = useRef(false);
  const [state, setState] = useState<EdiphyState>({
    isPropsSync: false,
    error: '',
    editing: false,
    value: initValue,
    prev: initValue,
  });

  isPropsSync.current = state.isPropsSync;

  const props: Omit<EdiphyProps, 'variant'> = {
    editing: state.editing,
    defaultValue: state.value,
    error: !!state.error,
    helperText: state.error,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.value;

      inputValue.current = name;

      const error = validate(name, maxLength);

      if (state.error !== error) {
        setState((prev) => ({
          ...prev,
          isPropsSync: false,
          error,
        }));
      }
    },
    onEditEnter: () => {
      inputValue.current = state.value;
      setState((prev) => ({
        ...prev,
        isPropsSync: false,
        prev: prev.value,
        editing: true,
      }));
    },
    onEditExit: (reason) => {
      const next = inputValue.current.trim();
      const isNotEmpty = next !== '';
      const isValid = !validate(next, maxLength);

      if (reason === 'keypress' && !isValid) {
        return;
      }

      if (isNotEmpty && isValid) {
        setState((prev) => ({
          ...prev,
          isPropsSync: false,
          error: '',
          editing: false,
          value: next,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          isPropsSync: false,
          error: '',
          editing: false,
        }));
      }
    },
  };

  useEffect(() => {
    if (mounted.current) {
      setState({
        isPropsSync: true,
        error: '',
        editing: false,
        value: initValue,
        prev: initValue,
      });
    }
  }, [initValue]);

  useEffect(() => {
    if (mounted.current && onChangeRef.current) {
      if (!isPropsSync.current) {
        onChangeRef.current(state.prev, state.value);
        inputValue.current = state.value;
      }
    } else {
      mounted.current = true;
    }
  }, [state.prev, state.value]);

  return [state, props] as const;
}
