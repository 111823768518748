import apiShareService from 'api/share/apiShareService';
import { Unpromise } from 'common/utilityTypes';
import { SharePermissions } from 'common/models/sharing';
import GuestContentPermissions from 'common/interfaces/guestContentPermissions';

const mapGuestPermissions = (
  input: Unpromise<ReturnType<typeof apiShareService.getGuestPerms>>
): GuestContentPermissions => {
  const guestPerms = {} as GuestContentPermissions;

  Object.keys(input).forEach((contentType) => {
    guestPerms[contentType] = {} as GuestContentPermissions[number];

    Object.keys(input[contentType]).forEach((itemId) => {
      guestPerms[contentType][itemId] = {
        VIEW: input[contentType][itemId].permissions.includes(SharePermissions.VIEW),
        DOWNLOAD: input[contentType][itemId].permissions.includes(
          SharePermissions.DOWNLOAD
        ),
        UPLOAD: input[contentType][itemId].permissions.includes(SharePermissions.UPLOAD),
        EDIT: input[contentType][itemId].permissions.includes(SharePermissions.EDIT),
        DELETE: input[contentType][itemId].permissions.includes(SharePermissions.DELETE),
      } as GuestContentPermissions[number][number];
    });
  });

  return guestPerms;
};

export default mapGuestPermissions;
