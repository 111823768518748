import React, { FC, ReactElement } from 'react';
import { DialogContent } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import { ContentType } from 'common/api/models';
import Preloader from 'uikit/preloader/Preloader';
import ShareFirstStep from './components/ShareFirstStep';
import ShareSecondStep from './components/ShareSecondStep';
import SharingItemInfo from './components/SharingItemInfo';
import { ShareContentProps } from './utils/shareContent';
import temporaryFilterPerms from './utils/temporaryFilterPerms';
import { SharePermissions } from 'common/models/sharing';
import { GetUmUsersDocument } from 'generated/graphql';
import { QueryName as GetGuestUserQueryName } from 'apollo/plugins/userManagement/queries/GetGuestUsers';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import InviteGuestDialog from 'uikit/inviteGuestDialog/InviteGuestDialog';
import * as ShareDialogActions from './store/shareDialogActions';
import ShareDialogButtons from './components/ShareDialogButtons';
import Button from 'uikit/button/Button';
import useShareResource from 'common/components/Modals/shareDialog/hooks/useShareResource';
import useShareAddUser from 'common/components/Modals/shareDialog/hooks/useShareAddUser';
import useShareDialog from 'common/components/Modals/shareDialog/hooks/useShareDialog';
import useInviteGuestStyles from 'common/components/Modals/shareDialog/hooks/useInviteGuestStyles';

const useStyles = makeStyles(
  () => ({
    subHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
  {
    name: 'ShareDialog',
  }
);

export interface ShareDialogProps {
  onClose: () => void;
  onShare: (params: ShareContentProps) => void;
  itemName: string;
  ids: number[];
  contentType: ContentType;
  perms: {
    guest: SharePermissions[];
    email: SharePermissions[];
  };
  parentId?: number;
}

const ShareDialog: FC<ShareDialogProps> = ({
  onClose,
  onShare,
  itemName,
  ids,
  contentType,
  perms,
  parentId,
}): ReactElement => {
  const { is2FAControlEnabled, isResourcesLoading } = useShareResource();
  const { openAddUserModal, closeAddUserModalHandler } = useShareAddUser();
  const {
    shareHandler,
    recipientChangeHandler,
    expirationChangeHandler,
    dispatch,
    state,
    copyShareLink,
  } = useShareDialog({ onShare, ids, contentType, onClose, parentId });

  const classes = useStyles();

  const renderContent = () => {
    const permsList = temporaryFilterPerms(
      !!state.recipient.userId ? perms.guest : perms.email
    );

    return (
      <>
        <DialogContent>
          <div className={classes.subHeader}>
            <SharingItemInfo itemName={itemName} />
            <Button color="secondary" variant="contained" onClick={copyShareLink}>
              Copy Link
            </Button>
          </div>
          <ShareFirstStep
            recipient={state.recipient || {}}
            message={state.message}
            isStateEmailValid={state.validation.email}
            onRecipientChange={recipientChangeHandler}
            onMessageChange={(message) =>
              dispatch(ShareDialogActions.setMessage({ value: message }))
            }
          />
          <ShareSecondStep
            permissionVariants={permsList}
            permissions={state.permissions}
            expiration={state.expiration}
            is2FAControlEnabled={is2FAControlEnabled}
            is2faEnabled={state.is2faEnabled}
            isExpirationValid={state.validation.expiration}
            onPermissionsChange={(permissions) =>
              dispatch(ShareDialogActions.setPermissions({ value: permissions }))
            }
            onExpirationChange={expirationChangeHandler}
            on2FAStatusChange={(status) =>
              dispatch(ShareDialogActions.set2FA({ value: status }))
            }
          />
        </DialogContent>
        <ShareDialogButtons onCancel={onClose} onShare={shareHandler} />
      </>
    );
  };

  return (
    <>
      <DialogHeader title="Share Files" onClose={onClose} />
      {isResourcesLoading ? <Preloader /> : renderContent()}
      <Dialog classes={useInviteGuestStyles()} open={openAddUserModal}>
        <InviteGuestDialog
          onClose={closeAddUserModalHandler}
          mutationOptions={{
            refetchQueries: [GetGuestUserQueryName, { query: GetUmUsersDocument }],
          }}
        />
      </Dialog>
    </>
  );
};

export default ShareDialog;
