import FileBrowserItemType from 'common/enums/fileBrowserItemType';
import DriveItem from 'redux/selectedFile/interfaces/driveItem';
import SharingItem from 'redux/selectedFile/interfaces/sharingItem';
import { InitArgs } from 'common/components/Modals/ModalMasterUpload/reducer';
import difference from 'lodash/difference';
import { MasterVersion, ContentType } from 'common/api/models';
import { NO_VALUE } from 'common/api/Constants';

const getModalMasterUploadArgs = (
  context: DriveItem | SharingItem,
  files?: File[] | null
): InitArgs | undefined => {
  if (context.__itemname === FileBrowserItemType.Sharing) {
    const { typeId, items } = context;
    const catalogId = NO_VALUE.CATALOG_ID;
    const masterId = context.parentId;
    const isUploadable = Boolean(catalogId && masterId && typeId);
    const missingVersions: MasterVersion[] = difference(
      [ContentType.MASTER_FILE_CLEAN, ContentType.MASTER_FILE_EXPLICIT],
      items.map((item) => item.type_id as MasterVersion)
    );
    const strictVersion = missingVersions.length === 2 ? undefined : missingVersions[0];

    if (isUploadable) {
      return {
        maxFiles: missingVersions.length,
        id: catalogId,
        name: '',
        masterName: 'Import master files',
        masterId: masterId,
        strictVersion,
        files,
        isSharing: true,
      };
    }
  }

  if (context.__itemname === FileBrowserItemType.Drive) {
    const { catalogId, masterId, typeId, items } = context;
    const isUploadable = Boolean(catalogId && masterId && typeId);
    const missingVersions: MasterVersion[] = difference(
      [ContentType.MASTER_FILE_CLEAN, ContentType.MASTER_FILE_EXPLICIT],
      items.map((item) => item.type_id as MasterVersion)
    );
    const strictVersion = missingVersions.length === 2 ? undefined : missingVersions[0];

    if (isUploadable && catalogId) {
      return {
        maxFiles: missingVersions.length,
        id: catalogId,
        name: '',
        masterName: 'Import master files',
        masterId: masterId,
        strictVersion,
        files,
      };
    }
  }
};

export default getModalMasterUploadArgs;
