import getMediaURL from './services/getMediaURL';
import getStorageLink from './services/getStorageLink';
import createFile from './services/createFile';
import getAttachFileUrl from './services/getAttachFileUrl';
import uploadFile from './services/uploadFile';
import addFile from './services/addFile';
import uploadAvatar from './services/uploadAvatar';
import uploadImage from './services/uploadImage';
import attachFileUrl from './services/attachFileUrl';

export default {
  addFile,
  attachFileUrl,
  createFile,
  getAttachFileUrl,
  getMediaURL,
  getStorageLink,
  uploadAvatar,
  uploadFile,
  uploadImage,
};
