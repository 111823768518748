import { LoginActions, LoginActionTypes } from 'redux/login/loginActions';

function loginStateReducer(
  state: { loggedIn: boolean } = { loggedIn: false },
  action: LoginActions
) {
  switch (action.type) {
    case LoginActionTypes.Logout:
      return { loggedIn: false };
    case LoginActionTypes.Login:
      return { loggedIn: true };
    default:
      return state;
  }
}

export default loginStateReducer;
