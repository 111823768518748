import SelectedFileState from 'redux/selectedFile/types/selectedFileState';
import FileBrowserItemType from 'common/enums/fileBrowserItemType';
import createHandler from 'redux/toolbar/utils/createHandler';
import uploadHandler from 'redux/toolbar/utils/uploadHandler';
import downloadHandler from 'redux/toolbar/services/download';
import shareHandler from 'redux/toolbar/services/share';
import uploadShare from 'redux/toolbar/utils/uploadShare';
import sharedDownload from 'redux/toolbar/services/sharedDownload';
import ToolbarOption from 'redux/toolbar/enums/toolbarOption';
import { ToolbarClickAction } from 'redux/toolbar/toolbarActions';

const getNextAction = (file: SelectedFileState, action: ToolbarClickAction) => {
  const { CREATE, UPLOAD, DOWNLOAD, SHARE, EDIT } = ToolbarOption;

  const driveHandlers = {
    [CREATE]: createHandler,
    [UPLOAD]: uploadHandler,
    [DOWNLOAD]: downloadHandler,
    [SHARE]: shareHandler,
    [EDIT]: (_) => false,
  };

  const sharedHandlers = {
    [CREATE]: (_) => false,
    [UPLOAD]: uploadShare,
    [DOWNLOAD]: sharedDownload,
    [SHARE]: (_) => false,
  };

  if (file?.__itemname === FileBrowserItemType.Drive) {
    const handler = driveHandlers[action.payload];
    return handler(file);
  }

  if (file?.__itemname === FileBrowserItemType.Sharing) {
    const handler = sharedHandlers[action.payload];

    return handler(file);
  }
};

export default getNextAction;
