import JSZip from 'jszip';
import { FileItem } from './interfaces/fileItem';
import getFileToSave from './getFileToSave';
import uniqueNames from 'common/utils/filename/uniqueNames';
import mergeArrays from 'lodash/zipWith';
import { FileToSave } from './interfaces/fileToSave';

const downloadFiles = async (
  files: FileItem[],
  archiveName: string
): Promise<boolean> => {
  try {
    const filesToSave = await Promise.all(files.map((file) => getFileToSave(file)));

    const names = uniqueNames(filesToSave.map((file) => file.name));
    const items = mergeArrays(
      names,
      filesToSave,
      (name, file): FileToSave => ({
        data: file.data,
        name,
      })
    );

    const zip = new JSZip();

    for (const f of items) {
      zip.file(f.name, f.data);
    }

    const archive = await zip.generateAsync({ type: 'blob' });
    saveAs(archive, archiveName);
    return true;
  } catch (error) {
    return false;
  }
};

export default downloadFiles;
