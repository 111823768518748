import React, { FC, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';

import MastersRoutes from 'modules/masters/navigation/enums/mastersRoutes';
import NotFoundPage from 'uikit/notFoundPage/NotFoundPage';
import Preloader from 'uikit/preloader/Preloader';

const LazyMastersPage = lazy(() => import('modules/masters/pages/MastersPage'));

const MastersRouter: FC = () => (
  <Switch>
    <Route path={MastersRoutes.Masters}>
      <Suspense fallback={<Preloader fillViewport />}>
        <LazyMastersPage />
      </Suspense>
    </Route>
    <Route>
      <NotFoundPage />
    </Route>
  </Switch>
);

export default MastersRouter;
