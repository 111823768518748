import * as yup from 'yup';

import * as vsh from 'common/formik/validationSchemas';

const getAddToCollectionValidationSchema = yup.object().shape({
  catalogId: vsh.catalogRequired,
  masterId: yup.number().required(),
  collectionType: vsh.collectionTypeRequired,
  collectionId: vsh.collectionIdRequired,
});

export default getAddToCollectionValidationSchema;
