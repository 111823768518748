import { Dispatch } from 'redux';
import * as User from 'redux/user/userActions';
import { getCurrentUser, getUmUsers } from 'apollo/queryFns';
import log from 'modules/app/utils/log';

const loadUser = async (dispatch: Dispatch) => {
  try {
    dispatch(User.requestUserData());
    const { data } = await getCurrentUser();

    const user = data?.getCurrentUser;
    if (user?.isAdmin) {
      // To speedup users table loading,
      // we execute GetUmUsers query if user is admin.
      getUmUsers({ fetchPolicy: 'network-only' }).catch(log.error);
    }

    dispatch(
      User.setUserData({
        data: data?.getCurrentUser,
      })
    );
    return true;
  } catch (error) {
    dispatch(User.setUserDataError(`${error}`));
    return false;
  }
};

export default loadUser;
