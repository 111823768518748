import SharePaths from 'api/share/enums/sharePaths';
import POST from 'common/services/httpServices/post';
import GetShareLinkProps from 'api/share/interfaces/getShareLinkProps';

const getShareLink: (props: GetShareLinkProps) => Promise<any> = async ({
  ids,
  type,
}) => {
  const res = await POST(
    SharePaths.ShareContent,
    JSON.stringify({
      expiration_type: 0,
      permissions: [1],
      recipients: [],
      ids,
      type,
    })
  );
  return res.json();
};

export default getShareLink;
