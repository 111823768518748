import ContractsPaths from 'api/contracts/enums/contractsPaths';
import constructPath from 'api/utils/constructPath';
import POST from 'common/services/httpServices/post';

const sendForApproval: (splitSheetID: string) => Promise<boolean> = async (
  splitSheetID
) => {
  const response = await POST(
    constructPath(ContractsPaths.MoveContractToReview, { ID: splitSheetID }),
    JSON.stringify({ state: 1 })
  );
  return response.ok;
};

export default sendForApproval;
