import * as React from 'react';
import { Radio as MuiRadio, RadioProps } from '@material-ui/core';
import RadioButtonIcon from 'uikit/radio/components/RadioButtonIcon';

export const defaultCheckedIcon = <RadioButtonIcon checked />;
export const defaultIcon = <RadioButtonIcon />;

export default React.forwardRef<HTMLButtonElement, RadioProps>((props, ref) => {
  const { size } = props;
  return (
    <MuiRadio
      {...props}
      icon={React.cloneElement(defaultIcon, {
        fontSize: size === 'small' ? 'small' : 'default',
      })}
      checkedIcon={React.cloneElement(defaultCheckedIcon, {
        fontSize: size === 'small' ? 'small' : 'default',
      })}
      ref={ref}
    />
  );
});
