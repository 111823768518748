// This is spectial component thet responsible
// to run user bootstrap services & hooks;
// The main idea is to have pure component
// to reduce number of hook re-render calls;
import * as React from 'react';
import useUserBootstrap from 'modules/app/hooks/useUserBootstrap';

const UserEffects = React.memo(() => {
  useUserBootstrap();
  return null;
});

export default UserEffects;
