import React, { FC, lazy, ReactElement, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { Routes } from 'navigation/enums/routes';
import Preloader from 'uikit/preloader/Preloader';
import useRedirectURN from 'modules/auth/hooks/useRedirectURN';

const LazySharing = lazy(() => import('modules/sharing/Sharing'));
const LazyLogin = lazy(() => import('modules/auth/login'));
const LazySignUpPromo = lazy(() => import('modules/auth/signUpPromo'));
const LazyResetPassword = lazy(() => import('modules/auth/reset-password'));
const LazyNewPassword = lazy(() => import('modules/auth/pages/NewPasswordPage'));
const LazyApproveSplitSheetPage = lazy(() =>
  import('modules/masters/pages/ApproveSplitSheetPage')
);
const LazyInviteRouter = lazy(() =>
  import('navigation/routes/inviteRouter/InviteRouter')
);
const LazyInviteDecline = lazy(() => import('modules/auth/pages/DeclineInvitePage'));

const PublicRouter: FC = (): ReactElement => {
  const redirectCallbackURN = useRedirectURN();
  return (
    <Switch>
      <Route path={[Routes.Shared, Routes.SharedInvite]}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazySharing />
        </Suspense>
      </Route>
      <Route path={Routes.Login}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyLogin />
        </Suspense>
      </Route>
      <Route path={Routes.SignUpPromo}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazySignUpPromo />
        </Suspense>
      </Route>
      <Route path={Routes.InviteConfirmationPage}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyInviteRouter />
        </Suspense>
      </Route>
      <Route path={Routes.ResetPassword} exact>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyResetPassword />
        </Suspense>
      </Route>
      <Route path={Routes.NewPassword}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyNewPassword />
        </Suspense>
      </Route>
      <Route path={Routes.ContractSignLink}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyApproveSplitSheetPage />
        </Suspense>
      </Route>
      <Route path={Routes.InviteDecline}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyInviteDecline />
        </Suspense>
      </Route>
      <Route>
        <Redirect
          to={`${Routes.Login}${
            redirectCallbackURN ? `?next=${redirectCallbackURN}` : ''
          }`}
        />
      </Route>
    </Switch>
  );
};

export default PublicRouter;
