import React from 'react';
import clsx from 'clsx';
import {
  ClickAwayListener,
  makeStyles,
  ButtonBaseProps,
  ButtonBase,
  WithStyles,
  Theme,
} from '@material-ui/core';
import { Overwrite } from 'common/utilityTypes';

const ICON_SLOT_WIDTH = 64;

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      borderRadius: theme.shape.borderRadius,
      '&$selected': {
        backgroundColor: theme.palette.grey[50],
      },
    },
    inner: {
      flex: '0 0 100%',
      maxWidth: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
    },
    gutters: {
      padding: theme.spacing(1),
      '&$dense': {
        padding: theme.spacing(0.5),
      },
    },
    dense: {},
    slot: {
      display: 'flex',
      alignItems: 'center',
      '&:not(:first-child)': {
        paddingLeft: theme.spacing(0.5),
      },
      '&:not(:last-child)': {
        paddingRight: theme.spacing(0.5),
      },
    },
    icon: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: ICON_SLOT_WIDTH,
      maxWidth: ICON_SLOT_WIDTH,
      boxSizing: 'content-box',
    },
    content: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
      minWidth: 0,
    },
    actions: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    notch: {
      opacity: 0,
      transition: theme.transitions.create(['opacity']),
      ...theme.mixins.activeLineVertical,
      '$selected &': {
        opacity: 1,
      },
    },
    selected: {},
  }),
  { name: 'FolderItemBase' }
);

type DivButtonProps = ButtonBaseProps<'div', { component?: 'div' }>;

export type FolderItemBaseProps = Overwrite<
  DivButtonProps,
  {
    classes?: Partial<WithStyles<keyof ReturnType<typeof useStyles>>['classes']>;
    disableGutters?: boolean;
    dense?: boolean;
    selected?: boolean;
  }
>;
/**
 * Base component for Drive folder items.
 * Main purpose is to make spacing consistent.
 * In general, is just item layout.
 */
const FolderItemBase: React.FC<FolderItemBaseProps> = ({
  children,
  className,
  dense = false,
  disableGutters = false,
  selected = false,
  classes: classesProp,
  onClick,
  ...other
}) => {
  const classes = useStyles({ classes: classesProp });
  const [icon, content, actions] = React.Children.toArray(children);

  const [selectedByClick, setSelectedByClick] = React.useState(false);
  const selectByClick = React.useCallback(() => setSelectedByClick(true), []);
  const unselectByClick = React.useCallback(() => setSelectedByClick(false), []);
  const handleClick = React.useCallback(
    (e) => {
      onClick && onClick(e);
      selectByClick();
    },
    [onClick, selectByClick]
  );

  return (
    <ClickAwayListener onClickAway={unselectByClick}>
      <ButtonBase
        component="div"
        onClick={handleClick}
        className={clsx(
          classes.root,
          {
            [classes.dense]: dense,
            [classes.gutters]: !disableGutters,
            [classes.selected]: selected || selectedByClick,
          },
          className
        )}
        {...other}
      >
        <div className={classes.inner}>
          <div className={clsx(classes.slot, classes.icon)}>{icon}</div>
          <div className={clsx(classes.slot, classes.content)}>{content}</div>
          {actions && (
            <div className={clsx(classes.slot, classes.actions)}>{actions}</div>
          )}
        </div>
        <span className={classes.notch} />
      </ButtonBase>
    </ClickAwayListener>
  );
};

export default FolderItemBase;
