import * as React from 'react';
import { SvgIcon, makeStyles, SvgIconProps, capitalize } from '@material-ui/core';

import { ReactComponent as AlbumSvg } from 'assets/content-types-balls/album.svg';
import { ReactComponent as MasterFolderSvg } from 'assets/content-types-balls/masterFolder.svg';
import { ReactComponent as BeatFolderSvg } from 'assets/content-types-balls/beatFolder.svg';
import { ReactComponent as ProjectFolderSvg } from 'assets/content-types-balls/projectFolder.svg';
import { ReactComponent as BeatBallSvg } from 'assets/content-types-balls/beat.svg';
import { ReactComponent as CatalogBallSvg } from 'assets/content-types-balls/catalog.svg';
import { ReactComponent as ContractBallSvg } from 'assets/content-types-balls/contract.svg';
import { ReactComponent as DocumentBallSvg } from 'assets/content-types-balls/document.svg';
import { ReactComponent as ImportsBallSvg } from 'assets/content-types-balls/imports.svg';
import { ReactComponent as LyricsBallSvg } from 'assets/content-types-balls/lyrics.svg';
import { ReactComponent as MasterBallSvg } from 'assets/content-types-balls/master.svg';
import { ReactComponent as MetadataBallSvg } from 'assets/content-types-balls/metadata.svg';
import { ReactComponent as MixesBallSvg } from 'assets/content-types-balls/mixes.svg';
import { ReactComponent as ProjectBallSvg } from 'assets/content-types-balls/project.svg';
import { ReactComponent as RecordingsBallSvg } from 'assets/content-types-balls/recordings.svg';
import { ReactComponent as StemsBallSvg } from 'assets/content-types-balls/stems.svg';
import { ReactComponent as Ra360BallSvg } from 'assets/content-types-balls/ra360.svg';
import { ReactComponent as TullyBallSvg } from 'assets/content-types-balls/tully.svg';
import { ReactComponent as CameraBallSvg } from 'assets/content-types-balls/camera.svg';
import { ReactComponent as FileBallSvg } from 'assets/content-types-balls/file.svg';

const colorPair = (back: string, front: string) => ({
  '& > path:first-child': {
    color: back,
    fill: 'currentColor',
  },
  '& > path:last-child, & > g:last-child path': {
    color: front,
    fill: 'currentColor',
  },
});

const useStyles = makeStyles(
  (theme) => ({
    backgroundDefault: colorPair(theme.palette.common.black, theme.palette.common.white),
    backgroundDark: colorPair(theme.palette.common.white, theme.palette.common.black),
    backgroundPrimary: colorPair(
      theme.palette.primary.main,
      theme.palette.primary.contrastText
    ),
    backgroundSecondary: colorPair(
      theme.palette.secondary.main,
      theme.palette.secondary.contrastText
    ),
    backgroundWarning: colorPair(
      theme.palette.warning.main,
      theme.palette.warning.contrastText
    ),
    backgroundError: colorPair(
      theme.palette.error.main,
      theme.palette.error.contrastText
    ),
  }),
  { name: 'BallSvgIcon' }
);

export type BallSvgIconProps = Omit<SvgIconProps, 'background'> & {
  background?: 'default' | 'dark' | 'primary' | 'secondary' | 'error' | 'warning';
};

function createBallIcon<T = SVGSVGElement>(
  name: string,
  icon: React.ElementType,
  defaultProps?: SvgIconProps
) {
  const Component = (props: BallSvgIconProps, ref: React.Ref<T>) => {
    const { background, ...svgIconProps } = props;
    const classes = useStyles();
    return (
      <SvgIcon
        classes={{
          ...(background
            ? {
                root: classes[`background${capitalize(background)}`],
              }
            : {}),
        }}
        component={icon}
        {...defaultProps}
        {...svgIconProps}
        ref={ref}
      />
    );
  };

  Component.displayName = name;

  return React.forwardRef<T, BallSvgIconProps>(Component);
}

export const AlbumIcon = createBallIcon('AlbumIcon', AlbumSvg);
export const BeatBallIcon = createBallIcon('BeatBallIcon', BeatBallSvg);
export const BeatFolderIcon = createBallIcon('BeatFolderIcon', BeatFolderSvg);
export const CameraBallIcon = createBallIcon('CameraBallIcon', CameraBallSvg);
export const CatalogBallIcon = createBallIcon('CatalogBallIcon', CatalogBallSvg);
export const ContractBallIcon = createBallIcon('ContractBallIcon', ContractBallSvg);
export const DocumentBallIcon = createBallIcon('DocumentBallIcon', DocumentBallSvg);
export const FileBallIcon = createBallIcon('FileBallIcon', FileBallSvg);
export const ImportsBallIcon = createBallIcon('ImportsBallIcon', ImportsBallSvg);
export const LyricsBallIcon = createBallIcon('LyricsBallIcon', LyricsBallSvg);
export const MasterBallIcon = createBallIcon('MasterBallIcon', MasterBallSvg);
export const MasterFolderIcon = createBallIcon('MasterFolderIcon', MasterFolderSvg);
export const MetadataBallIcon = createBallIcon('MetadataBallIcon', MetadataBallSvg);
export const MixesBallIcon = createBallIcon('MixesBallIcon', MixesBallSvg);
export const ProjectBallIcon = createBallIcon('ProjectBallIcon', ProjectBallSvg);
export const ProjectFolderIcon = createBallIcon('ProjectFolderIcon', ProjectFolderSvg);
export const RecordingsBallIcon = createBallIcon('RecordingsBallIcon', RecordingsBallSvg);
export const StemsBallIcon = createBallIcon('StemsBallIcon', StemsBallSvg);
export const Ra360BallIcon = createBallIcon('Ra360BallIcon', Ra360BallSvg);
export const TullyBallIcon = createBallIcon('TullyBallIcon', TullyBallSvg);
