import { AxiosRequestConfig } from 'axios';
import loadAccessToken from 'common/services/tokenServices/loadAccessToken';

const setAuthConfig = (initConfig?: AxiosRequestConfig): AxiosRequestConfig => {
  const token = loadAccessToken();
  const config = {
    ...initConfig,
  } as AxiosRequestConfig;
  if (token)
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  return config;
};

export default setAuthConfig;
