import { Routes } from 'navigation/enums/routes';
import { generatePath } from 'react-router';
import { createBrowserHistory } from 'history';

const appHistory = createBrowserHistory();

export type AppHistory = typeof appHistory;

export const navigator = {
  goMasters: (history: AppHistory = appHistory) => history.push(Routes.Masters),
  goResetPassword: (history: AppHistory = appHistory) =>
    history.push(Routes.ResetPassword),
  goNotFound: (history: AppHistory = appHistory) => history.push(Routes.NotFound),
  um: {
    goUserProfile: (userId: string | number, history: AppHistory = appHistory) => {
      history.push(
        generatePath(Routes.ManagementUserDetails, {
          userId,
        })
      );
    },
    goEditRole: (roleId: string | number, history: AppHistory = appHistory) => {
      history.push(generatePath(Routes.ManagementEditRole, { roleId }));
    },
    goCopyRole: (roleId: string | number, history: AppHistory = appHistory) => {
      history.push(generatePath(Routes.ManagementCopyRole, { roleId }));
    },
    goNewRole: (history: AppHistory = appHistory) => {
      history.push(Routes.ManagementNewRole);
    },
  },
} as const;

export default appHistory;
