import React from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import ValidatedValues from '../types/validatedValues';
import { modalClose } from 'redux/modal/modalActions';
import {
  useDropCollectionCoverArtMutation,
  useUpdateAlbumAndCoverMutation,
  useUpdateAlbumMutation,
} from 'generated/graphql';

const useEditAlbumSubmit = () => {
  const notify = useSnackbar();
  const dispatch = useDispatch();
  const [updateData] = useUpdateAlbumMutation();
  const [updateDataAndArtwork] = useUpdateAlbumAndCoverMutation();
  const [dropArtworkCover] = useDropCollectionCoverArtMutation();

  return React.useCallback(
    async (values: ValidatedValues) => {
      const variables = {
        id: values.albumId,
        input: {
          title: values.title,
          artist_name: values.artist,
          catalog_id: values.catalogId,
          year: values.year,
          collection_type: values.collectionType,
          master_positions: values.trackOrder,
        },
      };

      try {
        const isArtChanged = values.artworkFileName !== values.artworkFileNamePrev;
        if (!isArtChanged) {
          await updateData({ variables });
        } else if (values.artworkFileName) {
          await updateDataAndArtwork({
            variables: {
              ...variables,
              fileName: values.artworkFileName,
              attach: true,
            },
          });
        } else {
          await dropArtworkCover({
            variables: {
              id: variables.id,
            },
          });
        }

        notify.enqueueSnackbar('Updated successfully', { variant: 'success' });
        dispatch(modalClose());
      } catch (error) {
        notify.enqueueSnackbar(`Error: ${error}`, { variant: 'error' });
      }
    },
    [notify, dispatch, updateData, updateDataAndArtwork, dropArtworkCover]
  );
};

export default useEditAlbumSubmit;
