enum SharePaths {
  AttachFile = 'share/access/slug/attach/',
  CreateAsset = 'share/access/slug/create_asset/',
  GetGuestPerms = 'share/access/normalized_list/',
  GetSharedAssets = 'share/assetId/',
  GetSharedInviteAssets = 'share/invite/inviteId/',
  GetSharedVerificationCode = 'share/id/two_factor/',
  GetStorageLink = 'share/access/slug/upload/',
  ShareContent = 'share/',
  VerifySharedVerificationCode = 'share/id/two_factor_verify/',
}

export default SharePaths;
