import * as React from 'react';
import {
  MenuItem,
  MenuItemProps,
  ListItemIcon,
  makeStyles,
  TypographyProps,
} from '@material-ui/core';
import clsx from 'clsx';

import Typography from 'uikit/typography/Typography';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '&:hover, &.Mui-focusVisible': {
        '& $icon, & .MuiListItemIcon-root': {
          color: theme.palette.error.main,
        },
      },
    },
  }),
  { name: 'DotsMenuItem' }
);

export interface DotsMenuItem extends MenuItemProps {
  icon: React.ReactElement;
  label: string | React.ReactElement;
  danger?: boolean;
  labelVariant?: TypographyProps['variant'];
  labelStyles?: string;
  disabled?: boolean;
}

const DotsMenuItem = React.forwardRef<HTMLLIElement, DotsMenuItem>(function DotsMenuItem(
  props,
  ref
) {
  const classes = useStyles();

  const {
    className,
    icon,
    label,
    danger,
    labelVariant = 'inherit',
    labelStyles,
    disabled,
    ...other
  } = props;

  return (
    <MenuItem
      className={clsx(className, {
        [classes.root]: danger,
      })}
      disabled={disabled}
      {...other}
      button
      ref={ref}
    >
      <ListItemIcon className="fill-current">{icon}</ListItemIcon>
      <Typography component="span" variant={labelVariant} noWrap className={labelStyles}>
        {label}
      </Typography>
    </MenuItem>
  );
});

export default DotsMenuItem;
