import apolloClient from 'apollo/client';
import {
  GetCurrentUserDocument,
  GetUmRolesDocument,
  GetCurrentUserQuery,
  GetUmRolesQuery,
  GetUmUsersQuery,
  GetUmUsersDocument,
} from 'generated/graphql';
import { QueryOptions } from '@apollo/client';

export function getCurrentUser(
  options?: Omit<QueryOptions<GetCurrentUserQuery>, 'query'>
) {
  return apolloClient.query<GetCurrentUserQuery>({
    ...options,
    query: GetCurrentUserDocument,
  });
}

export function getUmRoles(options?: Omit<QueryOptions<GetUmRolesQuery>, 'query'>) {
  return apolloClient.query<GetUmRolesQuery>({
    ...options,
    query: GetUmRolesDocument,
  });
}

export function getUmUsers(options?: Omit<QueryOptions<GetUmUsersQuery>, 'query'>) {
  return apolloClient.query<GetUmUsersQuery>({
    ...options,
    query: GetUmUsersDocument,
  });
}
