import React, { FC, useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Avatar, Tooltip } from '@material-ui/core';

import Typography from 'uikit/typography/Typography';
import { UserFieldsFragment } from 'generated/graphql';

import { makeStyles } from '@material-ui/core/styles';
import noop from 'common/utils/noop';

const useStyles = makeStyles(
  (theme) => ({
    avatarContainer: {
      flexShrink: 0,
      height: 44,
      width: 44,
      overflow: 'hidden',
    },
    avatar: {
      width: '100%',
      height: '100%',
    },
    userInfo: {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      display: 'flex',
      width: '100%',
      ...theme.mixins.userInfoMinHeight,
      padding: theme.spacing(2),
    },
    userTextContainer: {
      marginLeft: theme.spacing(2),
      overflow: 'hidden',
    },
    userText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
    },
  }),
  {
    name: 'UserInfo',
  }
);

interface UserInfoProps {
  user?: UserFieldsFragment;
  onClick?: () => void;
  isOpened: boolean;
  isLoading: boolean;
}

const UserInfo: FC<UserInfoProps> = ({ user, isOpened, isLoading, onClick = noop }) => {
  const classes = useStyles();
  const userText = useMemo(
    () => user?.roles.map((item) => item.name).join(' / ') || user?.email,
    [user]
  );

  return (
    <div className={classes.userInfo} onClick={onClick}>
      {isLoading ? (
        <Skeleton variant={'circle'} width={44} height={44} />
      ) : (
        <div className={classes.avatarContainer}>
          <Avatar src={user?.profile?.avatar || undefined} className={classes.avatar} />
        </div>
      )}
      {isOpened && (
        <div className={classes.userTextContainer}>
          {isLoading ? (
            <React.Fragment>
              <Skeleton width={130} />
              <Skeleton width={130} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography variant={'subtitle1'} className={classes.userText}>
                {user?.firstName} {user?.lastName}
              </Typography>
              <Tooltip title={userText || ''} placement="bottom">
                <Typography variant={'body2'} className={classes.userText} noWrap>
                  {userText}
                </Typography>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default UserInfo;
