import * as React from 'react';
import * as Modal from 'redux/modal/modalActions';
import { useGetUploadedContractQuery } from 'generated/graphql';
import { notify } from 'common/components/notifMessages/Notify';
import { useDispatch } from 'react-redux';
import { toUploadedContractLocalState } from 'common/utils/toUploadedContractLocalState';
import ContractAgreementFormDialog from 'common/components/Modals/contractAgreementFormDialog/ContractAgreementFormDialog';
import Preloader from 'uikit/preloader/Preloader';

interface UploadedContractEditDialogProps {
  id: number;
}

const UploadedContractEditDialog: React.FC<UploadedContractEditDialogProps> = (props) => {
  const dispatch = useDispatch();

  const { loading, data, error } = useGetUploadedContractQuery({
    variables: { id: props.id },
  });

  const onClose = React.useCallback(() => dispatch(Modal.modalClose()), [dispatch]);

  if (loading) {
    return <Preloader />;
  }

  if (error || !data?.getContract) {
    notify.error('Failed to load contract');
    dispatch(Modal.modalClose());
    return null;
  }

  const summary = toUploadedContractLocalState(data.getContract);

  return <ContractAgreementFormDialog summary={summary} onClose={onClose} />;
};

export interface ModalConfig<T> {
  type: T;
  data: {
    props: UploadedContractEditDialogProps;
  };
}

export default UploadedContractEditDialog;
