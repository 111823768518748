import createMuiPalette from '@material-ui/core/styles/createPalette';
import { fade } from '@material-ui/core';

function createPalette() {
  const primary = '#15d290';
  const blue = '#3d9be9';
  const pink = '#e54c8d';
  const yellow = '#fed12e';
  const error = '#ff0030';
  const white = '#ffffff';
  const black = '#201d1a';
  const gray900 = '#252730';
  const gray800 = '#3f4150';
  const gray300 = '#8083A7';
  const gray200 = '#b8c8cc';
  const gray100 = '#edecec';
  const gray50 = '#f8f8f8';
  const gray200A50 = fade(gray200, 0.5);

  const palette = createMuiPalette({
    type: 'light',
    text: {
      primary: black,
      secondary: gray800,
      disabled: gray200A50,
    },
    primary: {
      main: primary,
      contrastText: white,
    },
    secondary: {
      main: blue,
      contrastText: white,
    },
    warning: {
      main: pink,
      contrastText: white,
    },
    error: {
      main: error,
      contrastText: white,
    },
    common: {
      black,
    },
    background: {
      default: white,
    },
    action: {
      hover: fade(black, 0.04),
      hoverOpacity: 0.04,
      active: gray200,
      disabled: gray200A50,
      disabledBackground: fade(gray200, 0.12),
    },
    divider: gray100,
  });

  palette.grey[900] = gray900;
  palette.grey[800] = gray800;
  palette.grey[300] = gray300;
  palette.grey[200] = gray200;
  palette.grey[100] = gray100;
  palette.grey[50] = gray50;

  const statusColor = {
    0: palette.grey[200],
    1: yellow,
    2: palette.primary.main,
    3: palette.error.main,
  };

  palette.status = statusColor;

  return palette;
}

export default createPalette();
