import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';

import { FolderItemProps } from 'common/components/FolderItem/FolderItem';
import Track from 'common/formik/Track';

export interface DraggableTrackProps extends FolderItemProps {
  id: string;
  index: number;
}

const useDraggableStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[50],
    },
    track: {
      transition: 'none',
      cursor: 'grab',
    },
  }),
  { name: 'DraggableTrack' }
);

const DraggableTrack = React.memo((props: DraggableTrackProps) => {
  const { id, index, ...trackProps } = props;
  const classes = useDraggableStyles();

  return (
    <Draggable draggableId={id} index={index} key={id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.root}
        >
          <Track
            {...trackProps}
            className={classes.track}
            dragging={snapshot.isDragging}
          />
        </div>
      )}
    </Draggable>
  );
});

export default DraggableTrack;
