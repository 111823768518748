import { DialogActions, DialogContent } from '@material-ui/core';
import Button from 'uikit/button/Button';
import React, { useState } from 'react';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import Typography from 'uikit/typography/Typography';
import Preloader from 'uikit/preloader/Preloader';
import deactivateUser from 'api/users/services/deactivateUser';
import { notify } from 'common/components/notifMessages/Notify';
import useLogOut from 'common/hooks/useLogOut/useLogOut';
import { useGetCurrentUserQuery } from 'generated/graphql';

type Props = {
  onClose: () => void;
};

const DeleteAccountModal: React.FC<Props> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data: dataUser } = useGetCurrentUserQuery();
  const userEmail = dataUser?.getCurrentUser.email;
  const { logOutHandler } = useLogOut();
  const onDelete = () => {
    setIsLoading(true);
    if (userEmail) {
      deactivateUser(userEmail)
        .then(() => {
          logOutHandler();
          onClose();
        })
        .catch((error) => {
          notify.enqueueSnackbar(`Error: ${error.response.data}`, {
            variant: 'error',
          });
          onClose();
        });
    } else {
      notify.enqueueSnackbar(`Error: Can't get your account email`, {
        variant: 'error',
      });
    }
  };
  return (
    <>
      <DialogHeader onClose={onClose} title="Warning" />
      <DialogContent>
        {isLoading ? (
          <Preloader />
        ) : (
          <Typography variant="body1">
            Are you sure you want to delete your account? Your account and all User data
            will be deleted within 30 days of this request.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant={'outlined'}
          disabled={isLoading}
          dialogActionBase
        >
          Cancel
        </Button>
        <Button
          onClick={onDelete}
          variant={'outlined'}
          color={'error'}
          disabled={isLoading}
          dialogActionBase
        >
          Delete Account
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteAccountModal;
