import React, { ReactElement } from 'react';
import { DialogActions, DialogContent, Box, Grid, makeStyles } from '@material-ui/core';
import { InviteInputSchema } from 'modules/users/components/inviteDialog/schemas/InviteInputSchema';
import { FormikConfig, Formik, Form } from 'formik';
import { NonNullObj } from 'common/utilityTypes';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import FormicTextField from 'common/formik/FormicTextField';
import HiddenSubmit from 'common/formik/HiddenSubmit';
import Preloader from 'uikit/preloader/Preloader';
import Typography from 'uikit/typography/Typography';
import Submit from 'common/formik/SubmitButton';
import Cancel from 'common/formik/Cancel';
import InviteUserValues from 'common/interfaces/inviteUserValues';

const useStyles = makeStyles(
  () => ({
    form: {
      overflow: 'hidden',
    },
  }),
  { name: 'InviteDialog' }
);

export type InviteDialogProps<Values extends InviteUserValues = InviteUserValues> = Omit<
  FormikConfig<Values>,
  'initialValues' | 'onSubmit'
> & {
  onSubmit: (values: NonNullObj<Values>) => Promise<void> | void;
  title: string;
  description: React.ReactNode;
  onClose: () => void;
  initialValues: Values;
  extraFields?: React.ReactNode;
  submitLabel?: string;
  loading?: boolean;
};

const InviteDialog = <T extends InviteUserValues = InviteUserValues>(
  props: InviteDialogProps<T>
): ReactElement => {
  const {
    initialValues,
    validationSchema = InviteInputSchema,
    title,
    description,
    submitLabel = 'Submit',
    extraFields,
    loading = false,
    onClose,
    onSubmit,
    ...formikProps
  } = props;

  const classes = useStyles();

  const handleSubmit = (values: T) => onSubmit(values as NonNullObj<T>);

  const renderContent = () => {
    if (loading) {
      return <Preloader />;
    }

    return (
      <>
        <DialogContent>
          <Box mb={2}>
            <Typography variant={'body2'}>{description}</Typography>
          </Box>
          <Form className={classes.form}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormicTextField
                  autoFocus
                  fullWidth
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormicTextField
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                />
              </Grid>
              <Grid item xs={12}>
                <FormicTextField
                  fullWidth
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                />
              </Grid>
              {extraFields}
            </Grid>
            <HiddenSubmit />
          </Form>
        </DialogContent>
        <DialogActions>
          <Cancel variant="outlined" onClick={onClose} dialogAction />
          <Submit
            variant="contained"
            color="primary"
            checkDirty
            submitOnClick
            dialogAction
          >
            {submitLabel}
          </Submit>
        </DialogActions>
      </>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      {...formikProps}
    >
      <>
        {/* NOTE: Please, don't remove fragment, Formik can accept only single child */}
        <DialogHeader onClose={onClose} title={title} />
        {renderContent()}
      </>
    </Formik>
  );
};

export default InviteDialog;
