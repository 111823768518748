import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';

const useStyles = makeStyles(
  (theme: Theme) => ({
    viewer: {
      width: '100%',
      height: '100%',
      '& #pdf-download': {
        display: 'none',
      },
    },
  }),
  { name: 'FilePreviewDialog' }
);

interface FilePreviewDialogProps {
  uri: string;
  title: string;
  onClose: () => void;
}

const FilePreviewDialog: React.FC<FilePreviewDialogProps> = ({ uri, title, onClose }) => {
  const classes = useStyles();
  const config = {
    header: { disableHeader: true },
  };

  return (
    <>
      <DialogHeader title={title} onClose={onClose} />

      <DocViewer
        config={config}
        className={classes.viewer}
        pluginRenderers={DocViewerRenderers}
        documents={[{ uri: uri }]}
      />
    </>
  );
};

export default FilePreviewDialog;
