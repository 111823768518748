import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { ReactComponent as TullyLogo } from 'assets/tully-logo-primary.svg';
import useIsMobileUi from 'common/hooks/useIsMobileUi';
import { MenuIcon } from 'common/icons/system';
import HeaderMenu from 'common/components/headerMenu/HeaderMenu';
import { IconButton } from 'uikit/iconButton/IconButton';

const useStyles = makeStyles(
  (theme) => ({
    nav: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...theme.mixins.headerHeight,
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0, 4),
        height: 80,
      },
    },
    menu: {
      display: 'none',
      '&$mobile': {
        display: 'block',
        color: theme.palette.text.primary,
      },
    },
    logo: {
      '&$mobile': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    mobile: {},
  }),
  {
    name: 'Header',
  }
);

interface HeaderProps {
  onOpenNav?: () => void;
  isHasMenu?: boolean;
}

const Header: React.FC<HeaderProps> = ({ onOpenNav, isHasMenu = true }): ReactElement => {
  const styles = useStyles();
  const isMobileUi = useIsMobileUi();

  const isMenuVisible = isHasMenu && !isMobileUi;

  return (
    <nav className={styles.nav}>
      {onOpenNav && (
        <IconButton
          onClick={onOpenNav}
          className={clsx([styles.menu, { [styles.mobile]: isMobileUi }])}
        >
          <MenuIcon />
        </IconButton>
      )}
      <TullyLogo className={clsx([styles.logo, { [styles.mobile]: isMobileUi }])} />
      {isMenuVisible && <HeaderMenu />}
    </nav>
  );
};

export default Header;
