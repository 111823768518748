import { Middleware } from 'redux';
import { RootState } from 'redux/root-reducer';
import isEndAction from 'redux/fileUpload/utils/isEndAction';
import * as fileAction from 'redux/fileUpload/fileUploadActions';

const fileUploadEndReset: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (isEndAction(action)) {
    setTimeout(() => store.dispatch(fileAction.reset()), 0);
  }

  return next(action);
};

export default fileUploadEndReset;
