import React from 'react';

import ITransferState from 'redux/fileUpload/interfaces/iTransferState';
import totalUploadSize from 'common/components/transferSnack/utils/totalUploadSize';
import totalLoaded from 'common/components/transferSnack/utils/totalLoaded';
import completedEntries from 'common/components/transferSnack/utils/completedEntries';
import getUploadingProgress from 'redux/fileUpload/utils/getUploadingProgress';
import { useRemainingUploadTime } from 'common/hooks/useRemainingUploadTime';
import UploadingSnack from 'common/components/transferSnack/components/UploadingSnack';
import formatRemaining from 'common/components/transferSnack/utils/formatRemaining';

const TransferSnackContent = (props: { data: ITransferState }) => {
  const { data } = props;
  const total = totalUploadSize(data);
  const loaded = totalLoaded(data);
  const finished = completedEntries(data);
  const filesCount = Math.max(data.entries.length - finished.length, 0);
  const percent = getUploadingProgress(loaded, total);
  const remaining = useRemainingUploadTime({
    total,
    loaded,
    ...(data.started && { started: data.started }),
  });

  return (
    <UploadingSnack
      progress={percent}
      filesCount={filesCount}
      remaining={formatRemaining(remaining)}
    />
  );
};

export default TransferSnackContent;
