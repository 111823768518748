import ext from './ext';

export default function getNewFilename(current: string, target: string) {
  const currentExt = ext(current);
  const targetExt = ext(target);

  if (currentExt && targetExt !== currentExt) {
    // We don't trying to fix ext, we just adding
    // original extension
    return `${target}.${currentExt}`;
  }

  return target;
}
