import MenuOption from 'redux/menu/enums/menuOption';

const MENU_OPTIONS_ARRAY = [
  MenuOption.ADD_TO_LP,
  MenuOption.ADD_TO_EP,
  MenuOption.LOCK,
  MenuOption.UNLOCK,
  MenuOption.SHARE,
  MenuOption.VIEW,
  MenuOption.EDIT,
  MenuOption.DOWNLOAD,
  MenuOption.UPLOAD,
  MenuOption.DISTRIBUTION,
  MenuOption.CONFIGURATE,
  MenuOption.DELETE,
  MenuOption.REMOVE,
] as const;

export default MENU_OPTIONS_ARRAY;
