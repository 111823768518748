import React from 'react';
import {
  TextField as MuiTextField,
  Theme,
  makeStyles,
  MenuItem,
  SelectProps as SelectPropsType,
} from '@material-ui/core';
import clsx from 'clsx';

import Typography from 'uikit/typography/Typography';
import DEFAULT_SELECTED_PROPS from 'uikit/textField/configs/defaultSelectedProps';
import TextFieldPropsExtended from 'common/types/textFieldPropsExtended';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&$hasMinWidth': {
      minWidth: 120,
    },
  },
  hasMinWidth: {},
  mainLabel: {
    color: theme.palette.common.black,
  },
  optional: {
    color: theme.palette.grey[200],
  },
  gridItem: {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: '100%',
      fontSize: theme.typography.pxToRem(10),
      lineHeight: 1.2,
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
  },
  placeholder: {
    color: theme.palette.grey[200],
  },
}));

const createLabel = (props: {
  classes: ReturnType<typeof useStyles>;
  label?: React.ReactNode;
  optional?: boolean;
}): React.ReactNode => {
  const { label, classes, optional } = props;

  if (label === undefined || label === null) return undefined;

  const labelElt = React.isValidElement(label) ? (
    label
  ) : (
    <Typography
      variant={'subtitle2'}
      className={clsx(classes.mainLabel, 'MuiMainLabel-root')}
    >
      {label}
    </Typography>
  );

  return optional ? (
    <>
      {labelElt}
      <Typography variant={'body2'} className={classes.optional}>
        &nbsp;(optional)
      </Typography>
    </>
  ) : (
    labelElt
  );
};

const selectMode = (
  props: TextFieldPropsExtended,
  classes: ReturnType<typeof useStyles>
) => {
  const { select, options, children, placeholder, SelectProps } = props;

  // If props.select is true and placeholder is true
  // We using renderValue prop of Select to render placeholder string
  // when value is empty
  const renderValue = (value: any) => {
    if (value === undefined || value === '') {
      return <span className={classes.placeholder}>{placeholder}</span>;
    }

    const items = options || [];
    const label = items.find((item) => item.value === value)?.label;

    return label || value;
  };

  const SelectPropsNext: SelectPropsType = {
    ...DEFAULT_SELECTED_PROPS,
    ...SelectProps,
  };

  if (select) {
    const hasNoChildren = children === null || children === undefined;
    if (hasNoChildren && Array.isArray(options)) {
      Object.assign(SelectPropsNext, {
        renderValue: placeholder ? renderValue : undefined,
        displayEmpty: !!placeholder,
      });

      const nextChildren = options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ));

      return {
        children: nextChildren,
        SelectProps: SelectPropsNext,
      };
    }
  }

  return {
    children,
    SelectProps: SelectPropsNext,
  };
};

const TextField: React.FC<TextFieldPropsExtended> = (props) => {
  const {
    label,
    optional = false,
    variant = 'outlined',
    InputLabelProps,
    disableMinWidth = false,
    gridItem = false,
    // Don't remove `options` as we don't want to pass
    // options to TextField props
    options,
    placeholder,
    inputRef,
    ...other
  } = props;
  const classes = useStyles();
  const { children, SelectProps } = selectMode(props, classes);

  return (
    <MuiTextField
      children={children}
      classes={{
        root: clsx(classes.root, {
          [classes.hasMinWidth]: !disableMinWidth,
          [classes.gridItem]: gridItem,
        }),
      }}
      variant={variant}
      placeholder={placeholder}
      label={createLabel({ classes, label, optional })}
      InputLabelProps={{ shrink: true, ...InputLabelProps }}
      SelectProps={SelectProps}
      {...other}
    />
  );
};

export default TextField;
