import * as React from 'react';
import { MasterBallIcon } from 'common/icons/content-types';
import { Avatar, AvatarProps, Badge, makeStyles } from '@material-ui/core';
import { EditIcon } from 'common/icons/system';
import clsx from 'clsx';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    outlined: {
      border: `1px solid ${theme.palette.divider}`,
    },
    avatar: {
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.grey[50],
    },
    badge: {
      boxSizing: 'border-box',
      height: 'auto',
      padding: 0,
      transform: 'none',
      color: theme.palette.grey[200],
    },
    default: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    giant: {
      width: theme.spacing(24),
      height: theme.spacing(24),
    },
    fallbackIcon: {
      width: '75%',
      height: '75%',
    },
  }),
  { name: 'Artwork' }
);

export type ArtworkProps = {
  size?: 'default' | 'giant';
  outlined?: boolean;
  withIcon?: boolean;
  icon?: React.ElementType;
  fallbackIcon?: React.ElementType;
  classes?: Partial<ReturnType<typeof useStyles>>;
};

const Artwork = React.forwardRef<HTMLDivElement, AvatarProps & ArtworkProps>(
  (props, ref) => {
    const {
      size = 'default',
      withIcon = false,
      outlined = false,
      icon: Icon = EditIcon,
      fallbackIcon: FallbackIcon = MasterBallIcon,
      className,
      classes: classesProp,
      ...avatarProps
    } = props;
    const classes = useStyles({ classes: classesProp });

    const artwork = (
      <Avatar variant="square" className={classes.avatar} ref={ref} {...avatarProps}>
        <FallbackIcon className={classes.fallbackIcon} />
      </Avatar>
    );

    return (
      <div
        className={clsx(
          classes.root,
          classes[size],
          { [classes.outlined]: outlined },
          className
        )}
      >
        {withIcon ? (
          <Badge
            component="div"
            classes={{ badge: classes.badge }}
            overlap="rectangle"
            badgeContent={<Icon />}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {artwork}
          </Badge>
        ) : (
          artwork
        )}
      </div>
    );
  }
);

export default Artwork;
