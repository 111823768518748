import SUCCESS_MESSAGES from 'config/successMessages';

const getSuccessMessage = (n: number) => {
  if (n === 1) {
    return 'File has been successfully imported!';
  }

  return SUCCESS_MESSAGES.allFilesImported;
};

export default getSuccessMessage;
