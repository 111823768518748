import * as P9S from 'redux/permissions/permissionsActions';
import initGuestPermissions from 'redux/permissions/utils/initGuestPermissions';
import PermissionsState from 'redux/permissions/interfaces/permissionsState';
import INIT_PERMISSIONS_STATE from 'redux/permissions/configs/initPermissionsState';

function permissionsReducer(
  state = INIT_PERMISSIONS_STATE,
  action: P9S.PermissionsActions
): PermissionsState {
  switch (action.type) {
    case P9S.PermissionsActionTypes.SET_HARD_P9S:
      return {
        ...state,
        hardPermissions: action.payload.value,
      };
    case P9S.PermissionsActionTypes.SET_GUEST_P9S:
      return {
        ...state,
        hardPermissions: initGuestPermissions(),
        guestPermissions: action.payload.value,
      };
    case P9S.PermissionsActionTypes.SET_USER_P9S: {
      return {
        ...state,
        catalogs: action.payload.value,
      };
    }
    default:
      return state;
  }
}

export default permissionsReducer;
