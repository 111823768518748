import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import Recipient from '../interfaces/recipient';
import transformRawGuestUsersData from '../utils/transformRawGuestUsersData';
import useInviteGuestStyles from '../hooks/useInviteGuestStyles';
import GuestUser from 'common/interfaces/guestUser';
import noop from 'common/utils/noop';
import validateEmail from 'common/utils/validateEmail';
import { GetUmUsersDocument, useGetGuestUsersQuery } from 'generated/graphql';
import { QueryName as GetGuestUserQueryName } from 'apollo/plugins/userManagement/queries/GetGuestUsers';
import InviteGuestDialog from 'uikit/inviteGuestDialog/InviteGuestDialog';
import TextField from 'uikit/textField/TextField';

const useStyles = makeStyles(
  (theme) => ({
    describeSectionText: {
      margin: theme.spacing(3, 0, 1),
    },
    select: {
      marginBottom: theme.spacing(2),
    },
    addUserOption: {
      color: theme.palette.secondary.main,
    },
    messageArea: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'ShareFirstStep' }
);

interface ShareFirstStepProps {
  recipient: Recipient;
  isStateEmailValid: boolean;
  message: string;
  onRecipientChange?: (recipient: Recipient) => void;
  onMessageChange?: (message: string) => void;
}

const ShareFirstStep: FC<ShareFirstStepProps> = ({
  onRecipientChange = noop,
  onMessageChange = noop,
  isStateEmailValid,
  message,
  recipient,
}) => {
  const classes = useStyles();
  const inviteGuestDialogClasses = useInviteGuestStyles();
  const guestUsers = transformRawGuestUsersData(useGetGuestUsersQuery());
  const [selectedGuest, setSelectedGuest] = useState<GuestUser | null>(null);
  const [email, setEmail] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [openAddUserModal, setOpenAddUserModal] = React.useState(false);

  const emailChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void = ({
    target: { value },
  }) => {
    onRecipientChange({ email: value });
    setIsEmailValid(validateEmail(value));
  };

  useEffect(() => {
    if (!selectedGuest || (selectedGuest && selectedGuest.id !== recipient.userId)) {
      const guest = recipient.userId
        ? guestUsers.find(({ id }) => id === recipient.userId) || null
        : null;
      const email = recipient.email || (guest ? guest.email : '');

      setSelectedGuest(guest);
      setEmail(email);
      setIsEmailValid(validateEmail(email));
    }
  }, [guestUsers, recipient, selectedGuest]);

  return (
    <>
      <TextField
        fullWidth
        variant={'outlined'}
        label={'Email'}
        value={email}
        placeholder={'Enter Email'}
        disabled={!!selectedGuest}
        className={classes.describeSectionText}
        onChange={emailChangeHandler}
        error={!isEmailValid || !isStateEmailValid}
      />
      <TextField
        fullWidth
        variant={'outlined'}
        label={'Message'}
        optional
        multiline
        value={message}
        placeholder={'Type any message'}
        onChange={(e) => onMessageChange(e.target.value)}
        inputProps={{
          maxLength: 500,
        }}
        InputProps={{
          rows: 4,
        }}
        className={classes.messageArea}
      />
      <Dialog classes={inviteGuestDialogClasses} open={openAddUserModal}>
        <InviteGuestDialog
          onClose={() => setOpenAddUserModal(false)}
          mutationOptions={{
            refetchQueries: [GetGuestUserQueryName, { query: GetUmUsersDocument }],
          }}
        />
      </Dialog>
    </>
  );
};

export default ShareFirstStep;
