import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import apiCoreService from 'api/core/apiCoreService';
import constructPath from 'api/utils/constructPath';
import { Asset, ContentType } from 'common/api/models';
import POST from 'common/services/httpServices/post';

const uploadBeat = async (params: {
  file: File;
  catalogId: number;
  onProgress?(event: ProgressEvent): void;
}) => {
  const { file, catalogId, onProgress } = params;

  const createFromInput = async <T>(args: { endpoint: string; input: any }) => {
    return await POST(args.endpoint, JSON.stringify(args.input)).then<T>((res) =>
      res.json()
    );
  };

  try {
    const { data } = await apiCoreService.addFile(
      {
        asset_type: 'BEATS',
        catalog_id: catalogId,
        file: file,
      },
      {
        onProgress(event) {
          if (onProgress) onProgress(event);
        },
      }
    );

    const asset = await createFromInput<Asset>({
      input: {
        title: file.name,
        catalog: catalogId,
        type: ContentType.BEATS_IMPORT,
        file: data.id,
      },
      endpoint: constructPath(CatalogsPaths.UploadBeat, { catalogId }),
    });

    return asset;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export default uploadBeat;
