enum ContractsPaths {
  AcceptedView = 'contracts/contract_accepted_view/',
  CreateSplitSheet = 'contracts/manage/splitsheet/',
  GetContract = 'contracts/',
  GetSummaries = 'contracts/summaries/',
  MoveContractToReview = 'contracts/ID/workflow/',
  PostUploadedContracts = 'contracts/manage/uploaded/',
  UpdateSplitSheet = 'contracts/manage/splitsheet/',
}

export default ContractsPaths;
