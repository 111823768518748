import { Routes } from 'navigation/enums/routes';
import { Middleware } from 'redux';
import appHistory from 'common/appHistory';
import { ModalConfigActionTypes, modalOpen } from 'redux/modal/modalActions';
import { RootState } from 'redux/root-reducer';
import * as menu from 'redux/menu/menuActions';
import { isGqlSplitSheet, isGqlUploadedContract } from 'common/api/utils';
import { ContentType } from 'common/api/models';
import isClickAction from 'redux/menu/utils/isClickAction';
import getEditAlbumValues from 'redux/menu/utils/getEditAlbumValues';
import isDriveContractContext from 'redux/menu/utils/isDriveContractContext';
import DriveCollectionContext from 'redux/menu/interfaces/driveCollectionContext';
import MenuContext from 'redux/menu/types/menuContext';
import MenuOption from 'redux/menu/enums/menuOption';

const openEditAlbumDialog = (context: DriveCollectionContext) => {
  return modalOpen({
    type: ModalConfigActionTypes.EDIT_ALBUM,
    data: {
      values: getEditAlbumValues(context),
    },
  });
};

const getNextAction = (context: MenuContext) => {
  if (isDriveContractContext(context)) {
    const [contract] = context.data;

    if (isGqlSplitSheet(contract)) {
      appHistory.push(`${Routes.SplitSheet}/${contract.id}`, {
        prevRoute: appHistory.location.pathname,
      });
      return;
    }

    if (isGqlUploadedContract(contract)) {
      return modalOpen({
        type: ModalConfigActionTypes.UPLOAD_CONTRACT_EDIT,
        data: {
          props: {
            id: contract.id,
          },
        },
      });
    }
  }

  if (context.typeId === ContentType.COLLECTION) {
    return openEditAlbumDialog(context);
  }
};

const edit: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (isClickAction(action)) {
    const { option, context } = action.payload;
    if (option === MenuOption.EDIT) {
      const action = getNextAction(context);

      if (action) {
        store.dispatch(action);
      }

      return next(menu.close());
    }
  }

  return next(action);
};

export default edit;
