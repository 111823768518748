import clearUserCache from 'common/utils/clearUserCache';

export enum LoginActionTypes {
  Login = '@LOGOUT',
  Logout = '@LOGIN',
}

interface LogoutAction {
  type: LoginActionTypes.Logout;
}

export const logoutAction = (): LogoutAction => {
  clearUserCache();
  return {
    type: LoginActionTypes.Logout,
  };
};

interface LoginAction {
  type: LoginActionTypes.Login;
}

export const loginAction = (): LoginAction => ({
  type: LoginActionTypes.Login,
});

export type LoginActions = LoginAction | LogoutAction;
