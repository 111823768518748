import * as React from 'react';
import { FastField, FieldProps } from 'formik';
import { YearSelectRange } from 'config/formFields';
import { MenuItem } from '@material-ui/core';
import TextFieldPropsExtended from 'common/types/textFieldPropsExtended';
import TextField from 'uikit/textField/TextField';

const renderOptions = () => {
  const [start, end] = YearSelectRange;
  const items: React.ReactNode[] = [];
  for (let i = start; i <= end; i++) {
    items.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }

  return items;
};

const options = renderOptions().reverse();

export default function YearSelectField(props: TextFieldPropsExtended) {
  return (
    <FastField name={props.name}>
      {({ field, meta }: FieldProps) => {
        const isInvalid = !!meta.error && meta.touched;
        const errorMsg = isInvalid ? meta.error : <span>&nbsp;</span>;

        return (
          <TextField
            {...props}
            {...field}
            error={isInvalid}
            helperText={errorMsg}
            disableMinWidth
            select
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: '192px',
                  },
                },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              },
            }}
          >
            {options}
          </TextField>
        );
      }}
    </FastField>
  );
}
