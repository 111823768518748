import ITransferState from 'redux/fileUpload/interfaces/iTransferState';

const remove = <T>(xs: T[], index: number) => {
  return xs.filter((_, i) => i !== index);
};

const removeFile = (state: ITransferState, index: number): ITransferState => {
  return {
    ...state,
    files: remove(state.files, index),
    entries: remove(state.entries, index),
  };
};

export default removeFile;
