import createRecord from 'common/utils/createRecord';
import { Privileges } from 'generated/graphql';
import PrivilegesConfig from 'common/types/privilegesConfig';

export const PRIVILEGES_ARRAY = [
  Privileges.VIEW,
  Privileges.DOWNLOAD,
  Privileges.UPLOAD,
  Privileges.EDIT,
  Privileges.DELETE,
] as const;

const initPrivilegesConfig = (value = false): PrivilegesConfig =>
  createRecord(PRIVILEGES_ARRAY, value);

export default initPrivilegesConfig;
