import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Routes } from 'navigation/enums/routes';
import { ReactComponent as DropsIcon } from 'assets/navigation/drops.svg';
import SectionItem from 'common/components/sidebarNavigation/components/SectionItem';
import useHasResourceAccess from 'common/components/sidebarNavigation/hooks/useHasResourceAccess';

interface DropsItemProps {
  isLoading: boolean;
  isCollapsed: boolean;
  onItemClick: () => void;
}

const DropsItem: FC<DropsItemProps> = ({ isLoading, isCollapsed, onItemClick }) => {
  const location = useLocation();
  const isVisible = useHasResourceAccess('drops');

  return isVisible ? (
    <SectionItem
      loading={isLoading}
      to={Routes.Drops}
      isActive={location.pathname.includes(Routes.Drops)}
      isCollapsed={isCollapsed}
      IconComponent={DropsIcon}
      label="Drops"
      onClick={onItemClick}
    />
  ) : null;
};

export default DropsItem;
