import { makeStyles, Theme } from '@material-ui/core/styles';
import { SearchIcon } from 'common/icons/system';
import React from 'react';
import Fuse from 'fuse.js';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';

export type IdName = { id: string | number; name: string };

export type NamesProps = {
  names: Array<IdName>;
  activeNames: Array<IdName>;
  handleChange: (name: IdName) => void;
  loading?: boolean;
  flexSearch?: boolean;
  className?: string;
};

const options = {
  isCaseSensitive: false,
  findAllMatches: true,
  minMatchCharLength: 2,
  shouldSort: true,
  threshold: 0.3,
  distance: 100,
  keys: ['name'],
};

const useStyles = makeStyles((theme: Theme) => ({
  dropDownContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 160,
    width: '100%',
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  search: {
    height: 30,
    width: '100%',
    padding: 0,
    border: 'none',
    outline: 'none',
    '$flexSearch &': {
      maxWidth: '100%',
      flex: '1 1 auto',
    },
  },
  divider: {
    margin: '3px 0 0 0',
    width: '100%',
    borderTop: '1px solid #eeeeee',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
  namesContainer: {
    maxHeight: 150,
    overflowY: 'scroll',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: 30,
    padding: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#eaeaea',
    },
  },
  activeName: {
    composes: '$name',
    backgroundColor: '#dbdbdb',
  },
  searchIcon: {
    '$flexSearch &': {
      flex: '0 0 auto',
    },
  },
  flexSearch: {},
}));

const fuse = new Fuse([] as IdName[], options);

const Names: React.FC<NamesProps> = (props) => {
  const {
    className,
    names,
    handleChange,
    activeNames,
    loading = false,
    flexSearch = false,
  } = props;
  const styles = useStyles();

  fuse.setCollection(names);
  const [searchVal, setSearchVal] = React.useState('');
  const handleSearchInput = React.useCallback(
    (event: React.ChangeEvent<{ value: string }>) => setSearchVal(event.target.value),
    []
  );
  const searchResult = fuse.search(searchVal);
  let namesList: typeof names;
  if (searchVal.length === 0) {
    namesList = names;
  } else {
    namesList = searchResult.map((result) => result.item);
  }

  namesList.sort((catalog1, catalog2) =>
    catalog1.name.toUpperCase() > catalog2.name.toUpperCase() ? 1 : -1
  );

  return (
    <div
      className={clsx(
        styles.dropDownContainer,
        { [styles.flexSearch]: flexSearch },
        className
      )}
    >
      <div className={styles.searchContainer}>
        <input
          value={searchVal}
          onChange={handleSearchInput}
          placeholder="Type name"
          className={styles.search}
        />
        <SearchIcon color="action" className={styles.searchIcon} />
      </div>
      <hr className={styles.divider} />
      <div className={styles.namesContainer}>
        {loading && (
          <React.Fragment>
            <p className={styles.name}>
              <Skeleton width={92} />
            </p>
            <p className={styles.name}>
              <Skeleton width={92} />
            </p>
            <p className={styles.name}>
              <Skeleton width={92} />
            </p>
            <p className={styles.name}>
              <Skeleton width={92} />
            </p>
          </React.Fragment>
        )}
        {!loading &&
          !!namesList.length &&
          namesList.map((name, index) => (
            <p
              key={index}
              className={
                activeNames.findIndex((actName) => actName.id === name.id) >= 0
                  ? styles.activeName
                  : styles.name
              }
              onClick={() => handleChange(name)}
            >
              {name.name}
            </p>
          ))}
        {!loading && !namesList.length && (
          <p style={{ padding: '10px 0', textAlign: 'center' }}>No results...</p>
        )}
      </div>
    </div>
  );
};

export default Names;
