import * as React from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { RootState } from 'redux/root-reducer';
import { fileUploadIsUploading } from 'redux/fileUpload/fileUploadSelectors';
import { TransferSnack } from 'common/components/transferSnack/TransferSnack';
import UploadingSnackBox from 'common/components/transferSnack/components/UploadingSnackBox';

const getModalOpen = (state: RootState) => state.modal.isOpen;

const UploadingTray = () => {
  const notify = useSnackbar();
  const open = useSelector(getModalOpen);
  const uploading = useSelector(fileUploadIsUploading);

  React.useEffect(() => {
    if (!open && uploading) {
      const key = notify.enqueueSnackbar('', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        content: () => (
          <UploadingSnackBox variant="success">
            <TransferSnack />
          </UploadingSnackBox>
        ),
        persist: true,
      });

      return () => notify.closeSnackbar(key);
    }
  }, [notify, open, uploading]);

  return null;
};

export default UploadingTray;
