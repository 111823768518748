import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import constructPath from 'api/utils/constructPath';
import { RestProject } from 'common/api/models';
import POST from 'common/services/httpServices/post';
import { SomeRequired } from 'common/utilityTypes';

const createProject: (
  input: SomeRequired<RestProject, 'title' | 'catalog'>,
  params: {
    catalogId: number;
  }
) => Promise<RestProject> = async (input, params) => {
  const { catalogId } = params;
  const endpoint = constructPath(CatalogsPaths.CreateProject, {
    catalogId: catalogId.toString(),
  });

  return await POST(endpoint, JSON.stringify(input)).then<RestProject>((res) =>
    res.json()
  );
};

export default createProject;
