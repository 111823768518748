import { NetworkStatus, ApolloError, ServerError } from '@apollo/client';

import {
  ContentType,
  FolderTypesArray,
  AssetType,
  ASSET_TYPE_IDS,
  TypedNode,
  ITypedNode,
  ITypedSnakeNode,
  FolderContentType,
} from './models';
import { ContractType } from 'generated/graphql';
import { GqlSplitSheet, GqlUploadedContract } from './contractModels';
import { ISharedDataItem, ISharedCollection } from 'modules/sharing/api/models';

const { MASTER_FILE, MASTER_FILE_CLEAN, MASTER_FILE_EXPLICIT } = ContentType;

export const DriveSectionUtils = {
  hasMore(x?: number, y?: number) {
    if (typeof x === 'number' && typeof y === 'number') {
      return x < y;
    }

    return false;
  },
  isLoading(status: NetworkStatus) {
    return [
      NetworkStatus.loading,
      NetworkStatus.refetch,
      NetworkStatus.setVariables,
    ].includes(status);
  },
};

export const DriveUtils = {
  getTypeId(o: TypedNode) {
    return (o as ITypedSnakeNode).type_id || (o as ITypedNode).typeId;
  },
  isTypeNode(o: unknown): o is TypedNode {
    return typeof DriveUtils.getTypeId(o as TypedNode) === 'number';
  },
  isOfType(o: unknown, typeId: ContentType) {
    return DriveUtils.isTypeNode(o) && DriveUtils.getTypeId(o) === typeId;
  },
  isMasterFile(o: unknown) {
    return (
      DriveUtils.isOfType(o, MASTER_FILE_EXPLICIT) ||
      DriveUtils.isOfType(o, MASTER_FILE_CLEAN)
    );
  },
  getMasterFiles<T>(assets: T[]) {
    return assets.filter(DriveUtils.isMasterFile);
  },
  getOfType<T>(assets: T[], typeId: ContentType) {
    const items = Array.isArray(assets) ? assets : [];

    if (typeId === MASTER_FILE) {
      return DriveUtils.getMasterFiles(items);
    }

    return items.filter((o) => DriveUtils.isOfType(o, typeId));
  },
  cleanFirst(a: TypedNode, b: TypedNode) {
    const aIsClean = DriveUtils.getTypeId(a) === MASTER_FILE_CLEAN;
    const bIsClean = DriveUtils.getTypeId(b) === MASTER_FILE_CLEAN;

    if (bIsClean && !aIsClean) return 1;
    if (aIsClean && !bIsClean) return -1;

    return 0;
  },
  groupLastN<T>(items: T[], n = 5) {
    const head: T[] = [];
    const tail: T[] = [];
    const tailStart = items.length - n;

    for (let i = 0; i < items.length; ++i) {
      if (i < tailStart) head.push(items[i]);
      else tail.push(items[i]);
    }

    return [head, tail] as const;
  },
  isAssetType(typeId: number): typeId is AssetType {
    return ASSET_TYPE_IDS.includes(typeId);
  },
  isFolderType(typeId: number): typeId is FolderContentType {
    return FolderTypesArray.includes(typeId);
  },
};

export const isGqlSplitSheet = (contract: any): contract is GqlSplitSheet =>
  contract?.__typename === 'Contract' &&
  contract?.type === ContractType.SPLIT_SHEET_AGREEMENT;

export const isGqlUploadedContract = (contract: any): contract is GqlUploadedContract =>
  contract?.__typename === 'Contract' &&
  (contract?.type === ContractType.UPLOADED_CONTRACT ||
    contract?.type === ContractType.LICENSE_AGREEMENT ||
    contract?.type === ContractType.PRODUCER_ARTIST_AGREEMENT ||
    contract?.type === ContractType.SIDE_ARTIST_AGREEMENT ||
    contract?.type === ContractType.CLEARANCE_AGREEMENT);

export const isSystemRecord = (contract: any): boolean =>
  isGqlSplitSheet(contract) ||
  (isGqlUploadedContract(contract) && !contract.file?.media_url);

export const isSharedCollection = (item: ISharedDataItem): item is ISharedCollection =>
  item.type_id === ContentType.COLLECTION;

export const getResponseMessage = (error?: ApolloError | null): string => {
  if (!error) return '';

  const result = (error.networkError as ServerError)?.result;

  if (result?.[0]) return result[0];

  return `${error}`;
};
