import { FC } from 'react';

import useFileUploadAutostart from 'modules/app/hooks/useFileUploadAutostart';

const FileUploadEffects: FC = (): null => {
  useFileUploadAutostart();
  return null;
};

export default FileUploadEffects;
