import ITransferState from 'redux/fileUpload/interfaces/iTransferState';
import * as yup from 'yup';
import countFreeSlots from 'redux/fileUpload/utils/countFreeSlots';
import { MAX_UPLOAD_SIZE } from 'config/network';
import countTotalSize from 'redux/fileUpload/utils/countTotalSize';

function calcFields(state: ITransferState): ITransferState {
  const stateSchema = yup.object({
    maxFiles: yup.number().integer().min(1),
    files: yup
      .array()
      .max(
        yup.ref('maxFiles'),
        // eslint-disable-next-line no-template-curly-in-string
        "You can't upload more then ${max} at time"
      )
      .test(
        'max-size',
        "Total size can't be greater than 2GB",
        (value) => countTotalSize(value as File[]) < MAX_UPLOAD_SIZE
      ),
    multiple: yup.boolean(),
    error: yup.mixed(),
  });
  const freeSlots = countFreeSlots(state.files, state.maxFiles);
  let error: yup.ValidationError | null = null;
  try {
    stateSchema.validateSync(state);
  } catch (err) {
    error = err as yup.ValidationError;
  }

  return {
    ...state,
    freeSlots,
    error,
  };
}

export default calcFields;
