import * as React from 'react';
import CheckListDialog, {
  CheckListDialogProps,
} from 'common/components/Modals/CheckListDialog';
import { useAddUsers } from 'modules/roles/redux/hooks';

export type UserListDialogConfig<T> = {
  type: T;
  data: {};
};

const UserListDialog = (props: Pick<CheckListDialogProps, 'onClose'>) => {
  const { onClose } = props;
  const { loading, items, onChange, onSubmit, noItemsText } = useAddUsers();

  return (
    <CheckListDialog
      onClose={onClose}
      title="Add User"
      listTitle="Choose user"
      submitLabel="Add"
      noItems={noItemsText}
      loading={loading}
      items={items}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};

export default UserListDialog;
