import { useMenuResourcesQuery } from 'generated/graphql';

interface ResourceSlug {
  slug_name: string;
}

const useHasResourceAccess: (ResourceSlug) => boolean = (slug_name) => {
  return Boolean(
    useMenuResourcesQuery().data?.resources.find(
      (resource) => resource?.slug === slug_name
    )?.access
  );
};

export default useHasResourceAccess;
