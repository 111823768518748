import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'uikit/typography/Typography';
import { Box, Theme } from '@material-ui/core';
import { ReactComponent as ErrorImage } from 'assets/404.svg';
import { ReactComponent as TullyAmpImage } from 'assets/tully-amp.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 1),
  },
  center: {
    width: '100%',
    textAlign: 'center',
  },
  box: {
    marginTop: theme.spacing(13),
  },
  errorImage: {
    marginTop: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(3),
  },
  tullyImage: {
    marginTop: theme.spacing(1),
  },
}));

const NotFoundPage = () => {
  const styles = useStyles();

  return (
    <Box className={`${styles.box} ${styles.center} ${styles.root}`}>
      <Typography variant={'h2'} className={`${styles.errorImage} ${styles.center}`}>
        Page Not Found
      </Typography>
      <ErrorImage className={`${styles.errorImage} ${styles.center}`} />
      <Typography variant={'subtitle1'} className={`${styles.subtitle} ${styles.center}`}>
        Go back to
      </Typography>
      <a href="/">
        <TullyAmpImage className={`${styles.tullyImage} ${styles.center}`} />
      </a>
    </Box>
  );
};

export default NotFoundPage;
