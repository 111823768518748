import { FC, ReactElement } from 'react';
import * as React from 'react';
import { MasterFieldsFragment } from 'generated/graphql';
import usePlayerPlayMaster from 'common/hooks/usePlayerPlayMaster';
import DraggableTrack, { DraggableTrackProps } from 'common/formik/DraggableTrack';

interface MasterDraggableProps extends DraggableTrackProps {
  master: MasterFieldsFragment;
}

const MasterDraggable: FC<MasterDraggableProps> = ({
  master,
  onDelete,
  ...trackProps
}): ReactElement => {
  const { isAssetPlaying, playAsset, pauseAsset, closePlayer } = usePlayerPlayMaster(
    master
  );

  const handleDelete = React.useCallback<React.MouseEventHandler>(
    (event) => {
      closePlayer();
      if (onDelete) onDelete(event);
    },
    [onDelete, closePlayer]
  );

  return (
    <DraggableTrack
      {...trackProps}
      play={playAsset}
      pause={pauseAsset}
      playButton={typeof playAsset === 'function'}
      isPlaying={isAssetPlaying}
      onDelete={handleDelete}
    />
  );
};

export default MasterDraggable;
