import type { ISplitSheetData } from 'modules/splitSheet/redux/model';
import type { ErrorSplitSheet } from 'modules/splitSheet/redux/reducer';

export interface IFetchSplitSheetAction {
  type: '@SPLITSHEET/FETCH_SPLITSHEET';
  payload: { id: string };
}
interface IFetchSplitSheetSuccessAction {
  type: '@SPLITSHEET/FETCH_SPLITSHEET_SUCCESS';
  payload: ISplitSheetData;
}
interface IFetchSplitSheetFailureAction {
  type: '@SPLITSHEET/FETCH_SPLITSHEET_FAILURE';
  payload: ErrorSplitSheet;
}

// export interface IPostSplitSheetAction {
//   type: '@SPLITSHEET/POST_SPLITSHEET';
//   payload: { data: ISplitSheetData; masterID: number };
// }
interface IPostSplitSheetSuccessAction {
  type: '@SPLITSHEET/POST_SPLITSHEET_SUCCESS';
  payload: { data: ISplitSheetData };
}
interface IPostSplitSheetFailureAction {
  type: '@SPLITSHEET/POST_SPLITSHEET_FAILURE';
  payload: ErrorSplitSheet;
}

// export interface IPatchSplitSheetAction {
//   type: '@SPLITSHEET/PATCH_SPLITSHEET';
//   payload: { data: ISplitSheetData; masterID: number; splitSheetId: string };
// }
interface IPatchSplitSheetSuccessAction {
  type: '@SPLITSHEET/PATCH_SPLITSHEET_SUCCESS';
}
interface IPatchSplitSheetFailureAction {
  type: '@SPLITSHEET/PATCH_SPLITSHEET_FAILURE';
  payload: ErrorSplitSheet;
}

// export interface ISetReviewStatusAction {
//   type: '@SPLITSHEET/SET_REVIEW_STATUS';
//   payload: { splitSheetId: string };
// }
interface ISetReviewStatusSuccessAction {
  type: '@SPLITSHEET/SET_REVIEW_STATUS_SUCCESS';
}
interface ISetReviewStatusFailureAction {
  type: '@SPLITSHEET/SET_REVIEW_STATUS_FAILURE';
  payload: ErrorSplitSheet;
}

export type IAsyncAction =
  | IFetchSplitSheetAction
  | IFetchSplitSheetSuccessAction
  | IFetchSplitSheetFailureAction
  // | IPostSplitSheetAction
  | IPostSplitSheetSuccessAction
  | IPostSplitSheetFailureAction
  // | IPatchSplitSheetAction
  | IPatchSplitSheetSuccessAction
  | IPatchSplitSheetFailureAction
  // | ISetReviewStatusAction
  | ISetReviewStatusSuccessAction
  | ISetReviewStatusFailureAction;

export const fetchSplitSheetAction = (id: string): IFetchSplitSheetAction => ({
  type: '@SPLITSHEET/FETCH_SPLITSHEET',
  payload: { id },
});
export const fetchSplitSheetSuccessAction = (
  data: ISplitSheetData
): IFetchSplitSheetSuccessAction => ({
  type: '@SPLITSHEET/FETCH_SPLITSHEET_SUCCESS',
  payload: data,
});
export const fetchSplitSheetFailureAction = (
  error: ErrorSplitSheet
): IFetchSplitSheetFailureAction => ({
  type: '@SPLITSHEET/FETCH_SPLITSHEET_FAILURE',
  payload: error,
});

// export const postSplitSheetAction = (
//   data: ISplitSheetData,
//   masterID: number
// ): IPostSplitSheetAction => ({
//   type: '@SPLITSHEET/POST_SPLITSHEET',
//   payload: { data, masterID },
// });
export const postSplitSheetSuccessAction = (
  data: ISplitSheetData
): IPostSplitSheetSuccessAction => ({
  type: '@SPLITSHEET/POST_SPLITSHEET_SUCCESS',
  payload: { data },
});
export const postSplitSheetFailureAction = (
  error: ErrorSplitSheet
): IPostSplitSheetFailureAction => ({
  type: '@SPLITSHEET/POST_SPLITSHEET_FAILURE',
  payload: error,
});

// export const patchSplitSheetAction = (
//   data: ISplitSheetData,
//   masterID: number,
//   splitSheetId: string
// ): IPatchSplitSheetAction => ({
//   type: '@SPLITSHEET/PATCH_SPLITSHEET',
//   payload: { data, masterID, splitSheetId },
// });
export const patchSplitSheetSuccessAction = (): IPatchSplitSheetSuccessAction => ({
  type: '@SPLITSHEET/PATCH_SPLITSHEET_SUCCESS',
});
export const patchSplitSheetFailureAction = (
  error: ErrorSplitSheet
): IPatchSplitSheetFailureAction => ({
  type: '@SPLITSHEET/PATCH_SPLITSHEET_FAILURE',
  payload: error,
});

// export const setReviewStatusAction = (splitSheetId: string): ISetReviewStatusAction => ({
//   type: '@SPLITSHEET/SET_REVIEW_STATUS',
//   payload: { splitSheetId },
// });
export const setReviewStatusSuccessAction = (): ISetReviewStatusSuccessAction => ({
  type: '@SPLITSHEET/SET_REVIEW_STATUS_SUCCESS',
});
export const setReviewStatusFailureAction = (
  error: ErrorSplitSheet
): ISetReviewStatusFailureAction => ({
  type: '@SPLITSHEET/SET_REVIEW_STATUS_FAILURE',
  payload: error,
});
