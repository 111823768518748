import { makeStyles, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog/Dialog';
import FilePreviewDialog from 'common/components/Modals/filePreviewDialog/FilePreviewDialog';
import { FileBallIcon } from 'common/icons/content-types';
import React from 'react';
import Typography from 'uikit/typography/Typography';

const useStyles = makeStyles(
  (theme: Theme) => ({
    contentItem: {
      textAlign: 'left',
      marginBottom: theme.spacing(3),
    },
    fileName: {
      color: theme.palette.common.black,
      display: 'inline-block',
      marginLeft: theme.spacing(1),
      verticalAlign: 'super',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    itemLabel: {
      marginBottom: theme.spacing(0.5),
    },
  }),
  { name: 'FileSubSection' }
);

const useDialogStyles = makeStyles(
  (theme: Theme) => ({
    paper: {
      width: '70%',
      maxWidth: '100%',
      height: '80%',
    },
  }),
  { name: 'FileSubSection' }
);

interface FileSubSectionProps {
  file: {
    fileName?: string | null;
    downloadUrl?: string;
    id?: number;
  };
}

const FileSubSection: React.FC<FileSubSectionProps> = ({ file }) => {
  const classes = useStyles();
  const classesDialog = useDialogStyles();
  const fileName = file.fileName?.substring(file.fileName?.indexOf('_') + 1);
  const fileUrl = file.downloadUrl;
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <>
      <Typography variant={'subtitle2'} className={classes.itemLabel}>
        File
      </Typography>

      <p className={classes.contentItem}>
        <FileBallIcon />
        <Typography
          variant={'subtitle2'}
          className={classes.fileName}
          onClick={() => setOpenModal(true)}
        >
          {fileName}
        </Typography>
      </p>

      {!!fileUrl && !!fileName && (
        <Dialog classes={classesDialog} open={openModal}>
          <FilePreviewDialog
            onClose={() => setOpenModal(false)}
            uri={fileUrl}
            title={fileName}
          />
        </Dialog>
      )}
    </>
  );
};

export default FileSubSection;
