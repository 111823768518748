import ContractsPaths from 'api/contracts/enums/contractsPaths';
import PATCH from 'common/services/httpServices/patch';
import { IRestSplitSheetUpdateInput } from 'modules/splitSheet/contracts/interfaces/IRestSplitSheetUpdateInput';
import { ISplitSheetData } from 'modules/splitSheet/redux/model';

const patchSplitSheet: (
  splitSheetData: ISplitSheetData,
  masterID: number,
  splitSheetID: string
) => Promise<boolean> = async (splitSheetData, masterID, splitSheetID) => {
  const splitSheetAPIData: IRestSplitSheetUpdateInput = {
    title: splitSheetData.songTitle,
    aka: splitSheetData.aka || undefined,
    approvers: splitSheetData.approvers.map((approver) => ({
      id: parseInt(approver.id) || undefined,
      name: approver.name,
      email: approver.email.address,
      role_label: approver.role,
      affiliation: approver.affiliation,
      ownership: approver.ownership,
      can_see_others: approver.canViewAll,
      tracking_id: approver.trackingId,
    })),
    reviewers: splitSheetData.reviewers.map((reviewer) => ({
      id: parseInt(reviewer.id) || undefined,
      name: reviewer.name,
      email: reviewer.email.address,
      can_see_others: reviewer.canViewAll,
      following_id: reviewer.trackedApprover?.trackingId,
    })),
    master_id: masterID,
    date: splitSheetData.date.format('YYYY-MM-DD'),
  };
  const res = await PATCH(
    `${ContractsPaths.UpdateSplitSheet}${splitSheetID}/`,
    JSON.stringify(splitSheetAPIData)
  );
  return res.ok;
};

export default patchSplitSheet;
