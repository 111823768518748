import breakpoints from 'theme/core/breakpoints';
import { Palette } from '@material-ui/core/styles/createPalette';
import { Spacing } from '@material-ui/core/styles/createSpacing';
import { Typography } from '@material-ui/core/styles/createTypography';
import globalStyles from 'theme/globalStyles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Mixins } from '@material-ui/core/styles/createMixins';
/*
 * In this file we should keep global style overrides of Mui components
 * https://material-ui.com/customization/globals/#css
 *
 * NOTE: only simple overrides allowed;
 * If you need more advanced customization, is better
 * to apply changes on component level (e.g Typography)
 * And same for custom classes & props (e.g IconButton)
 *
 * Example:
 *   // Style sheet name ⚛️
 *   MuiButton: {
 *     // Name of the rule
 *     text: {
 *       // Some CSS
 *       color: 'white',
 *     },
 *   },
 */

export default function createOverrides(
  typography: Typography,
  palette: Palette,
  spacing: Spacing,
  mixins: Mixins
) {
  const crossPlatformPlaceholder = (() => {
    const placeholder: CSSProperties = {
      color: palette.grey[200],
      opacity: 1,
    };

    return {
      '&::-webkit-input-placeholder': placeholder,
      '&::-moz-placeholder': placeholder,
      '&:-ms-input-placeholder': placeholder,
      '&:-moz-placeholder': placeholder,
    };
  })();

  return {
    // Here we extending default CssBaseline component
    // by including base, reset & normalize css.
    MuiCssBaseline: globalStyles,
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: palette.action.hover,
        },
      },
    },
    MuiInputLabel: {
      root: {
        display: 'flex',
        alignItems: 'start',
        transform: 'translate(0, 0) !important',
      },
      formControl: {
        position: 'static' as const,
      },
    },
    MuiSelect: {
      selectMenu: {
        height: '2rem',
        display: 'flex',
        alignItems: 'center',
      },
      iconOutlined: {
        right: 8,
      },
    },
    MuiAutocomplete: {
      paper: {
        fontSize: typography.body2.fontSize,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: typography.body2.fontSize,
        borderRadius: 4,
        'label + &': {
          marginTop: spacing(0.5),
        },
      },
      input: {
        height: typography.pxToRem(20),
        ...crossPlatformPlaceholder,
      },
    },
    MuiRadio: {
      root: {
        padding: spacing(0.5),
        color: palette.grey[200],
        '&:hover': {
          color: palette.grey[200],
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        border: '1px solid',
        borderColor: palette.grey[200],
        '&$focused': {
          borderColor: palette.secondary.main,
        },
        '&$error': {
          borderColor: palette.error.main,
        },
      },
      error: {},
      focused: {},
      input: {
        padding: '5px 7px',
        lineHeight: '1.5em',
      },
      multiline: {
        padding: spacing(1),
      },
      adornedStart: {
        paddingLeft: 0,
      },
      adornedEnd: {
        paddingRight: 0,
      },
      notchedOutline: {
        display: 'none',
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
        '&:hover': {
          color: palette.grey['800'],
          backgroundColor: palette.action.hover,
        },
        '&:active': {
          backgroundColor: palette.action.hover,
        },
      },
      sizeSmall: {
        padding: 4,
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover, &.Mui-focusVisible': {
          '& .MuiListItemIcon-root': {
            color: palette.grey['800'],
          },
        },
        ...typography.body2,
        height: 32,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: spacing(1),
        paddingLeft: spacing(1),
        alignItems: 'center',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiTab: {
      root: {
        ...typography.button2,
        textTransform: 'none' as const,
        [breakpoints.up('sm')]: {
          minWidth: 120,
        },
      },
      selected: {},
      textColorPrimary: {
        '&$selected': {
          color: palette.text.primary,
        },
      },
      textColorSecondary: {
        '&$selected': {
          color: palette.text.primary,
        },
      },
    },
    MuiGrid: {
      item: {
        '&.icon-button-col': {
          flexBasis: 32,
          maxWidth: 32,
          flexGrow: 0,
        },
        '&.switch-fixed': {
          flexBasis: 152,
          maxWidth: 152,
          flexGrow: 0,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: palette.grey[200],
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      } as const,
      contained: {
        marginLeft: spacing(1),
        marginRight: spacing(1),
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: spacing(0.5),
      },
    },
    MuiFormControlLabel: {
      root: {
        // Can't use spacing here, need fix offset to respect icon
        marginLeft: -13,
      },
      label: {
        ...typography.body2,
      },
    },
    MuiDialogTitle: {
      root: {
        [breakpoints.down('sm')]: {
          padding: spacing(2, 3, 1),
        },
      },
    },
    MuiDialogContent: {
      root: {
        [breakpoints.up('md')]: {
          padding: spacing(0, 5),
        },
      },
      dividers: {
        [breakpoints.up('md')]: {
          padding: spacing(0, 5),
        },
      },
    },
    MuiDialogActions: {
      root: {
        ...(mixins.dialog.actions as CSSProperties),
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${palette.grey[50]}`,
      },
    },
  };
}
