import { ModalActionTypes, ModalActions } from 'redux/modal/modalActions';
import ModalState from 'redux/modal/interfaces/modalState';
import INIT_MODAL_STATE from 'redux/modal/configs/initModalState';

function modalReducer(state = INIT_MODAL_STATE, action: ModalActions): ModalState {
  switch (action.type) {
    case ModalActionTypes.CLOSE:
      return { ...state, closing: true };
    case ModalActionTypes.CLOSED:
      return { isOpen: false, closing: false, config: null };
    case ModalActionTypes.OPEN:
      return { isOpen: true, closing: false, config: action.payload };
    default:
      return state;
  }
}

export default modalReducer;
