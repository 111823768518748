import RefetchState from 'redux/refetch/interfaces/refetchState';

export enum RefetchActionTypes {
  SetBeatsTrue = 'SetBeatsTrue',
  SetBeatsFalse = 'SetBeatsFalse',
}

export interface SetBeatsTrueAction {
  type: RefetchActionTypes.SetBeatsTrue;
  payload: RefetchState;
}

export const setBeatsTrue = (): SetBeatsTrueAction => ({
  type: RefetchActionTypes.SetBeatsTrue,
  payload: {
    beats: true,
  },
});

export interface SetBeatsFalseAction {
  type: RefetchActionTypes.SetBeatsFalse;
  payload: RefetchState;
}

export const setBeatsFalse = (): SetBeatsFalseAction => ({
  type: RefetchActionTypes.SetBeatsFalse,
  payload: {
    beats: false,
  },
});

export type RefetchActions = SetBeatsTrueAction | SetBeatsFalseAction;
