import { useDispatch } from 'react-redux';

import { logoutAction } from 'redux/login/loginActions';

interface UseLogOutRes {
  logOutHandler: () => void;
}

const useLogOut: () => UseLogOutRes = () => {
  const dispatch = useDispatch();

  return {
    logOutHandler: () => dispatch(logoutAction()),
  };
};

export default useLogOut;
