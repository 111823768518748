import * as React from 'react';
import { useSnackbar, ProviderContext, SnackbarKey, OptionsObject } from 'notistack';
import { createPendingToast } from 'common/components/notifMessages/notifMessages';

export class NotiStackProxy<C extends ProviderContext> {
  ready = false;
  _context: C | null = null;

  setContext(context: C) {
    this.ready = true;
    this._context = context;
  }

  reset() {
    this.ready = false;
    this._context = null;
  }

  enqueueSnackbar(message: React.ReactNode, options?: OptionsObject) {
    if (this._context) {
      return this._context.enqueueSnackbar(message, options);
    }

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        'You trying to call notify.enqueueSnackbar method before notify get initialized...'
      );
    }
  }

  closeSnackbar(key?: SnackbarKey) {
    if (this._context) {
      return this._context.closeSnackbar(key);
    }

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        'You trying to call notify.closeSnackbar method before notify get initialized...'
      );
    }
  }

  pending(message: React.ReactNode, options?: OptionsObject) {
    if (this._context) {
      return this._context.enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        content: createPendingToast(message),
        persist: true,
        ...options,
      });
    }

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        'You trying to call notify.pending method before notify get initialized...'
      );
    }
  }

  error(message: React.ReactNode, options?: OptionsObject) {
    if (this._context) {
      return this._context.enqueueSnackbar(message, { variant: 'error', ...options });
    }

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        'You trying to call notify.error method before notify get initialized...'
      );
    }
  }

  success(message: React.ReactNode, options?: OptionsObject) {
    if (this._context) {
      return this._context.enqueueSnackbar(message, { variant: 'success', ...options });
    }

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        'You trying to call notify.success method before notify get initialized...'
      );
    }
  }

  warning(message: React.ReactNode, options?: OptionsObject) {
    if (this._context) {
      return this._context.enqueueSnackbar(message, { variant: 'warning', ...options });
    }

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        'You trying to call notify.warning method before notify get initialized...'
      );
    }
  }
}

export const notify = new NotiStackProxy();

export const Notify = () => {
  const context = useSnackbar();

  React.useEffect(() => {
    notify.setContext(context);

    return () => notify.reset();
  }, [context]);

  return null;
};
