import { AxiosRequestConfig } from 'axios';
import getStorageLink from 'api/core/services/getStorageLink';
import uploadFile from 'api/core/services/uploadFile';

export default async function uploadImage(file: File, config?: AxiosRequestConfig) {
  const { data: link } = await getStorageLink({
    file_name: file.name,
    file_size: file.size,
  });

  await uploadFile({ link, file }, config);

  return link;
}
