import { ContentType } from 'common/api/models';
import { SharePermissions } from 'common/models/sharing';

const { VIEW, DOWNLOAD, UPLOAD, EDIT, DELETE } = SharePermissions;

export const SystemRecordPerms = [VIEW];

export enum ShareTypes {
  Entity = 'entity',
  SubFolder = 'subfolder',
}

export const GuestPerms: Record<
  ContentType,
  {
    [ShareTypes.Entity]: SharePermissions[];
    [ShareTypes.SubFolder]: SharePermissions[];
  }
> = {
  // Masters
  [ContentType.MASTER_FOLDER]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, UPLOAD, EDIT, DELETE],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.MASTER_FILE]: {
    [ShareTypes.Entity]: [],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_360RA]: {
    [ShareTypes.Entity]: [],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_FILE_EXPLICIT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.MASTER_FILE_CLEAN]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.MASTER_META]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.MASTER_STEM]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_360RA]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_MEDIA]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_CONTRACT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_DOCUMENT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  // Projects
  [ContentType.PROJECT_FOLDER]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, UPLOAD, EDIT, DELETE],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.PROJECT_LYRICS]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.PROJECT_BEAT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.PROJECT_META]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.PROJECT_MIX]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.PROJECT_IMPORT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.PROJECT_RECORDING]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  // Beats
  [ContentType.BEATS_FOLDER]: { entity: [], [ShareTypes.SubFolder]: [] },
  [ContentType.BEATS_FREE]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD],
  },
  [ContentType.BEATS_IMPORT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, DELETE],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD, DELETE],
  },
  [ContentType.COLLECTION]: {
    [ShareTypes.Entity]: [VIEW],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.ALBUM_COVER]: {
    [ShareTypes.Entity]: [],
    [ShareTypes.SubFolder]: [],
  },
  //Releases
  [ContentType.RELEASES]: {
    [ShareTypes.Entity]: [DELETE],
    [ShareTypes.SubFolder]: [DELETE],
  },
  [ContentType.DROP_SUBMISSION]: {
    [ShareTypes.Entity]: [],
    [ShareTypes.SubFolder]: [],
  },
};

export const EmailPerms: Record<
  ContentType,
  {
    [ShareTypes.Entity]: SharePermissions[];
    [ShareTypes.SubFolder]: SharePermissions[];
  }
> = {
  // Masters
  [ContentType.MASTER_FOLDER]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, UPLOAD],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.MASTER_FILE]: {
    [ShareTypes.Entity]: [],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_360RA]: {
    [ShareTypes.Entity]: [],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_FILE_EXPLICIT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.MASTER_FILE_CLEAN]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.MASTER_META]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.MASTER_STEM]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_360RA]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_MEDIA]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_CONTRACT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.MASTER_DOCUMENT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  // Projects
  [ContentType.PROJECT_FOLDER]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD, UPLOAD],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.PROJECT_LYRICS]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.PROJECT_BEAT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.PROJECT_META]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.PROJECT_MIX]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.PROJECT_IMPORT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.PROJECT_RECORDING]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  // Beats
  [ContentType.BEATS_FOLDER]: { [ShareTypes.Entity]: [], [ShareTypes.SubFolder]: [] },
  [ContentType.BEATS_FREE]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD],
  },
  [ContentType.BEATS_IMPORT]: {
    [ShareTypes.Entity]: [VIEW, DOWNLOAD],
    [ShareTypes.SubFolder]: [VIEW, DOWNLOAD, UPLOAD],
  },
  [ContentType.COLLECTION]: {
    [ShareTypes.Entity]: [VIEW],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.ALBUM_COVER]: {
    [ShareTypes.Entity]: [],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.ALBUM_COVER]: {
    [ShareTypes.Entity]: [VIEW],
    [ShareTypes.SubFolder]: [],
  },
  //Releases
  [ContentType.RELEASES]: {
    [ShareTypes.Entity]: [DELETE],
    [ShareTypes.SubFolder]: [],
  },
  [ContentType.DROP_SUBMISSION]: {
    [ShareTypes.Entity]: [],
    [ShareTypes.SubFolder]: [],
  },
};
