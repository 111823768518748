import getSplitSheetApprove from './services/getSplitSheetApprove';
import postSplitSheet from './services/postSplitSheet';
import getContractSummaries from './services/getContractSummaries';
import postUploadedContract from './services/postUploadedContract';
import getSplitSheet from './services/getSplitSheet';
import patchSplitSheet from './services/patchSplitSheet';
import sendForApproval from './services/sendForApproval';

export default {
  getContractSummaries,
  getSplitSheet,
  getSplitSheetApprove,
  patchSplitSheet,
  postSplitSheet,
  postUploadedContract,
  sendForApproval,
};
