import React, { FC } from 'react';
import { Box, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik, FormikConfig, FormikHelpers } from 'formik';

import Button from 'uikit/button/Button';
import AddToCollectionDialogValues from '../interfaces/addToCollectionDialogValues';
import getAddToCollectionValidationSchema from '../utils/getAddToCollectionValidationSchema';
import { ValidatedValues } from '../types/validatedValues';
import AlbumSelectField from 'common/formik/AlbumSelectField';
import SubmitButton from 'common/formik/SubmitButton';
import { CollectionType } from 'common/models/Collection';
import { Overwrite, SomePartial } from 'common/utilityTypes';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        width: 420,
      },
    },
    form: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
  }),
  { name: 'AddToCollectionDialogContent' }
);

export type AddToCollectionDialogContentProps = Overwrite<
  FormikConfig<AddToCollectionDialogValues>,
  {
    onSubmit: (
      values: ValidatedValues,
      actions: FormikHelpers<AddToCollectionDialogValues>
    ) => void | Promise<any>;
    initialValues: SomePartial<AddToCollectionDialogValues, 'collectionId'>;
    onAddClick?: () => void;
    onClose: () => void;
  }
>;

const AddToCollectionDialogContent: FC<AddToCollectionDialogContentProps> = ({
  onClose,
  onAddClick,
  initialValues,
  validationSchema: schema = getAddToCollectionValidationSchema,
  onSubmit,
  ...formikProps
}) => {
  const classes = useStyles();

  const handleSubmit = React.useCallback(
    (
      values: AddToCollectionDialogValues,
      actions: FormikHelpers<AddToCollectionDialogValues>
    ) => {
      onSubmit(values as ValidatedValues, actions);
    },
    [onSubmit]
  );

  const initValues: AddToCollectionDialogValues = {
    collectionId: undefined,
    collectionType: CollectionType.Lp,
    ...initialValues,
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={initValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        {...formikProps}
      >
        <Form className={classes.form}>
          <DialogHeader
            title={
              initValues.collectionType === CollectionType.Lp
                ? 'Add Master to Album'
                : 'Add Master to EP'
            }
            onClose={onClose}
          />
          <DialogContent>
            <Box p={0.5}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <AlbumSelectField
                    name="collectionId"
                    label="Album"
                    fullWidth
                    onAddClick={onAddClick}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined" dialogAction>
              Cancel
            </Button>
            <SubmitButton variant="contained" color="primary" type="submit" dialogAction>
              Add
            </SubmitButton>
          </DialogActions>
        </Form>
      </Formik>
    </div>
  );
};

export default AddToCollectionDialogContent;
