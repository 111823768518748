import { gql } from '@apollo/client';
import { UserMetaFields } from 'gql/fragments/UserMetaFields';

export const UmRoleFields = gql`
  ${UserMetaFields}

  fragment UmRoleFields on UMRole {
    id
    name
    account
    created_at
    updated_at
    catalog_details @type(name: "UMRoleCatalog") {
      catalog_id
      name
      permissions @type(name: "CatalogPermissions") {
        assigned
        privileges @type(name: "PrivilegeRec") {
          slug
          title
          allowed
        }
        content @type(name: "ContentRec") {
          slug
          title
          allowed
          type_id
          category
        }
      }
    }
    assigned_users @type(name: "UMRoleUser") {
      id
      email
      first_name
      last_name
      meta @type(name: "UMUserMeta") {
        ...UserMetaFields
      }
    }
  }
`;
