import apiShareService from 'api/share/apiShareService';
import { ContentType } from 'common/api/models';
import ShareDialogState from '../interfaces/shareDialogState';
import { notify } from 'common/components/notifMessages/Notify';

export interface ShareContentProps {
  state: ShareDialogState;
  contentType: ContentType;
  ids: number[];
  parentId?: number;
}

const shareContent: (params: ShareContentProps) => void = ({
  state,
  contentType,
  ids,
  parentId,
}) => {
  apiShareService
    .shareContent({
      expirationType: state.expiration * 1,
      type: contentType,
      ids: ids,
      permissions: state.permissions,
      recipients: state.recipient
        ? [
            ...(state.recipient.userId ? [{ user_id: state.recipient.userId }] : []),
            ...(state.recipient.email ? [{ email: state.recipient.email }] : []),
          ]
        : [],
      message: state.message,
      two_factor_auth: state.is2faEnabled,
      parentId,
    })
    .then(() => notify.success('Your Email has been successfully sent!'))
    .catch(() => notify.error('Error while Email sending.'));
};

export default shareContent;
