import getAccessToken from 'common/services/tokenServices/getAccessToken';

import objToQueryString from './utils/objToQueryString';
import { IRequestParams } from './interfaces/IRequestParams';
import { BASE_URL } from './configs/constants';

const GET = async <IParams>(
  endpoint: string,
  params?: IRequestParams & IParams,
  signal?: AbortSignal
): Promise<Response> => {
  const paramsString = objToQueryString(params);
  const token = await getAccessToken();
  const headers = new Headers({
    Accept: 'application/json',
  });

  token && headers.append('Authorization', `Bearer ${token}`);

  const res = await fetch(`${BASE_URL}/${endpoint}${paramsString}`, {
    signal,
    method: 'GET',
    headers: headers,
  });

  if (res.ok) {
    return res;
  }

  throw res;
};

export default GET;
