import SharingItem from 'redux/selectedFile/interfaces/sharingItem';
import { ContentType } from 'common/api/models';
import downloadSubfolder from 'modules/sharing/services/downloadSubfolder';

const sharedDownload = (selectedFile: SharingItem) => {
  switch (selectedFile.typeId as ContentType) {
    case ContentType.MASTER_FILE:
    case ContentType.MASTER_STEM:
    case ContentType.MASTER_MEDIA:
    case ContentType.MASTER_DOCUMENT:
    case ContentType.MASTER_CONTRACT:
    case ContentType.MASTER_FILE_CLEAN: {
      downloadSubfolder(selectedFile);
      return true;
    }
  }
};

export default sharedDownload;
