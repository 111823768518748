import { NO_VALUE } from 'common/api/Constants';
import {
  ContentType,
  MasterAssetIds,
  MasterAssetType,
  ProjectAssetIds,
  ProjectAssetType,
} from 'common/api/models';
import { notify } from 'common/components/notifMessages/Notify';
import {
  ModalConfigActionTypes,
  ModalMasterUploadConfigAction,
  modalOpen,
  ModalUploadAssetConfigAction,
} from 'redux/modal/modalActions';
import SharingItem from 'redux/selectedFile/interfaces/sharingItem';
import { FOLDER_ITEM_ACCEPT_TYPE } from 'config/acceptTypes';
import getModalMasterUploadArgs from 'redux/selectedFile/utils/getModalMasterUploadArgs';

const uploadMasterFile = (file: SharingItem) => {
  const initArgs = getModalMasterUploadArgs(file);
  return (
    initArgs &&
    modalOpen({
      type: ModalConfigActionTypes.MASTER_UPLOAD,
      data: initArgs,
    } as ModalMasterUploadConfigAction)
  );
};

const uploadMasterAsset = (file: SharingItem) => {
  const { typeId, itemTypeId } = file;
  const catalogId = NO_VALUE.CATALOG_ID;
  const masterId = file.parentId;
  const accept = FOLDER_ITEM_ACCEPT_TYPE[itemTypeId];
  const isUploadable = Boolean(catalogId && masterId && typeId && accept !== '');

  return (
    isUploadable &&
    modalOpen({
      type: ModalConfigActionTypes.UPLOAD_ASSET,
      data: {
        params: {
          typeId,
          catalogId,
          masterId,
        },
        props: {
          multiple: true,
          accept,
        },
        isSharing: true,
      },
    } as ModalUploadAssetConfigAction)
  );
};

const uploadProjectAsset = (file: SharingItem) => {
  const { typeId, itemTypeId } = file;
  const catalogId = NO_VALUE.CATALOG_ID;
  const projectId = file.parentId;
  const accept = FOLDER_ITEM_ACCEPT_TYPE[itemTypeId];
  const isUploadable = Boolean(catalogId && projectId && typeId && accept !== '');

  return (
    isUploadable &&
    modalOpen({
      type: ModalConfigActionTypes.UPLOAD_ASSET,
      data: {
        params: {
          typeId,
          catalogId,
          projectId,
        },
        props: {
          multiple: true,
          accept,
        },
        isSharing: true,
      },
    } as ModalUploadAssetConfigAction)
  );
};

const uploadShare = (file: SharingItem) => {
  if (file.typeId === ContentType.MASTER_FILE) return uploadMasterFile(file);

  if (MasterAssetIds.includes(file.typeId as MasterAssetType))
    return uploadMasterAsset(file);

  if (ProjectAssetIds.includes(file.typeId as ProjectAssetType))
    return uploadProjectAsset(file);

  notify.enqueueSnackbar('Uploading of this content type is not supported yet.');
};

export default uploadShare;
