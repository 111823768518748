import { makeStyles, Theme } from '@material-ui/core/styles';
import { IContractParty } from 'common/api/contractModels';
import Roles from 'uikit/roles/Roles';
import Typography from 'uikit/typography/Typography';
import {
  IUContractAction,
  removePartyAction,
  setPartyAdvanceAction,
  setPartyEmailAction,
  setPartyNameAction,
  setPartyPublishingAction,
  setPartyRoleAction,
} from 'common/components/Modals/contractAgreementFormDialog/contractAgreementFormState';
import Select from 'uikit/select/Select';
import { DeleteIconOld } from 'common/icons/system';
import validateEmail from 'common/utils/validateEmail';
import React from 'react';
import PartyRoyaltySelect from './PartyRoyaltySelect';
import PartyRecoupSelect from './PartyRecoupSelect';
import TextField from 'uikit/textField/TextField';
import { IconButton } from 'uikit/iconButton/IconButton';

const useStyles = makeStyles((theme: Theme) => ({
  subHeader: {
    padding: theme.spacing(3, 0, 1, 0),
  },
  partySection: { marginBottom: '15px' },
  partyHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  boldText: {
    marginBottom: '5px',
    fontSize: '1.6rem',
    fontWeight: 600,
  },
  dataRow: {
    display: 'flex',
    margin: '0 -40px 0',
    padding: '10px 40px',
    backgroundColor: theme.palette.grey[50],
  },
  dataCell: {
    paddingLeft: '10px',
    width: '25%',
  },
  inputData: { width: '100%' },
}));

interface IPartiesSectionProps {
  parties: IContractParty[];
  dispatch: React.Dispatch<IUContractAction>;
  setModalConfig: React.Dispatch<
    React.SetStateAction<{ isActive: boolean; id: string | null }>
  >;
}

const PartiesSection: React.FC<IPartiesSectionProps> = ({
  parties,
  dispatch,
  setModalConfig,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.subHeader} variant="h6">
        Contract Parties
      </Typography>

      {parties.map((party, index) => (
        <div key={party.id} className={classes.partySection}>
          <div className={classes.partyHeader}>
            <p className={classes.boldText}>Party {index + 1}</p>
            <IconButton
              size={'small'}
              onClick={() => dispatch(removePartyAction(party.id))}
            >
              <DeleteIconOld />
            </IconButton>
          </div>

          <div className={classes.dataRow}>
            <div className={classes.dataCell}>
              <Select
                dropdownFullWidth
                closeOnClick
                text={party.role}
                title={'Role'}
                placeholder={'Choose Role'}
                containerStyle={{ width: '100%' }}
              >
                <Roles
                  onCreateRequest={() => setModalConfig({ isActive: true, id: party.id })}
                  onSelect={(role) => dispatch(setPartyRoleAction(party.id, role))}
                />
              </Select>
            </div>

            <div className={classes.dataCell}>
              <TextField
                label={'Name'}
                placeholder="Enter Name"
                inputProps={{ maxLength: 50 }}
                value={party.name}
                onChange={(e) => dispatch(setPartyNameAction(party.id, e.target.value))}
                className={classes.inputData}
              />
            </div>

            <div className={classes.dataCell} style={{ width: '50%' }}>
              <TextField
                label={'Email'}
                placeholder="Enter Email"
                inputProps={{ maxLength: 80 }}
                value={party.email}
                onChange={(e) => dispatch(setPartyEmailAction(party.id, e.target.value))}
                className={classes.inputData}
                style={{
                  borderColor: validateEmail(party.email) ? undefined : '#ff0000',
                }}
              />
            </div>
          </div>

          <div className={classes.dataRow}>
            <div className={classes.dataCell}>
              <TextField
                label={'Advance, $'}
                value={party.advance.toString()}
                onChange={(e) =>
                  dispatch(setPartyAdvanceAction(party.id, Number(e.target.value) || 0))
                }
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 100000000,
                  step: 1,
                }}
                className={classes.inputData}
              />
            </div>

            <div className={classes.dataCell}>
              <PartyRoyaltySelect dispatch={dispatch} party={party} />
            </div>

            <div className={classes.dataCell}>
              <PartyRecoupSelect dispatch={dispatch} party={party} />
            </div>

            <div className={classes.dataCell}>
              <TextField
                label={'Publishing, %'}
                value={party.publishing.toString()}
                onChange={(e) =>
                  dispatch(
                    setPartyPublishingAction(party.id, Number(e.target.value) || 0)
                  )
                }
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 100,
                  step: 1,
                }}
                className={classes.inputData}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PartiesSection;
