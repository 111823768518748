import { Reference } from '@apollo/client';
import { FieldPolicy } from '@apollo/client/cache';

export function offsetLimitPagination<T extends Reference = Reference>(): FieldPolicy<
  T[]
> {
  return {
    keyArgs: false,
    merge(existing, incoming, { args }) {
      const merged = existing ? existing.slice(0) : [];
      const start = args?.page?.offset || merged.length;
      const end = start + incoming.length;

      for (let i = start; i < end; ++i) {
        merged[i] = incoming[i - start];
      }

      return merged;
    },
  };
}
