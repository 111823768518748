import { RootState } from 'redux/root-reducer';
import { userIds } from 'common/models/UmRole';
import PrivilegesConfig from 'common/types/privilegesConfig';

export const roleSelector = (state: RootState) => state.roleEditor?.role;

export const roleNameSelector = (state: RootState) =>
  state.roleEditor?.role?.name || 'Untitiled Role';

export const editorCatalogsSelector = (state: RootState) =>
  state.roleEditor?.role?.catalog_details || [];

export const editorCatalogIdsSelector = (state: RootState) =>
  editorCatalogsSelector(state).map((catalog) => catalog.catalog_id);

export const editorUserIdsSelector = (state: RootState) => {
  const role = state?.roleEditor?.role;
  return role ? userIds(role) : [];
};

export const editorUsers = (state: RootState) => {
  return state.roleEditor?.role?.assigned_users || [];
};

export const catalogControls = (state: RootState) => {
  const currentIndex = state.roleEditor.catalogIndex;
  const catalogs = state.roleEditor?.role?.catalog_details || [];

  return catalogs.map((catalog, catalogIndex) => ({
    data: catalog,
    selected: currentIndex === catalogIndex,
    values: catalog.permissions.privileges.reduce((acc, item) => {
      acc[item.slug] = item.allowed;
      return acc;
    }, {} as PrivilegesConfig),
  }));
};

export const disabledSelector = (state: RootState) => {
  const { isSubmitting, disabled } = state.roleEditor || {};

  return isSubmitting || disabled;
};

export const touchedSelector = (state: RootState) => state.roleEditor.touched;
