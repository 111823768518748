import { FieldPolicy } from '@apollo/client';
import { UM_RES_NAME } from 'apollo/plugins/features/constants';

interface AMPFeatures {
  __typename: 'AMPFeatures';
  userManagement: boolean;
}

const AMPFeaturesFieldPolicy: FieldPolicy = {
  read(_, { readField, toReference }): AMPFeatures {
    const isAdmin = !!readField<boolean>('is_admin', readField('getCurrentUser'));
    const umResAccess = !!readField<boolean>(
      'access',
      toReference(`Resources:${UM_RES_NAME}`)
    );
    return {
      __typename: 'AMPFeatures',
      userManagement: umResAccess && isAdmin,
    };
  },
};

export default AMPFeaturesFieldPolicy;
