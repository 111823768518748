import { SimpleAsset } from 'common/interfaces/simpleAsset';
import { FileItem } from 'common/services/downloadServices/interfaces/fileItem';

export default function getFileItems(assets: SimpleAsset[]): FileItem[] {
  return assets
    .sort((a, b) => a.title.localeCompare(b.title))
    .reduce((acc, asset) => {
      if (asset.file?.media_url) {
        return acc.concat({
          title: asset.title,
          url: asset.file.media_url,
        });
      }

      return acc;
    }, [] as FileItem[]);
}
