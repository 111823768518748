import DriveItem from 'redux/selectedFile/interfaces/driveItem';
import { notify } from 'common/components/notifMessages/Notify';
import getSubfolderName from 'common/utils/getMasterSubfolderName';
import getFileItems from 'common/utils/getFileItems';
import downloadFiles from 'common/services/downloadServices/downloadFiles';
import { ContentType } from 'common/api/models';
import getMasterFilesFileItems from 'common/utils/getMasterFilesFileItems';

const downloadSubfolder = async (context: DriveItem) => {
  const archiveName = `${context.parentTitle} (${getSubfolderName(context.typeId)})`;

  function getFiles() {
    switch (context.typeId) {
      case ContentType.MASTER_FILE:
        return getMasterFilesFileItems(context.items);
      default:
        return getFileItems(context.items);
    }
  }

  const files = getFiles();
  if (files.length) {
    const toastId = notify.pending(`Downloading ${archiveName}...`);
    await downloadFiles(files, archiveName);
    notify.closeSnackbar(toastId);
  } else {
    notify.error('Error: no files to download');
  }
};

export default downloadSubfolder;
