import useSelectedFile from 'common/hooks/useSelectedFile/useSelectedFile';

const useSelected = <T extends { id?: string; selected?: boolean }>(props: T) => {
  const current = useSelectedFile();

  if (typeof props.selected === 'boolean') {
    return props.selected;
  }

  return !!props.id && current?.id === props.id;
};

export default useSelected;
