import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import useHasResourceAccess from 'common/components/sidebarNavigation/hooks/useHasResourceAccess';
import SectionItem from 'common/components/sidebarNavigation/components/SectionItem';
import noop from 'common/utils/noop';
import { DISTRIBUTION_URL } from 'common/api/Constants';
import Typography from 'uikit/typography/Typography';
import { Routes } from 'navigation/enums/routes';
import { ReactComponent as ReleasesIcon } from 'assets/navigation/releases.svg';
import { ReactComponent as DistributionIcon } from 'assets/navigation/distribution.svg';

interface DistributionItemProps {
  isCollapsed: boolean;
  isLoading: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      marginTop: theme.spacing(2.75),

      '&:after': {
        content: '""',
        position: 'absolute',
        left: 29,
        top: 0,
        right: 0,
        height: 1,
        backgroundColor: theme.palette.grey[100],
      },
    },
    title: {
      color: theme.palette.grey[200],
      padding: theme.spacing(2.75, 0, 1.5, 3),
    },
  }),
  {
    name: 'DistributionItem',
  }
);

const DistributionItem: FC<DistributionItemProps> = ({
  isLoading,
  isCollapsed,
  onClick = noop,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const hasDistributionAccess = useHasResourceAccess('distribution');
  const isVisible = hasDistributionAccess && DISTRIBUTION_URL;

  return isVisible ? (
    <div className={classes.root}>
      <Typography variant="subtitle2" className={classes.title}>
        Distribution
      </Typography>
      <SectionItem
        loading={isLoading}
        to={DISTRIBUTION_URL || ''}
        isActive={false}
        isCollapsed={isCollapsed}
        IconComponent={DistributionIcon}
        label="Dashboard"
        onClick={onClick}
        isExternalLink={true}
      />
      <SectionItem
        loading={isLoading}
        to={Routes.Releases}
        isActive={location.pathname.includes(Routes.Releases)}
        isCollapsed={isCollapsed}
        IconComponent={ReleasesIcon}
        label="Releases"
        onClick={onClick}
      />
    </div>
  ) : null;
};

export default DistributionItem;
