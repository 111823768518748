import React, { FC, MouseEventHandler } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Avatar, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import UserState from 'redux/user/interfaces/userState';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      cursor: 'pointer',
    },
    avatarWrap: {
      cursor: 'pointer',
      width: 40,
      height: 40,
      background: theme.palette.grey.A100,
      borderRadius: '50%',
    },
  }),
  { name: 'HeaderMenuButton' }
);

interface HeaderMenuButtonProps {
  userState: UserState;
  onClick: MouseEventHandler;
}

const HeaderMenuButton: FC<HeaderMenuButtonProps> = ({
  userState: { data: user, loading: isUserLoading },
  onClick,
}) => {
  const classes = useStyles();

  return (
    <div onClick={onClick}>
      {isUserLoading ? (
        <Skeleton variant={'circle'} width={40} height={40} />
      ) : (
        <div className={classes.avatarWrap}>
          <Avatar src={user?.profile?.avatar || undefined} />
        </div>
      )}
    </div>
  );
};

export default HeaderMenuButton;
