import Button from 'uikit/button/Button';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import React, { useCallback, useState } from 'react';
import { DialogActions, DialogContent } from '@material-ui/core';
import TextField from 'uikit/textField/TextField';

type Props = {
  closeModal: () => void;
  selectRole: (role: string) => void;
};

const CreateRoleModal: React.FC<Props> = ({ closeModal, selectRole }) => {
  const [name, setName] = useState('');

  const setCreatedRole = useCallback(() => {
    selectRole(name);
    closeModal();
  }, [closeModal, name, selectRole]);

  return (
    <>
      <DialogHeader onClose={closeModal} title="Create New Role" />
      <DialogContent>
        <TextField
          fullWidth
          label={'Name'}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter Roles’s name"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} variant="outlined" dialogActionBase>
          Cancel
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={setCreatedRole}
          dialogActionBase
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateRoleModal;
