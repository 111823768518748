import ShareResponse from 'api/share/interfaces/shareResponse';
import { ContentType } from 'common/api/models';
import { ISharedCollection } from 'modules/sharing/api/models';

const patchCollectionData = (data: ShareResponse): ShareResponse => {
  if (data.type_id === ContentType.COLLECTION) {
    const patchedData = data.data.map((item) => ({
      ...item,
      type_id: ContentType.COLLECTION,
    }));

    return {
      ...data,
      data: patchedData as ISharedCollection[],
    };
  }

  return data;
};

export default patchCollectionData;
