import PreparePayload from 'redux/fileUpload/types/preparePayload';
import BeatsParams from 'redux/fileUpload/interfaces/beatsParams';
import MasterParams from 'redux/fileUpload/interfaces/masterParams';
import ProjectParams from 'redux/fileUpload/interfaces/projectParams';
import CommonAsset from 'redux/fileUpload/interfaces/commonAsset';

export enum FileUploadActionTypes {
  IDLE = 'IDLE',
  PREPARING = 'PREPARING',
  UPLOADING = 'UPLOADING',
  DONE = 'DONE',
}

export enum UploadingActionTypes {
  START = '@FILE_UPLOAD/START',
  PREPARE = '@FILE_UPLOAD/PREPARE',
  SET_PROGRESS = '@FILE_UPLOAD/SET_PROGRESS',
  SET_ERROR = '@FILE_UPLOAD/SET_ERROR',
  SET_SUCCESS = '@FILE_UPLOAD/SET_SUCCESS',
  REMOVE_FILE = '@FILE_UPLOAD/REMOVE_FILE',
  END = '@FILE_UPLOAD/END',
  RESET = '@FILE_UPLOAD/RESET',
}

export interface SetProgressAction {
  type: UploadingActionTypes.SET_PROGRESS;
  payload: {
    index: number;
    total: number;
    loaded: number;
  };
}

export const setProgress = (
  payload: SetProgressAction['payload']
): SetProgressAction => ({
  type: UploadingActionTypes.SET_PROGRESS,
  payload,
});

export interface SetSuccessAction {
  type: UploadingActionTypes.SET_SUCCESS;
  payload: {
    index: number;
    value: CommonAsset;
  };
}

export const setSuccess = (payload: SetSuccessAction['payload']): SetSuccessAction => ({
  type: UploadingActionTypes.SET_SUCCESS,
  payload,
});

export interface SetErrorAction {
  type: UploadingActionTypes.SET_ERROR;
  payload: {
    index: number;
    value: Error;
  };
}

export const setError = (payload: SetErrorAction['payload']): SetErrorAction => ({
  type: UploadingActionTypes.SET_ERROR,
  payload,
});

export interface EndAction {
  type: UploadingActionTypes.END;
  payload: { loaded: number };
}

export const end = (payload: EndAction['payload']): EndAction => ({
  type: UploadingActionTypes.END,
  payload,
});

export type PrepareAction = {
  type: UploadingActionTypes.PREPARE;
  payload:
    | PreparePayload<BeatsParams>
    | PreparePayload<MasterParams>
    | PreparePayload<ProjectParams>;
};

export const prepare = (payload: PrepareAction['payload']): PrepareAction => ({
  type: UploadingActionTypes.PREPARE,
  payload,
});

export interface StartAction {
  type: UploadingActionTypes.START;
}

export const start = (): StartAction => ({
  type: UploadingActionTypes.START,
});

export interface ResetAction {
  type: UploadingActionTypes.RESET;
}

export const reset = (): ResetAction => ({
  type: UploadingActionTypes.RESET,
});

export interface RemoveFileAction {
  type: UploadingActionTypes.REMOVE_FILE;
  payload: { index: number };
}

export const removeFile = (payload: RemoveFileAction['payload']): RemoveFileAction => ({
  type: UploadingActionTypes.REMOVE_FILE,
  payload,
});

export type FileUploadActions =
  | StartAction
  | PrepareAction
  | SetProgressAction
  | SetErrorAction
  | SetSuccessAction
  | EndAction
  | ResetAction
  | RemoveFileAction;
