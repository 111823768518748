import React, { FC } from 'react';

import AddToCollectionDialogContent, {
  AddToCollectionDialogContentProps,
} from './components/AddToCollectionDialogContent';
import { CollectionType } from 'common/models/Collection';
import { Master } from 'common/models/Master';
import addToCollInitValues from 'common/components/Modals/addToCollectionDialog/utils/addToCollInitValues';
import useAddToCollSubmit from 'common/components/Modals/addToCollectionDialog/hooks/useAddToCollSubmit';
import useCreateWithMaster from 'common/components/Modals/addToCollectionDialog/hooks/useCreateWithMaster';

export interface AddToCollectionDialogContainerProps {
  master: Master;
  collectionType: CollectionType;
  onClose: AddToCollectionDialogContentProps['onClose'];
}

const AddToCollectionDialog: FC<AddToCollectionDialogContainerProps> = ({
  master,
  collectionType,
  onClose,
}) => {
  const onSubmit = useAddToCollSubmit();
  const onAddClick = useCreateWithMaster(master, collectionType);

  return (
    <AddToCollectionDialogContent
      onClose={onClose}
      onSubmit={onSubmit}
      onAddClick={onAddClick}
      initialValues={addToCollInitValues(master, collectionType)}
    />
  );
};

export default AddToCollectionDialog;
