import ContractsPaths from 'api/contracts/enums/contractsPaths';
import axios from 'common/api/axios';
import SplitSheetApproveData from 'modules/masters/interfaces/splitSheetApproveData';

const getSplitSheetApprove: (token: string) => Promise<SplitSheetApproveData> = async (
  token
) => {
  const { data } = await axios.get<SplitSheetApproveData>(
    `${ContractsPaths.AcceptedView}${token}`
  );

  return {
    title: data.title,
    approvers: data.approvers.filter((approver) => !approver.accepted),
    state: data.state,
  };
};

export default getSplitSheetApprove;
