import ReactGa from 'react-ga';
import * as React from 'react';

const useGAEffect = (): void => {
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production' && !!process.env.REACT_APP_GA_TRACKING_ID) {
      ReactGa.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);
      ReactGa.pageview(window.location.pathname);
    }
  }, []);
};

export default useGAEffect;
