import { useSnackbar } from 'notistack';
import { useAddToAlbumMutation } from 'generated/graphql';
import { useCallback } from 'react';
import AddToCollParams from 'common/hooks/useAddToCollService/interfaces/addToCollParams';
import { ERROR_MESSAGES } from 'config/errorsConfig';

const useAddToCollService = () => {
  const notify = useSnackbar();
  const [addToCollection] = useAddToAlbumMutation();

  return useCallback(
    async (values: AddToCollParams) => {
      try {
        const coll = await addToCollection({
          variables: {
            collectionId: values.collectionId,
            masterId: values.masterId,
          },
        });

        notify.enqueueSnackbar('Added successfully', { variant: 'success' });

        return coll;
      } catch (error) {
        notify.enqueueSnackbar(ERROR_MESSAGES.ALREADY_IN_COLL, {
          variant: 'error',
        });
      }
    },
    [notify, addToCollection]
  );
};

export default useAddToCollService;
