import validateSharingData from 'common/components/Modals/shareDialog/utils/validateSharingData';
import * as ShareDialogActions from 'common/components/Modals/shareDialog/store/shareDialogActions';
import { notify } from 'common/components/notifMessages/Notify';
import Recipient from 'common/components/Modals/shareDialog/interfaces/recipient';
import { SharedLinkExpiration, SharePermissions } from 'common/models/sharing';
import { Dispatch, useReducer } from 'react';
import shareDialogReducer from 'common/components/Modals/shareDialog/store/shareDialogReducer';
import { ShareContentProps } from 'common/components/Modals/shareDialog/utils/shareContent';
import { ContentType } from 'common/api/models';
import ShareDialogState from 'common/components/Modals/shareDialog/interfaces/shareDialogState';
import { ShareDialogAction } from 'common/components/Modals/shareDialog/store/shareDialogActions';
import apiShareService from 'api/share/apiShareService';

export interface UseShareRes {
  shareHandler: () => void;
  copyShareLink: () => void;
  recipientChangeHandler: (recipient: Recipient) => void;
  expirationChangeHandler: (expiration: SharedLinkExpiration) => void;
  state: ShareDialogState;
  dispatch: Dispatch<ShareDialogAction>;
}

export interface UseShareProps {
  onClose: () => void;
  onShare: (params: ShareContentProps) => void;
  ids: number[];
  contentType: ContentType;
  parentId?: number;
}

const useShareDialog: (props: UseShareProps) => UseShareRes = ({
  onShare,
  onClose,
  contentType,
  ids,
  parentId,
}) => {
  const [state, dispatch] = useReducer(shareDialogReducer, {
    recipient: {},
    message: '',
    permissions: [SharePermissions.VIEW],
    expiration: -1,
    is2faEnabled: false,
    validation: {
      email: true,
      expiration: true,
    },
  });

  const shareHandler = () => {
    const validationData = validateSharingData(state);
    dispatch(ShareDialogActions.setValidation({ value: validationData }));
    if (validationData.expiration && validationData.email) {
      onShare({ state, contentType, ids, parentId });
      onClose();
    } else {
      notify.error('Please check if you filled all the necessary fields correctly.');
    }
  };

  const recipientChangeHandler = (recipient: Recipient): void => {
    dispatch(ShareDialogActions.setRecipient({ value: recipient }));
    if (!state.validation.email) {
      dispatch(
        ShareDialogActions.setValidation({
          value: {
            ...state.validation,
            email: true,
          },
        })
      );
    }
  };

  const expirationChangeHandler = (expiration: SharedLinkExpiration): void => {
    dispatch(
      ShareDialogActions.setExpiration({
        value: expiration,
      })
    );
    if (!state.validation.expiration) {
      dispatch(
        ShareDialogActions.setValidation({
          value: {
            ...state.validation,
            expiration: true,
          },
        })
      );
    }
  };

  const copyShareLink = async () => {
    try {
      const data = await apiShareService.getShareLink({
        type: contentType,
        ids,
      });
      await navigator.clipboard.writeText(data.link);
      notify.success('Link copied to clipboard!');
    } catch {
      notify.error('Something went wrong, please try again later');
    }
  };

  return {
    expirationChangeHandler,
    recipientChangeHandler,
    shareHandler,
    state,
    dispatch,
    copyShareLink,
  };
};

export default useShareDialog;
