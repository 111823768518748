import React, { FC } from 'react';

import { ReactComponent as UserIcon } from 'assets/navigation/users.svg';
import { ReactComponent as RoleIcon } from 'assets/navigation/settings.svg';
import { ReactComponent as AccountIcon } from 'assets/navigation/account.svg';
import { ReactComponent as LogoutIcon } from 'assets/navigation/logout.svg';
import HeaderMenuActionTypes from 'common/components/headerMenu/enums/HeaderMenuActionTypes';

interface IconsProps {
  icon: HeaderMenuActionTypes;
}

const HeaderMenuIcon: FC<IconsProps> = ({ icon }) => {
  switch (icon) {
    case HeaderMenuActionTypes.Users:
      return <UserIcon />;
    case HeaderMenuActionTypes.Roles:
      return <RoleIcon />;
    case HeaderMenuActionTypes.Profile:
    case HeaderMenuActionTypes.ArtistProfile:
      return <AccountIcon />;
    case HeaderMenuActionTypes.LogOut:
      return <LogoutIcon />;
    default:
      return null;
  }
};

export default HeaderMenuIcon;
