import createProjectAsset from './services/createProjectAsset';
import createMasterAsset from './services/createMasterAsset';
import createMaster from './services/createMaster';
import getLyrics from './services/getLyrics';
import getMasterName from './services/getMasterName';
import deleteAsset from './services/deleteAsset';
import distribution from './services/distribution';
import updateAsset from './services/updateAsset';
import uploadBeat from './services/uploadBeat';
import createProject from './services/createProject';
import createLyrics from './services/createLyrics';
import updateLyrics from './services/updateLyrics';

export default {
  createLyrics,
  createMaster,
  createMasterAsset,
  createProject,
  createProjectAsset,
  deleteAsset,
  distribution,
  getLyrics,
  getMasterName,
  updateAsset,
  updateLyrics,
  uploadBeat,
};
