import React, { FC } from 'react';
import clsx from 'clsx';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { UserFieldsFragment } from 'generated/graphql';
import { ChevronRight } from 'common/icons/system';
// import FeedItem from 'common/components/sidebarNavigation/components/FeedItem';
import DropsItem from './DropsItem';
import DriveNavItems from 'common/components/sidebarNavigation/components/DriveNavItems';
import DistributionItem from 'common/components/sidebarNavigation/components/DistributionItem';
import StorageInfo from 'common/components/sidebarNavigation/components/StorageInfo';
import { IconButton } from 'uikit/iconButton/IconButton';
import UserInfo from 'common/components/sidebarNavigation/components/UserInfo';
import noop from 'common/utils/noop';

const useStyles = makeStyles(
  (theme: Theme) => ({
    paper: {
      overflow: 'visible',
    },
    container: {
      position: 'relative',
      flexShrink: 0,
      width: 240,
      ...theme.mixins.contentHeight,
      borderRight: `1px solid ${theme.palette.grey[100]}`,
      transition: '0.15s ease-in-out',
      '&$collapsed': {
        width: 80,
      },
      '&$mobile': {
        height: '100%',
      },
    },
    collapseBtnContainer: {
      position: 'absolute',
      zIndex: 5,
      top: 64,
      right: -16,
      backgroundColor: theme.palette.background.default,
      border: `solid 1px ${theme.palette.grey[100]}`,
      borderRadius: '50%',
    },
    collapseTrigger: {
      transform: 'rotateZ(180deg)',
      '&$collapsed': {
        transform: 'rotateZ(0deg)',
      },
    },
    section: {
      padding: theme.spacing(2, 0),
    },
    sectionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    collapsed: {},
    mobile: {},
  }),
  { name: 'Navigation' }
);

interface NavigationProps {
  isOpened: boolean;
  isLoading: boolean;
  isMobile: boolean;
  onUserClick?: () => void;
  handleItemClick: () => void;
  toggleNav: () => void;
  user?: UserFieldsFragment;
}

const Navigation: FC<NavigationProps> = ({
  toggleNav,
  user,
  isOpened,
  handleItemClick,
  onUserClick = noop,
  isLoading,
  isMobile,
}) => {
  const classes = useStyles();

  return (
    <nav
      className={clsx([
        classes.container,
        { [classes.collapsed]: !isOpened },
        { [classes.mobile]: isMobile },
      ])}
    >
      <Box className={classes.collapseBtnContainer}>
        <IconButton
          size={'small'}
          onClick={toggleNav}
          className={clsx([classes.collapseTrigger, { [classes.collapsed]: !isOpened }])}
        >
          <ChevronRight />
        </IconButton>
      </Box>
      <div className={classes.sectionsContainer}>
        <div>
          {isMobile && (
            <UserInfo
              isLoading={isLoading}
              isOpened={isOpened}
              user={user}
              onClick={onUserClick}
            />
          )}
          <div className={classes.section}>
            {/* <FeedItem
              onItemClick={handleItemClick}
              isLoading={isLoading}
              isCollapsed={!isOpened}
            /> */}
            <DriveNavItems
              isLoading={isLoading}
              isCollapsed={!isOpened}
              onItemClick={handleItemClick}
            />
            <DropsItem
              onItemClick={handleItemClick}
              isLoading={isLoading}
              isCollapsed={!isOpened}
            />
            <DistributionItem
              isCollapsed={!isOpened}
              isLoading={isLoading}
              onClick={handleItemClick}
            />
          </div>
        </div>
        <div>
          {user && user.account && (
            <StorageInfo
              isOpened={isOpened}
              isLoading={isLoading}
              account={user.account}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
