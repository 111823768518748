import { useState, useCallback } from 'react';

import apiShareService from 'api/share/apiShareService';
import { ResolvedValue } from 'common/utilityTypes';

type GuestP9s = ResolvedValue<typeof apiShareService.getGuestPerms>;

const useGetGuestP9s = () => {
  const [state, setState] = useState<{
    data?: GuestP9s;
    loading: boolean;
    error?: unknown;
  }>({
    data: undefined,
    loading: true,
  });

  const load = useCallback(async () => {
    setState((prev) => {
      if (!prev.loading) {
        return { ...prev, loading: true };
      }

      return prev;
    });
    try {
      const result = await apiShareService.getGuestPerms();
      setState({
        data: result,
        loading: false,
      });
    } catch (error) {
      setState({
        error,
        loading: false,
      });
    }
  }, [setState]);

  // Using useState to call load only on initialRender;
  useState(load);

  return {
    ...state,
    refetch: load,
  };
};

export default useGetGuestP9s;
