import loadAccessToken from 'common/services/tokenServices/loadAccessToken';
import moment from 'moment';

const useIsTokenExpired: () => boolean = () => {
  try {
    const token = loadAccessToken();

    if (!token) return true;

    const [, tokenPayload] = token.split('.');

    const { exp } = JSON.parse(atob(tokenPayload));

    return !exp || exp <= moment().unix();
  } catch {
    return true;
  }
};

export default useIsTokenExpired;
