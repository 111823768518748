import { QueryResult } from '@apollo/client';

import GuestUser from 'common/interfaces/guestUser';
import { GetGuestUsersQuery } from 'generated/graphql';

const transformRawGuestUsersData = (
  res: QueryResult<GetGuestUsersQuery>
): GuestUser[] => {
  const guestUsers = res.data?.getGuestUsers || [];

  return guestUsers.map((user) => ({
    id: user.id,
    email: user.email || '',
    ...(!!user.first_name ? { firstName: user.first_name } : {}),
    ...(!!user.last_name ? { lastName: user.last_name } : {}),
  }));
};

export default transformRawGuestUsersData;
