import noop from 'common/utils/noop';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import useAddMasterService from 'common/api/useAddMasterService';
import ModalMasterUpload, {
  ModalMasterUploadProps,
} from 'common/components/Modals/ModalMasterUpload/ModalMasterUpload';
import { ModalSubmitHandler } from './reducer';
import { setDefaultSelected } from 'redux/selectedFile/selectedFileActions';
import { ContentType } from 'common/api/models';
import { setFilter } from 'redux/filters/filtersActions';
import { notify } from 'common/components/notifMessages/Notify';
import AmpService from 'common/api/AmpService';
import Filter from 'redux/filters/enums/filter';

function useSubmitNewMaster(
  onSuccessUpload: (id: string) => void,
  onClose: () => void,
  queueNewMaster = true
): ModalSubmitHandler {
  const dispatch = useDispatch();
  const addMaster = useAddMasterService();

  return async (values, actions) => {
    const onProgress = (event: ProgressEvent, index: number) => {
      const progress = Math.floor((event.loaded / event.total) * 100);

      actions.setProgress(index, progress);
    };

    actions.setUploading(true);

    try {
      const result = await addMaster(
        {
          catalogId: values.catalogId,
          masterName: values.masterName,
          files: values.files,
          customizeMaster: values.customizeMaster,
        },
        { onProgress }
      );

      if (result.master) {
        dispatch(
          setFilter({
            filter: Filter.catalog,
            value: { id: values.catalogId, name: values.catalogName },
          })
        );

        dispatch(setDefaultSelected(`${result.master.id}:${ContentType.MASTER_FILE}`));
        if (queueNewMaster) {
          if (AmpService.areCatalogMastersLoaded(values.catalogId)) {
            await AmpService.queryNewMaster({
              catalogId: values.catalogId,
              newMasterId: result.master.id,
            });
          }
        }
        onSuccessUpload(result.master.id.toString());
      }
    } catch (error) {
      notify.enqueueSnackbar(`Error: ${error}`, { variant: 'error' });
    }

    onClose();
  };
}

const AddMasterDialog = (props: Omit<ModalMasterUploadProps, 'onSubmit'>) => {
  const onSubmit = useSubmitNewMaster(
    props.config.onUpload || noop,
    props.onClose,
    props.config.queueNewMaster
  );

  return <ModalMasterUpload {...props} onSubmit={onSubmit} />;
};

export default AddMasterDialog;
