import { ContentType, FolderItem } from 'common/api/models';
import {
  ISharedMasterUploadedContract,
  ISharedMasterSplitSheet,
  ISharedDataItem,
} from 'modules/sharing/api/models';

export const isSharedUploaded = (
  contract: object
): contract is ISharedMasterUploadedContract =>
  contract && contract.hasOwnProperty('party');

export const isSharedSplitSheet = (
  contract: object
): contract is ISharedMasterSplitSheet =>
  contract &&
  contract.hasOwnProperty('approvers') &&
  contract.hasOwnProperty('reviewers');

export function getSharedFolderItemSubLine(
  itemTypeId: FolderItem,
  typeId: ContentType,
  sharedItems: ISharedDataItem[]
) {
  let itemsNumber = 0;
  switch (itemTypeId) {
    case FolderItem.DOCUMENTS:
    case FolderItem.CONTRACTS:
      itemsNumber = sharedItems.filter(
        (item) =>
          item.type_id === ContentType.MASTER_DOCUMENT ||
          item.type_id === ContentType.MASTER_CONTRACT
      ).length;
      return `${itemsNumber} items`;
    case FolderItem.MASTER_FILES:
      itemsNumber = sharedItems.filter(
        (item) =>
          item.type_id === ContentType.MASTER_FILE_CLEAN ||
          item.type_id === ContentType.MASTER_FILE_EXPLICIT
      ).length;
      return `${itemsNumber} items`;
    case FolderItem.LYRICS:
    case FolderItem.META:
      return '';
    default:
      itemsNumber = sharedItems.filter((item) => item.type_id === typeId).length;
      return `${itemsNumber} items`;
  }
}
