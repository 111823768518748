import { MasterFieldsFragment, useGetMasterWithAssetsQuery } from 'generated/graphql';

interface UseGetMasterFolderRes {
  loading: boolean;
  masterFolder: MasterFieldsFragment | null;
  error?: Error | null;
}
interface UseGetMasterFolderProps {
  folderId: number;
}

const useGetMasterFolder: (props: UseGetMasterFolderProps) => UseGetMasterFolderRes = ({
  folderId,
}) => {
  const { loading, data, error } = useGetMasterWithAssetsQuery({
    variables: {
      id: folderId,
    },
  });

  return { loading, masterFolder: data?.getMaster || null, error: error || null };
};

export default useGetMasterFolder;
