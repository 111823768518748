import DriveItem from 'redux/selectedFile/interfaces/driveItem';
import getModalMasterUploadArgs from 'redux/selectedFile/utils/getModalMasterUploadArgs';
import {
  ModalConfigActionTypes,
  ModalMasterUploadConfigAction,
  modalOpen,
} from 'redux/modal/modalActions';

const uploadMasterFile = (file: DriveItem) => {
  const initArgs = getModalMasterUploadArgs(file);
  return (
    initArgs &&
    modalOpen({
      type: ModalConfigActionTypes.MASTER_UPLOAD,
      data: initArgs,
    } as ModalMasterUploadConfigAction)
  );
};

export default uploadMasterFile;
