import validateEmail from 'common/utils/validateEmail';

// ACTION TYPES
interface IAddApproverAction {
  type: '@SPLITSHEET/ADD_APPROVER';
}
interface IDeleteApproverAction {
  type: '@SPLITSHEET/DELETE_APPROVER';
  payload: { id: string };
}
interface ISetApproverNameAction {
  type: '@SPLITSHEET/SET_APPROVER_NAME';
  payload: { id: string; name: string };
}
interface ISetApproverEmailAction {
  type: '@SPLITSHEET/SET_APPROVER_EMAIL';
  payload: { id: string; email: { address: string; isValid: boolean } };
}
interface ISetApproverRoleAction {
  type: '@SPLITSHEET/SET_APPROVER_ROLE';
  payload: { id: string; role: string };
}
interface ISetApproverAffiliationAction {
  type: '@SPLITSHEET/SET_APPROVER_AFFILIATION';
  payload: { id: string; affiliation: string };
}
interface ISetApproverOwnershipAction {
  type: '@SPLITSHEET/SET_APPROVER_OWNERSHIP';
  payload: { id: string; ownership: number };
}
interface ISetApproverCanViewAction {
  type: '@SPLITSHEET/SET_APPROVER_CAN_VIEW_ALL';
  payload: { id: string; canViewAll: boolean };
}

export type IApproverAction =
  | IAddApproverAction
  | IDeleteApproverAction
  | ISetApproverNameAction
  | ISetApproverEmailAction
  | ISetApproverRoleAction
  | ISetApproverAffiliationAction
  | ISetApproverOwnershipAction
  | ISetApproverCanViewAction;

// ACTIONS
export const addApproverAction = (): IAddApproverAction => ({
  type: '@SPLITSHEET/ADD_APPROVER',
});
export const deleteApproverAction = (id: string): IDeleteApproverAction => ({
  type: '@SPLITSHEET/DELETE_APPROVER',
  payload: { id },
});
export const setApproverNameAction = (
  id: string,
  name: string
): ISetApproverNameAction => ({
  type: '@SPLITSHEET/SET_APPROVER_NAME',
  payload: { id, name },
});
export const setApproverEmailAction = (
  id: string,
  email: string
): ISetApproverEmailAction => ({
  type: '@SPLITSHEET/SET_APPROVER_EMAIL',
  payload: {
    id,
    email: { address: email, isValid: email ? validateEmail(email) : true },
  },
});
export const setApproverRoleAction = (
  id: string,
  role: string
): ISetApproverRoleAction => ({
  type: '@SPLITSHEET/SET_APPROVER_ROLE',
  payload: { id, role },
});
export const setApproverAffiliationAction = (
  id: string,
  affiliation: string
): ISetApproverAffiliationAction => ({
  type: '@SPLITSHEET/SET_APPROVER_AFFILIATION',
  payload: { id, affiliation },
});
export const setApproverOwnershipAction = (
  id: string,
  ownership: number
): ISetApproverOwnershipAction => ({
  type: '@SPLITSHEET/SET_APPROVER_OWNERSHIP',
  payload: { id, ownership },
});
export const setApproverCanViewAllAction = (
  id: string,
  canViewAll: boolean
): ISetApproverCanViewAction => ({
  type: '@SPLITSHEET/SET_APPROVER_CAN_VIEW_ALL',
  payload: { id, canViewAll },
});
