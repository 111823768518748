import { LoginActions, LoginActionTypes } from 'redux/login/loginActions';
import MastersSectionContent from 'redux/filters/enums/mastersSectionContent';
import Filter from 'redux/filters/enums/filter';
import { FilterActions, FilterActionTypes } from 'redux/filters/filtersActions';
import FiltersState from 'redux/filters/interfaces/filtersState';

const initState: FiltersState = {
  catalog: null,
  searchQuery: null,
  mastersSectionContent: {
    filter: Filter.mastersSectionContent,
    value: MastersSectionContent.SINGLE,
  },
};

export default function filtersReducer(
  state = initState,
  action: FilterActions | LoginActions
): FiltersState {
  switch (action.type) {
    case LoginActionTypes.Logout:
      return { ...initState };
    case FilterActionTypes.SET_FILTER:
      return {
        ...state,
        [action.payload.filter]: action.payload,
      };
    default:
      return state;
  }
}
