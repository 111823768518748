import moment from 'moment';
import generateID from 'common/utils/generateID';
import { ISplitSheetAction } from 'modules/splitSheet/redux/actions';
import { ISplitSheetData } from 'modules/splitSheet/redux/model';
import uniqueId from 'lodash/uniqueId';
import { ContractWorkflowState as ContractState } from 'generated/graphql';

export type ErrorSplitSheet = { message: string; status: number };

type FetchBase<Data> = {
  loading: boolean;
  error: ErrorSplitSheet | null;
  edited: boolean;
  data: Data;
};

const initState = (): FetchBase<ISplitSheetData> => ({
  loading: false,
  error: null,
  edited: false,
  data: {
    songTitle: '',
    aka: '',
    date: moment(),
    approvers: [],
    reviewers: [],
    state: ContractState.DRAFT,
    master_id: null,
    splitSheetId: null,
  },
});

function editDataReducer(
  state = initState(),
  action: ISplitSheetAction
): FetchBase<ISplitSheetData> {
  switch (action.type) {
    case '@SPLITSHEET/SET_AKA':
    case '@SPLITSHEET/SET_DATE':
    case '@SPLITSHEET/ADD_APPROVER':
    case '@SPLITSHEET/DELETE_APPROVER':
    case '@SPLITSHEET/SET_APPROVER_NAME':
    case '@SPLITSHEET/SET_APPROVER_EMAIL':
    case '@SPLITSHEET/SET_APPROVER_ROLE':
    case '@SPLITSHEET/SET_APPROVER_AFFILIATION':
    case '@SPLITSHEET/SET_APPROVER_OWNERSHIP':
    case '@SPLITSHEET/SET_APPROVER_CAN_VIEW_ALL':
    case '@SPLITSHEET/ADD_REVIEWER':
    case '@SPLITSHEET/DELETE_REVIEWER':
    case '@SPLITSHEET/SET_REVIEWER_NAME':
    case '@SPLITSHEET/SET_REVIEWER_EMAIL':
    case '@SPLITSHEET/SET_REVIEWER_CAN_VIEW_ALL':
    case '@SPLITSHEET/SET_REVIEWER_TRACKING_ID':
      return { ...state, edited: true };
    default:
      return state;
  }
}

function splitSheetReducer(
  state = initState(),
  action: ISplitSheetAction
): FetchBase<ISplitSheetData> {
  state = editDataReducer(state, action);

  switch (action.type) {
    case '@SPLITSHEET/SET_SONG_NAME':
      return { ...state, data: { ...state.data, songTitle: action.payload } };
    case '@SPLITSHEET/SET_AKA':
      return { ...state, data: { ...state.data, aka: action.payload } };
    case '@SPLITSHEET/SET_DATE':
      return { ...state, data: { ...state.data, date: action.payload } };
    case '@SPLITSHEET/SET_MASTER_ID':
      return { ...state, data: { ...state.data, master_id: action.payload } };
    case '@SPLITSHEET/ADD_APPROVER':
      return {
        ...state,
        data: {
          ...state.data,
          approvers: [
            ...state.data.approvers,
            {
              id: generateID(),
              name: '',
              email: { address: '', isValid: true },
              role: '',
              affiliation: '',
              ownership:
                100 -
                state.data.approvers
                  .map((approver) => approver.ownership)
                  .reduce((prevVal, currVal) => prevVal + currVal, 0),
              accepted: false,
              canViewAll: false,
              trackingId: parseInt(uniqueId()),
            },
          ],
        },
      };
    case '@SPLITSHEET/DELETE_APPROVER':
      return {
        ...state,
        data: {
          ...state.data,
          approvers: state.data.approvers.filter(
            (approver) => approver.id !== action.payload.id
          ),
        },
      };
    case '@SPLITSHEET/SET_APPROVER_NAME':
      return {
        ...state,
        data: {
          ...state.data,
          approvers: state.data.approvers.map((approver) => {
            if (approver.id === action.payload.id) {
              return { ...approver, name: action.payload.name };
            }
            return approver;
          }),
        },
      };
    case '@SPLITSHEET/SET_APPROVER_EMAIL':
      return {
        ...state,
        data: {
          ...state.data,
          approvers: state.data.approvers.map((approver) => {
            if (approver.id === action.payload.id) {
              return { ...approver, email: action.payload.email };
            }
            return approver;
          }),
        },
      };
    case '@SPLITSHEET/SET_APPROVER_ROLE':
      return {
        ...state,
        data: {
          ...state.data,
          approvers: state.data.approvers.map((approver) => {
            if (approver.id === action.payload.id) {
              return { ...approver, role: action.payload.role };
            }
            return approver;
          }),
        },
      };
    case '@SPLITSHEET/SET_APPROVER_AFFILIATION':
      return {
        ...state,
        data: {
          ...state.data,
          approvers: state.data.approvers.map((approver) => {
            if (approver.id === action.payload.id) {
              return { ...approver, affiliation: action.payload.affiliation };
            }
            return approver;
          }),
        },
      };
    case '@SPLITSHEET/SET_APPROVER_OWNERSHIP':
      return {
        ...state,
        data: {
          ...state.data,
          approvers: state.data.approvers.map((approver) => {
            if (approver.id === action.payload.id) {
              return { ...approver, ownership: action.payload.ownership };
            }
            return approver;
          }),
        },
      };
    case '@SPLITSHEET/SET_APPROVER_CAN_VIEW_ALL':
      return {
        ...state,
        data: {
          ...state.data,
          approvers: state.data.approvers.map((approver) => {
            if (approver.id === action.payload.id) {
              return { ...approver, canViewAll: action.payload.canViewAll };
            }
            return approver;
          }),
        },
      };
    case '@SPLITSHEET/ADD_REVIEWER':
      return {
        ...state,
        data: {
          ...state.data,
          reviewers: [
            ...state.data.reviewers,
            {
              id: generateID(),
              name: '',
              email: { address: '', isValid: true },
              canViewAll: false,
              trackedApprover: null,
            },
          ],
        },
      };
    case '@SPLITSHEET/DELETE_REVIEWER':
      return {
        ...state,
        data: {
          ...state.data,
          reviewers: state.data.reviewers.filter(
            (reviewer) => reviewer.id !== action.payload.id
          ),
        },
      };
    case '@SPLITSHEET/SET_REVIEWER_NAME':
      return {
        ...state,
        data: {
          ...state.data,
          reviewers: state.data.reviewers.map((reviewer) => {
            if (reviewer.id === action.payload.id) {
              return {
                ...reviewer,
                name: action.payload.name,
              };
            }
            return reviewer;
          }),
        },
      };
    case '@SPLITSHEET/SET_REVIEWER_EMAIL':
      return {
        ...state,
        data: {
          ...state.data,
          reviewers: state.data.reviewers.map((reviewer) => {
            if (reviewer.id === action.payload.id) {
              return {
                ...reviewer,
                email: action.payload.email,
              };
            }
            return reviewer;
          }),
        },
      };
    case '@SPLITSHEET/SET_REVIEWER_CAN_VIEW_ALL':
      return {
        ...state,
        data: {
          ...state.data,
          reviewers: state.data.reviewers.map((reviewer) => {
            if (reviewer.id === action.payload.id) {
              return {
                ...reviewer,
                canViewAll: action.payload.canViewAll,
              };
            }
            return reviewer;
          }),
        },
      };
    case '@SPLITSHEET/SET_REVIEWER_TRACKING_ID':
      return {
        ...state,
        data: {
          ...state.data,
          reviewers: state.data.reviewers.map((reviewer) => {
            if (reviewer.id === action.payload.id) {
              return {
                ...reviewer,
                trackedApprover: action.payload.trackedApprover,
              };
            }
            return reviewer;
          }),
        },
      };
    case '@SPLITSHEET/CLEAR_ALL_DATA':
      return initState();
    case '@SPLITSHEET/FETCH_SPLITSHEET':
      return { ...state, loading: true };
    case '@SPLITSHEET/FETCH_SPLITSHEET_SUCCESS':
      return { ...state, loading: false, error: null, data: action.payload };
    case '@SPLITSHEET/FETCH_SPLITSHEET_FAILURE':
      return { ...state, error: action.payload, loading: false };
    case '@SPLITSHEET/SAVE_AS_DRAFT':
      return { ...state, loading: true };
    case '@SPLITSHEET/SAVE_AND_SEND':
      return { ...state, loading: true };
    // case '@SPLITSHEET/POST_SPLITSHEET':
    //   return { ...state, loading: true };
    case '@SPLITSHEET/POST_SPLITSHEET_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data,
      };
    case '@SPLITSHEET/POST_SPLITSHEET_FAILURE':
      return { ...state, loading: false, error: action.payload };
    // case '@SPLITSHEET/PATCH_SPLITSHEET':
    //   return { ...state, loading: true };
    case '@SPLITSHEET/PATCH_SPLITSHEET_SUCCESS':
      return { ...state, loading: false, error: null };
    case '@SPLITSHEET/PATCH_SPLITSHEET_FAILURE':
      return { ...state, loading: false, error: action.payload };
    // case '@SPLITSHEET/SET_REVIEW_STATUS':
    //   return { ...state, loading: true };
    case '@SPLITSHEET/SET_REVIEW_STATUS_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        data: { ...state.data, state: ContractState.REVIEW },
      };
    case '@SPLITSHEET/SET_REVIEW_STATUS_FAILURE':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default splitSheetReducer;
