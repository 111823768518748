import { useMemo } from 'react';

import { MasterFieldsFragment } from 'generated/graphql';
import { ContentType } from 'common/api/models';
import usePlayerInstance from 'common/hooks/usePlayerInstance/usePlayerInstance';

export default function usePlayerPlayMaster(master: MasterFieldsFragment) {
  const assets = master?.assets || [];
  const masterFileToPlay = useMemo(
    () =>
      assets.find(
        (asset) =>
          asset?.type_id === ContentType.MASTER_FILE_EXPLICIT ||
          asset?.type_id === ContentType.MASTER_FILE_CLEAN
      ),
    [assets]
  );
  // const strings = useMemo(
  //   () => (masterFileToPlay ? getDriveItemStrings(masterFileToPlay) : undefined),
  //   [masterFileToPlay]
  // );

  const { playingAssetId, playAsset, pauseAsset, hidePlayer } = usePlayerInstance();

  return {
    isAssetPlaying: masterFileToPlay?.id === playingAssetId,
    playAsset: () => playAsset(masterFileToPlay?.id || 0),
    pauseAsset,
    closePlayer: hidePlayer,
  };
}
