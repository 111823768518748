import noop from 'common/utils/noop';
import { HUGE_MAX_IMAGE_SIZE } from 'config/network';
import { FC } from 'react';
import * as React from 'react';
import { useFormikContext } from 'formik';
import { PureImageUpload } from 'common/components/pureImageUpload/PureImageUpload';
import { StorageLink } from 'common/api/models';

interface ArtworkFieldProps {
  artworkName: string;
  onLoadingChange?: (loading: boolean) => void;
}

const ArtworkField: FC<ArtworkFieldProps> = ({ artworkName, onLoadingChange = noop }) => {
  const { values, setFieldValue, setFieldError, setFieldTouched } = useFormikContext<{
    artworkUrl: string;
    artworkFileSize: number;
  }>();
  const { artworkUrl } = values;
  const [name] = React.useState(artworkName);
  const [initPreviewState] = React.useState({
    files: [] as File[],
    previews: typeof artworkUrl === 'string' ? [artworkUrl] : [],
  });

  const handleChange = React.useCallback(
    (link: StorageLink) => {
      setFieldValue(name, link.file_name);
    },
    [name, setFieldValue]
  );

  const handleFileChange = React.useCallback(
    (file?: File) => {
      if (file) {
        setFieldValue('artworkFileSize', file.size);
      } else {
        setFieldValue('artworkFileSize', 0);
      }
    },
    [setFieldValue]
  );

  const handleReset = React.useCallback(() => {
    setFieldValue(name, null);
    setFieldValue('artworkFileSize', 0);
  }, [name, setFieldValue]);

  const handleError = React.useCallback(
    (error: unknown) => {
      setFieldTouched(name, true);
      setFieldError(name, `${error}`);
    },
    [name, setFieldError, setFieldTouched]
  );

  return (
    <PureImageUpload
      onFileChange={handleFileChange}
      onCompleted={handleChange}
      onError={handleError}
      fileSizeLimit={HUGE_MAX_IMAGE_SIZE}
      onReset={handleReset}
      initPrevewState={initPreviewState}
      onLoadingChange={onLoadingChange}
    />
  );
};

export default ArtworkField;
