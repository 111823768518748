import React from 'react';
import { logoutAction } from 'redux/login/loginActions';
import { useDispatch } from 'react-redux';

/**
 * Used as redirect handler only in sceniries such as RP-initiated logout in OAuth
 */
export default function LogoutPage() {
  useDispatch()(logoutAction());
  return <></>;
}
