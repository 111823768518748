import MenuState from 'redux/menu/interfaces/menuState';

const INIT_MENU_STATE: MenuState = {
  anchorEl: null,
  context: null,
  visibility: null,
  isClosing: false,
  open: false,
};

export default INIT_MENU_STATE;
