import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

/**
 * Reads `?next=` query param, which might be sent by backend
 * Required for OAuth integration
 */
function useRedirectURN(): string | null {
  const { search } = useLocation();
  return useMemo(() => {
    // Required for OAuth login. OAuth is required for Eveara integration
    return new URLSearchParams(search).get('next');
  }, [search]);
}

export default useRedirectURN;
