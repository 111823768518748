import MasterCustomizeTypes from '../emun/masterCustomizeTypes';

const SUB_BUTTON_TITILE: Record<MasterCustomizeTypes, string> = {
  [MasterCustomizeTypes.realityAudio]: '360 Reality Audio',
  [MasterCustomizeTypes.stemFiles]: 'Stem Files',
  [MasterCustomizeTypes.contracts]: 'Contracts',
  [MasterCustomizeTypes.metadata]: 'Metadata',
  [MasterCustomizeTypes.media]: 'Media',
};

export default SUB_BUTTON_TITILE;
