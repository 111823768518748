import React from 'react';
import clsx from 'clsx';
import emptyFolderIcon from 'assets/empty-folder.svg';
import capitalize from '@material-ui/core/utils/capitalize';
import { Box, BoxProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  folderImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${emptyFolderIcon})`,
  },
  sizeMedium: {
    // TODO: Move sizes to theme
    width: 49,
    height: 40,
  },
  sizeLarge: {
    width: 64,
    height: 53,
  },
}));

interface FolderItemIconProps extends BoxProps {
  size?: 'medium' | 'large';
  disableFolderImage?: boolean;
}

const FolderItemIcon: React.FC<FolderItemIconProps> = ({
  size = 'medium',
  disableFolderImage = false,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.root, classes[`size${capitalize(size)}`], {
        [classes.folderImage]: !disableFolderImage,
      })}
      {...other}
    />
  );
};

export default FolderItemIcon;
