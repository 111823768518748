import { ContentType } from 'common/api/models';

const MASTER_CONTENT_TYPES: ContentType[] = [
  ContentType.MASTER_META,
  ContentType.MASTER_MEDIA,
  ContentType.MASTER_STEM,
  ContentType.MASTER_DOCUMENT,
  ContentType.MASTER_FILE,
  ContentType.MASTER_CONTRACT,
  ContentType.MASTER_FILE_CLEAN,
  ContentType.MASTER_FILE_EXPLICIT,
  ContentType.MASTER_FOLDER,
  ContentType.COLLECTION,
  ContentType.ALBUM_COVER,
];

export default MASTER_CONTENT_TYPES;
