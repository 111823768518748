import { Middleware } from 'redux';
import { RootState } from 'redux/root-reducer';
import { isToolbarClick } from 'redux/toolbar/utils/isToolbarClick';
import isModalOpenAction from 'redux/toolbar/utils/isModalOpenAction';
import getNextAction from 'redux/toolbar/utils/getNextAction';

const toolbarMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (isToolbarClick(action)) {
    const file = store.getState().selectedFile;
    const nextAction = getNextAction(file, action);

    if (isModalOpenAction(nextAction)) {
      store.dispatch(nextAction);
    }
  }

  return next(action);
};

export default toolbarMiddleware;
