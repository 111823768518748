import ContractsPaths from 'api/contracts/enums/contractsPaths';
import { Contract } from 'common/api/advSearchFixTypes';
import POST from 'common/services/httpServices/post';

const getContractSummaries: (contractIDs: number[]) => Promise<Contract[]> = async (
  contractIDs
) => {
  const response = await POST(
    ContractsPaths.GetSummaries,
    JSON.stringify({ contract_ids: contractIDs })
  );

  return await response.json();
};

export default getContractSummaries;
