import React, { FC } from 'react';
import { Checkbox, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SHARE_PERMISSION_LABEL from '../configs/sharePermissionLabels';
import filterViewPermission from '../utils/filterViewPermission';
import { SharePermissions } from 'common/models/sharing';
import noop from 'common/utils/noop';
import Typography from 'uikit/typography/Typography';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
    },
    mainLabel: {
      flexBasis: '100%',
      color: theme.palette.common.black,
    },
    inputWrap: {
      marginRight: theme.spacing(1),
    },
    controls: {
      display: 'flex',

      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(-1.5),
      },
    },
  }),
  { name: 'PermissionsCheckboxSelector' }
);

interface PermissionsCheckboxSelectorProps {
  permissionVariants: SharePermissions[];
  permissions: SharePermissions[];
  onChange?: (permissions: SharePermissions[]) => void;
}

const PermissionsCheckboxSelector: FC<PermissionsCheckboxSelectorProps> = ({
  permissions,
  permissionVariants,
  onChange = noop,
}) => {
  const SharePermissionLabels = SHARE_PERMISSION_LABEL;

  const classes = useStyles();

  const handleValueChange = (permission: SharePermissions) => (): void => {
    const newPermissions = [...permissions];

    if (permissions.includes(permission)) {
      newPermissions.splice(
        permissions.findIndex((perm) => perm === permission),
        1
      );
      onChange(newPermissions);
      return;
    }

    newPermissions.push(permission);
    onChange(newPermissions);
  };

  return (
    <div className={classes.root}>
      <InputLabel className={classes.mainLabel}>
        <Typography variant={'subtitle2'} className={'MuiMainLabel-root'}>
          Permissions
        </Typography>
      </InputLabel>
      <div className={classes.controls}>
        {filterViewPermission(permissionVariants).map((perm) => {
          return (
            <div
              className={classes.inputWrap}
              onClick={handleValueChange(perm)}
              key={perm}
            >
              <Checkbox color={'primary'} checked={permissions.includes(perm)} />
              {SharePermissionLabels[perm].label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PermissionsCheckboxSelector;
