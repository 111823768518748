import { SharePermissions } from 'common/models/sharing';

const SHARE_PERMISSION_LABEL: Record<SharePermissions, { label: string }> = {
  [SharePermissions.VIEW]: { label: 'View' },
  [SharePermissions.DOWNLOAD]: { label: 'Download' },
  [SharePermissions.UPLOAD]: { label: 'Upload' },
  [SharePermissions.EDIT]: { label: 'Edit' },
  [SharePermissions.DELETE]: { label: 'Delete' },
};

export default SHARE_PERMISSION_LABEL;
