import React from 'react';
import { FastField, FieldProps } from 'formik';

import TextFieldPropsExtended from 'common/types/textFieldPropsExtended';
import AppearingField from 'uikit/appearingField/AppearingField';
import TextField from 'uikit/textField/TextField';

type Props = TextFieldPropsExtended & {
  errIgnoreTouched?: boolean;
  component?: 'text-field' | 'appearing-field';
};

export default function FormicTextField(allProps: Props) {
  const { errIgnoreTouched = false, component = 'text-field', ...props } = allProps;
  const Component = component === 'text-field' ? TextField : AppearingField;

  return (
    <FastField name={props.name}>
      {({ field, meta, form }: FieldProps) => {
        const disabled = form.isSubmitting;
        const hasError = !!meta.error;
        const isInvalid = errIgnoreTouched ? hasError : hasError && meta.touched;
        const errorMsg = isInvalid ? (
          meta.error
        ) : (
          <span style={{ userSelect: 'none' }}>&nbsp;</span>
        );

        return (
          <Component
            {...props}
            {...field}
            value={field.value === undefined ? '' : field.value}
            disabled={props.disabled || disabled}
            error={isInvalid}
            helperText={errorMsg}
            disableMinWidth
          />
        );
      }}
    </FastField>
  );
}
