import shareContent from 'common/components/Modals/shareDialog/utils/shareContent';
import { PanelHeadings as Headings } from 'config/panelConfig';
import { ContentType } from 'common/api/models';
import { ModalConfigActionTypes, modalOpen } from 'redux/modal/modalActions';
import DriveItem from 'redux/selectedFile/interfaces/driveItem';
import { EmailPerms, GuestPerms } from 'config/sharingConfig';

const share = (selectedFile: DriveItem) => {
  switch (selectedFile.typeId as ContentType) {
    case ContentType.MASTER_FILE: {
      return (
        !!selectedFile.items &&
        modalOpen({
          type: ModalConfigActionTypes.SHARE,
          data: {
            itemName: Headings[selectedFile.itemTypeId],
            contentType: ContentType.MASTER_FILE,
            ids: [selectedFile.masterId || -1],
            onShare: shareContent,
            parentId: selectedFile.parentId || selectedFile.masterId,
            perms: {
              guest: GuestPerms[ContentType.MASTER_FILE].subfolder,
              email: EmailPerms[ContentType.MASTER_FILE].subfolder,
            },
          },
        })
      );
    }
    case ContentType.MASTER_META: {
      return (
        !!selectedFile.masterId &&
        modalOpen({
          type: ModalConfigActionTypes.SHARE,
          data: {
            itemName: `${selectedFile.parentTitle} ${Headings[selectedFile.itemTypeId]}`,
            contentType: selectedFile.typeId,
            ids: [selectedFile.masterId],
            onShare: shareContent,
            perms: {
              guest: GuestPerms[selectedFile.typeId].entity,
              email: EmailPerms[selectedFile.typeId].entity,
            },
          },
        })
      );
    }
    case ContentType.PROJECT_META: {
      return (
        !!selectedFile.projectId &&
        modalOpen({
          type: ModalConfigActionTypes.SHARE,
          data: {
            itemName: `${selectedFile.parentTitle} ${Headings[selectedFile.itemTypeId]}`,
            contentType: selectedFile.typeId,
            ids: [selectedFile.projectId],
            onShare: shareContent,
            perms: {
              guest: GuestPerms[selectedFile.typeId].entity,
              email: EmailPerms[selectedFile.typeId].entity,
            },
          },
        })
      );
    }
    case ContentType.PROJECT_LYRICS: {
      return (
        !!selectedFile.lyrics?.id &&
        modalOpen({
          type: ModalConfigActionTypes.SHARE,
          data: {
            itemName: Headings[selectedFile.itemTypeId],
            contentType: selectedFile.typeId,
            ids: [selectedFile.lyrics.id],
            onShare: shareContent,
            perms: {
              guest: GuestPerms[selectedFile.typeId].entity,
              email: EmailPerms[selectedFile.typeId].entity,
            },
          },
        })
      );
    }
    case ContentType.MASTER_DOCUMENT:
    case ContentType.MASTER_CONTRACT: {
      return (
        !!selectedFile.contracts &&
        modalOpen({
          type: ModalConfigActionTypes.SHARE,
          data: {
            itemName: Headings[selectedFile.itemTypeId],
            contentType: ContentType.MASTER_CONTRACT,
            ids: selectedFile.contracts.map((item) => item.id),
            onShare: shareContent,
            parentId: selectedFile.parentId || selectedFile.masterId,
            perms: {
              guest: GuestPerms[selectedFile.typeId].subfolder,
              email: EmailPerms[selectedFile.typeId].subfolder,
            },
          },
        })
      );
    }
    default: {
      return (
        !!selectedFile.items &&
        modalOpen({
          type: ModalConfigActionTypes.SHARE,
          data: {
            itemName: Headings[selectedFile.itemTypeId],
            contentType: selectedFile.typeId,
            parentId:
              selectedFile.parentId || selectedFile.masterId || selectedFile.projectId,
            ids: selectedFile.items
              .filter((dataItem) => dataItem.type_id === selectedFile.typeId)
              .map((dataItem) => dataItem.id as number),
            onShare: shareContent,
            perms: {
              guest: GuestPerms[selectedFile.typeId].subfolder,
              email: EmailPerms[selectedFile.typeId].subfolder,
            },
          },
        })
      );
    }
  }
};

export default share;
