import { MutationUpdaterFn } from '@apollo/client';
import { CollectionFieldsFragmentDoc, CreateAlbumMutation } from 'generated/graphql';

const createAlbumUpdater = (
  catalogId: number | string
): MutationUpdaterFn<CreateAlbumMutation> => (cache, { data }) => {
  if (!data) return;
  cache.modify({
    id: cache.identify({ __typename: 'Catalog', id: catalogId }),
    fields: {
      collections(prevRefs = [], { readField }) {
        const newColl = cache.writeFragment({
          data: data.createCollection,
          fragment: CollectionFieldsFragmentDoc,
          fragmentName: 'CollectionFields',
        });
        const isDuplicate = prevRefs.some(
          (ref) => readField('id', ref) === data.createCollection?.id
        );

        return isDuplicate ? prevRefs : [newColl, ...prevRefs];
      },
    },
  });
};

export default createAlbumUpdater;
