import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  TimeStamp: any;
}




export interface Query {
  __typename?: 'Query';
  AppHero?: Maybe<HeroSlider>;
  canInviteArtist: Scalars['Boolean'];
  canInviteUser: Scalars['Boolean'];
  features: AmpFeatues;
  getActionCounters: ActionCounters;
  getActions: Array<Action>;
  getActionsConnection?: Maybe<ActionConnection>;
  getAlbumDataEveara?: Maybe<AlbumDraftEveara>;
  getAlbumEveara: Array<Maybe<AlbumEveara>>;
  getAlbumRecordEveara?: Maybe<AlbumDraftEveara>;
  getArtistsEveara: Array<Maybe<EvearaArtist>>;
  getAsset?: Maybe<AmpAsset>;
  getAssetGroup?: Maybe<AssetGroup>;
  getAssetGroups: Array<AssetGroup>;
  getAssets: Assets;
  getAssetsWithBrokenMediaUrl?: Maybe<Array<AmpAsset>>;
  getAuthors: Array<User>;
  getAvailabilitiesEveara: Array<Maybe<EvearaAvailability>>;
  getCatalog?: Maybe<Catalog>;
  getCatalogs: Array<Catalog>;
  getCollabProject?: Maybe<CollabProject>;
  getCollabProjects: Array<CollabProject>;
  getCollection?: Maybe<AmpCollection>;
  getCollections: Array<AmpCollection>;
  getCollectionsAvailableForNewDistribution: Array<AmpCollection>;
  getContentTypes: Array<SearchContentType>;
  getContract?: Maybe<Contract>;
  getContractsSummaries: Array<Contract>;
  getCountries: Array<Country>;
  getCountriesEveara: Array<Maybe<EvearaCountry>>;
  getCurrentUser: User;
  getDistributionRecordsConnection: GetDistributionRecordConnectionsResult;
  getDraftAlbumEveara?: Maybe<AlbumDraftEveara>;
  getDraftOutletsDetails?: Maybe<Array<Maybe<OutletsDetails>>>;
  getDraftReleaseDetails?: Maybe<Array<Maybe<ReleaseDetails>>>;
  getDraftTrackDetails?: Maybe<Array<Maybe<TrackDetails>>>;
  getDraftTrackEveara: Array<Maybe<TrackDraftEveara>>;
  getDropById: Drop;
  getDrops: Array<Drop>;
  getDropsConnection: GetDropsConnectionsResult;
  getEvearaOutlets: Array<EvearaOutlet>;
  getGenres: Array<Genre>;
  getGenresEveara: Array<Maybe<EvearaGenre>>;
  getGuestUsers: Array<User>;
  getInvitedUsers: Array<UserInviteModel>;
  getKeywords: Array<MetadataKeyword>;
  getLabelsEveara: Array<Maybe<EvearaLabel>>;
  getLanguages: Array<Language>;
  getLanguagesEveara: Array<Maybe<EvearaLanguage>>;
  getLyrics?: Maybe<AmpLyrics>;
  getMaster?: Maybe<AmpMaster>;
  getMasterResources: Array<Maybe<Resources>>;
  getMasterWatch?: Maybe<Array<Maybe<MasterWatch>>>;
  getMasters?: Maybe<Array<Maybe<AmpMaster>>>;
  getMastersAvailableForNewDistribution: Array<AmpMaster>;
  getMenuResources: Array<Maybe<Resources>>;
  getOutletsDetailsByAlbumEveara?: Maybe<Array<Maybe<EvearaOutletsDetailsByAlbum>>>;
  getOutletsSmartLinksEveara?: Maybe<Array<Maybe<EvearaOutletsSmartLinks>>>;
  getParticipantsEveara: Array<Maybe<EvearaParticipant>>;
  getProject?: Maybe<AmpProject>;
  getReleaseEveara: ReleasesEveara;
  getResources: Array<Resources>;
  getRoles: Array<Role>;
  getRolesEveara: Array<Maybe<EvearaRole>>;
  getSavedSearch?: Maybe<SavedSearch>;
  getSavedSearchResult?: Maybe<Search>;
  getSavedSearches?: Maybe<Array<SavedSearch>>;
  getSubscribedSubscriptions: Array<AccountSubscription>;
  /** Returns subscriptions available to the user */
  getSubscriptions: Array<AccountSubscription>;
  getSubscriptionsEveara: Array<Maybe<EvearaSubscription>>;
  getTitleBlacklistedWords: Array<Scalars['String']>;
  getTrackDataEveara: TrackDataEveara;
  getTrackEveara: Array<Maybe<TrackEveara>>;
  getTullyBeat: TullyBeatResult;
  getTullyBeats: TullyBeats;
  getTullyMetadataArtists: Array<TullyMetadataArtist>;
  getTullyMetadataParticipantRoles: Array<TullyMetadataParticipantRole>;
  getTullyMetadataParticipants: Array<TullyMetadataParticipant>;
  getTullyPersonMetadata: Array<TullyPersonMetadata>;
  getWatchedActions?: Maybe<ActionConnection>;
  isMetaSynced?: Maybe<Scalars['Boolean']>;
  pollAction: Scalars['String'];
  search?: Maybe<Search>;
  umRole: UmRole;
  umRoles: UmRolePayload;
  umUsers: Array<UmUser>;
}


export interface QueryAppHeroArgs {
  type: Scalars['String'];
}


export interface QueryGetActionsArgs {
  where?: Maybe<ActionFilter>;
  page?: Maybe<Page>;
}


export interface QueryGetActionsConnectionArgs {
  where?: Maybe<ActionFilter>;
  category: Maybe<ActionCategory>;
  first: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  last: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
}


export interface QueryGetAlbumDataEvearaArgs {
  type: Maybe<AlbumType>;
  id: Scalars['Int'];
}


export interface QueryGetAlbumEvearaArgs {
  releaseId: Maybe<Scalars['Float']>;
}


export interface QueryGetAlbumRecordEvearaArgs {
  id: Scalars['Int'];
}


export interface QueryGetArtistsEvearaArgs {
  search_term: Maybe<Scalars['String']>;
  artistId: Maybe<Scalars['Int']>;
}


export interface QueryGetAssetArgs {
  id: Scalars['Int'];
}


export interface QueryGetAssetGroupArgs {
  id: Scalars['Int'];
}


export interface QueryGetAssetGroupsArgs {
  filter: Maybe<AssetGroupFilter>;
}


export interface QueryGetAssetsArgs {
  filter: Maybe<AssetsFilter>;
  first: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  last: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
}


export interface QueryGetCatalogArgs {
  id: Scalars['Int'];
}


export interface QueryGetCatalogsArgs {
  where?: Maybe<CatalogFilter>;
  page?: Maybe<Page>;
}


export interface QueryGetCollabProjectArgs {
  id: Scalars['String'];
}


export interface QueryGetCollectionArgs {
  id: Scalars['Int'];
}


export interface QueryGetCollectionsArgs {
  where?: Maybe<CollectionFilter>;
  page?: Maybe<Page>;
  state: Maybe<ObjectState>;
}


export interface QueryGetContractArgs {
  id: Scalars['Int'];
}


export interface QueryGetContractsSummariesArgs {
  ids: Maybe<Array<Scalars['Int']>>;
}


export interface QueryGetDistributionRecordsConnectionArgs {
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  filters: Maybe<GetDistributionRecordsConnectionFiltersInput>;
}


export interface QueryGetDraftAlbumEvearaArgs {
  id: Maybe<Scalars['Float']>;
  albumType: Maybe<AlbumTypeEveara>;
}


export interface QueryGetDraftOutletsDetailsArgs {
  releaseId: Scalars['Int'];
}


export interface QueryGetDraftReleaseDetailsArgs {
  masterId: Scalars['Int'];
  userId: Scalars['Int'];
}


export interface QueryGetDraftTrackDetailsArgs {
  releaseId: Scalars['Int'];
}


export interface QueryGetDraftTrackEvearaArgs {
  id: Maybe<Scalars['Float']>;
  albumType: Maybe<AlbumTypeEveara>;
}


export interface QueryGetDropByIdArgs {
  id: Scalars['Int'];
}


export interface QueryGetDropsArgs {
  status: Maybe<DropStatus>;
  sub_status: Maybe<DropSubStatus>;
}


export interface QueryGetDropsConnectionArgs {
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  filters: Maybe<GetDropsConnectionFiltersInput>;
}


export interface QueryGetInvitedUsersArgs {
  invite_status: Maybe<Array<Maybe<InviteStatus>>>;
}


export interface QueryGetKeywordsArgs {
  by_account: Maybe<Scalars['Boolean']>;
}


export interface QueryGetLabelsEvearaArgs {
  labelId: Maybe<Scalars['String']>;
}


export interface QueryGetLyricsArgs {
  id: Scalars['Int'];
}


export interface QueryGetMasterArgs {
  id: Scalars['Int'];
}


export interface QueryGetMasterWatchArgs {
  where?: Maybe<MasterWatchFilter>;
  page?: Maybe<Page>;
  state: Maybe<ObjectState>;
}


export interface QueryGetMastersArgs {
  id: Array<Scalars['Int']>;
}


export interface QueryGetOutletsDetailsByAlbumEvearaArgs {
  releaseId: Scalars['Float'];
}


export interface QueryGetOutletsSmartLinksEvearaArgs {
  albumId: Scalars['Float'];
}


export interface QueryGetParticipantsEvearaArgs {
  participantId: Maybe<Scalars['Int']>;
}


export interface QueryGetProjectArgs {
  id: Scalars['Int'];
}


export interface QueryGetReleaseEvearaArgs {
  data: Maybe<ReleaseEvearaInput>;
}


export interface QueryGetSavedSearchArgs {
  id: Scalars['Int'];
}


export interface QueryGetSavedSearchResultArgs {
  id: Scalars['Int'];
  first: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  last: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
}


export interface QueryGetSubscribedSubscriptionsArgs {
  include_free_plan: Maybe<Scalars['Boolean']>;
  include_extended_statuses: Maybe<Scalars['Boolean']>;
}


export interface QueryGetSubscriptionsArgs {
  payment_period_type?: Maybe<Period>;
  include_free_plan: Maybe<Scalars['Boolean']>;
  platform_type: Maybe<Platform>;
}


export interface QueryGetSubscriptionsEvearaArgs {
  mySubscriptionId: Maybe<Scalars['Int']>;
}


export interface QueryGetTrackDataEvearaArgs {
  master_id: Scalars['Int'];
  asset_id: Scalars['Int'];
}


export interface QueryGetTrackEvearaArgs {
  trackLibraryId: Maybe<Scalars['Int']>;
}


export interface QueryGetTullyBeatArgs {
  id: Scalars['Int'];
}


export interface QueryGetTullyBeatsArgs {
  type: Maybe<TullyBeatType>;
  search: Maybe<Scalars['String']>;
  keywords: Maybe<Array<Scalars['String']>>;
  first: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  last: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
}


export interface QueryGetWatchedActionsArgs {
  where?: Maybe<ActionFilter>;
  first: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  last: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
}


export interface QueryIsMetaSyncedArgs {
  masterId: Maybe<Scalars['Int']>;
  assetId: Maybe<Scalars['Int']>;
}


export interface QuerySearchArgs {
  filter?: Maybe<SearchFilterInput>;
  sort: Maybe<Array<Maybe<SearchSortInput>>>;
  first: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  last: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
}


export interface QueryUmRoleArgs {
  id: Scalars['Int'];
}

export enum Period {
  ALL = 'ALL',
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY'
}

export enum Platform {
  /** For ios/android. Excludes subscriptions not available for mobile */
  MOBILE = 'MOBILE',
  /** For web */
  WEB = 'WEB'
}

export interface AccountSubscription {
  __typename?: 'AccountSubscription';
  name: Scalars['String'];
  display_name: Scalars['String'];
  amount: Scalars['Float'];
  renewal?: Maybe<Scalars['String']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  upgrade_index?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  meta?: Maybe<SubscriptionMeta>;
  is_visible: Scalars['Boolean'];
  ios_sub_product_ids?: Maybe<Array<Scalars['String']>>;
  google_sub_product_id?: Maybe<Scalars['String']>;
}

export interface SubscriptionMeta {
  __typename?: 'SubscriptionMeta';
  subscription_id?: Maybe<Scalars['Int']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  ios_transaction_id?: Maybe<Scalars['String']>;
  google_transaction_id?: Maybe<Scalars['String']>;
  last_transaction_period_start?: Maybe<Scalars['TimeStamp']>;
  last_transaction_period_end?: Maybe<Scalars['TimeStamp']>;
}


export interface Resources {
  __typename?: 'Resources';
  id: Scalars['Int'];
  slug: Scalars['String'];
  display_name: Scalars['String'];
  type: Scalars['String'];
  access?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Float']>;
}

export interface Genre {
  __typename?: 'Genre';
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface Country {
  __typename?: 'Country';
  id: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
}

export interface Language {
  __typename?: 'Language';
  id: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
}

export interface HeroSlider {
  __typename?: 'HeroSlider';
  is_active?: Maybe<Scalars['Boolean']>;
  timeLapse?: Maybe<Scalars['Int']>;
  slides?: Maybe<Array<Maybe<Slides>>>;
}

export interface Slides {
  __typename?: 'Slides';
  action?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface TrackDataEveara {
  __typename?: 'TrackDataEveara';
  asset_id: Scalars['Int'];
  name: Scalars['String'];
  isrc?: Maybe<Scalars['String']>;
  iswc?: Maybe<Scalars['String']>;
  genre?: Maybe<Array<Maybe<Scalars['Int']>>>;
  language?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  explicit: Scalars['Int'];
  spatialIsrc?: Maybe<Scalars['String']>;
  dolbyAtmosIsrc?: Maybe<Scalars['String']>;
  availability?: Maybe<Array<Maybe<Scalars['Int']>>>;
  artists?: Maybe<Array<Maybe<Scalars['String']>>>;
  featuredArtists?: Maybe<Array<Maybe<Scalars['Int']>>>;
  albumOnly?: Maybe<Scalars['Boolean']>;
  lyrics?: Maybe<Scalars['String']>;
  sony360?: Maybe<Array<Maybe<Sony360File>>>;
  meta?: Maybe<TullyMetadata>;
}

export interface Sony360File {
  __typename?: 'Sony360File';
  asset_id: Scalars['Int'];
  filename: Scalars['String'];
  spatial_type: Scalars['String'];
  sony360_file_type?: Maybe<Scalars['String']>;
}

/** User input based metadata */
export interface TullyMetadata extends HasSimpleHistory {
  __typename?: 'TullyMetadata';
  id: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  account?: Maybe<Account>;
  imported?: Maybe<Scalars['TimeStamp']>;
  /** (Metadata) */
  last_edited?: Maybe<Scalars['TimeStamp']>;
  /** e.g. Burzum */
  artist?: Maybe<Scalars['String']>;
  /** e.g. Varg Vikernes */
  artist_name?: Maybe<Scalars['String']>;
  artists: Array<TullyMetadataArtist>;
  featured_artists_objs: Array<TullyMetadataArtist>;
  spotify_artist_id?: Maybe<Scalars['String']>;
  /** e.g.	PRS: 500879361 */
  artist_affiliation?: Maybe<Scalars['String']>;
  participants: Array<TullyMetadataParticipantInTullyMetadata>;
  /** @deprecated Field no longer supported */
  composers?: Maybe<Array<TullyPersonMetadata>>;
  /** @deprecated Field no longer supported */
  featured_artists?: Maybe<Array<TullyPersonMetadata>>;
  /** @deprecated Field no longer supported */
  performers?: Maybe<Array<TullyPersonMetadata>>;
  /** @deprecated Field no longer supported */
  producers?: Maybe<Array<TullyPersonMetadata>>;
  /** @deprecated Field no longer supported */
  songwriters?: Maybe<Array<TullyPersonMetadata>>;
  collaborators?: Maybe<Array<TullyPersonMetadata>>;
  remixer?: Maybe<Scalars['String']>;
  track_title?: Maybe<Scalars['String']>;
  album_name?: Maybe<Scalars['String']>;
  ep_name?: Maybe<Scalars['String']>;
  artwork_id?: Maybe<Scalars['Int']>;
  artwork?: Maybe<AmpFile>;
  publisher_name?: Maybe<Scalars['String']>;
  /** e.g. PRS: 500879361 */
  publisher_affiliation?: Maybe<Scalars['String']>;
  /** 0..100% */
  publisher_ownership?: Maybe<Scalars['Int']>;
  upc_ean_code?: Maybe<Scalars['String']>;
  /** e.g. GBBPP2060181 */
  isrc?: Maybe<Scalars['String']>;
  /**
   * From Tully genres list
   * @deprecated Field no longer supported
   */
  genre?: Maybe<Scalars['String']>;
  genre_obj?: Maybe<Genre>;
  /** To avoid unwanted edge cases, tempo is alphanumeric */
  tempo?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  language?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  country?: Maybe<Scalars['String']>;
  language_obj?: Maybe<Language>;
  country_obj?: Maybe<Country>;
  /** mostly Asset property */
  mix_variant?: Maybe<MixVariant>;
  release_date?: Maybe<Scalars['TimeStamp']>;
  sale_start_date?: Maybe<Scalars['TimeStamp']>;
  has_third_party_publisher?: Maybe<Scalars['Boolean']>;
  has_ownership?: Maybe<Scalars['Boolean']>;
  collections?: Maybe<Array<AmpCollection>>;
  keywords: Array<MetadataKeyword>;
  ownership_sample_recording?: Maybe<AmpFile>;
  ownership_license?: Maybe<AmpFile>;
}

export interface HasSimpleHistory {
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
}

export interface Account extends HasSimpleHistory {
  __typename?: 'Account';
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  name: Scalars['String'];
  /** (computed) Total storage available (plans + addons) */
  max_storage: Scalars['Float'];
  /** (computed) Total plans storage */
  plan_storage: Scalars['Float'];
  /** (computed) Total addons storage */
  addon_storage: Scalars['Float'];
  /** (computed) String representation for `max_storage` */
  storage_limit: Scalars['String'];
  used_storage?: Maybe<Scalars['Float']>;
  used_file_count?: Maybe<Scalars['Int']>;
  lyriq_requests_limit: Scalars['Int'];
  used_lyriq_requests: Scalars['Int'];
  available_lyriq_requests: Scalars['Int'];
  /** (computed) Number of invited guests */
  guest_count: Scalars['Int'];
  /** (computed) Number of users (AMP Users + Artists) in the account */
  user_count: Scalars['Int'];
  /** (computed) Number of AMP Users in the account */
  amp_user_count: Scalars['Int'];
  /** (computed) Number of Artists in the account */
  artist_count: Scalars['Int'];
  /** (computed) Max number of available users (AMP Users + Artists) to invite according to the plan */
  plan_user_seats: Scalars['Int'];
  /** (computed) Max number of available Guests to invite according to the plan */
  plan_guest_seats: Scalars['Int'];
  /** (computed) Max number of available AMP Users to invite according to the addons */
  addon_amp_user_seats: Scalars['Int'];
  /** (computed) Max number of available Artists to invite according to the addons */
  addon_artist_seats: Scalars['Int'];
  /** (computed) Non-negative number of free amp user seats */
  free_amp_user_seats: Scalars['Int'];
  /** (computed) Non-negative number of free artist seats */
  free_artist_seats: Scalars['Int'];
  /** (computed) If the user has already accepred free trial */
  used_trial_period?: Maybe<Scalars['Boolean']>;
}

export interface TullyMetadataArtist {
  __typename?: 'TullyMetadataArtist';
  id: Scalars['Int'];
  name: Scalars['String'];
  country: Country;
  eveara_id?: Maybe<Scalars['Int']>;
}

export interface TullyMetadataParticipantInTullyMetadata {
  __typename?: 'TullyMetadataParticipantInTullyMetadata';
  participant: TullyMetadataParticipant;
  role: TullyMetadataParticipantRole;
  payout: Scalars['Int'];
}

export interface TullyMetadataParticipant {
  __typename?: 'TullyMetadataParticipant';
  id: Scalars['Int'];
  name: Scalars['String'];
  eveara_id?: Maybe<Scalars['Int']>;
}

export interface TullyMetadataParticipantRole {
  __typename?: 'TullyMetadataParticipantRole';
  name: Scalars['String'];
  value: Scalars['String'];
}

export interface TullyPersonMetadata {
  __typename?: 'TullyPersonMetadata';
  id: Scalars['Int'];
  account?: Maybe<Account>;
  name: Scalars['String'];
  affiliation?: Maybe<Scalars['String']>;
  apple_id?: Maybe<Scalars['String']>;
  spotify_id?: Maybe<Scalars['String']>;
  ownership?: Maybe<Scalars['Int']>;
  role: CollaboratorRole;
}

export enum CollaboratorRole {
  COMPOSER = 'COMPOSER',
  FEATURED_ARTIST = 'FEATURED_ARTIST',
  PERFORMER = 'PERFORMER',
  PRODUCER = 'PRODUCER',
  SONGWRITER = 'SONGWRITER'
}

export interface AmpFile extends HasSimpleHistory {
  __typename?: 'AMPFile';
  id: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  file_path: Scalars['String'];
  file_name: Scalars['String'];
  crc32c: Scalars['String'];
  md5hash: Scalars['String'];
  content_type?: Maybe<Scalars['String']>;
  media_created: Scalars['TimeStamp'];
  media_updated: Scalars['TimeStamp'];
  size: Scalars['Int'];
  resolution?: Maybe<ImageResolution>;
  user: User;
  user_id: Scalars['Int'];
  /** Holds fresh signed download url (computed attr) */
  media_url: Scalars['String'];
  upload_status: Scalars['String'];
  signed_upload_url?: Maybe<Scalars['String']>;
  /** Used by media_url. The URL might be expired or does not exist */
  signed_download_url?: Maybe<Scalars['String']>;
  signed_upload_url_expiry: Scalars['TimeStamp'];
  signed_download_url_expiry: Scalars['TimeStamp'];
}

export interface ImageResolution {
  __typename?: 'ImageResolution';
  width: Scalars['Int'];
  height: Scalars['Int'];
}

export interface User extends HasSimpleHistory {
  __typename?: 'User';
  id: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  is_active: Scalars['Boolean'];
  is_staff: Scalars['Boolean'];
  is_admin: Scalars['Boolean'];
  is_artist: Scalars['Boolean'];
  is_owner: Scalars['Boolean'];
  last_login?: Maybe<Scalars['TimeStamp']>;
  last_login_web?: Maybe<Scalars['TimeStamp']>;
  /** computed */
  is_guest?: Maybe<Scalars['Boolean']>;
  account?: Maybe<Account>;
  profile?: Maybe<Profile>;
  profile_id?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['String']>;
  roles: Array<UserRole>;
  fid?: Maybe<Scalars['String']>;
  catalogs: Array<Catalog>;
  menu?: Maybe<Array<Resources>>;
  master_config?: Maybe<Array<Resources>>;
}

export interface Profile {
  __typename?: 'Profile';
  phone?: Maybe<Array<Scalars['String']>>;
  avatar?: Maybe<Scalars['String']>;
  artist_profile?: Maybe<ArtistProfile>;
  artist_profile_id?: Maybe<Scalars['Int']>;
}

export interface ArtistProfile {
  __typename?: 'ArtistProfile';
  artist_name: Scalars['String'];
  artist_option?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  genre?: Maybe<Scalars['String']>;
  genre_obj?: Maybe<Genre>;
  audio_settings?: Maybe<Scalars['String']>;
  artist_bio: Scalars['String'];
  soundcloud_id: Scalars['String'];
  spotify_id: Scalars['String'];
  apple_artist_id: Scalars['String'];
  youtube_channel_id: Scalars['String'];
  affiliation: Scalars['String'];
}

export interface UserRole {
  __typename?: 'UserRole';
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface Catalog extends HasSimpleHistory {
  __typename?: 'Catalog';
  account: Account;
  account_id: Scalars['Int'];
  assets: Array<AmpAsset>;
  collections: Array<AmpCollection>;
  created_at: Scalars['TimeStamp'];
  id: Scalars['Int'];
  lyrics: Array<AmpLyrics>;
  masterCount: Scalars['Int'];
  masters: Array<AmpMaster>;
  name: Scalars['String'];
  projectCount: Scalars['Int'];
  projects: Array<AmpProject>;
  updated_at: Scalars['TimeStamp'];
  user: User;
  user_id: Scalars['Int'];
}


export interface CatalogAssetsArgs {
  where?: Maybe<AssetFilter>;
  page?: Maybe<Page>;
  state: Maybe<ObjectState>;
}


export interface CatalogCollectionsArgs {
  where?: Maybe<CollectionFilter>;
  page?: Maybe<Page>;
  state: Maybe<ObjectState>;
  excludeWithDistributionRecord?: Maybe<Scalars['Boolean']>;
}


export interface CatalogLyricsArgs {
  where?: Maybe<LyricsFilter>;
  page?: Maybe<Page>;
  state: Maybe<ObjectState>;
}


export interface CatalogMastersArgs {
  where?: Maybe<MasterFilter>;
  page?: Maybe<Page>;
  state: Maybe<ObjectState>;
  excludeWithDistributionRecord?: Maybe<Scalars['Boolean']>;
}


export interface CatalogProjectsArgs {
  where?: Maybe<ProjectFilter>;
  page?: Maybe<Page>;
  state: Maybe<ObjectState>;
}

export interface MasterFilter {
  id?: Maybe<WhereArgInt>;
  title?: Maybe<WhereArgString>;
}

export interface WhereArgInt {
  exact?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export interface WhereArgString {
  exact?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface Page {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export enum ObjectState {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED'
}

export interface AmpMaster extends HasSimpleHistory {
  __typename?: 'AMPMaster';
  id: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  catalog: Catalog;
  catalog_id: Scalars['Int'];
  user: User;
  user_id: Scalars['Int'];
  assets: Array<AmpAsset>;
  fire_node_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  is_locked: Scalars['Boolean'];
  meta?: Maybe<MasterMeta>;
  tully_metadata?: Maybe<TullyMetadata>;
  object_state?: Maybe<ObjectState>;
  subfolder_config?: Maybe<Array<Resources>>;
  contracts: Array<Contract>;
}


export interface AmpMasterAssetsArgs {
  where?: Maybe<AssetFilter>;
  page?: Maybe<Page>;
  state: Maybe<ObjectState>;
}


export interface AmpMasterContractsArgs {
  where?: Maybe<ContractFilter>;
}

export interface AssetFilter {
  id?: Maybe<WhereArgInt>;
  title?: Maybe<WhereArgString>;
  type?: Maybe<WhereArgAssetType>;
}

export interface WhereArgAssetType {
  exact?: Maybe<AssetType>;
  in?: Maybe<Array<Maybe<AssetType>>>;
}

export enum AssetType {
  TULLY_BEAT = 'TULLY_BEAT',
  IMPORT_BEAT = 'IMPORT_BEAT',
  PROJECT_BEAT = 'PROJECT_BEAT',
  PROJECT_IMPORT = 'PROJECT_IMPORT',
  PROJECT_RECORDING = 'PROJECT_RECORDING',
  PROJECT_MIX = 'PROJECT_MIX',
  MASTER_CLEAN = 'MASTER_CLEAN',
  MASTER_EXPLICIT = 'MASTER_EXPLICIT',
  MASTER_STEM = 'MASTER_STEM',
  MASTER_360RA = 'MASTER_360RA',
  MASTER_DOCUMENT = 'MASTER_DOCUMENT',
  MASTER_CONTRACT = 'MASTER_CONTRACT',
  MASTER_MEDIA = 'MASTER_MEDIA',
  ALBUM_COVER = 'ALBUM_COVER',
  DROP_SUBMISSION = 'DROP_SUBMISSION'
}

export interface AmpAsset extends HasSimpleHistory {
  __typename?: 'AMPAsset';
  id: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  fire_node_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: AssetType;
  type_id: Scalars['Int'];
  file?: Maybe<AmpFile>;
  file_id?: Maybe<Scalars['Int']>;
  media_cover?: Maybe<AmpFile>;
  media_cover_id?: Maybe<Scalars['Int']>;
  project?: Maybe<AmpProject>;
  project_id?: Maybe<Scalars['Int']>;
  master?: Maybe<AmpMaster>;
  master_id?: Maybe<Scalars['Int']>;
  catalog: Catalog;
  catalog_id: Scalars['Int'];
  is_locked: Scalars['Boolean'];
  metadata?: Maybe<AssetMetadata>;
  meta?: Maybe<AudioMeta>;
  tully_metadata?: Maybe<TullyMetadata>;
  object_state?: Maybe<ObjectState>;
  hq_audio?: Maybe<Scalars['String']>;
}

export interface AmpProject extends HasSimpleHistory {
  __typename?: 'AMPProject';
  id: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  catalog: Catalog;
  catalog_id: Scalars['Int'];
  user: User;
  user_id: Scalars['Int'];
  lyrics: Array<AmpLyrics>;
  assets: Array<AmpAsset>;
  fire_node_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['Int'];
  is_locked: Scalars['Boolean'];
  metadata?: Maybe<ProjectMetadata>;
  meta?: Maybe<ProjectMeta>;
  collabId?: Maybe<Scalars['String']>;
  object_state?: Maybe<ObjectState>;
}


export interface AmpProjectLyricsArgs {
  where?: Maybe<LyricsFilter>;
  page?: Maybe<Page>;
  state: Maybe<ObjectState>;
}


export interface AmpProjectAssetsArgs {
  where?: Maybe<AssetFilter>;
  page?: Maybe<Page>;
  state: Maybe<ObjectState>;
}

export interface LyricsFilter {
  id?: Maybe<WhereArgInt>;
}

export interface AmpLyrics extends HasSimpleHistory {
  __typename?: 'AMPLyrics';
  id: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  fire_node_id?: Maybe<Scalars['String']>;
  catalog: Catalog;
  catalog_id: Scalars['Int'];
  project: AmpProject;
  project_id: Scalars['Int'];
  content: Scalars['String'];
  object_state?: Maybe<ObjectState>;
  versions: Array<Version>;
}

export interface Version {
  __typename?: 'Version';
  id: Scalars['Int'];
  object_id: Scalars['String'];
  revision: Revision;
  format?: Maybe<Scalars['String']>;
  serialized_data?: Maybe<Scalars['String']>;
  object_repr?: Maybe<Scalars['String']>;
}

export interface Revision {
  __typename?: 'Revision';
  id: Scalars['Int'];
  user?: Maybe<User>;
  comment: Scalars['String'];
  date_created?: Maybe<Scalars['TimeStamp']>;
}

export interface ProjectMetadata {
  __typename?: 'ProjectMetadata';
  nameindex?: Maybe<ProjectNameIndex>;
}

export interface ProjectNameIndex {
  __typename?: 'ProjectNameIndex';
  import_recording?: Maybe<Scalars['Int']>;
  mix?: Maybe<Scalars['Int']>;
  recording?: Maybe<Scalars['Int']>;
}

export interface ProjectMeta {
  __typename?: 'ProjectMeta';
  title?: Maybe<Scalars['String']>;
  artist?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['TimeStamp']>;
  updated_at?: Maybe<Scalars['TimeStamp']>;
}

export interface AssetMetadata {
  __typename?: 'AssetMetadata';
  has_media_cover_processed?: Maybe<Scalars['Boolean']>;
  deltaStart?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Int']>;
  localFileName?: Maybe<Scalars['String']>;
  bpm?: Maybe<Scalars['Float']>;
  key?: Maybe<Scalars['String']>;
}

export type AudioMeta = AudioMetaSuccess | AudioMetaError;

export interface AudioMetaSuccess {
  __typename?: 'AudioMetaSuccess';
  created_at: Scalars['TimeStamp'];
  format: Format;
  streams: Array<Streams>;
  program_version: ProgramVersion;
  is_360ra?: Maybe<Scalars['Boolean']>;
}

export interface Format {
  __typename?: 'Format';
  nb_streams: Scalars['Int'];
  nb_programs: Scalars['Int'];
  format_name: Scalars['String'];
  format_long_name: Scalars['String'];
  start_time: Scalars['String'];
  duration: Scalars['String'];
  size: Scalars['String'];
  bit_rate: Scalars['String'];
  probe_score: Scalars['Int'];
  tags?: Maybe<Tags>;
}

export interface Tags {
  __typename?: 'Tags';
  title?: Maybe<Scalars['String']>;
  artist?: Maybe<Scalars['String']>;
  album_artist?: Maybe<Scalars['String']>;
  album?: Maybe<Scalars['String']>;
  encoded_by?: Maybe<Scalars['String']>;
  track?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  composer?: Maybe<Scalars['String']>;
  TOPE?: Maybe<Scalars['String']>;
  TPUB?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
}

export interface Streams {
  __typename?: 'Streams';
  index: Scalars['Int'];
  codec_name?: Maybe<Scalars['String']>;
  codec_long_name?: Maybe<Scalars['String']>;
  codec_type: Scalars['String'];
  codec_time_base?: Maybe<Scalars['String']>;
  codec_tag_string?: Maybe<Scalars['String']>;
  codec_tag?: Maybe<Scalars['String']>;
  sample_fmt?: Maybe<Scalars['String']>;
  sample_rate?: Maybe<Scalars['String']>;
  channels?: Maybe<Scalars['Int']>;
  channel_layout?: Maybe<Scalars['String']>;
  bits_per_sample?: Maybe<Scalars['Int']>;
  r_frame_rate?: Maybe<Scalars['String']>;
  avg_frame_rate?: Maybe<Scalars['String']>;
  time_base?: Maybe<Scalars['String']>;
  start_pts?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['String']>;
  duration_ts?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['String']>;
  bit_rate?: Maybe<Scalars['String']>;
  tags?: Maybe<Tags>;
  disposition: Disposition;
  UDTA?: Maybe<Scalars['String']>;
  codec?: Maybe<Scalars['String']>;
}

export interface Disposition {
  __typename?: 'Disposition';
  default: Scalars['Int'];
  dub: Scalars['Int'];
  original: Scalars['Int'];
  comment: Scalars['Int'];
  lyrics: Scalars['Int'];
  karaoke: Scalars['Int'];
  forced: Scalars['Int'];
  hearing_impaired: Scalars['Int'];
  visual_impaired: Scalars['Int'];
  clean_effects: Scalars['Int'];
  attached_pic: Scalars['Int'];
  timed_thumbnails: Scalars['Int'];
}

export interface ProgramVersion {
  __typename?: 'ProgramVersion';
  version: Scalars['String'];
}

export interface AudioMetaError {
  __typename?: 'AudioMetaError';
  error: Scalars['String'];
}

export interface MasterMeta {
  __typename?: 'MasterMeta';
  artist: Scalars['String'];
  album: Scalars['String'];
  createdAt: Scalars['TimeStamp'];
  format_name: Scalars['String'];
  format_long_name: Scalars['String'];
  title: Scalars['String'];
  project_name: Scalars['String'];
  streams: Array<AudioStreamMeta>;
  customizeMaster?: Maybe<Array<Maybe<CustomizeMaster>>>;
}

export interface AudioStreamMeta {
  __typename?: 'AudioStreamMeta';
  codecName: Scalars['String'];
  channelLayout: Scalars['String'];
  sampleFormat: Scalars['String'];
  sampleRate: Scalars['Int'];
  bitrate: Scalars['Int'];
}

export interface CustomizeMaster {
  __typename?: 'CustomizeMaster';
  realityAudio: Scalars['Boolean'];
  stemFiles: Scalars['Boolean'];
  contracts: Scalars['Boolean'];
  metadata: Scalars['Boolean'];
  media: Scalars['Boolean'];
  userId: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
}

export interface ContractFilter {
  type?: Maybe<WhereArgContractType>;
}

export interface WhereArgContractType {
  exact?: Maybe<ContractType>;
  in?: Maybe<Array<Maybe<ContractType>>>;
}

export enum ContractType {
  SPLIT_SHEET_AGREEMENT = 'SPLIT_SHEET_AGREEMENT',
  CLEARANCE_AGREEMENT = 'CLEARANCE_AGREEMENT',
  SIDE_ARTIST_AGREEMENT = 'SIDE_ARTIST_AGREEMENT',
  PRODUCER_ARTIST_AGREEMENT = 'PRODUCER_ARTIST_AGREEMENT',
  LICENSE_AGREEMENT = 'LICENSE_AGREEMENT',
  UPLOADED_CONTRACT = 'UPLOADED_CONTRACT'
}

export interface Contract extends HasSimpleHistory {
  __typename?: 'Contract';
  id: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  title: Scalars['String'];
  type: ContractType;
  project?: Maybe<AmpProject>;
  project_id?: Maybe<Scalars['Int']>;
  master?: Maybe<AmpMaster>;
  master_id?: Maybe<Scalars['Int']>;
  state: ContractWorkflowState;
  metadata?: Maybe<ContractMetadata>;
  file?: Maybe<AmpFile>;
  file_id?: Maybe<Scalars['Int']>;
  date: Scalars['TimeStamp'];
  created_by: User;
  created_by_id: Scalars['Int'];
  parties: Array<ContractParty>;
  object_state?: Maybe<ObjectState>;
}

export enum ContractWorkflowState {
  DRAFT = 'DRAFT',
  REVIEW = 'REVIEW',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED'
}

export interface ContractMetadata {
  __typename?: 'ContractMetadata';
  aka?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  localFileName?: Maybe<Scalars['String']>;
}

export interface ContractParty extends HasSimpleHistory {
  __typename?: 'ContractParty';
  id: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  contract: Contract;
  contract_id: Scalars['Int'];
  name: Scalars['String'];
  accepted: Scalars['Boolean'];
  contact: Scalars['String'];
  role: ContractPartyRole;
  role_label: Scalars['String'];
  can_see_others: Scalars['Boolean'];
  following?: Maybe<ContractParty>;
  following_id?: Maybe<Scalars['Int']>;
  followers: Array<ContractParty>;
  metadata: ContractPartyMeta;
}

export enum ContractPartyRole {
  AUTHOR = 'AUTHOR',
  REVIEWER = 'REVIEWER',
  APPROVER = 'APPROVER'
}

export type ContractPartyMeta = UploadedPartyMeta | SplitPartyMeta;

export interface UploadedPartyMeta {
  __typename?: 'UploadedPartyMeta';
  advance?: Maybe<Scalars['Float']>;
  royalty?: Maybe<Scalars['Float']>;
  royalty_label?: Maybe<Scalars['String']>;
  recoup?: Maybe<Scalars['Float']>;
  recoup_label?: Maybe<Scalars['String']>;
  publishing?: Maybe<Scalars['Float']>;
}

export interface SplitPartyMeta {
  __typename?: 'SplitPartyMeta';
  affiliation?: Maybe<Scalars['String']>;
  ownership?: Maybe<Scalars['Float']>;
}

export interface ProjectFilter {
  id?: Maybe<WhereArgInt>;
  title?: Maybe<WhereArgString>;
}

export interface CollectionFilter {
  id?: Maybe<WhereArgInt>;
  name?: Maybe<WhereArgString>;
}

export interface AmpCollection extends HasSimpleHistory {
  __typename?: 'AMPCollection';
  id: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  catalog: Catalog;
  catalog_id: Scalars['Int'];
  collection_type: Scalars['Int'];
  artist_name: Scalars['String'];
  masters: Array<AmpMaster>;
  ordered_masters: Array<AmpMaster>;
  title: Scalars['String'];
  year: Scalars['Int'];
  type_id: Scalars['Int'];
  cover_art?: Maybe<AmpFile>;
  cover_art_id?: Maybe<Scalars['Int']>;
  country?: Maybe<Country>;
  master_positions: Array<AmpMasterCollectionPosition>;
  object_state?: Maybe<ObjectState>;
}


export interface AmpCollectionMastersArgs {
  where?: Maybe<MasterFilter>;
}


export interface AmpCollectionMaster_PositionsArgs {
  where: Maybe<MasterPositionFilter>;
}

export interface MasterPositionFilter {
  master?: Maybe<MasterFilter>;
  position?: Maybe<WhereArgInt>;
}

export interface AmpMasterCollectionPosition {
  __typename?: 'AMPMasterCollectionPosition';
  master_id: Scalars['Int'];
  position: Scalars['Int'];
}

export enum MixVariant {
  EXPLICIT = 'EXPLICIT',
  CLEAN = 'CLEAN'
}

export interface MetadataKeyword {
  __typename?: 'MetadataKeyword';
  id: Scalars['Int'];
  label: Scalars['String'];
}

export enum AlbumType {
  SINGLE = 'Single',
  ALBUM = 'Album',
  MASTER = 'MASTER',
  COLLECTION = 'COLLECTION'
}

export interface AlbumDraftEveara {
  __typename?: 'AlbumDraftEveara';
  releaseId?: Maybe<Scalars['Float']>;
  eanpc?: Maybe<Scalars['String']>;
  tracks?: Maybe<Array<Maybe<EvearaDraftAlbumTrack>>>;
  artists?: Maybe<Array<Maybe<AlbumArtistsRecord>>>;
  albumId?: Maybe<Scalars['Float']>;
  trackCount?: Maybe<Scalars['Int']>;
  subscription?: Maybe<EvearaAlbumSubscription>;
  isActive?: Maybe<Scalars['Int']>;
  productType?: Maybe<Scalars['String']>;
  productCodeType?: Maybe<Scalars['String']>;
  spatialEanUpc?: Maybe<Scalars['String']>;
  spatialProductCodeType?: Maybe<Scalars['String']>;
  coverImage?: Maybe<AlbumCoverImage>;
  removable?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  labelId?: Maybe<Scalars['Int']>;
  originalReleaseDate?: Maybe<Scalars['String']>;
}

export interface EvearaDraftAlbumTrack {
  __typename?: 'EvearaDraftAlbumTrack';
  asset_id?: Maybe<Scalars['Int']>;
  participant?: Maybe<Array<Maybe<EvearaAlbumTrackParticipant>>>;
  iswc?: Maybe<Scalars['String']>;
  artists?: Maybe<Array<Maybe<Scalars['String']>>>;
  isrc?: Maybe<Scalars['String']>;
  trackId?: Maybe<Scalars['Int']>;
  preview?: Maybe<EvearaAlbumTrackPreview>;
  name: Scalars['String'];
  featuredArtists?: Maybe<Array<Maybe<Scalars['String']>>>;
  trackUrl?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  lyrics?: Maybe<Scalars['String']>;
  genres?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicit?: Maybe<Scalars['Int']>;
  availability?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<TullyMetadataRecord>;
}

export interface EvearaAlbumTrackParticipant {
  __typename?: 'EvearaAlbumTrackParticipant';
  participantId?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Maybe<EvearaAlbumParticipantRole>>>;
  participantName?: Maybe<Scalars['String']>;
  payoutSharePercentage?: Maybe<Scalars['String']>;
}

export interface EvearaAlbumParticipantRole {
  __typename?: 'EvearaAlbumParticipantRole';
  roleId?: Maybe<Scalars['Int']>;
  roleName?: Maybe<Scalars['String']>;
}

export interface EvearaAlbumTrackPreview {
  __typename?: 'EvearaAlbumTrackPreview';
  startAt?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
}

export interface TullyMetadataRecord {
  __typename?: 'TullyMetadataRecord';
  genre?: Maybe<Scalars['String']>;
}

export interface AlbumArtistsRecord {
  __typename?: 'AlbumArtistsRecord';
  artistName?: Maybe<Scalars['String']>;
  artistCountry?: Maybe<Scalars['String']>;
}

export interface EvearaAlbumSubscription {
  __typename?: 'EvearaAlbumSubscription';
  subscriptionId?: Maybe<Scalars['Int']>;
  subscriptionName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
}

export interface AlbumCoverImage {
  __typename?: 'AlbumCoverImage';
  url?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
}

export interface TrackEveara {
  __typename?: 'TrackEveara';
  name?: Maybe<Scalars['String']>;
  isrc?: Maybe<Scalars['String']>;
  iswc?: Maybe<Scalars['String']>;
  albumOnly?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  trackUrl?: Maybe<Scalars['String']>;
  explicit?: Maybe<Scalars['String']>;
  trackId?: Maybe<Scalars['Int']>;
  removable?: Maybe<Scalars['Boolean']>;
  extention?: Maybe<Scalars['String']>;
  spatialIsrc?: Maybe<Scalars['String']>;
  dolbyAtmosIsrc?: Maybe<Scalars['String']>;
  availability?: Maybe<Array<Maybe<EvearaTrackAvailability>>>;
  genres?: Maybe<Array<Maybe<EvearaTrackGenres>>>;
  artists?: Maybe<Array<Maybe<EvearaTrackArtist>>>;
  featuredArtists?: Maybe<Array<Maybe<EvearaTrackArtist>>>;
  lyrics?: Maybe<Scalars['String']>;
}

export interface EvearaTrackAvailability {
  __typename?: 'EvearaTrackAvailability';
  availabilityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

export interface EvearaTrackGenres {
  __typename?: 'EvearaTrackGenres';
  genreId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

export interface EvearaTrackArtist {
  __typename?: 'EvearaTrackArtist';
  artistId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

export interface AlbumEveara {
  __typename?: 'AlbumEveara';
  releaseId?: Maybe<Scalars['Float']>;
  eanpc?: Maybe<Scalars['String']>;
  tracks?: Maybe<Array<Maybe<EvearaAlbumTrack>>>;
  artists?: Maybe<Array<Maybe<EvearaAlbumArtist>>>;
  albumId?: Maybe<Scalars['Float']>;
  trackCount?: Maybe<Scalars['Int']>;
  subscription?: Maybe<EvearaAlbumSubscription>;
  isActive?: Maybe<Scalars['Int']>;
  productType?: Maybe<Scalars['String']>;
  productCodeType?: Maybe<Scalars['String']>;
  spatialEanUpc?: Maybe<Scalars['String']>;
  spatialProductCodeType?: Maybe<Scalars['String']>;
  coverImage?: Maybe<AlbumCoverImage>;
  removable?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  labelId?: Maybe<Scalars['Int']>;
  originalReleaseDate?: Maybe<Scalars['String']>;
}

export interface EvearaAlbumTrack {
  __typename?: 'EvearaAlbumTrack';
  participant?: Maybe<Array<Maybe<EvearaAlbumTrackParticipant>>>;
  iswc?: Maybe<Scalars['String']>;
  artists?: Maybe<Array<Maybe<EvearaAlbumTrackArtist>>>;
  isrc?: Maybe<Scalars['String']>;
  trackId?: Maybe<Scalars['Int']>;
  preview?: Maybe<EvearaAlbumTrackPreview>;
  name?: Maybe<Scalars['String']>;
  featuredArtists?: Maybe<Array<Maybe<EvearaAlbumTrackArtist>>>;
}

export interface EvearaAlbumTrackArtist {
  __typename?: 'EvearaAlbumTrackArtist';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

export interface EvearaAlbumArtist {
  __typename?: 'EvearaAlbumArtist';
  artistName?: Maybe<Scalars['String']>;
  artistId?: Maybe<Scalars['Int']>;
}

export interface EvearaGenre {
  __typename?: 'EvearaGenre';
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface EvearaAvailability {
  __typename?: 'EvearaAvailability';
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface EvearaRole {
  __typename?: 'EvearaRole';
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface EvearaLanguage {
  __typename?: 'EvearaLanguage';
  languageCode?: Maybe<Scalars['String']>;
  languageName?: Maybe<Scalars['String']>;
}

export interface EvearaCountry {
  __typename?: 'EvearaCountry';
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  state?: Maybe<Array<Maybe<EvearaCountryState>>>;
}

export interface EvearaCountryState {
  __typename?: 'EvearaCountryState';
  stateCode?: Maybe<Scalars['String']>;
  StateName?: Maybe<Scalars['String']>;
}

export interface EvearaSubscription {
  __typename?: 'EvearaSubscription';
  mySubscriptionId?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['String']>;
  artistsAdded?: Maybe<Scalars['Int']>;
  tracksAdded?: Maybe<Scalars['Int']>;
  albumsAdded?: Maybe<Scalars['Int']>;
  deactivateEnabled?: Maybe<Scalars['Boolean']>;
  reactivateEnabled?: Maybe<Scalars['Boolean']>;
  cancelEnabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  subscriptionDetails?: Maybe<EvearaSubscriptionDetails>;
}

export interface EvearaSubscriptionDetails {
  __typename?: 'EvearaSubscriptionDetails';
  subscriptionId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  totalNumberOfArtist?: Maybe<Scalars['String']>;
  totalNumberOfTracks?: Maybe<Scalars['String']>;
  totalNumberOfAlbums?: Maybe<Scalars['String']>;
}

export interface EvearaLabel {
  __typename?: 'EvearaLabel';
  labelId?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
  labelName?: Maybe<Scalars['String']>;
  removable?: Maybe<Scalars['Boolean']>;
}

export interface EvearaParticipant {
  __typename?: 'EvearaParticipant';
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
  ipn?: Maybe<Scalars['String']>;
  isni?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paypalEmailId?: Maybe<Scalars['String']>;
  removable?: Maybe<Scalars['Boolean']>;
}

export interface EvearaArtist {
  __typename?: 'EvearaArtist';
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  removable?: Maybe<Scalars['Boolean']>;
}

export interface EvearaOutletsDetailsByAlbum {
  __typename?: 'EvearaOutletsDetailsByAlbum';
  storeId?: Maybe<Scalars['String']>;
  storeName?: Maybe<Scalars['String']>;
  outletStatus?: Maybe<Scalars['String']>;
  preSalesDate?: Maybe<Scalars['String']>;
  releaseStartDate?: Maybe<Scalars['String']>;
  releaseEndDate?: Maybe<Scalars['String']>;
  processDurationDates?: Maybe<Scalars['Int']>;
  isPresalesDateEnabled?: Maybe<Scalars['Boolean']>;
  childStores?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface EvearaOutletsSmartLinks {
  __typename?: 'EvearaOutletsSmartLinks';
  storeName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface ReleaseEvearaInput {
  releaseId?: Maybe<Scalars['Float']>;
  type?: Maybe<ReleasesEvearaType>;
  text?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<ReleaseSort>>>;
}

export enum ReleasesEvearaType {
  RELEASED = 'RELEASED',
  DRAFT = 'DRAFT'
}

export interface ReleaseSort {
  column: ReleaseSortColumn;
  type: ReleaseSortType;
}

export enum ReleaseSortColumn {
  NAME = 'NAME',
  FORMAT = 'FORMAT',
  CODE = 'CODE'
}

export enum ReleaseSortType {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface ReleasesEveara {
  __typename?: 'ReleasesEveara';
  pageInfo: PageInfo;
  result?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<ReleasesEvearaEdge>>;
}

export interface PageInfo {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  endCursor?: Maybe<Scalars['String']>;
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
}

export interface ReleasesEvearaEdge {
  __typename?: 'ReleasesEvearaEdge';
  cursor: Scalars['String'];
  node: AlbumEveara;
}

export interface CatalogFilter {
  id?: Maybe<WhereArgInt>;
  name?: Maybe<WhereArgString>;
}

export interface MasterWatchFilter {
  master_id?: Maybe<WhereArgInt>;
}

export interface MasterWatch {
  __typename?: 'MasterWatch';
  id: Scalars['Int'];
  master_id: Scalars['Int'];
  watched_subfolders?: Maybe<Array<Resources>>;
}

export interface AssetsFilter {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  catalog_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  title?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<AssetType>>>;
  keywords?: Maybe<Array<Scalars['String']>>;
}

export interface Assets {
  __typename?: 'Assets';
  pageInfo: PageInfo;
  result?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<AssetsEdge>>;
}

export interface AssetsEdge {
  __typename?: 'AssetsEdge';
  cursor: Scalars['String'];
  node: AmpAsset;
}

export interface TullyBeatResult {
  __typename?: 'TullyBeatResult';
  id: Scalars['Int'];
  fire_node_id: Scalars['String'];
  title: Scalars['String'];
  sequence: Scalars['Int'];
  is_free: Scalars['Boolean'];
  is_active: Scalars['Boolean'];
  is_bought?: Maybe<Scalars['Boolean']>;
  file: AmpFile;
  meta?: Maybe<AudioMetaSuccess>;
  metadata?: Maybe<AssetMetadata>;
  price: Scalars['Float'];
  ios_name: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
}

export enum TullyBeatType {
  ALL = 'ALL',
  FREE = 'FREE',
  PREMIUM = 'PREMIUM'
}

export interface TullyBeats {
  __typename?: 'TullyBeats';
  pageInfo: PageInfo;
  result?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<TullyBeatsEdge>>;
}

export interface TullyBeatsEdge {
  __typename?: 'TullyBeatsEdge';
  cursor: Scalars['String'];
  node: TullyBeatResult;
}

export interface AssetGroup {
  __typename?: 'AssetGroup';
  id: Scalars['Int'];
  title: Scalars['String'];
  assets: Array<AmpAsset>;
}

export interface AssetGroupFilter {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  title?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['Int']>;
  catalog_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export interface ReleaseDetails {
  __typename?: 'ReleaseDetails';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  master_id?: Maybe<Scalars['Int']>;
  artist_name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['Int']>;
  product_type?: Maybe<Scalars['Int']>;
  type_name?: Maybe<Scalars['String']>;
  release_date?: Maybe<Scalars['TimeStamp']>;
  code_type?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  cover_image?: Maybe<Array<Maybe<AlbumCoverImage>>>;
}

export interface TrackDetails {
  __typename?: 'TrackDetails';
  id?: Maybe<Scalars['Int']>;
  asset_id?: Maybe<Scalars['Int']>;
  release_id?: Maybe<Scalars['Int']>;
  song_title?: Maybe<Scalars['String']>;
  preview_start?: Maybe<Scalars['Int']>;
  preview_end?: Maybe<Scalars['Int']>;
  genres?: Maybe<Array<Maybe<Genres>>>;
  artist?: Maybe<Scalars['String']>;
  featured_artist?: Maybe<Scalars['Int']>;
  isrc?: Maybe<Scalars['Int']>;
  iswc?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['Int']>;
  parental_advisory?: Maybe<Scalars['Int']>;
  for_type?: Maybe<Array<Maybe<Types>>>;
  song_lyrics?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Maybe<ParticipantAndRoles>>>;
}

export interface Genres {
  __typename?: 'Genres';
  id?: Maybe<Scalars['Int']>;
  genre?: Maybe<Scalars['Int']>;
}

export interface Types {
  __typename?: 'Types';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
}

export interface ParticipantAndRoles {
  __typename?: 'ParticipantAndRoles';
  id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  participant?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Maybe<Roles>>>;
  payout?: Maybe<Scalars['Int']>;
}

export interface Roles {
  __typename?: 'Roles';
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
}

export interface OutletsDetails {
  __typename?: 'OutletsDetails';
  id?: Maybe<Scalars['Int']>;
  release_id?: Maybe<Scalars['Int']>;
  outlet?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  start_release?: Maybe<Scalars['TimeStamp']>;
}

export enum AlbumTypeEveara {
  SINGLE = 'Single',
  ALBUM = 'Album'
}

export interface TrackDraftEveara {
  __typename?: 'TrackDraftEveara';
  name: Scalars['String'];
  isrc?: Maybe<Scalars['String']>;
  iswc?: Maybe<Scalars['String']>;
  albumOnly?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  trackUrl: Scalars['String'];
  explicit?: Maybe<Scalars['Int']>;
  trackId: Scalars['Int'];
  removable?: Maybe<Scalars['Boolean']>;
  extention?: Maybe<Scalars['String']>;
  spatialIsrc?: Maybe<Scalars['String']>;
  dolbyAtmosIsrc?: Maybe<Scalars['String']>;
  availability?: Maybe<Array<Maybe<EvearaTrackAvailability>>>;
  genres?: Maybe<Array<Maybe<EvearaTrackGenres>>>;
  artists?: Maybe<Array<Maybe<EvearaTrackArtist>>>;
  featuredArtists?: Maybe<Array<Maybe<EvearaTrackArtist>>>;
  lyrics?: Maybe<Scalars['String']>;
}

export interface EvearaOutlet {
  __typename?: 'EvearaOutlet';
  storeId: Scalars['String'];
  storeName: Scalars['String'];
  processDurationDates: Scalars['Int'];
  isPresalesDateEnabled?: Maybe<Scalars['Boolean']>;
  childStores?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface GetDistributionRecordsConnectionFiltersInput {
  status?: Maybe<DistributionStatus>;
  titleSearchTerm?: Maybe<Scalars['String']>;
  type?: Maybe<DistributionType>;
}

export enum DistributionStatus {
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED'
}

export enum DistributionType {
  SINGLE = 'SINGLE',
  ALBUM = 'ALBUM'
}

export interface GetDistributionRecordConnectionsResult {
  __typename?: 'GetDistributionRecordConnectionsResult';
  edges: Array<DistributionRecordEdge>;
  pageInfo: PageInfo;
}

export interface DistributionRecordEdge {
  __typename?: 'DistributionRecordEdge';
  cursor: Scalars['String'];
  node: DistributionRecord;
}

export interface DistributionRecord {
  __typename?: 'DistributionRecord';
  id: Scalars['Int'];
  type: DistributionType;
  status: DistributionStatus;
  artistCountry?: Maybe<Country>;
  codeType: DistributionCodeType;
  code?: Maybe<Scalars['String']>;
  distributeToFutureOutlets: Scalars['Boolean'];
  collection?: Maybe<AmpCollection>;
  master?: Maybe<AmpMaster>;
  tracks: Array<DistributionRecordTrack>;
  outlets?: Maybe<Array<DistributionRecordOutletDetails>>;
  createdAt: Scalars['TimeStamp'];
  updatedAt: Scalars['TimeStamp'];
  evearaReleaseId?: Maybe<Scalars['String']>;
  distributedDate?: Maybe<Scalars['TimeStamp']>;
  distributionFinishedDate?: Maybe<Scalars['TimeStamp']>;
  tookDownDate?: Maybe<Scalars['TimeStamp']>;
  takingDownFinishedDate?: Maybe<Scalars['TimeStamp']>;
}

export enum DistributionCodeType {
  EAN = 'EAN',
  UPC = 'UPC',
  JAN = 'JAN'
}

export interface DistributionRecordTrack {
  __typename?: 'DistributionRecordTrack';
  id: Scalars['Int'];
  record: DistributionRecord;
  distributeClean: Scalars['Boolean'];
  distributeExplicit: Scalars['Boolean'];
  master: AmpMaster;
  preview?: Maybe<DistributionTrackPreview>;
  isForStreaming: Scalars['Boolean'];
  isForDownloading: Scalars['Boolean'];
  iswc?: Maybe<Scalars['String']>;
  lyrics?: Maybe<Scalars['String']>;
  createdAt: Scalars['TimeStamp'];
  updatedAt: Scalars['TimeStamp'];
  cleanVersionEvearaId?: Maybe<Scalars['Int']>;
  explicitVersionEvearaId?: Maybe<Scalars['Int']>;
}

export interface DistributionTrackPreview {
  __typename?: 'DistributionTrackPreview';
  startAt?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
}

export interface DistributionRecordOutletDetails {
  __typename?: 'DistributionRecordOutletDetails';
  id: Scalars['Int'];
  record: DistributionRecord;
  distributorOutletId: Scalars['String'];
  preSalesDate?: Maybe<Scalars['TimeStamp']>;
  startReleaseDate?: Maybe<Scalars['TimeStamp']>;
  endReleaseDate?: Maybe<Scalars['TimeStamp']>;
  createdAt: Scalars['TimeStamp'];
  updatedAt: Scalars['TimeStamp'];
}

export enum InviteStatus {
  PENDING = 'PENDING',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT'
}

export interface UserInviteModel {
  __typename?: 'UserInviteModel';
  id: Scalars['Int'];
  user?: Maybe<User>;
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
  status: InviteStatus;
  meta?: Maybe<Scalars['String']>;
}

export interface CollabProject {
  __typename?: 'CollabProject';
  id?: Maybe<Scalars['Float']>;
  fire_node_id: Scalars['String'];
  created_at: Scalars['TimeStamp'];
  updated_at?: Maybe<Scalars['TimeStamp']>;
  collab_id: Scalars['String'];
  project_name: Scalars['String'];
  status: Scalars['String'];
  owner: Scalars['String'];
  member_count: Scalars['Int'];
  beat?: Maybe<CollabAsset>;
  lyrics: Array<CollabLyrics>;
  assets: Array<CollabAsset>;
}

export interface CollabAsset {
  __typename?: 'CollabAsset';
  id: Scalars['Float'];
  created_at?: Maybe<Scalars['TimeStamp']>;
  updated_at?: Maybe<Scalars['TimeStamp']>;
  fire_node_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['Int']>;
  downloadURL?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Float']>;
  tid?: Maybe<Scalars['String']>;
  bpm?: Maybe<Scalars['Float']>;
  key?: Maybe<Scalars['String']>;
}

export interface CollabLyrics {
  __typename?: 'CollabLyrics';
  content: Scalars['String'];
}

export interface SearchContentType {
  __typename?: 'SearchContentType';
  id: Scalars['Int'];
  category: Scalars['Int'];
  category_name: Scalars['String'];
  display_label: Scalars['String'];
  directory: Scalars['String'];
  type: Scalars['Int'];
  search_result_priority: Scalars['Int'];
}

export interface SavedSearch extends HasSimpleHistory {
  __typename?: 'SavedSearch';
  id: Scalars['Int'];
  name: Scalars['String'];
  filter?: Maybe<SearchFilter>;
  sort?: Maybe<Array<SearchSort>>;
  public?: Maybe<Scalars['Boolean']>;
  shared_with?: Maybe<Array<Maybe<User>>>;
  user?: Maybe<User>;
  user_id: Scalars['Int'];
  account?: Maybe<Account>;
  account_id: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
}

export interface SearchFilter {
  __typename?: 'SearchFilter';
  name?: Maybe<Scalars['String']>;
  author?: Maybe<Array<User>>;
  catalog?: Maybe<Array<Catalog>>;
  content_type?: Maybe<Array<SearchContentType>>;
  from_date?: Maybe<Scalars['TimeStamp']>;
  to_date?: Maybe<Scalars['TimeStamp']>;
  period?: Maybe<Scalars['Int']>;
  period_unit?: Maybe<SearchPeriodUnit>;
}

export enum SearchPeriodUnit {
  YEARS = 'YEARS',
  MONTHS = 'MONTHS',
  DAYS = 'DAYS'
}

export interface SearchSort {
  __typename?: 'SearchSort';
  field: SortField;
  direction: SortDirection;
}

export enum SortField {
  FOLDER = 'FOLDER',
  NAME = 'NAME',
  CATALOG = 'CATALOG',
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
  AUTHOR = 'AUTHOR'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface SearchFilterInput {
  author?: Maybe<Array<Scalars['Int']>>;
  catalog?: Maybe<Array<Scalars['Int']>>;
  content_type?: Maybe<Array<Scalars['Int']>>;
  from_date?: Maybe<Scalars['TimeStamp']>;
  to_date?: Maybe<Scalars['TimeStamp']>;
  period?: Maybe<Scalars['Int']>;
  period_unit?: Maybe<SearchPeriodUnit>;
  name?: Maybe<Scalars['String']>;
}

export interface SearchSortInput {
  field?: Maybe<SortField>;
  direction?: Maybe<SortDirection>;
}

export interface Search {
  __typename?: 'Search';
  pageInfo: PageInfo;
  edges?: Maybe<Array<SearchEdge>>;
}

export interface SearchEdge {
  __typename?: 'SearchEdge';
  cursor: Scalars['String'];
  node: SearchResult;
}

export interface SearchResult extends HasSimpleHistory {
  __typename?: 'SearchResult';
  id: Scalars['Int'];
  entity_id: Scalars['Int'];
  name: Scalars['String'];
  folder: Scalars['String'];
  catalog: Catalog;
  catalog_id: Scalars['Int'];
  author?: Maybe<User>;
  author_id?: Maybe<Scalars['Int']>;
  type: SearchContentType;
  parent_id?: Maybe<Scalars['Int']>;
  parent_type?: Maybe<Scalars['String']>;
  parent_name?: Maybe<Scalars['String']>;
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
}

export interface ActionFilter {
  id?: Maybe<WhereArgInt>;
  content_type_id?: Maybe<WhereArgInt>;
  subject_id?: Maybe<WhereArgInt>;
  action_type?: Maybe<WhereArgString>;
  object_id?: Maybe<WhereArgInt>;
  created_at?: Maybe<WhereArgTimestamp>;
  search_content_type_id?: Maybe<WhereArgInt>;
  catalog_id?: Maybe<WhereArgInt>;
  title?: Maybe<WhereArgString>;
}

export interface WhereArgTimestamp {
  exact?: Maybe<Scalars['TimeStamp']>;
  gt?: Maybe<Scalars['TimeStamp']>;
  lt?: Maybe<Scalars['TimeStamp']>;
  lte?: Maybe<Scalars['TimeStamp']>;
  gte?: Maybe<Scalars['TimeStamp']>;
  in?: Maybe<Array<Maybe<Scalars['TimeStamp']>>>;
}

export interface Action extends HasSimpleHistory {
  __typename?: 'Action';
  id: Scalars['Int'];
  account: Account;
  subject?: Maybe<User>;
  action_type: ActionType;
  object_id: Scalars['Int'];
  object: ActionObject;
  snapshot?: Maybe<ActionObject>;
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  /** False for unseen */
  is_seen: Scalars['Boolean'];
  title: Scalars['String'];
  catalog_id?: Maybe<Scalars['Int']>;
  catalog_name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  parent_name?: Maybe<Scalars['String']>;
  parent_search_content_type_id: Scalars['Int'];
  search_content_type_id: Scalars['Int'];
  /** For non-registred subjects */
  subject_email?: Maybe<Scalars['String']>;
  /**
   * Tells if the item could be opened.
   * In other words them item is not deleted/soft-deleted/archived
   */
  exists: Scalars['Boolean'];
  /** (non-generic) For Collections only */
  image_file_uri?: Maybe<Scalars['String']>;
  /** (non-generic) For Collections only */
  collection_type?: Maybe<Scalars['String']>;
  /** (non-generic) For shared items only */
  share_recipients: Array<SharedRecipient>;
}

export enum ActionType {
  IMPORTED = 'IMPORTED',
  RENAMED = 'RENAMED',
  DELETED = 'DELETED',
  SHARED = 'SHARED',
  SHARE_ACCESS = 'SHARE_ACCESS',
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  SENT = 'SENT',
  APPROVED = 'APPROVED',
  STATUS_CHANGED = 'STATUS_CHANGED',
  FILE_ATTACHED = 'FILE_ATTACHED',
  USER_ACTIVATED = 'USER_ACTIVATED',
  USER_DEACTIVATED = 'USER_DEACTIVATED',
  USER_INVITED = 'USER_INVITED',
  USER_MADE_ADMIN = 'USER_MADE_ADMIN',
  USER_REVOKED_ADMIN = 'USER_REVOKED_ADMIN',
  USER_DELETED = 'USER_DELETED',
  ARTIST_CREATED = 'ARTIST_CREATED',
  ARTIST_INVITED = 'ARTIST_INVITED',
  ARTIST_DELETED = 'ARTIST_DELETED',
  GUEST_CREATED = 'GUEST_CREATED',
  GUEST_INVITED = 'GUEST_INVITED',
  GUEST_DELETED = 'GUEST_DELETED',
  DONE = 'DONE'
}

export type ActionObject = AmpMaster | AmpProject | AmpAsset | AmpLyrics | AmpCollection | Contract | User | UserInviteModel;

export interface SharedRecipient {
  __typename?: 'SharedRecipient';
  uuid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  expired: Scalars['Boolean'];
  first_used_at?: Maybe<Scalars['TimeStamp']>;
  /** (computed) */
  is_guest?: Maybe<Scalars['Boolean']>;
  /** (computed) user/invited user. Might be required when user is null */
  authority_holder: User;
  /** (computed) */
  is_access_expired: Scalars['Boolean'];
  /** (computed) */
  is_active_two_factory_auth: Scalars['Boolean'];
}

export enum ActionCategory {
  MY_WORK = 'MY_WORK',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
  ACTIVITY = 'ACTIVITY'
}

export interface ActionConnection {
  __typename?: 'ActionConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ActionEdge>>>;
}

export interface ActionEdge {
  __typename?: 'ActionEdge';
  cursor: Scalars['String'];
  node: Action;
}

export interface ActionCounters {
  __typename?: 'ActionCounters';
  myWork: Scalars['Int'];
  assignedToMe: Scalars['Int'];
  activity: Scalars['Int'];
}

export interface Role extends HasSimpleHistory {
  __typename?: 'Role';
  name: Scalars['String'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  account: Account;
  catalogs: Array<Maybe<RoleCatalog>>;
  users: Array<User>;
  invited_users: Array<Maybe<UserInviteModel>>;
}

export interface RoleCatalog {
  __typename?: 'RoleCatalog';
  catalog: Catalog;
  permissions: Array<Maybe<CatalogPermission>>;
}

export interface CatalogPermission {
  __typename?: 'CatalogPermission';
  permission_level: PermissionLevel;
  permission: Permission;
}

export enum PermissionLevel {
  NEUTRAL = 'NEUTRAL',
  ALLOWED = 'ALLOWED',
  DENIED = 'DENIED'
}

export interface Permission {
  __typename?: 'Permission';
  id: Scalars['Int'];
  name: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['Int'];
  priority: Scalars['Int'];
  description: Scalars['String'];
}

export enum DropStatus {
  CLOSED = 'CLOSED',
  ACTIVE = 'ACTIVE'
}

export enum DropSubStatus {
  OPEN = 'OPEN',
  COMING_SOON = 'COMING_SOON'
}

export interface Drop {
  __typename?: 'Drop';
  id: Scalars['Int'];
  name: Scalars['String'];
  artist_name: Scalars['String'];
  cover_file_uri: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  type?: Maybe<DropType>;
  close_date?: Maybe<Scalars['TimeStamp']>;
  status: DropStatus;
  sub_status?: Maybe<DropSubStatus>;
  date_created: Scalars['TimeStamp'];
  order_id?: Maybe<Scalars['Int']>;
  mp3_file_uri?: Maybe<Scalars['String']>;
  wav_file_uri?: Maybe<Scalars['String']>;
  custom_field?: Maybe<CustomField>;
}

export enum DropType {
  FOR_ARTISTS = 'FOR_ARTISTS',
  FOR_PRODUCERS = 'FOR_PRODUCERS'
}

export interface CustomField {
  __typename?: 'CustomField';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<CustomFieldValue>>>;
}

export interface CustomFieldValue {
  __typename?: 'CustomFieldValue';
  id: Scalars['Int'];
  custom_field: CustomField;
  value: Scalars['String'];
}

export interface GetDropsConnectionFiltersInput {
  status?: Maybe<DropStatus>;
  sub_status?: Maybe<DropSubStatus>;
}

export interface GetDropsConnectionsResult {
  __typename?: 'GetDropsConnectionsResult';
  edges: Array<DropEdge>;
  pageInfo: PageInfo;
}

export interface DropEdge {
  __typename?: 'DropEdge';
  cursor: Scalars['String'];
  node: Drop;
}

export interface Mutation {
  __typename?: 'Mutation';
  addAlbumEveara?: Maybe<EvearaAddAlbumResponse>;
  addArtistEveara?: Maybe<EvearaAddArtistResponse>;
  /**
   * Adds existing collaborator to existing metadata object
   * Use masterId or metadataId to identify metadata object, not both
   * :param masterId: (optional) find metadata by master
   * :param metadataId: (optional) refer to metadata directly
   * :param collaboratorId: Id of TullyPersonMetadata
   */
  addCollaborator?: Maybe<Error>;
  addLabelEveara?: Maybe<EvearaAddLabelResponse>;
  addMasterToCollection?: Maybe<AmpCollection>;
  addParticipantEveara?: Maybe<EvearaAddParticipantResponse>;
  addSubscriptionEveara?: Maybe<Array<EvearaAddSubscriptionResponse>>;
  addTrackEveara?: Maybe<Array<EvearaAddTrackResponse>>;
  addTrackToDistributionRecord: DistributionRecordTrack;
  addUserToRoles: User;
  archiveAsset?: Maybe<AmpAsset>;
  archiveMaster?: Maybe<AmpMaster>;
  archiveProject?: Maybe<AmpProject>;
  assignAdmin: User;
  attachCollectionCoverArt?: Maybe<AmpCollection>;
  createAssetGroup?: Maybe<AssetGroup>;
  createCollection?: Maybe<AmpCollection>;
  createContract?: Maybe<Contract>;
  createContractParty?: Maybe<ContractParty>;
  createDraftOutletsDetails?: Maybe<Array<Maybe<OutletsDetails>>>;
  createDraftReleaseDetails?: Maybe<ReleaseDetails>;
  createDraftTrackDetails?: Maybe<TrackDetails>;
  createGuestUser: User;
  createLyrics?: Maybe<AmpLyrics>;
  createMaster?: Maybe<AmpMaster>;
  createOrUpdateRelease?: Maybe<CreateOrUpdateReleaseAllDetails>;
  createProjectFromBeat: AmpProject;
  createRole: UmRole;
  createSavedSearch: SavedSearch;
  createTullyMetadataArtist: TullyMetadataArtist;
  createTullyMetadataParticipant: TullyMetadataParticipant;
  createTullyPersonMetadata?: Maybe<TullyPersonMetadata>;
  createUserInvite: UserInviteModel;
  deleteAlbumEveara?: Maybe<EvearaBaseResponse>;
  deleteArtistEveara?: Maybe<EvearaBaseResponse>;
  deleteAsset: Scalars['Int'];
  deleteAssetGroup: Scalars['Int'];
  deleteCollection: Scalars['Int'];
  /** Returns number of deleted items */
  deleteContract: Scalars['Int'];
  deleteContractParty: Scalars['Int'];
  deleteDistributionRecord: DistributionRecord;
  deleteDraftAlbumEveara?: Maybe<EvearaBaseResponse>;
  deleteDraftOutletsDetails: Scalars['Int'];
  deleteDraftReleaseDetails: Scalars['Int'];
  deleteDraftTrackDetails: Scalars['Int'];
  deleteDraftTrackEveara?: Maybe<EvearaBaseResponse>;
  deleteExcludedResource: Resources;
  /**
   * (Should) return number of deleted items
   * @deprecated Field no longer supported
   */
  deleteGuestUser?: Maybe<Scalars['Int']>;
  deleteInvitedUser: Scalars['Int'];
  deleteLyrics: Scalars['Int'];
  deleteMaster: Scalars['Int'];
  deleteMultiExcludedResources: Array<Resources>;
  deleteParticipantEveara?: Maybe<EvearaBaseResponse>;
  deleteProject: Scalars['Int'];
  deleteRole: NoResponse;
  deleteSavedSearch: Scalars['Int'];
  deleteTrackEveara?: Maybe<EvearaBaseResponse>;
  deleteTullyPersonMetadata?: Maybe<Scalars['Int']>;
  /** (Should) return number of deleted items */
  deleteUser?: Maybe<Scalars['Int']>;
  distributeAlbumEveara?: Maybe<EvearaBaseResponse>;
  distributeAlbumToFutureOutlets?: Maybe<EvearaBaseResponse>;
  distributeDistributionRecord: DistributionRecord;
  /** This does not remove any files. To remove asset use deleteAsset mutation */
  dropCollectionCoverArt?: Maybe<AmpCollection>;
  generateLyricsByThemeWithChatGPT: OpenAiPromptRecord;
  leaveAccount?: Maybe<Error>;
  markActionsAsSeen?: Maybe<Error>;
  rateOpenAIPromptRecordCompletion: OpenAiPromptRecord;
  removeMasterFromCollection?: Maybe<AmpCollection>;
  removeTrackFromDistributionRecord: DistributionRecordTrack;
  removeUserFromRoles: User;
  resendInviteUser: UserInviteModel;
  restoreAsset?: Maybe<AmpAsset>;
  restoreCollection?: Maybe<AmpCollection>;
  /** Restore deleted/archived */
  restoreLyrics?: Maybe<AmpLyrics>;
  restoreMaster?: Maybe<AmpMaster>;
  restoreProject?: Maybe<AmpProject>;
  rewriteLyricsWithChatGPT: OpenAiPromptRecord;
  setExcludedResource: Resources;
  setMasterWatch?: Maybe<MasterWatch>;
  setMultiExcludedResources: Array<Resources>;
  setUserStatus: User;
  synchronizeAndGetDistributionRecord: DistributionRecord;
  synchronizeAndGetDistributionRecordById: DistributionRecord;
  takeDownDistributionRecord: DistributionRecord;
  takedownOutletsEveara?: Maybe<EvearaBaseResponse>;
  unassignAdmin: User;
  updateAlbumEveara?: Maybe<EvearaBaseResponse>;
  updateAssetGroup?: Maybe<AssetGroup>;
  updateCollection?: Maybe<AmpCollection>;
  updateCollectionMasterPosition?: Maybe<AmpCollection>;
  updateContract?: Maybe<Contract>;
  updateContractParty?: Maybe<ContractParty>;
  updateCurrentUser: User;
  updateCurrentUserMasterItems: User;
  updateCurrentUserMenu: User;
  updateDistributionRecord: DistributionRecord;
  updateDistributionRecordOutletsDetails?: Maybe<UpdateDistributionRecordOutletsDetailsResponse>;
  updateDistributionRecordTracks: Array<DistributionRecordTrack>;
  updateGuestUser: User;
  updateLyrics?: Maybe<AmpLyrics>;
  updateMaster?: Maybe<AmpMaster>;
  updateMasterMeta?: Maybe<AmpMaster>;
  updateOutletsEveara?: Maybe<EvearaBaseResponse>;
  updateRole: UmRole;
  updateSavedSearch: SavedSearch;
  updateTrackEveara?: Maybe<EvearaBaseResponse>;
  updateTullyPersonMetadata?: Maybe<TullyPersonMetadata>;
  updateUserRoles: UserRolesUpdateResponse;
  validateAlbumEveara?: Maybe<EvearaValidateAlbumResponse>;
}


export interface MutationAddAlbumEvearaArgs {
  data: AlbumEvearaInput;
}


export interface MutationAddArtistEvearaArgs {
  name: Scalars['String'];
  country_code: Scalars['String'];
}


export interface MutationAddCollaboratorArgs {
  masterId: Maybe<Scalars['Int']>;
  metadataId: Maybe<Scalars['Int']>;
  collaboratorId: Scalars['Int'];
}


export interface MutationAddLabelEvearaArgs {
  name: Scalars['String'];
}


export interface MutationAddMasterToCollectionArgs {
  id: Scalars['Int'];
  data: CollectionMasterInput;
}


export interface MutationAddParticipantEvearaArgs {
  name: Scalars['String'];
  isni: Maybe<Scalars['String']>;
  ipn: Maybe<Scalars['String']>;
}


export interface MutationAddSubscriptionEvearaArgs {
  data: Maybe<Array<Maybe<SubscriptionEvearaInput>>>;
}


export interface MutationAddTrackEvearaArgs {
  data: Maybe<Array<EvearaTrackInput>>;
}


export interface MutationAddTrackToDistributionRecordArgs {
  recordId: Scalars['Int'];
  masterId: Scalars['Int'];
}


export interface MutationAddUserToRolesArgs {
  user_id: Scalars['Int'];
  role_ids: Array<Scalars['Int']>;
}


export interface MutationArchiveAssetArgs {
  id: Scalars['Int'];
}


export interface MutationArchiveMasterArgs {
  id: Scalars['Int'];
}


export interface MutationArchiveProjectArgs {
  id: Scalars['Int'];
}


export interface MutationAssignAdminArgs {
  id: Scalars['Int'];
}


export interface MutationAttachCollectionCoverArtArgs {
  id: Scalars['Int'];
  data: CollectionCoverArtInput;
}


export interface MutationCreateAssetGroupArgs {
  data: AssetGroupInput;
}


export interface MutationCreateCollectionArgs {
  data: CollectionInput;
}


export interface MutationCreateContractArgs {
  data: ContractInput;
}


export interface MutationCreateContractPartyArgs {
  data: ContractPartyInput;
}


export interface MutationCreateDraftOutletsDetailsArgs {
  releaseId: Scalars['Int'];
  Details: Maybe<Array<Maybe<OutletsDetailsInput>>>;
}


export interface MutationCreateDraftReleaseDetailsArgs {
  masterId: Scalars['Int'];
  userId: Scalars['Int'];
  Details: Maybe<ReleaseDetailsInput>;
}


export interface MutationCreateDraftTrackDetailsArgs {
  releaseId: Scalars['Int'];
  Details: Maybe<TrackDetailsInput>;
}


export interface MutationCreateGuestUserArgs {
  data: UserInput;
}


export interface MutationCreateLyricsArgs {
  data: AmpLyricsInput;
}


export interface MutationCreateMasterArgs {
  data: AmpMasterInput;
}


export interface MutationCreateOrUpdateReleaseArgs {
  masterId: Scalars['Int'];
  userId: Scalars['Int'];
  Release: Maybe<ReleaseDetailsInput>;
  Tracks: Maybe<Array<Maybe<TrackDetailsInput>>>;
  Outlets: Maybe<Array<Maybe<OutletsDetailsInput>>>;
}


export interface MutationCreateProjectFromBeatArgs {
  id: Scalars['Int'];
  data: ProjectFromBeatInput;
}


export interface MutationCreateRoleArgs {
  input: UmRoleInput;
}


export interface MutationCreateSavedSearchArgs {
  data: CreateSavedSearchInput;
}


export interface MutationCreateTullyMetadataArtistArgs {
  data: CreateTullyMetadataArtistInput;
}


export interface MutationCreateTullyMetadataParticipantArgs {
  data: CreateTullyMetadataParticipantInput;
}


export interface MutationCreateTullyPersonMetadataArgs {
  data: TullyPersonMetadataInput;
}


export interface MutationCreateUserInviteArgs {
  data: UserInviteInput;
}


export interface MutationDeleteAlbumEvearaArgs {
  releaseId: Scalars['Float'];
}


export interface MutationDeleteArtistEvearaArgs {
  artistId: Scalars['Int'];
}


export interface MutationDeleteAssetArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteAssetGroupArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteCollectionArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteContractArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteContractPartyArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteDistributionRecordArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteDraftAlbumEvearaArgs {
  id: Scalars['Float'];
  albumType: AlbumTypeEveara;
}


export interface MutationDeleteDraftOutletsDetailsArgs {
  outletId: Scalars['Int'];
}


export interface MutationDeleteDraftReleaseDetailsArgs {
  releaseId: Scalars['Int'];
}


export interface MutationDeleteDraftTrackDetailsArgs {
  trackId: Scalars['Int'];
}


export interface MutationDeleteDraftTrackEvearaArgs {
  id: Scalars['Float'];
  albumType: AlbumTypeEveara;
}


export interface MutationDeleteExcludedResourceArgs {
  resource_id: Scalars['Int'];
}


export interface MutationDeleteGuestUserArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteInvitedUserArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteLyricsArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteMasterArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteMultiExcludedResourcesArgs {
  resource_id: Array<Maybe<Scalars['Int']>>;
}


export interface MutationDeleteParticipantEvearaArgs {
  participantId: Scalars['Int'];
}


export interface MutationDeleteProjectArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteRoleArgs {
  input: RoleDeleteInput;
}


export interface MutationDeleteSavedSearchArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteTrackEvearaArgs {
  trackLibraryId: Scalars['Int'];
}


export interface MutationDeleteTullyPersonMetadataArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteUserArgs {
  id: Scalars['Int'];
}


export interface MutationDistributeAlbumEvearaArgs {
  releaseId: Scalars['Float'];
  outletsDetails: Array<OutletsDetailsEveara>;
}


export interface MutationDistributeAlbumToFutureOutletsArgs {
  releaseId: Scalars['Float'];
  enableDistributeToFutureOutlets: Scalars['Boolean'];
}


export interface MutationDistributeDistributionRecordArgs {
  id: Scalars['Int'];
}


export interface MutationDropCollectionCoverArtArgs {
  id: Scalars['Int'];
}


export interface MutationGenerateLyricsByThemeWithChatGptArgs {
  theme: Scalars['String'];
}


export interface MutationMarkActionsAsSeenArgs {
  ids: Array<Scalars['Int']>;
}


export interface MutationRateOpenAiPromptRecordCompletionArgs {
  recordId: Scalars['Int'];
  rate: Maybe<Scalars['Int']>;
}


export interface MutationRemoveMasterFromCollectionArgs {
  id: Scalars['Int'];
  data: CollectionMasterInput;
}


export interface MutationRemoveTrackFromDistributionRecordArgs {
  recordId: Scalars['Int'];
  trackId: Scalars['Int'];
}


export interface MutationRemoveUserFromRolesArgs {
  user_id: Scalars['Int'];
  role_ids: Array<Scalars['Int']>;
}


export interface MutationResendInviteUserArgs {
  id: Scalars['Int'];
}


export interface MutationRestoreAssetArgs {
  id: Scalars['Int'];
}


export interface MutationRestoreCollectionArgs {
  id: Scalars['Int'];
}


export interface MutationRestoreLyricsArgs {
  id: Scalars['Int'];
}


export interface MutationRestoreMasterArgs {
  id: Scalars['Int'];
}


export interface MutationRestoreProjectArgs {
  id: Scalars['Int'];
}


export interface MutationRewriteLyricsWithChatGptArgs {
  lyrics: Scalars['String'];
}


export interface MutationSetExcludedResourceArgs {
  resource_id: Scalars['Int'];
}


export interface MutationSetMasterWatchArgs {
  masterId: Scalars['Int'];
  isWatch: Scalars['Boolean'];
  subfolders: Maybe<Array<Maybe<ResourcesInput>>>;
}


export interface MutationSetMultiExcludedResourcesArgs {
  resource_id: Array<Maybe<Scalars['Int']>>;
}


export interface MutationSetUserStatusArgs {
  user_id: Scalars['Int'];
  status: UserStatus;
}


export interface MutationSynchronizeAndGetDistributionRecordArgs {
  type: DistributionType;
  relatedId: Scalars['Int'];
}


export interface MutationSynchronizeAndGetDistributionRecordByIdArgs {
  id: Scalars['Int'];
}


export interface MutationTakeDownDistributionRecordArgs {
  id: Scalars['Int'];
}


export interface MutationTakedownOutletsEvearaArgs {
  releaseId: Scalars['Float'];
  stores: Array<Scalars['Int']>;
}


export interface MutationUnassignAdminArgs {
  id: Scalars['Int'];
}


export interface MutationUpdateAlbumEvearaArgs {
  data: UpdateAlbumEvearaRecordInput;
}


export interface MutationUpdateAssetGroupArgs {
  id: Scalars['Int'];
  data: AssetGroupInput;
}


export interface MutationUpdateCollectionArgs {
  id: Scalars['Int'];
  data: CollectionInput;
}


export interface MutationUpdateCollectionMasterPositionArgs {
  id: Scalars['Int'];
  data: CollectionMasterPositionInput;
}


export interface MutationUpdateContractArgs {
  id: Scalars['Int'];
  data: ContractInput;
}


export interface MutationUpdateContractPartyArgs {
  id: Scalars['Int'];
  data: ContractPartyInput;
}


export interface MutationUpdateCurrentUserArgs {
  data: UserInput;
}


export interface MutationUpdateCurrentUserMasterItemsArgs {
  data: Array<ResourcesInput>;
}


export interface MutationUpdateCurrentUserMenuArgs {
  data: Array<ResourcesInput>;
}


export interface MutationUpdateDistributionRecordArgs {
  id: Scalars['Int'];
  data: UpdateDistributionRecordInput;
}


export interface MutationUpdateDistributionRecordOutletsDetailsArgs {
  recordId: Scalars['Int'];
  distributeToFutureOutlets: Scalars['Boolean'];
  data: Array<UpdateDistributionRecordOutletsDetailsInput>;
}


export interface MutationUpdateDistributionRecordTracksArgs {
  recordId: Scalars['Int'];
  data: Array<UpdateDistributionTrackInput>;
}


export interface MutationUpdateGuestUserArgs {
  id: Scalars['Int'];
  data: UserInput;
}


export interface MutationUpdateLyricsArgs {
  id: Scalars['Int'];
  data: AmpLyricsInput;
}


export interface MutationUpdateMasterArgs {
  id: Scalars['Int'];
  data: AmpMasterInput;
}


export interface MutationUpdateMasterMetaArgs {
  id: Scalars['Int'];
  data: AmpMasterMetaInput;
}


export interface MutationUpdateOutletsEvearaArgs {
  releaseId: Scalars['Float'];
  stores: Maybe<Array<Scalars['Int']>>;
}


export interface MutationUpdateRoleArgs {
  id: Scalars['Int'];
  input: UmRoleInput;
}


export interface MutationUpdateSavedSearchArgs {
  id: Scalars['Int'];
  data: UpdateSavedSearchInput;
}


export interface MutationUpdateTrackEvearaArgs {
  data: Maybe<EvearaUpdateTrackInput>;
}


export interface MutationUpdateTullyPersonMetadataArgs {
  id: Scalars['Int'];
  data: TullyPersonMetadataInput;
}


export interface MutationUpdateUserRolesArgs {
  input: UserRolesInput;
}


export interface MutationValidateAlbumEvearaArgs {
  releaseId: Scalars['Float'];
}

export interface EvearaTrackInput {
  /** Uploadable asset identifier */
  asset_id: Scalars['Int'];
  name: Scalars['String'];
  isrc?: Maybe<Scalars['String']>;
  iswc?: Maybe<Scalars['String']>;
  genre?: Maybe<Array<Maybe<Scalars['Int']>>>;
  language?: Maybe<Scalars['String']>;
  explicit?: Maybe<Scalars['Int']>;
  availability?: Maybe<Array<Maybe<Scalars['Int']>>>;
  artists?: Maybe<Array<Maybe<Scalars['Int']>>>;
  featuredArtists?: Maybe<Array<Maybe<Scalars['Int']>>>;
  albumOnly?: Maybe<Scalars['Boolean']>;
  lyrics?: Maybe<Scalars['String']>;
  dolbyAtmosIsrc?: Maybe<Scalars['String']>;
  spatialIsrc?: Maybe<Scalars['String']>;
  sony360?: Maybe<Array<Maybe<Sony360FileInput>>>;
}

export interface Sony360FileInput {
  asset_id: Scalars['Int'];
  filename: Scalars['String'];
  spatial_type: Scalars['String'];
  sony360_file_type?: Maybe<Scalars['String']>;
}

export interface EvearaAddTrackResponse {
  __typename?: 'EvearaAddTrackResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  trackId?: Maybe<Scalars['Int']>;
  sony360raFiles?: Maybe<EvearaBaseResponse>;
}

export interface EvearaBaseResponse {
  __typename?: 'EvearaBaseResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
}

export interface EvearaUpdateTrackInput {
  trackLibraryId: Scalars['Int'];
  name: Scalars['String'];
  isrc?: Maybe<Scalars['String']>;
  iswc?: Maybe<Scalars['String']>;
  genre?: Maybe<Array<Maybe<Scalars['Int']>>>;
  language?: Maybe<Scalars['String']>;
  explicit?: Maybe<Scalars['Int']>;
  availability?: Maybe<Array<Maybe<Scalars['Int']>>>;
  artists?: Maybe<Array<Maybe<Scalars['Int']>>>;
  featuredArtists?: Maybe<Array<Maybe<Scalars['Int']>>>;
  albumOnly?: Maybe<Scalars['Boolean']>;
  lyrics?: Maybe<Scalars['String']>;
  dolbyAtmosIsrc?: Maybe<Scalars['String']>;
  spatialIsrc?: Maybe<Scalars['String']>;
}

export interface SubscriptionEvearaInput {
  subscriptionId: Scalars['Int'];
  partnerReferenceId?: Maybe<Scalars['String']>;
}

export interface EvearaAddSubscriptionResponse {
  __typename?: 'EvearaAddSubscriptionResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Array<Maybe<SubscriptionDataEveara>>>;
}

export interface SubscriptionDataEveara {
  __typename?: 'SubscriptionDataEveara';
  subscriptionId?: Maybe<Scalars['Int']>;
  mySubscriptionId?: Maybe<Scalars['Int']>;
}

export interface EvearaAddLabelResponse {
  __typename?: 'EvearaAddLabelResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  labelId?: Maybe<Scalars['String']>;
  labelName?: Maybe<Scalars['String']>;
}

export interface EvearaAddParticipantResponse {
  __typename?: 'EvearaAddParticipantResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  participantId?: Maybe<Scalars['String']>;
  participantName?: Maybe<Scalars['String']>;
}

export interface EvearaAddArtistResponse {
  __typename?: 'EvearaAddArtistResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  artistId?: Maybe<Scalars['String']>;
  artistName?: Maybe<Scalars['String']>;
}

export interface AlbumEvearaInput {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  artists?: Maybe<Array<Scalars['Int']>>;
  subscriptionId: Scalars['Int'];
  eanupc?: Maybe<Scalars['String']>;
  labelId?: Maybe<Scalars['String']>;
  originalReleaseDate?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  productCodeType?: Maybe<Scalars['String']>;
  codeAutoGenerate?: Maybe<Scalars['Boolean']>;
  spatialEanUpc?: Maybe<Scalars['Int']>;
  spatialProductCodeType?: Maybe<Scalars['String']>;
  spatialCodeAutoGenerate?: Maybe<Scalars['Boolean']>;
  productFormat?: Maybe<Scalars['String']>;
  coverImage?: Maybe<AlbumCoverImageInput>;
  tracks?: Maybe<Array<Maybe<TrackAlbumInput>>>;
}

export interface AlbumCoverImageInput {
  url?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
}

export interface TrackAlbumInput {
  track: EvearaTrackInput;
  artists?: Maybe<Array<Maybe<Scalars['Int']>>>;
  featuredArtists?: Maybe<Array<Maybe<Scalars['Int']>>>;
  preview?: Maybe<AlbumTrackPreviewInput>;
  participant: Array<Maybe<AlbumTrackParticipantInput>>;
}

export interface AlbumTrackPreviewInput {
  startAt?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
}

export interface AlbumTrackParticipantInput {
  id: Scalars['String'];
  roleId: Array<Scalars['Int']>;
  payoutSharePercentage: Scalars['Int'];
}

export interface EvearaAddAlbumResponse {
  __typename?: 'EvearaAddAlbumResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  releaseId?: Maybe<Scalars['String']>;
  tracks?: Maybe<Array<Maybe<EvearaAddTrackResponse>>>;
}

export interface UpdateAlbumEvearaRecordInput {
  releaseId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  labelId?: Maybe<Scalars['Int']>;
  artists?: Maybe<ArtistsRecord>;
  featuredArtists?: Maybe<Array<Maybe<Scalars['Int']>>>;
  eanupc?: Maybe<Scalars['String']>;
  originalReleaseDate?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  productCodeType?: Maybe<Scalars['String']>;
  codeAutoGenerate?: Maybe<Scalars['Boolean']>;
  spatialEanUpc?: Maybe<Scalars['Int']>;
  spatialProductCodeType?: Maybe<Scalars['String']>;
  spatialCodeAutoGenerate?: Maybe<Scalars['Boolean']>;
  productFormat?: Maybe<Scalars['String']>;
  coverImage?: Maybe<AlbumCoverImageInput>;
  tracks?: Maybe<Array<AlbumTrackUpdateRecordInput>>;
}

export interface ArtistsRecord {
  artistName?: Maybe<Scalars['String']>;
  artistCountry?: Maybe<Scalars['String']>;
}

export interface AlbumTrackUpdateRecordInput {
  trackId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  isrc?: Maybe<Scalars['String']>;
  iswc?: Maybe<Scalars['String']>;
  lyrics?: Maybe<Scalars['String']>;
  genres?: Maybe<Array<Maybe<Scalars['String']>>>;
  artist?: Maybe<Array<Maybe<Scalars['String']>>>;
  availabilities?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicit?: Maybe<Scalars['Int']>;
  featuredArtists?: Maybe<Array<Maybe<Scalars['String']>>>;
  preview?: Maybe<AlbumTrackPreviewInput>;
  participant: Array<Maybe<UpdateAlbumTrackParticipantInput>>;
}

export interface UpdateAlbumTrackParticipantInput {
  id: Scalars['String'];
  roleId: Array<Maybe<Scalars['Int']>>;
  payoutSharePercentage: Scalars['String'];
}

export interface OutletsDetailsEveara {
  storeId: Scalars['Int'];
  preSalesDate: Scalars['String'];
  releaseStartDate: Scalars['String'];
  releaseEndDate?: Maybe<Scalars['String']>;
}

export interface EvearaValidateAlbumResponse {
  __typename?: 'EvearaValidateAlbumResponse';
  albumStatus: Scalars['String'];
  errorFields: Array<Maybe<EvearaErrorFieldsAlbum>>;
}

export interface EvearaErrorFieldsAlbum {
  __typename?: 'EvearaErrorFieldsAlbum';
  fields?: Maybe<Scalars['String']>;
  message: Scalars['String'];
}

export interface AmpMasterInput {
  catalog_id?: Maybe<Scalars['Int']>;
  fire_node_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  is_locked?: Maybe<Scalars['Boolean']>;
  tully_metadata?: Maybe<TullyMetadataInput>;
  subfolder_config?: Maybe<Array<Maybe<ResourcesInput>>>;
}

export interface TullyMetadataInput {
  imported?: Maybe<Scalars['TimeStamp']>;
  /** (Metadata) */
  last_edited?: Maybe<Scalars['TimeStamp']>;
  /** e.g. Burzum */
  artist?: Maybe<Scalars['String']>;
  /** e.g. Varg Vikernes */
  artist_name?: Maybe<Scalars['String']>;
  spotify_artist_id?: Maybe<Scalars['String']>;
  /** e.g.	PRS: 500879361 */
  artist_affiliation?: Maybe<Scalars['String']>;
  composers?: Maybe<Array<Scalars['Int']>>;
  featured_artists?: Maybe<Array<Scalars['Int']>>;
  performers?: Maybe<Array<Scalars['Int']>>;
  producers?: Maybe<Array<Scalars['Int']>>;
  songwriters?: Maybe<Array<Scalars['Int']>>;
  collaborators?: Maybe<Array<Scalars['Int']>>;
  remixer?: Maybe<Scalars['String']>;
  track_title?: Maybe<Scalars['String']>;
  album_name?: Maybe<Scalars['String']>;
  ep_name?: Maybe<Scalars['String']>;
  artwork?: Maybe<ArtworkInput>;
  publisher_name?: Maybe<Scalars['String']>;
  /** e.g. PRS: 500879361 */
  publisher_affiliation?: Maybe<Scalars['String']>;
  /** 0..100% */
  publisher_ownership?: Maybe<Scalars['Int']>;
  upc_ean_code?: Maybe<Scalars['String']>;
  /** e.g. GBBPP2060181 */
  isrc?: Maybe<Scalars['String']>;
  /** From Tully genres list */
  genre?: Maybe<Scalars['String']>;
  genre_id?: Maybe<Scalars['Int']>;
  /** To avoid unwanted edge cases, tempo is alphanumeric */
  tempo?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  language_id?: Maybe<Scalars['Int']>;
  country_id?: Maybe<Scalars['Int']>;
  /** mostly Asset property */
  mix_variant?: Maybe<MixVariant>;
  release_date?: Maybe<Scalars['TimeStamp']>;
  sale_start_date?: Maybe<Scalars['TimeStamp']>;
  has_third_party_publisher?: Maybe<Scalars['Boolean']>;
  has_ownership?: Maybe<Scalars['Boolean']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  ownership_sample_recording?: Maybe<OwnershipFileInput>;
  ownership_license?: Maybe<OwnershipFileInput>;
}

export interface ArtworkInput {
  filename?: Maybe<Scalars['String']>;
}

export interface OwnershipFileInput {
  filename?: Maybe<Scalars['String']>;
}

export interface ResourcesInput {
  slug: Scalars['String'];
  access: Scalars['Boolean'];
}

export interface AmpMasterMetaInput {
  customizeMaster?: Maybe<Array<CustomizeMasterInput>>;
}

export interface CustomizeMasterInput {
  realityAudio: Scalars['Boolean'];
  stemFiles: Scalars['Boolean'];
  contracts: Scalars['Boolean'];
  metadata: Scalars['Boolean'];
  media: Scalars['Boolean'];
  userId: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
}

export interface AmpLyricsInput {
  fire_node_id?: Maybe<Scalars['String']>;
  catalog_id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
}

export interface ProjectFromBeatInput {
  catalog_id?: Maybe<Scalars['Int']>;
}

export interface CollectionInput {
  catalog_id: Scalars['Int'];
  artist_name?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
  country_id?: Maybe<Scalars['Int']>;
  collection_type: Scalars['Int'];
  cover_art_id?: Maybe<Scalars['Int']>;
  /** update only */
  master_positions?: Maybe<Array<Scalars['Int']>>;
}

export interface CollectionMasterInput {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export interface CollectionCoverArtInput {
  file_name: Scalars['String'];
  attach?: Maybe<Scalars['Boolean']>;
}

export interface CollectionMasterPositionInput {
  master_id: Scalars['Int'];
  position: Scalars['Int'];
}

export interface AssetGroupInput {
  title?: Maybe<Scalars['String']>;
  asset_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export interface TullyPersonMetadataInput {
  name: Scalars['String'];
  affiliation?: Maybe<Scalars['String']>;
  apple_id?: Maybe<Scalars['String']>;
  spotify_id?: Maybe<Scalars['String']>;
  ownership?: Maybe<Scalars['Int']>;
  role: CollaboratorRole;
}

export interface CreateTullyMetadataArtistInput {
  name?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
}

export interface CreateTullyMetadataParticipantInput {
  name?: Maybe<Scalars['String']>;
}

export interface Error {
  __typename?: 'Error';
  message?: Maybe<Scalars['String']>;
}

export interface ReleaseDetailsInput {
  artist_name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['Int']>;
  product_type?: Maybe<Scalars['Int']>;
  type_name?: Maybe<Scalars['String']>;
  release_date?: Maybe<Scalars['TimeStamp']>;
  code_type?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  cover_image?: Maybe<AlbumCoverImageInput>;
}

export interface TrackDetailsInput {
  asset_id: Scalars['Int'];
  song_title?: Maybe<Scalars['String']>;
  preview_start?: Maybe<Scalars['Int']>;
  preview_end?: Maybe<Scalars['Int']>;
  genres?: Maybe<Array<Maybe<GenresInput>>>;
  artist?: Maybe<Scalars['String']>;
  featured_artist?: Maybe<Scalars['Int']>;
  isrc?: Maybe<Scalars['Int']>;
  iswc?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['Int']>;
  parental_advisory?: Maybe<Scalars['Int']>;
  for_type?: Maybe<Array<Maybe<TypesInput>>>;
  song_lyrics?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Maybe<ParticipantAndRolesInput>>>;
}

export interface GenresInput {
  genre?: Maybe<Scalars['Int']>;
}

export interface TypesInput {
  type?: Maybe<Scalars['Int']>;
}

export interface ParticipantAndRolesInput {
  participant?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Maybe<RolesInput>>>;
  payout?: Maybe<Scalars['Int']>;
}

export interface RolesInput {
  role?: Maybe<Scalars['Int']>;
}

export interface OutletsDetailsInput {
  outlet?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  start_release?: Maybe<Scalars['TimeStamp']>;
}

export interface CreateOrUpdateReleaseAllDetails {
  __typename?: 'CreateOrUpdateReleaseAllDetails';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  master_id?: Maybe<Scalars['Int']>;
  artist_name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['Int']>;
  product_type?: Maybe<Scalars['Int']>;
  type_name?: Maybe<Scalars['String']>;
  release_date?: Maybe<Scalars['TimeStamp']>;
  code_type?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  cover_image?: Maybe<Array<Maybe<AlbumCoverImage>>>;
  tracks?: Maybe<Array<Maybe<TrackDetails>>>;
  outlets?: Maybe<Array<Maybe<OutletsDetails>>>;
}

export interface UpdateDistributionRecordInput {
  originalReleaseDate?: Maybe<Scalars['TimeStamp']>;
  codeType: DistributionCodeType;
  code?: Maybe<Scalars['String']>;
  artistCountryId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  coverFileSignedDownloadUrl?: Maybe<Scalars['String']>;
}

export interface UpdateDistributionTrackInput {
  id: Scalars['Int'];
  preview?: Maybe<DistributionTrackPreviewInput>;
  isForDownloading?: Maybe<Scalars['Boolean']>;
  isForStreaming?: Maybe<Scalars['Boolean']>;
  iswc?: Maybe<Scalars['String']>;
  lyrics?: Maybe<Scalars['String']>;
  artistCountryId?: Maybe<Scalars['Int']>;
  distributeClean?: Maybe<Scalars['Boolean']>;
  distributeExplicit?: Maybe<Scalars['Boolean']>;
  isrc?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  genreId?: Maybe<Scalars['Int']>;
  languageId?: Maybe<Scalars['Int']>;
  artistsIds?: Maybe<Array<Scalars['Int']>>;
  featuredArtistsIds?: Maybe<Array<Scalars['Int']>>;
  participantsData?: Maybe<Array<TullyMetadataParticipantInTullyMetadataInput>>;
}

export interface DistributionTrackPreviewInput {
  startAt?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
}

export interface TullyMetadataParticipantInTullyMetadataInput {
  participantId: Scalars['Int'];
  role: Scalars['String'];
  payout: Scalars['Int'];
}

export interface UpdateDistributionRecordOutletsDetailsInput {
  distributorOutletId: Scalars['String'];
  preSalesDate?: Maybe<Scalars['TimeStamp']>;
  startReleaseDate?: Maybe<Scalars['TimeStamp']>;
  endReleaseDate?: Maybe<Scalars['TimeStamp']>;
}

export interface UpdateDistributionRecordOutletsDetailsResponse {
  __typename?: 'UpdateDistributionRecordOutletsDetailsResponse';
  outlets: Array<DistributionRecordOutletDetails>;
  distributeToFutureOutlets: Scalars['Boolean'];
}

export interface ContractInput {
  title?: Maybe<Scalars['String']>;
  type: ContractType;
  date?: Maybe<Scalars['TimeStamp']>;
  master_id?: Maybe<Scalars['Int']>;
  file_id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<ContractMetadataInput>;
  parties?: Maybe<Array<ContractPartyInput>>;
}

export interface ContractMetadataInput {
  aka?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  localFileName?: Maybe<Scalars['String']>;
}

export interface ContractPartyInput {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  role: ContractPartyRole;
  role_label?: Maybe<Scalars['String']>;
  can_see_others?: Maybe<Scalars['Boolean']>;
  contract_id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<ContractPartyMetaInput>;
}

export interface ContractPartyMetaInput {
  /** Uploaded Attributes */
  advance?: Maybe<Scalars['Float']>;
  royalty?: Maybe<Scalars['Float']>;
  royalty_label?: Maybe<Scalars['String']>;
  recoup?: Maybe<Scalars['Float']>;
  recoup_label?: Maybe<Scalars['String']>;
  publishing?: Maybe<Scalars['Float']>;
  /** Split Sheet Attributes */
  affiliation?: Maybe<Scalars['String']>;
  ownership?: Maybe<Scalars['Float']>;
}

export interface UserInput {
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<ProfileInput>;
  password?: Maybe<Scalars['String']>;
}

export interface ProfileInput {
  avatar?: Maybe<Scalars['String']>;
  phone?: Maybe<Array<Scalars['String']>>;
  artist_profile?: Maybe<ArtistProfileInput>;
}

export interface ArtistProfileInput {
  artist_name?: Maybe<Scalars['String']>;
  artist_option?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  genreId?: Maybe<Scalars['Int']>;
  audio_settings?: Maybe<Scalars['String']>;
  artist_bio?: Maybe<Scalars['String']>;
  soundcloud_id?: Maybe<Scalars['String']>;
  spotify_id?: Maybe<Scalars['String']>;
  apple_artist_id?: Maybe<Scalars['String']>;
  youtube_channel_id?: Maybe<Scalars['String']>;
  affiliation?: Maybe<Scalars['String']>;
}

export interface UserInviteInput {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  role_id?: Maybe<Scalars['Int']>;
  invite_type: InviteType;
  meta?: Maybe<Scalars['String']>;
}

export enum InviteType {
  USER = 'USER',
  ARTIST = 'ARTIST'
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  IN_ACTIVE = 'IN_ACTIVE'
}

export interface CreateSavedSearchInput {
  name: Scalars['String'];
  filter: SearchFilterInput;
  sort?: Maybe<Array<Maybe<SearchSortInput>>>;
  public?: Maybe<Scalars['Boolean']>;
  shared_with?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export interface UpdateSavedSearchInput {
  name?: Maybe<Scalars['String']>;
  filter?: Maybe<SearchFilterInput>;
  sort?: Maybe<Array<Maybe<SearchSortInput>>>;
  public?: Maybe<Scalars['Boolean']>;
  shared_with?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export interface OpenAiPromptRecord {
  __typename?: 'OpenAIPromptRecord';
  id: Scalars['Int'];
  user?: Maybe<User>;
  createdAt: Scalars['TimeStamp'];
  updatedAt: Scalars['TimeStamp'];
  prompt: Scalars['String'];
  completion: Scalars['String'];
  type: OpenAiPromptType;
  rate?: Maybe<Scalars['Int']>;
}

export enum OpenAiPromptType {
  LYRICS_GENERATION_BY_THEME = 'LYRICS_GENERATION_BY_THEME',
  LYRICS_REWRITING = 'LYRICS_REWRITING'
}

export interface Plan extends HasSimpleHistory {
  __typename?: 'Plan';
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  name: Scalars['String'];
  display_name: Scalars['String'];
  /** computed */
  max_storage: Scalars['Float'];
  /** computed */
  storage_limit: Scalars['String'];
  is_visible?: Maybe<Scalars['Boolean']>;
}

export interface WhereArgBoolean {
  exact?: Maybe<Scalars['Boolean']>;
}

export interface UpdateAlbumEvearaInput {
  releaseId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  subscriptionId: Scalars['Int'];
  labelId?: Maybe<Scalars['Int']>;
  artists?: Maybe<Array<Maybe<Scalars['Int']>>>;
  featuredArtists?: Maybe<Array<Maybe<Scalars['Int']>>>;
  eanupc?: Maybe<Scalars['String']>;
  originalReleaseDate?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  productCodeType?: Maybe<Scalars['String']>;
  codeAutoGenerate?: Maybe<Scalars['Boolean']>;
  spatialEanUpc?: Maybe<Scalars['Int']>;
  spatialProductCodeType?: Maybe<Scalars['String']>;
  spatialCodeAutoGenerate?: Maybe<Scalars['Boolean']>;
  productFormat?: Maybe<Scalars['String']>;
  coverImage?: Maybe<AlbumCoverImageInput>;
  tracks?: Maybe<Array<AlbumTrackUpdateInput>>;
}

export interface AlbumTrackUpdateInput {
  trackId: Scalars['String'];
  artist?: Maybe<Array<Maybe<Scalars['Int']>>>;
  featuredArtists?: Maybe<Array<Maybe<Scalars['Int']>>>;
  preview?: Maybe<AlbumTrackPreviewInput>;
  participant: Array<Maybe<UpdateAlbumTrackParticipantInput>>;
}

export interface AlbumDataEveara {
  __typename?: 'AlbumDataEveara';
  name?: Maybe<Scalars['String']>;
  productType: Scalars['String'];
  productCodeType?: Maybe<Scalars['String']>;
  spatialEanUpc?: Maybe<Scalars['String']>;
  spatialProductCodeType?: Maybe<Scalars['String']>;
  artists?: Maybe<Array<Maybe<ArtistsAlbumDataEveara>>>;
  subscriptionId?: Maybe<Scalars['Int']>;
  eanupc?: Maybe<Scalars['String']>;
  labelId?: Maybe<Scalars['String']>;
  originalReleaseDate?: Maybe<Scalars['String']>;
  coverImage?: Maybe<AlbumCoverImage>;
  tracks?: Maybe<Array<Maybe<TrackDataEveara>>>;
}

export interface ArtistsAlbumDataEveara {
  __typename?: 'ArtistsAlbumDataEveara';
  name: Scalars['String'];
  country_code?: Maybe<Scalars['String']>;
}

export interface CollectionMasterOrderInput {
  master_id: Scalars['Int'];
  position: Scalars['Int'];
}

export enum CatalogEntityType {
  PROJECT = 'PROJECT',
  MASTER = 'MASTER'
}

export interface AmpFeatues {
  __typename?: 'AMPFeatues';
  userManagement: Scalars['Boolean'];
}

export interface UserEmailInput {
  email: Scalars['String'];
}

export interface RoleDeleteInput {
  id: Scalars['Int'];
}

export interface InviteUserDeleteInput {
  id: Scalars['Int'];
}

export interface UmUserMeta {
  __typename?: 'UMUserMeta';
  photo_url: Scalars['String'];
}

export interface UserEmailOnly {
  __typename?: 'UserEmailOnly';
  email: Scalars['String'];
}

export interface UmUserCatalog {
  __typename?: 'UmUserCatalog';
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface UmUser {
  __typename?: 'UMUser';
  id: Scalars['Int'];
  name: Scalars['String'];
  email: Scalars['String'];
  catalog: Array<UmUserCatalog>;
  role: Array<UserRole>;
  is_admin: Scalars['Boolean'];
  is_guest: Scalars['Boolean'];
  is_artist: Scalars['Boolean'];
  meta: UmUserMeta;
  status: Scalars['String'];
}

export interface UmRoleUser {
  __typename?: 'UMRoleUser';
  id: Scalars['Int'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  meta: UmUserMeta;
}

export enum Privileges {
  VIEW = 'VIEW',
  DOWNLOAD = 'DOWNLOAD',
  UPLOAD = 'UPLOAD',
  EDIT = 'EDIT',
  DELETE = 'DELETE'
}

export interface PrivilegeRec {
  __typename?: 'PrivilegeRec';
  slug: Privileges;
  title: Scalars['String'];
  allowed: Scalars['Boolean'];
}

export interface PrivilegeRecInput {
  slug: Privileges;
  title: Scalars['String'];
  allowed: Scalars['Boolean'];
}

export interface ContentRec {
  __typename?: 'ContentRec';
  slug: Scalars['String'];
  title: Scalars['String'];
  allowed: Scalars['Boolean'];
  type_id: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
}

export interface ContentRecInput {
  slug: Scalars['String'];
  title: Scalars['String'];
  allowed: Scalars['Boolean'];
  type_id: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
}

export interface CatalogPermissions {
  __typename?: 'CatalogPermissions';
  assigned: Scalars['Boolean'];
  privileges: Array<PrivilegeRec>;
  content: Array<ContentRec>;
}

export interface UmRoleCatalog {
  __typename?: 'UMRoleCatalog';
  name: Scalars['String'];
  catalog_id: Scalars['Int'];
  permissions: CatalogPermissions;
}

export interface UmRolePrivilegesInput {
  VIEW?: Maybe<Scalars['Boolean']>;
  DOWNLOAD?: Maybe<Scalars['Boolean']>;
  UPLOAD?: Maybe<Scalars['Boolean']>;
  EDIT?: Maybe<Scalars['Boolean']>;
  DELETE?: Maybe<Scalars['Boolean']>;
}

export interface UmRoleContentInput {
  MASTERS: Scalars['Boolean'];
  PROJECTS: Scalars['Boolean'];
  MASTER_FILE: Scalars['Boolean'];
  MASTER_CLEAN: Scalars['Boolean'];
  MASTER_EXPLICIT: Scalars['Boolean'];
  MASTER_STEM: Scalars['Boolean'];
  MASTER_CONTRACTS: Scalars['Boolean'];
  MASTER_DOCUMENTS: Scalars['Boolean'];
  MASTER_METADATA: Scalars['Boolean'];
  MASTER_MEDIA: Scalars['Boolean'];
  PROJECT_LYRIC: Scalars['Boolean'];
  PROJECT_BEAT: Scalars['Boolean'];
  PROJECT_METADATA: Scalars['Boolean'];
  PROJECT_MIX: Scalars['Boolean'];
  PROJECT_IMPORT: Scalars['Boolean'];
  PROJECT_RECORDING: Scalars['Boolean'];
  TULLY_BEATS: Scalars['Boolean'];
  IMPORT_BEATS: Scalars['Boolean'];
}

export interface UmRoleCatalogInput {
  catalog_id: Scalars['Int'];
  privileges: UmRolePrivilegesInput;
  content: UmRoleContentInput;
}

export interface UmRole extends HasSimpleHistory {
  __typename?: 'UMRole';
  id: Scalars['Int'];
  account: Scalars['Int'];
  created_at: Scalars['TimeStamp'];
  updated_at: Scalars['TimeStamp'];
  name: Scalars['String'];
  catalog_details: Array<UmRoleCatalog>;
  assigned_users: Array<UmRoleUser>;
}

export interface UmRoleInput {
  name: Scalars['String'];
  catalogs: Array<UmRoleCatalogInput>;
  assigned_users: Array<Scalars['Int']>;
}

export interface UmRolePayload {
  __typename?: 'UMRolePayload';
  count: Scalars['Int'];
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  results: Array<UmRole>;
}

export interface UserRolesUpdateResponse {
  __typename?: 'UserRolesUpdateResponse';
  id: Scalars['Int'];
  role: Array<UserRole>;
}

export interface UserRolesInput {
  id: Scalars['Int'];
  role: Array<Scalars['Int']>;
}

export interface NoResponse {
  __typename?: 'NoResponse';
  NoResponse?: Maybe<Scalars['Int']>;
}

export type GetFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeaturesQuery = (
  { __typename?: 'Query' }
  & { features: (
    { __typename?: 'AMPFeatues' }
    & Pick<AmpFeatues, 'userManagement'>
  ) }
);

export type UmRoleFieldsFragment = (
  { __typename?: 'UMRole' }
  & Pick<UmRole, 'id' | 'name' | 'account' | 'created_at' | 'updated_at'>
  & { catalog_details: Array<(
    { __typename?: 'UMRoleCatalog' }
    & Pick<UmRoleCatalog, 'catalog_id' | 'name'>
    & { permissions: (
      { __typename?: 'CatalogPermissions' }
      & Pick<CatalogPermissions, 'assigned'>
      & { privileges: Array<(
        { __typename?: 'PrivilegeRec' }
        & Pick<PrivilegeRec, 'slug' | 'title' | 'allowed'>
      )>, content: Array<(
        { __typename?: 'ContentRec' }
        & Pick<ContentRec, 'slug' | 'title' | 'allowed' | 'type_id' | 'category'>
      )> }
    ) }
  )>, assigned_users: Array<(
    { __typename?: 'UMRoleUser' }
    & Pick<UmRoleUser, 'id' | 'email' | 'first_name' | 'last_name'>
    & { meta: (
      { __typename?: 'UMUserMeta' }
      & UserMetaFieldsFragment
    ) }
  )> }
);

export type CreateRoleMutationVariables = Exact<{
  input: UmRoleInput;
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole: (
    { __typename?: 'UMRole' }
    & Pick<UmRole, 'id' | 'name'>
  ) }
);

export type DeleteRoleMutationVariables = Exact<{
  input: RoleDeleteInput;
}>;


export type DeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & { deleteRole: (
    { __typename?: 'NoResponse' }
    & Pick<NoResponse, 'NoResponse'>
  ) }
);

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UmRoleInput;
}>;


export type UpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateRole: (
    { __typename?: 'UMRole' }
    & UmRoleFieldsFragment
  ) }
);

export type UpdateUserRolesMutationVariables = Exact<{
  input: UserRolesInput;
}>;


export type UpdateUserRolesMutation = (
  { __typename?: 'Mutation' }
  & { updateUserRoles: (
    { __typename?: 'UserRolesUpdateResponse' }
    & Pick<UserRolesUpdateResponse, 'id'>
    & { role: Array<(
      { __typename?: 'UserRole' }
      & Pick<UserRole, 'id' | 'name'>
    )> }
  ) }
);

export type GetGuestUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGuestUsersQuery = (
  { __typename?: 'Query' }
  & { getGuestUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'email'>
  )> }
);

export type GetInviteFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInviteFlagsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'canInviteUser' | 'canInviteArtist'>
);

export type GetUmRoleByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUmRoleByIdQuery = (
  { __typename?: 'Query' }
  & { umRole: (
    { __typename?: 'UMRole' }
    & UmRoleFieldsFragment
  ) }
);

export type GetUmRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUmRolesQuery = (
  { __typename?: 'Query' }
  & { umRoles: (
    { __typename?: 'UMRolePayload' }
    & { results: Array<(
      { __typename?: 'UMRole' }
      & UmRoleFieldsFragment
    )> }
  ) }
);

export type UmUserFieldsFragment = (
  { __typename?: 'UMUser' }
  & Pick<UmUser, 'id' | 'name' | 'email' | 'is_admin' | 'is_guest' | 'is_artist' | 'status'>
  & { catalog: Array<(
    { __typename?: 'UmUserCatalog' }
    & Pick<UmUserCatalog, 'id' | 'name'>
  )>, role: Array<(
    { __typename?: 'UserRole' }
    & Pick<UserRole, 'id' | 'name'>
  )>, meta: (
    { __typename?: 'UMUserMeta' }
    & UserMetaFieldsFragment
  ) }
);

export type GetUmUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUmUsersQuery = (
  { __typename?: 'Query' }
  & { umUsers: Array<(
    { __typename?: 'UMUser' }
    & UmUserFieldsFragment
  )> }
);

export type AccountFieldsFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'name'>
  & { updatedAt: Account['updated_at'], createdAt: Account['created_at'], addonStorage: Account['addon_storage'], maxStorage: Account['max_storage'], planStorage: Account['plan_storage'], storageLimit: Account['storage_limit'], usedFileCount: Account['used_file_count'], usedStorage: Account['used_storage'], freeAmpUserSeats: Account['free_amp_user_seats'], freeArtistSeats: Account['free_artist_seats'] }
);

export type AccountSubscriptionFragment = (
  { __typename?: 'AccountSubscription' }
  & Pick<AccountSubscription, 'amount' | 'exclusive' | 'name' | 'renewal' | 'status' | 'type'>
  & { displayName: AccountSubscription['display_name'], isVisible: AccountSubscription['is_visible'], upgradeIndex: AccountSubscription['upgrade_index'] }
  & { meta?: Maybe<(
    { __typename?: 'SubscriptionMeta' }
    & SubscriptionMetaFragment
  )> }
);

export type ActionAuthorFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & { firstName: User['first_name'], lastName: User['last_name'] }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'avatar'>
  )> }
);

export type ActionFieldsFragment = (
  { __typename?: 'Action' }
  & Pick<Action, 'title'>
  & { authorEmail: Action['subject_email'], contentTypeId: Action['search_content_type_id'], catalogId: Action['catalog_id'], id: Action['id'], isSeen: Action['is_seen'], objectId: Action['object_id'], parentId: Action['parent_id'], catalogName: Action['catalog_name'], isExist: Action['exists'], parentName: Action['parent_name'], actionType: Action['action_type'], createdAt: Action['created_at'], parentContentType: Action['parent_search_content_type_id'], collectionCover: Action['image_file_uri'], collectionType: Action['collection_type'] }
  & { author?: Maybe<(
    { __typename?: 'User' }
    & ActionAuthorFieldsFragment
  )>, object: { __typename?: 'AMPMaster' } | { __typename?: 'AMPProject' } | { __typename?: 'AMPAsset' } | { __typename?: 'AMPLyrics' } | { __typename?: 'AMPCollection' } | { __typename?: 'Contract' } | (
    { __typename?: 'User' }
    & ActionAuthorFieldsFragment
  ) | { __typename?: 'UserInviteModel' } }
);

export type AlbumEvearaFragment = (
  { __typename?: 'AlbumDataEveara' }
  & Pick<AlbumDataEveara, 'name' | 'eanupc' | 'labelId' | 'originalReleaseDate' | 'subscriptionId' | 'productType'>
  & { artists?: Maybe<Array<Maybe<(
    { __typename?: 'ArtistsAlbumDataEveara' }
    & Pick<ArtistsAlbumDataEveara, 'name' | 'country_code'>
  )>>>, coverImage?: Maybe<(
    { __typename?: 'AlbumCoverImage' }
    & Pick<AlbumCoverImage, 'url' | 'extension'>
  )>, tracks?: Maybe<Array<Maybe<(
    { __typename?: 'TrackDataEveara' }
    & EvearaTrackFragment
  )>>> }
);

export type AlbumEvearaDataFragment = (
  { __typename?: 'AlbumEveara' }
  & Pick<AlbumEveara, 'name' | 'releaseId'>
);

export type ArtistProfileFieldsFragment = (
  { __typename?: 'ArtistProfile' }
  & Pick<ArtistProfile, 'affiliation' | 'apple_artist_id' | 'artist_option' | 'audio_settings' | 'youtube_channel_id' | 'artist_bio' | 'genre' | 'soundcloud_id' | 'spotify_id' | 'artist_name'>
);

export type AssetFieldsFragment = (
  { __typename?: 'AMPAsset' }
  & Pick<AmpAsset, 'id' | 'title' | 'type' | 'created_at' | 'updated_at' | 'type_id' | 'catalog_id'>
  & { meta?: Maybe<(
    { __typename?: 'AudioMetaSuccess' }
    & AssetMeta_AudioMetaSuccess_Fragment
  ) | (
    { __typename?: 'AudioMetaError' }
    & AssetMeta_AudioMetaError_Fragment
  )>, master?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id'>
    & { locked: AmpMaster['is_locked'] }
  )>, project?: Maybe<(
    { __typename?: 'AMPProject' }
    & Pick<AmpProject, 'id'>
    & { locked: AmpProject['is_locked'] }
  )>, mediaCover?: Maybe<(
    { __typename?: 'AMPFile' }
    & { filePath: AmpFile['file_path'] }
  )>, file?: Maybe<(
    { __typename?: 'AMPFile' }
    & Pick<AmpFile, 'media_url' | 'file_name'>
  )> }
);

export type AssetMeta_AudioMetaSuccess_Fragment = (
  { __typename?: 'AudioMetaSuccess' }
  & { is360Ra: AudioMetaSuccess['is_360ra'] }
);

export type AssetMeta_AudioMetaError_Fragment = { __typename?: 'AudioMetaError' };

export type AssetMetaFragment = AssetMeta_AudioMetaSuccess_Fragment | AssetMeta_AudioMetaError_Fragment;

export type AssetMetaFieldsFragment = (
  { __typename?: 'AMPAsset' }
  & Pick<AmpAsset, 'id' | 'title' | 'type' | 'created_at' | 'updated_at'>
  & { meta?: Maybe<(
    { __typename?: 'AudioMetaSuccess' }
    & AudioMetaFieldsFragment
  ) | { __typename?: 'AudioMetaError' }> }
);

export type AudioMetaFieldsFragment = (
  { __typename?: 'AudioMetaSuccess' }
  & { is360RA: AudioMetaSuccess['is_360ra'] }
  & { format: (
    { __typename?: 'Format' }
    & Pick<Format, 'duration'>
    & { formatName: Format['format_name'], bitRate: Format['bit_rate'], bitsPerSample: Format['bit_rate'] }
    & { tags?: Maybe<(
      { __typename?: 'Tags' }
      & Pick<Tags, 'title' | 'artist' | 'composer' | 'genre'>
      & { performers: Tags['TOPE'], year: Tags['date'] }
    )> }
  ) }
);

export type AuthorFragment = (
  { __typename?: 'User' }
  & Pick<User, 'email' | 'first_name' | 'id' | 'last_name' | 'meta'>
);

export type BeatsKeywordFragment = (
  { __typename?: 'MetadataKeyword' }
  & Pick<MetadataKeyword, 'id' | 'label'>
);

export type CatalogFieldsForDistributionFragment = (
  { __typename?: 'Catalog' }
  & Pick<Catalog, 'id' | 'name'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'first_name' | 'last_name'>
  ) }
);

export type CatalogListItemFragment = (
  { __typename?: 'Catalog' }
  & Pick<Catalog, 'id' | 'name'>
);

export type CatalogNameAndUserFragment = (
  { __typename?: 'Catalog' }
  & Pick<Catalog, 'id' | 'name'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type CatalogOptionFragment = (
  { __typename?: 'Catalog' }
  & Pick<Catalog, 'id' | 'name'>
  & { userId: Catalog['user_id'] }
);

export type CollectionBaseInfoFragment = (
  { __typename?: 'AMPCollection' }
  & Pick<AmpCollection, 'id' | 'title' | 'year' | 'created_at' | 'updated_at' | 'artist_name'>
  & { collectionType: AmpCollection['type_id'] }
);

export type CollectionFieldsFragment = (
  { __typename?: 'AMPCollection' }
  & { master_positions: Array<(
    { __typename?: 'AMPMasterCollectionPosition' }
    & Pick<AmpMasterCollectionPosition, 'master_id'>
  )>, cover_art?: Maybe<(
    { __typename?: 'AMPFile' }
    & Pick<AmpFile, 'id' | 'media_url' | 'file_name'>
  )>, ordered_masters: Array<(
    { __typename?: 'AMPMaster' }
    & MasterFieldsFragment
  )> }
  & CollectionBaseInfoFragment
);

export type CollectionListFieldsFragment = (
  { __typename?: 'AMPCollection' }
  & { master_positions: Array<(
    { __typename?: 'AMPMasterCollectionPosition' }
    & Pick<AmpMasterCollectionPosition, 'master_id'>
  )>, cover_art?: Maybe<(
    { __typename?: 'AMPFile' }
    & Pick<AmpFile, 'id' | 'media_url' | 'file_name'>
  )> }
  & CollectionBaseInfoFragment
);

export type ContentTypeFragment = (
  { __typename?: 'SearchContentType' }
  & Pick<SearchContentType, 'id' | 'category' | 'directory' | 'type'>
  & { categoryName: SearchContentType['category_name'], displayLabel: SearchContentType['display_label'], searchResultPriority: SearchContentType['search_result_priority'] }
);

export type CountryFragment = (
  { __typename?: 'Country' }
  & Pick<Country, 'id' | 'name' | 'code'>
);

export type CustomizeMasterMetaFragment = (
  { __typename?: 'MasterMeta' }
  & { customizeMaster?: Maybe<Array<Maybe<(
    { __typename?: 'CustomizeMaster' }
    & MasterCustomizeFieldsFragment
  )>>> }
);

export type DistributionRecordFragment = (
  { __typename?: 'DistributionRecord' }
  & Pick<DistributionRecord, 'id' | 'type' | 'status' | 'codeType' | 'code' | 'distributeToFutureOutlets' | 'createdAt' | 'updatedAt' | 'evearaReleaseId' | 'takingDownFinishedDate'>
  & { artistCountry?: Maybe<(
    { __typename?: 'Country' }
    & CountryFragment
  )>, collection?: Maybe<(
    { __typename?: 'AMPCollection' }
    & { cover_art?: Maybe<(
      { __typename?: 'AMPFile' }
      & Pick<AmpFile, 'id' | 'media_url' | 'file_name' | 'size'>
      & { resolution?: Maybe<(
        { __typename?: 'ImageResolution' }
        & Pick<ImageResolution, 'width' | 'height'>
      )> }
    )>, catalog: (
      { __typename?: 'Catalog' }
      & CatalogFieldsForDistributionFragment
    ) }
    & CollectionFieldsFragment
  )>, master?: Maybe<(
    { __typename?: 'AMPMaster' }
    & { tully_metadata?: Maybe<(
      { __typename?: 'TullyMetadata' }
      & { artwork?: Maybe<(
        { __typename?: 'AMPFile' }
        & Pick<AmpFile, 'id' | 'media_url' | 'file_name' | 'size'>
        & { resolution?: Maybe<(
          { __typename?: 'ImageResolution' }
          & Pick<ImageResolution, 'width' | 'height'>
        )> }
      )> }
      & TullyMetadataFieldsFragment
    )>, catalog: (
      { __typename?: 'Catalog' }
      & CatalogFieldsForDistributionFragment
    ) }
    & MasterFieldsFragment
  )>, tracks: Array<(
    { __typename?: 'DistributionRecordTrack' }
    & DistributionRecordTrackFragment
  )>, outlets?: Maybe<Array<(
    { __typename?: 'DistributionRecordOutletDetails' }
    & DistributionRecordOutletDetailsFragment
  )>> }
);

export type DistributionRecordDataForReleasesTabFragment = (
  { __typename?: 'DistributionRecord' }
  & Pick<DistributionRecord, 'id' | 'type' | 'distributionFinishedDate' | 'takingDownFinishedDate'>
  & { collection?: Maybe<(
    { __typename?: 'AMPCollection' }
    & Pick<AmpCollection, 'title'>
    & { cover_art?: Maybe<(
      { __typename?: 'AMPFile' }
      & Pick<AmpFile, 'media_url'>
    )> }
  )>, master?: Maybe<(
    { __typename?: 'AMPMaster' }
    & { tully_metadata?: Maybe<(
      { __typename?: 'TullyMetadata' }
      & Pick<TullyMetadata, 'track_title'>
      & { artwork?: Maybe<(
        { __typename?: 'AMPFile' }
        & Pick<AmpFile, 'media_url'>
      )> }
    )> }
  )>, tracks: Array<(
    { __typename?: 'DistributionRecordTrack' }
    & Pick<DistributionRecordTrack, 'id'>
  )> }
);

export type DistributionRecordEdgeFragment = (
  { __typename?: 'DistributionRecordEdge' }
  & Pick<DistributionRecordEdge, 'cursor'>
  & { node: (
    { __typename?: 'DistributionRecord' }
    & DistributionRecordFragment
  ) }
);

export type DistributionRecordOutletDetailsFragment = (
  { __typename?: 'DistributionRecordOutletDetails' }
  & Pick<DistributionRecordOutletDetails, 'id' | 'distributorOutletId' | 'preSalesDate' | 'startReleaseDate' | 'endReleaseDate' | 'createdAt' | 'updatedAt'>
);

export type DistributionRecordTrackFragment = (
  { __typename?: 'DistributionRecordTrack' }
  & Pick<DistributionRecordTrack, 'id' | 'distributeClean' | 'distributeExplicit' | 'isForStreaming' | 'isForDownloading' | 'iswc' | 'lyrics' | 'createdAt' | 'updatedAt' | 'cleanVersionEvearaId' | 'explicitVersionEvearaId'>
  & { master: (
    { __typename?: 'AMPMaster' }
    & { tully_metadata?: Maybe<(
      { __typename?: 'TullyMetadata' }
      & { genre_obj?: Maybe<(
        { __typename?: 'Genre' }
        & GenreFieldsFragment
      )>, participants: Array<(
        { __typename?: 'TullyMetadataParticipantInTullyMetadata' }
        & TullyMetadataParticipantInTullyMetadataFragment
      )>, artists: Array<(
        { __typename?: 'TullyMetadataArtist' }
        & TullyMetadataArtistFragment
      )>, featured_artists_objs: Array<(
        { __typename?: 'TullyMetadataArtist' }
        & TullyMetadataArtistFragment
      )> }
      & TullyMetadataFieldsFragment
    )>, catalog: (
      { __typename?: 'Catalog' }
      & CatalogFieldsForDistributionFragment
    ) }
    & MasterFieldsFragment
  ), preview?: Maybe<(
    { __typename?: 'DistributionTrackPreview' }
    & DistributionRecordTrackPreviewFragment
  )> }
);

export type DistributionRecordTrackPreviewFragment = (
  { __typename?: 'DistributionTrackPreview' }
  & Pick<DistributionTrackPreview, 'startAt' | 'duration'>
);

export type DropFragment = (
  { __typename?: 'Drop' }
  & Pick<Drop, 'id' | 'name' | 'artist_name' | 'cover_file_uri' | 'close_date' | 'status' | 'sub_status' | 'date_created' | 'order_id'>
);

export type EvearaArtistFragment = (
  { __typename?: 'EvearaArtist' }
  & Pick<EvearaArtist, 'id' | 'isActive' | 'name'>
  & { isRemovable: EvearaArtist['removable'] }
);

export type EvearaAvailabilityFragment = (
  { __typename?: 'EvearaAvailability' }
  & Pick<EvearaAvailability, 'id' | 'name'>
);

export type EvearaCountryFragment = (
  { __typename?: 'EvearaCountry' }
  & Pick<EvearaCountry, 'countryCode' | 'countryName'>
  & { state?: Maybe<Array<Maybe<(
    { __typename?: 'EvearaCountryState' }
    & EvearaCountryStateFragment
  )>>> }
);

export type EvearaCountryStateFragment = (
  { __typename?: 'EvearaCountryState' }
  & Pick<EvearaCountryState, 'StateName' | 'stateCode'>
);

export type EvearaGenreFragment = (
  { __typename?: 'EvearaGenre' }
  & Pick<EvearaGenre, 'id' | 'name'>
);

export type EvearaLabelFragment = (
  { __typename?: 'EvearaLabel' }
  & Pick<EvearaLabel, 'labelId' | 'isActive' | 'labelName'>
  & { isRemovable: EvearaLabel['removable'] }
);

export type EvearaLanguageFragment = (
  { __typename?: 'EvearaLanguage' }
  & Pick<EvearaLanguage, 'languageCode' | 'languageName'>
);

export type EvearaOutletFragment = (
  { __typename?: 'EvearaOutlet' }
  & Pick<EvearaOutlet, 'storeId' | 'storeName' | 'processDurationDates'>
);

export type EvearaParticipantFragment = (
  { __typename?: 'EvearaParticipant' }
  & Pick<EvearaParticipant, 'ipn' | 'isActive' | 'isni' | 'name' | 'id' | 'paypalEmailId' | 'removable'>
);

export type EvearaReleaseFragment = (
  { __typename?: 'AlbumEveara' }
  & Pick<AlbumEveara, 'eanpc' | 'releaseId' | 'name' | 'trackCount' | 'productCodeType' | 'productType' | 'isActive'>
  & { coverImage?: Maybe<(
    { __typename?: 'AlbumCoverImage' }
    & Pick<AlbumCoverImage, 'url' | 'extension'>
  )> }
);

export type EvearaRoleFragment = (
  { __typename?: 'EvearaRole' }
  & Pick<EvearaRole, 'name' | 'id'>
);

export type EvearaSubscriptionFragment = (
  { __typename?: 'EvearaSubscription' }
  & Pick<EvearaSubscription, 'mySubscriptionId'>
);

export type EvearaTrackFragment = (
  { __typename?: 'TrackDataEveara' }
  & Pick<TrackDataEveara, 'isrc' | 'iswc' | 'artists' | 'genre' | 'featuredArtists' | 'language' | 'availability' | 'explicit' | 'lyrics'>
  & { id: TrackDataEveara['asset_id'], title: TrackDataEveara['name'] }
  & { sony360?: Maybe<Array<Maybe<(
    { __typename?: 'Sony360File' }
    & Sony360FileFragment
  )>>>, meta?: Maybe<(
    { __typename?: 'TullyMetadata' }
    & TullyMetadataFieldsFragment
  )> }
);

export type GenreFieldsFragment = (
  { __typename?: 'Genre' }
  & Pick<Genre, 'id' | 'name'>
);

export type ImportedBeatItemFragment = (
  { __typename?: 'AMPAsset' }
  & Pick<AmpAsset, 'id' | 'title'>
  & { file?: Maybe<(
    { __typename?: 'AMPFile' }
    & Pick<AmpFile, 'id'>
    & { url: AmpFile['media_url'], title: AmpFile['file_name'], contentType: AmpFile['content_type'], createdAt: AmpFile['created_at'] }
  )> }
);

export type ImportedBeatsFragment = (
  { __typename?: 'Catalog' }
  & { importedBeats: Array<(
    { __typename?: 'AMPAsset' }
    & AssetFieldsFragment
  )> }
);

export type InvitedUserFragment = (
  { __typename?: 'UserInviteModel' }
  & Pick<UserInviteModel, 'id' | 'email' | 'meta' | 'status'>
  & { firstName: UserInviteModel['first_name'], lastName: UserInviteModel['last_name'] }
  & { roles?: Maybe<Array<Maybe<(
    { __typename?: 'UserRole' }
    & UserRoleListItemFragment
  )>>> }
);

export type LanguageFragment = (
  { __typename?: 'Language' }
  & Pick<Language, 'id' | 'name' | 'code'>
);

export type MasterCustomizeFieldsFragment = (
  { __typename?: 'CustomizeMaster' }
  & Pick<CustomizeMaster, 'realityAudio' | 'stemFiles' | 'contracts' | 'metadata' | 'media' | 'userId' | 'isAdmin'>
);

export type MasterFieldsFragment = (
  { __typename?: 'AMPMaster' }
  & { assets: Array<(
    { __typename?: 'AMPAsset' }
    & AssetFieldsFragment
  )>, splitSheet: Array<(
    { __typename?: 'Contract' }
    & SplitSheetFieldsFragment
  )>, uploadedContracts: Array<(
    { __typename?: 'Contract' }
    & UploadedContractFieldsFragment
  )> }
  & MasterInfoFieldsFragment
);

export type MasterFilesFieldFragment = (
  { __typename?: 'AMPMaster' }
  & { masterFiles: Array<(
    { __typename?: 'AMPAsset' }
    & AssetFieldsFragment
  )> }
);

export type MasterInfoFieldsFragment = (
  { __typename?: 'AMPMaster' }
  & Pick<AmpMaster, 'id' | 'title' | 'updated_at' | 'catalog_id' | 'fire_node_id'>
  & { locked: AmpMaster['is_locked'] }
  & { meta?: Maybe<(
    { __typename?: 'MasterMeta' }
    & CustomizeMasterMetaFragment
  )>, masterFilesInfo: Array<(
    { __typename?: 'AMPAsset' }
    & Pick<AmpAsset, 'id' | 'type' | 'type_id'>
  )> }
);

export type MenuResourceFieldsFragment = (
  { __typename?: 'Resources' }
  & Pick<Resources, 'id' | 'access' | 'slug' | 'type' | 'value'>
  & { displayName: Resources['display_name'] }
);

export type NewAlbumEvearaFragment = (
  { __typename?: 'AlbumDraftEveara' }
  & Pick<AlbumDraftEveara, 'albumId' | 'name' | 'releaseId' | 'eanpc' | 'trackCount' | 'isActive' | 'productType' | 'productCodeType' | 'spatialEanUpc' | 'spatialProductCodeType' | 'removable' | 'originalReleaseDate'>
  & { tracks?: Maybe<Array<Maybe<(
    { __typename?: 'EvearaDraftAlbumTrack' }
    & Pick<EvearaDraftAlbumTrack, 'iswc' | 'artists' | 'isrc' | 'name' | 'featuredArtists' | 'genres' | 'language' | 'lyrics' | 'explicit' | 'availability'>
    & { trackId: EvearaDraftAlbumTrack['asset_id'], fileUrl: EvearaDraftAlbumTrack['trackUrl'] }
    & { participant?: Maybe<Array<Maybe<(
      { __typename?: 'EvearaAlbumTrackParticipant' }
      & NewParticipantEvearaFragment
    )>>>, preview?: Maybe<(
      { __typename?: 'EvearaAlbumTrackPreview' }
      & Pick<EvearaAlbumTrackPreview, 'startAt' | 'duration'>
    )>, meta?: Maybe<(
      { __typename?: 'TullyMetadataRecord' }
      & TullyMetadataRecordFieldsFragment
    )> }
  )>>>, artists?: Maybe<Array<Maybe<(
    { __typename?: 'AlbumArtistsRecord' }
    & Pick<AlbumArtistsRecord, 'artistName' | 'artistCountry'>
  )>>>, subscription?: Maybe<(
    { __typename?: 'EvearaAlbumSubscription' }
    & Pick<EvearaAlbumSubscription, 'subscriptionId' | 'subscriptionName' | 'expirationDate'>
  )>, coverImage?: Maybe<(
    { __typename?: 'AlbumCoverImage' }
    & Pick<AlbumCoverImage, 'url' | 'extension'>
  )> }
);

export type NewEvearaArtistFragment = (
  { __typename?: 'EvearaTrackArtist' }
  & Pick<EvearaTrackArtist, 'artistId' | 'name'>
);

export type NewEvearaAvailabilityFragment = (
  { __typename?: 'EvearaTrackAvailability' }
  & Pick<EvearaTrackAvailability, 'availabilityId' | 'name'>
);

export type NewEvearaGenreFragment = (
  { __typename?: 'EvearaTrackGenres' }
  & Pick<EvearaTrackGenres, 'genreId' | 'name'>
);

export type NewParticipantEvearaFragment = (
  { __typename?: 'EvearaAlbumTrackParticipant' }
  & Pick<EvearaAlbumTrackParticipant, 'participantId' | 'participantName' | 'payoutSharePercentage'>
  & { roles?: Maybe<Array<Maybe<(
    { __typename?: 'EvearaAlbumParticipantRole' }
    & Pick<EvearaAlbumParticipantRole, 'roleId' | 'roleName'>
  )>>> }
);

export type NewTrackEvearaFragment = (
  { __typename?: 'TrackDraftEveara' }
  & Pick<TrackDraftEveara, 'isrc' | 'iswc' | 'albumOnly' | 'language' | 'explicit' | 'removable' | 'extention' | 'spatialIsrc' | 'dolbyAtmosIsrc' | 'lyrics'>
  & { title: TrackDraftEveara['name'], id: TrackDraftEveara['trackId'], fileUrl: TrackDraftEveara['name'] }
  & { availability?: Maybe<Array<Maybe<(
    { __typename?: 'EvearaTrackAvailability' }
    & NewEvearaAvailabilityFragment
  )>>>, genres?: Maybe<Array<Maybe<(
    { __typename?: 'EvearaTrackGenres' }
    & NewEvearaGenreFragment
  )>>>, artists?: Maybe<Array<Maybe<(
    { __typename?: 'EvearaTrackArtist' }
    & NewEvearaArtistFragment
  )>>>, featuredArtists?: Maybe<Array<Maybe<(
    { __typename?: 'EvearaTrackArtist' }
    & NewEvearaArtistFragment
  )>>> }
);

export type OpenAiPromptRecordForTestingDialogFragment = (
  { __typename?: 'OpenAIPromptRecord' }
  & Pick<OpenAiPromptRecord, 'id' | 'completion'>
);

export type PageInfoFieldsFragment = (
  { __typename?: 'PageInfo' }
  & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
);

export type PlayerAssetFragment = (
  { __typename?: 'AMPAsset' }
  & Pick<AmpAsset, 'id' | 'title' | 'type'>
  & { mediaCover?: Maybe<(
    { __typename?: 'AMPFile' }
    & { mediaUrl: AmpFile['media_url'] }
  )>, file?: Maybe<(
    { __typename?: 'AMPFile' }
    & { mediaUrl: AmpFile['media_url'], fileName: AmpFile['file_name'] }
  )> }
);

export type ProjectMetaFieldFragment = (
  { __typename?: 'AMPProject' }
  & { meta?: Maybe<(
    { __typename?: 'ProjectMeta' }
    & Pick<ProjectMeta, 'title' | 'artist'>
  )> }
);

export type LyricsFieldsFragment = (
  { __typename?: 'AMPLyrics' }
  & Pick<AmpLyrics, 'id' | 'created_at' | 'content'>
);

export type ProjectFieldsFragment = (
  { __typename?: 'AMPProject' }
  & Pick<AmpProject, 'id' | 'title' | 'created_at' | 'updated_at' | 'catalog_id'>
  & { locked: AmpProject['is_locked'] }
  & { assets: Array<(
    { __typename?: 'AMPAsset' }
    & AssetFieldsFragment
  )>, lyrics: Array<(
    { __typename?: 'AMPLyrics' }
    & LyricsFieldsFragment
  )> }
  & ProjectMetaFieldFragment
);

export type ResourceFieldsFragment = (
  { __typename?: 'Resources' }
  & Pick<Resources, 'access' | 'slug' | 'type' | 'value'>
  & { id: Resources['slug'], displayName: Resources['display_name'] }
);

export type SavedSearchFragment = (
  { __typename?: 'SavedSearch' }
  & Pick<SavedSearch, 'id' | 'name' | 'public'>
  & { filters?: Maybe<(
    { __typename?: 'SearchFilter' }
    & SearchFilterFragment
  )> }
);

export type SearchFilterFragment = (
  { __typename?: 'SearchFilter' }
  & Pick<SearchFilter, 'period'>
  & { searchText: SearchFilter['name'], periodUnit: SearchFilter['period_unit'], fromDate: SearchFilter['from_date'], toDate: SearchFilter['to_date'] }
  & { contentTypes?: Maybe<Array<(
    { __typename?: 'SearchContentType' }
    & Pick<SearchContentType, 'id'>
  )>>, catalog?: Maybe<Array<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
  )>>, author?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>> }
);

export type SearchItemFragment = (
  { __typename?: 'SearchResult' }
  & Pick<SearchResult, 'name' | 'parent_id' | 'parent_name' | 'parent_type' | 'folder'>
  & { id: SearchResult['entity_id'], createdAt: SearchResult['created_at'], updatedAt: SearchResult['updated_at'] }
  & { type: (
    { __typename?: 'SearchContentType' }
    & Pick<SearchContentType, 'display_label' | 'type'>
  ), catalog: (
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'name'>
  ), author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'first_name' | 'last_name'>
  )> }
);

export type Sony360FileFragment = (
  { __typename?: 'Sony360File' }
  & Pick<Sony360File, 'spatial_type' | 'sony360_file_type'>
  & { id: Sony360File['asset_id'], title: Sony360File['filename'] }
);

export type SplitSheetFieldsFragment = (
  { __typename?: 'Contract' }
  & Pick<Contract, 'id' | 'type' | 'title' | 'date' | 'state' | 'created_at'>
  & { metadata?: Maybe<(
    { __typename?: 'ContractMetadata' }
    & Pick<ContractMetadata, 'aka'>
  )>, parties: Array<(
    { __typename?: 'ContractParty' }
    & SplitSheetPartyFieldsFragment
  )>, master?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id'>
    & { locked: AmpMaster['is_locked'] }
    & { catalog: (
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'id'>
    ) }
  )> }
);

export type SplitSheetPartyFieldsFragment = (
  { __typename?: 'ContractParty' }
  & Pick<ContractParty, 'id' | 'name' | 'role' | 'role_label' | 'contact' | 'accepted' | 'can_see_others'>
  & { metadata: { __typename: 'UploadedPartyMeta' } | (
    { __typename: 'SplitPartyMeta' }
    & Pick<SplitPartyMeta, 'affiliation' | 'ownership'>
  ) }
);

export type SubscriptionMetaFragment = (
  { __typename?: 'SubscriptionMeta' }
  & { iosTransactionId: SubscriptionMeta['ios_transaction_id'], lastTransactionPeriodEnd: SubscriptionMeta['last_transaction_period_end'], lastTransactionPeriodStart: SubscriptionMeta['last_transaction_period_start'], stripeSubscriptionId: SubscriptionMeta['stripe_subscription_id'], subscriptionId: SubscriptionMeta['subscription_id'] }
);

export type TullyBeatItemFragment = (
  { __typename?: 'TullyBeatResult' }
  & Pick<TullyBeatResult, 'id' | 'title' | 'price'>
  & { file: (
    { __typename?: 'AMPFile' }
    & Pick<AmpFile, 'id'>
    & { url: AmpFile['media_url'], title: AmpFile['file_name'], contentType: AmpFile['content_type'], createdAt: AmpFile['created_at'] }
  ) }
);

export type TullyBeatsFragment = (
  { __typename?: 'Catalog' }
  & { tullyBeats: Array<(
    { __typename?: 'AMPAsset' }
    & AssetFieldsFragment
  )> }
);

export type TullyMetadataArtistFragment = (
  { __typename?: 'TullyMetadataArtist' }
  & Pick<TullyMetadataArtist, 'id' | 'name' | 'eveara_id'>
  & { country: (
    { __typename?: 'Country' }
    & CountryFragment
  ) }
);

export type TullyMetadataFieldsFragment = (
  { __typename?: 'TullyMetadata' }
  & Pick<TullyMetadata, 'id' | 'imported' | 'last_edited' | 'artist' | 'artist_name' | 'spotify_artist_id' | 'artist_affiliation' | 'remixer' | 'track_title' | 'album_name' | 'created_at' | 'updated_at' | 'ep_name' | 'publisher_name' | 'publisher_ownership' | 'publisher_affiliation' | 'upc_ean_code' | 'isrc' | 'genre' | 'tempo' | 'mix_variant' | 'release_date' | 'sale_start_date' | 'has_third_party_publisher' | 'has_ownership'>
  & { language_obj?: Maybe<(
    { __typename?: 'Language' }
    & LanguageFragment
  )>, country_obj?: Maybe<(
    { __typename?: 'Country' }
    & CountryFragment
  )>, artwork?: Maybe<(
    { __typename?: 'AMPFile' }
    & Pick<AmpFile, 'id' | 'media_url' | 'file_name'>
  )>, collaborators?: Maybe<Array<(
    { __typename?: 'TullyPersonMetadata' }
    & TullyPersonMetadataFieldsFragment
  )>> }
);

export type TullyMetadataParticipantFragment = (
  { __typename?: 'TullyMetadataParticipant' }
  & Pick<TullyMetadataParticipant, 'id' | 'name' | 'eveara_id'>
);

export type TullyMetadataParticipantInTullyMetadataFragment = (
  { __typename?: 'TullyMetadataParticipantInTullyMetadata' }
  & Pick<TullyMetadataParticipantInTullyMetadata, 'payout'>
  & { participant: (
    { __typename?: 'TullyMetadataParticipant' }
    & TullyMetadataParticipantFragment
  ), role: (
    { __typename?: 'TullyMetadataParticipantRole' }
    & TullyMetadataParticipantRoleFragment
  ) }
);

export type TullyMetadataParticipantRoleFragment = (
  { __typename?: 'TullyMetadataParticipantRole' }
  & Pick<TullyMetadataParticipantRole, 'name' | 'value'>
);

export type TullyMetadataRecordFieldsFragment = (
  { __typename?: 'TullyMetadataRecord' }
  & Pick<TullyMetadataRecord, 'genre'>
);

export type TullyPersonMetadataFieldsFragment = (
  { __typename?: 'TullyPersonMetadata' }
  & Pick<TullyPersonMetadata, 'id' | 'name' | 'role' | 'affiliation' | 'ownership'>
  & { appleId: TullyPersonMetadata['apple_id'], spotifyId: TullyPersonMetadata['spotify_id'] }
);

export type UploadedContractFieldsFragment = (
  { __typename?: 'Contract' }
  & Pick<Contract, 'id' | 'type' | 'title' | 'created_at' | 'updated_at' | 'state'>
  & { metadata?: Maybe<(
    { __typename?: 'ContractMetadata' }
    & Pick<ContractMetadata, 'description'>
  )>, parties: Array<(
    { __typename?: 'ContractParty' }
    & UploadedContractPartyFieldsFragment
  )>, file?: Maybe<(
    { __typename?: 'AMPFile' }
    & Pick<AmpFile, 'id' | 'media_url' | 'file_name'>
  )>, master?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id'>
    & { locked: AmpMaster['is_locked'] }
    & { catalog: (
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'id'>
    ) }
  )> }
);

export type UploadedContractPartyFieldsFragment = (
  { __typename?: 'ContractParty' }
  & Pick<ContractParty, 'id' | 'name' | 'role_label' | 'contact' | 'accepted'>
  & { metadata: (
    { __typename: 'UploadedPartyMeta' }
    & Pick<UploadedPartyMeta, 'advance' | 'royalty_label' | 'royalty' | 'recoup' | 'recoup_label' | 'publishing'>
  ) | { __typename: 'SplitPartyMeta' } }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'meta'>
  & { firstName: User['first_name'], lastName: User['last_name'], isActive: User['is_active'], isAdmin: User['is_admin'], isGuest: User['is_guest'], isArtist: User['is_artist'], isOwner: User['is_owner'] }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'avatar'>
  )>, roles: Array<(
    { __typename?: 'UserRole' }
    & UserRoleListItemFragment
  )>, catalogs: Array<(
    { __typename?: 'Catalog' }
    & CatalogListItemFragment
  )> }
);

export type UserFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'created_at' | 'updated_at' | 'email'>
  & { firstName: User['first_name'], lastName: User['last_name'], isGuest: User['is_guest'], isActive: User['is_active'], isArtist: User['is_artist'], isStaff: User['is_staff'], isAdmin: User['is_admin'], isOwner: User['is_owner'] }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & AccountFieldsFragment
  )>, profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'phone' | 'avatar'>
    & { artist_profile?: Maybe<(
      { __typename?: 'ArtistProfile' }
      & ArtistProfileFieldsFragment
    )> }
  )>, roles: Array<(
    { __typename?: 'UserRole' }
    & Pick<UserRole, 'id' | 'name'>
  )> }
);

export type UserMetaFieldsFragment = (
  { __typename?: 'UMUserMeta' }
  & Pick<UmUserMeta, 'photo_url'>
);

export type UserRoleListItemFragment = (
  { __typename?: 'UserRole' }
  & Pick<UserRole, 'id' | 'name'>
);

export type AddAlbumEvearaMutationVariables = Exact<{
  albumEvearaInput: AlbumEvearaInput;
}>;


export type AddAlbumEvearaMutation = (
  { __typename?: 'Mutation' }
  & { newAlbumEveara?: Maybe<(
    { __typename?: 'EvearaAddAlbumResponse' }
    & Pick<EvearaAddAlbumResponse, 'releaseId' | 'message'>
    & { isSuccess: EvearaAddAlbumResponse['success'] }
  )> }
);

export type AddArtistEvearaMutationVariables = Exact<{
  name: Scalars['String'];
  country: Scalars['String'];
}>;


export type AddArtistEvearaMutation = (
  { __typename?: 'Mutation' }
  & { newArtistEveara?: Maybe<(
    { __typename?: 'EvearaAddArtistResponse' }
    & Pick<EvearaAddArtistResponse, 'artistId' | 'artistName'>
  )> }
);

export type AddLabelEvearaMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type AddLabelEvearaMutation = (
  { __typename?: 'Mutation' }
  & { newLabelEveara?: Maybe<(
    { __typename?: 'EvearaAddLabelResponse' }
    & Pick<EvearaAddLabelResponse, 'labelId' | 'labelName'>
  )> }
);

export type AddParticipantEvearaMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type AddParticipantEvearaMutation = (
  { __typename?: 'Mutation' }
  & { newParticipantEveara?: Maybe<(
    { __typename?: 'EvearaAddParticipantResponse' }
    & Pick<EvearaAddParticipantResponse, 'participantId' | 'participantName'>
  )> }
);

export type AddRolesToUserMutationVariables = Exact<{
  id: Scalars['Int'];
  roleIds: Array<Scalars['Int']>;
}>;


export type AddRolesToUserMutation = (
  { __typename?: 'Mutation' }
  & { updatedUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type AddToAlbumMutationVariables = Exact<{
  collectionId: Scalars['Int'];
  masterId: Scalars['Int'];
}>;


export type AddToAlbumMutation = (
  { __typename?: 'Mutation' }
  & { addMasterToCollection?: Maybe<(
    { __typename?: 'AMPCollection' }
    & Pick<AmpCollection, 'id'>
    & { master_positions: Array<(
      { __typename?: 'AMPMasterCollectionPosition' }
      & Pick<AmpMasterCollectionPosition, 'master_id'>
    )>, ordered_masters: Array<(
      { __typename?: 'AMPMaster' }
      & MasterFieldsFragment
    )> }
  )> }
);

export type AddTrackToDistributionRecordMutationVariables = Exact<{
  recordId: Scalars['Int'];
  masterId: Scalars['Int'];
}>;


export type AddTrackToDistributionRecordMutation = (
  { __typename?: 'Mutation' }
  & { addTrackToDistributionRecord: (
    { __typename?: 'DistributionRecordTrack' }
    & DistributionRecordTrackFragment
  ) }
);

export type AssignAdminMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AssignAdminMutation = (
  { __typename?: 'Mutation' }
  & { updatedUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type AttachAlbumArtworkMutationVariables = Exact<{
  id: Scalars['Int'];
  fileName: Scalars['String'];
  attach: Maybe<Scalars['Boolean']>;
}>;


export type AttachAlbumArtworkMutation = (
  { __typename?: 'Mutation' }
  & { attachCollectionCoverArt?: Maybe<(
    { __typename?: 'AMPCollection' }
    & Pick<AmpCollection, 'id'>
    & { cover_art?: Maybe<(
      { __typename?: 'AMPFile' }
      & Pick<AmpFile, 'id' | 'media_url'>
    )> }
  )> }
);

export type CreateAlbumMutationVariables = Exact<{
  input: CollectionInput;
}>;


export type CreateAlbumMutation = (
  { __typename?: 'Mutation' }
  & { createCollection?: Maybe<(
    { __typename?: 'AMPCollection' }
    & CollectionFieldsFragment
  )> }
);

export type CreateContractMutationVariables = Exact<{
  title: Scalars['String'];
  masterId: Scalars['Int'];
  description: Maybe<Scalars['String']>;
  type: ContractType;
  fileId: Maybe<Scalars['Int']>;
}>;


export type CreateContractMutation = (
  { __typename?: 'Mutation' }
  & { createContract?: Maybe<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id'>
  )> }
);

export type CreateContractPartyMutationVariables = Exact<{
  name: Maybe<Scalars['String']>;
  contact: Maybe<Scalars['String']>;
  roleLabel: Maybe<Scalars['String']>;
  contractId: Scalars['Int'];
  metadata: Maybe<ContractPartyMetaInput>;
}>;


export type CreateContractPartyMutation = (
  { __typename?: 'Mutation' }
  & { createContractParty?: Maybe<(
    { __typename?: 'ContractParty' }
    & Pick<ContractParty, 'id'>
  )> }
);

export type CreateGuestUserMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type CreateGuestUserMutation = (
  { __typename?: 'Mutation' }
  & { createGuestUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'is_active' | 'is_guest'>
  ) }
);

export type CreateSavedSearchMutationVariables = Exact<{
  name: Scalars['String'];
  public: Scalars['Boolean'];
  filter: SearchFilterInput;
}>;


export type CreateSavedSearchMutation = (
  { __typename?: 'Mutation' }
  & { createdSearch: (
    { __typename?: 'SavedSearch' }
    & SavedSearchFragment
  ) }
);

export type CreateTullyMetadataArtistMutationVariables = Exact<{
  data: CreateTullyMetadataArtistInput;
}>;


export type CreateTullyMetadataArtistMutation = (
  { __typename?: 'Mutation' }
  & { createTullyMetadataArtist: (
    { __typename?: 'TullyMetadataArtist' }
    & TullyMetadataArtistFragment
  ) }
);

export type CreateTullyMetadataParticipantMutationVariables = Exact<{
  data: CreateTullyMetadataParticipantInput;
}>;


export type CreateTullyMetadataParticipantMutation = (
  { __typename?: 'Mutation' }
  & { createTullyMetadataParticipant: (
    { __typename?: 'TullyMetadataParticipant' }
    & TullyMetadataParticipantFragment
  ) }
);

export type CreateTullyPersonMetadataMutationVariables = Exact<{
  data: TullyPersonMetadataInput;
}>;


export type CreateTullyPersonMetadataMutation = (
  { __typename?: 'Mutation' }
  & { createTullyPersonMetadata?: Maybe<(
    { __typename?: 'TullyPersonMetadata' }
    & Pick<TullyPersonMetadata, 'id'>
    & TullyPersonMetadataFieldsFragment
  )> }
);

export type CreateUserInviteMutationVariables = Exact<{
  data: UserInviteInput;
}>;


export type CreateUserInviteMutation = (
  { __typename?: 'Mutation' }
  & { invitedUser: (
    { __typename?: 'UserInviteModel' }
    & InvitedUserFragment
  ) }
);

export type DeleteAlbumEvearaMutationVariables = Exact<{
  releaseId: Scalars['Float'];
}>;


export type DeleteAlbumEvearaMutation = (
  { __typename?: 'Mutation' }
  & { deletedAlbumId?: Maybe<(
    { __typename?: 'EvearaBaseResponse' }
    & Pick<EvearaBaseResponse, 'message' | 'success'>
  )> }
);

export type DeleteAssetMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAssetMutation = (
  { __typename?: 'Mutation' }
  & { deletedAssetId: Mutation['deleteAsset'] }
);

export type DeleteCollectionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCollectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCollection'>
);

export type DeleteContractMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteContractMutation = (
  { __typename?: 'Mutation' }
  & { deletedContractId: Mutation['deleteContract'] }
);

export type DeleteContractPartyMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteContractPartyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractParty'>
);

export type DeleteDistributionRecordMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDistributionRecordMutation = (
  { __typename?: 'Mutation' }
  & { deleteDistributionRecord: (
    { __typename?: 'DistributionRecord' }
    & DistributionRecordFragment
  ) }
);

export type DeleteInvitedUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInvitedUserMutation = (
  { __typename?: 'Mutation' }
  & { deletedUserId: Mutation['deleteInvitedUser'] }
);

export type DeleteLyricsMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteLyricsMutation = (
  { __typename?: 'Mutation' }
  & { deletedLyricsId: Mutation['deleteLyrics'] }
);

export type DeleteMasterMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMasterMutation = (
  { __typename?: 'Mutation' }
  & { deletedMaster: Mutation['deleteMaster'] }
);

export type UpdateMasterMetaCollabsMutationVariables = Exact<{
  id: Scalars['Int'];
  collabIds: Array<Scalars['Int']>;
}>;


export type UpdateMasterMetaCollabsMutation = (
  { __typename?: 'Mutation' }
  & { updateMaster?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id'>
    & { tully_metadata?: Maybe<(
      { __typename?: 'TullyMetadata' }
      & Pick<TullyMetadata, 'id'>
      & { collaborators?: Maybe<Array<(
        { __typename?: 'TullyPersonMetadata' }
        & TullyPersonMetadataFieldsFragment
      )>> }
    )> }
  )> }
);

export type DeleteMultiExcludedResourcesMutationVariables = Exact<{
  resource_id: Array<Maybe<Scalars['Int']>>;
}>;


export type DeleteMultiExcludedResourcesMutation = (
  { __typename?: 'Mutation' }
  & { deleteMultiExcludedResources: Array<(
    { __typename?: 'Resources' }
    & Pick<Resources, 'id' | 'slug' | 'display_name' | 'type' | 'access' | 'value'>
  )> }
);

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { deletedProjectId: Mutation['deleteProject'] }
);

export type DeleteSavedSearchMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSavedSearchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSavedSearch'>
);

export type DeleteTullyPersonMetadataMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTullyPersonMetadataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTullyPersonMetadata'>
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deletedUserId: Mutation['deleteUser'] }
);

export type DistributeAlbumEvearaMutationVariables = Exact<{
  releaseId: Scalars['Float'];
  outletsDetails: Array<OutletsDetailsEveara>;
}>;


export type DistributeAlbumEvearaMutation = (
  { __typename?: 'Mutation' }
  & { addOutlets?: Maybe<(
    { __typename?: 'EvearaBaseResponse' }
    & Pick<EvearaBaseResponse, 'success' | 'message'>
  )> }
);

export type DistributeDistributionRecordMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DistributeDistributionRecordMutation = (
  { __typename?: 'Mutation' }
  & { distributeDistributionRecord: (
    { __typename?: 'DistributionRecord' }
    & DistributionRecordFragment
  ) }
);

export type DropCollectionCoverArtMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DropCollectionCoverArtMutation = (
  { __typename?: 'Mutation' }
  & { dropCollectionCoverArt?: Maybe<(
    { __typename?: 'AMPCollection' }
    & Pick<AmpCollection, 'id'>
    & { cover_art?: Maybe<(
      { __typename?: 'AMPFile' }
      & Pick<AmpFile, 'id' | 'media_url' | 'file_name'>
    )> }
  )> }
);

export type GenerateLyricsByThemeWithChatGptMutationVariables = Exact<{
  theme: Scalars['String'];
}>;


export type GenerateLyricsByThemeWithChatGptMutation = (
  { __typename?: 'Mutation' }
  & { generateLyricsByThemeWithChatGPT: (
    { __typename?: 'OpenAIPromptRecord' }
    & OpenAiPromptRecordForTestingDialogFragment
  ) }
);

export type MarkActionsAsSeenMutationVariables = Exact<{
  ids: Array<Scalars['Int']>;
}>;


export type MarkActionsAsSeenMutation = (
  { __typename?: 'Mutation' }
  & { markActionsAsSeen?: Maybe<(
    { __typename?: 'Error' }
    & Pick<Error, 'message'>
  )> }
);

export type RateOpenAiPromptRecordCompletionMutationVariables = Exact<{
  recordId: Scalars['Int'];
  rate: Maybe<Scalars['Int']>;
}>;


export type RateOpenAiPromptRecordCompletionMutation = (
  { __typename?: 'Mutation' }
  & { rateOpenAIPromptRecordCompletion: (
    { __typename?: 'OpenAIPromptRecord' }
    & OpenAiPromptRecordForTestingDialogFragment
  ) }
);

export type RemoveMasterFromCollectionMutationVariables = Exact<{
  collectionId: Scalars['Int'];
  masterId: Scalars['Int'];
}>;


export type RemoveMasterFromCollectionMutation = (
  { __typename?: 'Mutation' }
  & { removeMasterFromCollection?: Maybe<(
    { __typename?: 'AMPCollection' }
    & CollectionFieldsFragment
  )> }
);

export type RemoveRolesFromUserMutationVariables = Exact<{
  id: Scalars['Int'];
  roleIds: Array<Scalars['Int']>;
}>;


export type RemoveRolesFromUserMutation = (
  { __typename?: 'Mutation' }
  & { updatedUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type RemoveTrackFromDistributionRecordMutationVariables = Exact<{
  recordId: Scalars['Int'];
  trackId: Scalars['Int'];
}>;


export type RemoveTrackFromDistributionRecordMutation = (
  { __typename?: 'Mutation' }
  & { removeTrackFromDistributionRecord: (
    { __typename?: 'DistributionRecordTrack' }
    & Pick<DistributionRecordTrack, 'id'>
  ) }
);

export type ResendUserInviteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ResendUserInviteMutation = (
  { __typename?: 'Mutation' }
  & { invitedUser: (
    { __typename?: 'UserInviteModel' }
    & InvitedUserFragment
  ) }
);

export type RewriteLyricsWithChatGptMutationVariables = Exact<{
  lyrics: Scalars['String'];
}>;


export type RewriteLyricsWithChatGptMutation = (
  { __typename?: 'Mutation' }
  & { rewriteLyricsWithChatGPT: (
    { __typename?: 'OpenAIPromptRecord' }
    & OpenAiPromptRecordForTestingDialogFragment
  ) }
);

export type SetMultiExcludedResourcesMutationVariables = Exact<{
  resource_id: Array<Maybe<Scalars['Int']>>;
}>;


export type SetMultiExcludedResourcesMutation = (
  { __typename?: 'Mutation' }
  & { setMultiExcludedResources: Array<(
    { __typename?: 'Resources' }
    & Pick<Resources, 'id' | 'slug' | 'display_name' | 'type' | 'access' | 'value'>
  )> }
);

export type SetUserStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: UserStatus;
}>;


export type SetUserStatusMutation = (
  { __typename?: 'Mutation' }
  & { updatedUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type SynchronizeAndGetDistributionRecordMutationVariables = Exact<{
  type: DistributionType;
  relatedId: Scalars['Int'];
}>;


export type SynchronizeAndGetDistributionRecordMutation = (
  { __typename?: 'Mutation' }
  & { synchronizeAndGetDistributionRecord: (
    { __typename?: 'DistributionRecord' }
    & DistributionRecordFragment
  ) }
);

export type SynchronizeAndGetDistributionRecordByIdMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SynchronizeAndGetDistributionRecordByIdMutation = (
  { __typename?: 'Mutation' }
  & { synchronizeAndGetDistributionRecordById: (
    { __typename?: 'DistributionRecord' }
    & DistributionRecordFragment
  ) }
);

export type TakeDownDistributionRecordMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TakeDownDistributionRecordMutation = (
  { __typename?: 'Mutation' }
  & { takeDownDistributionRecord: (
    { __typename?: 'DistributionRecord' }
    & DistributionRecordFragment
  ) }
);

export type UnAssignAdminMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UnAssignAdminMutation = (
  { __typename?: 'Mutation' }
  & { updatedUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type UpdateAlbumMutationVariables = Exact<{
  id: Scalars['Int'];
  input: CollectionInput;
}>;


export type UpdateAlbumMutation = (
  { __typename?: 'Mutation' }
  & { updateCollection?: Maybe<(
    { __typename?: 'AMPCollection' }
    & CollectionFieldsFragment
  )> }
);

export type UpdateAlbumAndCoverMutationVariables = Exact<{
  id: Scalars['Int'];
  input: CollectionInput;
  fileName: Scalars['String'];
  attach: Maybe<Scalars['Boolean']>;
}>;


export type UpdateAlbumAndCoverMutation = (
  { __typename?: 'Mutation' }
  & { updateCollection?: Maybe<(
    { __typename?: 'AMPCollection' }
    & CollectionFieldsFragment
  )>, attachCollectionCoverArt?: Maybe<(
    { __typename?: 'AMPCollection' }
    & Pick<AmpCollection, 'id'>
    & { cover_art?: Maybe<(
      { __typename?: 'AMPFile' }
      & Pick<AmpFile, 'id' | 'media_url' | 'file_name'>
    )> }
  )> }
);

export type UpdateAlbumEvearaMutationVariables = Exact<{
  updateAlbumEvearaRecordInput: UpdateAlbumEvearaRecordInput;
}>;


export type UpdateAlbumEvearaMutation = (
  { __typename?: 'Mutation' }
  & { updateAlbumEveara?: Maybe<(
    { __typename?: 'EvearaBaseResponse' }
    & Pick<EvearaBaseResponse, 'message'>
    & { isSuccess: EvearaBaseResponse['success'] }
  )> }
);

export type UpdateArtistProfileMutationVariables = Exact<{
  artistProfileData: ArtistProfileInput;
}>;


export type UpdateArtistProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrentUser: (
    { __typename?: 'User' }
    & UserFieldsFragment
  ) }
);

export type UpdateContractMutationVariables = Exact<{
  id: Scalars['Int'];
  title: Scalars['String'];
  masterId: Scalars['Int'];
  description: Maybe<Scalars['String']>;
  type: ContractType;
  fileId: Maybe<Scalars['Int']>;
}>;


export type UpdateContractMutation = (
  { __typename?: 'Mutation' }
  & { updateContract?: Maybe<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id'>
    & SplitSheetFieldsFragment
    & UploadedContractFieldsFragment
  )> }
);

export type UpdateContractPartyMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  contact: Maybe<Scalars['String']>;
  roleLabel: Maybe<Scalars['String']>;
  contractId: Scalars['Int'];
  metadata: Maybe<ContractPartyMetaInput>;
}>;


export type UpdateContractPartyMutation = (
  { __typename?: 'Mutation' }
  & { updateContractParty?: Maybe<(
    { __typename?: 'ContractParty' }
    & Pick<ContractParty, 'id' | 'name'>
  )> }
);

export type UpdateDistributionRecordMutationVariables = Exact<{
  id: Scalars['Int'];
  data: UpdateDistributionRecordInput;
}>;


export type UpdateDistributionRecordMutation = (
  { __typename?: 'Mutation' }
  & { updateDistributionRecord: (
    { __typename?: 'DistributionRecord' }
    & DistributionRecordFragment
  ) }
);

export type UpdateDistributionRecordOutletsDetailsMutationVariables = Exact<{
  recordId: Scalars['Int'];
  distributeToFutureOutlets: Scalars['Boolean'];
  data: Array<UpdateDistributionRecordOutletsDetailsInput>;
}>;


export type UpdateDistributionRecordOutletsDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateDistributionRecordOutletsDetails?: Maybe<(
    { __typename?: 'UpdateDistributionRecordOutletsDetailsResponse' }
    & Pick<UpdateDistributionRecordOutletsDetailsResponse, 'distributeToFutureOutlets'>
    & { outlets: Array<(
      { __typename?: 'DistributionRecordOutletDetails' }
      & DistributionRecordOutletDetailsFragment
    )> }
  )> }
);

export type UpdateDistributionRecordTracksMutationVariables = Exact<{
  recordId: Scalars['Int'];
  data: Array<UpdateDistributionTrackInput>;
}>;


export type UpdateDistributionRecordTracksMutation = (
  { __typename?: 'Mutation' }
  & { updateDistributionRecordTracks: Array<(
    { __typename?: 'DistributionRecordTrack' }
    & DistributionRecordTrackFragment
  )> }
);

export type UpdateMasterMutationVariables = Exact<{
  id: Scalars['Int'];
  data: AmpMasterInput;
}>;


export type UpdateMasterMutation = (
  { __typename?: 'Mutation' }
  & { updateMaster?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'created_at' | 'updated_at' | 'id' | 'title' | 'is_locked' | 'fire_node_id' | 'catalog_id'>
  )> }
);

export type UpdateMasterMetaMutationVariables = Exact<{
  id: Scalars['Int'];
  data: AmpMasterMetaInput;
}>;


export type UpdateMasterMetaMutation = (
  { __typename?: 'Mutation' }
  & { updateMasterMeta?: Maybe<(
    { __typename?: 'AMPMaster' }
    & MasterFieldsFragment
  )> }
);

export type UpdateMasterMetadataMutationVariables = Exact<{
  id: Scalars['Int'];
  tullyMetadata: TullyMetadataInput;
}>;


export type UpdateMasterMetadataMutation = (
  { __typename?: 'Mutation' }
  & { updateMaster?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id'>
    & { tully_metadata?: Maybe<(
      { __typename?: 'TullyMetadata' }
      & TullyMetadataFieldsFragment
    )> }
  )> }
);

export type UpdateSavedSearchMutationVariables = Exact<{
  data: UpdateSavedSearchInput;
  id: Scalars['Int'];
}>;


export type UpdateSavedSearchMutation = (
  { __typename?: 'Mutation' }
  & { updatedSearch: (
    { __typename?: 'SavedSearch' }
    & SavedSearchFragment
  ) }
);

export type UpdateTullyPersonMetadataMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TullyPersonMetadataInput;
}>;


export type UpdateTullyPersonMetadataMutation = (
  { __typename?: 'Mutation' }
  & { updateTullyPersonMetadata?: Maybe<(
    { __typename?: 'TullyPersonMetadata' }
    & Pick<TullyPersonMetadata, 'id'>
    & TullyPersonMetadataFieldsFragment
  )> }
);

export type AccountResourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountResourcesQuery = (
  { __typename?: 'Query' }
  & { resources: Array<(
    { __typename?: 'Resources' }
    & ResourceFieldsFragment
  )> }
);

export type AccountSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { subscriptions: Array<(
    { __typename?: 'AccountSubscription' }
    & Pick<AccountSubscription, 'amount' | 'display_name' | 'exclusive' | 'name' | 'renewal' | 'status' | 'type' | 'upgrade_index'>
  )> }
);

export type AdvancedSearchQueryVariables = Exact<{
  cursor: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  filter: Maybe<SearchFilterInput>;
  sort: Maybe<Array<Maybe<SearchSortInput>>>;
}>;


export type AdvancedSearchQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<(
    { __typename?: 'Search' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ), edges?: Maybe<Array<(
      { __typename?: 'SearchEdge' }
      & Pick<SearchEdge, 'cursor'>
      & { node: (
        { __typename?: 'SearchResult' }
        & SearchItemFragment
      ) }
    )>> }
  )> }
);

export type CatalogNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type CatalogNamesQuery = (
  { __typename?: 'Query' }
  & { catalogs: Array<(
    { __typename?: 'Catalog' }
    & CatalogOptionFragment
  )> }
);

export type CollectionNamesQueryVariables = Exact<{
  catalogId: Scalars['Int'];
}>;


export type CollectionNamesQuery = (
  { __typename?: 'Query' }
  & { catalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { collections: Array<(
      { __typename?: 'AMPCollection' }
      & CollectionBaseInfoFragment
    )> }
  )> }
);

export type CollectionsQueryVariables = Exact<{
  catalogId: Scalars['Int'];
}>;


export type CollectionsQuery = (
  { __typename?: 'Query' }
  & { catalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { collections: Array<(
      { __typename?: 'AMPCollection' }
      & CollectionFieldsFragment
    )> }
  )> }
);

export type CollectionsListQueryVariables = Exact<{
  catalogId: Scalars['Int'];
}>;


export type CollectionsListQuery = (
  { __typename?: 'Query' }
  & { catalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { collections: Array<(
      { __typename?: 'AMPCollection' }
      & CollectionListFieldsFragment
    )> }
  )> }
);

export type GetActionCountersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActionCountersQuery = (
  { __typename?: 'Query' }
  & { actionCounters: (
    { __typename?: 'ActionCounters' }
    & Pick<ActionCounters, 'activity' | 'assignedToMe'>
  ) }
);

export type GetActionsInRangeQueryVariables = Exact<{
  cursor: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  where: Maybe<ActionFilter>;
  category: Maybe<ActionCategory>;
}>;


export type GetActionsInRangeQuery = (
  { __typename?: 'Query' }
  & { getActionsConnection?: Maybe<(
    { __typename?: 'ActionConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ActionEdge' }
      & Pick<ActionEdge, 'cursor'>
      & { node: (
        { __typename?: 'Action' }
        & ActionFieldsFragment
      ) }
    )>>> }
  )> }
);

export type GetAlbumDataEvearaQueryVariables = Exact<{
  id: Scalars['Int'];
  type: Maybe<AlbumType>;
}>;


export type GetAlbumDataEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaAlbum?: Maybe<(
    { __typename?: 'AlbumDraftEveara' }
    & NewAlbumEvearaFragment
  )> }
);

export type GetAlbumEvearaQueryVariables = Exact<{
  releaseId: Maybe<Scalars['Float']>;
}>;


export type GetAlbumEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaAlbum: Array<Maybe<(
    { __typename?: 'AlbumEveara' }
    & AlbumEvearaDataFragment
  )>> }
);

export type GetAlbumRecordEvearaQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAlbumRecordEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaAlbum?: Maybe<(
    { __typename?: 'AlbumDraftEveara' }
    & NewAlbumEvearaFragment
  )> }
);

export type GetAuthorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthorsQuery = (
  { __typename?: 'Query' }
  & { authors: Array<(
    { __typename?: 'User' }
    & AuthorFragment
  )> }
);

export type GetAvailabilitiesEvearaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailabilitiesEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaAvailabilities: Array<Maybe<(
    { __typename?: 'EvearaAvailability' }
    & EvearaAvailabilityFragment
  )>> }
);

export type GetBeatsKeywordsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBeatsKeywordsQuery = (
  { __typename?: 'Query' }
  & { beatsKeywords: Array<(
    { __typename?: 'MetadataKeyword' }
    & BeatsKeywordFragment
  )> }
);

export type GetCatalogMastersQueryVariables = Exact<{
  catalogId: Scalars['Int'];
  offset: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetCatalogMastersQuery = (
  { __typename?: 'Query' }
  & { getCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'masterCount'>
    & { masters: Array<(
      { __typename?: 'AMPMaster' }
      & MasterFieldsFragment
    )> }
  )> }
);

export type GetCatalogMastersInfoOnlyQueryVariables = Exact<{
  catalogId: Scalars['Int'];
  offset: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetCatalogMastersInfoOnlyQuery = (
  { __typename?: 'Query' }
  & { getCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'masterCount'>
    & { masters: Array<(
      { __typename?: 'AMPMaster' }
      & MasterInfoFieldsFragment
    )> }
  )> }
);

export type GetCatalogProjectsQueryVariables = Exact<{
  catalogId: Scalars['Int'];
  offset: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetCatalogProjectsQuery = (
  { __typename?: 'Query' }
  & { getCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'projectCount'>
    & { projects: Array<(
      { __typename?: 'AMPProject' }
      & ProjectFieldsFragment
    )> }
  )> }
);

export type GetCatalogsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCatalogsQuery = (
  { __typename?: 'Query' }
  & { getCatalogs: Array<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { masters: Array<(
      { __typename?: 'AMPMaster' }
      & MasterFieldsFragment
    )>, projects: Array<(
      { __typename?: 'AMPProject' }
      & ProjectFieldsFragment
    )> }
    & ImportedBeatsFragment
    & TullyBeatsFragment
  )> }
);

export type GetCatalogsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCatalogsListQuery = (
  { __typename?: 'Query' }
  & { catalogsList: Array<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id' | 'name'>
  )> }
);

export type GetCollectionQueryVariables = Exact<{
  collectionId: Scalars['Int'];
}>;


export type GetCollectionQuery = (
  { __typename?: 'Query' }
  & { getCollections: Array<(
    { __typename?: 'AMPCollection' }
    & CollectionFieldsFragment
  )> }
);

export type GetCollectionsAvailableForNewDistributionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCollectionsAvailableForNewDistributionQuery = (
  { __typename?: 'Query' }
  & { getCollectionsAvailableForNewDistribution: Array<(
    { __typename?: 'AMPCollection' }
    & Pick<AmpCollection, 'id' | 'title'>
  )> }
);

export type GetContentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContentTypesQuery = (
  { __typename?: 'Query' }
  & { contentTypes: Array<(
    { __typename?: 'SearchContentType' }
    & ContentTypeFragment
  )> }
);

export type GetContractsSummariesQueryVariables = Exact<{
  ids: Maybe<Array<Scalars['Int']>>;
}>;


export type GetContractsSummariesQuery = (
  { __typename?: 'Query' }
  & { contractsSummaries: Array<(
    { __typename?: 'Contract' }
    & UploadedContractFieldsFragment
  )> }
);

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = (
  { __typename?: 'Query' }
  & { countries: Array<(
    { __typename?: 'Country' }
    & CountryFragment
  )> }
);

export type GetCountriesEvearaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaCountries: Array<Maybe<(
    { __typename?: 'EvearaCountry' }
    & EvearaCountryFragment
  )>> }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { getCurrentUser: (
    { __typename?: 'User' }
    & UserFieldsFragment
  ), getCatalogs: Array<(
    { __typename?: 'Catalog' }
    & CatalogNameAndUserFragment
  )>, resources: Array<(
    { __typename?: 'Resources' }
    & ResourceFieldsFragment
  )>, features: (
    { __typename?: 'AMPFeatues' }
    & Pick<AmpFeatues, 'userManagement'>
  ) }
);

export type GetDistributionRecordsConnectionQueryVariables = Exact<{
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  filters: GetDistributionRecordsConnectionFiltersInput;
}>;


export type GetDistributionRecordsConnectionQuery = (
  { __typename?: 'Query' }
  & { distributionRecordsConnection: (
    { __typename?: 'GetDistributionRecordConnectionsResult' }
    & { edges: Array<(
      { __typename?: 'DistributionRecordEdge' }
      & DistributionRecordEdgeFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) }
  ) }
);

export type GetDistributionRecordsForReleasesTabQueryVariables = Exact<{
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  filters: GetDistributionRecordsConnectionFiltersInput;
}>;


export type GetDistributionRecordsForReleasesTabQuery = (
  { __typename?: 'Query' }
  & { distributionRecordsForReleasesTab: (
    { __typename?: 'GetDistributionRecordConnectionsResult' }
    & { edges: Array<(
      { __typename?: 'DistributionRecordEdge' }
      & Pick<DistributionRecordEdge, 'cursor'>
      & { node: (
        { __typename?: 'DistributionRecord' }
        & DistributionRecordDataForReleasesTabFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) }
  ) }
);

export type GetDraftAlbumEvearaQueryVariables = Exact<{
  id: Maybe<Scalars['Float']>;
  albumType: Maybe<AlbumTypeEveara>;
}>;


export type GetDraftAlbumEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaDraftAlbum?: Maybe<(
    { __typename?: 'AlbumDraftEveara' }
    & NewAlbumEvearaFragment
  )> }
);

export type GetDraftTrackEvearaQueryVariables = Exact<{
  id: Maybe<Scalars['Float']>;
  albumType: Maybe<AlbumTypeEveara>;
}>;


export type GetDraftTrackEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaDraftTrack: Array<Maybe<(
    { __typename?: 'TrackDraftEveara' }
    & NewTrackEvearaFragment
  )>> }
);

export type GetDropsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDropsQuery = (
  { __typename?: 'Query' }
  & { drops: Array<(
    { __typename?: 'Drop' }
    & DropFragment
  )> }
);

export type GetEvearaArtistsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEvearaArtistsQuery = (
  { __typename?: 'Query' }
  & { evearaArtists: Array<Maybe<(
    { __typename?: 'EvearaArtist' }
    & EvearaArtistFragment
  )>> }
);

export type GetEvearaLabelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEvearaLabelsQuery = (
  { __typename?: 'Query' }
  & { evearaLabels: Array<Maybe<(
    { __typename?: 'EvearaLabel' }
    & EvearaLabelFragment
  )>> }
);

export type GetGenresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGenresQuery = (
  { __typename?: 'Query' }
  & { getGenres: Array<(
    { __typename?: 'Genre' }
    & GenreFieldsFragment
  )> }
);

export type GetGenresEvearaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGenresEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaGenres: Array<Maybe<(
    { __typename?: 'EvearaGenre' }
    & EvearaGenreFragment
  )>> }
);

export type GetImportedBeatsQueryVariables = Exact<{
  cursor: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  keywords: Maybe<Array<Scalars['String']>>;
  catalogIds: Maybe<Array<Scalars['Int']>>;
}>;


export type GetImportedBeatsQuery = (
  { __typename?: 'Query' }
  & { beats: (
    { __typename?: 'Assets' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ), edges?: Maybe<Array<(
      { __typename?: 'AssetsEdge' }
      & Pick<AssetsEdge, 'cursor'>
      & { node: (
        { __typename?: 'AMPAsset' }
        & ImportedBeatItemFragment
      ) }
    )>> }
  ) }
);

export type GetInvitedUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvitedUsersQuery = (
  { __typename?: 'Query' }
  & { invitedUsers: Array<(
    { __typename?: 'UserInviteModel' }
    & InvitedUserFragment
  )> }
);

export type GetLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLanguagesQuery = (
  { __typename?: 'Query' }
  & { languages: Array<(
    { __typename?: 'Language' }
    & LanguageFragment
  )> }
);

export type GetLanguagesEvearaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLanguagesEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaLanguages: Array<Maybe<(
    { __typename?: 'EvearaLanguage' }
    & EvearaLanguageFragment
  )>> }
);

export type GetLastActionsQueryVariables = Exact<{
  where: Maybe<ActionFilter>;
  category: Maybe<ActionCategory>;
}>;


export type GetLastActionsQuery = (
  { __typename?: 'Query' }
  & { getActionsConnection?: Maybe<(
    { __typename?: 'ActionConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ActionEdge' }
      & { node: (
        { __typename?: 'Action' }
        & ActionFieldsFragment
      ) }
    )>>> }
  )> }
);

export type GetMasterQueryVariables = Exact<{
  catalogId: Scalars['Int'];
  masterId: Scalars['Int'];
}>;


export type GetMasterQuery = (
  { __typename?: 'Query' }
  & { getCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { masters: Array<(
      { __typename?: 'AMPMaster' }
      & MasterFieldsFragment
    )> }
  )> }
);

export type GetMasterArtworkQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMasterArtworkQuery = (
  { __typename?: 'Query' }
  & { master?: Maybe<(
    { __typename?: 'AMPMaster' }
    & { tullyMetadata?: Maybe<(
      { __typename?: 'TullyMetadata' }
      & { artwork?: Maybe<(
        { __typename?: 'AMPFile' }
        & Pick<AmpFile, 'id'>
        & { mediaUrl: AmpFile['media_url'] }
      )> }
    )> }
  )> }
);

export type GetMasterAssetsQueryVariables = Exact<{
  masterId: Scalars['Int'];
}>;


export type GetMasterAssetsQuery = (
  { __typename?: 'Query' }
  & { getMaster?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id'>
    & { assets: Array<(
      { __typename?: 'AMPAsset' }
      & AssetFieldsFragment
    )> }
  )> }
);

export type GetMasterAssetsByTypeQueryVariables = Exact<{
  masterId: Scalars['Int'];
  assets: Array<AssetType>;
}>;


export type GetMasterAssetsByTypeQuery = (
  { __typename?: 'Query' }
  & { getMaster?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id'>
    & { assets: Array<(
      { __typename?: 'AMPAsset' }
      & AssetFieldsFragment
    )> }
  )> }
);

export type GetMasterByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMasterByIdQuery = (
  { __typename?: 'Query' }
  & { getMaster?: Maybe<(
    { __typename?: 'AMPMaster' }
    & MasterFieldsFragment
  )> }
);

export type GetMasterContractsQueryVariables = Exact<{
  masterId: Scalars['Int'];
}>;


export type GetMasterContractsQuery = (
  { __typename?: 'Query' }
  & { getMaster?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id'>
    & { documents: Array<(
      { __typename?: 'AMPAsset' }
      & AssetFieldsFragment
    )>, splitSheet: Array<(
      { __typename?: 'Contract' }
      & SplitSheetFieldsFragment
    )>, uploadedContracts: Array<(
      { __typename?: 'Contract' }
      & UploadedContractFieldsFragment
    )> }
  )> }
);

export type GetMasterFilesOnlyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMasterFilesOnlyQuery = (
  { __typename?: 'Query' }
  & { master?: Maybe<(
    { __typename?: 'AMPMaster' }
    & MasterInfoFieldsFragment
    & MasterFilesFieldFragment
  )> }
);

export type GetMasterFullMetaQueryVariables = Exact<{
  masterId: Scalars['Int'];
}>;


export type GetMasterFullMetaQuery = (
  { __typename?: 'Query' }
  & { getMaster?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id' | 'title'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'email'>
    ), tully_metadata?: Maybe<(
      { __typename?: 'TullyMetadata' }
      & TullyMetadataFieldsFragment
    )>, masterClean: Array<(
      { __typename?: 'AMPAsset' }
      & AssetMetaFieldsFragment
    )>, masterExplicit: Array<(
      { __typename?: 'AMPAsset' }
      & AssetMetaFieldsFragment
    )>, stems: Array<(
      { __typename?: 'AMPAsset' }
      & AssetMetaFieldsFragment
    )>, RA360: Array<(
      { __typename?: 'AMPAsset' }
      & AssetMetaFieldsFragment
    )> }
  )> }
);

export type GetMasterMetaQueryVariables = Exact<{
  catalogId: Scalars['Int'];
  masterId: Scalars['Int'];
}>;


export type GetMasterMetaQuery = (
  { __typename?: 'Query' }
  & { getCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { masters: Array<(
      { __typename?: 'AMPMaster' }
      & Pick<AmpMaster, 'id'>
      & { masterClean: Array<(
        { __typename?: 'AMPAsset' }
        & AssetMetaFieldsFragment
      )>, masterExplicit: Array<(
        { __typename?: 'AMPAsset' }
        & AssetMetaFieldsFragment
      )>, stems: Array<(
        { __typename?: 'AMPAsset' }
        & AssetMetaFieldsFragment
      )>, RA360: Array<(
        { __typename?: 'AMPAsset' }
        & AssetMetaFieldsFragment
      )> }
    )> }
  )> }
);

export type GetMasterTullyMetadataQueryVariables = Exact<{
  masterId: Scalars['Int'];
}>;


export type GetMasterTullyMetadataQuery = (
  { __typename?: 'Query' }
  & { getMaster?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id' | 'title'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'email'>
    ), tully_metadata?: Maybe<(
      { __typename?: 'TullyMetadata' }
      & TullyMetadataFieldsFragment
    )> }
  )> }
);

export type GetMasterWithAssetsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMasterWithAssetsQuery = (
  { __typename?: 'Query' }
  & { getMaster?: Maybe<(
    { __typename?: 'AMPMaster' }
    & MasterFieldsFragment
  )> }
);

export type GetMastersAvailableForNewDistributionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMastersAvailableForNewDistributionQuery = (
  { __typename?: 'Query' }
  & { getMastersAvailableForNewDistribution: Array<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id' | 'title'>
  )> }
);

export type GetMastersByIdQueryVariables = Exact<{
  ids: Array<Scalars['Int']>;
}>;


export type GetMastersByIdQuery = (
  { __typename?: 'Query' }
  & { getMasters?: Maybe<Array<Maybe<(
    { __typename?: 'AMPMaster' }
    & MasterFieldsFragment
  )>>> }
);

export type MenuResourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type MenuResourcesQuery = (
  { __typename?: 'Query' }
  & { resources: Array<Maybe<(
    { __typename?: 'Resources' }
    & MenuResourceFieldsFragment
  )>> }
);

export type GetNewImportedBeatQueryVariables = Exact<{
  catalogId: Scalars['Int'];
}>;


export type GetNewImportedBeatQuery = (
  { __typename?: 'Query' }
  & { getCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { importedBeats: Array<(
      { __typename?: 'AMPAsset' }
      & AssetFieldsFragment
    )> }
  )> }
);

export type GetNewMasterQueryVariables = Exact<{
  catalogId: Scalars['Int'];
  newMasterId: Scalars['Int'];
}>;


export type GetNewMasterQuery = (
  { __typename?: 'Query' }
  & { getCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { masters: Array<(
      { __typename?: 'AMPMaster' }
      & MasterFieldsFragment
    )> }
  )> }
);

export type GetNewProjectQueryVariables = Exact<{
  catalogId: Scalars['Int'];
  newProjectId: Scalars['Int'];
}>;


export type GetNewProjectQuery = (
  { __typename?: 'Query' }
  & { getCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { projects: Array<(
      { __typename?: 'AMPProject' }
      & ProjectFieldsFragment
    )> }
  )> }
);

export type GetOneCatalogQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOneCatalogQuery = (
  { __typename?: 'Query' }
  & { getCatalog?: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & ImportedBeatsFragment
    & TullyBeatsFragment
  )> }
);

export type GetOutletsEvearaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOutletsEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaOutlets: Array<(
    { __typename?: 'EvearaOutlet' }
    & EvearaOutletFragment
  )> }
);

export type GetParticipantsEvearaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetParticipantsEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaParticipants: Array<Maybe<(
    { __typename?: 'EvearaParticipant' }
    & EvearaParticipantFragment
  )>> }
);

export type GetPlayerAssetQueryVariables = Exact<{
  assetId: Scalars['Int'];
}>;


export type GetPlayerAssetQuery = (
  { __typename?: 'Query' }
  & { playerAsset?: Maybe<(
    { __typename?: 'AMPAsset' }
    & PlayerAssetFragment
  )> }
);

export type GetProjectAssetsQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type GetProjectAssetsQuery = (
  { __typename?: 'Query' }
  & { getProject?: Maybe<(
    { __typename?: 'AMPProject' }
    & Pick<AmpProject, 'id'>
    & { assets: Array<(
      { __typename?: 'AMPAsset' }
      & AssetFieldsFragment
    )> }
  )> }
);

export type GetProjectMetaQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type GetProjectMetaQuery = (
  { __typename?: 'Query' }
  & { getProject?: Maybe<(
    { __typename?: 'AMPProject' }
    & Pick<AmpProject, 'id' | 'created_at' | 'updated_at'>
    & { meta?: Maybe<(
      { __typename?: 'ProjectMeta' }
      & Pick<ProjectMeta, 'title' | 'artist'>
    )>, assets: Array<(
      { __typename?: 'AMPAsset' }
      & AssetFieldsFragment
    )>, lyrics: Array<(
      { __typename?: 'AMPLyrics' }
      & LyricsFieldsFragment
    )> }
  )> }
);

export type GetProjectWithAssetsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProjectWithAssetsQuery = (
  { __typename?: 'Query' }
  & { getProject?: Maybe<(
    { __typename?: 'AMPProject' }
    & ProjectFieldsFragment
  )> }
);

export type GetReleasesEvearaQueryVariables = Exact<{
  releasesData: Maybe<ReleaseEvearaInput>;
}>;


export type GetReleasesEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaReleases: (
    { __typename?: 'ReleasesEveara' }
    & { edges?: Maybe<Array<(
      { __typename?: 'ReleasesEvearaEdge' }
      & { node: (
        { __typename?: 'AlbumEveara' }
        & EvearaReleaseFragment
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) }
  ) }
);

export type GetReleasesEvearaDraftQueryVariables = Exact<{
  releasesData: Maybe<ReleaseEvearaInput>;
}>;


export type GetReleasesEvearaDraftQuery = (
  { __typename?: 'Query' }
  & { evearaReleasesDraft: (
    { __typename?: 'ReleasesEveara' }
    & { edges?: Maybe<Array<(
      { __typename?: 'ReleasesEvearaEdge' }
      & { node: (
        { __typename?: 'AlbumEveara' }
        & EvearaReleaseFragment
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) }
  ) }
);

export type GetRolesEvearaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaRoles: Array<Maybe<(
    { __typename?: 'EvearaRole' }
    & EvearaRoleFragment
  )>> }
);

export type GetSavedSearchesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSavedSearchesQuery = (
  { __typename?: 'Query' }
  & { savedSearches?: Maybe<Array<(
    { __typename?: 'SavedSearch' }
    & SavedSearchFragment
  )>> }
);

export type GetSplitsheetQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSplitsheetQuery = (
  { __typename?: 'Query' }
  & { getContract?: Maybe<(
    { __typename?: 'Contract' }
    & SplitSheetFieldsFragment
  )> }
);

export type GetSubscribedSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscribedSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { subscribedSubscriptions: Array<(
    { __typename?: 'AccountSubscription' }
    & AccountSubscriptionFragment
  )> }
);

export type GetSubscriptionEvearaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionEvearaQuery = (
  { __typename?: 'Query' }
  & { evearaSubscription: Array<Maybe<(
    { __typename?: 'EvearaSubscription' }
    & EvearaSubscriptionFragment
  )>> }
);

export type GetMasterTitleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMasterTitleQuery = (
  { __typename?: 'Query' }
  & { getMaster?: Maybe<(
    { __typename?: 'AMPMaster' }
    & Pick<AmpMaster, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { tully_metadata?: Maybe<(
      { __typename?: 'TullyMetadata' }
      & Pick<TullyMetadata, 'id' | 'track_title'>
    )> }
  )> }
);

export type GetProjectTitleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProjectTitleQuery = (
  { __typename?: 'Query' }
  & { getProject?: Maybe<(
    { __typename?: 'AMPProject' }
    & Pick<AmpProject, 'id' | 'title' | 'created_at' | 'updated_at'>
  )> }
);

export type GetAssetTitleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAssetTitleQuery = (
  { __typename?: 'Query' }
  & { getAsset?: Maybe<(
    { __typename?: 'AMPAsset' }
    & Pick<AmpAsset, 'id' | 'title' | 'created_at' | 'updated_at'>
  )> }
);

export type GetCollectionTitleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCollectionTitleQuery = (
  { __typename?: 'Query' }
  & { getCollection?: Maybe<(
    { __typename?: 'AMPCollection' }
    & Pick<AmpCollection, 'id' | 'title' | 'created_at' | 'updated_at'>
  )> }
);

export type GetTitleBlacklistedWordsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTitleBlacklistedWordsQuery = (
  { __typename?: 'Query' }
  & { titleBlacklistedWords: Query['getTitleBlacklistedWords'] }
);

export type GetTullyBeatQueryVariables = Exact<{
  tullyBeatId: Scalars['Int'];
}>;


export type GetTullyBeatQuery = (
  { __typename?: 'Query' }
  & { tullyBeat: (
    { __typename?: 'TullyBeatResult' }
    & TullyBeatItemFragment
  ) }
);

export type GetTullyBeatsQueryVariables = Exact<{
  cursor: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  keywords: Maybe<Array<Scalars['String']>>;
}>;


export type GetTullyBeatsQuery = (
  { __typename?: 'Query' }
  & { beats: (
    { __typename?: 'TullyBeats' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ), edges?: Maybe<Array<(
      { __typename?: 'TullyBeatsEdge' }
      & Pick<TullyBeatsEdge, 'cursor'>
      & { node: (
        { __typename?: 'TullyBeatResult' }
        & TullyBeatItemFragment
      ) }
    )>> }
  ) }
);

export type GetTullyMetadataArtistsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTullyMetadataArtistsQuery = (
  { __typename?: 'Query' }
  & { tullyMetadataArtists: Array<(
    { __typename?: 'TullyMetadataArtist' }
    & TullyMetadataArtistFragment
  )> }
);

export type GetTullyMetadataParticipantRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTullyMetadataParticipantRolesQuery = (
  { __typename?: 'Query' }
  & { tullyMetadataParticipantRoles: Array<(
    { __typename?: 'TullyMetadataParticipantRole' }
    & TullyMetadataParticipantRoleFragment
  )> }
);

export type GetTullyMetadataParticipantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTullyMetadataParticipantsQuery = (
  { __typename?: 'Query' }
  & { tullyMetadataParticipants: Array<(
    { __typename?: 'TullyMetadataParticipant' }
    & TullyMetadataParticipantFragment
  )> }
);

export type GetUploadedContractQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUploadedContractQuery = (
  { __typename?: 'Query' }
  & { getContract?: Maybe<(
    { __typename?: 'Contract' }
    & UploadedContractFieldsFragment
  )> }
);

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export const UserMetaFieldsFragmentDoc = gql`
    fragment UserMetaFields on UMUserMeta {
  photo_url
}
    `;
export const UmRoleFieldsFragmentDoc = gql`
    fragment UmRoleFields on UMRole {
  id
  name
  account
  created_at
  updated_at
  catalog_details @type(name: "UMRoleCatalog") {
    catalog_id
    name
    permissions @type(name: "CatalogPermissions") {
      assigned
      privileges @type(name: "PrivilegeRec") {
        slug
        title
        allowed
      }
      content @type(name: "ContentRec") {
        slug
        title
        allowed
        type_id
        category
      }
    }
  }
  assigned_users @type(name: "UMRoleUser") {
    id
    email
    first_name
    last_name
    meta @type(name: "UMUserMeta") {
      ...UserMetaFields
    }
  }
}
    ${UserMetaFieldsFragmentDoc}`;
export const UmUserFieldsFragmentDoc = gql`
    fragment UMUserFields on UMUser {
  id
  name
  email
  catalog @type(name: "Catalog") {
    id
    name
  }
  role @type(name: "UserRole") {
    id
    name
  }
  is_admin
  is_guest
  is_artist
  status
  meta @type(name: "UMUserMeta") {
    ...UserMetaFields
  }
}
    ${UserMetaFieldsFragmentDoc}`;
export const SubscriptionMetaFragmentDoc = gql`
    fragment SubscriptionMeta on SubscriptionMeta {
  iosTransactionId: ios_transaction_id
  lastTransactionPeriodEnd: last_transaction_period_end
  lastTransactionPeriodStart: last_transaction_period_start
  stripeSubscriptionId: stripe_subscription_id
  subscriptionId: subscription_id
}
    `;
export const AccountSubscriptionFragmentDoc = gql`
    fragment AccountSubscription on AccountSubscription {
  displayName: display_name
  amount
  exclusive
  isVisible: is_visible
  name
  renewal
  status
  type
  upgradeIndex: upgrade_index
  meta {
    ...SubscriptionMeta
  }
}
    ${SubscriptionMetaFragmentDoc}`;
export const ActionAuthorFieldsFragmentDoc = gql`
    fragment ActionAuthorFields on User {
  id
  profile {
    avatar
  }
  firstName: first_name
  lastName: last_name
}
    `;
export const ActionFieldsFragmentDoc = gql`
    fragment ActionFields on Action {
  author: subject {
    ...ActionAuthorFields
  }
  authorEmail: subject_email
  contentTypeId: search_content_type_id
  catalogId: catalog_id
  id: id
  isSeen: is_seen
  object {
    ...ActionAuthorFields
  }
  objectId: object_id
  parentId: parent_id
  catalogName: catalog_name
  isExist: exists
  parentName: parent_name
  actionType: action_type
  createdAt: created_at
  parentContentType: parent_search_content_type_id
  title
  collectionCover: image_file_uri
  collectionType: collection_type
}
    ${ActionAuthorFieldsFragmentDoc}`;
export const Sony360FileFragmentDoc = gql`
    fragment Sony360File on Sony360File {
  id: asset_id
  title: filename
  spatial_type
  sony360_file_type
}
    `;
export const LanguageFragmentDoc = gql`
    fragment Language on Language {
  id
  name
  code
}
    `;
export const CountryFragmentDoc = gql`
    fragment Country on Country {
  id
  name
  code
}
    `;
export const TullyPersonMetadataFieldsFragmentDoc = gql`
    fragment TullyPersonMetadataFields on TullyPersonMetadata {
  id
  name
  role
  affiliation
  appleId: apple_id
  spotifyId: spotify_id
  ownership
}
    `;
export const TullyMetadataFieldsFragmentDoc = gql`
    fragment TullyMetadataFields on TullyMetadata {
  id
  imported
  last_edited
  artist
  artist_name
  spotify_artist_id
  artist_affiliation
  remixer
  track_title
  album_name
  created_at
  updated_at
  ep_name
  publisher_name
  publisher_ownership
  publisher_affiliation
  upc_ean_code
  isrc
  genre
  tempo
  language_obj {
    ...Language
  }
  country_obj {
    ...Country
  }
  mix_variant
  release_date
  sale_start_date
  has_third_party_publisher
  has_ownership
  artwork {
    id
    media_url
    file_name
  }
  collaborators {
    ...TullyPersonMetadataFields
  }
}
    ${LanguageFragmentDoc}
${CountryFragmentDoc}
${TullyPersonMetadataFieldsFragmentDoc}`;
export const EvearaTrackFragmentDoc = gql`
    fragment EvearaTrack on TrackDataEveara {
  id: asset_id
  title: name
  isrc
  iswc
  artists
  genre
  featuredArtists
  language
  availability
  explicit
  lyrics
  sony360 {
    ...Sony360File
  }
  meta {
    ...TullyMetadataFields
  }
}
    ${Sony360FileFragmentDoc}
${TullyMetadataFieldsFragmentDoc}`;
export const AlbumEvearaFragmentDoc = gql`
    fragment AlbumEveara on AlbumDataEveara {
  name
  artists {
    name
    country_code
  }
  eanupc
  labelId
  coverImage {
    url
    extension
  }
  tracks {
    ...EvearaTrack
  }
  originalReleaseDate
  subscriptionId
  productType
}
    ${EvearaTrackFragmentDoc}`;
export const AlbumEvearaDataFragmentDoc = gql`
    fragment AlbumEvearaData on AlbumEveara {
  name
  releaseId
}
    `;
export const AudioMetaFieldsFragmentDoc = gql`
    fragment AudioMetaFields on AudioMetaSuccess {
  is360RA: is_360ra
  format {
    formatName: format_name
    bitRate: bit_rate
    bitsPerSample: bit_rate
    duration
    tags {
      title
      artist
      composer
      performers: TOPE
      genre
      year: date
    }
  }
}
    `;
export const AssetMetaFieldsFragmentDoc = gql`
    fragment AssetMetaFields on AMPAsset {
  id
  title
  type
  created_at
  updated_at
  meta {
    ...AudioMetaFields
  }
}
    ${AudioMetaFieldsFragmentDoc}`;
export const AuthorFragmentDoc = gql`
    fragment Author on User {
  email
  first_name
  id
  last_name
  meta
}
    `;
export const BeatsKeywordFragmentDoc = gql`
    fragment BeatsKeyword on MetadataKeyword {
  id
  label
}
    `;
export const CatalogNameAndUserFragmentDoc = gql`
    fragment CatalogNameAndUser on Catalog {
  id
  name
  user {
    id
  }
}
    `;
export const CatalogOptionFragmentDoc = gql`
    fragment CatalogOption on Catalog {
  id
  name
  userId: user_id
}
    `;
export const CollectionBaseInfoFragmentDoc = gql`
    fragment CollectionBaseInfo on AMPCollection {
  id
  title
  year
  created_at
  updated_at
  collectionType: type_id
  artist_name
}
    `;
export const CollectionListFieldsFragmentDoc = gql`
    fragment CollectionListFields on AMPCollection {
  ...CollectionBaseInfo
  master_positions {
    master_id
  }
  cover_art {
    id
    media_url
    file_name
  }
}
    ${CollectionBaseInfoFragmentDoc}`;
export const ContentTypeFragmentDoc = gql`
    fragment ContentType on SearchContentType {
  id
  category
  categoryName: category_name
  directory
  displayLabel: display_label
  type
  searchResultPriority: search_result_priority
}
    `;
export const DistributionRecordDataForReleasesTabFragmentDoc = gql`
    fragment DistributionRecordDataForReleasesTab on DistributionRecord {
  id
  type
  distributionFinishedDate
  takingDownFinishedDate
  collection {
    title
    cover_art {
      media_url
    }
  }
  master {
    tully_metadata {
      track_title
      artwork {
        media_url
      }
    }
  }
  tracks {
    id
  }
}
    `;
export const MasterCustomizeFieldsFragmentDoc = gql`
    fragment MasterCustomizeFields on CustomizeMaster {
  realityAudio
  stemFiles
  contracts
  metadata
  media
  userId
  isAdmin
}
    `;
export const CustomizeMasterMetaFragmentDoc = gql`
    fragment CustomizeMasterMeta on MasterMeta {
  customizeMaster {
    ...MasterCustomizeFields
  }
}
    ${MasterCustomizeFieldsFragmentDoc}`;
export const MasterInfoFieldsFragmentDoc = gql`
    fragment MasterInfoFields on AMPMaster {
  id
  title
  updated_at
  locked: is_locked
  catalog_id
  fire_node_id
  meta {
    ...CustomizeMasterMeta
  }
  masterFilesInfo: assets(where: {type: {in: [MASTER_CLEAN, MASTER_EXPLICIT]}}) {
    id
    type
    type_id
  }
}
    ${CustomizeMasterMetaFragmentDoc}`;
export const AssetMetaFragmentDoc = gql`
    fragment AssetMeta on AudioMeta {
  ... on AudioMetaSuccess {
    is360Ra: is_360ra
  }
}
    `;
export const AssetFieldsFragmentDoc = gql`
    fragment AssetFields on AMPAsset {
  id
  title
  type
  created_at
  updated_at
  meta {
    ...AssetMeta
  }
  master {
    id
    locked: is_locked
  }
  project {
    id
    locked: is_locked
  }
  type_id
  catalog_id
  mediaCover: media_cover {
    filePath: file_path
  }
  file {
    media_url
    file_name
  }
}
    ${AssetMetaFragmentDoc}`;
export const SplitSheetPartyFieldsFragmentDoc = gql`
    fragment SplitSheetPartyFields on ContractParty {
  id
  name
  role
  role_label
  contact
  accepted
  can_see_others
  metadata {
    __typename
    ... on SplitPartyMeta {
      affiliation
      ownership
    }
  }
}
    `;
export const SplitSheetFieldsFragmentDoc = gql`
    fragment SplitSheetFields on Contract {
  id
  type
  title
  date
  state
  created_at
  metadata {
    aka
  }
  parties {
    ...SplitSheetPartyFields
  }
  master {
    id
    locked: is_locked
    catalog {
      id
    }
  }
}
    ${SplitSheetPartyFieldsFragmentDoc}`;
export const UploadedContractPartyFieldsFragmentDoc = gql`
    fragment UploadedContractPartyFields on ContractParty {
  id
  name
  role_label
  contact
  accepted
  metadata {
    __typename
    ... on UploadedPartyMeta {
      advance
      royalty_label
      royalty
      recoup
      recoup_label
      publishing
    }
  }
}
    `;
export const UploadedContractFieldsFragmentDoc = gql`
    fragment UploadedContractFields on Contract {
  id
  type
  title
  created_at
  metadata {
    description
  }
  parties {
    ...UploadedContractPartyFields
  }
  file {
    id
    media_url
    file_name
  }
  master {
    id
    locked: is_locked
    catalog {
      id
    }
  }
  updated_at
  state
}
    ${UploadedContractPartyFieldsFragmentDoc}`;
export const MasterFieldsFragmentDoc = gql`
    fragment MasterFields on AMPMaster {
  ...MasterInfoFields
  assets {
    ...AssetFields
  }
  splitSheet: contracts(where: {type: {exact: SPLIT_SHEET_AGREEMENT}}) {
    ...SplitSheetFields
  }
  uploadedContracts: contracts(where: {type: {in: [UPLOADED_CONTRACT, LICENSE_AGREEMENT, PRODUCER_ARTIST_AGREEMENT, SIDE_ARTIST_AGREEMENT, CLEARANCE_AGREEMENT]}}) {
    ...UploadedContractFields
  }
}
    ${MasterInfoFieldsFragmentDoc}
${AssetFieldsFragmentDoc}
${SplitSheetFieldsFragmentDoc}
${UploadedContractFieldsFragmentDoc}`;
export const CollectionFieldsFragmentDoc = gql`
    fragment CollectionFields on AMPCollection {
  ...CollectionBaseInfo
  master_positions {
    master_id
  }
  cover_art {
    id
    media_url
    file_name
  }
  ordered_masters {
    ...MasterFields
  }
}
    ${CollectionBaseInfoFragmentDoc}
${MasterFieldsFragmentDoc}`;
export const CatalogFieldsForDistributionFragmentDoc = gql`
    fragment CatalogFieldsForDistribution on Catalog {
  id
  name
  user {
    first_name
    last_name
  }
}
    `;
export const GenreFieldsFragmentDoc = gql`
    fragment GenreFields on Genre {
  id
  name
}
    `;
export const TullyMetadataParticipantFragmentDoc = gql`
    fragment TullyMetadataParticipant on TullyMetadataParticipant {
  id
  name
  eveara_id
}
    `;
export const TullyMetadataParticipantRoleFragmentDoc = gql`
    fragment TullyMetadataParticipantRole on TullyMetadataParticipantRole {
  name
  value
}
    `;
export const TullyMetadataParticipantInTullyMetadataFragmentDoc = gql`
    fragment TullyMetadataParticipantInTullyMetadata on TullyMetadataParticipantInTullyMetadata {
  participant {
    ...TullyMetadataParticipant
  }
  role {
    ...TullyMetadataParticipantRole
  }
  payout
}
    ${TullyMetadataParticipantFragmentDoc}
${TullyMetadataParticipantRoleFragmentDoc}`;
export const TullyMetadataArtistFragmentDoc = gql`
    fragment TullyMetadataArtist on TullyMetadataArtist {
  id
  name
  country {
    ...Country
  }
  eveara_id
}
    ${CountryFragmentDoc}`;
export const DistributionRecordTrackPreviewFragmentDoc = gql`
    fragment DistributionRecordTrackPreview on DistributionTrackPreview {
  startAt
  duration
}
    `;
export const DistributionRecordTrackFragmentDoc = gql`
    fragment DistributionRecordTrack on DistributionRecordTrack {
  id
  distributeClean
  distributeExplicit
  master {
    ...MasterFields
    tully_metadata {
      ...TullyMetadataFields
      genre_obj {
        ...GenreFields
      }
      participants {
        ...TullyMetadataParticipantInTullyMetadata
      }
      artists {
        ...TullyMetadataArtist
      }
      featured_artists_objs {
        ...TullyMetadataArtist
      }
    }
    catalog {
      ...CatalogFieldsForDistribution
    }
  }
  preview {
    ...DistributionRecordTrackPreview
  }
  isForStreaming
  isForDownloading
  iswc
  lyrics
  createdAt
  updatedAt
  cleanVersionEvearaId
  explicitVersionEvearaId
}
    ${MasterFieldsFragmentDoc}
${TullyMetadataFieldsFragmentDoc}
${GenreFieldsFragmentDoc}
${TullyMetadataParticipantInTullyMetadataFragmentDoc}
${TullyMetadataArtistFragmentDoc}
${CatalogFieldsForDistributionFragmentDoc}
${DistributionRecordTrackPreviewFragmentDoc}`;
export const DistributionRecordOutletDetailsFragmentDoc = gql`
    fragment DistributionRecordOutletDetails on DistributionRecordOutletDetails {
  id
  distributorOutletId
  preSalesDate
  startReleaseDate
  endReleaseDate
  createdAt
  updatedAt
}
    `;
export const DistributionRecordFragmentDoc = gql`
    fragment DistributionRecord on DistributionRecord {
  id
  type
  status
  artistCountry {
    ...Country
  }
  codeType
  code
  distributeToFutureOutlets
  collection {
    ...CollectionFields
    cover_art {
      id
      media_url
      file_name
      size
      resolution {
        width
        height
      }
    }
    catalog {
      ...CatalogFieldsForDistribution
    }
  }
  master {
    ...MasterFields
    tully_metadata {
      ...TullyMetadataFields
      artwork {
        id
        media_url
        file_name
        size
        resolution {
          width
          height
        }
      }
    }
    catalog {
      ...CatalogFieldsForDistribution
    }
  }
  tracks {
    ...DistributionRecordTrack
  }
  outlets {
    ...DistributionRecordOutletDetails
  }
  createdAt
  updatedAt
  evearaReleaseId
  takingDownFinishedDate
}
    ${CountryFragmentDoc}
${CollectionFieldsFragmentDoc}
${CatalogFieldsForDistributionFragmentDoc}
${MasterFieldsFragmentDoc}
${TullyMetadataFieldsFragmentDoc}
${DistributionRecordTrackFragmentDoc}
${DistributionRecordOutletDetailsFragmentDoc}`;
export const DistributionRecordEdgeFragmentDoc = gql`
    fragment DistributionRecordEdge on DistributionRecordEdge {
  cursor
  node {
    ...DistributionRecord
  }
}
    ${DistributionRecordFragmentDoc}`;
export const DropFragmentDoc = gql`
    fragment Drop on Drop {
  id
  name
  artist_name
  cover_file_uri
  close_date
  status
  sub_status
  date_created
  order_id
}
    `;
export const EvearaArtistFragmentDoc = gql`
    fragment EvearaArtist on EvearaArtist {
  id
  isActive
  name
  isRemovable: removable
}
    `;
export const EvearaAvailabilityFragmentDoc = gql`
    fragment EvearaAvailability on EvearaAvailability {
  id
  name
}
    `;
export const EvearaCountryStateFragmentDoc = gql`
    fragment EvearaCountryState on EvearaCountryState {
  StateName
  stateCode
}
    `;
export const EvearaCountryFragmentDoc = gql`
    fragment EvearaCountry on EvearaCountry {
  countryCode
  countryName
  state {
    ...EvearaCountryState
  }
}
    ${EvearaCountryStateFragmentDoc}`;
export const EvearaGenreFragmentDoc = gql`
    fragment EvearaGenre on EvearaGenre {
  id
  name
}
    `;
export const EvearaLabelFragmentDoc = gql`
    fragment EvearaLabel on EvearaLabel {
  labelId
  isActive
  labelName
  isRemovable: removable
}
    `;
export const EvearaLanguageFragmentDoc = gql`
    fragment EvearaLanguage on EvearaLanguage {
  languageCode
  languageName
}
    `;
export const EvearaOutletFragmentDoc = gql`
    fragment EvearaOutlet on EvearaOutlet {
  storeId
  storeName
  processDurationDates
}
    `;
export const EvearaParticipantFragmentDoc = gql`
    fragment EvearaParticipant on EvearaParticipant {
  ipn
  isActive
  isni
  name
  id
  paypalEmailId
  removable
}
    `;
export const EvearaReleaseFragmentDoc = gql`
    fragment EvearaRelease on AlbumEveara {
  eanpc
  releaseId
  name
  trackCount
  productCodeType
  productType
  isActive
  coverImage {
    url
    extension
  }
}
    `;
export const EvearaRoleFragmentDoc = gql`
    fragment EvearaRole on EvearaRole {
  name
  id
}
    `;
export const EvearaSubscriptionFragmentDoc = gql`
    fragment EvearaSubscription on EvearaSubscription {
  mySubscriptionId
}
    `;
export const ImportedBeatItemFragmentDoc = gql`
    fragment ImportedBeatItem on AMPAsset {
  id
  title
  file {
    url: media_url
    title: file_name
    contentType: content_type
    createdAt: created_at
    id
  }
}
    `;
export const ImportedBeatsFragmentDoc = gql`
    fragment ImportedBeats on Catalog {
  importedBeats: assets(where: {type: {exact: IMPORT_BEAT}}) {
    ...AssetFields
  }
}
    ${AssetFieldsFragmentDoc}`;
export const UserRoleListItemFragmentDoc = gql`
    fragment UserRoleListItem on UserRole {
  id
  name
}
    `;
export const InvitedUserFragmentDoc = gql`
    fragment InvitedUser on UserInviteModel {
  id
  email
  meta
  firstName: first_name
  lastName: last_name
  status
  roles {
    ...UserRoleListItem
  }
}
    ${UserRoleListItemFragmentDoc}`;
export const MasterFilesFieldFragmentDoc = gql`
    fragment MasterFilesField on AMPMaster {
  masterFiles: assets(where: {type: {in: [MASTER_CLEAN, MASTER_EXPLICIT]}}) {
    ...AssetFields
  }
}
    ${AssetFieldsFragmentDoc}`;
export const MenuResourceFieldsFragmentDoc = gql`
    fragment MenuResourceFields on Resources {
  id
  access
  displayName: display_name
  slug
  type
  value
}
    `;
export const NewParticipantEvearaFragmentDoc = gql`
    fragment NewParticipantEveara on EvearaAlbumTrackParticipant {
  participantId
  roles {
    roleId
    roleName
  }
  participantName
  payoutSharePercentage
}
    `;
export const TullyMetadataRecordFieldsFragmentDoc = gql`
    fragment TullyMetadataRecordFields on TullyMetadataRecord {
  genre
}
    `;
export const NewAlbumEvearaFragmentDoc = gql`
    fragment NewAlbumEveara on AlbumDraftEveara {
  albumId
  name
  releaseId
  eanpc
  tracks {
    participant {
      ...NewParticipantEveara
    }
    iswc
    artists
    isrc
    trackId: asset_id
    preview {
      startAt
      duration
    }
    name
    meta {
      ...TullyMetadataRecordFields
    }
    featuredArtists
    fileUrl: trackUrl
    genres
    language
    lyrics
    explicit
    availability
  }
  artists {
    artistName
    artistCountry
  }
  trackCount
  subscription {
    subscriptionId
    subscriptionName
    expirationDate
  }
  isActive
  productType
  productCodeType
  spatialEanUpc
  spatialProductCodeType
  coverImage {
    url
    extension
  }
  removable
  originalReleaseDate
}
    ${NewParticipantEvearaFragmentDoc}
${TullyMetadataRecordFieldsFragmentDoc}`;
export const NewEvearaAvailabilityFragmentDoc = gql`
    fragment NewEvearaAvailability on EvearaTrackAvailability {
  availabilityId
  name
}
    `;
export const NewEvearaGenreFragmentDoc = gql`
    fragment NewEvearaGenre on EvearaTrackGenres {
  genreId
  name
}
    `;
export const NewEvearaArtistFragmentDoc = gql`
    fragment NewEvearaArtist on EvearaTrackArtist {
  artistId
  name
}
    `;
export const NewTrackEvearaFragmentDoc = gql`
    fragment NewTrackEveara on TrackDraftEveara {
  title: name
  id: trackId
  fileUrl: name
  isrc
  iswc
  albumOnly
  language
  explicit
  removable
  extention
  spatialIsrc
  dolbyAtmosIsrc
  lyrics
  availability {
    ...NewEvearaAvailability
  }
  genres {
    ...NewEvearaGenre
  }
  artists {
    ...NewEvearaArtist
  }
  featuredArtists {
    ...NewEvearaArtist
  }
}
    ${NewEvearaAvailabilityFragmentDoc}
${NewEvearaGenreFragmentDoc}
${NewEvearaArtistFragmentDoc}`;
export const OpenAiPromptRecordForTestingDialogFragmentDoc = gql`
    fragment OpenAIPromptRecordForTestingDialog on OpenAIPromptRecord {
  id
  completion
}
    `;
export const PageInfoFieldsFragmentDoc = gql`
    fragment PageInfoFields on PageInfo {
  endCursor
  startCursor
  hasNextPage
  hasPreviousPage
}
    `;
export const PlayerAssetFragmentDoc = gql`
    fragment PlayerAsset on AMPAsset {
  id
  title
  type
  mediaCover: media_cover {
    mediaUrl: media_url
  }
  file {
    mediaUrl: media_url
    fileName: file_name
  }
}
    `;
export const LyricsFieldsFragmentDoc = gql`
    fragment LyricsFields on AMPLyrics {
  id
  created_at
  content
}
    `;
export const ProjectMetaFieldFragmentDoc = gql`
    fragment ProjectMetaField on AMPProject {
  meta {
    title
    artist
  }
}
    `;
export const ProjectFieldsFragmentDoc = gql`
    fragment ProjectFields on AMPProject {
  id
  title
  created_at
  updated_at
  locked: is_locked
  catalog_id
  assets {
    ...AssetFields
  }
  lyrics {
    ...LyricsFields
  }
  ...ProjectMetaField
}
    ${AssetFieldsFragmentDoc}
${LyricsFieldsFragmentDoc}
${ProjectMetaFieldFragmentDoc}`;
export const ResourceFieldsFragmentDoc = gql`
    fragment ResourceFields on Resources {
  id: slug
  access
  displayName: display_name
  slug
  type
  value
}
    `;
export const SearchFilterFragmentDoc = gql`
    fragment SearchFilter on SearchFilter {
  searchText: name
  contentTypes: content_type {
    id
  }
  catalog: catalog {
    id
  }
  author: author {
    id
  }
  period
  periodUnit: period_unit
  fromDate: from_date
  toDate: to_date
}
    `;
export const SavedSearchFragmentDoc = gql`
    fragment SavedSearch on SavedSearch {
  id
  name
  public
  filters: filter {
    ...SearchFilter
  }
}
    ${SearchFilterFragmentDoc}`;
export const SearchItemFragmentDoc = gql`
    fragment SearchItem on SearchResult {
  id: entity_id
  name
  type {
    display_label
    type
  }
  parent_id
  parent_name
  parent_type
  folder
  catalog {
    id
    name
  }
  createdAt: created_at
  updatedAt: updated_at
  author {
    first_name
    last_name
  }
}
    `;
export const TullyBeatItemFragmentDoc = gql`
    fragment TullyBeatItem on TullyBeatResult {
  id
  title
  price
  file {
    url: media_url
    title: file_name
    contentType: content_type
    createdAt: created_at
    id
  }
}
    `;
export const TullyBeatsFragmentDoc = gql`
    fragment TullyBeats on Catalog {
  tullyBeats: assets(where: {type: {exact: TULLY_BEAT}}) {
    ...AssetFields
  }
}
    ${AssetFieldsFragmentDoc}`;
export const CatalogListItemFragmentDoc = gql`
    fragment CatalogListItem on Catalog {
  id
  name
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  firstName: first_name
  lastName: last_name
  profile {
    avatar
  }
  email
  roles {
    ...UserRoleListItem
  }
  catalogs {
    ...CatalogListItem
  }
  isActive: is_active
  isAdmin: is_admin
  isGuest: is_guest
  isArtist: is_artist
  isOwner: is_owner
  meta
}
    ${UserRoleListItemFragmentDoc}
${CatalogListItemFragmentDoc}`;
export const AccountFieldsFragmentDoc = gql`
    fragment AccountFields on Account {
  name
  updatedAt: updated_at
  createdAt: created_at
  addonStorage: addon_storage
  maxStorage: max_storage
  planStorage: plan_storage
  storageLimit: storage_limit
  usedFileCount: used_file_count
  usedStorage: used_storage
  freeAmpUserSeats: free_amp_user_seats
  freeArtistSeats: free_artist_seats
}
    `;
export const ArtistProfileFieldsFragmentDoc = gql`
    fragment ArtistProfileFields on ArtistProfile {
  affiliation
  apple_artist_id
  artist_option
  audio_settings
  youtube_channel_id
  artist_bio
  genre
  soundcloud_id
  spotify_id
  artist_name
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  created_at
  updated_at
  email
  firstName: first_name
  lastName: last_name
  isGuest: is_guest
  isActive: is_active
  isArtist: is_artist
  isStaff: is_staff
  isAdmin: is_admin
  isOwner: is_owner
  account {
    ...AccountFields
  }
  profile {
    phone
    avatar
    artist_profile {
      ...ArtistProfileFields
    }
  }
  roles {
    id
    name
  }
}
    ${AccountFieldsFragmentDoc}
${ArtistProfileFieldsFragmentDoc}`;
export const GetFeaturesDocument = gql`
    query GetFeatures {
  features @client {
    userManagement
  }
}
    `;

/**
 * __useGetFeaturesQuery__
 *
 * To run a query within a React component, call `useGetFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, baseOptions);
      }
export function useGetFeaturesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, baseOptions);
        }
export type GetFeaturesQueryHookResult = ReturnType<typeof useGetFeaturesQuery>;
export type GetFeaturesLazyQueryHookResult = ReturnType<typeof useGetFeaturesLazyQuery>;
export type GetFeaturesQueryResult = ApolloReactCommon.QueryResult<GetFeaturesQuery, GetFeaturesQueryVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($input: UMRoleInput!) {
  createRole(input: $input) @rest(type: "UmRole", path: "/roles/manage/", method: "POST") {
    id
    name
  }
}
    `;
export type CreateRoleMutationFn = ApolloReactCommon.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, baseOptions);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = ApolloReactCommon.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($input: RoleDeleteInput!) {
  deleteRole(input: $input) @rest(type: "NoResult", path: "/roles/{args.input.id}/", method: "DELETE") {
    NoResponse
  }
}
    `;
export type DeleteRoleMutationFn = ApolloReactCommon.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, baseOptions);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = ApolloReactCommon.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($id: Int!, $input: UMRoleInput!) {
  updateRole(id: $id, input: $input) @rest(type: "UmRole", path: "/roles/manage/{args.id}/", method: "PUT") {
    ...UmRoleFields
  }
}
    ${UmRoleFieldsFragmentDoc}`;
export type UpdateRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, baseOptions);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = ApolloReactCommon.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const UpdateUserRolesDocument = gql`
    mutation UpdateUserRoles($input: UserRolesInput!) {
  updateUserRoles(input: $input) @rest(type: "UserRolesUpdateResponse", path: "/users/role/assign/", method: "PATCH") {
    id
    role @type(name: "UserRole") {
      id
      name
    }
  }
}
    `;
export type UpdateUserRolesMutationFn = ApolloReactCommon.MutationFunction<UpdateUserRolesMutation, UpdateUserRolesMutationVariables>;

/**
 * __useUpdateUserRolesMutation__
 *
 * To run a mutation, you first call `useUpdateUserRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRolesMutation, { data, loading, error }] = useUpdateUserRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRolesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserRolesMutation, UpdateUserRolesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserRolesMutation, UpdateUserRolesMutationVariables>(UpdateUserRolesDocument, baseOptions);
      }
export type UpdateUserRolesMutationHookResult = ReturnType<typeof useUpdateUserRolesMutation>;
export type UpdateUserRolesMutationResult = ApolloReactCommon.MutationResult<UpdateUserRolesMutation>;
export type UpdateUserRolesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserRolesMutation, UpdateUserRolesMutationVariables>;
export const GetGuestUsersDocument = gql`
    query GetGuestUsers {
  getGuestUsers {
    id
    first_name
    last_name
    email
  }
}
    `;

/**
 * __useGetGuestUsersQuery__
 *
 * To run a query within a React component, call `useGetGuestUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuestUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuestUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGuestUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGuestUsersQuery, GetGuestUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGuestUsersQuery, GetGuestUsersQueryVariables>(GetGuestUsersDocument, baseOptions);
      }
export function useGetGuestUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGuestUsersQuery, GetGuestUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGuestUsersQuery, GetGuestUsersQueryVariables>(GetGuestUsersDocument, baseOptions);
        }
export type GetGuestUsersQueryHookResult = ReturnType<typeof useGetGuestUsersQuery>;
export type GetGuestUsersLazyQueryHookResult = ReturnType<typeof useGetGuestUsersLazyQuery>;
export type GetGuestUsersQueryResult = ApolloReactCommon.QueryResult<GetGuestUsersQuery, GetGuestUsersQueryVariables>;
export const GetInviteFlagsDocument = gql`
    query GetInviteFlags {
  canInviteUser @client
  canInviteArtist @client
}
    `;

/**
 * __useGetInviteFlagsQuery__
 *
 * To run a query within a React component, call `useGetInviteFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInviteFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInviteFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInviteFlagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInviteFlagsQuery, GetInviteFlagsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInviteFlagsQuery, GetInviteFlagsQueryVariables>(GetInviteFlagsDocument, baseOptions);
      }
export function useGetInviteFlagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInviteFlagsQuery, GetInviteFlagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInviteFlagsQuery, GetInviteFlagsQueryVariables>(GetInviteFlagsDocument, baseOptions);
        }
export type GetInviteFlagsQueryHookResult = ReturnType<typeof useGetInviteFlagsQuery>;
export type GetInviteFlagsLazyQueryHookResult = ReturnType<typeof useGetInviteFlagsLazyQuery>;
export type GetInviteFlagsQueryResult = ApolloReactCommon.QueryResult<GetInviteFlagsQuery, GetInviteFlagsQueryVariables>;
export const GetUmRoleByIdDocument = gql`
    query GetUmRoleById($id: Int!) {
  umRole(id: $id) @rest(type: "UMRole", path: "/roles/{args.id}/") {
    ...UmRoleFields
  }
}
    ${UmRoleFieldsFragmentDoc}`;

/**
 * __useGetUmRoleByIdQuery__
 *
 * To run a query within a React component, call `useGetUmRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUmRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUmRoleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUmRoleByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUmRoleByIdQuery, GetUmRoleByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUmRoleByIdQuery, GetUmRoleByIdQueryVariables>(GetUmRoleByIdDocument, baseOptions);
      }
export function useGetUmRoleByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUmRoleByIdQuery, GetUmRoleByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUmRoleByIdQuery, GetUmRoleByIdQueryVariables>(GetUmRoleByIdDocument, baseOptions);
        }
export type GetUmRoleByIdQueryHookResult = ReturnType<typeof useGetUmRoleByIdQuery>;
export type GetUmRoleByIdLazyQueryHookResult = ReturnType<typeof useGetUmRoleByIdLazyQuery>;
export type GetUmRoleByIdQueryResult = ApolloReactCommon.QueryResult<GetUmRoleByIdQuery, GetUmRoleByIdQueryVariables>;
export const GetUmRolesDocument = gql`
    query GetUmRoles {
  umRoles @rest(type: "UMRolePayload", path: "/roles/") {
    results @type(name: "UMRole") {
      ...UmRoleFields
    }
  }
}
    ${UmRoleFieldsFragmentDoc}`;

/**
 * __useGetUmRolesQuery__
 *
 * To run a query within a React component, call `useGetUmRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUmRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUmRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUmRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUmRolesQuery, GetUmRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUmRolesQuery, GetUmRolesQueryVariables>(GetUmRolesDocument, baseOptions);
      }
export function useGetUmRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUmRolesQuery, GetUmRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUmRolesQuery, GetUmRolesQueryVariables>(GetUmRolesDocument, baseOptions);
        }
export type GetUmRolesQueryHookResult = ReturnType<typeof useGetUmRolesQuery>;
export type GetUmRolesLazyQueryHookResult = ReturnType<typeof useGetUmRolesLazyQuery>;
export type GetUmRolesQueryResult = ApolloReactCommon.QueryResult<GetUmRolesQuery, GetUmRolesQueryVariables>;
export const GetUmUsersDocument = gql`
    query GetUmUsers {
  umUsers @rest(type: "UMUser", path: "/users/list/") {
    ...UMUserFields
  }
}
    ${UmUserFieldsFragmentDoc}`;

/**
 * __useGetUmUsersQuery__
 *
 * To run a query within a React component, call `useGetUmUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUmUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUmUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUmUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUmUsersQuery, GetUmUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUmUsersQuery, GetUmUsersQueryVariables>(GetUmUsersDocument, baseOptions);
      }
export function useGetUmUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUmUsersQuery, GetUmUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUmUsersQuery, GetUmUsersQueryVariables>(GetUmUsersDocument, baseOptions);
        }
export type GetUmUsersQueryHookResult = ReturnType<typeof useGetUmUsersQuery>;
export type GetUmUsersLazyQueryHookResult = ReturnType<typeof useGetUmUsersLazyQuery>;
export type GetUmUsersQueryResult = ApolloReactCommon.QueryResult<GetUmUsersQuery, GetUmUsersQueryVariables>;
export const AddAlbumEvearaDocument = gql`
    mutation AddAlbumEveara($albumEvearaInput: AlbumEvearaInput!) {
  newAlbumEveara: addAlbumEveara(data: $albumEvearaInput) {
    releaseId
    isSuccess: success
    message
  }
}
    `;
export type AddAlbumEvearaMutationFn = ApolloReactCommon.MutationFunction<AddAlbumEvearaMutation, AddAlbumEvearaMutationVariables>;

/**
 * __useAddAlbumEvearaMutation__
 *
 * To run a mutation, you first call `useAddAlbumEvearaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAlbumEvearaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAlbumEvearaMutation, { data, loading, error }] = useAddAlbumEvearaMutation({
 *   variables: {
 *      albumEvearaInput: // value for 'albumEvearaInput'
 *   },
 * });
 */
export function useAddAlbumEvearaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAlbumEvearaMutation, AddAlbumEvearaMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAlbumEvearaMutation, AddAlbumEvearaMutationVariables>(AddAlbumEvearaDocument, baseOptions);
      }
export type AddAlbumEvearaMutationHookResult = ReturnType<typeof useAddAlbumEvearaMutation>;
export type AddAlbumEvearaMutationResult = ApolloReactCommon.MutationResult<AddAlbumEvearaMutation>;
export type AddAlbumEvearaMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAlbumEvearaMutation, AddAlbumEvearaMutationVariables>;
export const AddArtistEvearaDocument = gql`
    mutation AddArtistEveara($name: String!, $country: String!) {
  newArtistEveara: addArtistEveara(country_code: $country, name: $name) {
    artistId
    artistName
  }
}
    `;
export type AddArtistEvearaMutationFn = ApolloReactCommon.MutationFunction<AddArtistEvearaMutation, AddArtistEvearaMutationVariables>;

/**
 * __useAddArtistEvearaMutation__
 *
 * To run a mutation, you first call `useAddArtistEvearaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtistEvearaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtistEvearaMutation, { data, loading, error }] = useAddArtistEvearaMutation({
 *   variables: {
 *      name: // value for 'name'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useAddArtistEvearaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddArtistEvearaMutation, AddArtistEvearaMutationVariables>) {
        return ApolloReactHooks.useMutation<AddArtistEvearaMutation, AddArtistEvearaMutationVariables>(AddArtistEvearaDocument, baseOptions);
      }
export type AddArtistEvearaMutationHookResult = ReturnType<typeof useAddArtistEvearaMutation>;
export type AddArtistEvearaMutationResult = ApolloReactCommon.MutationResult<AddArtistEvearaMutation>;
export type AddArtistEvearaMutationOptions = ApolloReactCommon.BaseMutationOptions<AddArtistEvearaMutation, AddArtistEvearaMutationVariables>;
export const AddLabelEvearaDocument = gql`
    mutation AddLabelEveara($name: String!) {
  newLabelEveara: addLabelEveara(name: $name) {
    labelId
    labelName
  }
}
    `;
export type AddLabelEvearaMutationFn = ApolloReactCommon.MutationFunction<AddLabelEvearaMutation, AddLabelEvearaMutationVariables>;

/**
 * __useAddLabelEvearaMutation__
 *
 * To run a mutation, you first call `useAddLabelEvearaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLabelEvearaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLabelEvearaMutation, { data, loading, error }] = useAddLabelEvearaMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddLabelEvearaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddLabelEvearaMutation, AddLabelEvearaMutationVariables>) {
        return ApolloReactHooks.useMutation<AddLabelEvearaMutation, AddLabelEvearaMutationVariables>(AddLabelEvearaDocument, baseOptions);
      }
export type AddLabelEvearaMutationHookResult = ReturnType<typeof useAddLabelEvearaMutation>;
export type AddLabelEvearaMutationResult = ApolloReactCommon.MutationResult<AddLabelEvearaMutation>;
export type AddLabelEvearaMutationOptions = ApolloReactCommon.BaseMutationOptions<AddLabelEvearaMutation, AddLabelEvearaMutationVariables>;
export const AddParticipantEvearaDocument = gql`
    mutation AddParticipantEveara($name: String!) {
  newParticipantEveara: addParticipantEveara(name: $name) {
    participantId
    participantName
  }
}
    `;
export type AddParticipantEvearaMutationFn = ApolloReactCommon.MutationFunction<AddParticipantEvearaMutation, AddParticipantEvearaMutationVariables>;

/**
 * __useAddParticipantEvearaMutation__
 *
 * To run a mutation, you first call `useAddParticipantEvearaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParticipantEvearaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParticipantEvearaMutation, { data, loading, error }] = useAddParticipantEvearaMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddParticipantEvearaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddParticipantEvearaMutation, AddParticipantEvearaMutationVariables>) {
        return ApolloReactHooks.useMutation<AddParticipantEvearaMutation, AddParticipantEvearaMutationVariables>(AddParticipantEvearaDocument, baseOptions);
      }
export type AddParticipantEvearaMutationHookResult = ReturnType<typeof useAddParticipantEvearaMutation>;
export type AddParticipantEvearaMutationResult = ApolloReactCommon.MutationResult<AddParticipantEvearaMutation>;
export type AddParticipantEvearaMutationOptions = ApolloReactCommon.BaseMutationOptions<AddParticipantEvearaMutation, AddParticipantEvearaMutationVariables>;
export const AddRolesToUserDocument = gql`
    mutation AddRolesToUser($id: Int!, $roleIds: [Int!]!) {
  updatedUser: addUserToRoles(user_id: $id, role_ids: $roleIds) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type AddRolesToUserMutationFn = ApolloReactCommon.MutationFunction<AddRolesToUserMutation, AddRolesToUserMutationVariables>;

/**
 * __useAddRolesToUserMutation__
 *
 * To run a mutation, you first call `useAddRolesToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRolesToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRolesToUserMutation, { data, loading, error }] = useAddRolesToUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useAddRolesToUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRolesToUserMutation, AddRolesToUserMutationVariables>) {
        return ApolloReactHooks.useMutation<AddRolesToUserMutation, AddRolesToUserMutationVariables>(AddRolesToUserDocument, baseOptions);
      }
export type AddRolesToUserMutationHookResult = ReturnType<typeof useAddRolesToUserMutation>;
export type AddRolesToUserMutationResult = ApolloReactCommon.MutationResult<AddRolesToUserMutation>;
export type AddRolesToUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRolesToUserMutation, AddRolesToUserMutationVariables>;
export const AddToAlbumDocument = gql`
    mutation AddToAlbum($collectionId: Int!, $masterId: Int!) {
  addMasterToCollection(id: $collectionId, data: {ids: [$masterId]}) {
    id
    master_positions {
      master_id
    }
    ordered_masters {
      ...MasterFields
    }
  }
}
    ${MasterFieldsFragmentDoc}`;
export type AddToAlbumMutationFn = ApolloReactCommon.MutationFunction<AddToAlbumMutation, AddToAlbumMutationVariables>;

/**
 * __useAddToAlbumMutation__
 *
 * To run a mutation, you first call `useAddToAlbumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToAlbumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToAlbumMutation, { data, loading, error }] = useAddToAlbumMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      masterId: // value for 'masterId'
 *   },
 * });
 */
export function useAddToAlbumMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddToAlbumMutation, AddToAlbumMutationVariables>) {
        return ApolloReactHooks.useMutation<AddToAlbumMutation, AddToAlbumMutationVariables>(AddToAlbumDocument, baseOptions);
      }
export type AddToAlbumMutationHookResult = ReturnType<typeof useAddToAlbumMutation>;
export type AddToAlbumMutationResult = ApolloReactCommon.MutationResult<AddToAlbumMutation>;
export type AddToAlbumMutationOptions = ApolloReactCommon.BaseMutationOptions<AddToAlbumMutation, AddToAlbumMutationVariables>;
export const AddTrackToDistributionRecordDocument = gql`
    mutation AddTrackToDistributionRecord($recordId: Int!, $masterId: Int!) {
  addTrackToDistributionRecord(recordId: $recordId, masterId: $masterId) {
    ...DistributionRecordTrack
  }
}
    ${DistributionRecordTrackFragmentDoc}`;
export type AddTrackToDistributionRecordMutationFn = ApolloReactCommon.MutationFunction<AddTrackToDistributionRecordMutation, AddTrackToDistributionRecordMutationVariables>;

/**
 * __useAddTrackToDistributionRecordMutation__
 *
 * To run a mutation, you first call `useAddTrackToDistributionRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrackToDistributionRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrackToDistributionRecordMutation, { data, loading, error }] = useAddTrackToDistributionRecordMutation({
 *   variables: {
 *      recordId: // value for 'recordId'
 *      masterId: // value for 'masterId'
 *   },
 * });
 */
export function useAddTrackToDistributionRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTrackToDistributionRecordMutation, AddTrackToDistributionRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTrackToDistributionRecordMutation, AddTrackToDistributionRecordMutationVariables>(AddTrackToDistributionRecordDocument, baseOptions);
      }
export type AddTrackToDistributionRecordMutationHookResult = ReturnType<typeof useAddTrackToDistributionRecordMutation>;
export type AddTrackToDistributionRecordMutationResult = ApolloReactCommon.MutationResult<AddTrackToDistributionRecordMutation>;
export type AddTrackToDistributionRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTrackToDistributionRecordMutation, AddTrackToDistributionRecordMutationVariables>;
export const AssignAdminDocument = gql`
    mutation AssignAdmin($id: Int!) {
  updatedUser: assignAdmin(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type AssignAdminMutationFn = ApolloReactCommon.MutationFunction<AssignAdminMutation, AssignAdminMutationVariables>;

/**
 * __useAssignAdminMutation__
 *
 * To run a mutation, you first call `useAssignAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAdminMutation, { data, loading, error }] = useAssignAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssignAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignAdminMutation, AssignAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignAdminMutation, AssignAdminMutationVariables>(AssignAdminDocument, baseOptions);
      }
export type AssignAdminMutationHookResult = ReturnType<typeof useAssignAdminMutation>;
export type AssignAdminMutationResult = ApolloReactCommon.MutationResult<AssignAdminMutation>;
export type AssignAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignAdminMutation, AssignAdminMutationVariables>;
export const AttachAlbumArtworkDocument = gql`
    mutation AttachAlbumArtwork($id: Int!, $fileName: String!, $attach: Boolean) {
  attachCollectionCoverArt(id: $id, data: {file_name: $fileName, attach: $attach}) {
    id
    cover_art {
      id
      media_url
    }
  }
}
    `;
export type AttachAlbumArtworkMutationFn = ApolloReactCommon.MutationFunction<AttachAlbumArtworkMutation, AttachAlbumArtworkMutationVariables>;

/**
 * __useAttachAlbumArtworkMutation__
 *
 * To run a mutation, you first call `useAttachAlbumArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachAlbumArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachAlbumArtworkMutation, { data, loading, error }] = useAttachAlbumArtworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileName: // value for 'fileName'
 *      attach: // value for 'attach'
 *   },
 * });
 */
export function useAttachAlbumArtworkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttachAlbumArtworkMutation, AttachAlbumArtworkMutationVariables>) {
        return ApolloReactHooks.useMutation<AttachAlbumArtworkMutation, AttachAlbumArtworkMutationVariables>(AttachAlbumArtworkDocument, baseOptions);
      }
export type AttachAlbumArtworkMutationHookResult = ReturnType<typeof useAttachAlbumArtworkMutation>;
export type AttachAlbumArtworkMutationResult = ApolloReactCommon.MutationResult<AttachAlbumArtworkMutation>;
export type AttachAlbumArtworkMutationOptions = ApolloReactCommon.BaseMutationOptions<AttachAlbumArtworkMutation, AttachAlbumArtworkMutationVariables>;
export const CreateAlbumDocument = gql`
    mutation CreateAlbum($input: CollectionInput!) {
  createCollection(data: $input) {
    ...CollectionFields
  }
}
    ${CollectionFieldsFragmentDoc}`;
export type CreateAlbumMutationFn = ApolloReactCommon.MutationFunction<CreateAlbumMutation, CreateAlbumMutationVariables>;

/**
 * __useCreateAlbumMutation__
 *
 * To run a mutation, you first call `useCreateAlbumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlbumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlbumMutation, { data, loading, error }] = useCreateAlbumMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAlbumMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAlbumMutation, CreateAlbumMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAlbumMutation, CreateAlbumMutationVariables>(CreateAlbumDocument, baseOptions);
      }
export type CreateAlbumMutationHookResult = ReturnType<typeof useCreateAlbumMutation>;
export type CreateAlbumMutationResult = ApolloReactCommon.MutationResult<CreateAlbumMutation>;
export type CreateAlbumMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAlbumMutation, CreateAlbumMutationVariables>;
export const CreateContractDocument = gql`
    mutation CreateContract($title: String!, $masterId: Int!, $description: String, $type: ContractType!, $fileId: Int) {
  createContract(data: {title: $title, master_id: $masterId, type: $type, metadata: {description: $description}, file_id: $fileId}) {
    id
  }
}
    `;
export type CreateContractMutationFn = ApolloReactCommon.MutationFunction<CreateContractMutation, CreateContractMutationVariables>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      title: // value for 'title'
 *      masterId: // value for 'masterId'
 *      description: // value for 'description'
 *      type: // value for 'type'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useCreateContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractMutation, CreateContractMutationVariables>(CreateContractDocument, baseOptions);
      }
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = ApolloReactCommon.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractMutation, CreateContractMutationVariables>;
export const CreateContractPartyDocument = gql`
    mutation CreateContractParty($name: String, $contact: String, $roleLabel: String, $contractId: Int!, $metadata: ContractPartyMetaInput) {
  createContractParty(data: {name: $name, contact: $contact, role_label: $roleLabel, contract_id: $contractId, role: AUTHOR, metadata: $metadata}) {
    id
  }
}
    `;
export type CreateContractPartyMutationFn = ApolloReactCommon.MutationFunction<CreateContractPartyMutation, CreateContractPartyMutationVariables>;

/**
 * __useCreateContractPartyMutation__
 *
 * To run a mutation, you first call `useCreateContractPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractPartyMutation, { data, loading, error }] = useCreateContractPartyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      contact: // value for 'contact'
 *      roleLabel: // value for 'roleLabel'
 *      contractId: // value for 'contractId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreateContractPartyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractPartyMutation, CreateContractPartyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractPartyMutation, CreateContractPartyMutationVariables>(CreateContractPartyDocument, baseOptions);
      }
export type CreateContractPartyMutationHookResult = ReturnType<typeof useCreateContractPartyMutation>;
export type CreateContractPartyMutationResult = ApolloReactCommon.MutationResult<CreateContractPartyMutation>;
export type CreateContractPartyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractPartyMutation, CreateContractPartyMutationVariables>;
export const CreateGuestUserDocument = gql`
    mutation CreateGuestUser($email: String!, $firstName: String!, $lastName: String!) {
  createGuestUser(data: {email: $email, first_name: $firstName, last_name: $lastName}) {
    id
    email
    first_name
    last_name
    is_active
    is_guest
  }
}
    `;
export type CreateGuestUserMutationFn = ApolloReactCommon.MutationFunction<CreateGuestUserMutation, CreateGuestUserMutationVariables>;

/**
 * __useCreateGuestUserMutation__
 *
 * To run a mutation, you first call `useCreateGuestUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGuestUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGuestUserMutation, { data, loading, error }] = useCreateGuestUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useCreateGuestUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGuestUserMutation, CreateGuestUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGuestUserMutation, CreateGuestUserMutationVariables>(CreateGuestUserDocument, baseOptions);
      }
export type CreateGuestUserMutationHookResult = ReturnType<typeof useCreateGuestUserMutation>;
export type CreateGuestUserMutationResult = ApolloReactCommon.MutationResult<CreateGuestUserMutation>;
export type CreateGuestUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGuestUserMutation, CreateGuestUserMutationVariables>;
export const CreateSavedSearchDocument = gql`
    mutation CreateSavedSearch($name: String!, $public: Boolean!, $filter: SearchFilterInput!) {
  createdSearch: createSavedSearch(data: {name: $name, public: $public, filter: $filter}) {
    ...SavedSearch
  }
}
    ${SavedSearchFragmentDoc}`;
export type CreateSavedSearchMutationFn = ApolloReactCommon.MutationFunction<CreateSavedSearchMutation, CreateSavedSearchMutationVariables>;

/**
 * __useCreateSavedSearchMutation__
 *
 * To run a mutation, you first call `useCreateSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSavedSearchMutation, { data, loading, error }] = useCreateSavedSearchMutation({
 *   variables: {
 *      name: // value for 'name'
 *      public: // value for 'public'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCreateSavedSearchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSavedSearchMutation, CreateSavedSearchMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSavedSearchMutation, CreateSavedSearchMutationVariables>(CreateSavedSearchDocument, baseOptions);
      }
export type CreateSavedSearchMutationHookResult = ReturnType<typeof useCreateSavedSearchMutation>;
export type CreateSavedSearchMutationResult = ApolloReactCommon.MutationResult<CreateSavedSearchMutation>;
export type CreateSavedSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSavedSearchMutation, CreateSavedSearchMutationVariables>;
export const CreateTullyMetadataArtistDocument = gql`
    mutation CreateTullyMetadataArtist($data: CreateTullyMetadataArtistInput!) {
  createTullyMetadataArtist(data: $data) {
    ...TullyMetadataArtist
  }
}
    ${TullyMetadataArtistFragmentDoc}`;
export type CreateTullyMetadataArtistMutationFn = ApolloReactCommon.MutationFunction<CreateTullyMetadataArtistMutation, CreateTullyMetadataArtistMutationVariables>;

/**
 * __useCreateTullyMetadataArtistMutation__
 *
 * To run a mutation, you first call `useCreateTullyMetadataArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTullyMetadataArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTullyMetadataArtistMutation, { data, loading, error }] = useCreateTullyMetadataArtistMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTullyMetadataArtistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTullyMetadataArtistMutation, CreateTullyMetadataArtistMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTullyMetadataArtistMutation, CreateTullyMetadataArtistMutationVariables>(CreateTullyMetadataArtistDocument, baseOptions);
      }
export type CreateTullyMetadataArtistMutationHookResult = ReturnType<typeof useCreateTullyMetadataArtistMutation>;
export type CreateTullyMetadataArtistMutationResult = ApolloReactCommon.MutationResult<CreateTullyMetadataArtistMutation>;
export type CreateTullyMetadataArtistMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTullyMetadataArtistMutation, CreateTullyMetadataArtistMutationVariables>;
export const CreateTullyMetadataParticipantDocument = gql`
    mutation CreateTullyMetadataParticipant($data: CreateTullyMetadataParticipantInput!) {
  createTullyMetadataParticipant(data: $data) {
    ...TullyMetadataParticipant
  }
}
    ${TullyMetadataParticipantFragmentDoc}`;
export type CreateTullyMetadataParticipantMutationFn = ApolloReactCommon.MutationFunction<CreateTullyMetadataParticipantMutation, CreateTullyMetadataParticipantMutationVariables>;

/**
 * __useCreateTullyMetadataParticipantMutation__
 *
 * To run a mutation, you first call `useCreateTullyMetadataParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTullyMetadataParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTullyMetadataParticipantMutation, { data, loading, error }] = useCreateTullyMetadataParticipantMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTullyMetadataParticipantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTullyMetadataParticipantMutation, CreateTullyMetadataParticipantMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTullyMetadataParticipantMutation, CreateTullyMetadataParticipantMutationVariables>(CreateTullyMetadataParticipantDocument, baseOptions);
      }
export type CreateTullyMetadataParticipantMutationHookResult = ReturnType<typeof useCreateTullyMetadataParticipantMutation>;
export type CreateTullyMetadataParticipantMutationResult = ApolloReactCommon.MutationResult<CreateTullyMetadataParticipantMutation>;
export type CreateTullyMetadataParticipantMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTullyMetadataParticipantMutation, CreateTullyMetadataParticipantMutationVariables>;
export const CreateTullyPersonMetadataDocument = gql`
    mutation CreateTullyPersonMetadata($data: TullyPersonMetadataInput!) {
  createTullyPersonMetadata(data: $data) {
    id
    ...TullyPersonMetadataFields
  }
}
    ${TullyPersonMetadataFieldsFragmentDoc}`;
export type CreateTullyPersonMetadataMutationFn = ApolloReactCommon.MutationFunction<CreateTullyPersonMetadataMutation, CreateTullyPersonMetadataMutationVariables>;

/**
 * __useCreateTullyPersonMetadataMutation__
 *
 * To run a mutation, you first call `useCreateTullyPersonMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTullyPersonMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTullyPersonMetadataMutation, { data, loading, error }] = useCreateTullyPersonMetadataMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTullyPersonMetadataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTullyPersonMetadataMutation, CreateTullyPersonMetadataMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTullyPersonMetadataMutation, CreateTullyPersonMetadataMutationVariables>(CreateTullyPersonMetadataDocument, baseOptions);
      }
export type CreateTullyPersonMetadataMutationHookResult = ReturnType<typeof useCreateTullyPersonMetadataMutation>;
export type CreateTullyPersonMetadataMutationResult = ApolloReactCommon.MutationResult<CreateTullyPersonMetadataMutation>;
export type CreateTullyPersonMetadataMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTullyPersonMetadataMutation, CreateTullyPersonMetadataMutationVariables>;
export const CreateUserInviteDocument = gql`
    mutation CreateUserInvite($data: UserInviteInput!) {
  invitedUser: createUserInvite(data: $data) {
    ...InvitedUser
  }
}
    ${InvitedUserFragmentDoc}`;
export type CreateUserInviteMutationFn = ApolloReactCommon.MutationFunction<CreateUserInviteMutation, CreateUserInviteMutationVariables>;

/**
 * __useCreateUserInviteMutation__
 *
 * To run a mutation, you first call `useCreateUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserInviteMutation, { data, loading, error }] = useCreateUserInviteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserInviteMutation, CreateUserInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserInviteMutation, CreateUserInviteMutationVariables>(CreateUserInviteDocument, baseOptions);
      }
export type CreateUserInviteMutationHookResult = ReturnType<typeof useCreateUserInviteMutation>;
export type CreateUserInviteMutationResult = ApolloReactCommon.MutationResult<CreateUserInviteMutation>;
export type CreateUserInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserInviteMutation, CreateUserInviteMutationVariables>;
export const DeleteAlbumEvearaDocument = gql`
    mutation DeleteAlbumEveara($releaseId: Float!) {
  deletedAlbumId: deleteAlbumEveara(releaseId: $releaseId) {
    message
    success
  }
}
    `;
export type DeleteAlbumEvearaMutationFn = ApolloReactCommon.MutationFunction<DeleteAlbumEvearaMutation, DeleteAlbumEvearaMutationVariables>;

/**
 * __useDeleteAlbumEvearaMutation__
 *
 * To run a mutation, you first call `useDeleteAlbumEvearaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlbumEvearaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlbumEvearaMutation, { data, loading, error }] = useDeleteAlbumEvearaMutation({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useDeleteAlbumEvearaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAlbumEvearaMutation, DeleteAlbumEvearaMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAlbumEvearaMutation, DeleteAlbumEvearaMutationVariables>(DeleteAlbumEvearaDocument, baseOptions);
      }
export type DeleteAlbumEvearaMutationHookResult = ReturnType<typeof useDeleteAlbumEvearaMutation>;
export type DeleteAlbumEvearaMutationResult = ApolloReactCommon.MutationResult<DeleteAlbumEvearaMutation>;
export type DeleteAlbumEvearaMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAlbumEvearaMutation, DeleteAlbumEvearaMutationVariables>;
export const DeleteAssetDocument = gql`
    mutation DeleteAsset($id: Int!) {
  deletedAssetId: deleteAsset(id: $id)
}
    `;
export type DeleteAssetMutationFn = ApolloReactCommon.MutationFunction<DeleteAssetMutation, DeleteAssetMutationVariables>;

/**
 * __useDeleteAssetMutation__
 *
 * To run a mutation, you first call `useDeleteAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetMutation, { data, loading, error }] = useDeleteAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAssetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAssetMutation, DeleteAssetMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAssetMutation, DeleteAssetMutationVariables>(DeleteAssetDocument, baseOptions);
      }
export type DeleteAssetMutationHookResult = ReturnType<typeof useDeleteAssetMutation>;
export type DeleteAssetMutationResult = ApolloReactCommon.MutationResult<DeleteAssetMutation>;
export type DeleteAssetMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAssetMutation, DeleteAssetMutationVariables>;
export const DeleteCollectionDocument = gql`
    mutation DeleteCollection($id: Int!) {
  deleteCollection(id: $id)
}
    `;
export type DeleteCollectionMutationFn = ApolloReactCommon.MutationFunction<DeleteCollectionMutation, DeleteCollectionMutationVariables>;

/**
 * __useDeleteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMutation, { data, loading, error }] = useDeleteCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCollectionMutation, DeleteCollectionMutationVariables>(DeleteCollectionDocument, baseOptions);
      }
export type DeleteCollectionMutationHookResult = ReturnType<typeof useDeleteCollectionMutation>;
export type DeleteCollectionMutationResult = ApolloReactCommon.MutationResult<DeleteCollectionMutation>;
export type DeleteCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>;
export const DeleteContractDocument = gql`
    mutation DeleteContract($id: Int!) {
  deletedContractId: deleteContract(id: $id)
}
    `;
export type DeleteContractMutationFn = ApolloReactCommon.MutationFunction<DeleteContractMutation, DeleteContractMutationVariables>;

/**
 * __useDeleteContractMutation__
 *
 * To run a mutation, you first call `useDeleteContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractMutation, { data, loading, error }] = useDeleteContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractMutation, DeleteContractMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractMutation, DeleteContractMutationVariables>(DeleteContractDocument, baseOptions);
      }
export type DeleteContractMutationHookResult = ReturnType<typeof useDeleteContractMutation>;
export type DeleteContractMutationResult = ApolloReactCommon.MutationResult<DeleteContractMutation>;
export type DeleteContractMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractMutation, DeleteContractMutationVariables>;
export const DeleteContractPartyDocument = gql`
    mutation DeleteContractParty($id: Int!) {
  deleteContractParty(id: $id)
}
    `;
export type DeleteContractPartyMutationFn = ApolloReactCommon.MutationFunction<DeleteContractPartyMutation, DeleteContractPartyMutationVariables>;

/**
 * __useDeleteContractPartyMutation__
 *
 * To run a mutation, you first call `useDeleteContractPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractPartyMutation, { data, loading, error }] = useDeleteContractPartyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContractPartyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractPartyMutation, DeleteContractPartyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractPartyMutation, DeleteContractPartyMutationVariables>(DeleteContractPartyDocument, baseOptions);
      }
export type DeleteContractPartyMutationHookResult = ReturnType<typeof useDeleteContractPartyMutation>;
export type DeleteContractPartyMutationResult = ApolloReactCommon.MutationResult<DeleteContractPartyMutation>;
export type DeleteContractPartyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractPartyMutation, DeleteContractPartyMutationVariables>;
export const DeleteDistributionRecordDocument = gql`
    mutation DeleteDistributionRecord($id: Int!) {
  deleteDistributionRecord(id: $id) {
    ...DistributionRecord
  }
}
    ${DistributionRecordFragmentDoc}`;
export type DeleteDistributionRecordMutationFn = ApolloReactCommon.MutationFunction<DeleteDistributionRecordMutation, DeleteDistributionRecordMutationVariables>;

/**
 * __useDeleteDistributionRecordMutation__
 *
 * To run a mutation, you first call `useDeleteDistributionRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDistributionRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDistributionRecordMutation, { data, loading, error }] = useDeleteDistributionRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDistributionRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDistributionRecordMutation, DeleteDistributionRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDistributionRecordMutation, DeleteDistributionRecordMutationVariables>(DeleteDistributionRecordDocument, baseOptions);
      }
export type DeleteDistributionRecordMutationHookResult = ReturnType<typeof useDeleteDistributionRecordMutation>;
export type DeleteDistributionRecordMutationResult = ApolloReactCommon.MutationResult<DeleteDistributionRecordMutation>;
export type DeleteDistributionRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDistributionRecordMutation, DeleteDistributionRecordMutationVariables>;
export const DeleteInvitedUserDocument = gql`
    mutation DeleteInvitedUser($id: Int!) {
  deletedUserId: deleteInvitedUser(id: $id)
}
    `;
export type DeleteInvitedUserMutationFn = ApolloReactCommon.MutationFunction<DeleteInvitedUserMutation, DeleteInvitedUserMutationVariables>;

/**
 * __useDeleteInvitedUserMutation__
 *
 * To run a mutation, you first call `useDeleteInvitedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitedUserMutation, { data, loading, error }] = useDeleteInvitedUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvitedUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInvitedUserMutation, DeleteInvitedUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInvitedUserMutation, DeleteInvitedUserMutationVariables>(DeleteInvitedUserDocument, baseOptions);
      }
export type DeleteInvitedUserMutationHookResult = ReturnType<typeof useDeleteInvitedUserMutation>;
export type DeleteInvitedUserMutationResult = ApolloReactCommon.MutationResult<DeleteInvitedUserMutation>;
export type DeleteInvitedUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInvitedUserMutation, DeleteInvitedUserMutationVariables>;
export const DeleteLyricsDocument = gql`
    mutation DeleteLyrics($id: Int!) {
  deletedLyricsId: deleteLyrics(id: $id)
}
    `;
export type DeleteLyricsMutationFn = ApolloReactCommon.MutationFunction<DeleteLyricsMutation, DeleteLyricsMutationVariables>;

/**
 * __useDeleteLyricsMutation__
 *
 * To run a mutation, you first call `useDeleteLyricsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLyricsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLyricsMutation, { data, loading, error }] = useDeleteLyricsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLyricsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLyricsMutation, DeleteLyricsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteLyricsMutation, DeleteLyricsMutationVariables>(DeleteLyricsDocument, baseOptions);
      }
export type DeleteLyricsMutationHookResult = ReturnType<typeof useDeleteLyricsMutation>;
export type DeleteLyricsMutationResult = ApolloReactCommon.MutationResult<DeleteLyricsMutation>;
export type DeleteLyricsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLyricsMutation, DeleteLyricsMutationVariables>;
export const DeleteMasterDocument = gql`
    mutation DeleteMaster($id: Int!) {
  deletedMaster: deleteMaster(id: $id)
}
    `;
export type DeleteMasterMutationFn = ApolloReactCommon.MutationFunction<DeleteMasterMutation, DeleteMasterMutationVariables>;

/**
 * __useDeleteMasterMutation__
 *
 * To run a mutation, you first call `useDeleteMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMasterMutation, { data, loading, error }] = useDeleteMasterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMasterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMasterMutation, DeleteMasterMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMasterMutation, DeleteMasterMutationVariables>(DeleteMasterDocument, baseOptions);
      }
export type DeleteMasterMutationHookResult = ReturnType<typeof useDeleteMasterMutation>;
export type DeleteMasterMutationResult = ApolloReactCommon.MutationResult<DeleteMasterMutation>;
export type DeleteMasterMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMasterMutation, DeleteMasterMutationVariables>;
export const UpdateMasterMetaCollabsDocument = gql`
    mutation UpdateMasterMetaCollabs($id: Int!, $collabIds: [Int!]!) {
  updateMaster(id: $id, data: {tully_metadata: {collaborators: $collabIds}}) {
    id
    tully_metadata {
      id
      collaborators {
        ...TullyPersonMetadataFields
      }
    }
  }
}
    ${TullyPersonMetadataFieldsFragmentDoc}`;
export type UpdateMasterMetaCollabsMutationFn = ApolloReactCommon.MutationFunction<UpdateMasterMetaCollabsMutation, UpdateMasterMetaCollabsMutationVariables>;

/**
 * __useUpdateMasterMetaCollabsMutation__
 *
 * To run a mutation, you first call `useUpdateMasterMetaCollabsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasterMetaCollabsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasterMetaCollabsMutation, { data, loading, error }] = useUpdateMasterMetaCollabsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      collabIds: // value for 'collabIds'
 *   },
 * });
 */
export function useUpdateMasterMetaCollabsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMasterMetaCollabsMutation, UpdateMasterMetaCollabsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMasterMetaCollabsMutation, UpdateMasterMetaCollabsMutationVariables>(UpdateMasterMetaCollabsDocument, baseOptions);
      }
export type UpdateMasterMetaCollabsMutationHookResult = ReturnType<typeof useUpdateMasterMetaCollabsMutation>;
export type UpdateMasterMetaCollabsMutationResult = ApolloReactCommon.MutationResult<UpdateMasterMetaCollabsMutation>;
export type UpdateMasterMetaCollabsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMasterMetaCollabsMutation, UpdateMasterMetaCollabsMutationVariables>;
export const DeleteMultiExcludedResourcesDocument = gql`
    mutation deleteMultiExcludedResources($resource_id: [Int]!) {
  deleteMultiExcludedResources(resource_id: $resource_id) {
    id
    slug
    display_name
    type
    access
    value
  }
}
    `;
export type DeleteMultiExcludedResourcesMutationFn = ApolloReactCommon.MutationFunction<DeleteMultiExcludedResourcesMutation, DeleteMultiExcludedResourcesMutationVariables>;

/**
 * __useDeleteMultiExcludedResourcesMutation__
 *
 * To run a mutation, you first call `useDeleteMultiExcludedResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMultiExcludedResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMultiExcludedResourcesMutation, { data, loading, error }] = useDeleteMultiExcludedResourcesMutation({
 *   variables: {
 *      resource_id: // value for 'resource_id'
 *   },
 * });
 */
export function useDeleteMultiExcludedResourcesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMultiExcludedResourcesMutation, DeleteMultiExcludedResourcesMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMultiExcludedResourcesMutation, DeleteMultiExcludedResourcesMutationVariables>(DeleteMultiExcludedResourcesDocument, baseOptions);
      }
export type DeleteMultiExcludedResourcesMutationHookResult = ReturnType<typeof useDeleteMultiExcludedResourcesMutation>;
export type DeleteMultiExcludedResourcesMutationResult = ApolloReactCommon.MutationResult<DeleteMultiExcludedResourcesMutation>;
export type DeleteMultiExcludedResourcesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMultiExcludedResourcesMutation, DeleteMultiExcludedResourcesMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($id: Int!) {
  deletedProjectId: deleteProject(id: $id)
}
    `;
export type DeleteProjectMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, baseOptions);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = ApolloReactCommon.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const DeleteSavedSearchDocument = gql`
    mutation DeleteSavedSearch($id: Int!) {
  deleteSavedSearch(id: $id)
}
    `;
export type DeleteSavedSearchMutationFn = ApolloReactCommon.MutationFunction<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>;

/**
 * __useDeleteSavedSearchMutation__
 *
 * To run a mutation, you first call `useDeleteSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedSearchMutation, { data, loading, error }] = useDeleteSavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSavedSearchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>(DeleteSavedSearchDocument, baseOptions);
      }
export type DeleteSavedSearchMutationHookResult = ReturnType<typeof useDeleteSavedSearchMutation>;
export type DeleteSavedSearchMutationResult = ApolloReactCommon.MutationResult<DeleteSavedSearchMutation>;
export type DeleteSavedSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>;
export const DeleteTullyPersonMetadataDocument = gql`
    mutation DeleteTullyPersonMetadata($id: Int!) {
  deleteTullyPersonMetadata(id: $id)
}
    `;
export type DeleteTullyPersonMetadataMutationFn = ApolloReactCommon.MutationFunction<DeleteTullyPersonMetadataMutation, DeleteTullyPersonMetadataMutationVariables>;

/**
 * __useDeleteTullyPersonMetadataMutation__
 *
 * To run a mutation, you first call `useDeleteTullyPersonMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTullyPersonMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTullyPersonMetadataMutation, { data, loading, error }] = useDeleteTullyPersonMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTullyPersonMetadataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTullyPersonMetadataMutation, DeleteTullyPersonMetadataMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTullyPersonMetadataMutation, DeleteTullyPersonMetadataMutationVariables>(DeleteTullyPersonMetadataDocument, baseOptions);
      }
export type DeleteTullyPersonMetadataMutationHookResult = ReturnType<typeof useDeleteTullyPersonMetadataMutation>;
export type DeleteTullyPersonMetadataMutationResult = ApolloReactCommon.MutationResult<DeleteTullyPersonMetadataMutation>;
export type DeleteTullyPersonMetadataMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTullyPersonMetadataMutation, DeleteTullyPersonMetadataMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: Int!) {
  deletedUserId: deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DistributeAlbumEvearaDocument = gql`
    mutation DistributeAlbumEveara($releaseId: Float!, $outletsDetails: [OutletsDetailsEveara!]!) {
  addOutlets: distributeAlbumEveara(releaseId: $releaseId, outletsDetails: $outletsDetails) {
    success
    message
  }
}
    `;
export type DistributeAlbumEvearaMutationFn = ApolloReactCommon.MutationFunction<DistributeAlbumEvearaMutation, DistributeAlbumEvearaMutationVariables>;

/**
 * __useDistributeAlbumEvearaMutation__
 *
 * To run a mutation, you first call `useDistributeAlbumEvearaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDistributeAlbumEvearaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [distributeAlbumEvearaMutation, { data, loading, error }] = useDistributeAlbumEvearaMutation({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *      outletsDetails: // value for 'outletsDetails'
 *   },
 * });
 */
export function useDistributeAlbumEvearaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DistributeAlbumEvearaMutation, DistributeAlbumEvearaMutationVariables>) {
        return ApolloReactHooks.useMutation<DistributeAlbumEvearaMutation, DistributeAlbumEvearaMutationVariables>(DistributeAlbumEvearaDocument, baseOptions);
      }
export type DistributeAlbumEvearaMutationHookResult = ReturnType<typeof useDistributeAlbumEvearaMutation>;
export type DistributeAlbumEvearaMutationResult = ApolloReactCommon.MutationResult<DistributeAlbumEvearaMutation>;
export type DistributeAlbumEvearaMutationOptions = ApolloReactCommon.BaseMutationOptions<DistributeAlbumEvearaMutation, DistributeAlbumEvearaMutationVariables>;
export const DistributeDistributionRecordDocument = gql`
    mutation DistributeDistributionRecord($id: Int!) {
  distributeDistributionRecord(id: $id) {
    ...DistributionRecord
  }
}
    ${DistributionRecordFragmentDoc}`;
export type DistributeDistributionRecordMutationFn = ApolloReactCommon.MutationFunction<DistributeDistributionRecordMutation, DistributeDistributionRecordMutationVariables>;

/**
 * __useDistributeDistributionRecordMutation__
 *
 * To run a mutation, you first call `useDistributeDistributionRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDistributeDistributionRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [distributeDistributionRecordMutation, { data, loading, error }] = useDistributeDistributionRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistributeDistributionRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DistributeDistributionRecordMutation, DistributeDistributionRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<DistributeDistributionRecordMutation, DistributeDistributionRecordMutationVariables>(DistributeDistributionRecordDocument, baseOptions);
      }
export type DistributeDistributionRecordMutationHookResult = ReturnType<typeof useDistributeDistributionRecordMutation>;
export type DistributeDistributionRecordMutationResult = ApolloReactCommon.MutationResult<DistributeDistributionRecordMutation>;
export type DistributeDistributionRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<DistributeDistributionRecordMutation, DistributeDistributionRecordMutationVariables>;
export const DropCollectionCoverArtDocument = gql`
    mutation DropCollectionCoverArt($id: Int!) {
  dropCollectionCoverArt(id: $id) {
    id
    cover_art {
      id
      media_url
      file_name
    }
  }
}
    `;
export type DropCollectionCoverArtMutationFn = ApolloReactCommon.MutationFunction<DropCollectionCoverArtMutation, DropCollectionCoverArtMutationVariables>;

/**
 * __useDropCollectionCoverArtMutation__
 *
 * To run a mutation, you first call `useDropCollectionCoverArtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDropCollectionCoverArtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dropCollectionCoverArtMutation, { data, loading, error }] = useDropCollectionCoverArtMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDropCollectionCoverArtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DropCollectionCoverArtMutation, DropCollectionCoverArtMutationVariables>) {
        return ApolloReactHooks.useMutation<DropCollectionCoverArtMutation, DropCollectionCoverArtMutationVariables>(DropCollectionCoverArtDocument, baseOptions);
      }
export type DropCollectionCoverArtMutationHookResult = ReturnType<typeof useDropCollectionCoverArtMutation>;
export type DropCollectionCoverArtMutationResult = ApolloReactCommon.MutationResult<DropCollectionCoverArtMutation>;
export type DropCollectionCoverArtMutationOptions = ApolloReactCommon.BaseMutationOptions<DropCollectionCoverArtMutation, DropCollectionCoverArtMutationVariables>;
export const GenerateLyricsByThemeWithChatGptDocument = gql`
    mutation GenerateLyricsByThemeWithChatGPT($theme: String!) {
  generateLyricsByThemeWithChatGPT(theme: $theme) {
    ...OpenAIPromptRecordForTestingDialog
  }
}
    ${OpenAiPromptRecordForTestingDialogFragmentDoc}`;
export type GenerateLyricsByThemeWithChatGptMutationFn = ApolloReactCommon.MutationFunction<GenerateLyricsByThemeWithChatGptMutation, GenerateLyricsByThemeWithChatGptMutationVariables>;

/**
 * __useGenerateLyricsByThemeWithChatGptMutation__
 *
 * To run a mutation, you first call `useGenerateLyricsByThemeWithChatGptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLyricsByThemeWithChatGptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLyricsByThemeWithChatGptMutation, { data, loading, error }] = useGenerateLyricsByThemeWithChatGptMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useGenerateLyricsByThemeWithChatGptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateLyricsByThemeWithChatGptMutation, GenerateLyricsByThemeWithChatGptMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateLyricsByThemeWithChatGptMutation, GenerateLyricsByThemeWithChatGptMutationVariables>(GenerateLyricsByThemeWithChatGptDocument, baseOptions);
      }
export type GenerateLyricsByThemeWithChatGptMutationHookResult = ReturnType<typeof useGenerateLyricsByThemeWithChatGptMutation>;
export type GenerateLyricsByThemeWithChatGptMutationResult = ApolloReactCommon.MutationResult<GenerateLyricsByThemeWithChatGptMutation>;
export type GenerateLyricsByThemeWithChatGptMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateLyricsByThemeWithChatGptMutation, GenerateLyricsByThemeWithChatGptMutationVariables>;
export const MarkActionsAsSeenDocument = gql`
    mutation MarkActionsAsSeen($ids: [Int!]!) {
  markActionsAsSeen(ids: $ids) {
    message
  }
}
    `;
export type MarkActionsAsSeenMutationFn = ApolloReactCommon.MutationFunction<MarkActionsAsSeenMutation, MarkActionsAsSeenMutationVariables>;

/**
 * __useMarkActionsAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkActionsAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkActionsAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markActionsAsSeenMutation, { data, loading, error }] = useMarkActionsAsSeenMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkActionsAsSeenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkActionsAsSeenMutation, MarkActionsAsSeenMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkActionsAsSeenMutation, MarkActionsAsSeenMutationVariables>(MarkActionsAsSeenDocument, baseOptions);
      }
export type MarkActionsAsSeenMutationHookResult = ReturnType<typeof useMarkActionsAsSeenMutation>;
export type MarkActionsAsSeenMutationResult = ApolloReactCommon.MutationResult<MarkActionsAsSeenMutation>;
export type MarkActionsAsSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkActionsAsSeenMutation, MarkActionsAsSeenMutationVariables>;
export const RateOpenAiPromptRecordCompletionDocument = gql`
    mutation RateOpenAIPromptRecordCompletion($recordId: Int!, $rate: Int) {
  rateOpenAIPromptRecordCompletion(recordId: $recordId, rate: $rate) {
    ...OpenAIPromptRecordForTestingDialog
  }
}
    ${OpenAiPromptRecordForTestingDialogFragmentDoc}`;
export type RateOpenAiPromptRecordCompletionMutationFn = ApolloReactCommon.MutationFunction<RateOpenAiPromptRecordCompletionMutation, RateOpenAiPromptRecordCompletionMutationVariables>;

/**
 * __useRateOpenAiPromptRecordCompletionMutation__
 *
 * To run a mutation, you first call `useRateOpenAiPromptRecordCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateOpenAiPromptRecordCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateOpenAiPromptRecordCompletionMutation, { data, loading, error }] = useRateOpenAiPromptRecordCompletionMutation({
 *   variables: {
 *      recordId: // value for 'recordId'
 *      rate: // value for 'rate'
 *   },
 * });
 */
export function useRateOpenAiPromptRecordCompletionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateOpenAiPromptRecordCompletionMutation, RateOpenAiPromptRecordCompletionMutationVariables>) {
        return ApolloReactHooks.useMutation<RateOpenAiPromptRecordCompletionMutation, RateOpenAiPromptRecordCompletionMutationVariables>(RateOpenAiPromptRecordCompletionDocument, baseOptions);
      }
export type RateOpenAiPromptRecordCompletionMutationHookResult = ReturnType<typeof useRateOpenAiPromptRecordCompletionMutation>;
export type RateOpenAiPromptRecordCompletionMutationResult = ApolloReactCommon.MutationResult<RateOpenAiPromptRecordCompletionMutation>;
export type RateOpenAiPromptRecordCompletionMutationOptions = ApolloReactCommon.BaseMutationOptions<RateOpenAiPromptRecordCompletionMutation, RateOpenAiPromptRecordCompletionMutationVariables>;
export const RemoveMasterFromCollectionDocument = gql`
    mutation RemoveMasterFromCollection($collectionId: Int!, $masterId: Int!) {
  removeMasterFromCollection(id: $collectionId, data: {ids: [$masterId]}) {
    ...CollectionFields
  }
}
    ${CollectionFieldsFragmentDoc}`;
export type RemoveMasterFromCollectionMutationFn = ApolloReactCommon.MutationFunction<RemoveMasterFromCollectionMutation, RemoveMasterFromCollectionMutationVariables>;

/**
 * __useRemoveMasterFromCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveMasterFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMasterFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMasterFromCollectionMutation, { data, loading, error }] = useRemoveMasterFromCollectionMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      masterId: // value for 'masterId'
 *   },
 * });
 */
export function useRemoveMasterFromCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMasterFromCollectionMutation, RemoveMasterFromCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMasterFromCollectionMutation, RemoveMasterFromCollectionMutationVariables>(RemoveMasterFromCollectionDocument, baseOptions);
      }
export type RemoveMasterFromCollectionMutationHookResult = ReturnType<typeof useRemoveMasterFromCollectionMutation>;
export type RemoveMasterFromCollectionMutationResult = ApolloReactCommon.MutationResult<RemoveMasterFromCollectionMutation>;
export type RemoveMasterFromCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMasterFromCollectionMutation, RemoveMasterFromCollectionMutationVariables>;
export const RemoveRolesFromUserDocument = gql`
    mutation RemoveRolesFromUser($id: Int!, $roleIds: [Int!]!) {
  updatedUser: removeUserFromRoles(user_id: $id, role_ids: $roleIds) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type RemoveRolesFromUserMutationFn = ApolloReactCommon.MutationFunction<RemoveRolesFromUserMutation, RemoveRolesFromUserMutationVariables>;

/**
 * __useRemoveRolesFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveRolesFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRolesFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRolesFromUserMutation, { data, loading, error }] = useRemoveRolesFromUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useRemoveRolesFromUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveRolesFromUserMutation, RemoveRolesFromUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveRolesFromUserMutation, RemoveRolesFromUserMutationVariables>(RemoveRolesFromUserDocument, baseOptions);
      }
export type RemoveRolesFromUserMutationHookResult = ReturnType<typeof useRemoveRolesFromUserMutation>;
export type RemoveRolesFromUserMutationResult = ApolloReactCommon.MutationResult<RemoveRolesFromUserMutation>;
export type RemoveRolesFromUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveRolesFromUserMutation, RemoveRolesFromUserMutationVariables>;
export const RemoveTrackFromDistributionRecordDocument = gql`
    mutation RemoveTrackFromDistributionRecord($recordId: Int!, $trackId: Int!) {
  removeTrackFromDistributionRecord(recordId: $recordId, trackId: $trackId) {
    id
  }
}
    `;
export type RemoveTrackFromDistributionRecordMutationFn = ApolloReactCommon.MutationFunction<RemoveTrackFromDistributionRecordMutation, RemoveTrackFromDistributionRecordMutationVariables>;

/**
 * __useRemoveTrackFromDistributionRecordMutation__
 *
 * To run a mutation, you first call `useRemoveTrackFromDistributionRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTrackFromDistributionRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTrackFromDistributionRecordMutation, { data, loading, error }] = useRemoveTrackFromDistributionRecordMutation({
 *   variables: {
 *      recordId: // value for 'recordId'
 *      trackId: // value for 'trackId'
 *   },
 * });
 */
export function useRemoveTrackFromDistributionRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTrackFromDistributionRecordMutation, RemoveTrackFromDistributionRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTrackFromDistributionRecordMutation, RemoveTrackFromDistributionRecordMutationVariables>(RemoveTrackFromDistributionRecordDocument, baseOptions);
      }
export type RemoveTrackFromDistributionRecordMutationHookResult = ReturnType<typeof useRemoveTrackFromDistributionRecordMutation>;
export type RemoveTrackFromDistributionRecordMutationResult = ApolloReactCommon.MutationResult<RemoveTrackFromDistributionRecordMutation>;
export type RemoveTrackFromDistributionRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTrackFromDistributionRecordMutation, RemoveTrackFromDistributionRecordMutationVariables>;
export const ResendUserInviteDocument = gql`
    mutation ResendUserInvite($id: Int!) {
  invitedUser: resendInviteUser(id: $id) {
    ...InvitedUser
  }
}
    ${InvitedUserFragmentDoc}`;
export type ResendUserInviteMutationFn = ApolloReactCommon.MutationFunction<ResendUserInviteMutation, ResendUserInviteMutationVariables>;

/**
 * __useResendUserInviteMutation__
 *
 * To run a mutation, you first call `useResendUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserInviteMutation, { data, loading, error }] = useResendUserInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendUserInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendUserInviteMutation, ResendUserInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendUserInviteMutation, ResendUserInviteMutationVariables>(ResendUserInviteDocument, baseOptions);
      }
export type ResendUserInviteMutationHookResult = ReturnType<typeof useResendUserInviteMutation>;
export type ResendUserInviteMutationResult = ApolloReactCommon.MutationResult<ResendUserInviteMutation>;
export type ResendUserInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendUserInviteMutation, ResendUserInviteMutationVariables>;
export const RewriteLyricsWithChatGptDocument = gql`
    mutation RewriteLyricsWithChatGPT($lyrics: String!) {
  rewriteLyricsWithChatGPT(lyrics: $lyrics) {
    ...OpenAIPromptRecordForTestingDialog
  }
}
    ${OpenAiPromptRecordForTestingDialogFragmentDoc}`;
export type RewriteLyricsWithChatGptMutationFn = ApolloReactCommon.MutationFunction<RewriteLyricsWithChatGptMutation, RewriteLyricsWithChatGptMutationVariables>;

/**
 * __useRewriteLyricsWithChatGptMutation__
 *
 * To run a mutation, you first call `useRewriteLyricsWithChatGptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRewriteLyricsWithChatGptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rewriteLyricsWithChatGptMutation, { data, loading, error }] = useRewriteLyricsWithChatGptMutation({
 *   variables: {
 *      lyrics: // value for 'lyrics'
 *   },
 * });
 */
export function useRewriteLyricsWithChatGptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RewriteLyricsWithChatGptMutation, RewriteLyricsWithChatGptMutationVariables>) {
        return ApolloReactHooks.useMutation<RewriteLyricsWithChatGptMutation, RewriteLyricsWithChatGptMutationVariables>(RewriteLyricsWithChatGptDocument, baseOptions);
      }
export type RewriteLyricsWithChatGptMutationHookResult = ReturnType<typeof useRewriteLyricsWithChatGptMutation>;
export type RewriteLyricsWithChatGptMutationResult = ApolloReactCommon.MutationResult<RewriteLyricsWithChatGptMutation>;
export type RewriteLyricsWithChatGptMutationOptions = ApolloReactCommon.BaseMutationOptions<RewriteLyricsWithChatGptMutation, RewriteLyricsWithChatGptMutationVariables>;
export const SetMultiExcludedResourcesDocument = gql`
    mutation setMultiExcludedResources($resource_id: [Int]!) {
  setMultiExcludedResources(resource_id: $resource_id) {
    id
    slug
    display_name
    type
    access
    value
  }
}
    `;
export type SetMultiExcludedResourcesMutationFn = ApolloReactCommon.MutationFunction<SetMultiExcludedResourcesMutation, SetMultiExcludedResourcesMutationVariables>;

/**
 * __useSetMultiExcludedResourcesMutation__
 *
 * To run a mutation, you first call `useSetMultiExcludedResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMultiExcludedResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMultiExcludedResourcesMutation, { data, loading, error }] = useSetMultiExcludedResourcesMutation({
 *   variables: {
 *      resource_id: // value for 'resource_id'
 *   },
 * });
 */
export function useSetMultiExcludedResourcesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetMultiExcludedResourcesMutation, SetMultiExcludedResourcesMutationVariables>) {
        return ApolloReactHooks.useMutation<SetMultiExcludedResourcesMutation, SetMultiExcludedResourcesMutationVariables>(SetMultiExcludedResourcesDocument, baseOptions);
      }
export type SetMultiExcludedResourcesMutationHookResult = ReturnType<typeof useSetMultiExcludedResourcesMutation>;
export type SetMultiExcludedResourcesMutationResult = ApolloReactCommon.MutationResult<SetMultiExcludedResourcesMutation>;
export type SetMultiExcludedResourcesMutationOptions = ApolloReactCommon.BaseMutationOptions<SetMultiExcludedResourcesMutation, SetMultiExcludedResourcesMutationVariables>;
export const SetUserStatusDocument = gql`
    mutation SetUserStatus($id: Int!, $status: UserStatus!) {
  updatedUser: setUserStatus(user_id: $id, status: $status) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type SetUserStatusMutationFn = ApolloReactCommon.MutationFunction<SetUserStatusMutation, SetUserStatusMutationVariables>;

/**
 * __useSetUserStatusMutation__
 *
 * To run a mutation, you first call `useSetUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserStatusMutation, { data, loading, error }] = useSetUserStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetUserStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetUserStatusMutation, SetUserStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<SetUserStatusMutation, SetUserStatusMutationVariables>(SetUserStatusDocument, baseOptions);
      }
export type SetUserStatusMutationHookResult = ReturnType<typeof useSetUserStatusMutation>;
export type SetUserStatusMutationResult = ApolloReactCommon.MutationResult<SetUserStatusMutation>;
export type SetUserStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<SetUserStatusMutation, SetUserStatusMutationVariables>;
export const SynchronizeAndGetDistributionRecordDocument = gql`
    mutation SynchronizeAndGetDistributionRecord($type: DistributionType!, $relatedId: Int!) {
  synchronizeAndGetDistributionRecord(type: $type, relatedId: $relatedId) {
    ...DistributionRecord
  }
}
    ${DistributionRecordFragmentDoc}`;
export type SynchronizeAndGetDistributionRecordMutationFn = ApolloReactCommon.MutationFunction<SynchronizeAndGetDistributionRecordMutation, SynchronizeAndGetDistributionRecordMutationVariables>;

/**
 * __useSynchronizeAndGetDistributionRecordMutation__
 *
 * To run a mutation, you first call `useSynchronizeAndGetDistributionRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSynchronizeAndGetDistributionRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [synchronizeAndGetDistributionRecordMutation, { data, loading, error }] = useSynchronizeAndGetDistributionRecordMutation({
 *   variables: {
 *      type: // value for 'type'
 *      relatedId: // value for 'relatedId'
 *   },
 * });
 */
export function useSynchronizeAndGetDistributionRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SynchronizeAndGetDistributionRecordMutation, SynchronizeAndGetDistributionRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<SynchronizeAndGetDistributionRecordMutation, SynchronizeAndGetDistributionRecordMutationVariables>(SynchronizeAndGetDistributionRecordDocument, baseOptions);
      }
export type SynchronizeAndGetDistributionRecordMutationHookResult = ReturnType<typeof useSynchronizeAndGetDistributionRecordMutation>;
export type SynchronizeAndGetDistributionRecordMutationResult = ApolloReactCommon.MutationResult<SynchronizeAndGetDistributionRecordMutation>;
export type SynchronizeAndGetDistributionRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<SynchronizeAndGetDistributionRecordMutation, SynchronizeAndGetDistributionRecordMutationVariables>;
export const SynchronizeAndGetDistributionRecordByIdDocument = gql`
    mutation SynchronizeAndGetDistributionRecordById($id: Int!) {
  synchronizeAndGetDistributionRecordById(id: $id) {
    ...DistributionRecord
  }
}
    ${DistributionRecordFragmentDoc}`;
export type SynchronizeAndGetDistributionRecordByIdMutationFn = ApolloReactCommon.MutationFunction<SynchronizeAndGetDistributionRecordByIdMutation, SynchronizeAndGetDistributionRecordByIdMutationVariables>;

/**
 * __useSynchronizeAndGetDistributionRecordByIdMutation__
 *
 * To run a mutation, you first call `useSynchronizeAndGetDistributionRecordByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSynchronizeAndGetDistributionRecordByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [synchronizeAndGetDistributionRecordByIdMutation, { data, loading, error }] = useSynchronizeAndGetDistributionRecordByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSynchronizeAndGetDistributionRecordByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SynchronizeAndGetDistributionRecordByIdMutation, SynchronizeAndGetDistributionRecordByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<SynchronizeAndGetDistributionRecordByIdMutation, SynchronizeAndGetDistributionRecordByIdMutationVariables>(SynchronizeAndGetDistributionRecordByIdDocument, baseOptions);
      }
export type SynchronizeAndGetDistributionRecordByIdMutationHookResult = ReturnType<typeof useSynchronizeAndGetDistributionRecordByIdMutation>;
export type SynchronizeAndGetDistributionRecordByIdMutationResult = ApolloReactCommon.MutationResult<SynchronizeAndGetDistributionRecordByIdMutation>;
export type SynchronizeAndGetDistributionRecordByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<SynchronizeAndGetDistributionRecordByIdMutation, SynchronizeAndGetDistributionRecordByIdMutationVariables>;
export const TakeDownDistributionRecordDocument = gql`
    mutation TakeDownDistributionRecord($id: Int!) {
  takeDownDistributionRecord(id: $id) {
    ...DistributionRecord
  }
}
    ${DistributionRecordFragmentDoc}`;
export type TakeDownDistributionRecordMutationFn = ApolloReactCommon.MutationFunction<TakeDownDistributionRecordMutation, TakeDownDistributionRecordMutationVariables>;

/**
 * __useTakeDownDistributionRecordMutation__
 *
 * To run a mutation, you first call `useTakeDownDistributionRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeDownDistributionRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeDownDistributionRecordMutation, { data, loading, error }] = useTakeDownDistributionRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTakeDownDistributionRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TakeDownDistributionRecordMutation, TakeDownDistributionRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<TakeDownDistributionRecordMutation, TakeDownDistributionRecordMutationVariables>(TakeDownDistributionRecordDocument, baseOptions);
      }
export type TakeDownDistributionRecordMutationHookResult = ReturnType<typeof useTakeDownDistributionRecordMutation>;
export type TakeDownDistributionRecordMutationResult = ApolloReactCommon.MutationResult<TakeDownDistributionRecordMutation>;
export type TakeDownDistributionRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<TakeDownDistributionRecordMutation, TakeDownDistributionRecordMutationVariables>;
export const UnAssignAdminDocument = gql`
    mutation UnAssignAdmin($id: Int!) {
  updatedUser: unassignAdmin(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UnAssignAdminMutationFn = ApolloReactCommon.MutationFunction<UnAssignAdminMutation, UnAssignAdminMutationVariables>;

/**
 * __useUnAssignAdminMutation__
 *
 * To run a mutation, you first call `useUnAssignAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnAssignAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unAssignAdminMutation, { data, loading, error }] = useUnAssignAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnAssignAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnAssignAdminMutation, UnAssignAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<UnAssignAdminMutation, UnAssignAdminMutationVariables>(UnAssignAdminDocument, baseOptions);
      }
export type UnAssignAdminMutationHookResult = ReturnType<typeof useUnAssignAdminMutation>;
export type UnAssignAdminMutationResult = ApolloReactCommon.MutationResult<UnAssignAdminMutation>;
export type UnAssignAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<UnAssignAdminMutation, UnAssignAdminMutationVariables>;
export const UpdateAlbumDocument = gql`
    mutation UpdateAlbum($id: Int!, $input: CollectionInput!) {
  updateCollection(id: $id, data: $input) {
    ...CollectionFields
  }
}
    ${CollectionFieldsFragmentDoc}`;
export type UpdateAlbumMutationFn = ApolloReactCommon.MutationFunction<UpdateAlbumMutation, UpdateAlbumMutationVariables>;

/**
 * __useUpdateAlbumMutation__
 *
 * To run a mutation, you first call `useUpdateAlbumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlbumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlbumMutation, { data, loading, error }] = useUpdateAlbumMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlbumMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAlbumMutation, UpdateAlbumMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAlbumMutation, UpdateAlbumMutationVariables>(UpdateAlbumDocument, baseOptions);
      }
export type UpdateAlbumMutationHookResult = ReturnType<typeof useUpdateAlbumMutation>;
export type UpdateAlbumMutationResult = ApolloReactCommon.MutationResult<UpdateAlbumMutation>;
export type UpdateAlbumMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAlbumMutation, UpdateAlbumMutationVariables>;
export const UpdateAlbumAndCoverDocument = gql`
    mutation UpdateAlbumAndCover($id: Int!, $input: CollectionInput!, $fileName: String!, $attach: Boolean) {
  updateCollection(id: $id, data: $input) {
    ...CollectionFields
  }
  attachCollectionCoverArt(id: $id, data: {file_name: $fileName, attach: $attach}) {
    id
    cover_art {
      id
      media_url
      file_name
    }
  }
}
    ${CollectionFieldsFragmentDoc}`;
export type UpdateAlbumAndCoverMutationFn = ApolloReactCommon.MutationFunction<UpdateAlbumAndCoverMutation, UpdateAlbumAndCoverMutationVariables>;

/**
 * __useUpdateAlbumAndCoverMutation__
 *
 * To run a mutation, you first call `useUpdateAlbumAndCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlbumAndCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlbumAndCoverMutation, { data, loading, error }] = useUpdateAlbumAndCoverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      fileName: // value for 'fileName'
 *      attach: // value for 'attach'
 *   },
 * });
 */
export function useUpdateAlbumAndCoverMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAlbumAndCoverMutation, UpdateAlbumAndCoverMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAlbumAndCoverMutation, UpdateAlbumAndCoverMutationVariables>(UpdateAlbumAndCoverDocument, baseOptions);
      }
export type UpdateAlbumAndCoverMutationHookResult = ReturnType<typeof useUpdateAlbumAndCoverMutation>;
export type UpdateAlbumAndCoverMutationResult = ApolloReactCommon.MutationResult<UpdateAlbumAndCoverMutation>;
export type UpdateAlbumAndCoverMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAlbumAndCoverMutation, UpdateAlbumAndCoverMutationVariables>;
export const UpdateAlbumEvearaDocument = gql`
    mutation UpdateAlbumEveara($updateAlbumEvearaRecordInput: UpdateAlbumEvearaRecordInput!) {
  updateAlbumEveara: updateAlbumEveara(data: $updateAlbumEvearaRecordInput) {
    isSuccess: success
    message
  }
}
    `;
export type UpdateAlbumEvearaMutationFn = ApolloReactCommon.MutationFunction<UpdateAlbumEvearaMutation, UpdateAlbumEvearaMutationVariables>;

/**
 * __useUpdateAlbumEvearaMutation__
 *
 * To run a mutation, you first call `useUpdateAlbumEvearaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlbumEvearaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlbumEvearaMutation, { data, loading, error }] = useUpdateAlbumEvearaMutation({
 *   variables: {
 *      updateAlbumEvearaRecordInput: // value for 'updateAlbumEvearaRecordInput'
 *   },
 * });
 */
export function useUpdateAlbumEvearaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAlbumEvearaMutation, UpdateAlbumEvearaMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAlbumEvearaMutation, UpdateAlbumEvearaMutationVariables>(UpdateAlbumEvearaDocument, baseOptions);
      }
export type UpdateAlbumEvearaMutationHookResult = ReturnType<typeof useUpdateAlbumEvearaMutation>;
export type UpdateAlbumEvearaMutationResult = ApolloReactCommon.MutationResult<UpdateAlbumEvearaMutation>;
export type UpdateAlbumEvearaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAlbumEvearaMutation, UpdateAlbumEvearaMutationVariables>;
export const UpdateArtistProfileDocument = gql`
    mutation UpdateArtistProfile($artistProfileData: ArtistProfileInput!) {
  updateCurrentUser(data: {profile: {artist_profile: $artistProfileData}}) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateArtistProfileMutationFn = ApolloReactCommon.MutationFunction<UpdateArtistProfileMutation, UpdateArtistProfileMutationVariables>;

/**
 * __useUpdateArtistProfileMutation__
 *
 * To run a mutation, you first call `useUpdateArtistProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtistProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtistProfileMutation, { data, loading, error }] = useUpdateArtistProfileMutation({
 *   variables: {
 *      artistProfileData: // value for 'artistProfileData'
 *   },
 * });
 */
export function useUpdateArtistProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArtistProfileMutation, UpdateArtistProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArtistProfileMutation, UpdateArtistProfileMutationVariables>(UpdateArtistProfileDocument, baseOptions);
      }
export type UpdateArtistProfileMutationHookResult = ReturnType<typeof useUpdateArtistProfileMutation>;
export type UpdateArtistProfileMutationResult = ApolloReactCommon.MutationResult<UpdateArtistProfileMutation>;
export type UpdateArtistProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArtistProfileMutation, UpdateArtistProfileMutationVariables>;
export const UpdateContractDocument = gql`
    mutation UpdateContract($id: Int!, $title: String!, $masterId: Int!, $description: String, $type: ContractType!, $fileId: Int) {
  updateContract(id: $id, data: {title: $title, master_id: $masterId, type: $type, metadata: {description: $description}, file_id: $fileId}) {
    id
    ...SplitSheetFields
    ...UploadedContractFields
  }
}
    ${SplitSheetFieldsFragmentDoc}
${UploadedContractFieldsFragmentDoc}`;
export type UpdateContractMutationFn = ApolloReactCommon.MutationFunction<UpdateContractMutation, UpdateContractMutationVariables>;

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      masterId: // value for 'masterId'
 *      description: // value for 'description'
 *      type: // value for 'type'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useUpdateContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractMutation, UpdateContractMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(UpdateContractDocument, baseOptions);
      }
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>;
export type UpdateContractMutationResult = ApolloReactCommon.MutationResult<UpdateContractMutation>;
export type UpdateContractMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractMutation, UpdateContractMutationVariables>;
export const UpdateContractPartyDocument = gql`
    mutation UpdateContractParty($id: Int!, $name: String, $contact: String, $roleLabel: String, $contractId: Int!, $metadata: ContractPartyMetaInput) {
  updateContractParty(id: $id, data: {name: $name, contact: $contact, role_label: $roleLabel, contract_id: $contractId, role: AUTHOR, metadata: $metadata}) {
    id
    name
  }
}
    `;
export type UpdateContractPartyMutationFn = ApolloReactCommon.MutationFunction<UpdateContractPartyMutation, UpdateContractPartyMutationVariables>;

/**
 * __useUpdateContractPartyMutation__
 *
 * To run a mutation, you first call `useUpdateContractPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractPartyMutation, { data, loading, error }] = useUpdateContractPartyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      contact: // value for 'contact'
 *      roleLabel: // value for 'roleLabel'
 *      contractId: // value for 'contractId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateContractPartyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractPartyMutation, UpdateContractPartyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractPartyMutation, UpdateContractPartyMutationVariables>(UpdateContractPartyDocument, baseOptions);
      }
export type UpdateContractPartyMutationHookResult = ReturnType<typeof useUpdateContractPartyMutation>;
export type UpdateContractPartyMutationResult = ApolloReactCommon.MutationResult<UpdateContractPartyMutation>;
export type UpdateContractPartyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractPartyMutation, UpdateContractPartyMutationVariables>;
export const UpdateDistributionRecordDocument = gql`
    mutation UpdateDistributionRecord($id: Int!, $data: UpdateDistributionRecordInput!) {
  updateDistributionRecord(id: $id, data: $data) {
    ...DistributionRecord
  }
}
    ${DistributionRecordFragmentDoc}`;
export type UpdateDistributionRecordMutationFn = ApolloReactCommon.MutationFunction<UpdateDistributionRecordMutation, UpdateDistributionRecordMutationVariables>;

/**
 * __useUpdateDistributionRecordMutation__
 *
 * To run a mutation, you first call `useUpdateDistributionRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistributionRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistributionRecordMutation, { data, loading, error }] = useUpdateDistributionRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDistributionRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDistributionRecordMutation, UpdateDistributionRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDistributionRecordMutation, UpdateDistributionRecordMutationVariables>(UpdateDistributionRecordDocument, baseOptions);
      }
export type UpdateDistributionRecordMutationHookResult = ReturnType<typeof useUpdateDistributionRecordMutation>;
export type UpdateDistributionRecordMutationResult = ApolloReactCommon.MutationResult<UpdateDistributionRecordMutation>;
export type UpdateDistributionRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDistributionRecordMutation, UpdateDistributionRecordMutationVariables>;
export const UpdateDistributionRecordOutletsDetailsDocument = gql`
    mutation UpdateDistributionRecordOutletsDetails($recordId: Int!, $distributeToFutureOutlets: Boolean!, $data: [UpdateDistributionRecordOutletsDetailsInput!]!) {
  updateDistributionRecordOutletsDetails(recordId: $recordId, distributeToFutureOutlets: $distributeToFutureOutlets, data: $data) {
    outlets {
      ...DistributionRecordOutletDetails
    }
    distributeToFutureOutlets
  }
}
    ${DistributionRecordOutletDetailsFragmentDoc}`;
export type UpdateDistributionRecordOutletsDetailsMutationFn = ApolloReactCommon.MutationFunction<UpdateDistributionRecordOutletsDetailsMutation, UpdateDistributionRecordOutletsDetailsMutationVariables>;

/**
 * __useUpdateDistributionRecordOutletsDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateDistributionRecordOutletsDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistributionRecordOutletsDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistributionRecordOutletsDetailsMutation, { data, loading, error }] = useUpdateDistributionRecordOutletsDetailsMutation({
 *   variables: {
 *      recordId: // value for 'recordId'
 *      distributeToFutureOutlets: // value for 'distributeToFutureOutlets'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDistributionRecordOutletsDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDistributionRecordOutletsDetailsMutation, UpdateDistributionRecordOutletsDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDistributionRecordOutletsDetailsMutation, UpdateDistributionRecordOutletsDetailsMutationVariables>(UpdateDistributionRecordOutletsDetailsDocument, baseOptions);
      }
export type UpdateDistributionRecordOutletsDetailsMutationHookResult = ReturnType<typeof useUpdateDistributionRecordOutletsDetailsMutation>;
export type UpdateDistributionRecordOutletsDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateDistributionRecordOutletsDetailsMutation>;
export type UpdateDistributionRecordOutletsDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDistributionRecordOutletsDetailsMutation, UpdateDistributionRecordOutletsDetailsMutationVariables>;
export const UpdateDistributionRecordTracksDocument = gql`
    mutation UpdateDistributionRecordTracks($recordId: Int!, $data: [UpdateDistributionTrackInput!]!) {
  updateDistributionRecordTracks(recordId: $recordId, data: $data) {
    ...DistributionRecordTrack
  }
}
    ${DistributionRecordTrackFragmentDoc}`;
export type UpdateDistributionRecordTracksMutationFn = ApolloReactCommon.MutationFunction<UpdateDistributionRecordTracksMutation, UpdateDistributionRecordTracksMutationVariables>;

/**
 * __useUpdateDistributionRecordTracksMutation__
 *
 * To run a mutation, you first call `useUpdateDistributionRecordTracksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistributionRecordTracksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistributionRecordTracksMutation, { data, loading, error }] = useUpdateDistributionRecordTracksMutation({
 *   variables: {
 *      recordId: // value for 'recordId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDistributionRecordTracksMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDistributionRecordTracksMutation, UpdateDistributionRecordTracksMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDistributionRecordTracksMutation, UpdateDistributionRecordTracksMutationVariables>(UpdateDistributionRecordTracksDocument, baseOptions);
      }
export type UpdateDistributionRecordTracksMutationHookResult = ReturnType<typeof useUpdateDistributionRecordTracksMutation>;
export type UpdateDistributionRecordTracksMutationResult = ApolloReactCommon.MutationResult<UpdateDistributionRecordTracksMutation>;
export type UpdateDistributionRecordTracksMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDistributionRecordTracksMutation, UpdateDistributionRecordTracksMutationVariables>;
export const UpdateMasterDocument = gql`
    mutation UpdateMaster($id: Int!, $data: AMPMasterInput!) {
  updateMaster(id: $id, data: $data) {
    created_at
    updated_at
    id
    title
    is_locked
    fire_node_id
    catalog_id
  }
}
    `;
export type UpdateMasterMutationFn = ApolloReactCommon.MutationFunction<UpdateMasterMutation, UpdateMasterMutationVariables>;

/**
 * __useUpdateMasterMutation__
 *
 * To run a mutation, you first call `useUpdateMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasterMutation, { data, loading, error }] = useUpdateMasterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMasterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMasterMutation, UpdateMasterMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMasterMutation, UpdateMasterMutationVariables>(UpdateMasterDocument, baseOptions);
      }
export type UpdateMasterMutationHookResult = ReturnType<typeof useUpdateMasterMutation>;
export type UpdateMasterMutationResult = ApolloReactCommon.MutationResult<UpdateMasterMutation>;
export type UpdateMasterMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMasterMutation, UpdateMasterMutationVariables>;
export const UpdateMasterMetaDocument = gql`
    mutation updateMasterMeta($id: Int!, $data: AMPMasterMetaInput!) {
  updateMasterMeta(id: $id, data: $data) {
    ...MasterFields
  }
}
    ${MasterFieldsFragmentDoc}`;
export type UpdateMasterMetaMutationFn = ApolloReactCommon.MutationFunction<UpdateMasterMetaMutation, UpdateMasterMetaMutationVariables>;

/**
 * __useUpdateMasterMetaMutation__
 *
 * To run a mutation, you first call `useUpdateMasterMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasterMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasterMetaMutation, { data, loading, error }] = useUpdateMasterMetaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMasterMetaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMasterMetaMutation, UpdateMasterMetaMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMasterMetaMutation, UpdateMasterMetaMutationVariables>(UpdateMasterMetaDocument, baseOptions);
      }
export type UpdateMasterMetaMutationHookResult = ReturnType<typeof useUpdateMasterMetaMutation>;
export type UpdateMasterMetaMutationResult = ApolloReactCommon.MutationResult<UpdateMasterMetaMutation>;
export type UpdateMasterMetaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMasterMetaMutation, UpdateMasterMetaMutationVariables>;
export const UpdateMasterMetadataDocument = gql`
    mutation UpdateMasterMetadata($id: Int!, $tullyMetadata: TullyMetadataInput!) {
  updateMaster(id: $id, data: {tully_metadata: $tullyMetadata}) {
    id
    tully_metadata {
      ...TullyMetadataFields
    }
  }
}
    ${TullyMetadataFieldsFragmentDoc}`;
export type UpdateMasterMetadataMutationFn = ApolloReactCommon.MutationFunction<UpdateMasterMetadataMutation, UpdateMasterMetadataMutationVariables>;

/**
 * __useUpdateMasterMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateMasterMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasterMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasterMetadataMutation, { data, loading, error }] = useUpdateMasterMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tullyMetadata: // value for 'tullyMetadata'
 *   },
 * });
 */
export function useUpdateMasterMetadataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMasterMetadataMutation, UpdateMasterMetadataMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMasterMetadataMutation, UpdateMasterMetadataMutationVariables>(UpdateMasterMetadataDocument, baseOptions);
      }
export type UpdateMasterMetadataMutationHookResult = ReturnType<typeof useUpdateMasterMetadataMutation>;
export type UpdateMasterMetadataMutationResult = ApolloReactCommon.MutationResult<UpdateMasterMetadataMutation>;
export type UpdateMasterMetadataMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMasterMetadataMutation, UpdateMasterMetadataMutationVariables>;
export const UpdateSavedSearchDocument = gql`
    mutation UpdateSavedSearch($data: UpdateSavedSearchInput!, $id: Int!) {
  updatedSearch: updateSavedSearch(data: $data, id: $id) {
    ...SavedSearch
  }
}
    ${SavedSearchFragmentDoc}`;
export type UpdateSavedSearchMutationFn = ApolloReactCommon.MutationFunction<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>;

/**
 * __useUpdateSavedSearchMutation__
 *
 * To run a mutation, you first call `useUpdateSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedSearchMutation, { data, loading, error }] = useUpdateSavedSearchMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSavedSearchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>(UpdateSavedSearchDocument, baseOptions);
      }
export type UpdateSavedSearchMutationHookResult = ReturnType<typeof useUpdateSavedSearchMutation>;
export type UpdateSavedSearchMutationResult = ApolloReactCommon.MutationResult<UpdateSavedSearchMutation>;
export type UpdateSavedSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>;
export const UpdateTullyPersonMetadataDocument = gql`
    mutation UpdateTullyPersonMetadata($id: Int!, $data: TullyPersonMetadataInput!) {
  updateTullyPersonMetadata(id: $id, data: $data) {
    id
    ...TullyPersonMetadataFields
  }
}
    ${TullyPersonMetadataFieldsFragmentDoc}`;
export type UpdateTullyPersonMetadataMutationFn = ApolloReactCommon.MutationFunction<UpdateTullyPersonMetadataMutation, UpdateTullyPersonMetadataMutationVariables>;

/**
 * __useUpdateTullyPersonMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateTullyPersonMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTullyPersonMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTullyPersonMetadataMutation, { data, loading, error }] = useUpdateTullyPersonMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTullyPersonMetadataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTullyPersonMetadataMutation, UpdateTullyPersonMetadataMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTullyPersonMetadataMutation, UpdateTullyPersonMetadataMutationVariables>(UpdateTullyPersonMetadataDocument, baseOptions);
      }
export type UpdateTullyPersonMetadataMutationHookResult = ReturnType<typeof useUpdateTullyPersonMetadataMutation>;
export type UpdateTullyPersonMetadataMutationResult = ApolloReactCommon.MutationResult<UpdateTullyPersonMetadataMutation>;
export type UpdateTullyPersonMetadataMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTullyPersonMetadataMutation, UpdateTullyPersonMetadataMutationVariables>;
export const AccountResourcesDocument = gql`
    query AccountResources {
  resources: getResources {
    ...ResourceFields
  }
}
    ${ResourceFieldsFragmentDoc}`;

/**
 * __useAccountResourcesQuery__
 *
 * To run a query within a React component, call `useAccountResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountResourcesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountResourcesQuery, AccountResourcesQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountResourcesQuery, AccountResourcesQueryVariables>(AccountResourcesDocument, baseOptions);
      }
export function useAccountResourcesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountResourcesQuery, AccountResourcesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountResourcesQuery, AccountResourcesQueryVariables>(AccountResourcesDocument, baseOptions);
        }
export type AccountResourcesQueryHookResult = ReturnType<typeof useAccountResourcesQuery>;
export type AccountResourcesLazyQueryHookResult = ReturnType<typeof useAccountResourcesLazyQuery>;
export type AccountResourcesQueryResult = ApolloReactCommon.QueryResult<AccountResourcesQuery, AccountResourcesQueryVariables>;
export const AccountSubscriptionsDocument = gql`
    query AccountSubscriptions {
  subscriptions: getSubscriptions {
    amount
    display_name
    exclusive
    name
    renewal
    status
    type
    upgrade_index
  }
}
    `;

/**
 * __useAccountSubscriptionsQuery__
 *
 * To run a query within a React component, call `useAccountSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountSubscriptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>(AccountSubscriptionsDocument, baseOptions);
      }
export function useAccountSubscriptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>(AccountSubscriptionsDocument, baseOptions);
        }
export type AccountSubscriptionsQueryHookResult = ReturnType<typeof useAccountSubscriptionsQuery>;
export type AccountSubscriptionsLazyQueryHookResult = ReturnType<typeof useAccountSubscriptionsLazyQuery>;
export type AccountSubscriptionsQueryResult = ApolloReactCommon.QueryResult<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>;
export const AdvancedSearchDocument = gql`
    query AdvancedSearch($cursor: String, $first: Int, $filter: SearchFilterInput, $sort: [SearchSortInput]) {
  search(after: $cursor, first: $first, filter: $filter, sort: $sort) {
    pageInfo {
      ...PageInfoFields
    }
    edges {
      cursor
      node {
        ...SearchItem
      }
    }
  }
}
    ${PageInfoFieldsFragmentDoc}
${SearchItemFragmentDoc}`;

/**
 * __useAdvancedSearchQuery__
 *
 * To run a query within a React component, call `useAdvancedSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancedSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancedSearchQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdvancedSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvancedSearchQuery, AdvancedSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvancedSearchQuery, AdvancedSearchQueryVariables>(AdvancedSearchDocument, baseOptions);
      }
export function useAdvancedSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvancedSearchQuery, AdvancedSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvancedSearchQuery, AdvancedSearchQueryVariables>(AdvancedSearchDocument, baseOptions);
        }
export type AdvancedSearchQueryHookResult = ReturnType<typeof useAdvancedSearchQuery>;
export type AdvancedSearchLazyQueryHookResult = ReturnType<typeof useAdvancedSearchLazyQuery>;
export type AdvancedSearchQueryResult = ApolloReactCommon.QueryResult<AdvancedSearchQuery, AdvancedSearchQueryVariables>;
export const CatalogNamesDocument = gql`
    query CatalogNames {
  catalogs: getCatalogs {
    ...CatalogOption
  }
}
    ${CatalogOptionFragmentDoc}`;

/**
 * __useCatalogNamesQuery__
 *
 * To run a query within a React component, call `useCatalogNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCatalogNamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CatalogNamesQuery, CatalogNamesQueryVariables>) {
        return ApolloReactHooks.useQuery<CatalogNamesQuery, CatalogNamesQueryVariables>(CatalogNamesDocument, baseOptions);
      }
export function useCatalogNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogNamesQuery, CatalogNamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CatalogNamesQuery, CatalogNamesQueryVariables>(CatalogNamesDocument, baseOptions);
        }
export type CatalogNamesQueryHookResult = ReturnType<typeof useCatalogNamesQuery>;
export type CatalogNamesLazyQueryHookResult = ReturnType<typeof useCatalogNamesLazyQuery>;
export type CatalogNamesQueryResult = ApolloReactCommon.QueryResult<CatalogNamesQuery, CatalogNamesQueryVariables>;
export const CollectionNamesDocument = gql`
    query CollectionNames($catalogId: Int!) {
  catalog: getCatalog(id: $catalogId) {
    id
    collections {
      ...CollectionBaseInfo
    }
  }
}
    ${CollectionBaseInfoFragmentDoc}`;

/**
 * __useCollectionNamesQuery__
 *
 * To run a query within a React component, call `useCollectionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionNamesQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useCollectionNamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionNamesQuery, CollectionNamesQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionNamesQuery, CollectionNamesQueryVariables>(CollectionNamesDocument, baseOptions);
      }
export function useCollectionNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionNamesQuery, CollectionNamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionNamesQuery, CollectionNamesQueryVariables>(CollectionNamesDocument, baseOptions);
        }
export type CollectionNamesQueryHookResult = ReturnType<typeof useCollectionNamesQuery>;
export type CollectionNamesLazyQueryHookResult = ReturnType<typeof useCollectionNamesLazyQuery>;
export type CollectionNamesQueryResult = ApolloReactCommon.QueryResult<CollectionNamesQuery, CollectionNamesQueryVariables>;
export const CollectionsDocument = gql`
    query Collections($catalogId: Int!) {
  catalog: getCatalog(id: $catalogId) {
    id
    collections {
      ...CollectionFields
    }
  }
}
    ${CollectionFieldsFragmentDoc}`;

/**
 * __useCollectionsQuery__
 *
 * To run a query within a React component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useCollectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, baseOptions);
      }
export function useCollectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, baseOptions);
        }
export type CollectionsQueryHookResult = ReturnType<typeof useCollectionsQuery>;
export type CollectionsLazyQueryHookResult = ReturnType<typeof useCollectionsLazyQuery>;
export type CollectionsQueryResult = ApolloReactCommon.QueryResult<CollectionsQuery, CollectionsQueryVariables>;
export const CollectionsListDocument = gql`
    query CollectionsList($catalogId: Int!) {
  catalog: getCatalog(id: $catalogId) {
    id
    collections {
      ...CollectionListFields
    }
  }
}
    ${CollectionListFieldsFragmentDoc}`;

/**
 * __useCollectionsListQuery__
 *
 * To run a query within a React component, call `useCollectionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsListQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useCollectionsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionsListQuery, CollectionsListQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionsListQuery, CollectionsListQueryVariables>(CollectionsListDocument, baseOptions);
      }
export function useCollectionsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionsListQuery, CollectionsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionsListQuery, CollectionsListQueryVariables>(CollectionsListDocument, baseOptions);
        }
export type CollectionsListQueryHookResult = ReturnType<typeof useCollectionsListQuery>;
export type CollectionsListLazyQueryHookResult = ReturnType<typeof useCollectionsListLazyQuery>;
export type CollectionsListQueryResult = ApolloReactCommon.QueryResult<CollectionsListQuery, CollectionsListQueryVariables>;
export const GetActionCountersDocument = gql`
    query GetActionCounters {
  actionCounters: getActionCounters {
    activity
    assignedToMe
  }
}
    `;

/**
 * __useGetActionCountersQuery__
 *
 * To run a query within a React component, call `useGetActionCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionCountersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActionCountersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActionCountersQuery, GetActionCountersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActionCountersQuery, GetActionCountersQueryVariables>(GetActionCountersDocument, baseOptions);
      }
export function useGetActionCountersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActionCountersQuery, GetActionCountersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActionCountersQuery, GetActionCountersQueryVariables>(GetActionCountersDocument, baseOptions);
        }
export type GetActionCountersQueryHookResult = ReturnType<typeof useGetActionCountersQuery>;
export type GetActionCountersLazyQueryHookResult = ReturnType<typeof useGetActionCountersLazyQuery>;
export type GetActionCountersQueryResult = ApolloReactCommon.QueryResult<GetActionCountersQuery, GetActionCountersQueryVariables>;
export const GetActionsInRangeDocument = gql`
    query GetActionsInRange($cursor: String, $first: Int, $where: ActionFilter, $category: ActionCategory) {
  getActionsConnection(after: $cursor, first: $first, where: $where, category: $category) {
    pageInfo {
      ...PageInfoFields
    }
    edges {
      cursor
      node {
        ...ActionFields
      }
    }
  }
}
    ${PageInfoFieldsFragmentDoc}
${ActionFieldsFragmentDoc}`;

/**
 * __useGetActionsInRangeQuery__
 *
 * To run a query within a React component, call `useGetActionsInRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionsInRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionsInRangeQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *      where: // value for 'where'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetActionsInRangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActionsInRangeQuery, GetActionsInRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActionsInRangeQuery, GetActionsInRangeQueryVariables>(GetActionsInRangeDocument, baseOptions);
      }
export function useGetActionsInRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActionsInRangeQuery, GetActionsInRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActionsInRangeQuery, GetActionsInRangeQueryVariables>(GetActionsInRangeDocument, baseOptions);
        }
export type GetActionsInRangeQueryHookResult = ReturnType<typeof useGetActionsInRangeQuery>;
export type GetActionsInRangeLazyQueryHookResult = ReturnType<typeof useGetActionsInRangeLazyQuery>;
export type GetActionsInRangeQueryResult = ApolloReactCommon.QueryResult<GetActionsInRangeQuery, GetActionsInRangeQueryVariables>;
export const GetAlbumDataEvearaDocument = gql`
    query GetAlbumDataEveara($id: Int!, $type: AlbumType) {
  evearaAlbum: getAlbumDataEveara(id: $id, type: $type) {
    ...NewAlbumEveara
  }
}
    ${NewAlbumEvearaFragmentDoc}`;

/**
 * __useGetAlbumDataEvearaQuery__
 *
 * To run a query within a React component, call `useGetAlbumDataEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlbumDataEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlbumDataEvearaQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetAlbumDataEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlbumDataEvearaQuery, GetAlbumDataEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAlbumDataEvearaQuery, GetAlbumDataEvearaQueryVariables>(GetAlbumDataEvearaDocument, baseOptions);
      }
export function useGetAlbumDataEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlbumDataEvearaQuery, GetAlbumDataEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAlbumDataEvearaQuery, GetAlbumDataEvearaQueryVariables>(GetAlbumDataEvearaDocument, baseOptions);
        }
export type GetAlbumDataEvearaQueryHookResult = ReturnType<typeof useGetAlbumDataEvearaQuery>;
export type GetAlbumDataEvearaLazyQueryHookResult = ReturnType<typeof useGetAlbumDataEvearaLazyQuery>;
export type GetAlbumDataEvearaQueryResult = ApolloReactCommon.QueryResult<GetAlbumDataEvearaQuery, GetAlbumDataEvearaQueryVariables>;
export const GetAlbumEvearaDocument = gql`
    query GetAlbumEveara($releaseId: Float) {
  evearaAlbum: getAlbumEveara(releaseId: $releaseId) {
    ...AlbumEvearaData
  }
}
    ${AlbumEvearaDataFragmentDoc}`;

/**
 * __useGetAlbumEvearaQuery__
 *
 * To run a query within a React component, call `useGetAlbumEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlbumEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlbumEvearaQuery({
 *   variables: {
 *      releaseId: // value for 'releaseId'
 *   },
 * });
 */
export function useGetAlbumEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlbumEvearaQuery, GetAlbumEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAlbumEvearaQuery, GetAlbumEvearaQueryVariables>(GetAlbumEvearaDocument, baseOptions);
      }
export function useGetAlbumEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlbumEvearaQuery, GetAlbumEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAlbumEvearaQuery, GetAlbumEvearaQueryVariables>(GetAlbumEvearaDocument, baseOptions);
        }
export type GetAlbumEvearaQueryHookResult = ReturnType<typeof useGetAlbumEvearaQuery>;
export type GetAlbumEvearaLazyQueryHookResult = ReturnType<typeof useGetAlbumEvearaLazyQuery>;
export type GetAlbumEvearaQueryResult = ApolloReactCommon.QueryResult<GetAlbumEvearaQuery, GetAlbumEvearaQueryVariables>;
export const GetAlbumRecordEvearaDocument = gql`
    query GetAlbumRecordEveara($id: Int!) {
  evearaAlbum: getAlbumRecordEveara(id: $id) {
    ...NewAlbumEveara
  }
}
    ${NewAlbumEvearaFragmentDoc}`;

/**
 * __useGetAlbumRecordEvearaQuery__
 *
 * To run a query within a React component, call `useGetAlbumRecordEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlbumRecordEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlbumRecordEvearaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAlbumRecordEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlbumRecordEvearaQuery, GetAlbumRecordEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAlbumRecordEvearaQuery, GetAlbumRecordEvearaQueryVariables>(GetAlbumRecordEvearaDocument, baseOptions);
      }
export function useGetAlbumRecordEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlbumRecordEvearaQuery, GetAlbumRecordEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAlbumRecordEvearaQuery, GetAlbumRecordEvearaQueryVariables>(GetAlbumRecordEvearaDocument, baseOptions);
        }
export type GetAlbumRecordEvearaQueryHookResult = ReturnType<typeof useGetAlbumRecordEvearaQuery>;
export type GetAlbumRecordEvearaLazyQueryHookResult = ReturnType<typeof useGetAlbumRecordEvearaLazyQuery>;
export type GetAlbumRecordEvearaQueryResult = ApolloReactCommon.QueryResult<GetAlbumRecordEvearaQuery, GetAlbumRecordEvearaQueryVariables>;
export const GetAuthorsDocument = gql`
    query GetAuthors {
  authors: getAuthors {
    ...Author
  }
}
    ${AuthorFragmentDoc}`;

/**
 * __useGetAuthorsQuery__
 *
 * To run a query within a React component, call `useGetAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuthorsQuery, GetAuthorsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAuthorsQuery, GetAuthorsQueryVariables>(GetAuthorsDocument, baseOptions);
      }
export function useGetAuthorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuthorsQuery, GetAuthorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAuthorsQuery, GetAuthorsQueryVariables>(GetAuthorsDocument, baseOptions);
        }
export type GetAuthorsQueryHookResult = ReturnType<typeof useGetAuthorsQuery>;
export type GetAuthorsLazyQueryHookResult = ReturnType<typeof useGetAuthorsLazyQuery>;
export type GetAuthorsQueryResult = ApolloReactCommon.QueryResult<GetAuthorsQuery, GetAuthorsQueryVariables>;
export const GetAvailabilitiesEvearaDocument = gql`
    query GetAvailabilitiesEveara {
  evearaAvailabilities: getAvailabilitiesEveara {
    ...EvearaAvailability
  }
}
    ${EvearaAvailabilityFragmentDoc}`;

/**
 * __useGetAvailabilitiesEvearaQuery__
 *
 * To run a query within a React component, call `useGetAvailabilitiesEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailabilitiesEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailabilitiesEvearaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailabilitiesEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAvailabilitiesEvearaQuery, GetAvailabilitiesEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAvailabilitiesEvearaQuery, GetAvailabilitiesEvearaQueryVariables>(GetAvailabilitiesEvearaDocument, baseOptions);
      }
export function useGetAvailabilitiesEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAvailabilitiesEvearaQuery, GetAvailabilitiesEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAvailabilitiesEvearaQuery, GetAvailabilitiesEvearaQueryVariables>(GetAvailabilitiesEvearaDocument, baseOptions);
        }
export type GetAvailabilitiesEvearaQueryHookResult = ReturnType<typeof useGetAvailabilitiesEvearaQuery>;
export type GetAvailabilitiesEvearaLazyQueryHookResult = ReturnType<typeof useGetAvailabilitiesEvearaLazyQuery>;
export type GetAvailabilitiesEvearaQueryResult = ApolloReactCommon.QueryResult<GetAvailabilitiesEvearaQuery, GetAvailabilitiesEvearaQueryVariables>;
export const GetBeatsKeywordsDocument = gql`
    query GetBeatsKeywords {
  beatsKeywords: getKeywords {
    ...BeatsKeyword
  }
}
    ${BeatsKeywordFragmentDoc}`;

/**
 * __useGetBeatsKeywordsQuery__
 *
 * To run a query within a React component, call `useGetBeatsKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBeatsKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBeatsKeywordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBeatsKeywordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBeatsKeywordsQuery, GetBeatsKeywordsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBeatsKeywordsQuery, GetBeatsKeywordsQueryVariables>(GetBeatsKeywordsDocument, baseOptions);
      }
export function useGetBeatsKeywordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBeatsKeywordsQuery, GetBeatsKeywordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBeatsKeywordsQuery, GetBeatsKeywordsQueryVariables>(GetBeatsKeywordsDocument, baseOptions);
        }
export type GetBeatsKeywordsQueryHookResult = ReturnType<typeof useGetBeatsKeywordsQuery>;
export type GetBeatsKeywordsLazyQueryHookResult = ReturnType<typeof useGetBeatsKeywordsLazyQuery>;
export type GetBeatsKeywordsQueryResult = ApolloReactCommon.QueryResult<GetBeatsKeywordsQuery, GetBeatsKeywordsQueryVariables>;
export const GetCatalogMastersDocument = gql`
    query GetCatalogMasters($catalogId: Int!, $offset: Int!, $limit: Int = 10) {
  getCatalog(id: $catalogId) {
    id
    masters(page: {limit: $limit, offset: $offset}) {
      ...MasterFields
    }
    masterCount @client
  }
}
    ${MasterFieldsFragmentDoc}`;

/**
 * __useGetCatalogMastersQuery__
 *
 * To run a query within a React component, call `useGetCatalogMastersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogMastersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogMastersQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCatalogMastersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCatalogMastersQuery, GetCatalogMastersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCatalogMastersQuery, GetCatalogMastersQueryVariables>(GetCatalogMastersDocument, baseOptions);
      }
export function useGetCatalogMastersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCatalogMastersQuery, GetCatalogMastersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCatalogMastersQuery, GetCatalogMastersQueryVariables>(GetCatalogMastersDocument, baseOptions);
        }
export type GetCatalogMastersQueryHookResult = ReturnType<typeof useGetCatalogMastersQuery>;
export type GetCatalogMastersLazyQueryHookResult = ReturnType<typeof useGetCatalogMastersLazyQuery>;
export type GetCatalogMastersQueryResult = ApolloReactCommon.QueryResult<GetCatalogMastersQuery, GetCatalogMastersQueryVariables>;
export const GetCatalogMastersInfoOnlyDocument = gql`
    query GetCatalogMastersInfoOnly($catalogId: Int!, $offset: Int!, $limit: Int = 10) {
  getCatalog(id: $catalogId) {
    id
    masterCount @client
    masters(page: {offset: $offset, limit: $limit}) {
      ...MasterInfoFields
    }
  }
}
    ${MasterInfoFieldsFragmentDoc}`;

/**
 * __useGetCatalogMastersInfoOnlyQuery__
 *
 * To run a query within a React component, call `useGetCatalogMastersInfoOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogMastersInfoOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogMastersInfoOnlyQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCatalogMastersInfoOnlyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCatalogMastersInfoOnlyQuery, GetCatalogMastersInfoOnlyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCatalogMastersInfoOnlyQuery, GetCatalogMastersInfoOnlyQueryVariables>(GetCatalogMastersInfoOnlyDocument, baseOptions);
      }
export function useGetCatalogMastersInfoOnlyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCatalogMastersInfoOnlyQuery, GetCatalogMastersInfoOnlyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCatalogMastersInfoOnlyQuery, GetCatalogMastersInfoOnlyQueryVariables>(GetCatalogMastersInfoOnlyDocument, baseOptions);
        }
export type GetCatalogMastersInfoOnlyQueryHookResult = ReturnType<typeof useGetCatalogMastersInfoOnlyQuery>;
export type GetCatalogMastersInfoOnlyLazyQueryHookResult = ReturnType<typeof useGetCatalogMastersInfoOnlyLazyQuery>;
export type GetCatalogMastersInfoOnlyQueryResult = ApolloReactCommon.QueryResult<GetCatalogMastersInfoOnlyQuery, GetCatalogMastersInfoOnlyQueryVariables>;
export const GetCatalogProjectsDocument = gql`
    query GetCatalogProjects($catalogId: Int!, $offset: Int!, $limit: Int = 2) {
  getCatalog(id: $catalogId) {
    id
    projects(page: {limit: $limit, offset: $offset}) {
      ...ProjectFields
    }
    projectCount @client
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useGetCatalogProjectsQuery__
 *
 * To run a query within a React component, call `useGetCatalogProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogProjectsQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCatalogProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCatalogProjectsQuery, GetCatalogProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCatalogProjectsQuery, GetCatalogProjectsQueryVariables>(GetCatalogProjectsDocument, baseOptions);
      }
export function useGetCatalogProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCatalogProjectsQuery, GetCatalogProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCatalogProjectsQuery, GetCatalogProjectsQueryVariables>(GetCatalogProjectsDocument, baseOptions);
        }
export type GetCatalogProjectsQueryHookResult = ReturnType<typeof useGetCatalogProjectsQuery>;
export type GetCatalogProjectsLazyQueryHookResult = ReturnType<typeof useGetCatalogProjectsLazyQuery>;
export type GetCatalogProjectsQueryResult = ApolloReactCommon.QueryResult<GetCatalogProjectsQuery, GetCatalogProjectsQueryVariables>;
export const GetCatalogsDocument = gql`
    query GetCatalogs {
  getCatalogs {
    id
    masters {
      ...MasterFields
    }
    projects {
      ...ProjectFields
    }
    ...ImportedBeats
    ...TullyBeats
  }
}
    ${MasterFieldsFragmentDoc}
${ProjectFieldsFragmentDoc}
${ImportedBeatsFragmentDoc}
${TullyBeatsFragmentDoc}`;

/**
 * __useGetCatalogsQuery__
 *
 * To run a query within a React component, call `useGetCatalogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCatalogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCatalogsQuery, GetCatalogsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCatalogsQuery, GetCatalogsQueryVariables>(GetCatalogsDocument, baseOptions);
      }
export function useGetCatalogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCatalogsQuery, GetCatalogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCatalogsQuery, GetCatalogsQueryVariables>(GetCatalogsDocument, baseOptions);
        }
export type GetCatalogsQueryHookResult = ReturnType<typeof useGetCatalogsQuery>;
export type GetCatalogsLazyQueryHookResult = ReturnType<typeof useGetCatalogsLazyQuery>;
export type GetCatalogsQueryResult = ApolloReactCommon.QueryResult<GetCatalogsQuery, GetCatalogsQueryVariables>;
export const GetCatalogsListDocument = gql`
    query GetCatalogsList {
  catalogsList: getCatalogs {
    id
    name
  }
}
    `;

/**
 * __useGetCatalogsListQuery__
 *
 * To run a query within a React component, call `useGetCatalogsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCatalogsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCatalogsListQuery, GetCatalogsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCatalogsListQuery, GetCatalogsListQueryVariables>(GetCatalogsListDocument, baseOptions);
      }
export function useGetCatalogsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCatalogsListQuery, GetCatalogsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCatalogsListQuery, GetCatalogsListQueryVariables>(GetCatalogsListDocument, baseOptions);
        }
export type GetCatalogsListQueryHookResult = ReturnType<typeof useGetCatalogsListQuery>;
export type GetCatalogsListLazyQueryHookResult = ReturnType<typeof useGetCatalogsListLazyQuery>;
export type GetCatalogsListQueryResult = ApolloReactCommon.QueryResult<GetCatalogsListQuery, GetCatalogsListQueryVariables>;
export const GetCollectionDocument = gql`
    query GetCollection($collectionId: Int!) {
  getCollections(where: {id: {exact: $collectionId}}) {
    ...CollectionFields
  }
}
    ${CollectionFieldsFragmentDoc}`;

/**
 * __useGetCollectionQuery__
 *
 * To run a query within a React component, call `useGetCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionQuery, GetCollectionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionQuery, GetCollectionQueryVariables>(GetCollectionDocument, baseOptions);
      }
export function useGetCollectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionQuery, GetCollectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionQuery, GetCollectionQueryVariables>(GetCollectionDocument, baseOptions);
        }
export type GetCollectionQueryHookResult = ReturnType<typeof useGetCollectionQuery>;
export type GetCollectionLazyQueryHookResult = ReturnType<typeof useGetCollectionLazyQuery>;
export type GetCollectionQueryResult = ApolloReactCommon.QueryResult<GetCollectionQuery, GetCollectionQueryVariables>;
export const GetCollectionsAvailableForNewDistributionDocument = gql`
    query GetCollectionsAvailableForNewDistribution {
  getCollectionsAvailableForNewDistribution {
    id
    title
  }
}
    `;

/**
 * __useGetCollectionsAvailableForNewDistributionQuery__
 *
 * To run a query within a React component, call `useGetCollectionsAvailableForNewDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsAvailableForNewDistributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsAvailableForNewDistributionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCollectionsAvailableForNewDistributionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionsAvailableForNewDistributionQuery, GetCollectionsAvailableForNewDistributionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionsAvailableForNewDistributionQuery, GetCollectionsAvailableForNewDistributionQueryVariables>(GetCollectionsAvailableForNewDistributionDocument, baseOptions);
      }
export function useGetCollectionsAvailableForNewDistributionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionsAvailableForNewDistributionQuery, GetCollectionsAvailableForNewDistributionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionsAvailableForNewDistributionQuery, GetCollectionsAvailableForNewDistributionQueryVariables>(GetCollectionsAvailableForNewDistributionDocument, baseOptions);
        }
export type GetCollectionsAvailableForNewDistributionQueryHookResult = ReturnType<typeof useGetCollectionsAvailableForNewDistributionQuery>;
export type GetCollectionsAvailableForNewDistributionLazyQueryHookResult = ReturnType<typeof useGetCollectionsAvailableForNewDistributionLazyQuery>;
export type GetCollectionsAvailableForNewDistributionQueryResult = ApolloReactCommon.QueryResult<GetCollectionsAvailableForNewDistributionQuery, GetCollectionsAvailableForNewDistributionQueryVariables>;
export const GetContentTypesDocument = gql`
    query GetContentTypes {
  contentTypes: getContentTypes {
    ...ContentType
  }
}
    ${ContentTypeFragmentDoc}`;

/**
 * __useGetContentTypesQuery__
 *
 * To run a query within a React component, call `useGetContentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContentTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContentTypesQuery, GetContentTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContentTypesQuery, GetContentTypesQueryVariables>(GetContentTypesDocument, baseOptions);
      }
export function useGetContentTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContentTypesQuery, GetContentTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContentTypesQuery, GetContentTypesQueryVariables>(GetContentTypesDocument, baseOptions);
        }
export type GetContentTypesQueryHookResult = ReturnType<typeof useGetContentTypesQuery>;
export type GetContentTypesLazyQueryHookResult = ReturnType<typeof useGetContentTypesLazyQuery>;
export type GetContentTypesQueryResult = ApolloReactCommon.QueryResult<GetContentTypesQuery, GetContentTypesQueryVariables>;
export const GetContractsSummariesDocument = gql`
    query GetContractsSummaries($ids: [Int!]) {
  contractsSummaries: getContractsSummaries(ids: $ids) {
    ...UploadedContractFields
  }
}
    ${UploadedContractFieldsFragmentDoc}`;

/**
 * __useGetContractsSummariesQuery__
 *
 * To run a query within a React component, call `useGetContractsSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsSummariesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetContractsSummariesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractsSummariesQuery, GetContractsSummariesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractsSummariesQuery, GetContractsSummariesQueryVariables>(GetContractsSummariesDocument, baseOptions);
      }
export function useGetContractsSummariesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractsSummariesQuery, GetContractsSummariesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractsSummariesQuery, GetContractsSummariesQueryVariables>(GetContractsSummariesDocument, baseOptions);
        }
export type GetContractsSummariesQueryHookResult = ReturnType<typeof useGetContractsSummariesQuery>;
export type GetContractsSummariesLazyQueryHookResult = ReturnType<typeof useGetContractsSummariesLazyQuery>;
export type GetContractsSummariesQueryResult = ApolloReactCommon.QueryResult<GetContractsSummariesQuery, GetContractsSummariesQueryVariables>;
export const GetCountriesDocument = gql`
    query GetCountries {
  countries: getCountries {
    ...Country
  }
}
    ${CountryFragmentDoc}`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, baseOptions);
      }
export function useGetCountriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, baseOptions);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = ApolloReactCommon.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetCountriesEvearaDocument = gql`
    query GetCountriesEveara {
  evearaCountries: getCountriesEveara {
    ...EvearaCountry
  }
}
    ${EvearaCountryFragmentDoc}`;

/**
 * __useGetCountriesEvearaQuery__
 *
 * To run a query within a React component, call `useGetCountriesEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesEvearaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCountriesEvearaQuery, GetCountriesEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCountriesEvearaQuery, GetCountriesEvearaQueryVariables>(GetCountriesEvearaDocument, baseOptions);
      }
export function useGetCountriesEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCountriesEvearaQuery, GetCountriesEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCountriesEvearaQuery, GetCountriesEvearaQueryVariables>(GetCountriesEvearaDocument, baseOptions);
        }
export type GetCountriesEvearaQueryHookResult = ReturnType<typeof useGetCountriesEvearaQuery>;
export type GetCountriesEvearaLazyQueryHookResult = ReturnType<typeof useGetCountriesEvearaLazyQuery>;
export type GetCountriesEvearaQueryResult = ApolloReactCommon.QueryResult<GetCountriesEvearaQuery, GetCountriesEvearaQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  getCurrentUser {
    ...UserFields
  }
  getCatalogs {
    ...CatalogNameAndUser
  }
  resources: getResources {
    ...ResourceFields
  }
  features @client {
    userManagement
  }
}
    ${UserFieldsFragmentDoc}
${CatalogNameAndUserFragmentDoc}
${ResourceFieldsFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetDistributionRecordsConnectionDocument = gql`
    query GetDistributionRecordsConnection($first: Int, $last: Int, $before: String, $after: String, $filters: GetDistributionRecordsConnectionFiltersInput!) {
  distributionRecordsConnection: getDistributionRecordsConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters) {
    edges {
      ...DistributionRecordEdge
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${DistributionRecordEdgeFragmentDoc}
${PageInfoFieldsFragmentDoc}`;

/**
 * __useGetDistributionRecordsConnectionQuery__
 *
 * To run a query within a React component, call `useGetDistributionRecordsConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributionRecordsConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistributionRecordsConnectionQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDistributionRecordsConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDistributionRecordsConnectionQuery, GetDistributionRecordsConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDistributionRecordsConnectionQuery, GetDistributionRecordsConnectionQueryVariables>(GetDistributionRecordsConnectionDocument, baseOptions);
      }
export function useGetDistributionRecordsConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDistributionRecordsConnectionQuery, GetDistributionRecordsConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDistributionRecordsConnectionQuery, GetDistributionRecordsConnectionQueryVariables>(GetDistributionRecordsConnectionDocument, baseOptions);
        }
export type GetDistributionRecordsConnectionQueryHookResult = ReturnType<typeof useGetDistributionRecordsConnectionQuery>;
export type GetDistributionRecordsConnectionLazyQueryHookResult = ReturnType<typeof useGetDistributionRecordsConnectionLazyQuery>;
export type GetDistributionRecordsConnectionQueryResult = ApolloReactCommon.QueryResult<GetDistributionRecordsConnectionQuery, GetDistributionRecordsConnectionQueryVariables>;
export const GetDistributionRecordsForReleasesTabDocument = gql`
    query GetDistributionRecordsForReleasesTab($first: Int, $last: Int, $before: String, $after: String, $filters: GetDistributionRecordsConnectionFiltersInput!) {
  distributionRecordsForReleasesTab: getDistributionRecordsConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters) {
    edges {
      cursor
      node {
        ...DistributionRecordDataForReleasesTab
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${DistributionRecordDataForReleasesTabFragmentDoc}
${PageInfoFieldsFragmentDoc}`;

/**
 * __useGetDistributionRecordsForReleasesTabQuery__
 *
 * To run a query within a React component, call `useGetDistributionRecordsForReleasesTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributionRecordsForReleasesTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistributionRecordsForReleasesTabQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDistributionRecordsForReleasesTabQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDistributionRecordsForReleasesTabQuery, GetDistributionRecordsForReleasesTabQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDistributionRecordsForReleasesTabQuery, GetDistributionRecordsForReleasesTabQueryVariables>(GetDistributionRecordsForReleasesTabDocument, baseOptions);
      }
export function useGetDistributionRecordsForReleasesTabLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDistributionRecordsForReleasesTabQuery, GetDistributionRecordsForReleasesTabQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDistributionRecordsForReleasesTabQuery, GetDistributionRecordsForReleasesTabQueryVariables>(GetDistributionRecordsForReleasesTabDocument, baseOptions);
        }
export type GetDistributionRecordsForReleasesTabQueryHookResult = ReturnType<typeof useGetDistributionRecordsForReleasesTabQuery>;
export type GetDistributionRecordsForReleasesTabLazyQueryHookResult = ReturnType<typeof useGetDistributionRecordsForReleasesTabLazyQuery>;
export type GetDistributionRecordsForReleasesTabQueryResult = ApolloReactCommon.QueryResult<GetDistributionRecordsForReleasesTabQuery, GetDistributionRecordsForReleasesTabQueryVariables>;
export const GetDraftAlbumEvearaDocument = gql`
    query GetDraftAlbumEveara($id: Float, $albumType: AlbumTypeEveara) {
  evearaDraftAlbum: getDraftAlbumEveara(id: $id, albumType: $albumType) {
    ...NewAlbumEveara
  }
}
    ${NewAlbumEvearaFragmentDoc}`;

/**
 * __useGetDraftAlbumEvearaQuery__
 *
 * To run a query within a React component, call `useGetDraftAlbumEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftAlbumEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftAlbumEvearaQuery({
 *   variables: {
 *      id: // value for 'id'
 *      albumType: // value for 'albumType'
 *   },
 * });
 */
export function useGetDraftAlbumEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDraftAlbumEvearaQuery, GetDraftAlbumEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDraftAlbumEvearaQuery, GetDraftAlbumEvearaQueryVariables>(GetDraftAlbumEvearaDocument, baseOptions);
      }
export function useGetDraftAlbumEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDraftAlbumEvearaQuery, GetDraftAlbumEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDraftAlbumEvearaQuery, GetDraftAlbumEvearaQueryVariables>(GetDraftAlbumEvearaDocument, baseOptions);
        }
export type GetDraftAlbumEvearaQueryHookResult = ReturnType<typeof useGetDraftAlbumEvearaQuery>;
export type GetDraftAlbumEvearaLazyQueryHookResult = ReturnType<typeof useGetDraftAlbumEvearaLazyQuery>;
export type GetDraftAlbumEvearaQueryResult = ApolloReactCommon.QueryResult<GetDraftAlbumEvearaQuery, GetDraftAlbumEvearaQueryVariables>;
export const GetDraftTrackEvearaDocument = gql`
    query GetDraftTrackEveara($id: Float, $albumType: AlbumTypeEveara) {
  evearaDraftTrack: getDraftTrackEveara(id: $id, albumType: $albumType) {
    ...NewTrackEveara
  }
}
    ${NewTrackEvearaFragmentDoc}`;

/**
 * __useGetDraftTrackEvearaQuery__
 *
 * To run a query within a React component, call `useGetDraftTrackEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftTrackEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftTrackEvearaQuery({
 *   variables: {
 *      id: // value for 'id'
 *      albumType: // value for 'albumType'
 *   },
 * });
 */
export function useGetDraftTrackEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDraftTrackEvearaQuery, GetDraftTrackEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDraftTrackEvearaQuery, GetDraftTrackEvearaQueryVariables>(GetDraftTrackEvearaDocument, baseOptions);
      }
export function useGetDraftTrackEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDraftTrackEvearaQuery, GetDraftTrackEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDraftTrackEvearaQuery, GetDraftTrackEvearaQueryVariables>(GetDraftTrackEvearaDocument, baseOptions);
        }
export type GetDraftTrackEvearaQueryHookResult = ReturnType<typeof useGetDraftTrackEvearaQuery>;
export type GetDraftTrackEvearaLazyQueryHookResult = ReturnType<typeof useGetDraftTrackEvearaLazyQuery>;
export type GetDraftTrackEvearaQueryResult = ApolloReactCommon.QueryResult<GetDraftTrackEvearaQuery, GetDraftTrackEvearaQueryVariables>;
export const GetDropsDocument = gql`
    query GetDrops {
  drops: getDrops {
    ...Drop
  }
}
    ${DropFragmentDoc}`;

/**
 * __useGetDropsQuery__
 *
 * To run a query within a React component, call `useGetDropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDropsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDropsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDropsQuery, GetDropsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDropsQuery, GetDropsQueryVariables>(GetDropsDocument, baseOptions);
      }
export function useGetDropsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDropsQuery, GetDropsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDropsQuery, GetDropsQueryVariables>(GetDropsDocument, baseOptions);
        }
export type GetDropsQueryHookResult = ReturnType<typeof useGetDropsQuery>;
export type GetDropsLazyQueryHookResult = ReturnType<typeof useGetDropsLazyQuery>;
export type GetDropsQueryResult = ApolloReactCommon.QueryResult<GetDropsQuery, GetDropsQueryVariables>;
export const GetEvearaArtistsDocument = gql`
    query GetEvearaArtists {
  evearaArtists: getArtistsEveara {
    ...EvearaArtist
  }
}
    ${EvearaArtistFragmentDoc}`;

/**
 * __useGetEvearaArtistsQuery__
 *
 * To run a query within a React component, call `useGetEvearaArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvearaArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvearaArtistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEvearaArtistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEvearaArtistsQuery, GetEvearaArtistsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEvearaArtistsQuery, GetEvearaArtistsQueryVariables>(GetEvearaArtistsDocument, baseOptions);
      }
export function useGetEvearaArtistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEvearaArtistsQuery, GetEvearaArtistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEvearaArtistsQuery, GetEvearaArtistsQueryVariables>(GetEvearaArtistsDocument, baseOptions);
        }
export type GetEvearaArtistsQueryHookResult = ReturnType<typeof useGetEvearaArtistsQuery>;
export type GetEvearaArtistsLazyQueryHookResult = ReturnType<typeof useGetEvearaArtistsLazyQuery>;
export type GetEvearaArtistsQueryResult = ApolloReactCommon.QueryResult<GetEvearaArtistsQuery, GetEvearaArtistsQueryVariables>;
export const GetEvearaLabelsDocument = gql`
    query GetEvearaLabels {
  evearaLabels: getLabelsEveara {
    ...EvearaLabel
  }
}
    ${EvearaLabelFragmentDoc}`;

/**
 * __useGetEvearaLabelsQuery__
 *
 * To run a query within a React component, call `useGetEvearaLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvearaLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvearaLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEvearaLabelsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEvearaLabelsQuery, GetEvearaLabelsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEvearaLabelsQuery, GetEvearaLabelsQueryVariables>(GetEvearaLabelsDocument, baseOptions);
      }
export function useGetEvearaLabelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEvearaLabelsQuery, GetEvearaLabelsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEvearaLabelsQuery, GetEvearaLabelsQueryVariables>(GetEvearaLabelsDocument, baseOptions);
        }
export type GetEvearaLabelsQueryHookResult = ReturnType<typeof useGetEvearaLabelsQuery>;
export type GetEvearaLabelsLazyQueryHookResult = ReturnType<typeof useGetEvearaLabelsLazyQuery>;
export type GetEvearaLabelsQueryResult = ApolloReactCommon.QueryResult<GetEvearaLabelsQuery, GetEvearaLabelsQueryVariables>;
export const GetGenresDocument = gql`
    query GetGenres {
  getGenres {
    ...GenreFields
  }
}
    ${GenreFieldsFragmentDoc}`;

/**
 * __useGetGenresQuery__
 *
 * To run a query within a React component, call `useGetGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGenresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGenresQuery, GetGenresQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGenresQuery, GetGenresQueryVariables>(GetGenresDocument, baseOptions);
      }
export function useGetGenresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGenresQuery, GetGenresQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGenresQuery, GetGenresQueryVariables>(GetGenresDocument, baseOptions);
        }
export type GetGenresQueryHookResult = ReturnType<typeof useGetGenresQuery>;
export type GetGenresLazyQueryHookResult = ReturnType<typeof useGetGenresLazyQuery>;
export type GetGenresQueryResult = ApolloReactCommon.QueryResult<GetGenresQuery, GetGenresQueryVariables>;
export const GetGenresEvearaDocument = gql`
    query GetGenresEveara {
  evearaGenres: getGenresEveara {
    ...EvearaGenre
  }
}
    ${EvearaGenreFragmentDoc}`;

/**
 * __useGetGenresEvearaQuery__
 *
 * To run a query within a React component, call `useGetGenresEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenresEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenresEvearaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGenresEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGenresEvearaQuery, GetGenresEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGenresEvearaQuery, GetGenresEvearaQueryVariables>(GetGenresEvearaDocument, baseOptions);
      }
export function useGetGenresEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGenresEvearaQuery, GetGenresEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGenresEvearaQuery, GetGenresEvearaQueryVariables>(GetGenresEvearaDocument, baseOptions);
        }
export type GetGenresEvearaQueryHookResult = ReturnType<typeof useGetGenresEvearaQuery>;
export type GetGenresEvearaLazyQueryHookResult = ReturnType<typeof useGetGenresEvearaLazyQuery>;
export type GetGenresEvearaQueryResult = ApolloReactCommon.QueryResult<GetGenresEvearaQuery, GetGenresEvearaQueryVariables>;
export const GetImportedBeatsDocument = gql`
    query GetImportedBeats($cursor: String, $first: Int, $search: String, $keywords: [String!], $catalogIds: [Int!]) {
  beats: getAssets(after: $cursor, first: $first, filter: {title: $search, keywords: $keywords, types: [IMPORT_BEAT], catalog_ids: $catalogIds}) {
    pageInfo {
      ...PageInfoFields
    }
    edges {
      cursor
      node {
        ...ImportedBeatItem
      }
    }
  }
}
    ${PageInfoFieldsFragmentDoc}
${ImportedBeatItemFragmentDoc}`;

/**
 * __useGetImportedBeatsQuery__
 *
 * To run a query within a React component, call `useGetImportedBeatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImportedBeatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImportedBeatsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      keywords: // value for 'keywords'
 *      catalogIds: // value for 'catalogIds'
 *   },
 * });
 */
export function useGetImportedBeatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetImportedBeatsQuery, GetImportedBeatsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetImportedBeatsQuery, GetImportedBeatsQueryVariables>(GetImportedBeatsDocument, baseOptions);
      }
export function useGetImportedBeatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetImportedBeatsQuery, GetImportedBeatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetImportedBeatsQuery, GetImportedBeatsQueryVariables>(GetImportedBeatsDocument, baseOptions);
        }
export type GetImportedBeatsQueryHookResult = ReturnType<typeof useGetImportedBeatsQuery>;
export type GetImportedBeatsLazyQueryHookResult = ReturnType<typeof useGetImportedBeatsLazyQuery>;
export type GetImportedBeatsQueryResult = ApolloReactCommon.QueryResult<GetImportedBeatsQuery, GetImportedBeatsQueryVariables>;
export const GetInvitedUsersDocument = gql`
    query GetInvitedUsers {
  invitedUsers: getInvitedUsers(invite_status: PENDING) {
    ...InvitedUser
  }
}
    ${InvitedUserFragmentDoc}`;

/**
 * __useGetInvitedUsersQuery__
 *
 * To run a query within a React component, call `useGetInvitedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvitedUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvitedUsersQuery, GetInvitedUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvitedUsersQuery, GetInvitedUsersQueryVariables>(GetInvitedUsersDocument, baseOptions);
      }
export function useGetInvitedUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvitedUsersQuery, GetInvitedUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvitedUsersQuery, GetInvitedUsersQueryVariables>(GetInvitedUsersDocument, baseOptions);
        }
export type GetInvitedUsersQueryHookResult = ReturnType<typeof useGetInvitedUsersQuery>;
export type GetInvitedUsersLazyQueryHookResult = ReturnType<typeof useGetInvitedUsersLazyQuery>;
export type GetInvitedUsersQueryResult = ApolloReactCommon.QueryResult<GetInvitedUsersQuery, GetInvitedUsersQueryVariables>;
export const GetLanguagesDocument = gql`
    query GetLanguages {
  languages: getLanguages {
    ...Language
  }
}
    ${LanguageFragmentDoc}`;

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, baseOptions);
      }
export function useGetLanguagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, baseOptions);
        }
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>;
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>;
export type GetLanguagesQueryResult = ApolloReactCommon.QueryResult<GetLanguagesQuery, GetLanguagesQueryVariables>;
export const GetLanguagesEvearaDocument = gql`
    query GetLanguagesEveara {
  evearaLanguages: getLanguagesEveara {
    ...EvearaLanguage
  }
}
    ${EvearaLanguageFragmentDoc}`;

/**
 * __useGetLanguagesEvearaQuery__
 *
 * To run a query within a React component, call `useGetLanguagesEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesEvearaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLanguagesEvearaQuery, GetLanguagesEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLanguagesEvearaQuery, GetLanguagesEvearaQueryVariables>(GetLanguagesEvearaDocument, baseOptions);
      }
export function useGetLanguagesEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLanguagesEvearaQuery, GetLanguagesEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLanguagesEvearaQuery, GetLanguagesEvearaQueryVariables>(GetLanguagesEvearaDocument, baseOptions);
        }
export type GetLanguagesEvearaQueryHookResult = ReturnType<typeof useGetLanguagesEvearaQuery>;
export type GetLanguagesEvearaLazyQueryHookResult = ReturnType<typeof useGetLanguagesEvearaLazyQuery>;
export type GetLanguagesEvearaQueryResult = ApolloReactCommon.QueryResult<GetLanguagesEvearaQuery, GetLanguagesEvearaQueryVariables>;
export const GetLastActionsDocument = gql`
    query GetLastActions($where: ActionFilter, $category: ActionCategory) {
  getActionsConnection(first: 999999, where: $where, category: $category) {
    edges {
      node {
        ...ActionFields
      }
    }
  }
}
    ${ActionFieldsFragmentDoc}`;

/**
 * __useGetLastActionsQuery__
 *
 * To run a query within a React component, call `useGetLastActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastActionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetLastActionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLastActionsQuery, GetLastActionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLastActionsQuery, GetLastActionsQueryVariables>(GetLastActionsDocument, baseOptions);
      }
export function useGetLastActionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLastActionsQuery, GetLastActionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLastActionsQuery, GetLastActionsQueryVariables>(GetLastActionsDocument, baseOptions);
        }
export type GetLastActionsQueryHookResult = ReturnType<typeof useGetLastActionsQuery>;
export type GetLastActionsLazyQueryHookResult = ReturnType<typeof useGetLastActionsLazyQuery>;
export type GetLastActionsQueryResult = ApolloReactCommon.QueryResult<GetLastActionsQuery, GetLastActionsQueryVariables>;
export const GetMasterDocument = gql`
    query GetMaster($catalogId: Int!, $masterId: Int!) {
  getCatalog(id: $catalogId) {
    id
    masters(where: {id: {exact: $masterId}}) {
      ...MasterFields
    }
  }
}
    ${MasterFieldsFragmentDoc}`;

/**
 * __useGetMasterQuery__
 *
 * To run a query within a React component, call `useGetMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *      masterId: // value for 'masterId'
 *   },
 * });
 */
export function useGetMasterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterQuery, GetMasterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterQuery, GetMasterQueryVariables>(GetMasterDocument, baseOptions);
      }
export function useGetMasterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterQuery, GetMasterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterQuery, GetMasterQueryVariables>(GetMasterDocument, baseOptions);
        }
export type GetMasterQueryHookResult = ReturnType<typeof useGetMasterQuery>;
export type GetMasterLazyQueryHookResult = ReturnType<typeof useGetMasterLazyQuery>;
export type GetMasterQueryResult = ApolloReactCommon.QueryResult<GetMasterQuery, GetMasterQueryVariables>;
export const GetMasterArtworkDocument = gql`
    query GetMasterArtwork($id: Int!) {
  master: getMaster(id: $id) {
    tullyMetadata: tully_metadata {
      artwork {
        id
        mediaUrl: media_url
      }
    }
  }
}
    `;

/**
 * __useGetMasterArtworkQuery__
 *
 * To run a query within a React component, call `useGetMasterArtworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterArtworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterArtworkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMasterArtworkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterArtworkQuery, GetMasterArtworkQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterArtworkQuery, GetMasterArtworkQueryVariables>(GetMasterArtworkDocument, baseOptions);
      }
export function useGetMasterArtworkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterArtworkQuery, GetMasterArtworkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterArtworkQuery, GetMasterArtworkQueryVariables>(GetMasterArtworkDocument, baseOptions);
        }
export type GetMasterArtworkQueryHookResult = ReturnType<typeof useGetMasterArtworkQuery>;
export type GetMasterArtworkLazyQueryHookResult = ReturnType<typeof useGetMasterArtworkLazyQuery>;
export type GetMasterArtworkQueryResult = ApolloReactCommon.QueryResult<GetMasterArtworkQuery, GetMasterArtworkQueryVariables>;
export const GetMasterAssetsDocument = gql`
    query GetMasterAssets($masterId: Int!) {
  getMaster(id: $masterId) {
    id
    assets {
      ...AssetFields
    }
  }
}
    ${AssetFieldsFragmentDoc}`;

/**
 * __useGetMasterAssetsQuery__
 *
 * To run a query within a React component, call `useGetMasterAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterAssetsQuery({
 *   variables: {
 *      masterId: // value for 'masterId'
 *   },
 * });
 */
export function useGetMasterAssetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterAssetsQuery, GetMasterAssetsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterAssetsQuery, GetMasterAssetsQueryVariables>(GetMasterAssetsDocument, baseOptions);
      }
export function useGetMasterAssetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterAssetsQuery, GetMasterAssetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterAssetsQuery, GetMasterAssetsQueryVariables>(GetMasterAssetsDocument, baseOptions);
        }
export type GetMasterAssetsQueryHookResult = ReturnType<typeof useGetMasterAssetsQuery>;
export type GetMasterAssetsLazyQueryHookResult = ReturnType<typeof useGetMasterAssetsLazyQuery>;
export type GetMasterAssetsQueryResult = ApolloReactCommon.QueryResult<GetMasterAssetsQuery, GetMasterAssetsQueryVariables>;
export const GetMasterAssetsByTypeDocument = gql`
    query GetMasterAssetsByType($masterId: Int!, $assets: [AssetType!]!) {
  getMaster(id: $masterId) {
    id
    assets(where: {type: {in: $assets}}) {
      ...AssetFields
    }
  }
}
    ${AssetFieldsFragmentDoc}`;

/**
 * __useGetMasterAssetsByTypeQuery__
 *
 * To run a query within a React component, call `useGetMasterAssetsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterAssetsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterAssetsByTypeQuery({
 *   variables: {
 *      masterId: // value for 'masterId'
 *      assets: // value for 'assets'
 *   },
 * });
 */
export function useGetMasterAssetsByTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterAssetsByTypeQuery, GetMasterAssetsByTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterAssetsByTypeQuery, GetMasterAssetsByTypeQueryVariables>(GetMasterAssetsByTypeDocument, baseOptions);
      }
export function useGetMasterAssetsByTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterAssetsByTypeQuery, GetMasterAssetsByTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterAssetsByTypeQuery, GetMasterAssetsByTypeQueryVariables>(GetMasterAssetsByTypeDocument, baseOptions);
        }
export type GetMasterAssetsByTypeQueryHookResult = ReturnType<typeof useGetMasterAssetsByTypeQuery>;
export type GetMasterAssetsByTypeLazyQueryHookResult = ReturnType<typeof useGetMasterAssetsByTypeLazyQuery>;
export type GetMasterAssetsByTypeQueryResult = ApolloReactCommon.QueryResult<GetMasterAssetsByTypeQuery, GetMasterAssetsByTypeQueryVariables>;
export const GetMasterByIdDocument = gql`
    query GetMasterById($id: Int!) {
  getMaster(id: $id) {
    ...MasterFields
  }
}
    ${MasterFieldsFragmentDoc}`;

/**
 * __useGetMasterByIdQuery__
 *
 * To run a query within a React component, call `useGetMasterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMasterByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterByIdQuery, GetMasterByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterByIdQuery, GetMasterByIdQueryVariables>(GetMasterByIdDocument, baseOptions);
      }
export function useGetMasterByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterByIdQuery, GetMasterByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterByIdQuery, GetMasterByIdQueryVariables>(GetMasterByIdDocument, baseOptions);
        }
export type GetMasterByIdQueryHookResult = ReturnType<typeof useGetMasterByIdQuery>;
export type GetMasterByIdLazyQueryHookResult = ReturnType<typeof useGetMasterByIdLazyQuery>;
export type GetMasterByIdQueryResult = ApolloReactCommon.QueryResult<GetMasterByIdQuery, GetMasterByIdQueryVariables>;
export const GetMasterContractsDocument = gql`
    query GetMasterContracts($masterId: Int!) {
  getMaster(id: $masterId) {
    id
    documents: assets(where: {type: {exact: MASTER_DOCUMENT}}) {
      ...AssetFields
    }
    splitSheet: contracts(where: {type: {exact: SPLIT_SHEET_AGREEMENT}}) {
      ...SplitSheetFields
    }
    uploadedContracts: contracts(where: {type: {in: [UPLOADED_CONTRACT, LICENSE_AGREEMENT, PRODUCER_ARTIST_AGREEMENT, SIDE_ARTIST_AGREEMENT, CLEARANCE_AGREEMENT]}}) {
      ...UploadedContractFields
    }
  }
}
    ${AssetFieldsFragmentDoc}
${SplitSheetFieldsFragmentDoc}
${UploadedContractFieldsFragmentDoc}`;

/**
 * __useGetMasterContractsQuery__
 *
 * To run a query within a React component, call `useGetMasterContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterContractsQuery({
 *   variables: {
 *      masterId: // value for 'masterId'
 *   },
 * });
 */
export function useGetMasterContractsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterContractsQuery, GetMasterContractsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterContractsQuery, GetMasterContractsQueryVariables>(GetMasterContractsDocument, baseOptions);
      }
export function useGetMasterContractsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterContractsQuery, GetMasterContractsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterContractsQuery, GetMasterContractsQueryVariables>(GetMasterContractsDocument, baseOptions);
        }
export type GetMasterContractsQueryHookResult = ReturnType<typeof useGetMasterContractsQuery>;
export type GetMasterContractsLazyQueryHookResult = ReturnType<typeof useGetMasterContractsLazyQuery>;
export type GetMasterContractsQueryResult = ApolloReactCommon.QueryResult<GetMasterContractsQuery, GetMasterContractsQueryVariables>;
export const GetMasterFilesOnlyDocument = gql`
    query GetMasterFilesOnly($id: Int!) {
  master: getMaster(id: $id) {
    ...MasterInfoFields
    ...MasterFilesField
  }
}
    ${MasterInfoFieldsFragmentDoc}
${MasterFilesFieldFragmentDoc}`;

/**
 * __useGetMasterFilesOnlyQuery__
 *
 * To run a query within a React component, call `useGetMasterFilesOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterFilesOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterFilesOnlyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMasterFilesOnlyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterFilesOnlyQuery, GetMasterFilesOnlyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterFilesOnlyQuery, GetMasterFilesOnlyQueryVariables>(GetMasterFilesOnlyDocument, baseOptions);
      }
export function useGetMasterFilesOnlyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterFilesOnlyQuery, GetMasterFilesOnlyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterFilesOnlyQuery, GetMasterFilesOnlyQueryVariables>(GetMasterFilesOnlyDocument, baseOptions);
        }
export type GetMasterFilesOnlyQueryHookResult = ReturnType<typeof useGetMasterFilesOnlyQuery>;
export type GetMasterFilesOnlyLazyQueryHookResult = ReturnType<typeof useGetMasterFilesOnlyLazyQuery>;
export type GetMasterFilesOnlyQueryResult = ApolloReactCommon.QueryResult<GetMasterFilesOnlyQuery, GetMasterFilesOnlyQueryVariables>;
export const GetMasterFullMetaDocument = gql`
    query GetMasterFullMeta($masterId: Int!) {
  getMaster(id: $masterId) {
    id
    title
    user {
      email
    }
    tully_metadata {
      ...TullyMetadataFields
    }
    masterClean: assets(where: {type: {exact: MASTER_CLEAN}}) {
      ...AssetMetaFields
    }
    masterExplicit: assets(where: {type: {exact: MASTER_EXPLICIT}}) {
      ...AssetMetaFields
    }
    stems: assets(where: {type: {exact: MASTER_STEM}}) {
      ...AssetMetaFields
    }
    RA360: assets(where: {type: {exact: MASTER_360RA}}) {
      ...AssetMetaFields
    }
  }
}
    ${TullyMetadataFieldsFragmentDoc}
${AssetMetaFieldsFragmentDoc}`;

/**
 * __useGetMasterFullMetaQuery__
 *
 * To run a query within a React component, call `useGetMasterFullMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterFullMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterFullMetaQuery({
 *   variables: {
 *      masterId: // value for 'masterId'
 *   },
 * });
 */
export function useGetMasterFullMetaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterFullMetaQuery, GetMasterFullMetaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterFullMetaQuery, GetMasterFullMetaQueryVariables>(GetMasterFullMetaDocument, baseOptions);
      }
export function useGetMasterFullMetaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterFullMetaQuery, GetMasterFullMetaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterFullMetaQuery, GetMasterFullMetaQueryVariables>(GetMasterFullMetaDocument, baseOptions);
        }
export type GetMasterFullMetaQueryHookResult = ReturnType<typeof useGetMasterFullMetaQuery>;
export type GetMasterFullMetaLazyQueryHookResult = ReturnType<typeof useGetMasterFullMetaLazyQuery>;
export type GetMasterFullMetaQueryResult = ApolloReactCommon.QueryResult<GetMasterFullMetaQuery, GetMasterFullMetaQueryVariables>;
export const GetMasterMetaDocument = gql`
    query GetMasterMeta($catalogId: Int!, $masterId: Int!) {
  getCatalog(id: $catalogId) {
    id
    masters(where: {id: {exact: $masterId}}) {
      id
      masterClean: assets(where: {type: {exact: MASTER_CLEAN}}) {
        ...AssetMetaFields
      }
      masterExplicit: assets(where: {type: {exact: MASTER_EXPLICIT}}) {
        ...AssetMetaFields
      }
      stems: assets(where: {type: {exact: MASTER_STEM}}) {
        ...AssetMetaFields
      }
      RA360: assets(where: {type: {exact: MASTER_360RA}}) {
        ...AssetMetaFields
      }
    }
  }
}
    ${AssetMetaFieldsFragmentDoc}`;

/**
 * __useGetMasterMetaQuery__
 *
 * To run a query within a React component, call `useGetMasterMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterMetaQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *      masterId: // value for 'masterId'
 *   },
 * });
 */
export function useGetMasterMetaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterMetaQuery, GetMasterMetaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterMetaQuery, GetMasterMetaQueryVariables>(GetMasterMetaDocument, baseOptions);
      }
export function useGetMasterMetaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterMetaQuery, GetMasterMetaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterMetaQuery, GetMasterMetaQueryVariables>(GetMasterMetaDocument, baseOptions);
        }
export type GetMasterMetaQueryHookResult = ReturnType<typeof useGetMasterMetaQuery>;
export type GetMasterMetaLazyQueryHookResult = ReturnType<typeof useGetMasterMetaLazyQuery>;
export type GetMasterMetaQueryResult = ApolloReactCommon.QueryResult<GetMasterMetaQuery, GetMasterMetaQueryVariables>;
export const GetMasterTullyMetadataDocument = gql`
    query GetMasterTullyMetadata($masterId: Int!) {
  getMaster(id: $masterId) {
    id
    title
    user {
      email
    }
    tully_metadata {
      ...TullyMetadataFields
    }
  }
}
    ${TullyMetadataFieldsFragmentDoc}`;

/**
 * __useGetMasterTullyMetadataQuery__
 *
 * To run a query within a React component, call `useGetMasterTullyMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterTullyMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterTullyMetadataQuery({
 *   variables: {
 *      masterId: // value for 'masterId'
 *   },
 * });
 */
export function useGetMasterTullyMetadataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterTullyMetadataQuery, GetMasterTullyMetadataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterTullyMetadataQuery, GetMasterTullyMetadataQueryVariables>(GetMasterTullyMetadataDocument, baseOptions);
      }
export function useGetMasterTullyMetadataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterTullyMetadataQuery, GetMasterTullyMetadataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterTullyMetadataQuery, GetMasterTullyMetadataQueryVariables>(GetMasterTullyMetadataDocument, baseOptions);
        }
export type GetMasterTullyMetadataQueryHookResult = ReturnType<typeof useGetMasterTullyMetadataQuery>;
export type GetMasterTullyMetadataLazyQueryHookResult = ReturnType<typeof useGetMasterTullyMetadataLazyQuery>;
export type GetMasterTullyMetadataQueryResult = ApolloReactCommon.QueryResult<GetMasterTullyMetadataQuery, GetMasterTullyMetadataQueryVariables>;
export const GetMasterWithAssetsDocument = gql`
    query GetMasterWithAssets($id: Int!) {
  getMaster(id: $id) {
    ...MasterFields
  }
}
    ${MasterFieldsFragmentDoc}`;

/**
 * __useGetMasterWithAssetsQuery__
 *
 * To run a query within a React component, call `useGetMasterWithAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterWithAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterWithAssetsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMasterWithAssetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterWithAssetsQuery, GetMasterWithAssetsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterWithAssetsQuery, GetMasterWithAssetsQueryVariables>(GetMasterWithAssetsDocument, baseOptions);
      }
export function useGetMasterWithAssetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterWithAssetsQuery, GetMasterWithAssetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterWithAssetsQuery, GetMasterWithAssetsQueryVariables>(GetMasterWithAssetsDocument, baseOptions);
        }
export type GetMasterWithAssetsQueryHookResult = ReturnType<typeof useGetMasterWithAssetsQuery>;
export type GetMasterWithAssetsLazyQueryHookResult = ReturnType<typeof useGetMasterWithAssetsLazyQuery>;
export type GetMasterWithAssetsQueryResult = ApolloReactCommon.QueryResult<GetMasterWithAssetsQuery, GetMasterWithAssetsQueryVariables>;
export const GetMastersAvailableForNewDistributionDocument = gql`
    query GetMastersAvailableForNewDistribution {
  getMastersAvailableForNewDistribution {
    id
    title
  }
}
    `;

/**
 * __useGetMastersAvailableForNewDistributionQuery__
 *
 * To run a query within a React component, call `useGetMastersAvailableForNewDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMastersAvailableForNewDistributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMastersAvailableForNewDistributionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMastersAvailableForNewDistributionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMastersAvailableForNewDistributionQuery, GetMastersAvailableForNewDistributionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMastersAvailableForNewDistributionQuery, GetMastersAvailableForNewDistributionQueryVariables>(GetMastersAvailableForNewDistributionDocument, baseOptions);
      }
export function useGetMastersAvailableForNewDistributionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMastersAvailableForNewDistributionQuery, GetMastersAvailableForNewDistributionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMastersAvailableForNewDistributionQuery, GetMastersAvailableForNewDistributionQueryVariables>(GetMastersAvailableForNewDistributionDocument, baseOptions);
        }
export type GetMastersAvailableForNewDistributionQueryHookResult = ReturnType<typeof useGetMastersAvailableForNewDistributionQuery>;
export type GetMastersAvailableForNewDistributionLazyQueryHookResult = ReturnType<typeof useGetMastersAvailableForNewDistributionLazyQuery>;
export type GetMastersAvailableForNewDistributionQueryResult = ApolloReactCommon.QueryResult<GetMastersAvailableForNewDistributionQuery, GetMastersAvailableForNewDistributionQueryVariables>;
export const GetMastersByIdDocument = gql`
    query GetMastersById($ids: [Int!]!) {
  getMasters(id: $ids) {
    ...MasterFields
  }
}
    ${MasterFieldsFragmentDoc}`;

/**
 * __useGetMastersByIdQuery__
 *
 * To run a query within a React component, call `useGetMastersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMastersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMastersByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMastersByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMastersByIdQuery, GetMastersByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMastersByIdQuery, GetMastersByIdQueryVariables>(GetMastersByIdDocument, baseOptions);
      }
export function useGetMastersByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMastersByIdQuery, GetMastersByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMastersByIdQuery, GetMastersByIdQueryVariables>(GetMastersByIdDocument, baseOptions);
        }
export type GetMastersByIdQueryHookResult = ReturnType<typeof useGetMastersByIdQuery>;
export type GetMastersByIdLazyQueryHookResult = ReturnType<typeof useGetMastersByIdLazyQuery>;
export type GetMastersByIdQueryResult = ApolloReactCommon.QueryResult<GetMastersByIdQuery, GetMastersByIdQueryVariables>;
export const MenuResourcesDocument = gql`
    query MenuResources {
  resources: getMenuResources {
    ...MenuResourceFields
  }
}
    ${MenuResourceFieldsFragmentDoc}`;

/**
 * __useMenuResourcesQuery__
 *
 * To run a query within a React component, call `useMenuResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMenuResourcesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuResourcesQuery, MenuResourcesQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuResourcesQuery, MenuResourcesQueryVariables>(MenuResourcesDocument, baseOptions);
      }
export function useMenuResourcesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuResourcesQuery, MenuResourcesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuResourcesQuery, MenuResourcesQueryVariables>(MenuResourcesDocument, baseOptions);
        }
export type MenuResourcesQueryHookResult = ReturnType<typeof useMenuResourcesQuery>;
export type MenuResourcesLazyQueryHookResult = ReturnType<typeof useMenuResourcesLazyQuery>;
export type MenuResourcesQueryResult = ApolloReactCommon.QueryResult<MenuResourcesQuery, MenuResourcesQueryVariables>;
export const GetNewImportedBeatDocument = gql`
    query GetNewImportedBeat($catalogId: Int!) {
  getCatalog(id: $catalogId) {
    id
    importedBeats: assets(where: {type: {exact: IMPORT_BEAT}}) {
      ...AssetFields
    }
  }
}
    ${AssetFieldsFragmentDoc}`;

/**
 * __useGetNewImportedBeatQuery__
 *
 * To run a query within a React component, call `useGetNewImportedBeatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewImportedBeatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewImportedBeatQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useGetNewImportedBeatQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNewImportedBeatQuery, GetNewImportedBeatQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNewImportedBeatQuery, GetNewImportedBeatQueryVariables>(GetNewImportedBeatDocument, baseOptions);
      }
export function useGetNewImportedBeatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNewImportedBeatQuery, GetNewImportedBeatQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNewImportedBeatQuery, GetNewImportedBeatQueryVariables>(GetNewImportedBeatDocument, baseOptions);
        }
export type GetNewImportedBeatQueryHookResult = ReturnType<typeof useGetNewImportedBeatQuery>;
export type GetNewImportedBeatLazyQueryHookResult = ReturnType<typeof useGetNewImportedBeatLazyQuery>;
export type GetNewImportedBeatQueryResult = ApolloReactCommon.QueryResult<GetNewImportedBeatQuery, GetNewImportedBeatQueryVariables>;
export const GetNewMasterDocument = gql`
    query GetNewMaster($catalogId: Int!, $newMasterId: Int!) {
  getCatalog(id: $catalogId) {
    id
    masters(where: {id: {exact: $newMasterId}}) {
      ...MasterFields
    }
  }
}
    ${MasterFieldsFragmentDoc}`;

/**
 * __useGetNewMasterQuery__
 *
 * To run a query within a React component, call `useGetNewMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewMasterQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *      newMasterId: // value for 'newMasterId'
 *   },
 * });
 */
export function useGetNewMasterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNewMasterQuery, GetNewMasterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNewMasterQuery, GetNewMasterQueryVariables>(GetNewMasterDocument, baseOptions);
      }
export function useGetNewMasterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNewMasterQuery, GetNewMasterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNewMasterQuery, GetNewMasterQueryVariables>(GetNewMasterDocument, baseOptions);
        }
export type GetNewMasterQueryHookResult = ReturnType<typeof useGetNewMasterQuery>;
export type GetNewMasterLazyQueryHookResult = ReturnType<typeof useGetNewMasterLazyQuery>;
export type GetNewMasterQueryResult = ApolloReactCommon.QueryResult<GetNewMasterQuery, GetNewMasterQueryVariables>;
export const GetNewProjectDocument = gql`
    query GetNewProject($catalogId: Int!, $newProjectId: Int!) {
  getCatalog(id: $catalogId) {
    id
    projects(where: {id: {exact: $newProjectId}}) {
      ...ProjectFields
    }
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useGetNewProjectQuery__
 *
 * To run a query within a React component, call `useGetNewProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewProjectQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *      newProjectId: // value for 'newProjectId'
 *   },
 * });
 */
export function useGetNewProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNewProjectQuery, GetNewProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNewProjectQuery, GetNewProjectQueryVariables>(GetNewProjectDocument, baseOptions);
      }
export function useGetNewProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNewProjectQuery, GetNewProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNewProjectQuery, GetNewProjectQueryVariables>(GetNewProjectDocument, baseOptions);
        }
export type GetNewProjectQueryHookResult = ReturnType<typeof useGetNewProjectQuery>;
export type GetNewProjectLazyQueryHookResult = ReturnType<typeof useGetNewProjectLazyQuery>;
export type GetNewProjectQueryResult = ApolloReactCommon.QueryResult<GetNewProjectQuery, GetNewProjectQueryVariables>;
export const GetOneCatalogDocument = gql`
    query GetOneCatalog($id: Int!) {
  getCatalog(id: $id) {
    id
    ...ImportedBeats
    ...TullyBeats
  }
}
    ${ImportedBeatsFragmentDoc}
${TullyBeatsFragmentDoc}`;

/**
 * __useGetOneCatalogQuery__
 *
 * To run a query within a React component, call `useGetOneCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneCatalogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneCatalogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOneCatalogQuery, GetOneCatalogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOneCatalogQuery, GetOneCatalogQueryVariables>(GetOneCatalogDocument, baseOptions);
      }
export function useGetOneCatalogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOneCatalogQuery, GetOneCatalogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOneCatalogQuery, GetOneCatalogQueryVariables>(GetOneCatalogDocument, baseOptions);
        }
export type GetOneCatalogQueryHookResult = ReturnType<typeof useGetOneCatalogQuery>;
export type GetOneCatalogLazyQueryHookResult = ReturnType<typeof useGetOneCatalogLazyQuery>;
export type GetOneCatalogQueryResult = ApolloReactCommon.QueryResult<GetOneCatalogQuery, GetOneCatalogQueryVariables>;
export const GetOutletsEvearaDocument = gql`
    query GetOutletsEveara {
  evearaOutlets: getEvearaOutlets {
    ...EvearaOutlet
  }
}
    ${EvearaOutletFragmentDoc}`;

/**
 * __useGetOutletsEvearaQuery__
 *
 * To run a query within a React component, call `useGetOutletsEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletsEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletsEvearaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOutletsEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletsEvearaQuery, GetOutletsEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletsEvearaQuery, GetOutletsEvearaQueryVariables>(GetOutletsEvearaDocument, baseOptions);
      }
export function useGetOutletsEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletsEvearaQuery, GetOutletsEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletsEvearaQuery, GetOutletsEvearaQueryVariables>(GetOutletsEvearaDocument, baseOptions);
        }
export type GetOutletsEvearaQueryHookResult = ReturnType<typeof useGetOutletsEvearaQuery>;
export type GetOutletsEvearaLazyQueryHookResult = ReturnType<typeof useGetOutletsEvearaLazyQuery>;
export type GetOutletsEvearaQueryResult = ApolloReactCommon.QueryResult<GetOutletsEvearaQuery, GetOutletsEvearaQueryVariables>;
export const GetParticipantsEvearaDocument = gql`
    query GetParticipantsEveara {
  evearaParticipants: getParticipantsEveara {
    ...EvearaParticipant
  }
}
    ${EvearaParticipantFragmentDoc}`;

/**
 * __useGetParticipantsEvearaQuery__
 *
 * To run a query within a React component, call `useGetParticipantsEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsEvearaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParticipantsEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParticipantsEvearaQuery, GetParticipantsEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParticipantsEvearaQuery, GetParticipantsEvearaQueryVariables>(GetParticipantsEvearaDocument, baseOptions);
      }
export function useGetParticipantsEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParticipantsEvearaQuery, GetParticipantsEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParticipantsEvearaQuery, GetParticipantsEvearaQueryVariables>(GetParticipantsEvearaDocument, baseOptions);
        }
export type GetParticipantsEvearaQueryHookResult = ReturnType<typeof useGetParticipantsEvearaQuery>;
export type GetParticipantsEvearaLazyQueryHookResult = ReturnType<typeof useGetParticipantsEvearaLazyQuery>;
export type GetParticipantsEvearaQueryResult = ApolloReactCommon.QueryResult<GetParticipantsEvearaQuery, GetParticipantsEvearaQueryVariables>;
export const GetPlayerAssetDocument = gql`
    query GetPlayerAsset($assetId: Int!) {
  playerAsset: getAsset(id: $assetId) {
    ...PlayerAsset
  }
}
    ${PlayerAssetFragmentDoc}`;

/**
 * __useGetPlayerAssetQuery__
 *
 * To run a query within a React component, call `useGetPlayerAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerAssetQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetPlayerAssetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPlayerAssetQuery, GetPlayerAssetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPlayerAssetQuery, GetPlayerAssetQueryVariables>(GetPlayerAssetDocument, baseOptions);
      }
export function useGetPlayerAssetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlayerAssetQuery, GetPlayerAssetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPlayerAssetQuery, GetPlayerAssetQueryVariables>(GetPlayerAssetDocument, baseOptions);
        }
export type GetPlayerAssetQueryHookResult = ReturnType<typeof useGetPlayerAssetQuery>;
export type GetPlayerAssetLazyQueryHookResult = ReturnType<typeof useGetPlayerAssetLazyQuery>;
export type GetPlayerAssetQueryResult = ApolloReactCommon.QueryResult<GetPlayerAssetQuery, GetPlayerAssetQueryVariables>;
export const GetProjectAssetsDocument = gql`
    query GetProjectAssets($projectId: Int!) {
  getProject(id: $projectId) {
    id
    assets {
      ...AssetFields
    }
  }
}
    ${AssetFieldsFragmentDoc}`;

/**
 * __useGetProjectAssetsQuery__
 *
 * To run a query within a React component, call `useGetProjectAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAssetsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectAssetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectAssetsQuery, GetProjectAssetsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectAssetsQuery, GetProjectAssetsQueryVariables>(GetProjectAssetsDocument, baseOptions);
      }
export function useGetProjectAssetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectAssetsQuery, GetProjectAssetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectAssetsQuery, GetProjectAssetsQueryVariables>(GetProjectAssetsDocument, baseOptions);
        }
export type GetProjectAssetsQueryHookResult = ReturnType<typeof useGetProjectAssetsQuery>;
export type GetProjectAssetsLazyQueryHookResult = ReturnType<typeof useGetProjectAssetsLazyQuery>;
export type GetProjectAssetsQueryResult = ApolloReactCommon.QueryResult<GetProjectAssetsQuery, GetProjectAssetsQueryVariables>;
export const GetProjectMetaDocument = gql`
    query GetProjectMeta($projectId: Int!) {
  getProject(id: $projectId) {
    id
    created_at
    updated_at
    meta {
      title
      artist
    }
    assets {
      ...AssetFields
    }
    lyrics {
      ...LyricsFields
    }
  }
}
    ${AssetFieldsFragmentDoc}
${LyricsFieldsFragmentDoc}`;

/**
 * __useGetProjectMetaQuery__
 *
 * To run a query within a React component, call `useGetProjectMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMetaQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectMetaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectMetaQuery, GetProjectMetaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectMetaQuery, GetProjectMetaQueryVariables>(GetProjectMetaDocument, baseOptions);
      }
export function useGetProjectMetaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectMetaQuery, GetProjectMetaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectMetaQuery, GetProjectMetaQueryVariables>(GetProjectMetaDocument, baseOptions);
        }
export type GetProjectMetaQueryHookResult = ReturnType<typeof useGetProjectMetaQuery>;
export type GetProjectMetaLazyQueryHookResult = ReturnType<typeof useGetProjectMetaLazyQuery>;
export type GetProjectMetaQueryResult = ApolloReactCommon.QueryResult<GetProjectMetaQuery, GetProjectMetaQueryVariables>;
export const GetProjectWithAssetsDocument = gql`
    query GetProjectWithAssets($id: Int!) {
  getProject(id: $id) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useGetProjectWithAssetsQuery__
 *
 * To run a query within a React component, call `useGetProjectWithAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectWithAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectWithAssetsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectWithAssetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectWithAssetsQuery, GetProjectWithAssetsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectWithAssetsQuery, GetProjectWithAssetsQueryVariables>(GetProjectWithAssetsDocument, baseOptions);
      }
export function useGetProjectWithAssetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectWithAssetsQuery, GetProjectWithAssetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectWithAssetsQuery, GetProjectWithAssetsQueryVariables>(GetProjectWithAssetsDocument, baseOptions);
        }
export type GetProjectWithAssetsQueryHookResult = ReturnType<typeof useGetProjectWithAssetsQuery>;
export type GetProjectWithAssetsLazyQueryHookResult = ReturnType<typeof useGetProjectWithAssetsLazyQuery>;
export type GetProjectWithAssetsQueryResult = ApolloReactCommon.QueryResult<GetProjectWithAssetsQuery, GetProjectWithAssetsQueryVariables>;
export const GetReleasesEvearaDocument = gql`
    query GetReleasesEveara($releasesData: ReleaseEvearaInput) {
  evearaReleases: getReleaseEveara(data: $releasesData) {
    edges {
      node {
        ...EvearaRelease
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${EvearaReleaseFragmentDoc}
${PageInfoFieldsFragmentDoc}`;

/**
 * __useGetReleasesEvearaQuery__
 *
 * To run a query within a React component, call `useGetReleasesEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleasesEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleasesEvearaQuery({
 *   variables: {
 *      releasesData: // value for 'releasesData'
 *   },
 * });
 */
export function useGetReleasesEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReleasesEvearaQuery, GetReleasesEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReleasesEvearaQuery, GetReleasesEvearaQueryVariables>(GetReleasesEvearaDocument, baseOptions);
      }
export function useGetReleasesEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReleasesEvearaQuery, GetReleasesEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReleasesEvearaQuery, GetReleasesEvearaQueryVariables>(GetReleasesEvearaDocument, baseOptions);
        }
export type GetReleasesEvearaQueryHookResult = ReturnType<typeof useGetReleasesEvearaQuery>;
export type GetReleasesEvearaLazyQueryHookResult = ReturnType<typeof useGetReleasesEvearaLazyQuery>;
export type GetReleasesEvearaQueryResult = ApolloReactCommon.QueryResult<GetReleasesEvearaQuery, GetReleasesEvearaQueryVariables>;
export const GetReleasesEvearaDraftDocument = gql`
    query GetReleasesEvearaDraft($releasesData: ReleaseEvearaInput) {
  evearaReleasesDraft: getReleaseEveara(data: $releasesData) {
    edges {
      node {
        ...EvearaRelease
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${EvearaReleaseFragmentDoc}
${PageInfoFieldsFragmentDoc}`;

/**
 * __useGetReleasesEvearaDraftQuery__
 *
 * To run a query within a React component, call `useGetReleasesEvearaDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleasesEvearaDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleasesEvearaDraftQuery({
 *   variables: {
 *      releasesData: // value for 'releasesData'
 *   },
 * });
 */
export function useGetReleasesEvearaDraftQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReleasesEvearaDraftQuery, GetReleasesEvearaDraftQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReleasesEvearaDraftQuery, GetReleasesEvearaDraftQueryVariables>(GetReleasesEvearaDraftDocument, baseOptions);
      }
export function useGetReleasesEvearaDraftLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReleasesEvearaDraftQuery, GetReleasesEvearaDraftQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReleasesEvearaDraftQuery, GetReleasesEvearaDraftQueryVariables>(GetReleasesEvearaDraftDocument, baseOptions);
        }
export type GetReleasesEvearaDraftQueryHookResult = ReturnType<typeof useGetReleasesEvearaDraftQuery>;
export type GetReleasesEvearaDraftLazyQueryHookResult = ReturnType<typeof useGetReleasesEvearaDraftLazyQuery>;
export type GetReleasesEvearaDraftQueryResult = ApolloReactCommon.QueryResult<GetReleasesEvearaDraftQuery, GetReleasesEvearaDraftQueryVariables>;
export const GetRolesEvearaDocument = gql`
    query GetRolesEveara {
  evearaRoles: getRolesEveara {
    ...EvearaRole
  }
}
    ${EvearaRoleFragmentDoc}`;

/**
 * __useGetRolesEvearaQuery__
 *
 * To run a query within a React component, call `useGetRolesEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesEvearaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRolesEvearaQuery, GetRolesEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRolesEvearaQuery, GetRolesEvearaQueryVariables>(GetRolesEvearaDocument, baseOptions);
      }
export function useGetRolesEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRolesEvearaQuery, GetRolesEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRolesEvearaQuery, GetRolesEvearaQueryVariables>(GetRolesEvearaDocument, baseOptions);
        }
export type GetRolesEvearaQueryHookResult = ReturnType<typeof useGetRolesEvearaQuery>;
export type GetRolesEvearaLazyQueryHookResult = ReturnType<typeof useGetRolesEvearaLazyQuery>;
export type GetRolesEvearaQueryResult = ApolloReactCommon.QueryResult<GetRolesEvearaQuery, GetRolesEvearaQueryVariables>;
export const GetSavedSearchesDocument = gql`
    query GetSavedSearches {
  savedSearches: getSavedSearches {
    ...SavedSearch
  }
}
    ${SavedSearchFragmentDoc}`;

/**
 * __useGetSavedSearchesQuery__
 *
 * To run a query within a React component, call `useGetSavedSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedSearchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSavedSearchesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSavedSearchesQuery, GetSavedSearchesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSavedSearchesQuery, GetSavedSearchesQueryVariables>(GetSavedSearchesDocument, baseOptions);
      }
export function useGetSavedSearchesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSavedSearchesQuery, GetSavedSearchesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSavedSearchesQuery, GetSavedSearchesQueryVariables>(GetSavedSearchesDocument, baseOptions);
        }
export type GetSavedSearchesQueryHookResult = ReturnType<typeof useGetSavedSearchesQuery>;
export type GetSavedSearchesLazyQueryHookResult = ReturnType<typeof useGetSavedSearchesLazyQuery>;
export type GetSavedSearchesQueryResult = ApolloReactCommon.QueryResult<GetSavedSearchesQuery, GetSavedSearchesQueryVariables>;
export const GetSplitsheetDocument = gql`
    query GetSplitsheet($id: Int!) {
  getContract(id: $id) {
    ...SplitSheetFields
  }
}
    ${SplitSheetFieldsFragmentDoc}`;

/**
 * __useGetSplitsheetQuery__
 *
 * To run a query within a React component, call `useGetSplitsheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSplitsheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSplitsheetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSplitsheetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSplitsheetQuery, GetSplitsheetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSplitsheetQuery, GetSplitsheetQueryVariables>(GetSplitsheetDocument, baseOptions);
      }
export function useGetSplitsheetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSplitsheetQuery, GetSplitsheetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSplitsheetQuery, GetSplitsheetQueryVariables>(GetSplitsheetDocument, baseOptions);
        }
export type GetSplitsheetQueryHookResult = ReturnType<typeof useGetSplitsheetQuery>;
export type GetSplitsheetLazyQueryHookResult = ReturnType<typeof useGetSplitsheetLazyQuery>;
export type GetSplitsheetQueryResult = ApolloReactCommon.QueryResult<GetSplitsheetQuery, GetSplitsheetQueryVariables>;
export const GetSubscribedSubscriptionsDocument = gql`
    query GetSubscribedSubscriptions {
  subscribedSubscriptions: getSubscribedSubscriptions(include_free_plan: true) {
    ...AccountSubscription
  }
}
    ${AccountSubscriptionFragmentDoc}`;

/**
 * __useGetSubscribedSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscribedSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscribedSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscribedSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscribedSubscriptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubscribedSubscriptionsQuery, GetSubscribedSubscriptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubscribedSubscriptionsQuery, GetSubscribedSubscriptionsQueryVariables>(GetSubscribedSubscriptionsDocument, baseOptions);
      }
export function useGetSubscribedSubscriptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubscribedSubscriptionsQuery, GetSubscribedSubscriptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubscribedSubscriptionsQuery, GetSubscribedSubscriptionsQueryVariables>(GetSubscribedSubscriptionsDocument, baseOptions);
        }
export type GetSubscribedSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscribedSubscriptionsQuery>;
export type GetSubscribedSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscribedSubscriptionsLazyQuery>;
export type GetSubscribedSubscriptionsQueryResult = ApolloReactCommon.QueryResult<GetSubscribedSubscriptionsQuery, GetSubscribedSubscriptionsQueryVariables>;
export const GetSubscriptionEvearaDocument = gql`
    query GetSubscriptionEveara {
  evearaSubscription: getSubscriptionsEveara {
    ...EvearaSubscription
  }
}
    ${EvearaSubscriptionFragmentDoc}`;

/**
 * __useGetSubscriptionEvearaQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionEvearaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionEvearaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionEvearaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionEvearaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubscriptionEvearaQuery, GetSubscriptionEvearaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubscriptionEvearaQuery, GetSubscriptionEvearaQueryVariables>(GetSubscriptionEvearaDocument, baseOptions);
      }
export function useGetSubscriptionEvearaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubscriptionEvearaQuery, GetSubscriptionEvearaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubscriptionEvearaQuery, GetSubscriptionEvearaQueryVariables>(GetSubscriptionEvearaDocument, baseOptions);
        }
export type GetSubscriptionEvearaQueryHookResult = ReturnType<typeof useGetSubscriptionEvearaQuery>;
export type GetSubscriptionEvearaLazyQueryHookResult = ReturnType<typeof useGetSubscriptionEvearaLazyQuery>;
export type GetSubscriptionEvearaQueryResult = ApolloReactCommon.QueryResult<GetSubscriptionEvearaQuery, GetSubscriptionEvearaQueryVariables>;
export const GetMasterTitleDocument = gql`
    query GetMasterTitle($id: Int!) {
  getMaster(id: $id) {
    id
    title
    created_at
    updated_at
    tully_metadata {
      id
      track_title
    }
  }
}
    `;

/**
 * __useGetMasterTitleQuery__
 *
 * To run a query within a React component, call `useGetMasterTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterTitleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMasterTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterTitleQuery, GetMasterTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterTitleQuery, GetMasterTitleQueryVariables>(GetMasterTitleDocument, baseOptions);
      }
export function useGetMasterTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterTitleQuery, GetMasterTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterTitleQuery, GetMasterTitleQueryVariables>(GetMasterTitleDocument, baseOptions);
        }
export type GetMasterTitleQueryHookResult = ReturnType<typeof useGetMasterTitleQuery>;
export type GetMasterTitleLazyQueryHookResult = ReturnType<typeof useGetMasterTitleLazyQuery>;
export type GetMasterTitleQueryResult = ApolloReactCommon.QueryResult<GetMasterTitleQuery, GetMasterTitleQueryVariables>;
export const GetProjectTitleDocument = gql`
    query GetProjectTitle($id: Int!) {
  getProject(id: $id) {
    id
    title
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetProjectTitleQuery__
 *
 * To run a query within a React component, call `useGetProjectTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTitleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectTitleQuery, GetProjectTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectTitleQuery, GetProjectTitleQueryVariables>(GetProjectTitleDocument, baseOptions);
      }
export function useGetProjectTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectTitleQuery, GetProjectTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectTitleQuery, GetProjectTitleQueryVariables>(GetProjectTitleDocument, baseOptions);
        }
export type GetProjectTitleQueryHookResult = ReturnType<typeof useGetProjectTitleQuery>;
export type GetProjectTitleLazyQueryHookResult = ReturnType<typeof useGetProjectTitleLazyQuery>;
export type GetProjectTitleQueryResult = ApolloReactCommon.QueryResult<GetProjectTitleQuery, GetProjectTitleQueryVariables>;
export const GetAssetTitleDocument = gql`
    query GetAssetTitle($id: Int!) {
  getAsset(id: $id) {
    id
    title
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetAssetTitleQuery__
 *
 * To run a query within a React component, call `useGetAssetTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetTitleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssetTitleQuery, GetAssetTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssetTitleQuery, GetAssetTitleQueryVariables>(GetAssetTitleDocument, baseOptions);
      }
export function useGetAssetTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetTitleQuery, GetAssetTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssetTitleQuery, GetAssetTitleQueryVariables>(GetAssetTitleDocument, baseOptions);
        }
export type GetAssetTitleQueryHookResult = ReturnType<typeof useGetAssetTitleQuery>;
export type GetAssetTitleLazyQueryHookResult = ReturnType<typeof useGetAssetTitleLazyQuery>;
export type GetAssetTitleQueryResult = ApolloReactCommon.QueryResult<GetAssetTitleQuery, GetAssetTitleQueryVariables>;
export const GetCollectionTitleDocument = gql`
    query GetCollectionTitle($id: Int!) {
  getCollection(id: $id) {
    id
    title
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetCollectionTitleQuery__
 *
 * To run a query within a React component, call `useGetCollectionTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionTitleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCollectionTitleQuery, GetCollectionTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCollectionTitleQuery, GetCollectionTitleQueryVariables>(GetCollectionTitleDocument, baseOptions);
      }
export function useGetCollectionTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectionTitleQuery, GetCollectionTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCollectionTitleQuery, GetCollectionTitleQueryVariables>(GetCollectionTitleDocument, baseOptions);
        }
export type GetCollectionTitleQueryHookResult = ReturnType<typeof useGetCollectionTitleQuery>;
export type GetCollectionTitleLazyQueryHookResult = ReturnType<typeof useGetCollectionTitleLazyQuery>;
export type GetCollectionTitleQueryResult = ApolloReactCommon.QueryResult<GetCollectionTitleQuery, GetCollectionTitleQueryVariables>;
export const GetTitleBlacklistedWordsDocument = gql`
    query GetTitleBlacklistedWords {
  titleBlacklistedWords: getTitleBlacklistedWords
}
    `;

/**
 * __useGetTitleBlacklistedWordsQuery__
 *
 * To run a query within a React component, call `useGetTitleBlacklistedWordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTitleBlacklistedWordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTitleBlacklistedWordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTitleBlacklistedWordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTitleBlacklistedWordsQuery, GetTitleBlacklistedWordsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTitleBlacklistedWordsQuery, GetTitleBlacklistedWordsQueryVariables>(GetTitleBlacklistedWordsDocument, baseOptions);
      }
export function useGetTitleBlacklistedWordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTitleBlacklistedWordsQuery, GetTitleBlacklistedWordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTitleBlacklistedWordsQuery, GetTitleBlacklistedWordsQueryVariables>(GetTitleBlacklistedWordsDocument, baseOptions);
        }
export type GetTitleBlacklistedWordsQueryHookResult = ReturnType<typeof useGetTitleBlacklistedWordsQuery>;
export type GetTitleBlacklistedWordsLazyQueryHookResult = ReturnType<typeof useGetTitleBlacklistedWordsLazyQuery>;
export type GetTitleBlacklistedWordsQueryResult = ApolloReactCommon.QueryResult<GetTitleBlacklistedWordsQuery, GetTitleBlacklistedWordsQueryVariables>;
export const GetTullyBeatDocument = gql`
    query GetTullyBeat($tullyBeatId: Int!) {
  tullyBeat: getTullyBeat(id: $tullyBeatId) {
    ...TullyBeatItem
  }
}
    ${TullyBeatItemFragmentDoc}`;

/**
 * __useGetTullyBeatQuery__
 *
 * To run a query within a React component, call `useGetTullyBeatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTullyBeatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTullyBeatQuery({
 *   variables: {
 *      tullyBeatId: // value for 'tullyBeatId'
 *   },
 * });
 */
export function useGetTullyBeatQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTullyBeatQuery, GetTullyBeatQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTullyBeatQuery, GetTullyBeatQueryVariables>(GetTullyBeatDocument, baseOptions);
      }
export function useGetTullyBeatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTullyBeatQuery, GetTullyBeatQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTullyBeatQuery, GetTullyBeatQueryVariables>(GetTullyBeatDocument, baseOptions);
        }
export type GetTullyBeatQueryHookResult = ReturnType<typeof useGetTullyBeatQuery>;
export type GetTullyBeatLazyQueryHookResult = ReturnType<typeof useGetTullyBeatLazyQuery>;
export type GetTullyBeatQueryResult = ApolloReactCommon.QueryResult<GetTullyBeatQuery, GetTullyBeatQueryVariables>;
export const GetTullyBeatsDocument = gql`
    query GetTullyBeats($cursor: String, $first: Int, $search: String, $keywords: [String!]) {
  beats: getTullyBeats(type: PREMIUM, after: $cursor, first: $first, search: $search, keywords: $keywords) {
    pageInfo {
      ...PageInfoFields
    }
    edges {
      cursor
      node {
        ...TullyBeatItem
      }
    }
  }
}
    ${PageInfoFieldsFragmentDoc}
${TullyBeatItemFragmentDoc}`;

/**
 * __useGetTullyBeatsQuery__
 *
 * To run a query within a React component, call `useGetTullyBeatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTullyBeatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTullyBeatsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useGetTullyBeatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTullyBeatsQuery, GetTullyBeatsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTullyBeatsQuery, GetTullyBeatsQueryVariables>(GetTullyBeatsDocument, baseOptions);
      }
export function useGetTullyBeatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTullyBeatsQuery, GetTullyBeatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTullyBeatsQuery, GetTullyBeatsQueryVariables>(GetTullyBeatsDocument, baseOptions);
        }
export type GetTullyBeatsQueryHookResult = ReturnType<typeof useGetTullyBeatsQuery>;
export type GetTullyBeatsLazyQueryHookResult = ReturnType<typeof useGetTullyBeatsLazyQuery>;
export type GetTullyBeatsQueryResult = ApolloReactCommon.QueryResult<GetTullyBeatsQuery, GetTullyBeatsQueryVariables>;
export const GetTullyMetadataArtistsDocument = gql`
    query GetTullyMetadataArtists {
  tullyMetadataArtists: getTullyMetadataArtists {
    ...TullyMetadataArtist
  }
}
    ${TullyMetadataArtistFragmentDoc}`;

/**
 * __useGetTullyMetadataArtistsQuery__
 *
 * To run a query within a React component, call `useGetTullyMetadataArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTullyMetadataArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTullyMetadataArtistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTullyMetadataArtistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTullyMetadataArtistsQuery, GetTullyMetadataArtistsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTullyMetadataArtistsQuery, GetTullyMetadataArtistsQueryVariables>(GetTullyMetadataArtistsDocument, baseOptions);
      }
export function useGetTullyMetadataArtistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTullyMetadataArtistsQuery, GetTullyMetadataArtistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTullyMetadataArtistsQuery, GetTullyMetadataArtistsQueryVariables>(GetTullyMetadataArtistsDocument, baseOptions);
        }
export type GetTullyMetadataArtistsQueryHookResult = ReturnType<typeof useGetTullyMetadataArtistsQuery>;
export type GetTullyMetadataArtistsLazyQueryHookResult = ReturnType<typeof useGetTullyMetadataArtistsLazyQuery>;
export type GetTullyMetadataArtistsQueryResult = ApolloReactCommon.QueryResult<GetTullyMetadataArtistsQuery, GetTullyMetadataArtistsQueryVariables>;
export const GetTullyMetadataParticipantRolesDocument = gql`
    query GetTullyMetadataParticipantRoles {
  tullyMetadataParticipantRoles: getTullyMetadataParticipantRoles {
    ...TullyMetadataParticipantRole
  }
}
    ${TullyMetadataParticipantRoleFragmentDoc}`;

/**
 * __useGetTullyMetadataParticipantRolesQuery__
 *
 * To run a query within a React component, call `useGetTullyMetadataParticipantRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTullyMetadataParticipantRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTullyMetadataParticipantRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTullyMetadataParticipantRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTullyMetadataParticipantRolesQuery, GetTullyMetadataParticipantRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTullyMetadataParticipantRolesQuery, GetTullyMetadataParticipantRolesQueryVariables>(GetTullyMetadataParticipantRolesDocument, baseOptions);
      }
export function useGetTullyMetadataParticipantRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTullyMetadataParticipantRolesQuery, GetTullyMetadataParticipantRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTullyMetadataParticipantRolesQuery, GetTullyMetadataParticipantRolesQueryVariables>(GetTullyMetadataParticipantRolesDocument, baseOptions);
        }
export type GetTullyMetadataParticipantRolesQueryHookResult = ReturnType<typeof useGetTullyMetadataParticipantRolesQuery>;
export type GetTullyMetadataParticipantRolesLazyQueryHookResult = ReturnType<typeof useGetTullyMetadataParticipantRolesLazyQuery>;
export type GetTullyMetadataParticipantRolesQueryResult = ApolloReactCommon.QueryResult<GetTullyMetadataParticipantRolesQuery, GetTullyMetadataParticipantRolesQueryVariables>;
export const GetTullyMetadataParticipantsDocument = gql`
    query GetTullyMetadataParticipants {
  tullyMetadataParticipants: getTullyMetadataParticipants {
    ...TullyMetadataParticipant
  }
}
    ${TullyMetadataParticipantFragmentDoc}`;

/**
 * __useGetTullyMetadataParticipantsQuery__
 *
 * To run a query within a React component, call `useGetTullyMetadataParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTullyMetadataParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTullyMetadataParticipantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTullyMetadataParticipantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTullyMetadataParticipantsQuery, GetTullyMetadataParticipantsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTullyMetadataParticipantsQuery, GetTullyMetadataParticipantsQueryVariables>(GetTullyMetadataParticipantsDocument, baseOptions);
      }
export function useGetTullyMetadataParticipantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTullyMetadataParticipantsQuery, GetTullyMetadataParticipantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTullyMetadataParticipantsQuery, GetTullyMetadataParticipantsQueryVariables>(GetTullyMetadataParticipantsDocument, baseOptions);
        }
export type GetTullyMetadataParticipantsQueryHookResult = ReturnType<typeof useGetTullyMetadataParticipantsQuery>;
export type GetTullyMetadataParticipantsLazyQueryHookResult = ReturnType<typeof useGetTullyMetadataParticipantsLazyQuery>;
export type GetTullyMetadataParticipantsQueryResult = ApolloReactCommon.QueryResult<GetTullyMetadataParticipantsQuery, GetTullyMetadataParticipantsQueryVariables>;
export const GetUploadedContractDocument = gql`
    query GetUploadedContract($id: Int!) {
  getContract(id: $id) {
    ...UploadedContractFields
  }
}
    ${UploadedContractFieldsFragmentDoc}`;

/**
 * __useGetUploadedContractQuery__
 *
 * To run a query within a React component, call `useGetUploadedContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadedContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadedContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUploadedContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUploadedContractQuery, GetUploadedContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUploadedContractQuery, GetUploadedContractQueryVariables>(GetUploadedContractDocument, baseOptions);
      }
export function useGetUploadedContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUploadedContractQuery, GetUploadedContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUploadedContractQuery, GetUploadedContractQueryVariables>(GetUploadedContractDocument, baseOptions);
        }
export type GetUploadedContractQueryHookResult = ReturnType<typeof useGetUploadedContractQuery>;
export type GetUploadedContractLazyQueryHookResult = ReturnType<typeof useGetUploadedContractLazyQuery>;
export type GetUploadedContractQueryResult = ApolloReactCommon.QueryResult<GetUploadedContractQuery, GetUploadedContractQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users: getAuthors {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<GetUsersQuery, GetUsersQueryVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "HasSimpleHistory",
        "possibleTypes": [
          {
            "name": "TullyMetadata"
          },
          {
            "name": "Account"
          },
          {
            "name": "AMPFile"
          },
          {
            "name": "User"
          },
          {
            "name": "Catalog"
          },
          {
            "name": "AMPMaster"
          },
          {
            "name": "AMPAsset"
          },
          {
            "name": "AMPProject"
          },
          {
            "name": "AMPLyrics"
          },
          {
            "name": "Contract"
          },
          {
            "name": "ContractParty"
          },
          {
            "name": "AMPCollection"
          },
          {
            "name": "SavedSearch"
          },
          {
            "name": "SearchResult"
          },
          {
            "name": "Action"
          },
          {
            "name": "Role"
          },
          {
            "name": "Plan"
          },
          {
            "name": "UMRole"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "AudioMeta",
        "possibleTypes": [
          {
            "name": "AudioMetaSuccess"
          },
          {
            "name": "AudioMetaError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ContractPartyMeta",
        "possibleTypes": [
          {
            "name": "UploadedPartyMeta"
          },
          {
            "name": "SplitPartyMeta"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ActionObject",
        "possibleTypes": [
          {
            "name": "AMPMaster"
          },
          {
            "name": "AMPProject"
          },
          {
            "name": "AMPAsset"
          },
          {
            "name": "AMPLyrics"
          },
          {
            "name": "AMPCollection"
          },
          {
            "name": "Contract"
          },
          {
            "name": "User"
          },
          {
            "name": "UserInviteModel"
          }
        ]
      }
    ]
  }
};
      export default result;
    