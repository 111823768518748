import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import constructPath from 'api/utils/constructPath';
import POST from 'common/services/httpServices/post';

interface DistributionProps {
  masterId: number;
}

const distribution: (props: DistributionProps) => Promise<string> = async ({
  masterId,
}) => {
  const res = await POST(
    constructPath(CatalogsPaths.Distribution, { masterId: masterId.toString() })
  );

  if (res.status === 200) return (await res.json()).url;

  throw Error(res.statusText);
};

export default distribution;
