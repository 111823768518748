import PlayerState from 'redux/player/interfaces/playerState';
import MediaType from 'common/hooks/usePlayerInstance/enums/mediaType';

const INIT_PLAYER_STATE: PlayerState = {
  playList: [],
  activeAssetId: null,
  activeTullyBeatId: null,
  activeImportedBeatId: null,
  activeSharedAssetId: null,
  activeMediaType: MediaType.Asset,
  audio: new Audio(),
  playingAssetId: null,
  sharedSource: [],
};

export default INIT_PLAYER_STATE;
