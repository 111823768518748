import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import DotsMenuItem from 'uikit/dotsMenuItem/DotsMenuItem';
import HeaderMenuItem from 'common/components/headerMenu/interfaces/headerMenuItem';
import HeaderMenuIcon from 'common/components/headerMenu/components/HeaderMenuIcon';
import HeaderMenuActionTypes from 'common/components/headerMenu/enums/HeaderMenuActionTypes';
import USER_MANAGEMENT_ACTIONS from 'common/components/headerMenu/configs/userManagementActions';

const useStyles = makeStyles(
  (theme) => ({
    line: {
      height: 48,
      padding: theme.spacing(0, 3),
    },
    separated: {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
    },
    lineLabel: {
      paddingLeft: theme.spacing(2),
    },
  }),
  { name: 'HeaderMenuItems' }
);

interface HeaderMenuItemsProps {
  menuItems: HeaderMenuItem[];
  isUserManagement: boolean;
  isArtist: boolean;
  onClick: (actionType: HeaderMenuActionTypes) => void;
}

const HeaderMenuItems: FC<HeaderMenuItemsProps> = ({
  menuItems,
  onClick,
  isArtist,
  isUserManagement,
}) => {
  const classes = useStyles();
  const userManagementActions = USER_MANAGEMENT_ACTIONS;

  return (
    <>
      {menuItems.map((menuItem) => {
        if (menuItem.actionType === HeaderMenuActionTypes.ArtistProfile && !isArtist)
          return null;

        if (userManagementActions.includes(menuItem.actionType) && !isUserManagement)
          return null;

        return (
          <DotsMenuItem
            key={menuItem.title}
            icon={<HeaderMenuIcon icon={menuItem.actionType} />}
            label={menuItem.title}
            onClick={() => onClick(menuItem.actionType)}
            labelVariant="subtitle1"
            labelStyles={classes.lineLabel}
            className={clsx(classes.line, { [classes.separated]: menuItem.isSeparated })}
          />
        );
      })}
    </>
  );
};

export default HeaderMenuItems;
