import { MAX_SIMULTANEOUS_UPLOADS as startDefalult } from 'config/network';

export type QueueState<T = unknown> = {
  channels: Promise<unknown>[];
  triggers: ((value?: unknown) => void)[];
  queue: Promise<T>[];
};

export function promiseQueue<T>(args: { operations: (() => Promise<T>)[] }) {
  const operations = args.operations || [];
  const state = operations.reduce<QueueState<T>>(
    (state, operation) => {
      let trigger;
      const channel = new Promise((resolve) => {
        trigger = resolve;
      });

      const pausedOperation = (async () => {
        await channel;
        return operation();
      })();

      return {
        channels: state.channels.concat(channel),
        triggers: state.triggers.concat(trigger),
        queue: state.queue.concat(pausedOperation),
      };
    },
    { channels: [], triggers: [], queue: [] }
  );

  function* triggersQueue(items: QueueState['triggers']) {
    yield* items;
  }

  const tq = triggersQueue(state.triggers);
  const startNext = () => {
    const { value } = tq.next();
    if (typeof value === 'function') value();
  };

  const handledQueue = state.queue.map((op) => op.finally(startNext));

  const start = (n = startDefalult) => {
    const limit = Math.min(operations.length, n);
    for (let i = 0; i < limit; i++) {
      startNext();
    }
  };

  return {
    queue: handledQueue,
    start,
  };
}
