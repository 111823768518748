import { createContext } from 'react';

import noop from 'common/utils/noop';
import { ExpandedActions } from 'common/store/expanded/store/expandedActions';
import ExpandedState from 'common/store/expanded/store/expandedState';

export interface ExpandedContextType {
  expandedState: ExpandedState;
  dispatchExpandedState: (action: ExpandedActions) => void;
}

const ExpandedContext = createContext<ExpandedContextType>({
  dispatchExpandedState: noop,
  expandedState: {
    expandedMasterId: null,
    expandedCollectionId: null,
  },
});

export default ExpandedContext;
