import { flatMap, groupBy } from 'lodash';

import { UmRoleFieldsFragment } from 'generated/graphql';
import getCatalogIds from 'redux/permissions/utils/getCatalogIds';
import createContentPermissions from 'redux/permissions/utils/createContentPermissions';
import mergePermissions from 'redux/permissions/utils/mergePermissions';
import CatalogPerms from 'redux/permissions/interfaces/catalogPerms';

const getPerms = (roles = [] as UmRoleFieldsFragment[]): CatalogPerms[] => {
  const validRoles = roles.filter((role) => role.catalog_details.length > 0);
  const catalogIds = getCatalogIds(validRoles);
  const catalogs = flatMap(validRoles, (role) => role.catalog_details);

  const catalogsPerms = catalogs.map((catalog) => ({
    catalogId: catalog.catalog_id,
    permissions: createContentPermissions(catalog.permissions),
  }));

  const groupedPerms = groupBy(catalogsPerms, 'catalogId');

  return catalogIds.reduce<CatalogPerms[]>((array, catalogId) => {
    const permsArray = groupedPerms[catalogId].map((item) => item.permissions);
    const combinedPerms = permsArray.reduce(mergePermissions);
    const item = {
      catalogId,
      permissions: combinedPerms,
    };

    return array.concat(item);
  }, []);
};

export default getPerms;
