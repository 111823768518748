import * as React from 'react';
import Typography from 'uikit/typography/Typography';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  greyText: {
    color: theme.palette.grey[200],
  },
}));

export default function NoItems<P>(props: P) {
  const classes = useStyles();

  return (
    <Typography variant="body2" align="center" className={classes.greyText} {...props} />
  );
}
