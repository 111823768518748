import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { IContractParty } from 'common/api/contractModels';
import {
  IUContractAction,
  setPartyRoyaltyAction,
  setPartyRoyaltyLabelAction,
} from 'common/components/Modals/contractAgreementFormDialog/contractAgreementFormState';
import Select from 'uikit/select/Select';
import React from 'react';
import TextField from 'uikit/textField/TextField';

const useStyles = makeStyles(() => ({
  inputData: {
    width: '100%',
  },
}));

interface IPartyRoyaltySection {
  party: IContractParty;
  dispatch: React.Dispatch<IUContractAction>;
}

const PartyRoyaltySection: React.FC<IPartyRoyaltySection> = ({ party, dispatch }) => {
  const classes = useStyles();

  return (
    <>
      <Select
        dropdownFullWidth
        closeOnClick
        text={party.royaltyLabel}
        containerStyle={{ width: '100%' }}
        variant={'label'}
      >
        {['Royalty, %', 'Net Artist Royalty', 'Gross Receipts', 'PPD'].map(
          (royaltyType) => (
            <MenuItem
              value={royaltyType}
              key={royaltyType}
              onClick={() => dispatch(setPartyRoyaltyLabelAction(party.id, royaltyType))}
            >
              {royaltyType}
            </MenuItem>
          )
        )}
      </Select>

      <TextField
        value={party.royalty.toString()}
        onChange={(e) =>
          dispatch(setPartyRoyaltyAction(party.id, Number(e.target.value) || 0))
        }
        inputProps={{
          type: 'number',
          min: 0,
          max: 100,
          step: 1,
        }}
        className={classes.inputData}
      />
    </>
  );
};

export default PartyRoyaltySection;
