import { ContentType } from 'common/api/models';

export default function getSubfolderName(typeId: ContentType) {
  switch (typeId) {
    case ContentType.MASTER_FILE:
      return 'Master Files';
    case ContentType.MASTER_META:
      return 'Metadata';
    case ContentType.MASTER_STEM:
      return 'Stem Files';
    case ContentType.MASTER_MEDIA:
      return 'Media';
    case ContentType.MASTER_CONTRACT:
    case ContentType.MASTER_DOCUMENT:
      return 'Contracts';
    default:
      return 'Unknown';
  }
}
