import FilePreviewDialog from 'common/components/Modals/filePreviewDialog/FilePreviewDialog';
import * as React from 'react';
import clsx from 'clsx';
import noop from 'common/utils/noop';
import useSelected from 'common/hooks/useSelected';
import FolderItemIcon from 'uikit/folderItemIcon/FolderItemIcon';
import FolderItemBase, { FolderItemBaseProps } from 'uikit/folderItemBase/FolderItemBase';
import Typography from 'uikit/typography/Typography';
import IconProgress from 'uikit/iconProgress/IconProgress';
import { Dialog, makeStyles } from '@material-ui/core';
import { useEventCallback } from 'common/hooks';
import {
  PlayOldIcon,
  PlayingIcon,
  MoreVertIcon,
  DeleteIconOld,
  FilePreviewIcon,
} from 'common/icons/system';
import useEdiphyState from 'uikit/ediphy/hooks/useEdiphyState';
import EditableTitle from 'uikit/editableTitle/EditableTitle';
import { EdiphyProps } from 'uikit/ediphy/Ediphy';
import { IconButton } from 'uikit/iconButton/IconButton';
import { IconButtonGroup } from 'uikit/iconButtonGroup/IconButtonGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 48,
    paddingBottom: 0,
    paddingTop: 0,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('background'),
    '&:hover, &$selected, &$dragging': {
      backgroundColor: theme.palette.action.hover,
    },
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
  hasParent: {
    marginLeft: theme.spacing(2),
    '& $icon': {
      flexBasis: 48,
      maxWidth: 48,
    },
  },
  icon: {
    '$root:not($hasParent) &': {
      justifyContent: 'center',
    },
  },
  content: {},
  dense: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& $icon': {
      flexBasis: 24,
      maxWidth: 24,
    },
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
  },
  ghost: {
    '& $icon, & $content': {
      opacity: 0.33,
    },
  },
  selected: {},
  dragging: {},
}));

const useDialogStyles = makeStyles(
  () => ({
    paper: {
      width: '70%',
      maxWidth: '100%',
      height: '80%',
    },
  }),
  { name: 'FolderItemPreview' }
);

const handler = (fn) => (event: React.MouseEvent) => {
  // event.stopPropagation();
  if (fn) {
    fn(event);
  }
};

export type FolderItemProps = Partial<FolderItemBaseProps> & {
  mainLine: string;
  className?: string;
  dnese?: boolean;
  ghost?: boolean;
  hasParent?: boolean;
  selected?: boolean;
  dragging?: boolean;
  icon?: React.ReactNode;
  subLine?: React.ReactNode;
  playButton?: boolean;
  isPlaying?: boolean;
  play?: () => void;
  pause?: () => void;
  moreButton?: boolean;
  deleteButton?: boolean;
  onMoreButtonClick?: React.MouseEventHandler;
  onTitleChange?(prev: string, next: string): void;
  onDelete?: React.MouseEventHandler;
  editableTitle?: boolean;
  progress?: number;
  EdiphyProps?: Partial<EdiphyProps>;
  preview?: { fileUrl?: string; isPreviewable?: boolean };
};

const FolderItem: React.FC<FolderItemProps> = React.memo((props) => {
  const {
    hasParent = false,
    icon = null,
    mainLine,
    subLine,
    playButton = false,
    isPlaying = false,
    play,
    pause,
    moreButton = false,
    deleteButton = false,
    dense = false,
    onMoreButtonClick,
    onTitleChange,
    onDelete,
    editableTitle = false,
    ghost = false,
    dragging = false,
    EdiphyProps,
    progress,
    className,
    preview,
    ...itemBaseProps
  } = props;
  const classes = useStyles(props);
  const itemBaseCls = {
    icon: classes.icon,
    content: classes.content,
    selected: classes.selected,
  };
  const isLoading = typeof progress === 'number';
  const showButtonGroup =
    playButton || moreButton || isLoading || deleteButton || preview?.fileUrl;
  const [, ediphyProps] = useEdiphyState(mainLine, onTitleChange);
  const [openModal, setOpenModal] = React.useState(false);
  const classesDialog = useDialogStyles();

  return (
    <FolderItemBase
      className={clsx(
        classes.root,
        {
          [classes.hasParent]: hasParent,
          [classes.dense]: dense,
          [classes.ghost]: ghost,
          [classes.dragging]: dragging,
        },
        className
      )}
      classes={itemBaseCls}
      {...itemBaseProps}
    >
      {icon}
      <React.Fragment>
        <EditableTitle
          disabled={!editableTitle}
          ediphyProps={{
            ...ediphyProps,
            oneLineText: true,
            ...EdiphyProps,
          }}
          variant="subtitle2"
        />
        {subLine && <Typography variant="caption">{subLine}</Typography>}
      </React.Fragment>
      {showButtonGroup && (
        <IconButtonGroup>
          {isLoading && <IconProgress progress={progress as number} />}
          {preview?.fileUrl && preview.isPreviewable && (
            <>
              <IconButton size="small" onClick={() => setOpenModal(true)} outlined>
                <FilePreviewIcon />
              </IconButton>

              <Dialog classes={classesDialog} open={openModal} maxWidth={false}>
                <FilePreviewDialog
                  onClose={() => setOpenModal(false)}
                  uri={preview.fileUrl}
                  title={mainLine}
                />
              </Dialog>
            </>
          )}
          {playButton &&
            (isPlaying ? (
              <IconButton size="small" onClick={handler(pause)} outlined>
                <PlayingIcon />
              </IconButton>
            ) : (
              <IconButton size="small" onClick={handler(play)} outlined>
                <PlayOldIcon />
              </IconButton>
            ))}
          {moreButton && (
            <IconButton size="small" onClick={handler(onMoreButtonClick)}>
              <MoreVertIcon />
            </IconButton>
          )}
          {deleteButton && (
            <IconButton size="small" onClick={handler(onDelete)}>
              <DeleteIconOld />
            </IconButton>
          )}
        </IconButtonGroup>
      )}
    </FolderItemBase>
  );
});

export default FolderItem;

export type WrappedItemProps = Partial<FolderItemProps> & {
  hideIconBg?: boolean;
};

export function createFolderItem<
  T extends { [key in keyof T]: string },
  K1 extends keyof T,
  K2 extends Exclude<keyof T, K1>
>(options: {
  stringCtx: React.Context<T>;
  defaultStrings: T;
  Icon: React.ElementType;
  stringKeys: [K1, K2];
  hideBg?: boolean;
}) {
  const { Icon, hideBg = false, stringCtx, defaultStrings, stringKeys } = options;

  return (props: WrappedItemProps) => {
    const isSelected = useSelected(props);
    const { selected, hideIconBg, dense, ...other } = props;
    const str = React.useContext(stringCtx);
    const s = { ...defaultStrings, ...str };
    const [main, sub] = stringKeys;
    const isBgHidden = hideIconBg || hideBg;

    const handleClick = useEventCallback(props.onClick || noop);
    // Ignoring onClick handler if item is selected
    const onClick = isSelected ? undefined : handleClick;

    const icon = dense ? (
      <Icon />
    ) : (
      <FolderItemIcon disableFolderImage={isBgHidden}>
        <Icon />
      </FolderItemIcon>
    );

    return (
      <FolderItem
        icon={icon}
        mainLine={s[main]}
        subLine={s[sub]}
        onClick={onClick}
        selected={isSelected || other.isPlaying}
        dense={dense}
        {...other}
      />
    );
  };
}
