import toPartyRecord from 'common/utils/toPartyRecord';
import { UploadedContractFieldsFragment } from 'generated/graphql';
import {
  IUploadedContractLocalState,
  PartyRecord,
} from 'common/components/Modals/contractAgreementFormDialog/contractAgreementFormState';

export const toUploadedContractLocalState = (
  data: UploadedContractFieldsFragment
): IUploadedContractLocalState => {
  const parties = data.parties.reduce<PartyRecord[]>((acc, item) => {
    const party = toPartyRecord(item);
    if (party) {
      return acc.concat(party);
    }

    return acc;
  }, []);

  let fileName = data.file?.file_name || null;
  if (fileName) {
    fileName = fileName.substring(fileName.indexOf('_') + 1, fileName.length);
  }

  return {
    title: data.title,
    description: data.metadata?.description || '',
    createdAt: data.created_at,
    fileIsUploaded: !!data.file?.media_url,
    file: {
      downloadUrl: data.file?.media_url || '',
      id: data.file?.id || 0,
      fileName,
    },
    contractID: data.id,
    catalogId: data.master?.catalog?.id,
    masterId: data.master?.id || 0,
    type: data.type || '',
    parties,
    deletedPartyIds: [],
    editedPartyIds: [],
  };
};
