import { Category, ContentType } from 'common/api/models';
import MenuContext from 'redux/menu/types/menuContext';
import DriveContractContext from 'redux/menu/interfaces/driveContractContext';

const isDriveContractContext = (context: MenuContext): context is DriveContractContext =>
  context.kind === 'DRIVE' &&
  context.category === Category.AssetType &&
  context.typeId === ContentType.MASTER_CONTRACT;

export default isDriveContractContext;
