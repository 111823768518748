import { RootState } from 'redux/root-reducer';

const playerSelector = {
  state: (state: RootState) => state.player,
  activeAssetId: (state: RootState) => state.player.activeAssetId,
  playList: (state: RootState) => state.player.playList,
  isPlaying: (state: RootState) => state.player.playingAssetId,
  activeTrackId: (state: RootState) =>
    state.player.activeAssetId ||
    state.player.activeSharedAssetId ||
    state.player.activeImportedBeatId ||
    state.player.activeTullyBeatId,
};

export default playerSelector;
