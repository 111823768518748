import isIncludesVisible from 'redux/menu/utils/isIncludesVisible';
import { MenuActions, MenuActionTypes } from 'redux/menu/menuActions';
import MenuState from 'redux/menu/interfaces/menuState';
import INIT_MENU_STATE from 'redux/menu/configs/initMenuState';

export default function menuReducer(
  state = INIT_MENU_STATE,
  action: MenuActions
): MenuState {
  switch (action.type) {
    case MenuActionTypes.CLOSE:
      return {
        ...state,
        isClosing: true,
        open: false,
      };
    case MenuActionTypes.CLOSED:
      return { ...INIT_MENU_STATE };
    case MenuActionTypes.OPEN:
      return {
        anchorEl: action.payload.element,
        context: action.payload.context,
        visibility: action.payload.visibility,
        isClosing: false,
        open: isIncludesVisible(action.payload.visibility),
      };
    default:
      return state;
  }
}
