import * as React from 'react';
import clsx from 'clsx';
import {
  Typography as MuiTypography,
  TypographyProps,
  makeStyles,
} from '@material-ui/core';

export interface ITypographyProps extends Omit<TypographyProps, 'variant' | 'component'> {
  variant?: TypographyProps['variant'] | 'button1' | 'button2';
  component?: keyof JSX.IntrinsicElements;
  oneline?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '&$startAdornment, &$endAdornment': {
        display: 'inline-flex',
        alignItems: 'center',
      },
    },
    button1: {
      ...theme.typography.button1,
    },
    button2: {
      ...theme.typography.button2,
    },
    oneline: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
    },
    endAdornment: {
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: theme.spacing(0.5),
      },
    },
    startAdornment: {
      '& .MuiInputAdornment-positionStart': {
        marginRight: theme.spacing(0.5),
      },
    },
  }),
  { name: 'Typography' }
);

const Typography = React.forwardRef<HTMLElement, ITypographyProps>(function Typography(
  props,
  ref
) {
  const {
    children,
    startAdornment,
    endAdornment,
    variant = 'body1',
    className,
    oneline = false,
    ...other
  } = props;
  const classes = useStyles();
  const _variant = variant !== 'button1' && variant !== 'button2' ? variant : 'inherit';
  const _className = clsx(classes.root, className, classes[variant], {
    [classes.oneline]: oneline,
    [classes.startAdornment]: !!startAdornment,
    [classes.endAdornment]: !!endAdornment,
  });

  return (
    <MuiTypography ref={ref} variant={_variant} className={_className} {...other}>
      {startAdornment}
      {startAdornment || endAdornment ? <span>{children}</span> : children}
      {endAdornment}
    </MuiTypography>
  );
});

export default Typography;
