import { flatMap, uniq } from 'lodash';

import { UmRoleFieldsFragment } from 'generated/graphql';

const getCatalogIds = (roles: UmRoleFieldsFragment[]) => {
  return uniq(
    flatMap(roles, (role) => role.catalog_details.map(({ catalog_id }) => catalog_id))
  );
};

export default getCatalogIds;
