import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { useEffect, useState } from 'react';

interface UseStorageFreePlaceRes {
  freePlacePercentage: number; // from 0 to 100
  freePlace: number;
}

const useStorageFreePlace: () => UseStorageFreePlaceRes = () => {
  const [freePlacePercentage, setFreePlacePercentage] = useState<number>(100);
  const [freePlace, setFreePlace] = useState<number>(Infinity);
  const account = useSelector((state: RootState) => state.user.data?.account);

  useEffect(() => {
    if (!account) return;

    setFreePlacePercentage(100 - ((account.usedStorage || 0) / account.maxStorage) * 100);
    setFreePlace(account.maxStorage - (account.usedStorage || 0));
  }, [account]);

  return {
    freePlacePercentage,
    freePlace,
  };
};

export default useStorageFreePlace;
