import { useState, useEffect } from 'react';
import cache from 'apollo/cache/cache';
import apolloClient from 'apollo/client';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import {
  APOLLO_CACHE_KEY,
  GQL_SCHEMA_VERSION,
  GQL_SCHEMA_VERSION_KEY,
  PERSIST_CACHE,
} from 'config/appConstants';

async function setupPersistCache() {
  const persistor = new CachePersistor({
    cache,
    key: APOLLO_CACHE_KEY,
    storage: new LocalStorageWrapper(localStorage),
  });

  const currentVersion = localStorage.getItem(GQL_SCHEMA_VERSION_KEY);

  if (currentVersion === GQL_SCHEMA_VERSION) {
    await persistor.restore();
  } else {
    await persistor.purge();
    localStorage.setItem(GQL_SCHEMA_VERSION_KEY, GQL_SCHEMA_VERSION);
  }
}

function cleanup() {
  localStorage.removeItem(GQL_SCHEMA_VERSION_KEY);
  localStorage.removeItem(APOLLO_CACHE_KEY);
}

export function usePersistClient() {
  const [client, setClient] = useState<typeof apolloClient | undefined>(undefined);

  useEffect(() => {
    async function bootstrap() {
      await setupPersistCache();
      setClient(apolloClient);
    }

    if (PERSIST_CACHE) {
      bootstrap();
    } else {
      cleanup();
    }
  }, [setClient]);

  return PERSIST_CACHE ? client : apolloClient;
}
