import ToolbarOption from 'redux/toolbar/enums/toolbarOption';

export enum ToolbarActionTypes {
  CLICK = '@TOOLBAR/CLICK',
}

export type ToolbarClickAction = {
  type: ToolbarActionTypes.CLICK;
  payload: ToolbarOption;
};

export const toolbarClick = (
  payload: ToolbarClickAction['payload']
): ToolbarClickAction => ({
  type: ToolbarActionTypes.CLICK,
  payload,
});

export type ToolbarActions = ToolbarClickAction;
