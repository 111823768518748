import { Middleware } from 'redux';
import { RootState } from 'redux/root-reducer';
import * as menu from 'redux/menu/menuActions';
import { lockMaster } from 'modules/masters/services/lockMaster';
import { unlockMaster } from 'modules/masters/services/unlockMaster';
import isClickAction from 'redux/menu/utils/isClickAction';
import isMasterFolderContext from 'redux/menu/utils/isMasterFolderContext';
import MenuOption from 'redux/menu/enums/menuOption';
import { MasterFieldsFragment } from 'generated/graphql';

export const masterLocking: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (isClickAction(action)) {
    const { option, context } = action.payload;
    if (option === MenuOption.LOCK) {
      store.dispatch(menu.close());
      if (isMasterFolderContext(context) && context?.data) {
        lockMaster(context.data as MasterFieldsFragment).then();
      }
    }

    if (option === MenuOption.UNLOCK) {
      store.dispatch(menu.close());
      if (isMasterFolderContext(context) && context?.data) {
        unlockMaster(context.data as MasterFieldsFragment).then();
      }
    }
  }

  return next(action);
};
