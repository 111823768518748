import { AxiosRequestConfig } from 'axios';
import getAccessToken from 'common/services/tokenServices/getAccessToken';

const addAuthHeaders = async (
  config?: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const token = await getAccessToken();
  return {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${token}`,
    },
  } as AxiosRequestConfig;
};

export default addAuthHeaders;
