import { useDispatch, useSelector } from 'react-redux';
import fullScreenSelector from 'redux/fullScreenView/fullScreenViewSelectors';
import { setFullScreenViewClosed } from 'redux/fullScreenView/fullScreenViewActions';

const useCloseFullScreenView: () => () => void = () => {
  const onCloseAdditionalAction = useSelector(fullScreenSelector.onCloseAdditionalAction);
  const dispatch = useDispatch();

  return () => {
    if (onCloseAdditionalAction) {
      onCloseAdditionalAction();
    }
    dispatch(setFullScreenViewClosed());
  };
};

export default useCloseFullScreenView;
