import FiltersState from 'redux/filters/interfaces/filtersState';
import { DRIVE_FILTERS_STORAGE_KEY, VERSION } from 'redux/filters/configs/configs';
import DataContainer from 'redux/filters/interfaces/dataContainer';

const loadFilters = (): FiltersState | undefined => {
  try {
    const container = localStorage.getItem(DRIVE_FILTERS_STORAGE_KEY);

    if (container) {
      const parsed = JSON.parse(container) as DataContainer<FiltersState>;
      if (parsed.version === VERSION) {
        return parsed.data;
      }
    }
  } catch {}

  return undefined;
};

export default loadFilters;
