import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { fileUploadSelector } from 'redux/fileUpload/fileUploadSelectors';
import { useEffect } from 'react';
import * as fileActions from 'redux/fileUpload/fileUploadActions';
import isAutostartState from 'modules/app/utils/isAutostartState';

const useFileUploadAutostart = () => {
  const notify = useSnackbar();
  const state = useSelector(fileUploadSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAutostartState(state)) {
      if (state.error) {
        notify.enqueueSnackbar(state.error.message, { variant: 'error' });
        dispatch(fileActions.reset());
      } else {
        dispatch(fileActions.start());
      }
    }
  }, [state, dispatch, notify]);
};

export default useFileUploadAutostart;
