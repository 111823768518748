import moment from 'moment';
import jwtDecode from 'jwt-decode';

import loadToken from 'common/services/tokenServices/loadToken';

const loadAccessToken = () => {
  const token = loadToken() || undefined;
  if (token) {
    const decodedToken = jwtDecode(token);

    if (moment.unix(decodedToken.exp).isBefore(moment())) {
      return undefined;
    }
  }
  return token;
};

export default loadAccessToken;
