import React from 'react';
import { makeStyles } from '@material-ui/core';

import { SnackBox, SnackBoxProps } from 'uikit/notifications/Notifications';

const useSnackBoxStyles = makeStyles(
  {
    contentRoot: {
      minWidth: 'auto',
      padding: 0,
    },
  },
  { name: 'UploadingSnackBox' }
);

const UploadingSnackBox = React.forwardRef<HTMLDivElement, SnackBoxProps>(
  (props: SnackBoxProps, ref) => (
    <SnackBox classes={useSnackBoxStyles()} {...props} ref={ref} />
  )
);

export default UploadingSnackBox;
