import { gql } from '@apollo/client';
import { AssetFields } from 'gql/fragments/AssetFields';

export const ProjectMetaField = gql`
  fragment ProjectMetaField on AMPProject {
    meta {
      title
      artist
    }
  }
`;

export const LyricsFields = gql`
  fragment LyricsFields on AMPLyrics {
    id
    created_at
    content
  }
`;

export const ProjectFields = gql`
  ${AssetFields}
  ${ProjectMetaField}
  ${LyricsFields}

  fragment ProjectFields on AMPProject {
    id
    title
    created_at
    updated_at
    locked: is_locked
    catalog_id
    assets {
      ...AssetFields
    }
    lyrics {
      ...LyricsFields
    }
    ...ProjectMetaField
  }
`;
