import cloneDeep from 'lodash/cloneDeep';
import * as Role from 'redux/roleEditor/roleEditorActions';
import { RoleEditorActionTypes } from 'redux/roleEditor/roleEditorActions';
import { UmRoleCatalog } from 'generated/graphql';
import {
  addCatalog,
  addCatalogs,
  addUser,
  addUsers,
  copy,
  createUmRole,
  createUmRoleCatalog,
  removeCatalog,
  removeUser,
  TYPE_IDS_BY_CATEGORY,
  updateCatalog,
  updateContent,
  updatePrivilegesSet,
} from 'common/models/UmRole';
import SubmitResult from 'redux/roleEditor/enums/submitResult';
import EditorState from 'redux/roleEditor/interfaces/editorState';
import INIT_ROLE_EDITOR_STATE from 'redux/roleEditor/configs/initRoleEditorState';

function umRoleCatalogReducer(
  state: UmRoleCatalog,
  action: Role.RoleEditorActions
): UmRoleCatalog {
  switch (action.type) {
    case RoleEditorActionTypes.SET_CONTENT_CATEGORY:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          content: updateContent(
            state.permissions.content,
            TYPE_IDS_BY_CATEGORY[action.payload.category],
            action.payload.value
          ),
        },
      };
    case RoleEditorActionTypes.SET_CONTENT:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          content: updateContent(
            state.permissions.content,
            action.payload.typeIds,
            action.payload.value
          ),
        },
      };
    case RoleEditorActionTypes.SET_PERMISSION:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          privileges: updatePrivilegesSet(
            state.permissions.privileges,
            action.payload.type,
            action.payload.value
          ),
        },
      };
    default:
      return state;
  }
}

function umRoleReducer(state: EditorState, action: Role.RoleEditorActions): EditorState {
  if (!state.role) return state;

  switch (action.type) {
    case RoleEditorActionTypes.ADD_CATALOG:
      return {
        ...state,
        touched: true,
        role: addCatalog(
          state.role,
          createUmRoleCatalog(action.payload.id, action.payload.name)
        ),
      };
    case RoleEditorActionTypes.ADD_CATALOGS:
      return {
        ...state,
        touched: true,
        role: addCatalogs(state.role, action.payload.catalogs),
      };
    case RoleEditorActionTypes.REMOVE_CATALOG:
      return {
        ...state,
        touched: true,
        role: removeCatalog(state.role, action.payload.catalogIndex),
      };
    case RoleEditorActionTypes.ADD_USER:
      return {
        ...state,
        touched: true,
        role: addUser(state.role, action.payload.user),
      };
    case RoleEditorActionTypes.ADD_USERS:
      return {
        ...state,
        touched: true,
        role: addUsers(state.role, action.payload.users),
      };
    case RoleEditorActionTypes.REMOVE_USER:
      return {
        ...state,
        touched: true,
        role: removeUser(state.role, action.payload.userIndex),
      };
    case RoleEditorActionTypes.SET_PERMISSION:
      return {
        ...state,
        touched: true,
        catalogIndex: action.payload.catalogIndex,
        role: updateCatalog(state.role, action.payload.catalogIndex, (catalog) =>
          umRoleCatalogReducer(catalog, action)
        ),
      };
    case RoleEditorActionTypes.SET_CONTENT:
    case RoleEditorActionTypes.SET_CONTENT_CATEGORY:
      const index = action.payload.catalogIndex;
      return {
        ...state,
        touched: true,
        role: updateCatalog(state.role, index, (catalog) =>
          umRoleCatalogReducer(catalog, action)
        ),
      };
    case RoleEditorActionTypes.SET_NAME:
      return {
        ...state,
        touched: true,
        role: {
          ...state.role,
          name: action.payload.name,
        },
      };
    default:
      return state;
  }
}

export function roleEditorReducer(
  state: EditorState = INIT_ROLE_EDITOR_STATE,
  action: Role.RoleEditorActions
): EditorState {
  switch (action.type) {
    case RoleEditorActionTypes.SET_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload.value,
      };
    case RoleEditorActionTypes.SET_DISABLED:
      return {
        ...state,
        disabled: action.payload.value,
      };
    case RoleEditorActionTypes.SET_SUCCESS:
      return {
        ...state,
        touched: false,
        isSubmitting: false,
        submitResult: SubmitResult.success,
      };
    case RoleEditorActionTypes.SET_ERROR:
      return {
        ...state,
        isSubmitting: false,
        submitResult: SubmitResult.error,
      };
    case RoleEditorActionTypes.SELECT_CATALOG:
      return {
        ...state,
        catalogIndex: action.payload.catalogIndex,
      };
    case RoleEditorActionTypes.EDIT:
      const role = cloneDeep(action.payload.role);
      return {
        ...state,
        role: action.payload.copy ? copy(role) : role,
        touched: false,
        operation: 'edit',
      };
    case RoleEditorActionTypes.NEW:
      return {
        ...state,
        role: createUmRole(),
        touched: false,
        operation: 'new',
      };
    case RoleEditorActionTypes.RESET:
      return { ...INIT_ROLE_EDITOR_STATE };
    default:
      return umRoleReducer(state, action);
  }
}
