import React, { FC, lazy, ReactElement, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Routes } from 'navigation/enums/routes';
import Preloader from 'uikit/preloader/Preloader';

const LazyNewRolePage = lazy(() => import('modules/roles/pages/NewRolePage'));
const LazyEditRolePage = lazy(() => import('modules/roles/pages/EditRolePage'));
const LazyCopyRolePage = lazy(() => import('modules/roles/pages/CopyRolePage'));
const LazyRolesPage = lazy(() => import('modules/roles/pages/RolesPage'));
const LazyUsersProfilePage = lazy(() => import('modules/users/pages/UsersProfilePage'));
const LazyUsersPage = lazy(() => import('modules/users/pages/UsersPage'));
const LazyUserManagementPage = lazy(() =>
  import('modules/userManagement/pages/UserManagementPage')
);

const UMRouter: FC = (): ReactElement => (
  <Switch>
    <Route path={Routes.Management} exact>
      <Suspense fallback={<Preloader fillViewport />}>
        <LazyUserManagementPage />
      </Suspense>
    </Route>
    <Route path={Routes.ManagementNewRole}>
      <Suspense fallback={<Preloader fillViewport />}>
        <LazyNewRolePage />
      </Suspense>
    </Route>
    <Route path={Routes.ManagementEditRole}>
      <Suspense fallback={<Preloader fillViewport />}>
        <LazyEditRolePage />
      </Suspense>
    </Route>
    <Route path={Routes.ManagementCopyRole}>
      <Suspense fallback={<Preloader fillViewport />}>
        <LazyCopyRolePage />
      </Suspense>
    </Route>
    <Route exact path={Routes.ManagementRoles}>
      <Suspense fallback={<Preloader fillViewport />}>
        <LazyRolesPage />
      </Suspense>
    </Route>
    <Route path={Routes.ManagementUserDetails}>
      <Suspense fallback={<Preloader fillViewport />}>
        <LazyUsersProfilePage />
      </Suspense>
    </Route>
    <Route exact path={Routes.ManagementUsers}>
      <Suspense fallback={<Preloader fillViewport />}>
        <LazyUsersPage />
      </Suspense>
    </Route>
    <Route exact path={Routes.Management}>
      <Redirect to={Routes.ManagementUsers} />
    </Route>
    <Route>
      <Redirect to={Routes.NotFound} />
    </Route>
  </Switch>
);

export default UMRouter;
