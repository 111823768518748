import { Middleware } from 'redux';
import { RootState } from 'redux/root-reducer';
import isEndAction from 'redux/fileUpload/utils/isEndAction';
import { ModalConfigActionTypes, modalClose } from 'redux/modal/modalActions';

const fileUploadEndCloseDialog: Middleware<{}, RootState> = (store) => (next) => (
  action
) => {
  if (isEndAction(action)) {
    const state = store.getState();
    if (
      state.modal.isOpen &&
      state.modal.config?.type === ModalConfigActionTypes.UPLOAD_ASSET
    ) {
      setTimeout(() => store.dispatch(modalClose()), 0);
    }
  }

  return next(action);
};

export default fileUploadEndCloseDialog;
