import { FC, ReactElement } from 'react';
import * as React from 'react';

import Header from 'modules/app/components/Header';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
    },
  }),
  { name: 'PublicLayout' }
);

const PublicLayout: FC = ({ children }): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <Header />
      <div className={classes.root}>{children}</div>
    </>
  );
};

export default PublicLayout;
