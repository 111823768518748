import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import PUT from 'common/services/httpServices/put';
import constructPath from 'api/utils/constructPath';

const updateLyrics: (
  input: {
    catalog: number;
    project: number;
    content: string;
  },
  params: {
    lyricsId: number;
  }
) => Promise<{ id: number; content: string }> = async (input, params) => {
  const { lyricsId } = params;
  const endpoint = constructPath(CatalogsPaths.UpdateLyrics, {
    lyricsId: lyricsId.toString(),
  });
  return await PUT(endpoint, JSON.stringify(input)).then<{ id: number; content: string }>(
    (res) => res.json()
  );
};

export default updateLyrics;
