import { FileUploadActionTypes, PrepareAction } from 'redux/fileUpload/fileUploadActions';
import { MAX_UPLOAD_FILES } from 'config/network';
import ITransferState from 'redux/fileUpload/interfaces/iTransferState';
import countFreeSlots from 'redux/fileUpload/utils/countFreeSlots';
import createEntries from 'redux/fileUpload/utils/createEntries';
import TransferState from 'redux/fileUpload/types/transferState';

const prepare = (action: PrepareAction) => {
  const {
    autostart = false,
    catalogId,
    files,
    typeId,
    maxFiles = MAX_UPLOAD_FILES,
    ...other
  } = action.payload;

  const next: ITransferState = {
    autostart,
    maxFiles,
    freeSlots: countFreeSlots(files, maxFiles),
    error: null,
    catalogId,
    status: FileUploadActionTypes.PREPARING,
    started: null,
    finished: null,
    files,
    typeId,
    entries: createEntries(files, typeId),
    isSharing: action.payload.isSharing,
  };

  return { ...next, ...other } as TransferState;
};

export default prepare;
