import React from 'react';
import clsx from 'clsx';
import { capitalize, Grid, makeStyles } from '@material-ui/core';

import IconProgress from 'uikit/iconProgress/IconProgress';
import { UploadIcon } from 'common/icons/system';
import BatchUploadProgress from 'uikit/batchUploadProgress/BatchUploadProgress';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      cursor: 'pointer',
      display: 'flex',
      padding: theme.spacing(1, 2),
      minWidth: 384,
      '&$minimized': {
        minWidth: 'auto',
      },
    },
    icon: {
      display: 'block',
    },
    minimized: {},
  }),
  { name: 'UploadingSnack' }
);

interface UploadingSnackProps {
  progress: number;
  filesCount: number;
  color?: 'primary' | 'secondary';
  minimized?: boolean;
  onClick?: React.MouseEventHandler;
  remaining?: React.ReactNode;
}

const UploadingSnack = (props: UploadingSnackProps) => {
  const {
    progress,
    filesCount,
    color = 'primary',
    minimized = false,
    onClick,
    remaining,
  } = props;
  const classes = useStyles();

  const renderContent = () => {
    if (minimized) {
      return <IconProgress color="inherit" progress={progress} />;
    }

    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item xs="auto">
          <UploadIcon className={classes.icon} />
        </Grid>
        <Grid item xs>
          <BatchUploadProgress
            filesCount={filesCount}
            progress={progress}
            remaining={remaining}
            color="contrast"
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div
      className={clsx(
        classes.root,
        { [classes.minimized]: minimized },
        classes[`color${capitalize(color)}`]
      )}
      onClick={onClick}
    >
      {renderContent()}
    </div>
  );
};

export default UploadingSnack;
