import React from 'react';
import { useHistory } from 'react-router-dom';

import { useGetCurrentUserQuery, UserFieldsFragment } from 'generated/graphql';
import useIsMobileUi from 'common/hooks/useIsMobileUi';
import { Routes } from 'navigation/enums/routes';
import useCloseFullScreenView from 'common/hooks/useCloseFullScreenView';

interface UseSideBarNavigationProps {
  isOpened: boolean;
  menuOpenHandler: () => void;
  menuCloseHandler: () => void;
}

interface UseSideBarNavigationRes {
  isLoading: boolean;
  isErrored: boolean;
  user?: UserFieldsFragment;
  toggleNav: () => void;
  handleItemClick: () => void;
  userClickHandler: () => void;
  isMobileUi: boolean;
}

const useSideBarNavigation: (
  props: UseSideBarNavigationProps
) => UseSideBarNavigationRes = ({ menuCloseHandler, menuOpenHandler, isOpened }) => {
  const { loading: isLoading, data, error } = useGetCurrentUserQuery();
  const history = useHistory();
  const isMobileUi = useIsMobileUi();
  const closeFullScreenView = useCloseFullScreenView();

  const toggleNav = React.useCallback(() => {
    if (isOpened) menuCloseHandler();
    else menuOpenHandler();
  }, [menuCloseHandler, menuOpenHandler, isOpened]);

  const handleItemClick = React.useCallback(() => {
    closeFullScreenView();
    if (isMobileUi) toggleNav();
  }, [closeFullScreenView, isMobileUi, toggleNav]);

  const userClickHandler = () => {
    if (isMobileUi) toggleNav();
    history.push(Routes.Settings);
  };

  return {
    isLoading,
    isErrored: Boolean(error),
    user: data?.getCurrentUser,
    isMobileUi,
    toggleNav,
    handleItemClick,
    userClickHandler,
  };
};

export default useSideBarNavigation;
