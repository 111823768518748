export enum SharePermissions {
  VIEW = 1,
  DOWNLOAD = 2,
  UPLOAD = 3,
  EDIT = 4,
  DELETE = 5,
}

export enum SharedLinkExpiration {
  NO_EXPIRATION = 0,
  ONE_DAY = 1,
  ONE_CLICK = 2,
}
