import { DialogActions, DialogContent, makeStyles, Theme } from '@material-ui/core';
import Button from 'uikit/button/Button';
import React, { useCallback, useState } from 'react';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import Preloader from 'uikit/preloader/Preloader';
import Typography from 'uikit/typography/Typography';
import { notify } from 'common/components/notifMessages/Notify';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    color: theme.palette.grey[800],
  },
}));

type Props = {
  onClose: () => void;
  onApplyChange: () => Promise<boolean>;
  name: string;
};

const ModalDelete: React.FC<Props> = ({ onClose, onApplyChange, name }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const callApplyFunc = useCallback(() => {
    setIsLoading(true);
    onApplyChange().then((wasDeleted) => {
      if (wasDeleted) {
        setIsLoading(false);
        onClose();
      } else {
        notify.enqueueSnackbar(
          'You do not have permission to perform this action - please contact your Admin',
          {
            variant: 'error',
          }
        );
        setIsLoading(false);
        onClose();
      }
    });
  }, [onApplyChange, onClose]);

  return (
    <>
      {isLoading && (
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <Preloader />
        </div>
      )}
      <DialogHeader onClose={() => !isLoading && onClose()} title="Are you sure?" />
      <DialogContent>
        <Typography variant="body2" className={classes.text}>
          {name} will be deleted from Drive.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          disabled={isLoading}
          variant={'outlined'}
          dialogActionBase
        >
          Cancel
        </Button>
        <Button
          onClick={callApplyFunc}
          disabled={isLoading}
          variant={'outlined'}
          color={'error'}
          dialogActionBase
        >
          Delete
        </Button>
      </DialogActions>
    </>
  );
};

export default ModalDelete;
