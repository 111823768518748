import { FC } from 'react';

import useFileUploadAutostart from 'modules/app/hooks/useFileUploadAutostart';
import useFilterSaveEffect from 'modules/app/hooks/useFilterSaveEffect';

const PrivateEffects: FC = (): null => {
  useFileUploadAutostart();
  useFilterSaveEffect();
  return null;
};

export default PrivateEffects;
