export enum ShareActionTypes {
  SET_REFRESH_FN = 'SET_REFRESH_FN',
  SET_SLUG = 'SET_SLUG',
  RESET = 'RESET',
}

interface SetRefreshFnAction {
  type: ShareActionTypes.SET_REFRESH_FN;
  payload: {
    refreshFn: Function | null;
  };
}

export const setRefreshFn = (refreshFn: Function | null): SetRefreshFnAction => ({
  type: ShareActionTypes.SET_REFRESH_FN,
  payload: { refreshFn },
});

interface SetSlugAction {
  type: ShareActionTypes.SET_SLUG;
  payload: {
    slug: string | null;
  };
}

export const setSlug = (slug: string | null): SetSlugAction => ({
  type: ShareActionTypes.SET_SLUG,
  payload: { slug },
});

interface ResetAction {
  type: ShareActionTypes.RESET;
}

export const reset = (): ResetAction => ({
  type: ShareActionTypes.RESET,
});

export type ShareActions = SetRefreshFnAction | SetSlugAction | ResetAction;
