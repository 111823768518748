import SharePaths from 'api/share/enums/sharePaths';
import CreateAssetInput from 'api/share/interfaces/createAssetInput';
import setAuthConfig from 'api/share/utils/setAuthConfig';
import constructPath from 'api/utils/constructPath';
import axios from 'common/api/axios';

const createAsset = async (
  slug: string,
  input: CreateAssetInput
): Promise<{ id: number }> => {
  const response = await axios.post<{ id: number }>(
    constructPath(SharePaths.CreateAsset, { slug }),
    input,
    setAuthConfig()
  );
  return response.data;
};

export default createAsset;
