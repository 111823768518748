import CorePaths from 'api/core/enums/corePaths';
import addAuthHeaders from 'api/utils/addAuthHeaders';
import { AxiosRequestConfig } from 'axios';
import axios from 'common/api/axios';
import { StorageFile, StorageFileUpload } from 'common/api/models';

const createFile = async (input: StorageFileUpload, config?: AxiosRequestConfig) =>
  axios.post<StorageFile>(CorePaths.CreateFile, input, await addAuthHeaders(config));

export default createFile;
