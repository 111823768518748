import { UserFieldsFragment } from 'generated/graphql';
import actionFn from 'common/utils/actionFn';

export enum UserActionTypes {
  Request = 'Request',
  Success = 'Success',
  Failure = 'Failure',
  Patch = 'Patch',
  SetLoading = 'SetLoading',
}

export type UserDataRequestAction = {
  type: UserActionTypes.Request;
};

export const requestUserData = (): UserDataRequestAction => ({
  type: UserActionTypes.Request,
});

export type UserDataSuccessAction = {
  type: UserActionTypes.Success;
  payload: {
    data: UserFieldsFragment;
  };
};

export const setUserData = actionFn<UserDataSuccessAction>(UserActionTypes.Success);

export type UserDataFailureAction = {
  type: UserActionTypes.Failure;
  error: string;
};

export const setUserDataError = (error: string): UserDataFailureAction => ({
  type: UserActionTypes.Failure,
  error,
});

export type UserProfilePatchAction = {
  type: UserActionTypes.Patch;
};

export const patchUserProfile = (): UserProfilePatchAction => ({
  type: UserActionTypes.Patch,
});

export type UserDataSetLoadingAction = {
  type: UserActionTypes.SetLoading;
};

export const setLoadingUserData = (): UserDataSetLoadingAction => ({
  type: UserActionTypes.SetLoading,
});

export type UserActions =
  | UserDataRequestAction
  | UserDataSuccessAction
  | UserDataFailureAction
  | UserProfilePatchAction
  | UserDataSetLoadingAction;
