import React from 'react';
import { makeStyles, LinearProgressProps, LinearProgress, fade } from '@material-ui/core';
import clsx from 'clsx';

import { Overwrite } from 'common/utilityTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
  },
  colorContrast: {
    backgroundColor: fade(theme.palette.common.black, 0.25),
  },
  barColorContrast: {
    backgroundColor: theme.palette.common.white,
  },
}));

export default (
  props: Overwrite<
    LinearProgressProps,
    {
      color?: 'primary' | 'secondary' | 'contrast';
    }
  >
) => {
  const { color, ...other } = props;
  const isContrast = color === 'contrast';
  const classes = useStyles();
  const classesProp = {
    root: clsx(classes.root, {
      [classes.colorContrast]: isContrast,
    }),
    bar: clsx({ [classes.barColorContrast]: isContrast }),
  };

  return (
    <LinearProgress
      classes={classesProp}
      color={color !== 'contrast' ? color : undefined}
      {...other}
    />
  );
};
