import FileBrowserState from 'redux/fileBrowser/interfaces/fileBrowserState';
import {
  FileBrowserActions,
  FileBrowserActionTypes,
  SetFileBrowserClosedAction,
  SetFileBrowserContentAction,
} from 'redux/fileBrowser/fileBrowserActions';
import INIT_FILE_BROWSER_STATE from 'redux/fileBrowser/configs/initFileBrowserState';
import { CommonActions, CommonActionTypes } from 'redux/commonActions';

const fileBrowserReducer = (
  state: FileBrowserState = INIT_FILE_BROWSER_STATE,
  action: FileBrowserActions | CommonActions
): FileBrowserState => {
  switch (action.type) {
    case CommonActionTypes.CloseFileBrowserAndUnselectFile:
      return INIT_FILE_BROWSER_STATE;
    case FileBrowserActionTypes.SetFileBrowserOpened:
      return { ...state, ...action.payload.value };
    case FileBrowserActionTypes.SetFileBrowserClosed:
      return { ...state, isOpened: (action as SetFileBrowserClosedAction).payload.value };
    case FileBrowserActionTypes.SetFileBrowserContent:
      return { ...state, content: (action as SetFileBrowserContentAction).payload.value };
    default:
      return state;
  }
};

export default fileBrowserReducer;
