import DriveItem from 'redux/selectedFile/interfaces/driveItem';
import { FOLDER_ITEM_ACCEPT_TYPE } from 'config/acceptTypes';
import {
  ModalConfigActionTypes,
  modalOpen,
  ModalUploadAssetConfigAction,
} from 'redux/modal/modalActions';

const uploadMasterAsset = (file: DriveItem) => {
  const { catalogId, masterId, typeId, itemTypeId } = file;
  const accept = FOLDER_ITEM_ACCEPT_TYPE[itemTypeId];
  const isUploadable = Boolean(catalogId && masterId && typeId && accept !== '');

  return (
    isUploadable &&
    modalOpen({
      type: ModalConfigActionTypes.UPLOAD_ASSET,
      data: {
        params: {
          typeId,
          catalogId,
          masterId,
        },
        props: {
          multiple: true,
          accept,
        },
      },
    } as ModalUploadAssetConfigAction)
  );
};

export default uploadMasterAsset;
