import FileUploadState from 'redux/fileUpload/types/fileUploadState';
import ProjectState from 'redux/fileUpload/interfaces/projectState';
import { FileUploadActionTypes } from 'redux/fileUpload/fileUploadActions';
import { ProjectAssetIds, ProjectAssetType } from 'common/api/models';

const isProjectState = (state: FileUploadState): state is ProjectState =>
  state.status !== FileUploadActionTypes.IDLE &&
  ProjectAssetIds.includes(state.typeId as ProjectAssetType);

export default isProjectState;
