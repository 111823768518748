import * as React from 'react';
import Header, { HeaderProps } from './Header';
import { useFormikContext } from 'formik';

const FormikHeader = (props: HeaderProps) => {
  const { isSubmitting } = useFormikContext();
  return <Header {...props} submitting={isSubmitting || props.submitting} />;
};

export default FormikHeader;
