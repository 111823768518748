import { createMuiTheme } from '@material-ui/core';
import breakpoints from 'theme/core/breakpoints';
import palette from 'theme/core/palette';
import spacing from 'theme/core/spacing';
import typography from 'theme/core/typography';
import createOverrides from 'theme/overrides';
import mixins from 'theme/core/mixins';
import * as checkboxIcons from './checkboxIcons';

export function createTheme() {
  const overrides = createOverrides(typography, palette, spacing, mixins);

  const theme = createMuiTheme({
    breakpoints,
    palette,
    overrides,
    typography,
    spacing,
    mixins,
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The default props to change
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
      MuiCheckbox: {
        checkedIcon: checkboxIcons.checkedIcon,
        icon: checkboxIcons.icon,
      },
    },
  });

  theme.shape.rightPanelWidth = 432;
  theme.shape.closedPanelWidth = 80;
  theme.shape.menuItemHeight = 32;
  theme.shape.borderRadiusLg = 16;

  theme.zIndex.player = theme.zIndex.drawer + 1;

  return theme;
}
