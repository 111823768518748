import { combineReducers } from 'redux';

import menu from 'redux/menu/menuReducer';
import filters from 'redux/filters/filtersReducer';
import fileUpload from 'redux/fileUpload/fileUploadReducer';
import shareReducer from 'redux/share/shareReducer';
import { roleEditorReducer } from 'redux/roleEditor/roleEditorReducer';
import userReducer from 'redux/user/userReducer';
import loginReducer from 'redux/login/loginReducer';
import fileBrowserReducer from 'redux/fileBrowser/fileBrowserReducer';
import splitSheetReducer from 'modules/splitSheet/redux/reducer';
import { roleManagerReducer } from 'modules/roles/redux/reducer';
import permissionsReducer from 'redux/permissions/permissionsRreducer';
import selectedFile from 'redux/selectedFile/selectedFileReducer';
import fullScreenView from 'redux/fullScreenView/fullScreenViewReducer';
import defaultSelectedReducer from 'redux/selectedFile/defaultSelectedReducer';
import modal from 'redux/modal/reducer';
import dialog from 'redux/dialog/dialogReducer';
import player from 'redux/player/playerReducer';
import subscribedSubscriptionReducer from 'redux/subscribedSubscription/subscribedSubscriptionReducer';
import refetchReducer from 'redux/refetch/refetchReducer';

const rootReducer = combineReducers({
  filters,
  user: userReducer,
  loginState: loginReducer,
  permissions: permissionsReducer,
  splitSheet: splitSheetReducer,
  selectedFile,
  defaultSelected: defaultSelectedReducer,
  accountSubscription: subscribedSubscriptionReducer,
  menu,
  modal,
  dialog,
  player,
  fullScreenView,
  fileUpload,
  fileBrowser: fileBrowserReducer,
  share: shareReducer,
  roleEditor: roleEditorReducer,
  roleManager: roleManagerReducer,
  refetch: refetchReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
