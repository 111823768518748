import { gql } from '@apollo/client';
import { UmRoleFields } from 'apollo/plugins/userManagement/fragments/UmRoleFields';

export const QueryName = 'GetUmRoles' as const;

export const GetUmRoles = gql`
  ${UmRoleFields}

  query GetUmRoles {
    umRoles @rest(type: "UMRolePayload", path: "/roles/") {
      results @type(name: "UMRole") {
        ...UmRoleFields
      }
    }
  }
`;
