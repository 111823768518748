import ActionPayload from 'common/interfaces/actionPayload';
import { SharedLinkExpiration, SharePermissions } from 'common/models/sharing';
import actionFn from 'common/utils/actionFn';

import ShareDialogValidation from '../interfaces/shareDialogValidation';
import Recipient from '../interfaces/recipient';

export enum ShareDialogActionTypes {
  SetRecipient = 'SetRecipient',
  SetMessage = 'SetMessage',
  SetPermissions = 'SetPermissions',
  SetExpiration = 'SetExpiration',
  Set2FA = 'Set2FA',
  SetValidation = 'SetValidation',
}

export interface SetRecipientAction {
  type: ShareDialogActionTypes.SetRecipient;
  payload: ActionPayload<Recipient>;
}

export const setRecipient = actionFn<SetRecipientAction>(
  ShareDialogActionTypes.SetRecipient
);

export interface SetMessageAction {
  type: ShareDialogActionTypes.SetMessage;
  payload: ActionPayload<string>;
}

export const setMessage = actionFn<SetMessageAction>(ShareDialogActionTypes.SetMessage);

export interface SetPermissionsAction {
  type: ShareDialogActionTypes.SetPermissions;
  payload: ActionPayload<SharePermissions[]>;
}

export const setPermissions = actionFn<SetPermissionsAction>(
  ShareDialogActionTypes.SetPermissions
);

export interface SetExpirationAction {
  type: ShareDialogActionTypes.SetExpiration;
  payload: ActionPayload<SharedLinkExpiration>;
}

export const setExpiration = actionFn<SetExpirationAction>(
  ShareDialogActionTypes.SetExpiration
);

export interface Set2FAAction {
  type: ShareDialogActionTypes.Set2FA;
  payload: ActionPayload<boolean>;
}

export const set2FA = actionFn<Set2FAAction>(ShareDialogActionTypes.Set2FA);

export interface SetValidationAction {
  type: ShareDialogActionTypes.SetValidation;
  payload: ActionPayload<ShareDialogValidation>;
}

export const setValidation = actionFn<SetValidationAction>(
  ShareDialogActionTypes.SetValidation
);

export type ShareDialogAction =
  | SetRecipientAction
  | SetMessageAction
  | SetPermissionsAction
  | Set2FAAction
  | SetValidationAction
  | SetExpirationAction;
