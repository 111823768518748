import * as React from 'react';
import { DialogTitle, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import EditableTitle, { EditableTitleProps } from 'uikit/editableTitle/EditableTitle';
import { IconButton } from 'uikit/iconButton/IconButton';
import { CloseIcon } from 'common/icons/system';
import { SomePartial } from 'common/utilityTypes';
import useIsMobileUi from 'common/hooks/useIsMobileUi';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4, 5, 2),
      },
    },
    editable: {},
    closeButton: {
      // align right edge with icon
      marginRight: -12,
    },
  }),
  { name: 'UikDialogHeader' }
);

export interface HeaderProps {
  onClose?: () => void;
  title?: string;
  className?: string;
  editable?: boolean;
  submitting?: boolean;
  beforeTitleContent?: React.ReactElement;
  EditableTitleProps?: SomePartial<EditableTitleProps, 'variant'>;
}

const Header: React.FC<HeaderProps> = (props) => {
  const {
    title,
    className: classNameProp,
    onClose,
    editable = false,
    submitting = false,
    EditableTitleProps = {} as EditableTitleProps,
    beforeTitleContent,
  } = props;
  const { ediphyProps, ...titleProps } = EditableTitleProps;
  const classes = useStyles();
  const className = clsx(classes.root, { [classes.editable]: editable }, classNameProp);
  const isMobile = useIsMobileUi();

  return (
    <DialogTitle className={className} disableTypography>
      <EditableTitle
        variant={isMobile ? 'h6' : 'h4'}
        {...titleProps}
        ediphyProps={{
          noWrap: true,
          disableClickBubbling: true,
          defaultValue: title,
          ...ediphyProps,
        }}
        disabled={submitting || !editable}
        beforeTitleContent={beforeTitleContent}
      />
      {onClose && (
        <IconButton
          disabled={submitting}
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export default Header;
