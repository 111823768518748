import { useRef, useCallback, useMemo } from 'react';

export default function useBlurOnEnter(options: {
  ref?: React.RefObject<HTMLInputElement | null>;
}) {
  const { ref } = options;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        const elt = ref?.current ? ref.current : inputRef?.current;
        if (elt) {
          elt.blur();
        }
      }
    },
    [ref]
  );

  return useMemo(() => [inputRef, onKeyDown] as const, [inputRef, onKeyDown]);
}
