import createRecord from 'common/utils/createRecord';
import { ContentTypesArray } from 'common/constants';
import initPrivilegesConfig from 'redux/permissions/utils/initPrivilegesConfig';
import ContentPermissions from 'common/interfaces/contentPermissions';
import PrivilegesConfig from 'common/types/privilegesConfig';

const initContentPermissions = (config?: PrivilegesConfig): ContentPermissions => {
  return createRecord(ContentTypesArray, config ? config : initPrivilegesConfig());
};

export default initContentPermissions;
