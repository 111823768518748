import { useSelector } from 'react-redux';
import { permissionsSelector } from 'redux/permissions/permissionsSelectors';
import checkIsAvailableSection from 'common/utils/checkIsAvailableSection';
import MASTER_CONTENT_TYPES from 'config/masterContentTypes';
import BEAT_CONTENT_TYPES from 'config/beatContentTypes';
import PROJECT_CONTENT_TYPES from 'config/projectContentTypes';
import { useMemo } from 'react';

interface UseDriveNavItemsRes {
  isHasMasters: boolean;
  isHasProjects: boolean;
  isHasBeats: boolean;
}

const useHasAccessToDrive: () => UseDriveNavItemsRes = () => {
  const permissions = useSelector(permissionsSelector);

  const isHasBeats = useMemo(
    () =>
      checkIsAvailableSection({
        permissions,
        contentTypes: BEAT_CONTENT_TYPES,
      }),
    [permissions]
  );
  const isHasMasters = useMemo(
    () =>
      checkIsAvailableSection({
        permissions,
        contentTypes: MASTER_CONTENT_TYPES,
      }),
    [permissions]
  );
  const isHasProjects = useMemo(
    () =>
      checkIsAvailableSection({
        permissions,
        contentTypes: PROJECT_CONTENT_TYPES,
      }),
    [permissions]
  );

  return {
    isHasBeats,
    isHasMasters,
    isHasProjects,
  };
};

export default useHasAccessToDrive;
