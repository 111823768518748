import CorePaths from 'api/core/enums/corePaths';
import addAuthHeaders from 'api/utils/addAuthHeaders';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'common/api/axios';
import { AttachBody } from 'common/api/models';

const getAttachFileUrl = async (
  input: AttachBody,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<string>> =>
  axios.post<string>(CorePaths.GetAttachFileUrl, input, await addAuthHeaders(config));

export default getAttachFileUrl;
