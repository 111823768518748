import React, { useState, useContext } from 'react';
import {
  DialogActions,
  DialogContent,
  FormLabel,
  FormControl,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import Typography from 'uikit/typography/Typography';
import Button from 'uikit/button/Button';
import { RefreshIcon } from 'common/icons/system';
import SubButton from './SubButtons';
import {
  Ra360BallIcon,
  StemsBallIcon,
  FileBallIcon,
  CameraBallIcon,
  MetadataBallIcon,
} from 'common/icons/content-types';
import { CustomizeMaster } from '../../ModalMasterUpload/reducer';
import { userSelector } from 'redux/user/userSelectors';
import * as Modal from 'redux/modal/modalActions';
import { useDispatch, useSelector } from 'react-redux';
import ExpandedContext from 'common/store/expanded/store/expandedContext';
import { setExpandedMasterId } from 'common/store/expanded/store/expandedActions';
import MasterCustomizeTypes from '../emun/masterCustomizeTypes';
import customizeTypesForMaster from '../utils/customizeTypesForMaster';
import SUB_BUTTON_TITILE from '../configs/subButtonTitles';
import SUB_BUTTON_DESCRIPTIONS from '../configs/subButtonDescriptions';
import useGetMasterFolder from 'common/components/masterFullView/hooks/useGetMasterFolder';
import useCustomizeMaster, { UseDistributeProps } from '../hooks/uploadMasterMetaField';

const useStyles = makeStyles(
  (theme) => ({
    content: {
      maxHeight: 550,
      justifyContent: 'space-between',
    },
    formCheckbox: {
      transform: 'scale(1.3)',
    },
    formLabel: {
      '&.Mui-focused': {
        color: theme.palette.text.secondary,
      },
      marginBottom: '30px',
    },
    formControlLabel: {
      marginLeft: -8,
      '& .MuiCheckbox-root': {
        padding: 4,
      },
      '& .MuiFormControlLabel-label': {
        display: 'flex',
      },
    },
    formControlIcon: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
    },
    formControlTitle: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      marginLeft: '15px',
      fontSize: 'medium',
      fontWeight: 'bold',
    },
    formControlSubTitle: {
      marginTop: '0.4em',
      fontWeight: 'lighter',
      fontSize: 'small',
      color: 'grey',
    },
    formControlResetBtn: {
      position: 'relative',
      marginLeft: '3em',
      alignItems: 'up',
    },
    formControlResetIcon: {
      display: 'flex',
    },
    formControlTitleReset: {
      color: 'red',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      marginLeft: '15px',
      fontSize: 'medium',
      fontWeight: 'bold',
    },
    customizeMasterHeader: {
      '& h4': {
        fontSize: '2rem',
      },
    },
    customizeMasterDescription: {
      fontSize: '1.2rem',
      width: '300px',
    },
    line: {
      position: 'relative',
      marginTop: '1em',
      paddingBottom: '.5em',

      '&:after': {
        content: '""',
        position: 'absolute',
        left: 29,
        top: 0,
        right: 0,
        height: 1,
        backgroundColor: theme.palette.grey[100],
      },
    },
  }),
  { name: 'CheckListDialog' }
);

export type CustomizeMasterContentProps = {
  title: string;
  listTitle: React.ReactNode;
  submitLabel?: React.ReactNode;
  loading?: boolean;
  config: CustomizeMaster;
  isEdit: boolean;
};

const CustomizeMasterContent: React.FC<CustomizeMasterContentProps> = (props) => {
  const { title, listTitle, submitLabel, loading, config } = props;
  const classes = useStyles();
  const { dispatchExpandedState } = useContext(ExpandedContext);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const customizeValuesTrue = {
    realityAudio: true,
    stemFiles: true,
    contracts: true,
    metadata: true,
    media: true,
    isAdmin: user?.isAdmin,
    userId: user?.id,
  };
  const curentCustomazeValue = config?.customizeMaster || customizeValuesTrue;
  const [customazeValue, setCustomazeValue] = useState({ ...curentCustomazeValue });
  const change_current = (e) => {
    setCustomazeValue({ ...customazeValue, [e.target.id]: e.target.checked });
  };
  const resetAll = () => {
    setCustomazeValue({ ...curentCustomazeValue, ...customizeValuesTrue });
  };
  const { masterFolder } = useGetMasterFolder({ folderId: config.masterId });
  const userId = user?.id;
  const masterMeta: any = masterFolder?.meta;
  let isExists = false;
  for (let index = 0; index < masterMeta?.customizeMaster; index++) {
    if (masterMeta?.customizeMaster[index].userId === user?.id) {
      isExists = true;
    }
  }
  const customizeList = isExists
    ? masterMeta?.customizeMaster
    : [masterMeta?.customizeMaster, customazeValue];
  const allCustomize = customizeList.map((item) => {
    if (item == null) {
      return {
        realityAudio: customazeValue.realityAudio,
        stemFiles: customazeValue.stemFiles,
        contracts: customazeValue.contracts,
        metadata: customazeValue.metadata,
        media: customazeValue.media,
        isAdmin: user?.isAdmin,
        userId: user?.id,
      };
    }
    if (item.userId === userId) {
      return {
        realityAudio: customazeValue.realityAudio,
        stemFiles: customazeValue.stemFiles,
        contracts: customazeValue.contracts,
        metadata: customazeValue.metadata,
        media: customazeValue.media,
        isAdmin: user?.isAdmin,
        userId: user?.id,
      };
    }
    return {
      realityAudio: item.realityAudio,
      stemFiles: item.stemFiles,
      contracts: item.contracts,
      metadata: item.metadata,
      media: item.media,
      isAdmin: item.isAdmin,
      userId: item.userId,
    };
  });

  const returnMeta = { customizeMaster: allCustomize };
  const mutationVars: UseDistributeProps = {
    id: config.masterId,
    customizeData: returnMeta,
  };
  const { customize } = useCustomizeMaster(mutationVars);

  const onSubmit = () => {
    dispatch(Modal.modalClose());
    config?.isEdit
      ? customize()
      : dispatch(
          Modal.modalOpen({
            type: Modal.ModalConfigActionTypes.MASTER_UPLOAD,
            data: {
              id: config.catalogId,
              name: config.catalogName,
              masterName: config?.masterName,
              customizeMaster: customazeValue,
              files: config?.files,
              onUpload: (masterId: string) => {
                dispatchExpandedState(setExpandedMasterId({ value: masterId }));
              },
            },
          })
        );
  };

  const onClose = () => {
    dispatch(Modal.modalClose());
    !config?.isEdit &&
      dispatch(
        Modal.modalOpen({
          type: Modal.ModalConfigActionTypes.MASTER_UPLOAD,
          data: {
            id: config.catalogId,
            name: config.catalogName,
            masterName: config.masterName,
            customizeMaster: curentCustomazeValue,
            files: config?.files,
            onUpload: (masterId: string) => {
              dispatchExpandedState(setExpandedMasterId({ value: masterId }));
            },
          },
        })
      );
  };
  const accessValue = user?.isAdmin
    ? customizeValuesTrue
    : config?.customazeAccess || customizeValuesTrue;

  return (
    <>
      <DialogHeader
        onClose={onClose}
        title={title}
        className={classes.customizeMasterHeader}
      />
      <DialogContent className={classes.content}>
        <FormControl component="fieldset">
          <FormLabel className={classes.formLabel} component="legend">
            <Typography variant="body2" className={classes.customizeMasterDescription}>
              {listTitle}
            </Typography>
          </FormLabel>
          {Object.keys(MasterCustomizeTypes).map((typeKey) => {
            const customizeType = customizeTypesForMaster[typeKey];
            const isDisabled = !accessValue[typeKey];

            return customizeType ? (
              <SubButton
                key={typeKey}
                className={classes.content}
                endIcon={
                  <Checkbox
                    id={typeKey}
                    className={classes.formCheckbox}
                    color="primary"
                    checked={isDisabled ? true : customazeValue[typeKey]}
                    onChange={change_current}
                    disabled={isDisabled}
                  />
                }
              >
                <label htmlFor={typeKey}>
                  <div className={classes.formControlIcon}>
                    {typeKey === MasterCustomizeTypes.realityAudio ? (
                      <Ra360BallIcon />
                    ) : typeKey === MasterCustomizeTypes.stemFiles ? (
                      <StemsBallIcon />
                    ) : typeKey === MasterCustomizeTypes.contracts ? (
                      <FileBallIcon />
                    ) : typeKey === MasterCustomizeTypes.metadata ? (
                      <MetadataBallIcon />
                    ) : (
                      <CameraBallIcon />
                    )}

                    <div className={classes.formControlTitle}>
                      {SUB_BUTTON_TITILE[typeKey]}
                      <span className={classes.formControlSubTitle} color="inherit">
                        {SUB_BUTTON_DESCRIPTIONS[typeKey]}
                      </span>
                    </div>
                  </div>
                </label>
              </SubButton>
            ) : null;
          })}
        </FormControl>
        <div className={classes.line} />
        <SubButton className={classes.formControlResetBtn} onClick={resetAll}>
          <div className={classes.formControlResetIcon}>
            <RefreshIcon fontSize="small" color="inherit" />
            <div className={classes.formControlTitleReset}>
              Reset
              <span className={classes.formControlSubTitle}>
                Reset setting to Tully's system default
              </span>
            </div>
          </div>
        </SubButton>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={onClose} dialogAction>
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant={'contained'}
          color="primary"
          onClick={onSubmit}
          dialogAction
        >
          {submitLabel}
        </Button>
      </DialogActions>
    </>
  );
};

export default CustomizeMasterContent;
