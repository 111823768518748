import React, { useState } from 'react';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import {
  useGenerateLyricsByThemeWithChatGptMutation,
  useRewriteLyricsWithChatGptMutation,
} from 'generated/graphql';
import PageType from 'common/components/Modals/openAILyricsHelperDialog/enums/pageType';
import { makeStyles } from '@material-ui/core/styles';
import Preloader from 'uikit/preloader/Preloader';
import { IconOpenAIWeb } from 'common/icons/system';
import StartPage from 'common/components/Modals/openAILyricsHelperDialog/components/StartPage';
import GenerateLyricsPage from 'common/components/Modals/openAILyricsHelperDialog/components/GenerateLyricsPage';
import GENERATION_CONFIG from 'common/components/Modals/openAILyricsHelperDialog/config/generationConfig';
import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';
import { notify } from 'common/components/notifMessages/Notify';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '680px',
      [theme.breakpoints.down('md')]: {
        width: 'auto',
        height: '100%',
      },
    },
    openAIIcon: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'OpenAILyricsHelperDialog' }
);
interface OpenAILyricsHelperDialogProps {
  editor: BaseEditor & ReactEditor;
  onClose: () => void;
}
const OpenAILyricsHelperDialog: React.FC<OpenAILyricsHelperDialogProps> = ({
  editor,
  onClose,
}) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState<PageType>(PageType.START);
  const [
    generateLyricsByTheme,
    { data: generateLyricsByThemeData, loading: generateLyricsByThemeLoading },
  ] = useGenerateLyricsByThemeWithChatGptMutation();
  const [
    rewriteLyrics,
    { data: rewriteLyricsData, loading: rewriteLyricsLoading },
  ] = useRewriteLyricsWithChatGptMutation();

  let currentPageElement;
  let loading: boolean;
  let title: string;

  switch (currentPage) {
    case PageType.START:
      title = '';
      currentPageElement = (
        <StartPage setCurrentPage={setCurrentPage} onClose={onClose} />
      );
      loading = false;
      break;
    case PageType.GENERATE_LYRICS_BY_THEME:
      title = 'Generate new lyrics from a theme';
      currentPageElement = (
        <GenerateLyricsPage
          maxInputLength={GENERATION_CONFIG.BY_THEME.maxInputLength}
          placeholder={GENERATION_CONFIG.BY_THEME.placeholder}
          generatedCompletion={
            generateLyricsByThemeData?.generateLyricsByThemeWithChatGPT.completion || null
          }
          promptId={
            generateLyricsByThemeData?.generateLyricsByThemeWithChatGPT.id || null
          }
          editor={editor}
          onCancel={() => setCurrentPage(PageType.START)}
          onClose={onClose}
          generateLyrics={(input: string) => {
            generateLyricsByTheme({
              variables: {
                theme: input,
              },
            })
              .then()
              .catch(() => {
                notify.enqueueSnackbar(`Error: No Lyriq requests left`, {
                  variant: 'error',
                });
                onClose();
              });
          }}
        />
      );
      loading = generateLyricsByThemeLoading;
      break;
    case PageType.REWRITE_LYRICS:
      title = 'Get help with your lyrics';
      currentPageElement = (
        <GenerateLyricsPage
          maxInputLength={GENERATION_CONFIG.REWRITE.maxInputLength}
          placeholder={GENERATION_CONFIG.REWRITE.placeholder}
          generatedCompletion={
            rewriteLyricsData?.rewriteLyricsWithChatGPT.completion || null
          }
          promptId={rewriteLyricsData?.rewriteLyricsWithChatGPT.id || null}
          editor={editor}
          onCancel={() => setCurrentPage(PageType.START)}
          onClose={onClose}
          generateLyrics={(input: string) => {
            rewriteLyrics({
              variables: {
                lyrics: input,
              },
            })
              .then()
              .catch(() => {
                notify.enqueueSnackbar(`Error: No Lyriq requests left`, {
                  variant: 'error',
                });
                onClose();
              });
          }}
        />
      );
      loading = rewriteLyricsLoading;
      break;
  }

  return (
    <div className={classes.root} style={{ height: loading ? '500px' : 'auto' }}>
      {currentPage !== PageType.START ? (
        <DialogHeader
          title={title}
          onClose={onClose}
          beforeTitleContent={
            <IconOpenAIWeb fontSize={'large'} className={classes.openAIIcon} />
          }
        />
      ) : (
        <></>
      )}

      {loading ? <Preloader fillParent={true} /> : currentPageElement}
    </div>
  );
};

export default OpenAILyricsHelperDialog;
