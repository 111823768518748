import { FolderItem } from 'common/api/models';

export enum AcceptTypeString {
  AUDIO = 'audio/mp3,audio/wav,audio/aif,audio/aiff,audio/aac,audio/vnd.dlna.adts,audio/mpeg,audio/mp4,audio/flac',
  RA_360 = 'audio/mp3,audio/wav,audio/aif,audio/aiff,audio/aac,audio/vnd.dlna.adts,audio/mpeg,audio/mp4,video/mp4,audio/flac',
  DOCUMENT = '.txt,.pdf,.doc,.docx,image/*,.png,.jpg,.tiff',
  MEDIA = '.png,.jpg,.tiff,.pdf,.psd,.sketch,.mov,.mp4',
  IMAGE = '.png,.jpg,.jpeg',
}

export type AcceptType = keyof typeof AcceptTypeString;

export const FOLDER_ITEM_ACCEPT_TYPE: Record<FolderItem, AcceptType | ''> = {
  [FolderItem.BEAT]: '',
  [FolderItem.CONTRACTS]: 'DOCUMENT',
  [FolderItem.DOCUMENTS]: 'DOCUMENT',
  [FolderItem.MASTER_FILES]: 'AUDIO',
  [FolderItem.IMPORTS]: 'AUDIO',
  [FolderItem.LYRICS]: '',
  [FolderItem.MEDIA]: 'MEDIA',
  [FolderItem.META]: '',
  [FolderItem.MIXES]: 'AUDIO',
  [FolderItem.RECORDINGS]: 'AUDIO',
  [FolderItem.STEM_FILES]: 'AUDIO',
  [FolderItem.MASTER_360RA]: 'RA_360',
};
