export enum ResourceSlug {
  WriteLyrics = 'write_lyrics',
  RecordAudio = 'record_audio',
  FreeBeats = 'free_beats',
  CreateMix = 'create_mix',
  CreateProject = 'create_project',
  CreateMaster = 'create_master',
  ShareAsset = 'share_asset',
  Collaboration = 'collaboration',
  EnhancedNotepad = 'enhanced_notepad',
  RhymeMode = 'rhyme_mode',
  EnhancedSecurity = 'enhanced_security',
  TrashArchive = 'trash_archive',
  PublishLyrics = 'publish_lyrics',
  BeatsDiscount = 'beats_discount',
  BeatsMarketplace = 'beats_marketplace',
  MultiTenant = 'multi_tenant',
  TeamManagement = 'team_management',
  CatalogManager = 'catalog_manager',
  Analytics = 'analytics',
  Marketplace = 'marketplace',
  Distribution = 'distribution',
  Contracts = 'contracts',
  MasterFolderStructure = 'master_folder_structure',
  ProjectFolderStructure = 'project_folder_structure',
  AdvanceSearch = 'advance_search',
  Drops = 'drops',
  NotificationCenter = 'notification_center',
  ModelLock = 'model_lock',
  ContractSummary = 'contract_summary',
  SplitSheet = 'split_sheet',
  AdvanceSearchFilterAuthor = 'advance_search_filter_author',
  Storage2000GB = 'storage_2000GB',
  Storage5GB = 'storage_5GB',
  Storage500GB = 'storage_500GB',
  Storage1000GB = 'storage_1000GB',
}
