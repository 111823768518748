import { RootState } from 'redux/root-reducer';
import FileBrowserItemType from 'common/enums/fileBrowserItemType';
import CommonFileEntry from 'redux/fileUpload/types/commonFileEntry';
import { FileUploadActionTypes } from 'redux/fileUpload/fileUploadActions';
import isBelongToParent from 'redux/fileUpload/utils/isBelongToParent';

export const fileUploadSelector = (state: RootState) => state.fileUpload;

export const fileUploadStatusSelector = (state: RootState) => state.fileUpload.status;

export const fileUploadIsUploading = (state: RootState) =>
  state.fileUpload.status === FileUploadActionTypes.UPLOADING;

export const ghostFilesNames = (state: RootState) => {
  const fileUpload = state.fileUpload;
  const selected = state.selectedFile;

  if (
    selected?.__itemname === FileBrowserItemType.Drive ||
    selected?.__itemname === FileBrowserItemType.Sharing
  ) {
    if (fileUpload.status !== FileUploadActionTypes.IDLE) {
      const isTypeMatch = fileUpload.typeId === selected.typeId;
      const isMatch = isBelongToParent(fileUpload, selected) && isTypeMatch;

      if (isMatch) {
        const entries = state.fileUpload.entries || [];

        return entries.reduce<CommonFileEntry[]>((acc, entry) => {
          if (entry.completed) return acc;
          return acc.concat(entry);
        }, []);
      }
    }
  }

  return [];
};
