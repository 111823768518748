import { FieldPolicy } from '@apollo/client/cache';

const itemsCount = (name: string): FieldPolicy => ({
  read: (_, options): number => {
    const items = options.readField<Array<any>>(name);

    return items?.length || 0;
  },
});

export default itemsCount;
