import { UmUser, UmRoleUser } from 'generated/graphql';
import getIn from 'lodash/get';
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
const strcmp = (a: string, b: string) => a.localeCompare(b);

const strcmpWith = <T>(getStr: (data: T) => string) => {
  return (a: T, b: T): number => {
    return strcmp(getStr(a), getStr(b));
  };
};

export const userName = (user: UmUser) => getIn(user, ['name'], '');

export const userStatus = (user: UmUser) => getIn(user, ['status'], '');

export const joinCatalogs = (user: UmUser) => {
  return user.catalog.map((catalog) => catalog.name).join(', ');
};

export const joinRoles = (user: UmUser) => {
  return user.role.map(({ name }) => name).join(', ');
};

export const namesCmp = strcmpWith(userName);

export const statusCmp = strcmpWith(userStatus);

export const catalogsCmp = strcmpWith(joinCatalogs);

export const rolesCmp = strcmpWith(joinRoles);

export const nameMatcher = (search: string) => (user: UmUser) => {
  return userName(user).toLowerCase().includes(search.toLowerCase());
};

export const catalogMatcher = (catalogId: number) => (user: UmUser) => {
  return user.catalog.some(({ id }) => id === catalogId);
};

export const roleMatcher = (roleId: number) => (user: UmUser) => {
  return user.role.some(({ id }) => id === roleId);
};

export const filterByName = (users: UmUser[], search: string) =>
  users.filter(nameMatcher(search));

export const applyFilters = (
  users: UmUser[],
  filters: {
    search: string | null;
    catalog: number | null;
    role: number | null;
  }
) => {
  const allPass = () => true;
  const matchers = [
    filters.search ? nameMatcher(filters.search) : allPass,
    filters.catalog ? catalogMatcher(filters.catalog) : allPass,
    filters.role ? roleMatcher(filters.role) : allPass,
  ];

  return users.filter((user) => matchers.every((predicate) => predicate(user)));
};

export const toUmRoleUser = (user: UmUser): UmRoleUser => {
  // Not best solution, we should ask BE to provide this
  // fields on UmUser;
  const [first_name, last_name] = user.name.split(' ');

  return {
    id: user.id,
    email: user.email,
    first_name,
    last_name,
    meta: user.meta,
  };
};
