import getAccessToken from 'common/services/tokenServices/getAccessToken';

import { BASE_URL } from './configs/constants';

const DELETE = async (endpoint: string): Promise<boolean> => {
  const token = await getAccessToken();
  const res = await fetch(`${BASE_URL}/${endpoint}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return res.status === 204 || res.status === 200;
};

export default DELETE;
