export const DRIVE_LIST_PAGE_LIMIT = 10;

export const MAX_SIMULTANEOUS_UPLOADS = 5;

export const MAX_UPLOAD_FILES = 100;

export const MAX_UPLOAD_SIZE = 2e9; // 2GB;

export const MIN_IMAGE_SIZE = 1e6; //1Mb

export const MAX_IMAGE_SIZE = 1e7; // 10MB;

export const HUGE_MAX_IMAGE_SIZE = 104857600; // 100MB;
