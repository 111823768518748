import { useState, useEffect, useCallback } from 'react';

export function useStopwatch(initTime = Date.now()) {
  const [startTime, setStartTime] = useState(initTime);
  const [paused, setPause] = useState(false);
  const tick = useState(0)[1];

  if (startTime > Date.now()) {
    throw new Error("Init time can't be in future");
  }

  const reset = useCallback(() => setStartTime(Date.now()), [setStartTime]);

  useEffect(() => {
    if (!paused) {
      const iid = setInterval(() => tick((n) => n + 1), 1000);

      return () => clearInterval(iid);
    }
  }, [tick, paused]);

  return {
    passed: Date.now() - startTime,
    reset,
    setPause,
  };
}
