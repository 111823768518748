import { Moment } from 'moment';

// ACTION TYPES
interface ISetSongNameAction {
  type: '@SPLITSHEET/SET_SONG_NAME';
  payload: string;
}
interface ISetAkaAction {
  type: '@SPLITSHEET/SET_AKA';
  payload: string;
}
interface ISetDateAction {
  type: '@SPLITSHEET/SET_DATE';
  payload: Moment;
}
interface ISetMasterIDAction {
  type: '@SPLITSHEET/SET_MASTER_ID';
  payload: number;
}

export type IBasicDataAction =
  | ISetSongNameAction
  | ISetAkaAction
  | ISetDateAction
  | ISetMasterIDAction;

// ACTIONS
export const setSongNameAction = (name: string): ISetSongNameAction => ({
  type: '@SPLITSHEET/SET_SONG_NAME',
  payload: name,
});
export const setAkaAction = (aka: string): ISetAkaAction => ({
  type: '@SPLITSHEET/SET_AKA',
  payload: aka,
});
export const setDateAction = (date: Moment): ISetDateAction => ({
  type: '@SPLITSHEET/SET_DATE',
  payload: date,
});
export const setMasterIDAction = (id: number): ISetMasterIDAction => ({
  type: '@SPLITSHEET/SET_MASTER_ID',
  payload: id,
});
