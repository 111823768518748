import SharePaths from 'api/share/enums/sharePaths';
import setAuthConfig from 'api/share/utils/setAuthConfig';
import constructPath from 'api/utils/constructPath';
import axios from 'common/api/axios';

const verifySharedVerificationCode = async (
  id: string,
  code: string
): Promise<{ id: number }> => {
  const response = await axios.post<{ id: number }>(
    constructPath(SharePaths.VerifySharedVerificationCode, { id }),
    { code: Number(code) },
    setAuthConfig()
  );

  return response.data;
};

export default verifySharedVerificationCode;
