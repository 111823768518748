import * as React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  IconButton as MuiIconButton,
  CircularProgress,
  IconButtonProps,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    transition: theme.transitions.create('background'),
    '&:active': {
      transition: 'none',
    },
    '&:before': {
      borderRadius: 'inherit',
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  filled: {
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  outlined: {
    '&:before': {
      transition: theme.transitions.create(['color']),
      boxShadow: 'inset 0 0 0 1px currentColor',
    },
  },
  progressBox: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

export const IconButton = React.forwardRef<
  HTMLButtonElement,
  IconButtonProps & {
    outlined?: boolean;
    filled?: boolean;
    loadingCircle?: boolean;
  }
>(function IconButton(props, ref) {
  const {
    outlined: outlinedProp = false,
    filled: filledProp = false,
    children,
    loadingCircle = false,
    className,
    ...other
  } = props;
  const { outlined, filled, progressBox, ...classes } = useStyles(props);

  return (
    <MuiIconButton
      className={clsx({ [outlined]: outlinedProp }, { [filled]: filledProp }, className)}
      ref={ref}
      {...other}
      classes={classes}
    >
      {loadingCircle && (
        <div className={progressBox}>
          <CircularProgress
            size={32}
            thickness={2}
            variant={'indeterminate'}
            disableShrink
            color="primary"
          />
        </div>
      )}
      {children}
    </MuiIconButton>
  );
});
