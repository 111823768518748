import * as React from 'react';
import { makeStyles, CircularProgress, CircularProgressProps } from '@material-ui/core';
import Typography from 'uikit/typography/Typography';
import clsx from 'clsx';

const useUploading = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressBox: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  progress: {},
}));

const IconProgress = (props: {
  className?: string;
  progress?: number;
  color?: CircularProgressProps['color'];
}) => {
  const classes = useUploading();
  const isCompleted = props.progress === undefined || props.progress >= 100;

  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={classes.progressBox}>
        <CircularProgress
          className={classes.progress}
          size={32}
          thickness={2}
          value={props.progress}
          variant={isCompleted ? 'indeterminate' : 'static'}
          disableShrink={isCompleted}
          color={props.color}
        />
      </div>
      {props.progress && (
        <Typography variant="body2">{Math.floor(props.progress)}</Typography>
      )}
    </div>
  );
};

export default IconProgress;
