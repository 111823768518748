import SharePaths from 'api/share/enums/sharePaths';
import setAuthConfig from 'api/share/utils/setAuthConfig';
import constructPath from 'api/utils/constructPath';
import axios from 'common/api/axios';
import { StorageLink } from 'common/api/models';

const getStorageLink = async (slug: string, input: { file_name: string }) =>
  axios.post<StorageLink>(
    constructPath(SharePaths.GetStorageLink, { slug }),
    input,
    setAuthConfig()
  );

export default getStorageLink;
