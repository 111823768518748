import React from 'react';

import FolderItem, { FolderItemProps } from 'common/components/FolderItem/FolderItem';
import FolderItemIcon from 'uikit/folderItemIcon/FolderItemIcon';
import { MasterBallIcon } from 'common/icons/content-types';

const Track = React.memo((props: FolderItemProps) => (
  <FolderItem
    icon={
      <FolderItemIcon>
        <MasterBallIcon />
      </FolderItemIcon>
    }
    deleteButton
    playButton
    {...props}
  />
));

export default Track;
