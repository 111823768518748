import useCreateCollService from 'common/components/Modals/createAlbumDialog/hooks/useCreateCollService';
import { ValidatedValues as CreateFormValues } from 'common/components/Modals/createAlbumDialog/CreateAlbumDialog';

const useCreateCollSubmit = (onClose: () => void) => {
  const createColl = useCreateCollService();
  return async (values: CreateFormValues) => {
    await createColl(values);
    onClose();
  };
};

export default useCreateCollSubmit;
