import { AxiosRequestConfig } from 'axios';
import axios from 'common/api/axios';
import { StorageLink } from 'common/api/models';

const uploadFile = async (
  input: {
    link: StorageLink;
    file: File;
  },
  config?: AxiosRequestConfig
) => {
  const { link, file } = input;
  const endpoint = link.upload_url;

  return axios.put<undefined>(endpoint, file, {
    ...config,
    headers: { ...config?.headers, 'content-type': file.type },
  });
};

export default uploadFile;
