import { Middleware } from 'redux';
import { RootState } from 'redux/root-reducer';
import isStartAction from 'redux/fileUpload/utils/isStartAction';
import { FileUploadActionTypes } from 'redux/fileUpload/fileUploadActions';
import uploadFile from 'redux/fileUpload/utils/uploadFile';

const fileUploadStart: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (isStartAction(action)) {
    const state = store.getState().fileUpload;
    const share = store.getState().share;

    if (state.status === FileUploadActionTypes.PREPARING) {
      const ready = state.files.length > 0;

      if (ready) {
        uploadFile(state, store.dispatch, share).then();
      }
    }
  }

  return next(action);
};

export default fileUploadStart;
