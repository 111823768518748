export default function filenameFromUrl(fileUrl: string) {
  try {
    const url = new URL(fileUrl);
    const filename = url.pathname.substring(url.pathname.lastIndexOf('/') + 1);

    if (filename === '') {
      return null;
    }

    return filename;
  } catch (error) {
    return null;
  }
}
