import * as React from 'react';
import { FastField, FieldProps } from 'formik';
import { TrackList } from 'common/formik/trackListField/components/TrackList';
import { MasterFieldsFragment } from 'generated/graphql';
import { DropResult } from 'react-beautiful-dnd';
import removeIndex from './utils/removeIndex';
import reorder from './utils/reorder';
import MasterDraggable from 'common/formik/MasterDraggable';

export interface IFieldContext {
  masters: Record<string, MasterFieldsFragment>;
  releaseId: number;
}

export type Props = {
  name: string;
  className?: string;
};

export default function TrackListField(props: Props) {
  const { name, className } = props;
  return (
    <FastField name={name}>
      {({ field, meta, form }: FieldProps<number[], IFieldContext>) => {
        const { masters, releaseId } = form.values;

        const handleDragEnd = (result: DropResult) => {
          if (!result.destination) {
            return;
          }
          const next = reorder(
            field.value,
            result.source.index,
            result.destination.index
          );

          form.setFieldValue(field.name, next);
        };

        const handleDelete = (index) => () => {
          form.setFieldValue(field.name, removeIndex(field.value, index));
        };

        return (
          <TrackList
            listId={`${releaseId}`}
            onDragEnd={handleDragEnd}
            className={className}
          >
            {field.value.map((trackId, index) => (
              <MasterDraggable
                key={`${trackId}`}
                index={index}
                id={`${trackId}`}
                master={masters[trackId]}
                mainLine={masters[trackId].title}
                onDelete={handleDelete(index)}
              />
            ))}
          </TrackList>
        );
      }}
    </FastField>
  );
}
