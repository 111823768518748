import CorePaths from 'api/core/enums/corePaths';
import addAuthHeaders from 'api/utils/addAuthHeaders';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'common/api/axios';
import { AttachBody, AttachRes } from 'common/api/models';

const attachFileUrl = async (
  input: AttachBody,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<AttachRes>> =>
  axios.post<AttachRes>(CorePaths.GetAttachFileUrl, input, await addAuthHeaders(config));

export default attachFileUrl;
