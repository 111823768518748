import React, { ChangeEvent, FC } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExpirationRadioSelector from './ExpirationRadioSelector';
import PermissionsCheckboxSelector from './PermissionsCheckboxSelector';
import { SharedLinkExpiration, SharePermissions } from 'common/models/sharing';
import noop from 'common/utils/noop';

const useStyles = makeStyles(
  (theme) => ({
    describeSectionText: {
      margin: theme.spacing(3, 0, 1),
    },
    twoFA: {
      marginLeft: 1,
    },
  }),
  { name: 'ShareSecondStep' }
);

interface ShareSecondStepProps {
  permissionVariants: SharePermissions[];
  permissions: SharePermissions[];
  expiration: SharedLinkExpiration;
  is2FAControlEnabled: boolean;
  is2faEnabled?: boolean;
  isExpirationValid: boolean;
  onPermissionsChange: (permissions: SharePermissions[]) => void;
  onExpirationChange: (permissions: SharedLinkExpiration) => void;
  on2FAStatusChange: (is2FAEnabled: boolean) => void;
}

const ShareSecondStep: FC<ShareSecondStepProps> = ({
  permissionVariants,
  permissions,
  expiration,
  is2FAControlEnabled,
  isExpirationValid,
  is2faEnabled = false,
  onPermissionsChange = noop,
  onExpirationChange = noop,
  on2FAStatusChange = noop,
}) => {
  const classes = useStyles();

  const isOnlyForView =
    permissionVariants.length === 1 && permissionVariants[0] === SharePermissions.VIEW;

  const twoFactorAuthenticationChangeHandler = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    on2FAStatusChange(e.target.checked);
  };
  return (
    <>
      {!isOnlyForView && (
        <PermissionsCheckboxSelector
          permissionVariants={permissionVariants}
          permissions={permissions}
          onChange={onPermissionsChange}
        />
      )}
      <ExpirationRadioSelector
        isValid={isExpirationValid}
        expiration={expiration}
        onChange={onExpirationChange}
      />

      {is2FAControlEnabled && (
        <Box pt={1} className={classes.twoFA}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={twoFactorAuthenticationChangeHandler}
                  checked={is2faEnabled}
                />
              }
              label="2 Step Verification"
            />
          </FormGroup>
        </Box>
      )}
    </>
  );
};

export default ShareSecondStep;
