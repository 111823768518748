import React from 'react';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import {
  Box,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import DNDArea from 'uikit/dragAndDropArea/DragAndDropArea';
import clsx from 'clsx';
import Typography from 'uikit/typography/Typography';
import { DeleteIconOld } from 'common/icons/system';
import LinearProgress from 'uikit/linearProgress/LinearProgress';
import Button from 'uikit/button/Button';
import TextField from 'uikit/textField/TextField';
import getNewFilename from 'common/utils/filename/getNewFilename';
import splitFilename from 'common/utils/filename/split';
import ext from 'common/utils/filename/ext';
import {
  DropIdName,
  init,
  InitArgs,
  ModalActions,
  submitToDropDialogReducer,
} from './reducer';
import useHasPlaceToStore from 'common/hooks/useHasPlaceToStore/useHasPlaceToStore';
import { Drop, DropSubStatus, useGetCurrentUserQuery } from 'generated/graphql';
import Names, { IdName } from 'modules/advancedSearch/components/names/Names';
import Select from 'uikit/select/Select';
import { useSnackbar } from 'notistack';
import apiCoreService from 'api/core/apiCoreService';
import createDropSubmission from 'api/drops/services/createDropSubmission';
import { SharedLinkExpiration, SharePermissions } from 'common/models/sharing';
import apiShareService from 'api/share/apiShareService';

const useStyles = makeStyles(
  (theme) => ({
    dropSelect: {
      marginBottom: theme.spacing(2),
    },
    trackNameText: {
      margin: theme.spacing(3, 0, 1),
    },
    content: {},
    fileBox: {
      maxWidth: '100%',
      paddingBottom: theme.spacing(2),
    },
    item: {
      display: 'flex',
      minHeight: 32,
      marginTop: theme.spacing(2),
      alignItems: 'center',
    },
    itemGutterBottom: {
      paddingBottom: theme.spacing(0.5),
    },
  }),
  { name: 'SubmitToDropDialog' }
);

interface SubmitToDropDialogProps {
  onClose: () => void;
  config: InitArgs;
  drops: Drop[];
}

const SubmitToDropDialog: React.FC<SubmitToDropDialogProps> = ({
  onClose,
  config,
  drops,
}) => {
  const { hasPlaceToStore } = useHasPlaceToStore();
  const notify = useSnackbar();
  const [state, dispatch] = React.useReducer(submitToDropDialogReducer, config, init);
  const [openSelect, setOpenSelect] = React.useState(false);
  const handleSelectInputClick = React.useCallback(() => setOpenSelect(true), []);
  const handleSelectClose = React.useCallback(() => setOpenSelect(false), []);
  const { data: dataUser } = useGetCurrentUserQuery();
  const userCatalogId = dataUser?.getCatalogs[0].id as number;
  const userFullName =
    dataUser?.getCurrentUser.firstName + ' ' + dataUser?.getCurrentUser.lastName;

  const isUploadingState = state.isUploadingState;
  const entry = state.file || undefined;
  const isEmptyValue = state.file === undefined;
  const isEmptyTrackName = state.trackName === '';
  const disableSubmit = isEmptyValue || isUploadingState || isEmptyTrackName;
  const classes = useStyles();
  const openDropsIdName = drops
    .filter((drop) => drop.sub_status === DropSubStatus.OPEN)
    .map((drop) => ({ id: drop.id, name: `${drop.artist_name} | ${drop.name}` }));

  const handleTrackNameChange = React.useCallback(
    (trackName: string) =>
      dispatch({
        type: 'SET_TRACK_NAME',
        payload: trackName,
      }),
    [dispatch]
  );

  const handleDropChange = React.useCallback(
    (entry: DropIdName) =>
      dispatch({
        type: 'SET_DROP',
        payload: entry,
      }),
    [dispatch]
  );

  const handleSelectDropFieldChange = (drop: IdName) => {
    handleDropChange(drop as DropIdName);
    setOpenSelect(false);
  };

  const handleFileChange = React.useCallback(
    (files: FileList | null) => {
      const file = files ? Array.from(files)?.[0] : null;
      if (file) {
        dispatch({
          type: 'SET_FILE',
          payload: file,
        });
        if (isEmptyTrackName) {
          const fileSplit = splitFilename(file.name);
          const fileName = fileSplit ? fileSplit[0] : '';
          handleTrackNameChange(fileName);
        }
      }
    },
    [dispatch, handleTrackNameChange, isEmptyTrackName]
  );

  const handleFileRemove = () => () =>
    dispatch({
      type: 'REMOVE_FILE',
    });

  const modalActions = React.useMemo<ModalActions>(
    () => ({
      close: () => onClose && onClose(),
      setError: (error: any) =>
        dispatch({
          type: 'SET_ERROR',
          payload: error,
        }),
      setProgress: (progress) =>
        dispatch({
          type: 'SET_PROGRESS',
          payload: progress,
        }),
      setUploading: (state) =>
        dispatch({
          type: 'SET_UPLOADING',
          payload: state,
        }),
    }),
    [dispatch, onClose]
  );

  const handleSubmit = async () => {
    if (!hasPlaceToStore(state.file ? [state.file.file] : [])) {
      notify.enqueueSnackbar(`Error: There is not enough place in your storage`, {
        variant: 'error',
      });
      return;
    }
    if (state.file) {
      const onProgress = (event: ProgressEvent) => {
        const progress = Math.floor((event.loaded / event.total) * 100);
        modalActions.setProgress(progress);
      };
      modalActions.setUploading(true);
      try {
        const uploadResult = await apiCoreService.addFile(
          {
            file: state.file.file,
            asset_type: 'DROP_SUBMISSION',
            catalog_id: userCatalogId,
          },
          { onProgress }
        );
        const fileId = uploadResult.data.id;
        const trackTitle = state.trackName + '.' + ext(state.file.file.name);
        const assetId = (
          await createDropSubmission(
            { fileId: fileId, trackTitle: trackTitle, catalogId: userCatalogId },
            { dropId: state.dropId }
          )
        ).id;
        await apiShareService.shareContent({
          expirationType: SharedLinkExpiration.NO_EXPIRATION,
          type: 24,
          ids: [assetId],
          permissions: [SharePermissions.DOWNLOAD],
          recipients: [{ email: 'dropsubmissions@tullyapp.com' }],
          message: `${userFullName} submits ${trackTitle} to ${state.dropName}`,
          two_factor_auth: false,
        });
        notify.enqueueSnackbar(
          `Congratulations! We have received your submission for ${state.dropName}`,
          { variant: 'success' }
        );
      } catch (error) {
        notify.enqueueSnackbar(
          'There has been a problem processing your file, please go back and submit again.',
          { variant: 'error' }
        );
      }
    }
    modalActions.close();
  };

  const handleTrackNameFieldChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = ({ target: { value } }) => {
    handleTrackNameChange(value);
  };

  return (
    <>
      <DialogHeader title={'Submit'} onClose={onClose} />
      <DialogContent className={classes.content}>
        <TextField
          fullWidth
          variant={'outlined'}
          label={'Track name'}
          value={state.trackName}
          placeholder={'Enter your track name'}
          className={classes.trackNameText}
          onChange={handleTrackNameFieldChange}
          error={isEmptyTrackName}
        />
        <Select
          title={'Select drop'}
          text={state.dropName}
          open={openSelect}
          onClose={handleSelectClose}
          onInputClick={handleSelectInputClick}
          className={classes.dropSelect}
          disabled={isUploadingState}
        >
          <Names
            flexSearch
            names={openDropsIdName}
            activeNames={[{ name: state.dropName, id: state.dropId }]}
            handleChange={handleSelectDropFieldChange}
          />
        </Select>
        <DNDArea
          onChange={handleFileChange}
          disabled={!isEmptyValue}
          inputProps={{ accept: state.accept }}
        />
        {entry && (
          <div className={classes.fileBox}>
            <div>
              <Box
                className={clsx(classes.item, {
                  [classes.itemGutterBottom]: !isUploadingState,
                })}
                flexWrap="nowrap"
              >
                <Box flex="1 1 auto" minWidth={0}>
                  <Typography variant="subtitle2">
                    {getNewFilename(entry.file.name, state.trackName)}
                  </Typography>
                </Box>
                <Box flex="0 0 auto">
                  <Box display="flex" pl={1}>
                    {isUploadingState ? (
                      <Typography variant="body2">{entry.progress}%</Typography>
                    ) : (
                      <IconButton size="small" onClick={handleFileRemove()}>
                        <DeleteIconOld />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Box>
              {isUploadingState && (
                <Box>
                  <LinearProgress value={entry.progress} variant="determinate" />
                </Box>
              )}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" dialogAction>
          Cancel
        </Button>
        <Button
          disabled={disableSubmit}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          dialogAction
        >
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

export default SubmitToDropDialog;
