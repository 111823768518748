import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from 'uikit/typography/Typography';
import React from 'react';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const width: React.CSSProperties[] = [
  { width: '29%' },
  { width: '17%' },
  { width: '20%' },
  { width: '17%' },
  { width: '17%' },
];

const useStyles = makeStyles((theme: Theme) => ({
  headerRow: {
    display: 'flex',
    marginTop: theme.spacing(2),
    borderBottom: '1px solid #eeeeee',
  },
  dataRow: {
    display: 'flex',
    backgroundColor: '#fafafa',
    margin: theme.spacing(0, -5, 0),
    padding: theme.spacing(0, 5),
  },
  dataCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 78,
    padding: theme.spacing(1, 1, 1, 0),
  },
  greyText: {
    color: '#909090',
  },
}));

const ContractAgreementInfoParties: React.FC<{ parties: any }> = ({ parties }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant={'h6'}>Contract Parties</Typography>
      {parties.map((party, index) => (
        <React.Fragment key={party.id}>
          <div className={classes.headerRow}>
            <Typography variant={'subtitle1'} style={width[0]}>
              Party {index + 1}
            </Typography>
            <Typography variant={'subtitle2'} style={width[1]}>
              Advance, $
            </Typography>
            <Typography variant={'subtitle2'} style={width[2]}>
              {party.royaltyLabel ? party.royaltyLabel : 'Royalty'}
            </Typography>
            <Typography variant={'subtitle2'} style={width[3]}>
              {party.recoupLabel ? party.recoupLabel : 'Recoup'}
            </Typography>
            <Typography variant={'subtitle2'} style={width[4]}>
              Publishing, %
            </Typography>
          </div>
          <div className={classes.dataRow}>
            <div style={width[0]} className={classes.dataCell}>
              <Typography noWrap={true} variant={'body2'}>
                {party.partyType}
              </Typography>
              <Typography noWrap={true} variant={'body2'}>
                {party.name}
              </Typography>
              <Typography className={classes.greyText} noWrap={true} variant={'body2'}>
                {party.email}
              </Typography>
            </div>
            <p style={width[1]} className={classes.dataCell}>
              {(party.advance && formatter.format(party.advance)) || '-'}
            </p>
            <p style={width[2]} className={classes.dataCell}>
              {party.royalty || '-'}
            </p>
            <p style={width[3]} className={classes.dataCell}>
              {party.recoup || '-'}
            </p>
            <p style={width[4]} className={classes.dataCell}>
              {party.publishing || '-'}
            </p>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default ContractAgreementInfoParties;
