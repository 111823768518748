import { ISplitSheetData } from 'modules/splitSheet/redux/model';

interface IClearAllSplitSheetData {
  type: '@SPLITSHEET/CLEAR_ALL_DATA';
}
export interface ISaveAsDraftAction {
  type: '@SPLITSHEET/SAVE_AS_DRAFT';
  payload: {
    data: ISplitSheetData;
    redirectCallback: () => void;
    showNotification: (name: string) => void;
  };
}
export interface ISaveAndSendAction {
  type: '@SPLITSHEET/SAVE_AND_SEND';
  payload: {
    data: ISplitSheetData;
    redirectCallback: () => void;
    showNotification: (name: string) => void;
  };
}

export type IEntireStateAction =
  | IClearAllSplitSheetData
  | ISaveAsDraftAction
  | ISaveAndSendAction;

// ACTIONS
export const clearAllSplitSheetData = (): IClearAllSplitSheetData => ({
  type: '@SPLITSHEET/CLEAR_ALL_DATA',
});

export const saveAsDraftAction = (
  data: ISplitSheetData,
  redirectCallback: () => void,
  showNotification: (name: string) => void
): ISaveAsDraftAction => ({
  type: '@SPLITSHEET/SAVE_AS_DRAFT',
  payload: { data, redirectCallback, showNotification },
});

export const saveAndSendAction = (
  data: ISplitSheetData,
  redirectCallback: () => void,
  showNotification: (name: string) => void
): ISaveAndSendAction => ({
  type: '@SPLITSHEET/SAVE_AND_SEND',
  payload: { data, redirectCallback, showNotification },
});
