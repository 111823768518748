import ActionPayload from 'common/interfaces/actionPayload';
import actionFn from 'common/utils/actionFn';
import PlayerMedia from 'common/components/player/interfaces/playerMedia';

export enum PlayerActionTypes {
  SetPlayList = 'SetPlayList',
  SetActiveAssetId = 'SetActiveAssetId',
  SetPlayerClosed = 'SetPlayerClosed',
  SetActiveTullyBeatId = 'SetActiveTullyBeatId',
  SetActiveImportedBeatId = 'SetActiveImportedBeatId',
  SetActiveSharedId = 'SetActiveSharedId',
  SetPlay = 'SetPlay',
  SetSharedSource = 'SetSharedSource',
}

export interface SetSharedSourceAction {
  type: PlayerActionTypes.SetSharedSource;
  payload: ActionPayload<PlayerMedia[]>;
}

export const setSharedSource = actionFn<SetSharedSourceAction>(
  PlayerActionTypes.SetSharedSource
);

export interface SetPlayListAction {
  type: PlayerActionTypes.SetPlayList;
  payload: ActionPayload<number[]>;
}

export const setPlayerPlayList = actionFn<SetPlayListAction>(
  PlayerActionTypes.SetPlayList
);

export interface SetActiveSharedAction {
  type: PlayerActionTypes.SetActiveSharedId;
  payload: ActionPayload<number | null>;
}

export const setActiveSharedAsset = actionFn<SetActiveSharedAction>(
  PlayerActionTypes.SetActiveSharedId
);

export interface SetActiveAssetAction {
  type: PlayerActionTypes.SetActiveAssetId;
  payload: ActionPayload<number | null>;
}

export const setActivePlayerAsset = actionFn<SetActiveAssetAction>(
  PlayerActionTypes.SetActiveAssetId
);

export interface SetActiveTullyBeatAction {
  type: PlayerActionTypes.SetActiveTullyBeatId;
  payload: ActionPayload<number | null>;
}

export const setActivePlayerTullyBeat = actionFn<SetActiveTullyBeatAction>(
  PlayerActionTypes.SetActiveTullyBeatId
);

export interface SetActiveImportedBeatAction {
  type: PlayerActionTypes.SetActiveImportedBeatId;
  payload: ActionPayload<number | null>;
}

export const setActivePlayerImportedBeat = actionFn<SetActiveImportedBeatAction>(
  PlayerActionTypes.SetActiveImportedBeatId
);

export interface SetPlayerClosedAction {
  type: PlayerActionTypes.SetPlayerClosed;
  payload: ActionPayload<null>;
}

export const closePlayer = (): SetPlayerClosedAction => ({
  type: PlayerActionTypes.SetPlayerClosed,
  payload: {
    value: null,
  },
});

export interface SetPlayAction {
  type: PlayerActionTypes.SetPlay;
  payload: ActionPayload<number | null>;
}

export const playerPlay = actionFn<SetPlayAction>(PlayerActionTypes.SetPlay);

export const playerPause = (): SetPlayAction => ({
  type: PlayerActionTypes.SetPlay,
  payload: {
    value: null,
  },
});

export type PlayerActions =
  | SetPlayAction
  | SetActiveAssetAction
  | SetPlayListAction
  | SetActiveImportedBeatAction
  | SetPlayerClosedAction
  | SetSharedSourceAction
  | SetActiveSharedAction
  | SetActiveTullyBeatAction;
