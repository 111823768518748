import { gql } from '@apollo/client';

export const AssetFields = gql`
  fragment AssetFields on AMPAsset {
    id
    title
    type
    created_at
    updated_at
    meta {
      ...AssetMeta
    }
    master {
      id
      locked: is_locked
    }
    project {
      id
      locked: is_locked
    }
    type_id
    catalog_id
    mediaCover: media_cover {
      filePath: file_path
    }
    file {
      media_url
      file_name
    }
  }
`;
