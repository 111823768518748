const objToQueryString = (obj: object = {}): string => {
  const keyValuePairs: Array<string> = [];
  for (const key in obj) {
    obj[key] !== undefined &&
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return keyValuePairs.length > 0 ? `?${keyValuePairs.join('&')}` : '';
};

export default objToQueryString;
