import actionFn from 'common/utils/actionFn';
import CatalogIndex from 'redux/permissions/types/catalogIndex';
import GuestContentPermissions from 'common/interfaces/guestContentPermissions';
import ContentPermissions from 'common/interfaces/contentPermissions';

export enum PermissionsActionTypes {
  SET_HARD_P9S = '@PERMISSIONS/SET_HARD_PERMISSIONS',
  SET_USER_P9S = '@PERMISSIONS/SET_USER_PERMISSIONS',
  SET_GUEST_P9S = '@PERMISSIONS/SET_GUEST_PERMISSIONS',
}

export interface SetHardPermissionsAction {
  type: PermissionsActionTypes.SET_HARD_P9S;
  payload: {
    value: ContentPermissions | null;
  };
}

export const setHardPermissions = actionFn<SetHardPermissionsAction>(
  PermissionsActionTypes.SET_HARD_P9S
);

export interface SetUserPermissionsAction {
  type: PermissionsActionTypes.SET_USER_P9S;
  payload: {
    value: CatalogIndex | null;
  };
}

export const setUserPermissions = actionFn<SetUserPermissionsAction>(
  PermissionsActionTypes.SET_USER_P9S
);

export interface SetGuestPermissionsAction {
  type: PermissionsActionTypes.SET_GUEST_P9S;
  payload: {
    value: GuestContentPermissions | null;
  };
}

export const setGuestPermissions = actionFn<SetGuestPermissionsAction>(
  PermissionsActionTypes.SET_GUEST_P9S
);

export type PermissionsActions =
  | SetHardPermissionsAction
  | SetUserPermissionsAction
  | SetGuestPermissionsAction;
