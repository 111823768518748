import UsersPaths from 'api/users/enums/usersPaths';
import axios from 'common/api/axios';
import addAuthHeaders from 'api/utils/addAuthHeaders';

const deactivateUser = async (email: string): Promise<void> => {
  return await axios.post(
    UsersPaths.DeactivateUser,
    { email: email },
    await addAuthHeaders()
  );
};

export default deactivateUser;
