import apiContractsService from 'api/contracts/apiContractsService';
import AmpService from 'common/api/AmpService';
import { ContractWorkflowState as ContractState } from 'generated/graphql';
import { ContentType } from 'common/api/models';
import { setDefaultSelected } from 'redux/selectedFile/selectedFileActions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { populateStateFromApiData } from 'modules/splitSheet/utils';
import {
  IFetchSplitSheetAction,
  ISaveAndSendAction,
  ISaveAsDraftAction,
} from 'modules/splitSheet/redux/actions';
import {
  fetchSplitSheetFailureAction,
  fetchSplitSheetSuccessAction,
  patchSplitSheetFailureAction,
  patchSplitSheetSuccessAction,
  postSplitSheetFailureAction,
  postSplitSheetSuccessAction,
  setReviewStatusFailureAction,
  setReviewStatusSuccessAction,
} from 'modules/splitSheet/redux/actions';
import { ISplitSheetData } from 'modules/splitSheet/redux/model';
import { ErrorSplitSheet } from 'modules/splitSheet/redux/reducer';
import { IRestSplitSheet } from 'modules/splitSheet/contracts/interfaces/IRestSplitSheet';

function* fetchSplitSheet(action: IFetchSplitSheetAction) {
  try {
    const response = (yield call(
      apiContractsService.getSplitSheet,
      action.payload.id
    )) as IRestSplitSheet;
    const data = populateStateFromApiData(response);
    yield put(fetchSplitSheetSuccessAction(data));
  } catch (e) {
    const error: ErrorSplitSheet = {
      status: (e as Response).status,
      message: `There is no Split Sheet with ID:${action.payload.id}`,
    };
    yield put(fetchSplitSheetFailureAction(error));
  }
}

function* postSplitSheet(data: ISplitSheetData) {
  try {
    if (data.master_id) {
      const response = (yield call(
        apiContractsService.postSplitSheet,
        data,
        data.master_id
      )) as IRestSplitSheet;
      const splitSheetData = populateStateFromApiData(response);
      yield put(postSplitSheetSuccessAction(splitSheetData));
      return splitSheetData.splitSheetId;
    }
  } catch (e) {
    const status = (e as Response).status;
    const error: ErrorSplitSheet = {
      status,
      message: 'Error while posting the Split Sheet: please check data in the form',
    };
    yield put(postSplitSheetFailureAction(error));
    throw e;
  }
}

function* patchSplitSheet(data: ISplitSheetData) {
  try {
    if (data.master_id && data.splitSheetId) {
      yield call(
        apiContractsService.patchSplitSheet,
        data,
        data.master_id,
        `${data.splitSheetId}`
      );
      yield put(patchSplitSheetSuccessAction());
    }
  } catch (e) {
    const status = (e as Response).status;
    const error: ErrorSplitSheet = {
      status,
      message: 'Error while updating the Split Sheet: please check data in the form',
    };
    yield put(patchSplitSheetFailureAction(error));
    throw e;
  }
}

function* setReviewStatus(splitSheetId: string) {
  try {
    yield call(apiContractsService.sendForApproval, splitSheetId);

    yield put(setReviewStatusSuccessAction());
  } catch (e) {
    const status = (e as Response).status;
    const error: ErrorSplitSheet = {
      status,
      message: 'Error while sending the Split Sheet for Approval',
    };
    yield put(setReviewStatusFailureAction(error));
    throw e;
  }
}

function* saveAsDraft(action: ISaveAsDraftAction) {
  try {
    if (action.payload.data.master_id !== null) {
      if (!action.payload.data.splitSheetId) {
        yield call(postSplitSheet, action.payload.data);
      } else {
        yield call(patchSplitSheet, action.payload.data);
      }
      action.payload.redirectCallback();
      action.payload.showNotification(action.payload.data.songTitle);
      yield call(AmpService.queryMasterById, action.payload.data.master_id);
      yield put(
        setDefaultSelected(
          `${action.payload.data.master_id}:${ContentType.MASTER_DOCUMENT}`
        )
      );
    }
  } catch (e) {}
}

function* saveAndSend(action: ISaveAndSendAction) {
  try {
    if (action.payload.data.master_id !== null) {
      let splitSheetId: string;
      if (!action.payload.data.splitSheetId) {
        splitSheetId = (yield call(postSplitSheet, action.payload.data)).toString();
      } else {
        splitSheetId = action.payload.data.splitSheetId.toString();
        yield call(patchSplitSheet, action.payload.data);
      }
      // NOTE: if "Save & Send" button was clicked when splitsheet has REVIEW status
      //       then we should avoid setting REVIEW status again
      //       this lets us avoid email duplication
      if (action.payload.data.state !== ContractState.REVIEW)
        yield call(setReviewStatus, splitSheetId);
      action.payload.redirectCallback();
      action.payload.showNotification(action.payload.data.songTitle);
      yield call(AmpService.queryMasterById, action.payload.data.master_id);
      yield put(
        setDefaultSelected(
          `${action.payload.data.master_id}:${ContentType.MASTER_DOCUMENT}`
        )
      );
    }
  } catch (e) {}
}

export default function* mainSaga() {
  yield all([
    takeLatest<IFetchSplitSheetAction>('@SPLITSHEET/FETCH_SPLITSHEET', fetchSplitSheet),
    takeLatest<ISaveAsDraftAction>('@SPLITSHEET/SAVE_AS_DRAFT', saveAsDraft),
    takeLatest<ISaveAndSendAction>('@SPLITSHEET/SAVE_AND_SEND', saveAndSend),
  ]);
}
