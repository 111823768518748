import apiCoreService from 'api/core/apiCoreService';
import Button from 'uikit/button/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { PlusIcon } from 'common/icons/system';
import React, { useCallback } from 'react';
import { notify } from 'common/components/notifMessages/Notify';
import Preloader from 'uikit/preloader/Preloader';
import CreateRoleModal from '../CreateRoleModal';
import { ContractType } from 'generated/graphql';
import { DialogActions, DialogContent } from '@material-ui/core';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import useEdiphyState from 'uikit/ediphy/hooks/useEdiphyState';
import {
  useCreateContractMutation,
  useUpdateContractMutation,
  useCreateContractPartyMutation,
  useUpdateContractPartyMutation,
  useDeleteContractPartyMutation,
} from 'generated/graphql';
import {
  addPartyAction,
  IUploadedContractLocalState,
  reducer,
  setPartyRoleAction,
  validateContractData,
  setTitleAction,
} from 'common/components/Modals/contractAgreementFormDialog/contractAgreementFormState';
import AmpService from 'common/api/AmpService';
import PartiesSection from './components/PartiesSection';
import SummarySection from './components/SummarySection';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 768,
  },
}));

export type ModalUploadedContractEditProps = {
  onClose: () => void;
  isCloseButtonVisible?: boolean;
  isCreatingSummary?: boolean;
  summary: IUploadedContractLocalState;
};

const ContractAgreementFormDialog: React.FC<ModalUploadedContractEditProps> = ({
  onClose,
  isCreatingSummary = false,
  isCloseButtonVisible = true,
  summary,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalConfig, setModalConfig] = React.useState<{
    isActive: boolean;
    id: string | null;
  }>({ isActive: false, id: null });

  const [state, dispatch] = React.useReducer<typeof reducer>(reducer, summary);
  const [createContract] = useCreateContractMutation();
  const [updateContract] = useUpdateContractMutation();
  const [createContractParty] = useCreateContractPartyMutation();
  const [updateContractParty] = useUpdateContractPartyMutation();
  const [deleteContractParty] = useDeleteContractPartyMutation();

  React.useEffect(() => {
    if (isCreatingSummary) {
      dispatch(addPartyAction());
    }
  }, [isCreatingSummary]);

  const handlePartyUpdate = React.useCallback(
    async (contractID: number, partyId, party) => {
      await updateContractParty({
        variables: {
          id: partyId,
          contractId: contractID,
          name: party.name,
          contact: party.email,
          roleLabel: party.role,
          metadata: {
            advance: party.advance,
            royalty: party.royalty,
            royalty_label: party.royaltyLabel,
            recoup: party.recoup,
            recoup_label: party.recoupLabel,
            publishing: party.publishing,
            affiliation: null,
            ownership: null,
          },
        },
      });
    },
    [updateContractParty]
  );

  const handlePartyCreation = React.useCallback(
    async (contractID: number, party) => {
      await createContractParty({
        variables: {
          contractId: contractID,
          name: party.name,
          contact: party.email,
          roleLabel: party.role,
          metadata: {
            advance: party.advance,
            royalty: party.royalty,
            royalty_label: party.royaltyLabel,
            recoup: party.recoup,
            recoup_label: party.recoupLabel,
            publishing: party.publishing,
            affiliation: null,
            ownership: null,
          },
        },
      });
    },
    [createContractParty]
  );

  const updateAgreement = React.useCallback(
    async (fileId: number | null, newSummary: IUploadedContractLocalState) => {
      const { data } = await updateContract({
        variables: {
          id: newSummary.contractID as number,
          title: newSummary.title,
          masterId: newSummary.masterId,
          description: newSummary.description,
          type: (newSummary.type as ContractType) || ContractType.UPLOADED_CONTRACT,
          fileId: fileId,
        },
      });

      const contractID = data?.updateContract?.id;

      for (const partyId of newSummary.deletedPartyIds || []) {
        await deleteContractParty({ variables: { id: partyId } });
      }

      if (contractID && newSummary.isPartyEdited) {
        for (const party of newSummary.parties) {
          const partyId = Number(party.id);
          if (!partyId) {
            await handlePartyCreation(contractID, party);
          } else if (newSummary.editedPartyIds?.includes(partyId)) {
            await handlePartyUpdate(contractID, partyId, party);
          }
        }
      }
    },
    [deleteContractParty, handlePartyCreation, handlePartyUpdate, updateContract]
  );

  const createAgreement = React.useCallback(
    async (fileId: number, newSummary: IUploadedContractLocalState) => {
      try {
        const { data } = await createContract({
          variables: {
            title: newSummary.title,
            masterId: newSummary.masterId,
            description: newSummary.description,
            type: (newSummary.type as ContractType) || ContractType.UPLOADED_CONTRACT,
            fileId: fileId,
          },
        });

        if (data?.createContract?.id && newSummary.isPartyEdited) {
          for (const party of newSummary.parties) {
            await handlePartyCreation(data?.createContract?.id, party);
          }
        }
      } catch {
        notify.enqueueSnackbar("Can't update catalog, please, reload page", {
          variant: 'error',
        });
      }
    },
    [createContract, handlePartyCreation]
  );

  const applyChanges = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const newSummary = state;
      let uploadedFile;
      if (newSummary.fileBlob) {
        uploadedFile = await apiCoreService.addFile({
          asset_type: 'MASTER_ASSET',
          catalog_id: newSummary.catalogId as number,
          parent_id: newSummary.masterId,
          file: newSummary.fileBlob as File,
        });
      }

      !isCreatingSummary
        ? await updateAgreement(
            uploadedFile?.data?.id || newSummary.file?.id || null,
            newSummary
          )
        : await createAgreement(uploadedFile?.data?.id, newSummary);

      await AmpService.queryMaster({
        catalogId: newSummary.catalogId as number,
        masterId: newSummary.masterId,
      });
      setIsLoading(false);
      onClose();
      notify.enqueueSnackbar('Contract SummarySection has been successfully saved!', {
        variant: 'success',
      });
    } catch (e) {
      setIsLoading(false);
      notify.enqueueSnackbar(
        'Error during contract edit process... /nPlease check your form data and try again.',
        {
          variant: 'error',
        }
      );
    }
  }, [state, isCreatingSummary, updateAgreement, createAgreement, onClose]);

  const isFormValid = validateContractData(state);

  const [, ediphyProps] = useEdiphyState(
    state.title,
    (_, next) => {
      dispatch(setTitleAction(next));
    },
    100
  );
  const getOnCloseHandler = useCallback(() => {
    return isCloseButtonVisible
      ? () => {
          !isLoading && onClose();
        }
      : undefined;
  }, [isCloseButtonVisible, isLoading, onClose]);

  return (
    <>
      {isLoading && (
        <div style={{ position: 'absolute', width: '100%' }}>
          <Preloader />
        </div>
      )}
      <DialogHeader
        title={state.title}
        onClose={getOnCloseHandler()}
        EditableTitleProps={{ ediphyProps }}
        editable
      />
      <DialogContent className={classes.container}>
        <SummarySection dispatch={dispatch} summary={state} />

        <PartiesSection
          parties={state.parties}
          dispatch={dispatch}
          setModalConfig={setModalConfig}
        />

        <Button
          color={'secondary'}
          startIcon={<PlusIcon />}
          onClick={() => dispatch(addPartyAction())}
        >
          Add Party
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading} variant="outlined">
          Cancel
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={applyChanges}
          disabled={!isFormValid || isLoading}
        >
          Save
        </Button>
      </DialogActions>
      <Dialog open={modalConfig.isActive}>
        <CreateRoleModal
          closeModal={() => setModalConfig({ isActive: false, id: null })}
          selectRole={(role) => {
            if (modalConfig.id) dispatch(setPartyRoleAction(modalConfig.id, role));
          }}
        />
      </Dialog>
    </>
  );
};

export default ContractAgreementFormDialog;
