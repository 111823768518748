import SharePaths from 'api/share/enums/sharePaths';
import GET from 'common/services/httpServices/get';
import GuestPerms from 'api/share/interfaces/guestPerms';

const getGuestPerms = async (): Promise<GuestPerms> => {
  const res = await GET(SharePaths.GetGuestPerms);

  return await res.json();
};

export default getGuestPerms;
