import SharePaths from 'api/share/enums/sharePaths';
import { ContentType } from 'common/api/models';
import { SharedLinkExpiration } from 'common/models/sharing';
import POST from 'common/services/httpServices/post';

const shareContent = async (input: {
  expirationType: SharedLinkExpiration;
  type: ContentType;
  ids: number[];
  permissions: number[];
  recipients: Array<{ email: string } | { user_id: number }>;
  message: string;
  two_factor_auth?: boolean;
  parentId?: number;
}): Promise<boolean> => {
  const {
    expirationType,
    two_factor_auth,
    type,
    ids,
    permissions,
    recipients,
    message,
    parentId,
  } = input;

  const res = await POST(
    SharePaths.ShareContent,
    JSON.stringify({
      expiration_type: expirationType,
      type: type,
      ids,
      permissions,
      recipients,
      message: message || undefined,
      two_factor_auth,
      parent_id: parentId,
    })
  );

  return res.ok;
};

export default shareContent;
