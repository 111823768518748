import * as React from 'react';
import { FastField, FieldProps } from 'formik';
import CatalogSelect, {
  CatalogSelectProps,
} from 'common/components/catalogSelect/CatalogSelect';

export default function CatalogField(props: CatalogSelectProps & { name: string }) {
  return (
    <FastField name={props.name}>
      {({ field, meta, form }: FieldProps) => {
        const { name } = field;
        const isInvalid = !!meta.error && meta.touched;
        const errorMsg = isInvalid ? meta.error : <span>&nbsp;</span>;

        return (
          <CatalogSelect
            {...props}
            selectedId={field.value}
            onChange={(value) => form.setFieldValue(name, value.id)}
            error={isInvalid}
            helperText={errorMsg}
          />
        );
      }}
    </FastField>
  );
}
