import {
  PrivilegeRec,
  Privileges,
  ContentRec,
  UmRoleCatalog,
  UmRole,
  UmRoleUser,
  UmRoleInput,
  UmRolePrivilegesInput,
  UmRoleContentInput,
} from 'generated/graphql';
import { ContentType } from 'common/api/models';
import { CatalogName } from './Catalog';
import UserListItem from 'common/interfaces/userListItem';

export enum RoleMenuItem {
  EDIT = 'EDIT',
  COPY = 'COPY',
  DELETE = 'DELETE',
}

// WARNING: Don't change order.
// Change items order only if roles requirements changed.
export const PrivilegesOrdered = [
  Privileges.VIEW,
  Privileges.DOWNLOAD,
  Privileges.UPLOAD,
  Privileges.EDIT,
  Privileges.DELETE,
] as const;

export type ContentCategory = 'master' | 'project' | 'beats';

export const TYPE_IDS_BY_CATEGORY: Record<ContentCategory, ContentType[]> = {
  master: [
    ContentType.MASTER_FOLDER,
    ContentType.MASTER_FILE,
    ContentType.MASTER_FILE_CLEAN,
    ContentType.MASTER_FILE_EXPLICIT,
    ContentType.MASTER_STEM,
    ContentType.MASTER_CONTRACT,
    ContentType.MASTER_DOCUMENT,
    ContentType.MASTER_META,
    ContentType.MASTER_MEDIA,
    ContentType.COLLECTION,
  ],
  project: [
    ContentType.PROJECT_FOLDER,
    ContentType.PROJECT_LYRICS,
    ContentType.PROJECT_BEAT,
    ContentType.PROJECT_META,
    ContentType.PROJECT_MIX,
    ContentType.PROJECT_IMPORT,
    ContentType.PROJECT_RECORDING,
  ],
  beats: [ContentType.BEATS_FREE, ContentType.BEATS_IMPORT],
};

export function createPrivilegesSet(): PrivilegeRec[] {
  return [
    {
      slug: Privileges['VIEW'],
      title: 'View',
      allowed: false,
    },
    {
      slug: Privileges['DOWNLOAD'],
      title: 'Download',
      allowed: false,
    },
    {
      slug: Privileges['UPLOAD'],
      title: 'Upload',
      allowed: false,
    },
    {
      slug: Privileges['EDIT'],
      title: 'Edit',
      allowed: false,
    },
    {
      slug: Privileges['DELETE'],
      title: 'Delete',
      allowed: false,
    },
  ];
}

export function createContent() {
  return [
    {
      slug: 'MASTERS',
      title: 'Masters',
      allowed: false,
      type_id: 16,
      category: 'master',
    },
    {
      slug: 'PROJECTS',
      title: 'Projects',
      allowed: false,
      type_id: 14,
      category: 'project',
    },
    {
      slug: 'MASTER_FILE',
      title: 'Master File',
      allowed: false,
      type_id: 20,
      category: 'master',
    },
    {
      slug: 'MASTER_CLEAN',
      title: 'Master File Clean',
      allowed: false,
      type_id: 7,
      category: 'master',
    },
    {
      slug: 'MASTER_EXPLICIT',
      title: 'Explicit Files',
      allowed: false,
      type_id: 8,
      category: 'master',
    },
    {
      slug: 'MASTER_STEM',
      title: 'Stem Files',
      allowed: false,
      type_id: 9,
      category: 'master',
    },
    {
      slug: 'MASTER_CONTRACTS',
      title: 'Contracts',
      allowed: false,
      type_id: 13,
      category: 'master',
    },
    {
      slug: 'MASTER_DOCUMENTS',
      title: 'Documents',
      allowed: false,
      type_id: 12,
      category: 'master',
    },
    {
      slug: 'MASTER_METADATA',
      title: 'Metadata',
      allowed: false,
      type_id: 15,
      category: 'master',
    },
    {
      slug: 'MASTER_MEDIA',
      title: 'Media Files',
      allowed: false,
      type_id: 21,
      category: 'master',
    },
    {
      slug: 'PROJECT_LYRIC',
      title: 'Lyric',
      allowed: false,
      type_id: 17,
      category: 'project',
    },
    {
      slug: 'PROJECT_BEAT',
      title: 'Beat',
      allowed: false,
      type_id: 3,
      category: 'project',
    },
    {
      slug: 'PROJECT_METADATA',
      title: 'Metadata',
      allowed: false,
      type_id: 18,
      category: 'project',
    },
    {
      slug: 'PROJECT_MIX',
      title: 'Mix',
      allowed: false,
      type_id: 6,
      category: 'project',
    },
    {
      slug: 'PROJECT_IMPORT',
      title: 'Import',
      allowed: false,
      type_id: 4,
      category: 'project',
    },
    {
      slug: 'PROJECT_RECORDING',
      title: 'Recording',
      allowed: false,
      type_id: 5,
      category: 'project',
    },
    {
      slug: 'TULLY_BEATS',
      title: 'Tully Beats',
      allowed: false,
      type_id: 1,
      category: null,
    },
    {
      slug: 'IMPORT_BEATS',
      title: 'Imported Beats',
      allowed: false,
      type_id: 2,
      category: null,
    },
    {
      slug: 'COLLECTIONS',
      title: 'Collections',
      allowed: false,
      type_id: 22,
      category: 'master',
    },
  ];
}

export function createUmRoleCatalog(id: number, name: string): UmRoleCatalog {
  return {
    name,
    catalog_id: id,
    permissions: {
      // TOOD: Ask BE about this field.
      assigned: false,
      privileges: createPrivilegesSet(),
      content: createContent(),
    },
  };
}

export function createUmRole(): UmRole {
  const timestamp = new Date().toISOString();

  return {
    id: -1,
    account: -1,
    created_at: timestamp,
    updated_at: timestamp,
    name: 'Untitled Role',
    catalog_details: [],
    assigned_users: [],
  };
}

// If before true, create fn that check if item is less or eq to current
// If before false, create fn that check if item eq or greateer then current
export const positionPredicate = <T>(
  items: ReadonlyArray<T>,
  current: T,
  before: boolean
) => (item: T) => {
  const currentIndex = items.indexOf(current);
  const itemIndex = items.indexOf(item);

  if (before) {
    return itemIndex <= currentIndex;
  }

  return itemIndex >= currentIndex;
};

export function updatePrivilegesSet(
  records: PrivilegeRec[],
  item: Privileges,
  value: boolean,
  ordered = PrivilegesOrdered
): PrivilegeRec[] {
  const predicate = positionPredicate(ordered, item, value);

  return records.map((item) => {
    return {
      ...item,
      allowed: predicate(item.slug) ? value : !value,
    };
  });
}

export function updateContent<V, T extends { type_id: number; allowed: V }>(
  records: T[],
  ids: number[],
  value: V
): T[] {
  return records.map((record) => {
    const index = ids.indexOf(record.type_id);
    return {
      ...record,
      allowed: index >= 0 ? value : record.allowed,
    };
  });
}

export function updateCatalog(
  role: UmRole,
  index: number,
  updateFn: (data: UmRoleCatalog) => UmRoleCatalog
): UmRole {
  return {
    ...role,
    catalog_details: role.catalog_details.map((catalog, i) => {
      if (index === i) {
        return updateFn(catalog);
      }

      return catalog;
    }),
  };
}

export function addCatalog(role: UmRole, catalog: UmRoleCatalog): UmRole {
  return {
    ...role,
    catalog_details: role.catalog_details.concat(catalog),
  };
}

export function addCatalogs(role: UmRole, catalogs: CatalogName[]): UmRole {
  const newItems = catalogs.map(({ id, name }) => createUmRoleCatalog(id, name));

  return {
    ...role,
    catalog_details: role.catalog_details.concat(newItems),
  };
}

export function removeCatalog(role: UmRole, index: number): UmRole {
  return {
    ...role,
    catalog_details: role.catalog_details.filter((_, i) => i !== index),
  };
}

export function addUser(role: UmRole, user: UmRoleUser): UmRole {
  return {
    ...role,
    assigned_users: role.assigned_users.concat(user),
  };
}

export function addUsers(role: UmRole, users: UmRoleUser[]): UmRole {
  return {
    ...role,
    assigned_users: role.assigned_users.concat(users),
  };
}

export function removeUser(role: UmRole, index: number): UmRole {
  return {
    ...role,
    assigned_users: role.assigned_users.filter((_, i) => i !== index),
  };
}

const belongsTo = (category: ContentCategory, item: ContentRec) =>
  TYPE_IDS_BY_CATEGORY[category].indexOf(item.type_id) > -1;

const getItemCategoy = (item: ContentRec): ContentCategory | null => {
  if (belongsTo('master', item)) return 'master';
  if (belongsTo('project', item)) return 'project';
  if (belongsTo('beats', item)) return 'beats';
  return null;
};

export function getContentGroups(catalog: UmRoleCatalog) {
  return catalog.permissions.content.reduce(
    (acc, item) => {
      const category = getItemCategoy(item);
      if (category) {
        acc[category].push(item);

        return acc;
      }

      return acc;
    },
    { master: [], project: [], beats: [] } as Record<ContentCategory, ContentRec[]>
  );
}

export const roleName = (role: UmRole) => role.name;

export const nameCmp = (a: UmRole, b: UmRole) => roleName(a).localeCompare(roleName(b));

export const joinCatalogs = (role: UmRole) =>
  role.catalog_details.map((catalog) => catalog.name).join(', ');

export const nameMatcher = (search: string) => (role: UmRole) => {
  return roleName(role).toLowerCase().includes(search.toLowerCase());
};

export const applyFilters = (records: UmRole[], filters: { search: string | null }) => {
  const namePredicate = nameMatcher(filters.search || '');

  return records.filter(namePredicate);
};

export const userIds = (role?: UmRole | null) => {
  const users = role?.assigned_users || [];

  return users.map((user) => user.id);
};

export const toUmRolePrivilegesInput = (ps: PrivilegeRec[]) => {
  const map = ps.reduce((map, item) => {
    map[item.slug] = item.allowed;
    return map;
  }, {} as UmRolePrivilegesInput);

  const reversed = [...PrivilegesOrdered].reverse();

  const maxP = reversed.find((p) => map[p]);
  const result = {} as UmRolePrivilegesInput;

  // Only one privielege key is allowed.
  // Empty result means { VIEW: true }, which is wrong
  // to fix that, if maxP is null, we assigning VIEW = false.
  if (maxP) {
    result[maxP] = true;
  } else {
    result['VIEW'] = false;
  }

  return result;
};

export const toUmRoleInput = (role: UmRole): UmRoleInput => {
  return {
    name: role.name,
    assigned_users: userIds(role),
    catalogs: role.catalog_details.map(({ catalog_id, permissions }) => {
      return {
        catalog_id,
        content: permissions.content.reduce((map, item) => {
          map[item.slug] = item.allowed;
          return map;
        }, {} as UmRoleContentInput),
        privileges: toUmRolePrivilegesInput(permissions.privileges),
      };
    }),
  };
};

export const userName = (user: UmRoleUser) => {
  const { first_name, last_name } = user;
  if (first_name && last_name) {
    return `${user.first_name} ${user.last_name}`;
  }

  return first_name;
};

export const toListItem = (user: UmRoleUser): UserListItem => ({
  id: user.id,
  name: userName(user),
  picture: user.meta?.photo_url,
});

export const copy = (role: UmRole) => ({
  ...role,
  name: `${role.name} (Copy)`,
});
