import { applyMiddleware } from 'redux';
import share from 'redux/menu/middleware/clickShare';
import edit from 'redux/menu/middleware/edit';
import info from 'redux/menu/middleware/info';
import { downloadAsset } from 'redux/menu/middleware/download';
import { addToCollection } from 'redux/menu/middleware/addToCollection';
import { remove } from 'redux/menu/middleware/remove';
import { masterLocking } from 'redux/menu/middleware/masterLocking';
import deleteAsset from 'redux/menu/middleware/deleteAsset';
import deleteFolder from 'redux/menu/middleware/deleteFolder';

export default applyMiddleware(
  addToCollection,
  deleteAsset,
  deleteFolder,
  downloadAsset,
  edit,
  info,
  share,
  remove,
  masterLocking
);
