import { Identifiable } from 'common/interfaces/identifiable';

export const getId = (o: Identifiable) => o.id;

export const getIds = (coll: Identifiable[]) => coll.map(getId);

export const toIdMap = <T extends Identifiable<string | number>>(coll: T[]) => {
  return coll.reduce<{ [key: string]: T }>((map, item) => {
    map[item.id] = item;
    return map;
  }, {});
};
