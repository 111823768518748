import { Middleware } from 'redux';
import { RootState } from 'redux/root-reducer';
import { Category, ContentType } from 'common/api/models';
import { ISharedDataItem } from 'modules/sharing/api/models';
import { AssetFieldsFragment } from 'generated/graphql';
import AmpService from 'common/api/AmpService';
import { ModalConfigActionTypes, modalOpen } from 'redux/modal/modalActions';
import * as menu from 'redux/menu/menuActions';
import isClickAction from 'redux/menu/utils/isClickAction';
import MenuOption from 'redux/menu/enums/menuOption';

const deleteAsset: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (isClickAction(action)) {
    const {
      context,
      option,
      activePlayerAssetId,
      closePlayer,
      onDeleted,
    } = action.payload;

    if (option === MenuOption.DELETE) {
      if (context.category === Category.AssetType && context.kind === 'DRIVE') {
        const [asset] = context.data;
        const assetId = asset?.id;
        const assetTypeId =
          asset?.__typename === 'Contract'
            ? ContentType.MASTER_CONTRACT
            : (asset as ISharedDataItem | AssetFieldsFragment).type_id;

        const onApplyChange = async () => {
          if (typeof assetId === 'number' && typeof assetTypeId === 'number') {
            try {
              const success = await AmpService.deleteDriveItem(
                assetTypeId,
                assetId,
                asset
              );
              if (!success) return false;

              if (assetId === activePlayerAssetId && closePlayer) closePlayer();

              if (onDeleted) onDeleted();
              return true;
            } catch {
              return false;
            }
          }

          return false;
        };

        const openConfirmDialog = () =>
          store.dispatch(
            modalOpen({
              type: ModalConfigActionTypes.DELETE,
              data: {
                id: 0,
                contentType: 0,
                itemName: `"${context.data[0]?.title}"`,
                onApplyChange,
              },
            })
          );

        setTimeout(openConfirmDialog, 0);

        return next(menu.close());
      }
    }
  }

  return next(action);
};

export default deleteAsset;
