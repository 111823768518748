import { notify } from 'common/components/notifMessages/Notify';
import apolloClient from 'apollo/client';
import {
  UpdateMasterDocument,
  UpdateMasterMutationResult,
  UpdateMasterMutationVariables,
  MasterFieldsFragment,
} from 'generated/graphql';
import { getResponseMessage } from 'common/api/utils';
import sleep from 'common/utils/sleep';

export async function lockMaster(master: MasterFieldsFragment) {
  const snackKey = notify.pending('Locking master...');

  try {
    await apolloClient.mutate<UpdateMasterMutationResult, UpdateMasterMutationVariables>({
      mutation: UpdateMasterDocument,
      variables: {
        id: master.id,
        data: {
          is_locked: true,
        },
      },
    });

    notify.success('Locking success');
  } catch (error) {
    const message = getResponseMessage(error);
    notify.error(`Error while locking: ${message}`);
  }
  await sleep(350);
  notify.closeSnackbar(snackKey);
}
