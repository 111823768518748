import * as React from 'react';

import { Menu, MenuProps } from '@material-ui/core';

const fixedProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  getContentAnchorEl: null,
} as const;

export interface DotsMenuProps extends MenuProps {
  width?: number;
}

const DotsMenu: React.FC<DotsMenuProps> = (props) => {
  const { width = 172, ...other } = props;

  return (
    <Menu
      PaperProps={{
        style: {
          width,
        },
      }}
      {...other}
      {...fixedProps}
    />
  );
};

export default DotsMenu;
