import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useGetSubscribedSubscriptionsQuery } from 'generated/graphql';
import { getUmRoles } from 'apollo/queryFns';
import log from 'modules/app/utils/log';
import loadUser from 'modules/app/utils/loadUser';
import useGetPermissions from 'modules/app/hooks/useGetPermissions';
import { setSubscribedSubscriptionState } from 'redux/subscribedSubscription/subscribedSubscriptionActions';

const useUserBootstrap = () => {
  const dispatch = useDispatch();
  const { data: subscriptions } = useGetSubscribedSubscriptionsQuery();

  const userBootstrap = () => {
    // Getting UserData asap and sending them to store;
    loadUser(dispatch).then();
    // Getting roles to check permissions.
    getUmRoles({ fetchPolicy: 'network-only' }).catch(log.error);
  };

  // Loadin user data (called on init render only);
  useState(userBootstrap);

  // Loading permissions
  useGetPermissions();

  useEffect(() => {
    if (!subscriptions?.subscribedSubscriptions) return;
    dispatch(
      setSubscribedSubscriptionState({
        value: {
          isLoading: false,
          subscription: subscriptions.subscribedSubscriptions[0],
        },
      })
    );
  }, [dispatch, subscriptions]);
};

export default useUserBootstrap;
