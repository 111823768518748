import React, { FC, ReactElement, useCallback, useState } from 'react';
import { DialogActions, DialogContent } from '@material-ui/core';

import Button, { ButtonPropsExtended } from 'uikit/button/Button';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import Typography from 'uikit/typography/Typography';
import Preloader from 'uikit/preloader/Preloader';

export interface ConfirmDialogProps {
  title: string;
  body: React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  submitLabel?: React.ReactNode;
  isLoadingOnConfirm?: boolean;
  submitColor?: ButtonPropsExtended['color'];
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  body,
  onConfirm,
  isLoadingOnConfirm = false,
  submitLabel = 'Submit',
  submitColor = 'error',
  onClose,
}): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const confirmHandler = useCallback(() => {
    if (isLoadingOnConfirm) setLoading(true);
    onConfirm();
  }, [isLoadingOnConfirm, onConfirm]);
  return (
    <>
      <DialogHeader onClose={onClose} title={title} />
      <DialogContent>
        {loading ? <Preloader /> : <Typography variant={'body2'}>{body}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button
          variant={'outlined'}
          onClick={onClose}
          dialogActionBase
          disabled={loading}
          dialogAction
        >
          Cancel
        </Button>
        <Button
          variant={'outlined'}
          color={submitColor}
          onClick={confirmHandler}
          dialogActionBase
          disabled={loading}
          dialogAction
        >
          {submitLabel}
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmDialog;
