import { logoutAction } from 'redux/login/loginActions';
import loadAccessToken from 'common/services/tokenServices/loadAccessToken';
import { Routes } from 'navigation/enums/routes';
import { FC, ReactElement } from 'react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';

const AuthContainer: FC = ({ children }): ReactElement => {
  const loginState = useSelector((state: RootState) => state.loginState);
  const dispatch = useDispatch();

  if (!loadAccessToken()) {
    dispatch(logoutAction());
    return <Redirect to={Routes.Login} />;
  }

  if (!loginState.loggedIn) {
    return <Redirect to={Routes.Login} />;
  }

  return <>{children}</>;
};

export default AuthContainer;
