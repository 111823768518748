import INIT_REFETCH_STATE from 'redux/refetch/configs/initRefetchState';
import RefetchState from 'redux/refetch/interfaces/refetchState';
import { RefetchActions, RefetchActionTypes } from 'redux/refetch/refetchActions';

const refetchReducer = (
  state: RefetchState = INIT_REFETCH_STATE,
  action: RefetchActions
): RefetchState => {
  const actionTypes = RefetchActionTypes;

  switch (action.type) {
    case actionTypes.SetBeatsFalse:
      return { ...state, beats: false };
    case actionTypes.SetBeatsTrue:
      return { ...state, beats: true };
    default:
      return state;
  }
};

export default refetchReducer;
