import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
// Unfortunately `composeWithDevTools` has really pure TS support
// To mke it work I forced to move this part to JS file...
const composeEnhancers = composeWithDevTools({
  serialize: {
    replacer: (key, value) => (value instanceof HTMLElement ? value.toString() : value),
  },
});

export default composeEnhancers;
