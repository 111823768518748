import { AccountSubscriptionFragment } from 'generated/graphql';
import actionFn from 'common/utils/actionFn';
import SubscribedSubscriptionState from 'redux/subscribedSubscription/interfaces/subscribedSubscriptionState';

export enum SubscribedSubscriptionActionTypes {
  SetSubscribedSubscriptionIsLoading = 'SetSubscribedSubscriptionIsLoading',
  SetSubscribedSubscription = 'SetSubscribedSubscription',
  SetSubscribedSubscriptionState = 'SetSubscribedSubscriptionState',
}

export type SetSubscribedSubscriptionIsLoadingAction = {
  type: SubscribedSubscriptionActionTypes.SetSubscribedSubscriptionIsLoading;
  payload: {
    value: boolean;
  };
};

export const setSubscribedSubscriptionIsLoading = actionFn<
  SetSubscribedSubscriptionIsLoadingAction
>(SubscribedSubscriptionActionTypes.SetSubscribedSubscriptionIsLoading);

export type SetSubscribedSubscriptionAction = {
  type: SubscribedSubscriptionActionTypes.SetSubscribedSubscription;
  payload: {
    value: AccountSubscriptionFragment;
  };
};

export const setSubscribedSubscription = actionFn<SetSubscribedSubscriptionAction>(
  SubscribedSubscriptionActionTypes.SetSubscribedSubscription
);

export type SetSubscribedSubscriptionStateAction = {
  type: SubscribedSubscriptionActionTypes.SetSubscribedSubscriptionState;
  payload: {
    value: SubscribedSubscriptionState;
  };
};

export const setSubscribedSubscriptionState = actionFn<
  SetSubscribedSubscriptionStateAction
>(SubscribedSubscriptionActionTypes.SetSubscribedSubscriptionState);

export type SubscribedSubscriptionAction =
  | SetSubscribedSubscriptionIsLoadingAction
  | SetSubscribedSubscriptionStateAction
  | SetSubscribedSubscriptionAction;
