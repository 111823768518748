import { ContentRec, PrivilegeRec } from 'generated/graphql';
import initPrivilegesConfig from 'redux/permissions/utils/initPrivilegesConfig';
import { DriveUtils } from 'common/api/utils';
import initContentPermissions from 'redux/permissions/utils/initContentPermissions';
import ContentPermissions from 'common/interfaces/contentPermissions';
import PrivilegesConfig from 'common/types/privilegesConfig';

const createContentPermissions = (args: {
  privileges: PrivilegeRec[];
  content: ContentRec[];
}) => {
  const { privileges, content } = args;
  const privilegesConfig = privileges.reduce<PrivilegesConfig>((acc, item) => {
    acc[item.slug] = item.allowed;
    return acc;
  }, initPrivilegesConfig());

  return content.reduce<ContentPermissions>((acc, item) => {
    if (item.allowed) {
      acc[DriveUtils.getTypeId(item)] = privilegesConfig;
    }

    return acc;
  }, initContentPermissions());
};

export default createContentPermissions;
