import { makeStyles } from '@material-ui/core/styles';

const useContainerProgressStyles = makeStyles(
  () => ({
    line1: {
      height: 48,
    },
    line2: {
      height: 69,
    },
    line3: {
      height: 41,
    },
    line4: {
      height: 21,
    },
    line5: {
      height: 33,
    },
  }),
  { name: 'ContainerProgressStyles' }
);

export default useContainerProgressStyles;
