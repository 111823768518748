import { Master } from 'common/models/Master';
import { CollectionType } from 'common/models/Collection';
import { SomePartial } from 'common/utilityTypes';
import AddToCollectionDialogValues from 'common/components/Modals/addToCollectionDialog/interfaces/addToCollectionDialogValues';

const addToCollInitValues = (
  master: Master,
  collectionType: CollectionType
): SomePartial<AddToCollectionDialogValues, 'collectionId'> => {
  return {
    catalogId: master.catalog_id,
    masterId: master.id,
    collectionType,
  };
};

export default addToCollInitValues;
