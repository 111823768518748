import React, { FC, lazy, ReactElement, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import NotFoundPage from 'uikit/notFoundPage/NotFoundPage';
import { Routes } from 'navigation/enums/routes';
import UMRouter from 'navigation/routes/UMRouter/UMRouter';
import Preloader from 'uikit/preloader/Preloader';
import LogoutPage from 'modules/auth';
import useCatalogRoutesPermissions from 'common/hooks/useCatalogRoutesPermissions';
import MastersRouter from 'modules/masters/navigation/MastersRouter';

const LazyArtistSettingsPage = lazy(() =>
  import('modules/profile/pages/ArtistSettingsPage')
);
const LazyBeatsPage = lazy(() => import('modules/beats/pages/BeatsPage'));
const LazyProjectsPage = lazy(() => import('modules/projects/pages/ProjectsPage'));
const LazyAdvancedSearchPage = lazy(() =>
  import('modules/advancedSearch/pages/AdvancedSearchPage')
);
const LazyDropsPage = lazy(() => import('modules/drops/pages/DropsPage'));
const LazySplitSheet = lazy(() => import('modules/splitSheet'));
const LazySharing = lazy(() => import('modules/sharing/Sharing'));
const LazyLegal = lazy(() => import('modules/legal/pages/LegalPage'));
const LazyApproveSplitSheetPage = lazy(() =>
  import('modules/masters/pages/ApproveSplitSheetPage')
);
// const LazyFeedPage = lazy(() => import('modules/feed/pages/FeedPage'));
const LazyInviteRouter = lazy(() =>
  import('navigation/routes/inviteRouter/InviteRouter')
);
const LazyReleasesPage = lazy(() => import('modules/releases/pages/ReleasesPage'));
const LazySettings = lazy(() => import('modules/settings/pages/SettingsPage'));
const LazyInviteDecline = lazy(() => import('modules/auth/pages/DeclineInvitePage'));

const UserRouter: FC = (): ReactElement => {
  useCatalogRoutesPermissions();
  return (
    <Switch>
      <Route path={Routes.ArtistSettings}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyArtistSettingsPage />
        </Suspense>
      </Route>
      <Route path={Routes.Legal}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyLegal />
        </Suspense>
      </Route>
      <Route path={Routes.Settings} exact>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazySettings />
        </Suspense>
      </Route>
      {/* <Route path={[Routes.Feed]}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyFeedPage />
        </Suspense>
      </Route> */}
      <Route path={[Routes.Releases]}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyReleasesPage />
        </Suspense>
      </Route>
      <Route path={Routes.Management}>
        <UMRouter />
      </Route>
      <Route path={[Routes.Masters]}>
        <MastersRouter />
      </Route>
      <Route path={[Routes.Beats]}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyBeatsPage />
        </Suspense>
      </Route>
      <Route path={[Routes.Projects]}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyProjectsPage />
        </Suspense>
      </Route>
      <Route path={Routes.AdvanceSearch}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyAdvancedSearchPage />
        </Suspense>
      </Route>
      <Route path={Routes.Drops}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyDropsPage />
        </Suspense>
      </Route>
      <Route path={[Routes.SplitSheetItem, Routes.SplitSheet]}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazySplitSheet />
        </Suspense>
      </Route>
      <Route path={[Routes.Shared, Routes.SharedInvite]}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazySharing />
        </Suspense>
      </Route>
      <Route path={Routes.ContractSignLink}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyApproveSplitSheetPage />
        </Suspense>
      </Route>
      <Route path={Routes.Logout}>
        <LogoutPage />
      </Route>
      <Route path={Routes.InviteConfirmationPage}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyInviteRouter />
        </Suspense>
      </Route>
      <Route path={Routes.InviteDecline}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyInviteDecline />
        </Suspense>
      </Route>
      <Route
        exact
        path={[Routes.Home, Routes.Login, Routes.ResetPassword, Routes.NewPassword]}
      >
        <Redirect to={Routes.Masters} />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default UserRouter;
