const GENERATION_CONFIG = {
  BY_THEME: {
    maxInputLength: 100,
    placeholder: 'Provide a theme for your song',
  },
  REWRITE: {
    maxInputLength: 150,
    placeholder: 'Provide lyrics to rewrite',
  },
};

export default GENERATION_CONFIG;
