import { EditAlbumDialogContentProps } from 'common/components/Modals/editAlbumDialog/components/EditAlbumDialogContent';
import {
  InitArgs as ModalMasterUploadData,
  CustomizeMaster,
} from 'common/components/Modals/ModalMasterUpload/reducer';
import { ContentType } from 'common/api/models';
import { ShareDialogProps } from 'common/components/Modals/shareDialog/SahreDialog';
import { ModalSplitSheetProps } from 'common/components/Modals/ModalSplitSheet';
import { ModalUploadedContractEditProps } from 'common/components/Modals/contractAgreementFormDialog/ContractAgreementFormDialog';
import { UploadAssetDialogContentProps } from 'common/components/Modals/UploadAssetDialog/components/UploadAssetDialogContent';
import { ConfirmDialogProps } from 'uikit/confirmDialog/ConfirmDialog';
import { CatalogListDialogConfig } from 'modules/roles/components/сatalogListDialog/CatalogListDialog';
import { UserListDialogConfig } from 'modules/roles/components/userListDialog/UserListDialog';
import {
  CreateAlbumConfig,
  CreateAndAddConfig,
} from 'common/components/Modals/createAlbumDialog/CreateAlbumDialog';
import { AddToCollectionDialogContainerProps } from 'common/components/Modals/addToCollectionDialog/AddToCollectionDialog';
import { UserRolesDialogConfig } from 'modules/users/components/userRolesDialog/UserRolesDialog';
import { ModalConfig as UploadedContractEditDialogConifg } from 'modules/masters/components/uploadedContractEditDialog/UploadedContractEditDialog';
import IContractAgreementInfoProps from 'common/components/Modals/ContractAgreementInfoDialog/interfaces/IContractAgreementInfoProps';
import BeatsParams from 'redux/fileUpload/interfaces/beatsParams';
import MasterParams from 'redux/fileUpload/interfaces/masterParams';
import ProjectParams from 'redux/fileUpload/interfaces/projectParams';
import InviteArtistModalConfig from 'modules/users/interfaces/inviteArtistModalConfig';
import UserInviteModalConfig from 'modules/users/interfaces/userInviteModalConfig';
import { InitArgs as SubmitToDropDialogInitValues } from 'common/components/Modals/submitToDropDialog/reducer';
import { Drop } from 'generated/graphql';
import { InitArgs as CreateProjectData } from 'common/components/Modals/createProjectDialog/reducer';
import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';

export enum ModalConfigActionTypes {
  DELETE,
  SHARE,
  PREVIEW,
  SPLIT_SHEET,
  UPLOAD_CONTRACT_INFO,
  UPLOAD_CONTRACT_EDIT,
  UPLOAD_CONTRACT_CREATE,
  UPLOAD_ASSET,
  CREATE_CONTRACT,
  MASTER_UPLOAD,
  CREATE_ALBUM,
  ADD_TO_COLLECTION,
  EDIT_ALBUM,
  CONFIRM,
  CATALOG_LIST,
  USER_LIST,
  CREATE_COLL_AND_ADD_MASTER,
  EDIT_USER_ROLES,
  USER_INVITE,
  INVITE_ARTIST,
  MASTERS_INFO,
  MASTER_CUSTOMIZE,
  SUBMIT_TO_DROP,
  DELETE_ACCOUNT,
  ADD_NEW_RELEASE,
  SEND_PROMPT_TO_OPENAI,
  CREATE_PROJECT,
}

export type MastersInfoDialogConfigAction = {
  type: ModalConfigActionTypes.MASTERS_INFO;
};

export type AddToCollectionConfigAction<T> = {
  type: T;
  data: {
    props: Omit<AddToCollectionDialogContainerProps, 'onClose'>;
  };
};

export type ModalDeleteConfigAction = {
  type: ModalConfigActionTypes.DELETE;
  data: {
    id: number;
    contentType: ContentType;
    itemName: string;
    onApplyChange?: () => Promise<any>;
  };
};

export type ModalShareConfigAction = {
  type: ModalConfigActionTypes.SHARE;
  data: Omit<ShareDialogProps, 'onClose'>;
};

export type ModalPreviewConfigAction = {
  type: ModalConfigActionTypes.PREVIEW;
  data: { url: string };
};

export type ModalSplitSheetConfigAction = {
  type: ModalConfigActionTypes.SPLIT_SHEET;
  data: ModalSplitSheetProps['summary'];
};

export type ModalUploadedContractInfoConfigAction = {
  type: ModalConfigActionTypes.UPLOAD_CONTRACT_INFO;
  data: IContractAgreementInfoProps['summary'];
};

export type ModalUploadedContractCreateConfigAction = {
  type: ModalConfigActionTypes.UPLOAD_CONTRACT_CREATE;
  data: {
    isCreatingSummary: true;
    initialSummary: ModalUploadedContractEditProps['summary'];
  };
};

export type ModalUploadAssetConfigAction = {
  type: ModalConfigActionTypes.UPLOAD_ASSET;
  data: {
    props: Pick<UploadAssetDialogContentProps, 'accept' | 'multiple'>;
    params: BeatsParams | MasterParams | ProjectParams;
    isSharing?: boolean;
    onApplyChange?: () => Promise<any>;
  };
};

export type ModalCreateContractConfigAction = {
  type: ModalConfigActionTypes.CREATE_CONTRACT;
  data: {
    masterId: number;
    catalogId: number;
    disableSplitsheet: boolean;
  };
};

export type ModalMasterUploadConfigAction = {
  type: ModalConfigActionTypes.MASTER_UPLOAD;
  data: ModalMasterUploadData;
  onCloseAdditionalAction?: () => void;
};

export type ModalMasterCustomize = {
  type: ModalConfigActionTypes.MASTER_CUSTOMIZE;
  data: CustomizeMaster;
};

export type EditAlbumDialogConfigAction = {
  type: ModalConfigActionTypes.EDIT_ALBUM;
  data: {
    values: EditAlbumDialogContentProps['initialValues'];
  };
};

export type ConfirmDialogConfigAction = {
  type: ModalConfigActionTypes.CONFIRM;
  data: {
    props: {
      title?: string;
      body: string;
      submitLabel?: string;
      submitColor?: ConfirmDialogProps['submitColor'];
      onConfirm: () => void;
    };
  };
};

export type ModalSubmitToDropConfigAction = {
  type: ModalConfigActionTypes.SUBMIT_TO_DROP;
  initValues: SubmitToDropDialogInitValues;
  data: Drop[];
};

export type DeleteAccountModalConfigAction = {
  type: ModalConfigActionTypes.DELETE_ACCOUNT;
};

export type SendPromptToOpenAIDialogConfigAction = {
  type: ModalConfigActionTypes.SEND_PROMPT_TO_OPENAI;
  editor: BaseEditor & ReactEditor;
};

export type CreateProjectDialogConfigAction = {
  type: ModalConfigActionTypes.CREATE_PROJECT;
  data: CreateProjectData;
  onCreate: (projectId: string) => void;
};

export type ModalConfigActions =
  | ModalDeleteConfigAction
  | ModalShareConfigAction
  | ModalPreviewConfigAction
  | ModalSplitSheetConfigAction
  | ModalUploadedContractInfoConfigAction
  | MastersInfoDialogConfigAction
  | ModalUploadedContractCreateConfigAction
  | UploadedContractEditDialogConifg<ModalConfigActionTypes.UPLOAD_CONTRACT_EDIT>
  | ModalUploadAssetConfigAction
  | ModalCreateContractConfigAction
  | ModalMasterUploadConfigAction
  | ModalMasterCustomize
  | ModalSubmitToDropConfigAction
  | DeleteAccountModalConfigAction
  | SendPromptToOpenAIDialogConfigAction
  | CreateProjectDialogConfigAction
  | CreateAlbumConfig<ModalConfigActionTypes.CREATE_ALBUM>
  | CreateAndAddConfig<ModalConfigActionTypes.CREATE_COLL_AND_ADD_MASTER>
  | AddToCollectionConfigAction<ModalConfigActionTypes.ADD_TO_COLLECTION>
  | EditAlbumDialogConfigAction
  | ConfirmDialogConfigAction
  | CatalogListDialogConfig<ModalConfigActionTypes.CATALOG_LIST>
  | UserListDialogConfig<ModalConfigActionTypes.USER_LIST>
  | UserRolesDialogConfig<ModalConfigActionTypes.EDIT_USER_ROLES>
  | UserInviteModalConfig<ModalConfigActionTypes.USER_INVITE>
  | InviteArtistModalConfig<ModalConfigActionTypes.INVITE_ARTIST>;

export enum ModalActionTypes {
  OPEN = '@MODAL/OPEN',
  CLOSE = '@MODAL/CLOSE',
  CLOSED = '@MODAL/CLOSED',
  CUSTOMIZE = '@MODAL/CUSTOMIZE',
}

export interface ModalCustomizeAction {
  type: ModalActionTypes.CUSTOMIZE;
  payload: ModalConfigActions;
}

export interface ModalOpenAction {
  type: ModalActionTypes.OPEN;
  payload: ModalConfigActions;
}

export const modalOpen = (payload: ModalConfigActions): ModalOpenAction => ({
  type: ModalActionTypes.OPEN,
  payload,
});

export interface ModalCloseAction {
  type: ModalActionTypes.CLOSE;
}

export const modalClose = (): ModalCloseAction => ({ type: ModalActionTypes.CLOSE });

export interface ModalClosedAction {
  type: ModalActionTypes.CLOSED;
}

export const modalClosed = (): ModalClosedAction => ({ type: ModalActionTypes.CLOSED });

export type ModalActions =
  | ModalOpenAction
  | ModalCloseAction
  | ModalClosedAction
  | ModalCustomizeAction;
