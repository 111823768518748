import * as React from 'react';
import InviteDialog, {
  InviteDialogProps,
} from 'modules/users/components/inviteDialog/InviteDialog';
import { CreateGuestUserMutationOptions } from 'generated/graphql';
import noop from 'common/utils/noop';

export interface IInviteGuestDialogProps
  extends Omit<
    InviteDialogProps,
    'initialValues' | 'title' | 'description' | 'onSubmit'
  > {
  mutationOptions?: CreateGuestUserMutationOptions;
}

export type ModalConfig<T> = {
  type: T;
  data: {};
};

export const InviteGuestDialog: React.FC<IInviteGuestDialogProps> = (props) => {
  const { mutationOptions, ...other } = props;
  const onSubmit = noop;
  const initialValues = {
    firstName: '',
    lastName: '',
    inviteType: 1,
    email: '',
  };

  return (
    <InviteDialog
      {...other}
      onSubmit={onSubmit}
      initialValues={initialValues}
      title="Add Guest"
      description="Enter details below to create a guest account."
    />
  );
};

export default InviteGuestDialog;
