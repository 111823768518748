import { Breakpoints } from '@material-ui/core/styles/createBreakpoints';
import createMixins, { Mixins } from '@material-ui/core/styles/createMixins';
import { Palette } from '@material-ui/core/styles/createPalette';
import { Spacing } from '@material-ui/core/styles/createSpacing';
import breakpoints from 'theme/core/breakpoints';
import palette from 'theme/core/palette';
import spacing from 'theme/core/spacing';

type Params = {
  palette: Palette;
  breakpoints: Breakpoints;
  spacing: Spacing;
};

function customMixins({ palette, breakpoints, spacing }: Params): Mixins {
  const headerHeight = {
    xs: 64,
    md: 80,
  };

  const playerHeight = {
    xs: 80,
  };

  const mixins: Omit<Mixins, 'gutters' | 'toolbar'> = {
    activeLineVertical: {
      content: '""',
      display: 'block',
      width: 8,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      borderRadius: 4,
      borderLeft: `4px solid ${palette.primary.main}`,
    },
    dividerBottom: {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      right: 0,
      height: 1,
      width: '100%',
      bottom: 0,
      backgroundColor: palette.divider,
    },
    headerHeight: {
      height: headerHeight.xs,
      [breakpoints.up('md')]: {
        height: headerHeight.md,
      },
    },
    contentHeight: {
      height: `calc(100vh - ${headerHeight.xs}px)`,
      [breakpoints.up('md')]: {
        height: `calc(100vh - ${headerHeight.md}px)`,
      },
    },
    containerMinHeight: {
      minHeight: `calc(100vh - ${headerHeight.xs}px - ${playerHeight.xs}px)`,
      [breakpoints.up('md')]: {
        minHeight: `calc(100vh - ${headerHeight.md}px - ${playerHeight.xs}px)`,
      },
    },
    drawerPaperTop: {
      top: headerHeight.xs,
      [breakpoints.up('md')]: {
        top: headerHeight.md,
      },
    },
    playerHeight: {
      height: playerHeight.xs,
    },
    userInfoMinHeight: {
      minHeight: headerHeight.xs,
      [breakpoints.up('md')]: {
        minHeight: headerHeight.md,
      },
    },
    dialog: {
      actions: {
        justifyContent: 'space-between',
        padding: spacing(1, 3, 3),
        [breakpoints.up('md')]: {
          justifyContent: 'flex-end',
          margin: 0,
          padding: spacing(4, 5, 5, 5),
        },
      },
      actionsButton: {
        [breakpoints.down('sm')]: {
          flexBasis: '50%',
          height: 48,
          minWidth: 'auto',
        },
      },
      masterInfoContent: {
        paddingBottom: playerHeight.xs,
      },
    },
  };

  return createMixins(breakpoints, spacing, mixins);
}

export default customMixins({ palette, breakpoints, spacing });
