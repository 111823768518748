import ContractsPaths from 'api/contracts/enums/contractsPaths';
import GET from 'common/services/httpServices/get';
import { IRestSplitSheet } from 'modules/splitSheet/contracts/interfaces/IRestSplitSheet';

const getSplitSheet: (splitSheetID: string) => Promise<IRestSplitSheet> = async (
  splitSheetID
) => {
  const res = await GET(`${ContractsPaths.GetContract}${splitSheetID}/`);
  return await res.json();
};

export default getSplitSheet;
