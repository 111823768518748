import React, { FC, lazy, ReactElement, Suspense } from 'react';
import { Route, Switch } from 'react-router';

import { Routes } from 'navigation/enums/routes';
import Preloader from 'uikit/preloader/Preloader';

const LazyFeedPage = lazy(() => import('modules/feed/pages/FeedPage'));
const LazyErrorPage = lazy(() => import('modules/errorPage/pages/ErrorPage'));
const LazyDistributionPage = lazy(() =>
  import('modules/masters/components/distribution/pages/DistributionPage')
);
const LazyReleasesPage = lazy(() => import('modules/releases/pages/ReleasesPage'));

const WebViewRouter: FC = (): ReactElement => {
  return (
    <Switch>
      <Route path={Routes.WebViewFeed}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyFeedPage isWebView={true} />
        </Suspense>
      </Route>
      <Route path={Routes.WebViewError}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyErrorPage />
        </Suspense>
      </Route>
      <Route path={Routes.WebViewDistribution}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyDistributionPage />
        </Suspense>
      </Route>
      <Route path={Routes.WebViewReleases}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyReleasesPage isWebView={true} />
        </Suspense>
      </Route>
    </Switch>
  );
};

export default WebViewRouter;
