import apiCatalogsService from 'api/catalogs/apiCatalogsService';
import apiCoreService from 'api/core/apiCoreService';
import apiShareService from 'api/share/apiShareService';
import AmpService from 'common/api/AmpService';
import { AttachObjectType } from 'common/api/models';
import { notify } from 'common/components/notifMessages/Notify';
import { modalClose } from 'redux/modal/modalActions';
import { RootState } from 'redux/root-reducer';
import noop from 'common/utils/noop';
import ShareStorageService from 'modules/sharing/api/ShareStorageService';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalMasterUpload, {
  ModalMasterUploadProps,
} from 'common/components/Modals/ModalMasterUpload/ModalMasterUpload';
import { ModalSubmitHandler } from './reducer';

function useSubmitMasterImport(): ModalSubmitHandler {
  const dispatch = useDispatch();

  return async (values, actions) => {
    const onProgress = (event: ProgressEvent, index: number) => {
      const progress = Math.floor((event.loaded / event.total) * 100);

      actions.setProgress(index, progress);
    };

    actions.setUploading(true);

    try {
      const storageFiles = await Promise.all(
        values.files.map((file, index) => {
          return apiCoreService.addFile(
            {
              asset_type: 'MASTER_ASSET',
              catalog_id: values.catalogId,
              parent_id: values.masterId,
              file: file.file,
            },
            {
              onProgress(event) {
                onProgress(event, index);
              },
            }
          );
        })
      );

      await Promise.all(
        storageFiles.map(({ data }, index) => {
          return apiCatalogsService.createMasterAsset(
            {
              title: values.files[index].file.name,
              catalog: values.catalogId,
              type: values.files[index].typeId,
              file: data.id,
            },
            { catalogId: values.catalogId, masterId: values.masterId }
          );
        })
      );

      await AmpService.queryMaster({
        catalogId: values.catalogId,
        masterId: values.masterId,
      });
    } catch (error) {
      notify.enqueueSnackbar(`Error: ${error}`, { variant: 'error' });
    }

    dispatch(modalClose());
  };
}

function useSubmitMasterImportShared(): ModalSubmitHandler {
  const dispatch = useDispatch();
  const { refreshFn, slug } = useSelector((state: RootState) => state.share);

  if (!refreshFn || !slug) return noop;

  return async (values, actions) => {
    const onProgress = (event: ProgressEvent, index: number) => {
      const progress = Math.floor((event.loaded / event.total) * 100);

      actions.setProgress(index, progress);
    };

    actions.setUploading(true);

    try {
      await Promise.all(
        values.files.map(async (file, index) => {
          const { id } = await apiShareService.createAsset(slug, {
            title: values.files[index].file.name,
            type: values.files[index].typeId,
            master: values.masterId,
          });
          return ShareStorageService.addFile(
            slug,
            {
              file: file.file,
              asset_type: 'MASTER_ASSET',
              parent_id: values.masterId,
              attach: true,
              attach_object_id: id,
              attach_object_type: AttachObjectType.ASSET,
            },
            {
              onProgress(event) {
                onProgress(event, index);
              },
            }
          );
        })
      );

      await refreshFn();
    } catch (error) {
      notify.enqueueSnackbar(`Error: ${error}`, { variant: 'error' });
    }

    dispatch(modalClose());
  };
}

const ImportMasterFilesDialog = (props: Omit<ModalMasterUploadProps, 'onSubmit'>) => {
  const onSubmit = useSubmitMasterImport();
  const onSubmitShared = useSubmitMasterImportShared();
  let onSubmitHandler;
  if (props.config.isSharing) onSubmitHandler = onSubmitShared;
  else onSubmitHandler = onSubmit;

  return <ModalMasterUpload {...props} onSubmit={onSubmitHandler} />;
};

export default ImportMasterFilesDialog;
