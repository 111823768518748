/**
 * Perform a backend-initiated redirect to a callback URN
 * Reuired by OAuth
 * `token` is required to log the user into OAuth service provider
 */
export function handleCallbackRedirectURN(
  redirectCallbackURN: string,
  token: string | null
) {
  const href = `${process.env.REACT_APP_BACKEND_HOST}${redirectCallbackURN}&token=${token}`;
  window.location.href = href;
}
