import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import DotsMenu from 'uikit/dotsMenu/DotsMenu';
import { userStateSelector } from 'redux/user/userSelectors';
import HeaderMenuButton from 'common/components/headerMenu/components/HeaderMenuButton';
import useHeaderMenu from 'common/components/headerMenu/hooks/useHeaderMenu';
import useHeaderMenuItems from 'common/components/headerMenu/hooks/useHeaderMenuItems';
import HeaderMenuItems from 'common/components/headerMenu/components/HeaderMenuItems';

const HeaderMenu: FC = () => {
  const userState = useSelector(userStateSelector);
  const { menuAnchor, closeMenuHandler, openMenuHandler } = useHeaderMenu();
  const {
    config: menuItems,
    menuItemClickHandler,
    isUserManagement,
  } = useHeaderMenuItems();

  return (
    <>
      <HeaderMenuButton userState={userState} onClick={openMenuHandler} />
      <DotsMenu
        anchorEl={menuAnchor}
        onClick={closeMenuHandler}
        open={Boolean(menuAnchor)}
        width={240}
      >
        <HeaderMenuItems
          isArtist={Boolean(userState.data?.isArtist)}
          menuItems={menuItems}
          onClick={menuItemClickHandler}
          isUserManagement={isUserManagement}
        />
      </DotsMenu>
    </>
  );
};

export default HeaderMenu;
