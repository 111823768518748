import { useDispatch } from 'react-redux';
import useCreateCollService from 'common/components/Modals/createAlbumDialog/hooks/useCreateCollService';
import useAddToCollService from 'common/hooks/useAddToCollService/useAddToCollService';
import { ValidatedValues as CreateFormValues } from 'common/components/Modals/createAlbumDialog/CreateAlbumDialog';
import { CollectionType } from 'common/models/Collection';
import { setFilter } from 'redux/filters/filtersActions';
import MastersSectionContent from 'redux/filters/enums/mastersSectionContent';
import Filter from 'redux/filters/enums/filter';

const useCreateAndAddSubmit = (masterId: number, onClose: () => void) => {
  const dispatch = useDispatch();
  const createColl = useCreateCollService();
  const addToColl = useAddToCollService();

  return async (values: CreateFormValues) => {
    const collTab =
      values.collectionType === CollectionType.Ep
        ? MastersSectionContent.EP
        : MastersSectionContent.ALBUM;
    const coll = await createColl(values);

    if (coll) {
      onClose();
      dispatch(
        setFilter({
          filter: Filter.mastersSectionContent,
          value: collTab,
        })
      );
      await addToColl({
        masterId,
        collectionId: coll.id,
        collectionType: values.collectionType,
        catalogId: values.catalogId,
      });
    }
  };
};

export default useCreateAndAddSubmit;
