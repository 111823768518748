import { deepmerge } from '@material-ui/utils';

const reset = {
  '@global': {
    a: {
      textDecoration: 'none',
      color: 'inherit',
      cursor: 'pointer',
    },
    button: {
      backgroundColor: 'transparent',
      color: 'inherit',
      borderWidth: '0',
      padding: '0',
      cursor: 'pointer',
    },
    figure: {
      margin: '0',
    },
    'input::-moz-focus-inner': {
      border: '0',
      padding: '0',
      margin: '0',
    },
    'ul, ol, dd': {
      margin: '0',
      padding: '0',
      listStyle: 'none',
    },
    'h1, h2, h3, h4, h5, h6': {
      margin: '0',
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
    p: {
      margin: '0',
    },
    cite: {
      fontStyle: 'normal',
    },
    fieldset: {
      borderWidth: '0',
      padding: '0',
      margin: '0',
    },
  },
};

const normalize = {
  '@global': {
    html: {
      webkitTextSizeAdjust: '100%',
    },
    body: {
      margin: '0',
    },
    main: {
      display: 'block',
    },
    h1: {
      fontSize: '2em',
      margin: '0.67em 0',
    },
    hr: {
      boxSizing: 'content-box',
      height: '0',
      overflow: 'visible',
    },
    pre: {
      fontFamily: 'monospace, monospace',
      fontSize: '1em',
    },
    a: {
      backgroundColor: 'transparent',
    },
    'abbr[title]': {
      borderBottom: 'none',
      textDecoration: 'underline dotted',
    },
    'b, strong': {
      fontWeight: 'bolder',
    },
    'code, kbd, samp': {
      fontFamily: 'monospace, monospace',
      fontSize: '1em',
    },
    small: {
      fontSize: '80%',
    },
    'sub, sup': {
      fontSize: '75%',
      lineHeight: '0',
      position: 'relative',
      verticalAlign: 'baseline',
    },
    sub: {
      bottom: '-0.25em',
    },
    sup: {
      top: '-0.5em',
    },
    img: {
      borderStyle: 'none',
    },
    'button, input, optgroup, select, textarea': {
      fontFamily: 'inherit',
      fontSize: '100%',
      lineHeight: '1.15',
      margin: '0',
    },
    'button, input': {
      overflow: 'visible',
    },

    'button, select': {
      textTransform: 'none',
    },
    "button, [type='button'], [type='reset'], [type='submit']": {
      webkitAppearance: 'button',
    },
    "button::-moz-focus-inner, [type='button']::-moz-focus-inner, [type='reset']::-moz-focus-inner, [type='submit']::-moz-focus-inner": {
      borderStyle: 'none',
      padding: '0',
    },
    "button:-moz-focusring, [type='button']:-moz-focusring, [type='reset']:-moz-focusring, [type='submit']:-moz-focusring": {
      outline: '1px dotted ButtonText',
    },
    fieldset: {
      padding: '0.35em 0.75em 0.625em',
    },
    legend: {
      boxSizing: 'border-box',
      color: 'inherit',
      display: 'table',
      maxWidth: '100%',
      padding: '0',
      whiteSpace: 'normal',
    },
    progress: {
      verticalAlign: 'baseline',
    },
    textarea: {
      overflow: 'auto',
    },
    "[type='checkbox'], [type='radio']": {
      boxSizing: 'border-box',
      padding: '0',
    },
    "[type='number']::-webkit-inner-spin-button, [type='number']::-webkit-outer-spin-button": {
      height: 'auto',
    },
    "[type='search']": {
      webkitAppearance: 'textfield',
      outlineOffset: -2,
    },
    "[type='search']::-webkit-search-decoration": {
      webkitAppearance: 'none',
    },
    '::-webkit-file-upload-button': {
      webkitAppearance: 'button',
      font: 'inherit',
    },
    details: {
      display: 'block',
    },
    summary: {
      display: 'list-item',
    },
    template: {
      display: 'none',
    },
    '[hidden]': {
      display: 'none',
    },
  },
};

const base = {
  '@global': {
    html: {
      fontSize: '62.5%',
    },
    '.rc-slider': {
      pointerEvents: 'none',
    },
    '.rc-slider .rc-slider-handle': {
      pointerEvents: 'auto',
    },
  },
};

export default deepmerge(base, deepmerge(normalize, reset));
