import noop from 'common/utils/noop';
import { FC, useEffect } from 'react';
import * as React from 'react';
import { useFilePreview, InitState } from 'common/hooks/useFilePreview';
import ImagePicker from 'uikit/imagePicker/ImagePicker';
import Artwork from 'uikit/artwork/Artwork';
import DNDArea from 'uikit/dragAndDropArea/DragAndDropArea';
import { AcceptTypeString } from 'config/acceptTypes';
import useImageUpload from 'common/hooks/useImageUpload';
import Typography from 'uikit/typography/Typography';
import { makeStyles } from '@material-ui/core';
import { StorageLink } from 'common/api/models';
import useHasPlaceToStore from 'common/hooks/useHasPlaceToStore/useHasPlaceToStore';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[50],
    },
    previewBox: {
      display: 'flex',
      justifyContent: 'center',
    },
    error: {
      paddingTop: theme.spacing(2),
    },
  }),
  { name: 'ImageUpload' }
);

export type ImageUploadProps = {
  onReset?: () => void;
  onFileChange?: (file?: File) => void;
  onCompleted?: (result: StorageLink) => void;
  fileSizeLimit?: number;
  onError?: (error: unknown) => void;
  initPrevewState: InitState;
  onLoadingChange?: (loading: boolean) => void;
  stopLoading?: boolean;
};

const ImageUpload: FC<ImageUploadProps> = ({
  initPrevewState,
  onCompleted,
  onError,
  onReset,
  onFileChange,
  fileSizeLimit,
  onLoadingChange = noop,
  stopLoading,
}) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const classes = useStyles();
  const [{ files, previews }, setFiles, resetFiles] = useFilePreview(initPrevewState);
  const file = stopLoading ? undefined : files[0];
  const { loading, error, progress } = useImageUpload(file, onCompleted, onError);
  const { hasPlaceToStore } = useHasPlaceToStore();

  useEffect(() => {
    onLoadingChange(loading);
  }, [loading, onLoadingChange]);

  const handleFilesChange = React.useCallback(
    (files: FileList) => {
      const filesArray = Array.from(files);
      setFiles(filesArray);

      if (!hasPlaceToStore(filesArray)) {
        resetFiles();
        return;
      }

      if (onFileChange) onFileChange(filesArray[0]);
    },
    [setFiles, hasPlaceToStore, onFileChange, resetFiles]
  );

  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.currentTarget.files;
      if (files?.length) {
        handleFilesChange(files);
      }
      event.currentTarget.value = '';
    },
    [handleFilesChange]
  );

  const handleUpload = React.useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const handleReset = React.useCallback(() => {
    resetFiles();
    if (onReset) onReset();
  }, [onReset, resetFiles]);
  return (
    <div className={classes.root}>
      <input
        onChange={handleInputChange}
        type="file"
        ref={inputRef}
        hidden
        accept={AcceptTypeString.IMAGE}
      />
      {!!previews.length && (
        <div className={classes.previewBox}>
          <ImagePicker
            onUpload={handleUpload}
            onDelete={handleReset}
            loading={loading}
            progress={progress}
          >
            <Artwork outlined withIcon size="giant" src={previews[0]} />
          </ImagePicker>
        </div>
      )}
      {!previews.length && (
        <DNDArea
          areaHeight={192}
          onChange={handleFilesChange}
          fileSizeLimit={fileSizeLimit}
          inputProps={{ accept: AcceptTypeString.IMAGE }}
        />
      )}
      {!!error && (
        <Typography
          className={classes.error}
          color="error"
          align="center"
        >{`${error}`}</Typography>
      )}
    </div>
  );
};

export const PureImageUpload = React.memo(ImageUpload);
