import { ResourceSlug } from '../enums/ResourceSlug';
import { AccountResourcesQuery, ResourceFieldsFragment } from 'generated/graphql';

const matchResourceSlug = (slug: ResourceSlug) => (resource: ResourceFieldsFragment) =>
  resource.slug === slug;

const isResourceEnabled = (
  resources: ResourceFieldsFragment[],
  slug: ResourceSlug
): boolean => {
  const resource = resources.find(matchResourceSlug(slug));

  return resource ? !!resource?.access : false;
};

const isResourceSecurityEnabled = (query?: AccountResourcesQuery): boolean => {
  const resources = query?.resources || [];

  return isResourceEnabled(resources, ResourceSlug.EnhancedSecurity);
};

export default isResourceSecurityEnabled;
