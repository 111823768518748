import { Middleware } from 'redux';
import { Category, ContentType } from 'common/api/models';
import { RootState } from 'redux/root-reducer';
import * as Menu from 'redux/menu/menuActions';
import removeMasterFromCollectionService from 'redux/menu/utils/removeMasterFromCollectionService';
import isClickAction from 'redux/menu/utils/isClickAction';
import MenuContext from 'redux/menu/types/menuContext';
import AlbumItemContext from 'redux/menu/types/albumItemContext';
import MenuOption from 'redux/menu/enums/menuOption';

const removeAlbumItem = (context: AlbumItemContext) => {
  if (context.typeId === ContentType.MASTER_FOLDER) {
    removeMasterFromCollectionService({
      collectionId: context.collectionId,
      masterId: context.data.id,
    }).then();
  }
};

const removeItem = (context: MenuContext) => {
  if (context.category === Category.AlbumItem) {
    return removeAlbumItem(context);
  }
};

export const remove: Middleware<{}, RootState> = () => (next) => (action) => {
  if (isClickAction(action)) {
    const { context, option } = action.payload;

    if (option === MenuOption.REMOVE) {
      removeItem(context);

      return next(Menu.close());
    }
  }

  next(action);
};
