import React, { FC } from 'react';
import { DialogActions } from '@material-ui/core';

import Button from 'uikit/button/Button';
import noop from 'common/utils/noop';

interface ShareDialogButtonsProps {
  onCancel?: () => void;
  onShare?: () => void;
}

const ShareDialogButtons: FC<ShareDialogButtonsProps> = ({
  onCancel = noop,
  onShare = noop,
}) => {
  return (
    <DialogActions>
      <Button variant={'outlined'} onClick={onCancel} dialogAction>
        Cancel
      </Button>

      <Button variant={'contained'} color={'primary'} onClick={onShare} dialogAction>
        Share
      </Button>
    </DialogActions>
  );
};

export default ShareDialogButtons;
