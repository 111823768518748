import { EditAlbumDialogContentProps } from 'common/components/Modals/editAlbumDialog/components/EditAlbumDialogContent';
import albumMastersById from 'redux/menu/utils/albumMastersById';
import trackIdsOrdered from 'redux/menu/utils/trackIdsOrdered';
import DriveCollectionContext from 'redux/menu/interfaces/driveCollectionContext';

const getEditAlbumValues = ({
  catalogId,
  data,
}: DriveCollectionContext): EditAlbumDialogContentProps['initialValues'] => ({
  catalogId: catalogId,
  collectionType: data.collectionType,
  albumId: data.id,
  title: data.title,
  artist: data.artist_name,
  year: data.year,
  masters: albumMastersById(data),
  trackOrder: trackIdsOrdered(data),
  artworkUrl: data.cover_art?.media_url || null,
  artworkFileNamePrev: data.cover_art?.file_name || null,
  artworkFileName: data.cover_art?.file_name || null,
  artworkFileSize: 0,
});

export default getEditAlbumValues;
