import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import useBlurOnEnter from 'uikit/appearingField/hooks/useBlurOnEnter';
import TextFieldPropsExtended from 'common/types/textFieldPropsExtended';
import TextField from 'uikit/textField/TextField';

const useStyles = makeStyles(
  (theme) => {
    const duration = theme.transitions.duration.shortest;

    return {
      root: {},
      inputRoot: {
        borderColor: 'transparent',
        transition: theme.transitions.create('border', { duration }),
        '&:not(.Mui-error).Mui-focused': {
          borderColor: theme.palette.secondary.main,
        },
        '&:not(.Mui-focused):not(.Mui-error):hover': {
          borderColor: theme.palette.grey[200],
        },
        '& .MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
        '.MuiAutocomplete-root &[class*="MuiOutlinedInput-root"]': {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          '& .MuiInputBase-input': {
            padding: '5px 0 5px 7px',
          },
        },
      },
    };
  },
  { name: 'AppearingField' }
);

type AppearingFieldProps = TextFieldPropsExtended & {
  blurOnEnter?: boolean;
};

const AppearingField: FC<AppearingFieldProps> = (props) => {
  const {
    className,
    InputProps,
    inputProps,
    select,
    blurOnEnter = false,
    ...other
  } = props;
  const [localInputRef, onKeyDown] = useBlurOnEnter({
    ref: inputProps?.ref,
  });

  const classes = useStyles();

  const InputPropsNext = React.useMemo(() => {
    const _props: TextFieldPropsExtended['InputProps'] = {
      ...InputProps,
      classes: {
        ...InputProps?.classes,
        root: clsx(InputProps?.classes?.root, classes.inputRoot),
      },
    };

    if (blurOnEnter) {
      _props.onKeyDown = select ? undefined : onKeyDown;
    }

    return _props;
  }, [classes, InputProps, onKeyDown, select, blurOnEnter]);

  const inputPropsNext = React.useMemo(() => {
    if (blurOnEnter) {
      return {
        ...inputProps,
        ref: inputProps?.ref || localInputRef,
      };
    }

    return inputProps;
  }, [inputProps, localInputRef, blurOnEnter]);

  return (
    <TextField
      select={select}
      className={classes.root}
      inputProps={inputPropsNext}
      InputProps={InputPropsNext}
      {...other}
    />
  );
};

export default AppearingField;
