import { makeStyles } from '@material-ui/core/styles';

const useContainerAnimationStyles = makeStyles(
  () => {
    const duration = 1;
    return {
      line1: {
        animation: `$line1 ${duration}s linear 500ms infinite`,
      },
      line2: {
        animation: `$line1 ${duration}s linear 250ms infinite`,
      },
      line3: {
        animation: `$line1 ${duration}s linear infinite`,
      },
      line4: {
        animation: `$line1 ${duration}s linear 250ms infinite`,
      },
      line5: {
        animation: `$line1 ${duration}s linear 500ms infinite`,
      },
      '@keyframes line1': {
        '0%': { height: 30 },
        '10%': { height: 100 },
        '100%': { height: 30 },
      },
    };
  },
  { name: 'ContainerAnimationStyles' }
);

export default useContainerAnimationStyles;
