import { useCallback, createElement, useRef, DragEvent, useMemo } from 'react';
import { notify } from 'common/components/notifMessages/Notify';
import isFileSizeValid from 'uikit/dragAndDropArea/utils/isFileSizeValid';

interface FilesChangeHandler {
  (files: File[]): void;
}

interface Options {
  onChange?: FilesChangeHandler;
  accept?: string;
  multiple?: boolean;
  disabled?: boolean;
  fileSizeLimit?: number;
}

function checkTypes(files: File[], fileTypes: string) {
  if (!fileTypes) return true;
  const types = fileTypes.split(',');

  return files.every((file) => {
    const fileName = file.name;
    const fileExt = fileName.substr(fileName.lastIndexOf('.'));
    return types.includes(file.type) || types.includes(fileExt);
  });
}

function validateFiles(
  files: File[] | FileList | null,
  options?: { maxSize?: number; fileTypes?: string }
): File[] {
  const { maxSize = Infinity, fileTypes = '' } = options || {};
  const filesArray = files ? Array.from(files) : [];

  if (filesArray.length > 0) {
    const isFileSizeCorrect = isFileSizeValid(maxSize, filesArray);
    const isValidTypes = checkTypes(filesArray, fileTypes);

    if (!isFileSizeCorrect) {
      notify.enqueueSnackbar('Invalid File Size', { variant: 'warning' });
      return [];
    }

    if (!isValidTypes) {
      notify.enqueueSnackbar('Invalid File Type', { variant: 'warning' });
      return [];
    }

    return filesArray;
  }

  return [];
}

export default function useFilePicker(options?: Options) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    onChange: onChangeProp,
    disabled = false,
    fileSizeLimit = Infinity,
    accept,
    multiple = false,
  } = options || {};

  const onChange = useCallback(
    (fileList: FileList | null) => {
      const files = validateFiles(fileList, {
        maxSize: fileSizeLimit,
        fileTypes: accept,
      });

      if (files.length > 0 && !disabled && typeof onChangeProp === 'function') {
        onChangeProp(files);
      }
    },
    [disabled, fileSizeLimit, accept, onChangeProp]
  );

  const handleClick = useCallback(() => {
    if (!disabled) {
      inputRef?.current?.click();
    }
  }, [disabled]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.currentTarget.files);
      event.currentTarget.value = '';
    },
    [onChange]
  );

  const handleDrop = useCallback(
    (event: DragEvent<HTMLElement>) => {
      onChange(event.dataTransfer.files);
    },
    [onChange]
  );

  const inputElt = useMemo(
    () =>
      createElement('input', {
        accept,
        multiple,
        type: 'file',
        hidden: true,
        ref: inputRef,
        onChange: handleInputChange,
      }),
    [accept, multiple, handleInputChange]
  );

  return {
    inputElt,
    onClick: handleClick,
    onDrop: handleDrop,
  };
}
