import DefaultSelected from 'redux/selectedFile/types/defaultSelected';
import {
  SelectedFileTypes,
  ViewFileActions,
} from 'redux/selectedFile/selectedFileActions';

const defaultSelectedReducer = (
  state: DefaultSelected = null,
  action: ViewFileActions
): DefaultSelected => {
  switch (action.type) {
    case SelectedFileTypes.SET_DEFAULT_SELECTED:
      return action.payload;
    case SelectedFileTypes.DRIVE_ASSETS:
      return null;
    case SelectedFileTypes.SHARED_ASSET:
      return null;
    default:
      return state;
  }
};

export default defaultSelectedReducer;
