import { mapValues } from 'lodash';

import mergePrivileges from 'redux/permissions/utils/mergePrivileges';
import ContentPermissions from 'common/interfaces/contentPermissions';

const mergePermissions = <T extends ContentPermissions>(alpha: T, beta: T) => {
  return mapValues(alpha, (_, typeId) => {
    return mergePrivileges(alpha[typeId], beta[typeId]);
  });
};

export default mergePermissions;
