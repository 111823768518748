import { MouseEventHandler, useState } from 'react';

interface UseHeaderMenuRes {
  menuAnchor: HTMLElement | null;
  openMenuHandler: MouseEventHandler<HTMLElement>;
  closeMenuHandler: MouseEventHandler<HTMLElement>;
}

const useHeaderMenu: () => UseHeaderMenuRes = () => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const openMenuHandler: MouseEventHandler<HTMLElement> = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenuHandler = (): void => {
    setMenuAnchor(null);
  };

  return { closeMenuHandler, menuAnchor, openMenuHandler };
};

export default useHeaderMenu;
