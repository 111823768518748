import actionFn from 'common/utils/actionFn';
import { Privileges, UmRole, UmRoleUser } from 'generated/graphql';
import { ContentType } from 'common/api/models';
import { ContentCategory } from 'common/models/UmRole';
import { CatalogName } from 'common/models/Catalog';

export enum RoleEditorActionTypes {
  SELECT_CATALOG = '@ROLE/SELECT_CATALOG',
  SET_NAME = '@ROLE/SET_NAME',
  SET_PERMISSION = '@ROLE/SET_PERMISSION',
  SET_CONTENT = '@ROLE/SET_CONTENT',
  SET_CONTENT_CATEGORY = '@ROLE/SET_CONTENT_CATEGORY',
  ADD_CATALOG = '@ROLE/ADD_CATALOG',
  ADD_CATALOGS = '@ROLE/ADD_CATALOGS',
  REMOVE_CATALOG = '@ROLE/REMOVE_CATALOG',
  ADD_USER = '@ROLE/ADD_USER',
  ADD_USERS = '@ROLE/ADD_USERS',
  REMOVE_USER = '@ROLE/REMOVE_USER',
  EDIT = '@ROLE/EDIT',
  NEW = '@ROLE/NEW',
  SUBMIT = '@ROLE/SUBMIT',
  RESET = '@ROLE/RESET',
  SET_SUBMITTING = '@ROLE/SET_SUBMITTING',
  SET_DISABLED = '@ROLE/SET_DISABLED',
  SET_SUCCESS = '@ROLE/SET_SUCCESS',
  SET_ERROR = '@ROLE/SET_ERROR',
}

export type SetDisabledAction = {
  type: RoleEditorActionTypes.SET_DISABLED;
  payload: {
    value: boolean;
  };
};

export const setDisabled = actionFn<SetDisabledAction>(
  RoleEditorActionTypes.SET_DISABLED
);

export type SetSubmittingAction = {
  type: RoleEditorActionTypes.SET_SUBMITTING;
  payload: {
    value: boolean;
  };
};

export const setSubmitting = actionFn<SetSubmittingAction>(
  RoleEditorActionTypes.SET_SUBMITTING
);

export type SetSuccessAction = {
  type: RoleEditorActionTypes.SET_SUCCESS;
};

export const setSuccess = (): SetSuccessAction => ({
  type: RoleEditorActionTypes.SET_SUCCESS,
});

export type SetErrorAction = {
  type: RoleEditorActionTypes.SET_ERROR;
};

export const setError = (): SetErrorAction => ({ type: RoleEditorActionTypes.SET_ERROR });

export type SetRoleNameAction = {
  type: RoleEditorActionTypes.SET_NAME;
  payload: {
    name: string;
  };
};

export const setRoleName = actionFn<SetRoleNameAction>(RoleEditorActionTypes.SET_NAME);

export type RoleResetAction = {
  type: RoleEditorActionTypes.RESET;
};

export const resetRole = (): RoleResetAction => ({ type: RoleEditorActionTypes.RESET });

export type RoleSubmitAction = {
  type: RoleEditorActionTypes.SUBMIT;
};

export const roleSubmit = (): RoleSubmitAction => ({
  type: RoleEditorActionTypes.SUBMIT,
});

export type SetContentCategoryAction = {
  type: RoleEditorActionTypes.SET_CONTENT_CATEGORY;
  payload: {
    catalogIndex: number;
    category: ContentCategory;
    value: boolean;
  };
};

export const setContentCategory = actionFn<SetContentCategoryAction>(
  RoleEditorActionTypes.SET_CONTENT_CATEGORY
);

export type AddRoleUserAction = {
  type: RoleEditorActionTypes.ADD_USER;
  payload: {
    user: UmRoleUser;
  };
};

export const addRoleUser = actionFn<AddRoleUserAction>(RoleEditorActionTypes.ADD_USER);

export type AddRoleUsersAction = {
  type: RoleEditorActionTypes.ADD_USERS;
  payload: {
    users: UmRoleUser[];
  };
};

export const addRoleUsers = actionFn<AddRoleUsersAction>(RoleEditorActionTypes.ADD_USERS);

export type RemoveRoleUserAction = {
  type: RoleEditorActionTypes.REMOVE_USER;
  payload: {
    userIndex: number;
  };
};

export const removeRoleUser = actionFn<RemoveRoleUserAction>(
  RoleEditorActionTypes.REMOVE_USER
);

export type AddRoleCatalogAction = {
  type: RoleEditorActionTypes.ADD_CATALOG;
  payload: {
    id: number;
    name: string;
  };
};

export const addRoleCatalog = actionFn<AddRoleCatalogAction>(
  RoleEditorActionTypes.ADD_CATALOG
);

export type AddRoleCatalogsAction = {
  type: RoleEditorActionTypes.ADD_CATALOGS;
  payload: {
    catalogs: CatalogName[];
  };
};

export const addRoleCatalogs = actionFn<AddRoleCatalogsAction>(
  RoleEditorActionTypes.ADD_CATALOGS
);

export type RemoveRoleCatalogAction = {
  type: RoleEditorActionTypes.REMOVE_CATALOG;
  payload: {
    catalogIndex: number;
  };
};

export const removeRoleCatalog = actionFn<RemoveRoleCatalogAction>(
  RoleEditorActionTypes.REMOVE_CATALOG
);

export type SelectCatalogAction = {
  type: RoleEditorActionTypes.SELECT_CATALOG;
  payload: { catalogIndex: number };
};

export const selectCatalog = actionFn<SelectCatalogAction>(
  RoleEditorActionTypes.SELECT_CATALOG
);

export type SetPermissionAction = {
  type: RoleEditorActionTypes.SET_PERMISSION;
  payload: {
    type: Privileges;
    catalogIndex: number;
    value: boolean;
  };
};

export const setPermissions = actionFn<SetPermissionAction>(
  RoleEditorActionTypes.SET_PERMISSION
);

export type SetContentAction = {
  type: RoleEditorActionTypes.SET_CONTENT;
  payload: {
    typeIds: ContentType[];
    catalogIndex: number;
    value: boolean;
  };
};

export const setContent = actionFn<SetContentAction>(RoleEditorActionTypes.SET_CONTENT);

export type EditRoleActon = {
  type: RoleEditorActionTypes.EDIT;
  payload: {
    role: UmRole;
    copy?: boolean;
  };
};

export const editRole = actionFn<EditRoleActon>(RoleEditorActionTypes.EDIT);

export type CreateRoleAction = {
  type: RoleEditorActionTypes.NEW;
};

export const createRole = (): CreateRoleAction => ({ type: RoleEditorActionTypes.NEW });

export type RoleEditorActions =
  | RoleResetAction
  | RoleSubmitAction
  | SetContentCategoryAction
  | AddRoleUserAction
  | AddRoleUsersAction
  | RemoveRoleUserAction
  | AddRoleCatalogAction
  | AddRoleCatalogsAction
  | RemoveRoleCatalogAction
  | SelectCatalogAction
  | SetPermissionAction
  | SetContentAction
  | EditRoleActon
  | CreateRoleAction
  | SetRoleNameAction
  | SetSubmittingAction
  | SetSuccessAction
  | SetErrorAction
  | SetDisabledAction;
