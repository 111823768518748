import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import UserEffects from 'modules/app/components/UserEffects';
import GuestRouter from 'navigation/routes/guestRoter/GuestRouter';
import UserRouter from 'navigation/routes/userRouter/UserRouter';
import Layout from 'modules/app/components/Layout';
import PrivateEffects from 'modules/app/effects/PrivateEffects';
import AuthContainer from 'modules/app/components/AuthContainer';
import ErrorPage from 'modules/errorPage/pages/ErrorPage';
import Preloader from 'uikit/preloader/Preloader';
import { subscribedSubscriptionStateSelector } from 'redux/subscribedSubscription/subscribedSubscriptionSelectors';
import { RootState } from 'redux/root-reducer';
import NoAccess from 'modules/noAccess/pages/NoAccess';
import useCheckStorageLimit from 'modules/app/hooks/useCheckStorageLimit';

const PrivateContent: FC = (): ReactElement => {
  const { loading, data } = useSelector((state: RootState) => state.user);
  const subscriptionState = useSelector(subscribedSubscriptionStateSelector);
  useCheckStorageLimit();

  const renderContent = (): ReactElement => {
    if (loading || subscriptionState.isLoading) {
      return <Preloader fillViewport />;
    }
    if (data?.isGuest) return <GuestRouter />;

    if (data) {
      return <UserRouter />;
    }

    return <ErrorPage />;
  };

  const isLoading = loading || subscriptionState.isLoading;
  const isFreeArtist = subscriptionState.subscription?.name === 'FREE_ARTIST';

  return (
    <AuthContainer>
      <Layout isLoading={isLoading} isSideBarVisible={!isFreeArtist}>
        {isFreeArtist ? (
          <NoAccess />
        ) : (
          <>
            <UserEffects />
            <PrivateEffects />
            {renderContent()}
          </>
        )}
      </Layout>
    </AuthContainer>
  );
};

export default PrivateContent;
