import { useStopwatch } from './useStopwatch';
import { useEffect } from 'react';

export function useRemainingUploadTime(params: {
  total: number;
  loaded: number;
  started?: number;
}) {
  const { total, loaded, started } = params;
  const { passed: passedTime, setPause } = useStopwatch(started);
  const remainingBytes = total - loaded;
  const isLoaded = remainingBytes <= 0;
  const passedSeconds = passedTime / 1000;
  const uploadSpeed = passedSeconds > 0 ? Math.max(loaded / passedSeconds, 0) : 0;
  const remainingSeconds =
    uploadSpeed > 0 ? Math.max(remainingBytes / uploadSpeed, 0) : Infinity;

  useEffect(() => {
    if (isLoaded) setPause(true);
  }, [isLoaded, setPause]);

  return remainingSeconds * 1000;
}
