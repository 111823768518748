import * as React from 'react';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

import { DeleteIconOld, UploadIcon } from 'common/icons/system';
import IconProgress from 'uikit/iconProgress/IconProgress';
import { IconButton } from 'uikit/iconButton/IconButton';

const zIndex = {
  root: 100,
  preview: 200,
  foreground: 300,
  shadow: 400,
  content: 500,
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      position: 'relative',
      zIndex: zIndex.root,
    },
    preview: {
      position: 'relative',
      zIndex: zIndex.preview,
      pointerEvents: 'none',
    },
    foreground: {
      borderRadius: 'inherit',
      zIndex: zIndex.foreground,
      cursor: 'pointer',
      position: 'absolute',
      display: 'flex',
      flexFlow: 'row nowrap',
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      transition: theme.transitions.create(['opacity']),
      opacity: 0,
      '&:before': {
        content: '""',
        display: 'block',
        zIndex: zIndex.shadow,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
      },
      '&:hover, $loading &': {
        opacity: 1,
      },
    },
    icon: {
      '& + &': {
        marginLeft: theme.spacing(2),
      },
    },
    content: {
      position: 'relative',
      zIndex: zIndex.content,
    },
    progress: {
      color: theme.palette.getContrastText(theme.palette.grey[800]),
    },
    iconBox: {
      display: 'flex',
      opacity: 0,
      transition: theme.transitions.create(['opacity']),
      '$loading $foreground &': {
        opacity: 0,
      },
      '$foreground:hover &': {
        opacity: 1,
      },
    },
    loading: {},
  });

export type ImagePickerProps = {
  className?: string;
  loading?: boolean;
  progress?: number;
  onDelete?: () => void;
  onUpload?: () => void;
};

const ImagePicker: React.FC<ImagePickerProps & WithStyles<typeof styles>> = (props) => {
  const { children, classes, className, onDelete, onUpload, loading, progress } = props;
  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.loading]: loading,
        },
        className
      )}
    >
      <div className={classes.preview}>{children}</div>
      <div className={classes.foreground}>
        <div className={classes.content}>
          {loading && <IconProgress className={classes.progress} progress={progress} />}
          {!loading && (
            <div className={classes.iconBox}>
              <IconButton
                outlined
                filled
                size="small"
                onClick={onDelete}
                className={classes.icon}
              >
                <DeleteIconOld />
              </IconButton>
              <IconButton
                outlined
                filled
                size="small"
                onClick={onUpload}
                className={classes.icon}
              >
                <UploadIcon />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles, { name: 'ImagePicker' })(ImagePicker);
