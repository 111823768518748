import React from 'react';
import { DialogContent, IconButton } from '@material-ui/core';
import Button from 'uikit/button/Button';
import PageType from 'common/components/Modals/openAILyricsHelperDialog/enums/pageType';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from 'uikit/typography/Typography';
import { CloseIcon, IconGenerateLyrics, IconRewriteLyrics } from 'common/icons/system';

const useStyles = makeStyles(
  (theme) => ({
    contentRoot: {
      [theme.breakpoints.down('md')]: {
        // height: '100%',
      },
    },
    root: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: '2.5rem',

      [theme.breakpoints.down('md')]: {
        height: '100%',
      },
    },
    infoTextContainer: {
      width: '100%',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    infoIcon: {
      fontSize: '9rem',
    },
    helperTextContainer: {
      borderWidth: '2px',
      border: 'solid',
      borderRadius: '5px',
      margin: `0 0 8px 0`,
    },
    generateLyricsHelperTextContainer: {
      borderColor: theme.palette.primary.light,
    },
    rewriteLyricsHelperTextContainer: {
      borderColor: theme.palette.secondary.light,
    },
    descriptionText: {
      color: theme.palette.text.hint,

      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5rem',
      },
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
    },
    icon: {
      fontSize: '6rem',
    },
    button: {
      width: '100%',
      height: '50px',
      fontSize: '26px',

      [theme.breakpoints.down('xs')]: {
        // width: '300px',
        fontSize: '20px',
      },
    },
    gridContainer: {
      justifyContent: 'center',
    },
    gridItem: {
      padding: '20px',
      width: '80%',

      [theme.breakpoints.down('xs')]: {
        padding: '0 0 20px 0',
      },
    },
    closeButton: {
      display: 'flex',
      flexDirection: 'row-reverse',
      width: '100%',
      paddingBottom: '0.5rem',
    },
    paddings: {
      padding: '10px',
    },
    responsibleInfoText: {
      paddingBottom: '2.2rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.2rem',
      },
    },
    responsibleTitle: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.6rem',
      },
    },
    getStartedText: {
      width: '100%',
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5rem',
      },
    },
  }),
  {
    name: 'OpenAIStartPage',
  }
);

interface StartPageProps {
  setCurrentPage: (page: PageType) => void;
  onClose: () => void;
}

const StartPage: React.FC<StartPageProps> = ({ setCurrentPage, onClose }) => {
  const classes = useStyles();
  return (
    <DialogContent>
      <div className={classes.root}>
        <div className={classes.closeButton}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Grid container className={classes.gridContainer}>
          <Grid item className={classes.infoTextContainer}>
            <Typography variant={'h4'} className={classes.responsibleInfoText}>
              Tully’s Lyric AI can help songwriters with new ideas, rewrite existing
              lyrics, and even clear writer’s block.
            </Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            <div>
              <Grid
                container
                className={`${classes.helperTextContainer} ${classes.generateLyricsHelperTextContainer}`}
              >
                <Grid item xs={8} className={classes.paddings}>
                  <div>
                    <Typography
                      color={'primary'}
                      variant={'h3'}
                      className={classes.responsibleTitle}
                    >
                      BRAINSTORM
                    </Typography>
                    <Typography className={classes.descriptionText}>
                      Provide a word or description and Tully will generate lyrics
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} className={classes.iconContainer}>
                  <IconGenerateLyrics color={'primary'} className={classes.icon} />
                </Grid>
              </Grid>
              <Button
                className={classes.button}
                variant={'contained'}
                color={'primary'}
                onClick={() => setCurrentPage(PageType.GENERATE_LYRICS_BY_THEME)}
              >
                Start with a theme
              </Button>
            </div>
          </Grid>
          <Grid item className={classes.gridItem}>
            <div>
              <Grid
                container
                className={`${classes.helperTextContainer} ${classes.rewriteLyricsHelperTextContainer}`}
              >
                <Grid item xs={8} className={classes.paddings}>
                  <div>
                    <Typography
                      color={'secondary'}
                      variant={'h3'}
                      className={classes.responsibleTitle}
                    >
                      GHOSTWRITER
                    </Typography>
                    <Typography className={classes.descriptionText}>
                      Type or paste your own lyrics and Tully will rewrite your song for
                      you
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} className={classes.iconContainer}>
                  <IconRewriteLyrics color={'secondary'} className={classes.icon} />
                </Grid>
              </Grid>
              <Button
                className={classes.button}
                variant={'contained'}
                color={'secondary'}
                onClick={() => setCurrentPage(PageType.REWRITE_LYRICS)}
              >
                Get help with lyrics
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </DialogContent>
  );
};

export default StartPage;
