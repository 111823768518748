import moment from 'moment';

const passed = (t: number) => {
  const hh = Math.floor(t / 1000 / 60 / 60);

  return hh > 0 ? moment.utc(t).format('hh:mm:ss') : moment.utc(t).format('mm:ss');
};

const formatRemaining = (t: number): string | undefined => {
  if (t <= 0) return;

  return t === Infinity ? 'Calculating...' : `${passed(t)} Remaining`;
};

export default formatRemaining;
