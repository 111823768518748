import { RootState } from 'redux/root-reducer';

export const catalogSelector = (state: RootState) => state.filters.catalog?.value;

export const catalogIdSelector = (state: RootState) => state.filters.catalog?.value?.id;

export const searchQuerySelector = (state: RootState) => state.filters.searchQuery?.value;

export const filtersSelector = (state: RootState) => state.filters;

export const mastersSectionContentSelector = (state: RootState) =>
  state.filters.mastersSectionContent.value;
