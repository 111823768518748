import React, { FC, ReactElement } from 'react';
import noop from 'common/utils/noop';
import { DialogActions, DialogContent, Box, IconButton } from '@material-ui/core';
import LinearProgress from 'uikit/linearProgress/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import Button from 'uikit/button/Button';
import DNDArea from 'uikit/dragAndDropArea/DragAndDropArea';
import { DeleteIconOld } from 'common/icons/system';
import * as fileActions from 'redux/fileUpload/fileUploadActions';
import { fileUploadSelector } from 'redux/fileUpload/fileUploadSelectors';
import { AcceptTypeString, AcceptType } from 'config/acceptTypes';
import Typography from 'uikit/typography/Typography';
import { FileUploadActionTypes } from 'redux/fileUpload/fileUploadActions';

const useStyles = makeStyles(
  (theme) => ({
    fixedContent: {
      flex: '0 0 auto',
    },
    content: {},
    fileBox: {
      maxWidth: '100%',
      paddingBottom: theme.spacing(2),
    },
    item: {
      display: 'flex',
      minHeight: 32,
      marginTop: theme.spacing(2),
      alignItems: 'center',
    },
    itemGutterBottom: {
      // can't use theme here.
      paddingBottom: 4,
    },
    actions: {
      ...theme.mixins.dialog.actions,
    },
    button: {
      ...theme.mixins.dialog.actionsButton,
    },
  }),
  { name: 'UploadAssetDialogContentProps' }
);

export interface UploadAssetDialogContentProps {
  onSubmit: () => void;
  onClose: () => void;
  accept: AcceptType;
  onCancel?: () => void;
  onChange?: (files: File[]) => void;
  onReset?: () => void;
  multiple?: boolean;
}

const UploadAssetDialogContent: FC<UploadAssetDialogContentProps> = ({
  onCancel = noop,
  onChange,
  onClose,
  onSubmit,
  onReset,
  accept,
  multiple = false,
}): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector(fileUploadSelector);
  const isUploadingState =
    state.status === FileUploadActionTypes.UPLOADING ||
    state.status === FileUploadActionTypes.DONE;
  const entries = state.entries || [];
  const isEmpty = entries.length === 0;
  const hideReset = !onReset || isEmpty;
  const disableDnd = !isEmpty || isUploadingState;
  const disableSubmit = isUploadingState || entries.length === 0 || !!state.error;
  const disableReset = isUploadingState;

  const handleFileRemove = (index: number) => () =>
    dispatch(fileActions.removeFile({ index }));

  const handleFilesChange = React.useCallback(
    (fileList: FileList | null) => {
      const files = fileList ? Array.from(fileList) : null;
      if (files && files?.length > 0) {
        if (onChange) onChange(files);
      }
    },
    [onChange]
  );

  return (
    <>
      <DialogHeader onClose={onClose} title="Choose File to Import" />
      {isEmpty && (
        <DialogContent className={classes.content}>
          <DNDArea
            disabled={disableDnd}
            inputProps={{
              accept: AcceptTypeString[accept],
              multiple: multiple,
            }}
            onChange={handleFilesChange}
          />
        </DialogContent>
      )}
      {entries.length > 0 && (
        <DialogContent className={classes.content} dividers>
          <div className={classes.fileBox}>
            {entries.map((entry, index) => (
              <div className="modal-file-upload-progress" key={index}>
                <div
                  className={clsx(classes.item, {
                    [classes.itemGutterBottom]: !isUploadingState,
                  })}
                >
                  <Box flex="1 1 auto" minWidth={0}>
                    <Typography variant="subtitle2" oneline>
                      {entry.file.name}
                    </Typography>
                  </Box>
                  <Box flex="0 0 auto">
                    <Box display="flex" pl={1}>
                      {isUploadingState ? (
                        <Typography variant="body2">{entry.progress}%</Typography>
                      ) : (
                        <IconButton size="small" onClick={handleFileRemove(index)}>
                          <DeleteIconOld />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </div>
                {isUploadingState && (
                  <LinearProgress value={entry.progress} variant="determinate" />
                )}
              </div>
            ))}
          </div>
        </DialogContent>
      )}
      {state.error && (
        <DialogContent className={clsx(classes.fixedContent, classes.content)}>
          <Box py={1}>
            <Typography variant="subtitle2" color="error">
              {state.error?.message}
            </Typography>
          </Box>
        </DialogContent>
      )}
      <DialogActions>
        {hideReset ? (
          <Button
            onClick={onCancel}
            disabled={isUploadingState}
            variant="outlined"
            dialogAction
          >
            Cancel
          </Button>
        ) : (
          <Button
            onClick={onReset}
            disabled={disableReset}
            variant="contained"
            color="secondary"
            dialogAction
          >
            Reset
          </Button>
        )}
        <Button
          onClick={onSubmit}
          disabled={disableSubmit}
          variant="contained"
          color="primary"
          dialogAction
        >
          Import
        </Button>
      </DialogActions>
    </>
  );
};

export default UploadAssetDialogContent;
