import useStorageFreePlace from 'common/hooks/useStorageFreePlace/useStorageFreePlace';
import { useEffect } from 'react';
import { notify } from 'common/components/notifMessages/Notify';
import WARNING_MESSAGES from 'config/warningMessages';

const useCheckStorageLimit: () => void = () => {
  const { freePlacePercentage } = useStorageFreePlace();

  useEffect(() => {
    if (freePlacePercentage < 5) {
      notify.warning(WARNING_MESSAGES.storageLimitsWarning);
    }
  }, [freePlacePercentage]);
};

export default useCheckStorageLimit;
