export const ERROR_MESSAGES = {
  ALREADY_IN_COLL:
    'This Album (EP) already contains this Master - it cannot be added twice.',
  REMOVE_FROM_COLL: 'Failed to remove master',
  NO_AVAILABLE_SEATS:
    'You have reached your limit of available Users, please contact your admin',
  NO_ACCOUNT_DATA: "Can't load account details for current user, please reload the page",
  MASTER_TRACKS_LOAD_FAIL: 'Failed to load master files',
  UPDATE_TITLE: "Can't save title",
  MASTER_ARTWORK_UPDATE: 'Failed to update master artwork.',
  ERR_DELETE_USER: 'Failed to delete user',
  ERR_UPDATE_META_PERSON: 'Failed to update collaborator details.',
  ERR_ADD_META_PERSON: 'Failed to add collaborator',
  META_DL_FAIL: 'Failed - Metadata download error',
  UNKNOWN: 'Something went wrong',
  deleteSavedSearch: 'Something went wrong, please try again later',
  createSavedSearchError: 'Something went wrong, please try again later',
  updateSavedSearchError: 'Something went wrong, please try again later',
  duplicateSavedSearch: 'Looks like saved search with this name already exists.',
  savedSearchEmptyName: 'Name is required. Please fill Search Name field.',
  generic:
    'Oops, looks like something went wrong.\n Please try again, or contact Tully Support.',
  storeLimit:
    'You have reached your storage limit. Upgrade your storage or contact your Administrator.',
} as const;
