import MasterCustomizeTypes from '../emun/masterCustomizeTypes';

const customizeTypesForMaster = {
  [MasterCustomizeTypes.realityAudio]: 'realityAudio',
  [MasterCustomizeTypes.stemFiles]: 'stemFiles',
  [MasterCustomizeTypes.contracts]: 'contracts',
  [MasterCustomizeTypes.metadata]: 'metadata',
  [MasterCustomizeTypes.media]: 'media',
};

export default customizeTypesForMaster;
