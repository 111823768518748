import { Reducer } from 'react';
import generateID from 'common/utils/generateID';
import validateEmail from 'common/utils/validateEmail';

export interface PartyRecord {
  id: string;
  role?: string;
  name: string;
  email: string;
  partyType?: string;
  advance: number;
  royaltyLabel: string;
  royalty: number;
  recoupLabel?: string;
  recoup: number | null;
  publishing: number;
}

export interface IUploadedContractLocalState {
  title: string;
  description: string;
  parties: PartyRecord[];
  file?: {
    id?: number;
    downloadUrl?: string;
    fileName?: string | null;
  };
  masterId: number;
  createdAt?: string;
  fileBlob?: File;
  catalogId?: number;
  fileIsUploaded?: boolean;
  type?: string;
  contractID?: number;
  isEdited?: boolean;
  isPartyEdited?: boolean;
  deletedPartyIds?: number[];
  editedPartyIds?: number[];
}

export const validateContractData = (summary: IUploadedContractLocalState) => {
  let isValid = false;

  const isSummaryValid =
    summary.fileBlob || summary.file?.fileName || summary.description;
  if (!summary.parties.length && isSummaryValid) isValid = true;

  for (const party of summary.parties) {
    let isPartyValid =
      party.role ||
      party.name ||
      !!party.advance ||
      !!party.publishing ||
      !!party.recoup ||
      !!party.royalty;
    isPartyValid = isPartyValid || (!!party.email && validateEmail(party.email));

    if (isPartyValid || isSummaryValid) {
      isValid = true;
      break;
    }
  }

  return isValid && (summary.isEdited || summary.isPartyEdited);
};

interface ISetDescriptionAction {
  type: '@U_CONTRACT/SET_DESCRIPTION';
  payload: { description: string };
}
interface IAddPartyAction {
  type: '@U_CONTRACT/ADD_PARTY';
}
interface IRemovePartyAction {
  type: '@U_CONTRACT/REMOVE_PARTY';
  payload: { id: string };
}
interface IRemoveFileAction {
  type: '@U_CONTRACT/REMOVE_FILE';
}
interface ISetPartyRoleAction {
  type: '@U_CONTRACT/SET_PARTY_ROLE';
  payload: { id: string; role: string };
}
interface ISetPartyNameAction {
  type: '@U_CONTRACT/SET_PARTY_NAME';
  payload: { id: string; name: string };
}
interface ISetPartyEmailAction {
  type: '@U_CONTRACT/SET_PARTY_EMAIL';
  payload: { id: string; email: string };
}
interface ISetPartyAdvanceAction {
  type: '@U_CONTRACT/SET_PARTY_ADVANCE';
  payload: { id: string; advance: number };
}
interface ISetPartyRoyaltyLabelAction {
  type: '@U_CONTRACT/SET_PARTY_ROYALTY_LABEL';
  payload: { id: string; royaltyLabel: string };
}
interface ISetPartyRecoupLabelAction {
  type: '@U_CONTRACT/SET_PARTY_RECOUP_LABEL';
  payload: { id: string; recoupLabel: string };
}
interface ISetPartyRoyaltyAction {
  type: '@U_CONTRACT/SET_PARTY_ROYALTY';
  payload: { id: string; royalty: number };
}
interface ISetPartyRecoupAction {
  type: '@U_CONTRACT/SET_PARTY_RECOUP';
  payload: { id: string; recoup: number };
}
interface ISetPartyPublishingAction {
  type: '@U_CONTRACT/SET_PARTY_PUBLISHING';
  payload: { id: string; publishing: number };
}
interface ISetPushFileAction {
  type: '@U_CONTRACT/SET_PUSH_FILE';
  payload: { file: File };
}
interface ISetContractTypeAction {
  type: '@U_CONTRACT/SET_CONTRACT_TYPE';
  payload: { type: string };
}
interface ISetTitleAction {
  type: '@U_CONTRACT/SET_TITLE';
  payload: { title: string };
}

export type IUContractAction =
  | ISetDescriptionAction
  | IAddPartyAction
  | IRemovePartyAction
  | ISetPartyRoleAction
  | ISetPartyNameAction
  | ISetPartyEmailAction
  | ISetPartyAdvanceAction
  | ISetPartyRoyaltyLabelAction
  | ISetPartyRoyaltyAction
  | ISetPartyRecoupAction
  | ISetPartyPublishingAction
  | ISetPartyRecoupLabelAction
  | ISetPushFileAction
  | IRemoveFileAction
  | ISetContractTypeAction
  | ISetTitleAction;

export const setDescriptionAction = (description: string): ISetDescriptionAction => ({
  type: '@U_CONTRACT/SET_DESCRIPTION',
  payload: { description },
});
export const addPartyAction = (): IAddPartyAction => ({ type: '@U_CONTRACT/ADD_PARTY' });
export const removePartyAction = (id: string): IRemovePartyAction => ({
  type: '@U_CONTRACT/REMOVE_PARTY',
  payload: { id },
});
export const removeFileAction = (): IRemoveFileAction => ({
  type: '@U_CONTRACT/REMOVE_FILE',
});
export const setPartyRoleAction = (id: string, role: string): ISetPartyRoleAction => ({
  type: '@U_CONTRACT/SET_PARTY_ROLE',
  payload: { id, role },
});
export const setPartyNameAction = (id: string, name: string): ISetPartyNameAction => ({
  type: '@U_CONTRACT/SET_PARTY_NAME',
  payload: { id, name },
});
export const setPartyEmailAction = (id: string, email: string): ISetPartyEmailAction => ({
  type: '@U_CONTRACT/SET_PARTY_EMAIL',
  payload: { id, email },
});
export const setPartyAdvanceAction = (
  id: string,
  advance: number
): ISetPartyAdvanceAction => ({
  type: '@U_CONTRACT/SET_PARTY_ADVANCE',
  payload: { id, advance },
});
export const setPartyRoyaltyLabelAction = (
  id: string,
  royaltyLabel: string
): ISetPartyRoyaltyLabelAction => ({
  type: '@U_CONTRACT/SET_PARTY_ROYALTY_LABEL',
  payload: { id, royaltyLabel },
});
export const setPartyRecoupLabelAction = (
  id: string,
  recoupLabel: string
): ISetPartyRecoupLabelAction => ({
  type: '@U_CONTRACT/SET_PARTY_RECOUP_LABEL',
  payload: { id, recoupLabel },
});
export const setPartyRoyaltyAction = (
  id: string,
  royalty: number
): ISetPartyRoyaltyAction => ({
  type: '@U_CONTRACT/SET_PARTY_ROYALTY',
  payload: { id, royalty },
});
export const setPartyRecoupAction = (
  id: string,
  recoup: number
): ISetPartyRecoupAction => ({
  type: '@U_CONTRACT/SET_PARTY_RECOUP',
  payload: { id, recoup },
});
export const setPartyPublishingAction = (
  id: string,
  publishing: number
): ISetPartyPublishingAction => ({
  type: '@U_CONTRACT/SET_PARTY_PUBLISHING',
  payload: { id, publishing },
});
export const setPushFileAction = (file: File): ISetPushFileAction => ({
  type: '@U_CONTRACT/SET_PUSH_FILE',
  payload: { file },
});
export const setContractTypeAction = (type: string): ISetContractTypeAction => ({
  type: '@U_CONTRACT/SET_CONTRACT_TYPE',
  payload: { type },
});
export const setTitleAction = (title: string): ISetTitleAction => ({
  type: '@U_CONTRACT/SET_TITLE',
  payload: { title },
});

export const reducer: Reducer<IUploadedContractLocalState, IUContractAction> = function (
  state,
  action
) {
  switch (action.type) {
    case '@U_CONTRACT/SET_DESCRIPTION':
      return { ...state, description: action.payload.description, isEdited: true };
    case '@U_CONTRACT/ADD_PARTY':
      return {
        ...state,
        parties: [
          ...state.parties,
          {
            id: generateID(),
            role: '',
            name: '',
            email: '',
            advance: 0,
            royaltyLabel: 'Royalty, %',
            recoupLabel: 'Recoup',
            royalty: 0,
            recoup: 0,
            publishing: 0,
          },
        ],
      };
    case '@U_CONTRACT/REMOVE_PARTY':
      return {
        ...state,
        deletedPartyIds: [...state.deletedPartyIds, Number(action.payload.id)],
        parties: state.parties.filter((party) => party.id !== action.payload.id),
        isPartyEdited: true,
      };
    case '@U_CONTRACT/REMOVE_FILE':
      delete state.fileBlob;
      delete state.file;
      return {
        ...state,
        isEdited: true,
        fileName: null,
      };
    case '@U_CONTRACT/SET_PARTY_ROLE':
      return {
        ...state,
        parties: state.parties.map((party) => {
          if (party.id === action.payload.id) {
            return { ...party, role: action.payload.role };
          }
          return party;
        }),
        isPartyEdited: true,
      };
    case '@U_CONTRACT/SET_PARTY_NAME':
      return {
        ...state,
        editedPartyIds: state.editedPartyIds?.includes(Number(action.payload.id))
          ? state.editedPartyIds
          : [...state.editedPartyIds, Number(action.payload.id)],
        parties: state.parties.map((party) => {
          if (party.id === action.payload.id) {
            return { ...party, name: action.payload.name };
          }
          return party;
        }),
        isPartyEdited: true,
      };
    case '@U_CONTRACT/SET_PARTY_EMAIL':
      return {
        ...state,
        editedPartyIds: state.editedPartyIds?.includes(Number(action.payload.id))
          ? state.editedPartyIds
          : [...state.editedPartyIds, Number(action.payload.id)],
        parties: state.parties.map((party) => {
          if (party.id === action.payload.id) {
            return {
              ...party,
              email: action.payload.email,
            };
          }
          return party;
        }),
        isPartyEdited: true,
      };
    case '@U_CONTRACT/SET_PARTY_ADVANCE':
      return {
        ...state,
        editedPartyIds: state.editedPartyIds?.includes(Number(action.payload.id))
          ? state.editedPartyIds
          : [...state.editedPartyIds, Number(action.payload.id)],
        parties: state.parties.map((party) => {
          if (party.id === action.payload.id) {
            return {
              ...party,
              advance:
                action.payload.advance > 100000000
                  ? 100000000
                  : Math.round(action.payload.advance * 100) / 100,
            };
          }
          return party;
        }),
        isPartyEdited: true,
      };
    case '@U_CONTRACT/SET_PARTY_ROYALTY_LABEL':
      return {
        ...state,
        editedPartyIds: state.editedPartyIds?.includes(Number(action.payload.id))
          ? state.editedPartyIds
          : [...state.editedPartyIds, Number(action.payload.id)],
        parties: state.parties.map((party) => {
          if (party.id === action.payload.id) {
            return {
              ...party,
              royaltyLabel: action.payload.royaltyLabel,
            };
          }
          return party;
        }),
        isPartyEdited: true,
      };
    case '@U_CONTRACT/SET_PARTY_RECOUP_LABEL':
      return {
        ...state,
        editedPartyIds: state.editedPartyIds?.includes(Number(action.payload.id))
          ? state.editedPartyIds
          : [...state.editedPartyIds, Number(action.payload.id)],
        parties: state.parties.map((party) => {
          if (party.id === action.payload.id) {
            return {
              ...party,
              recoupLabel: action.payload.recoupLabel,
              recoup: 0,
            };
          }
          return party;
        }),
        isPartyEdited: true,
      };
    case '@U_CONTRACT/SET_PARTY_ROYALTY':
      return {
        ...state,
        editedPartyIds: state.editedPartyIds?.includes(Number(action.payload.id))
          ? state.editedPartyIds
          : [...state.editedPartyIds, Number(action.payload.id)],
        parties: state.parties.map((party) => {
          if (party.id === action.payload.id) {
            return {
              ...party,
              royalty:
                action.payload.royalty > 100
                  ? 100
                  : Math.round(action.payload.royalty * 100) / 100,
            };
          }
          return party;
        }),
        isPartyEdited: true,
      };
    case '@U_CONTRACT/SET_PARTY_RECOUP':
      return {
        ...state,
        editedPartyIds: state.editedPartyIds?.includes(Number(action.payload.id))
          ? state.editedPartyIds
          : [...state.editedPartyIds, Number(action.payload.id)],
        parties: state.parties.map((party) => {
          const maxValue = party.recoupLabel === 'Recoup, $' ? 100000000 : 100;
          if (party.id === action.payload.id) {
            return {
              ...party,
              recoup:
                action.payload.recoup > maxValue
                  ? maxValue
                  : Math.round(action.payload.recoup * 100) / 100,
            };
          }
          return party;
        }),
        isPartyEdited: true,
      };
    case '@U_CONTRACT/SET_PARTY_PUBLISHING':
      return {
        ...state,
        editedPartyIds: state.editedPartyIds?.includes(Number(action.payload.id))
          ? state.editedPartyIds
          : [...state.editedPartyIds, Number(action.payload.id)],
        parties: state.parties.map((party) => {
          if (party.id === action.payload.id) {
            return {
              ...party,
              publishing:
                action.payload.publishing > 100
                  ? 100
                  : Math.round(action.payload.publishing * 100) / 100,
            };
          }
          return party;
        }),
        isPartyEdited: true,
      };
    case '@U_CONTRACT/SET_PUSH_FILE':
      return {
        ...state,
        fileBlob: action.payload.file,
        isEdited: true,
      };
    case '@U_CONTRACT/SET_CONTRACT_TYPE':
      return {
        ...state,
        type: action.payload.type,
        isEdited: true,
      };
    case '@U_CONTRACT/SET_TITLE':
      return {
        ...state,
        title: action.payload.title,
        isEdited: true,
      };
    default:
      return state;
  }
};
