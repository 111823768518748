import { Category, ContentType } from 'common/api/models';
import MenuContext from 'redux/menu/types/menuContext';
import DriveAssetContext from 'redux/menu/interfaces/driveAssetContext';

const isDriveAssetContext = (context: MenuContext): context is DriveAssetContext =>
  context.kind === 'DRIVE' &&
  context.category === Category.AssetType &&
  context.typeId !== ContentType.MASTER_CONTRACT;

export default isDriveAssetContext;
