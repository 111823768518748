import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Routes } from 'navigation/enums/routes';
import { ReactComponent as MasterIcon } from 'assets/navigation/master.svg';
import { ReactComponent as ProjectIcon } from 'assets/navigation/project.svg';
import { ReactComponent as BeatIcon } from 'assets/navigation/note.svg';
import { ReactComponent as FilterIcon } from 'assets/navigation/filter.svg';
import useHasAccessToDrive from 'common/hooks/useHasAccessToDrive';
import SectionItem from 'common/components/sidebarNavigation/components/SectionItem';
import useHasResourceAccess from 'common/components/sidebarNavigation/hooks/useHasResourceAccess';

interface DriveNavItemsProps {
  isLoading: boolean;
  isCollapsed: boolean;
  onItemClick: () => void;
}

const DriveNavItems: FC<DriveNavItemsProps> = ({
  isLoading,
  isCollapsed,
  onItemClick,
}) => {
  const location = useLocation();
  const { isHasProjects, isHasMasters, isHasBeats } = useHasAccessToDrive();
  const isVisibleMaster = useHasResourceAccess('create_master');
  const isVisibleProject = useHasResourceAccess('create_project');
  const isVisibleBeat = useHasResourceAccess('free_beats');
  const isVisibleSearch = useHasResourceAccess('advance_search');
  return (
    <>
      {isHasMasters && isVisibleMaster && (
        <SectionItem
          loading={isLoading}
          to={Routes.Masters}
          isActive={location.pathname.includes(Routes.Masters)}
          isCollapsed={isCollapsed}
          IconComponent={MasterIcon}
          label="Masters"
          onClick={onItemClick}
        />
      )}
      {isHasProjects && isVisibleProject && (
        <SectionItem
          loading={isLoading}
          to={Routes.Projects}
          isActive={location.pathname.includes(Routes.Projects)}
          isCollapsed={isCollapsed}
          IconComponent={ProjectIcon}
          label="Create"
          onClick={onItemClick}
        />
      )}
      {isHasBeats && isVisibleBeat && (
        <SectionItem
          loading={isLoading}
          to={Routes.Beats}
          isActive={location.pathname.includes(Routes.Beats)}
          isCollapsed={isCollapsed}
          IconComponent={BeatIcon}
          label="Beats"
          onClick={onItemClick}
        />
      )}
      {isVisibleSearch && (
        <SectionItem
          loading={isLoading}
          to={Routes.AdvanceSearch}
          isActive={location.pathname.includes(Routes.AdvanceSearch)}
          isCollapsed={isCollapsed}
          IconComponent={FilterIcon}
          label="Advanced Search"
          onClick={onItemClick}
        />
      )}
    </>
  );
};

export default DriveNavItems;
