import { FolderItem, ContentType } from 'common/api/models';
import ViewDriveItemPayload from 'redux/selectedFile/interfaces/viewDriveItemPayload';
import ViewAssetActionPayload from 'redux/selectedFile/interfaces/viewAssetActionPayload';
import DefaultSelected from 'redux/selectedFile/types/defaultSelected';
import SharedData from 'modules/sharing/interfaces/sharedData';

export enum SelectedFileTypes {
  SHARED_ASSET = '@VIEW_FILE/SHARED_ASSET',
  DRIVE_ASSETS = '@VIEW_FILE/DRIVE_ASSETS',
  CLEAR_SELECTED_FILE = '@VIEW_FILE/RESET_VIEW',
  SET_DEFAULT_SELECTED = '@VIEW_FILE/SET_DEFAULT_SELECTED',
}

export interface ViewAssetAction {
  type: SelectedFileTypes.SHARED_ASSET;
  payload: ViewAssetActionPayload;
}

export const viewAsset = <T extends SharedData>(
  asset: T,
  typeId: ContentType,
  itemType: FolderItem,
  itemId: string,
  parentTitle: string
): ViewAssetAction => ({
  type: SelectedFileTypes.SHARED_ASSET,
  payload: { asset, typeId, itemId, itemType, parentTitle },
});

export interface ViewDriveItemAction {
  type: SelectedFileTypes.DRIVE_ASSETS;
  payload: ViewDriveItemPayload;
}

export const viewDriveItem = (payload: ViewDriveItemPayload): ViewDriveItemAction => ({
  type: SelectedFileTypes.DRIVE_ASSETS,
  payload,
});

export interface ResetViewAction {
  type: SelectedFileTypes.CLEAR_SELECTED_FILE;
  payload: null;
}

export const clearSelectedFile = (): ResetViewAction => ({
  type: SelectedFileTypes.CLEAR_SELECTED_FILE,
  payload: null,
});

export interface SetDefaultSelectedAction {
  type: SelectedFileTypes.SET_DEFAULT_SELECTED;
  payload: DefaultSelected;
}

export const setDefaultSelected = (payload: string | null): SetDefaultSelectedAction => ({
  type: SelectedFileTypes.SET_DEFAULT_SELECTED,
  payload,
});

export type ViewFileActions =
  | ViewAssetAction
  | ViewDriveItemAction
  | ResetViewAction
  | SetDefaultSelectedAction;
