import { gql } from '@apollo/client';

export const QueryName = 'GetGuestUser' as const;

export const GetGuestUsers = gql`
  query GetGuestUsers {
    getGuestUsers {
      id
      first_name
      last_name
      email
    }
  }
`;
