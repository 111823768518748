import * as React from 'react';
import { MenuItem, Divider, ListItemIcon } from '@material-ui/core';
import { useCollectionNamesQuery, CollectionBaseInfoFragment } from 'generated/graphql';
import { Skeleton } from '@material-ui/lab';
import { PlusIcon } from 'common/icons/system';
import Typography from 'uikit/typography/Typography';
import TextField from 'uikit/textField/TextField';
import TextFieldPropsExtended from 'common/types/textFieldPropsExtended';

const itemsSkeleton = [
  <MenuItem disabled key="1">
    <Skeleton width={92} />
  </MenuItem>,
  <MenuItem disabled key="2">
    <Skeleton width={92} />
  </MenuItem>,
  <MenuItem disabled key="3">
    <Skeleton width={92} />
  </MenuItem>,
];

const SelectProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: '192px',
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
} as const;

type Props = {
  catalogId: number;
  filter?: <T extends CollectionBaseInfoFragment = CollectionBaseInfoFragment>(
    items: T[]
  ) => T[];
  onChange?: (value: string, id?: number) => void;
  addItemValue?: string;
};

export type AlbumSelectProps = Omit<TextFieldPropsExtended, 'onChange'> & Props;

const AlbumSelect = (props: AlbumSelectProps) => {
  const {
    catalogId: catalogIdProp,
    onChange,
    variant,
    value,
    filter,
    addItemValue = 'ADD_ITEM_VAL',
    ...other
  } = props;
  const catalogId = catalogIdProp || -1;
  const isInvalidId = catalogId < 0;

  const { data, loading } = useCollectionNamesQuery({
    skip: isInvalidId,
    variables: { catalogId },
  });
  const colls = data?.catalog?.collections || [];
  const idIndex = colls.reduce<Record<string, typeof colls[number]>>((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
  const visible = filter ? filter(colls) : colls;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event.target.value, idIndex[event.target.value]?.id);
    },
    [onChange, idIndex]
  );

  return (
    <TextField
      variant={variant}
      value={value}
      select
      onChange={handleChange}
      SelectProps={SelectProps}
      {...other}
    >
      {loading && itemsSkeleton}
      {!loading &&
        visible.map((coll) => (
          <MenuItem key={coll.id} value={coll.id}>
            {coll.title}
          </MenuItem>
        ))}
      {!loading && <Divider />}
      {!loading && (
        <MenuItem value={addItemValue}>
          <ListItemIcon>
            <PlusIcon color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit" color="secondary">
            Create
          </Typography>
        </MenuItem>
      )}
    </TextField>
  );
};

export default AlbumSelect;
