import apiCoreService from 'api/core/apiCoreService';
import AddFileInput from 'api/core/interfaces/AddFileInput';

const addFile = async (
  input: AddFileInput,
  config?: {
    onProgress(event: ProgressEvent): void;
  }
) => {
  const { file, ...fileUpload } = input;
  const { data: link } = await apiCoreService.getStorageLink({ file_name: file.name });
  await apiCoreService.uploadFile(
    { link, file },
    { onUploadProgress: config?.onProgress }
  );

  return apiCoreService.createFile({ file_name: link.file_name, ...fileUpload });
};

export default addFile;
