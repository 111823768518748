import * as yup from 'yup';

const titleMax = 100;
const alphanumStrRegex = /^[\w\-\s]+$/;
const alphanumMsg = 'Alphanumeric characters or underscores only';

export const catalogOptional = yup.number();

export const catalogRequired = catalogOptional
  .required('Catalog is required')
  .min(0, 'Catalog is required');

export const titleOptional = yup
  .string()
  .matches(alphanumStrRegex, alphanumMsg)
  .max(titleMax);

export const titleRequired = titleOptional.required('Title is requuired');

export const artistNameOptional = yup
  .string()
  .matches(alphanumStrRegex, alphanumMsg)
  .max(titleMax);

export const artistNameRequired = artistNameOptional.required('Artist name is required');

const currentYear = new Date().getFullYear();

export const albumYearOptional = yup
  .number()
  .min(1900, "Year can't be less then 1900")
  .max(currentYear, `Year can't be greater then ${currentYear}`);

export const albumYearRequired = albumYearOptional.required('Album year is required');

export const albumNameOptional = yup
  .string()
  .matches(alphanumStrRegex, alphanumMsg)
  .max(titleMax);

export const albumNameRequired = albumNameOptional.required('Album name is required');

// TOOD: Replace with enum values;
export const collectionTypeOptional = yup.number().oneOf([0, 1]);

export const collectionTypeRequired = collectionTypeOptional.required();

export const collectionId = yup.number().min(0, 'Please choose album');

export const collectionIdRequired = collectionId.required('Please choose album');
