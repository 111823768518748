import * as React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import LinearProgress from 'uikit/linearProgress/LinearProgress';
import Typography from 'uikit/typography/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {},
  infoBox: {
    marginBottom: theme.spacing(1),
  },
  percent: {},
  progress: {},
  contrast: {
    color: theme.palette.common.white,
  },
}));

type BatchUploadProgressProps = {
  progress: number;
  filesCount: number;
  color?: 'primary' | 'secondary' | 'contrast';
  remaining?: React.ReactNode;
};

const BatchUploadProgress = (props: BatchUploadProgressProps) => {
  const { filesCount, progress, remaining, color = 'primary' } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, { [classes.contrast]: color === 'contrast' })}>
      <Box className={classes.infoBox} display="flex">
        <Box flex="1 1 auto">
          <Typography variant="body2">
            Uploading <strong>{filesCount}</strong> items
            {remaining && <span> - {remaining}</span>}
          </Typography>
        </Box>
        <Box flex="0 0 auto">
          <Typography variant="body2">{progress}%</Typography>
        </Box>
      </Box>
      <LinearProgress
        className={classes.progress}
        variant="determinate"
        color={color}
        value={progress}
      />
    </div>
  );
};

export default BatchUploadProgress;
