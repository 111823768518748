import ContractsPaths from 'api/contracts/enums/contractsPaths';
import POST from 'common/services/httpServices/post';
import { IRestSplitSheetCreateInput } from 'modules/splitSheet/contracts/interfaces/IRestSplitSheetCreateInput';
import { ISplitSheetData } from 'modules/splitSheet/redux/model';
import { toApproverInput, toReviewerInput } from 'modules/splitSheet/utils';

const postSplitSheet: (
  splitSheetData: ISplitSheetData,
  masterID: number
) => Promise<IRestSplitSheetCreateInput> = async (splitSheetData, masterID) => {
  const splitSheetAPIData: IRestSplitSheetCreateInput = {
    title: splitSheetData.songTitle,
    aka: splitSheetData.aka || undefined,
    approvers: splitSheetData.approvers.map(toApproverInput),
    reviewers: splitSheetData.reviewers.map(toReviewerInput),
    master_id: masterID,
    date: splitSheetData.date.format('YYYY-MM-DD'),
  };
  const res = await POST(
    ContractsPaths.CreateSplitSheet,
    JSON.stringify(splitSheetAPIData)
  );
  return await res.json();
};

export default postSplitSheet;
