import { Middleware } from 'redux';
import AmpService from 'common/api/AmpService';
import * as menu from 'redux/menu/menuActions';
import { RootState } from 'redux/root-reducer';
import { ContentType, Category } from 'common/api/models';
import { modalOpen, ModalConfigActionTypes } from 'redux/modal/modalActions';
import isClickAction from 'redux/menu/utils/isClickAction';
import FolderMenuContext from 'redux/menu/types/folderMenuContext';
import MenuOption from 'redux/menu/enums/menuOption';

const { BEATS_FOLDER } = ContentType;

const isFolderMediaPlaying = (context: FolderMenuContext, id?: number) => {
  if (!id) {
    return false;
  }

  if (context.typeId === ContentType.BEATS_FOLDER) {
    return !!context.data.find((asset) => asset.id === id);
  }

  if (context.typeId === ContentType.COLLECTION) {
    if ('ordered_masters' in context.data) {
      return !!context.data.ordered_masters.find((master) =>
        master.assets.find((asset) => asset.id === id)
      );
    }
  }

  if (context.data && 'assets' in context.data) {
    return !!context.data?.assets.find((asset) => asset.id === id);
  }
};

const deleteFolder: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (isClickAction(action)) {
    const {
      context,
      option,
      activePlayerAssetId,
      closePlayer,
      onDeleted,
    } = action.payload;

    if (option === MenuOption.DELETE) {
      if (
        context.category === Category.Folder &&
        context.typeId !== BEATS_FOLDER &&
        typeof context.data?.id === 'number'
      ) {
        const typeId = context.typeId;
        const { id, title } = context.data;
        const onApplyChange = async () => {
          try {
            const success = await AmpService.deleteDriveItem(typeId, id, context.data);

            if (success) {
              const isPlaying = isFolderMediaPlaying(context, activePlayerAssetId || 0);

              if (isPlaying && closePlayer) {
                closePlayer();
              }

              if (onDeleted) setTimeout(onDeleted, 0); // Sceduling modal close

              return success;
            }
          } catch {
            return false;
          }

          return false;
        };

        const openConfirmDialog = () =>
          store.dispatch(
            modalOpen({
              type: ModalConfigActionTypes.DELETE,
              data: {
                id: id,
                contentType: typeId,
                itemName: `"${title}"`,
                onApplyChange,
              },
            })
          );

        setTimeout(openConfirmDialog, 0); // Sceduling modal open

        return next(menu.close());
      }
    }
  }

  return next(action);
};

export default deleteFolder;
