import * as React from 'react';
import sleep from 'common/utils/sleep';
import {
  GetUmRolesDocument,
  UmRole,
  useDeleteRoleMutation,
  useCreateRoleMutation,
  useCatalogNamesQuery,
  useUpdateRoleMutation,
  useGetUmUsersQuery,
  UmUser,
  GetUmUsersDocument,
} from 'generated/graphql';
import { QueryName as GetUmRolesQueryName } from 'apollo/plugins/userManagement/queries/GetUmRoles';
import { RoleMenuItem, toUmRoleInput } from 'common/models/UmRole';
import { navigator } from 'common/appHistory';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as Modal from 'redux/modal/modalActions';
import * as Editor from 'redux/roleEditor/roleEditorActions';
import { useHistory } from 'react-router-dom';
import { SnackBox } from 'uikit/notifications/Notifications';
import { CreatingRole, SavingRole } from 'common/components/notifMessages/notifMessages';
import {
  touchedSelector,
  editorCatalogIdsSelector,
  editorUserIdsSelector,
} from 'redux/roleEditor/roleEditorSelectors';
import useConfirmExit from 'common/hooks/useConfirmExit/useConfirmExit';
import { CatalogName } from 'common/models/Catalog';
import { toUmRoleUser } from 'common/models/UmUser';
import { toIdMap } from 'common/models/commonUtils';
import { FormCheckableInput } from 'common/interfaces/formCheckableInput';

export function useDeleteRoleService() {
  const notify = useSnackbar();
  const dispatch = useDispatch();
  const [deleteRole] = useDeleteRoleMutation({
    // here we refetching by query name, because
    // query is 'active' (is alrady called)
    refetchQueries: [
      GetUmRolesQueryName,
      {
        query: GetUmUsersDocument,
      },
    ],
    onCompleted() {
      notify.enqueueSnackbar('Role is deleted', { variant: 'success' });
    },
  });

  return (role: UmRole) => {
    const handleConfirm = () => {
      deleteRole({ variables: { input: { id: role.id } } });
      dispatch(Modal.modalClose());
    };

    dispatch(
      Modal.modalOpen({
        type: Modal.ModalConfigActionTypes.CONFIRM,
        data: {
          props: {
            body: `${role.name} will be deleted.`,
            submitLabel: 'Delete',
            submitColor: 'error',
            onConfirm: handleConfirm,
          },
        },
      })
    );
  };
}

export function useHandleRoleOptins(context: UmRole) {
  const deleteRole = useDeleteRoleService();

  return (event: React.MouseEvent, item: RoleMenuItem) => {
    event.stopPropagation();
    if (item === RoleMenuItem.EDIT) {
      return navigator.um.goEditRole(context.id);
    }

    if (item === RoleMenuItem.COPY) {
      return navigator.um.goCopyRole(context.id);
    }

    if (item === RoleMenuItem.DELETE) {
      return deleteRole(context);
    }
  };
}

export function useCreateRoleService() {
  const history = useHistory();
  const dispatch = useDispatch();
  const notify = useSnackbar();

  const [create] = useCreateRoleMutation({
    // Updating in background
    refetchQueries: [
      {
        query: GetUmRolesDocument,
      },
      {
        query: GetUmUsersDocument,
      },
    ],
    awaitRefetchQueries: true,
  });

  return async (role: UmRole) => {
    const key = notify.enqueueSnackbar('', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      content: () => (
        <SnackBox>
          <CreatingRole />
        </SnackBox>
      ),
      persist: true,
    });

    dispatch(Editor.setSubmitting({ value: true }));

    try {
      const { data } = await create({
        variables: {
          input: toUmRoleInput(role),
        },
      });

      notify.enqueueSnackbar(`${data?.createRole.name} role created`, {
        variant: 'success',
      });

      dispatch(Editor.setSuccess());

      history.goBack();
    } catch (error) {
      notify.enqueueSnackbar('Failed to create new role', {
        variant: 'error',
      });
      dispatch(Editor.setError());
    } finally {
      // sleeping to get animation finish
      await sleep(350);
      notify.closeSnackbar(key);
    }
  };
}

export function useUpdateRoleService() {
  const dispatch = useDispatch();
  const notify = useSnackbar();

  const [update] = useUpdateRoleMutation({
    refetchQueries: [
      {
        query: GetUmRolesDocument,
      },
      {
        query: GetUmUsersDocument,
      },
    ],
    awaitRefetchQueries: true,
  });

  return async (role: UmRole) => {
    const key = notify.enqueueSnackbar('', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      content: () => (
        <SnackBox>
          <SavingRole />
        </SnackBox>
      ),
      persist: true,
    });

    dispatch(Editor.setSubmitting({ value: true }));

    try {
      await update({
        variables: {
          id: role.id,
          input: toUmRoleInput(role),
        },
      });

      notify.enqueueSnackbar('Role changes saved', {
        variant: 'success',
      });

      dispatch(Editor.setSuccess());
    } catch (error) {
      notify.enqueueSnackbar('Failed to save changes', {
        variant: 'error',
      });
      dispatch(Editor.setError());
    } finally {
      // sleeping to get animation finish
      await sleep(350);
      notify.closeSnackbar(key);
    }
  };
}

export function useAskOnExitEffect() {
  const touched = useSelector(touchedSelector);

  useConfirmExit(touched);
}

export function useAddCatalogs() {
  const addedIds = useSelector(editorCatalogIdsSelector, shallowEqual);
  const [checkedIds, setCheckedIds] = React.useState<number[]>([]);
  const { data, loading } = useCatalogNamesQuery();
  const dispatch = useDispatch();
  const catalogs = data?.catalogs || [];
  const catalogsById = React.useMemo(() => toIdMap(catalogs), [catalogs]);

  const notAdded = React.useMemo(() => {
    return catalogs.reduce((items, item) => {
      if (addedIds.indexOf(item.id) < 0) {
        return items.concat(item);
      }

      return items;
    }, [] as CatalogName[]);
  }, [addedIds, catalogs]);

  const items = React.useMemo(() => {
    return notAdded.map<FormCheckableInput>(({ id, name }) => ({
      id,
      label: name,
      value: id,
      checked: checkedIds.includes(id),
    }));
  }, [notAdded, checkedIds]);

  const allAdded = !notAdded.length && catalogs.length;
  const noItemsText = allAdded ? 'You added all catalogs' : 'No catalogs';

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked) => {
    const id = catalogsById[event.target.value].id;
    setCheckedIds((prev) => {
      return checked ? prev.concat(id) : prev.filter((cId) => cId !== id);
    });
  };

  const onSubmit = () => {
    dispatch(
      Editor.addRoleCatalogs({
        catalogs: checkedIds.map((id) => catalogsById[id]),
      })
    );
    dispatch(Modal.modalClose());
  };

  return {
    noItemsText,
    loading,
    items,
    onChange,
    onSubmit,
  };
}

export function useAddUsers() {
  const addedIds = useSelector(editorUserIdsSelector, shallowEqual);
  const [checkedIds, setCheckedIds] = React.useState<number[]>([]);
  const { data, loading } = useGetUmUsersQuery();
  const dispatch = useDispatch();
  const users = data?.umUsers || [];
  const usersById = React.useMemo(() => toIdMap(users), [users]);
  const notAdded = React.useMemo(() => {
    return users.reduce<UmUser[]>((items, item) => {
      if (addedIds.indexOf(item.id) < 0) {
        return items.concat(item);
      }

      return items;
    }, []);
  }, [users, addedIds]);
  const items = React.useMemo(() => {
    return notAdded.map<FormCheckableInput>(({ id, name }) => ({
      id,
      label: name,
      value: id,
      checked: checkedIds.includes(id),
    }));
  }, [notAdded, checkedIds]);

  const allAdded = !notAdded.length && !!users.length;
  const noItemsText = allAdded ? 'You added all users' : 'No catalogs';

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked) => {
    const id = usersById[event.target.value].id;
    setCheckedIds((prev) => {
      return checked ? prev.concat(id) : prev.filter((cId) => cId !== id);
    });
  };

  const onSubmit = () => {
    dispatch(
      Editor.addRoleUsers({
        users: checkedIds.map((id) => toUmRoleUser(usersById[id])),
      })
    );
    dispatch(Modal.modalClose());
  };

  return {
    noItemsText,
    loading,
    items,
    onChange,
    onSubmit,
  };
}
