import getGuestPerms from './services/getGuestPerms';
import getSharedVerificationCode from './services/getSharedVerificationCode';
import getSharedAssets from './services/getSharedAssets';
import getShareLink from './services/getShareLink';
import verifySharedVerificationCode from './services/verifySharedVerificationCode';
import getSharedInviteAssets from './services/getSharedInviteAssets';
import createAsset from './services/createAsset';
import shareContent from './services/shareContent';
import getStorageLink from './services/getStorageLink';
import attachFile from './services/attachFile';

export default {
  attachFile,
  createAsset,
  getGuestPerms,
  getShareLink,
  getSharedAssets,
  getSharedInviteAssets,
  getSharedVerificationCode,
  getStorageLink,
  shareContent,
  verifySharedVerificationCode,
};
