import * as yup from 'yup';

export const InviteInputSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .min(3, 'Must be at least 3 characters'),
  lastName: yup
    .string()
    .required('Last name is required')
    .min(3, 'Must be at least 3 characters'),
  email: yup.string().required('Email is required').email('Must be a valid email'),
});
