import { useDispatch } from 'react-redux';

import * as Modal from 'redux/modal/modalActions';

type ConfigData<
  T,
  C extends Modal.ModalConfigActions = Modal.ModalConfigActions
> = C extends {
  type: T;
  data: infer D;
}
  ? D
  : never;

const makeDialog = <T extends Modal.ModalConfigActionTypes, D extends ConfigData<T>>(
  type: T
) => {
  return () => {
    const dispatch = useDispatch();
    return (data: D) => {
      dispatch(
        Modal.modalOpen({
          type,
          data,
        } as Modal.ModalConfigActions)
      );
    };
  };
};

export default makeDialog;
