import AddToCollectionDialog from 'common/components/Modals/addToCollectionDialog/AddToCollectionDialog';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import ModalDelete from 'common/components/Modals/ModalDelete';
import ShareDialog from 'common/components/Modals/shareDialog/SahreDialog';
import ModalSplitSheet from 'common/components/Modals/ModalSplitSheet';
import ContractAgreementInfoDialog from 'common/components/Modals/ContractAgreementInfoDialog/ContractAgreementInfoDialog';
import ContractAgreementFormDialog from 'common/components/Modals/contractAgreementFormDialog/ContractAgreementFormDialog';
import AddMasterDialog from 'common/components/Modals/ModalMasterUpload/AddMasterDialog';
import ImportMasterFilesDialog from 'common/components/Modals/ModalMasterUpload/ImportMasterFilesDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  modalClose,
  modalClosed,
  ModalConfigActionTypes,
} from 'redux/modal/modalActions';
import { RootState } from 'redux/root-reducer';
import UploadAssetDialog from 'common/components/Modals/UploadAssetDialog/UploadAssetDialog';
import CreateAlbumDialog, {
  CreateCollAndAddMasterDialog,
} from 'common/components/Modals/createAlbumDialog/CreateAlbumDialog';
import CreateContractDialog from 'common/components/Modals/CreateContractDialog';
import EditAlbumDialog from 'common/components/Modals/editAlbumDialog/EditAlbumDialog';
import ConfirmDialog from 'uikit/confirmDialog/ConfirmDialog';
import { makeStyles } from '@material-ui/core';
import CatalogListDialog from 'modules/roles/components/сatalogListDialog/CatalogListDialog';
import UserListDialog from 'modules/roles/components/userListDialog/UserListDialog';
import useIsMobileUi from 'common/hooks/useIsMobileUi';
import UploadedContractEditDialog from 'modules/masters/components/uploadedContractEditDialog/UploadedContractEditDialog';
import clsx from 'clsx';
import CustomizeMasterDialog from 'common/components/Modals/customizeMasterDialog/CustomizeMasterDialog';
import SubmitToDropDialog from 'common/components/Modals/submitToDropDialog/SubmitToDropDialog';
import DeleteAccountModal from 'common/components/Modals//DeleteAccountModal';
import OpenAILyricsHelperDialog from 'common/components/Modals/openAILyricsHelperDialog/OpenAILyricsHelperDialog';
import CreateProjectDialog from 'common/components/Modals/createProjectDialog/CreateProjectDialog';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    paper: {},
    paperDefault: {
      [theme.breakpoints.up('md')]: {
        width: 420,
      },
      '$root$confirm &': {
        [theme.breakpoints.down('xs')]: {
          width: 304,
          margin: theme.spacing(1),
        },
      },
    },
    confirm: {},
  }),
  { name: 'UikDialog' }
);

const isConfirm = (modalType?: ModalConfigActionTypes | null) => {
  switch (modalType) {
    case ModalConfigActionTypes.DELETE:
    case ModalConfigActionTypes.CONFIRM:
    case ModalConfigActionTypes.CREATE_CONTRACT:
      return true;
    default:
      return false;
  }
};

const customWidth = (modalType?: ModalConfigActionTypes | null) => {
  switch (modalType) {
    case ModalConfigActionTypes.UPLOAD_CONTRACT_CREATE:
    case ModalConfigActionTypes.UPLOAD_CONTRACT_EDIT:
    case ModalConfigActionTypes.UPLOAD_CONTRACT_INFO:
    case ModalConfigActionTypes.SPLIT_SHEET:
    case ModalConfigActionTypes.EDIT_ALBUM:
    case ModalConfigActionTypes.SEND_PROMPT_TO_OPENAI:
      return true;
    default:
      return false;
  }
};

const Modals: React.FC = () => {
  const { isOpen, config, closing } = useSelector((state: RootState) => state.modal);
  const modalType = config?.type;
  const dispatch = useDispatch();
  const closeModal = React.useCallback(() => dispatch(modalClose()), [dispatch]);
  const handleExited = React.useCallback(() => dispatch(modalClosed()), [dispatch]);
  const classes = useStyles();
  const isMobile = useIsMobileUi();

  const renderModalBody = React.useCallback(() => {
    switch (config?.type) {
      case ModalConfigActionTypes.UPLOAD_CONTRACT_EDIT: {
        return <UploadedContractEditDialog {...config.data.props} />;
      }
      case ModalConfigActionTypes.USER_LIST: {
        return <UserListDialog onClose={closeModal} />;
      }
      case ModalConfigActionTypes.CATALOG_LIST: {
        return <CatalogListDialog onClose={closeModal} />;
      }
      case ModalConfigActionTypes.CONFIRM: {
        return (
          <ConfirmDialog
            title="Are you sure?"
            onClose={closeModal}
            {...config.data.props}
          />
        );
      }
      case ModalConfigActionTypes.EDIT_ALBUM: {
        return (
          <EditAlbumDialog onClose={closeModal} initialValues={config.data.values} />
        );
      }
      case ModalConfigActionTypes.ADD_TO_COLLECTION: {
        return <AddToCollectionDialog onClose={closeModal} {...config.data.props} />;
      }
      case ModalConfigActionTypes.CREATE_COLL_AND_ADD_MASTER: {
        return (
          <CreateCollAndAddMasterDialog onClose={closeModal} {...config.data.props} />
        );
      }
      case ModalConfigActionTypes.CREATE_ALBUM: {
        return (
          <CreateAlbumDialog
            onClose={() => {
              closeModal();
              if (config.onCloseAdditionalAction) {
                config.onCloseAdditionalAction();
              }
            }}
            {...config.data.props}
          />
        );
      }
      case ModalConfigActionTypes.MASTER_UPLOAD:
        return config.data.masterId ? (
          <ImportMasterFilesDialog onClose={closeModal} config={config.data} />
        ) : (
          <AddMasterDialog
            onClose={() => {
              closeModal();
              if (config.onCloseAdditionalAction) {
                config.onCloseAdditionalAction();
              }
            }}
            config={config.data}
          />
        );
      case ModalConfigActionTypes.CREATE_CONTRACT:
        return (
          <CreateContractDialog
            onClose={closeModal}
            masterId={config.data.masterId}
            catalogId={config.data.catalogId as number}
            disableSplitsheet={config.data.disableSplitsheet}
          />
        );
      case ModalConfigActionTypes.UPLOAD_ASSET: {
        return <UploadAssetDialog onClose={closeModal} config={config} />;
      }
      case ModalConfigActionTypes.DELETE:
        return (
          <ModalDelete
            onClose={closeModal}
            onApplyChange={async () => {
              if (config.data.onApplyChange) {
                return config.data.onApplyChange();
              }

              return true;
            }}
            name={config.data.itemName}
          />
        );
      case ModalConfigActionTypes.SHARE:
        return (
          <ShareDialog
            onClose={closeModal}
            onShare={config?.data.onShare}
            itemName={config?.data.itemName}
            ids={config?.data.ids}
            contentType={config?.data.contentType}
            perms={config?.data.perms}
            parentId={config?.data?.parentId}
          />
        );
      case ModalConfigActionTypes.SPLIT_SHEET:
        return <ModalSplitSheet onClose={closeModal} summary={config?.data} />;
      case ModalConfigActionTypes.UPLOAD_CONTRACT_INFO:
        return (
          <ContractAgreementInfoDialog onClose={closeModal} summary={config?.data} />
        );
      case ModalConfigActionTypes.UPLOAD_CONTRACT_CREATE:
        return (
          <ContractAgreementFormDialog
            onClose={closeModal}
            isCreatingSummary={config?.data.isCreatingSummary}
            summary={config?.data.initialSummary}
          />
        );
      case ModalConfigActionTypes.MASTER_CUSTOMIZE:
        return <CustomizeMasterDialog config={config.data} />;
      case ModalConfigActionTypes.SUBMIT_TO_DROP:
        return (
          <SubmitToDropDialog
            onClose={closeModal}
            config={config.initValues}
            drops={config.data}
          />
        );
      case ModalConfigActionTypes.DELETE_ACCOUNT:
        return <DeleteAccountModal onClose={closeModal} />;
      case ModalConfigActionTypes.SEND_PROMPT_TO_OPENAI:
        return <OpenAILyricsHelperDialog onClose={closeModal} editor={config.editor} />;
      case ModalConfigActionTypes.CREATE_PROJECT:
        return (
          <CreateProjectDialog
            onClose={closeModal}
            onCreate={config.onCreate}
            config={config.data}
          />
        );
      default:
        return <p>Unsupported modal</p>;
    }
  }, [closeModal, config]);

  return (
    <Dialog
      classes={{
        root: clsx(classes.root, {
          [classes.confirm]: isConfirm(modalType),
        }),
        paper: clsx(classes.paper, {
          [classes.paperDefault]: !customWidth(modalType),
        }),
      }}
      open={isOpen && !closing}
      scroll={'paper'}
      onExited={handleExited}
      fullScreen={isMobile && !isConfirm(modalType)}
      disableBackdropClick
      maxWidth="md"
    >
      {renderModalBody()}
    </Dialog>
  );
};

export default Modals;
