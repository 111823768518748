import { ContentType } from 'common/api/models';
import DriveItem from 'redux/selectedFile/interfaces/driveItem';
import createContract from 'redux/toolbar/services/createContract';

const createHandler = (file: DriveItem) => {
  if (file.typeId === ContentType.MASTER_DOCUMENT) {
    return createContract(file);
  }
};

export default createHandler;
