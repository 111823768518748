import { FC, ReactElement } from 'react';
import * as React from 'react';

const WebViewLayout: FC = ({ children }): ReactElement => {
  return (
    <>
      <div style={{ display: 'flex' }}>{children}</div>
    </>
  );
};

export default WebViewLayout;
