enum MenuOption {
  ADD_TO_LP = 'ADD_TO_LP',
  ADD_TO_EP = 'ADD_TO_EP',
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  SHARE = 'SHARE',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  DOWNLOAD = 'DOWNLOAD',
  UPLOAD = 'UPLOAD',
  DELETE = 'DELETE',
  REMOVE = 'REMOVE',
  DISTRIBUTION = 'DISTRIBUTION',
  CONFIGURATE = 'CONFIGURATE',
}

export default MenuOption;
