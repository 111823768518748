import * as React from 'react';

import ErrorPage from 'modules/errorPage/pages/ErrorPage';
import RootProviders from 'modules/app/components/RootProviders';
import PublicLayout from 'modules/app/components/PublicLayout';

const PublicError: React.FC = (): React.ReactElement => {
  return (
    <RootProviders>
      <PublicLayout>
        <ErrorPage />
      </PublicLayout>
    </RootProviders>
  );
};

export default PublicError;
