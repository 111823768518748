import { InMemoryCache } from '@apollo/client';
import schema from 'graphql.schema.json';

import itemsCount from 'apollo/cache/policies/itemsCount';
import { offsetLimitPagination } from 'apollo/cache/policies/pagination';
import CatalogMasters from 'apollo/cache/policies/CatalogMasters';
import AMPFeaturesFieldPolicy from 'apollo/plugins/features/policies/featuresFieldPolicy';

// TODO: Fix Array<any>;
const possibleTypes = (schema.__schema.types as Array<any>).reduce((pt, supertype) => {
  if (supertype === null) return pt;

  if (Array.isArray(supertype.possibleTypes)) {
    pt[supertype.name] = supertype.possibleTypes.map((subtype) => subtype.name);
  }

  return pt;
}, {});

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Resources: {
      keyFields: (resource, { typename }) => {
        // Generating id only if resouce has slug field
        if (resource.slug) {
          return `${typename}:${resource.slug}`;
        }
      },
    },
    DistributionRecords: {
      keyFields: (resource, { typename }) => {
        if (resource.edges && resource.edges[0]) {
          if (resource.edges[0].node.isActive) {
            return `${typename}:RELEASED`;
          } else {
            return `${typename}:DRAFT`;
          }
        }
      },
    },
    Assets: {
      keyFields: (resource, { typename }) => {
        if (typename === 'Assets' && resource.edges) {
          return `${typename}:Imported`;
        }
      },
    },
    Query: {
      fields: {
        getMaster(_, { args, toReference }) {
          if (args?.id) {
            return toReference({
              __typename: 'AMPMaster',
              id: args.id,
            });
          }
        },
        getContract(_, { args, toReference }) {
          if (args?.id) {
            return toReference({
              __typename: 'Contract',
              id: args.id,
            });
          }
        },
        // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
        umRole(_, { args, toReference }) {
          if (args?.id) {
            return toReference({
              __typename: 'UMRole',
              id: args.id,
            });
          }
        },
        features: AMPFeaturesFieldPolicy,
      },
    },
    Account: {
      keyFields: ['name'],
    },
    Catalog: {
      fields: {
        masters: CatalogMasters,
        masterCount: itemsCount('masters'),
        projects: offsetLimitPagination(),
        projectCount: itemsCount('projects'),
      },
    },
  },
});

export default cache;
