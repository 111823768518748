import {
  CollectionFieldsFragment,
  CollectionListFieldsFragment,
  MasterFieldsFragment,
} from 'generated/graphql';

const albumMastersById = (
  album: CollectionFieldsFragment | CollectionListFieldsFragment
) => {
  if ('ordered_masters' in album) {
    return album.ordered_masters.reduce<Record<string, MasterFieldsFragment>>(
      (acc, item) => {
        acc[item.id] = item;
        return acc;
      },
      {}
    );
  }
  return {};
};

export default albumMastersById;
