import React from 'react';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useSideBarNavigation from 'common/components/sidebarNavigation/hooks/useSideBarNavigation';
import Navigation from 'common/components/sidebarNavigation/components/Navigation';

const useStyles = makeStyles(
  () => ({
    paper: {
      overflow: 'visible',
    },
  }),
  { name: 'SidebarNavigation' }
);

interface SidebarNavigationProps {
  isOpened: boolean;
  menuOpenHandler: () => void;
  menuCloseHandler: () => void;
}

const SidebarNavigation: React.FC<SidebarNavigationProps> = ({
  isOpened,
  menuOpenHandler,
  menuCloseHandler,
}) => {
  const classes = useStyles();
  const {
    user,
    isLoading,
    isErrored,
    handleItemClick,
    isMobileUi,
    toggleNav,
    userClickHandler,
  } = useSideBarNavigation({
    isOpened,
    menuOpenHandler,
    menuCloseHandler,
  });

  if (isErrored) return null;

  const navigation = (
    <Navigation
      isOpened={isOpened}
      isMobile={isMobileUi}
      isLoading={isLoading}
      onUserClick={userClickHandler}
      handleItemClick={handleItemClick}
      toggleNav={toggleNav}
      user={user}
    />
  );

  return isMobileUi ? (
    <Drawer
      anchor={'left'}
      variant={'temporary'}
      open={isOpened}
      onClose={menuCloseHandler}
      classes={{ paper: classes.paper }}
    >
      {navigation}
    </Drawer>
  ) : (
    navigation
  );
};

export default SidebarNavigation;
