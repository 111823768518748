import INIT_DIALOG_STATE from 'redux/dialog/configs/initDialogState';
import DialogState from 'redux/dialog/interfaces/dialogState';
import { DialogActions, DialogActionTypes } from 'redux/dialog/dialogActions';

const dialogReducer = (
  state: DialogState = INIT_DIALOG_STATE,
  action: DialogActions
): DialogState => {
  const actionTypes = DialogActionTypes;

  switch (action.type) {
    case actionTypes.SetDialogOpened:
      if (state.content.some(({ name }) => name === action.payload.value.content.name)) {
        throw new Error('Modal with such name exists');
      }
      return {
        ...state,
        ...action.payload.value,
        content: [...state.content, action.payload.value.content],
      };
    case actionTypes.SetDialogClosed:
      return { ...state, isOpened: action.payload.value };
    case actionTypes.ClearDialogContent:
      return { ...state, content: action.payload.value };
    case actionTypes.SetPrevDialogOpened:
      return {
        ...state,
        ...(state.content.length > 1
          ? { content: state.content.slice(0, state.content.length - 1) }
          : {}),
        ...(state.content.length === 1 ? { isOpened: false } : {}),
      };
    case actionTypes.CloseDialogByName:
      const newContent = state.content.filter(
        ({ name }) => name !== action.payload.value
      );

      return {
        ...state,
        content: newContent,
        isOpened: newContent.length > 0,
      };
    default:
      return state;
  }
};

export default dialogReducer;
