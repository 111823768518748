import SubscribedSubscriptionState from 'redux/subscribedSubscription/interfaces/subscribedSubscriptionState';
import INIT_SUBSCRIBED_SUBSCRIPTION from 'redux/subscribedSubscription/configs/initSubscribedSubscription';
import {
  SubscribedSubscriptionAction,
  SubscribedSubscriptionActionTypes,
} from 'redux/subscribedSubscription/subscribedSubscriptionActions';
import { AccountSubscriptionFragment } from 'generated/graphql';

const subscribedSubscriptionReducer = (
  state: SubscribedSubscriptionState = INIT_SUBSCRIBED_SUBSCRIPTION,
  action: SubscribedSubscriptionAction
): SubscribedSubscriptionState => {
  const actions = SubscribedSubscriptionActionTypes;
  switch (action.type) {
    case actions.SetSubscribedSubscription:
      return {
        ...state,
        subscription: action.payload.value as AccountSubscriptionFragment,
      };
    case actions.SetSubscribedSubscriptionIsLoading:
      return {
        ...state,
        isLoading: action.payload.value,
      };
    case actions.SetSubscribedSubscriptionState:
      return action.payload.value;
    default:
      return state;
  }
};

export default subscribedSubscriptionReducer;
