import CollValues from 'common/components/Modals/createAlbumDialog/interfaces/collValues';
import { MutationOptions } from '@apollo/client';
import { CreateAlbumMutation, CreateAlbumMutationVariables } from 'generated/graphql';
import collValuesToCollectionInput from 'common/components/Modals/createAlbumDialog/utils/collValuesToCollectionInput';
import createAlbumUpdater from 'common/components/Modals/createAlbumDialog/utils/createAlbumUpdater';

const createAlbumOptions = (
  values: CollValues
): Omit<
  MutationOptions<CreateAlbumMutation, CreateAlbumMutationVariables>,
  'mutation'
> => {
  return {
    variables: { input: collValuesToCollectionInput(values) },
    update: createAlbumUpdater(values.catalogId),
  };
};

export default createAlbumOptions;
