import { notify } from 'common/components/notifMessages/Notify';
import {
  ContentType,
  MasterAssetIds,
  MasterAssetType,
  ProjectAssetIds,
  ProjectAssetType,
} from 'common/api/models';
import DriveItem from 'redux/selectedFile/interfaces/driveItem';
import uploadMasterAsset from 'redux/toolbar/services/uploadMasterAsset';
import uploadProjectAsset from 'redux/toolbar/services/uploadProjectAsset';
import uploadMasterFile from 'redux/toolbar/services/uploadMasterFile';

const uploadHandler = (file: DriveItem) => {
  if (file.typeId === ContentType.MASTER_FILE) return uploadMasterFile(file);

  if (MasterAssetIds.includes(file.typeId as MasterAssetType))
    return uploadMasterAsset(file);

  if (ProjectAssetIds.includes(file.typeId as ProjectAssetType))
    return uploadProjectAsset(file);

  notify.enqueueSnackbar('Uploading of this content type is not supported yet.');
};

export default uploadHandler;
