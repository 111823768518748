import apiCoreService from 'api/core/apiCoreService';
import { useState, useEffect } from 'react';
import { StorageLink } from 'common/api/models';
import getUploadingProgress from 'redux/fileUpload/utils/getUploadingProgress';

export type State = {
  data: StorageLink | undefined;
  loading: boolean;
  error: unknown | undefined;
  progress: number;
};

const initState = {
  data: undefined,
  loading: false,
  error: undefined,
  progress: 0,
};

export default function useImageUpload(
  img?: File,
  onCompleted?: (result: StorageLink) => void,
  onError?: (error: unknown) => void
) {
  const [state, setState] = useState<State>(initState);

  useEffect(() => {
    const upload = async (file?: File) => {
      if (file) {
        setState((prev) => ({ ...prev, loading: true }));
        try {
          const { data: link } = await apiCoreService.getStorageLink({
            file_name: file.name,
          });

          await apiCoreService.uploadFile(
            { link, file },
            {
              onUploadProgress: (event: ProgressEvent) => {
                setState((prev) => {
                  if (prev.loading) {
                    return {
                      ...prev,
                      progress: getUploadingProgress(event.loaded, event.total),
                    };
                  }

                  return prev;
                });
              },
            }
          );

          setState((prev) => ({
            ...prev,
            loading: false,
            error: undefined,
            data: link,
          }));

          if (onCompleted) onCompleted(link);
        } catch (error) {
          setState((prev) => ({ ...prev, loading: false, error }));
          if (onError) onError(error);
        }
      }
    };

    upload(img);
    return () => setState({ ...initState });
  }, [img, onCompleted, onError]);

  return state;
}
