import React, { useState } from 'react';
import { Box, DialogActions, DialogContent } from '@material-ui/core';
import TextField from 'uikit/textField/TextField';
import Typography from 'uikit/typography/Typography';
import { Rating } from '@material-ui/lab';
import Button from 'uikit/button/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useRateOpenAiPromptRecordCompletionMutation } from 'generated/graphql';
import useAddPlainText from 'modules/projectLyricsEditor/hooks/useAddPlainText';
import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'hidden',
      marginTop: theme.spacing(2),
    },
    completionArea: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    completionTextArea: {
      color: theme.palette.text.primary,
      [theme.breakpoints.up('md')]: {
        width: '550px',
      },
    },
    ratingBox: {
      marginTop: '10px',
      height: '50px',
    },
    actionButton: {
      width: '175px',
      height: '43px',
      fontSize: '14pt',
    },
  }),
  { name: 'GenerateLyricsPage' }
);
interface GenerateLyricsPageProps {
  maxInputLength: number;
  placeholder: string;
  generatedCompletion: string | null;
  promptId: number | null;
  editor: BaseEditor & ReactEditor;
  onCancel: () => void;
  onClose: () => void;
  generateLyrics: (input: string) => void;
}

const GenerateLyricsPage: React.FC<GenerateLyricsPageProps> = ({
  maxInputLength,
  placeholder,
  generatedCompletion,
  promptId,
  editor,
  onCancel,
  onClose,
  generateLyrics,
}) => {
  const classes = useStyles();
  const [
    rateCompletion,
    { loading: rateCompletionLoading },
  ] = useRateOpenAiPromptRecordCompletionMutation();
  const [textInput, setTextInput] = useState<string>('');
  const addPlainText = useAddPlainText(editor, generatedCompletion || '');
  const isTextInputVoid = textInput.length === 0;
  const inputMaxLengthExceeded = textInput.length > maxInputLength;
  const showCompletion = !!generatedCompletion;

  const onPrimaryButtonClickHandler = () => {
    if (showCompletion) {
      addPlainText();
      onClose();
    } else {
      generateLyrics(textInput);
    }
  };
  const onSecondaryButtonClickHandler = () => {
    if (showCompletion) {
      generateLyrics(textInput);
      return;
    } else {
      onCancel();
    }
  };

  return (
    <>
      <DialogContent className={classes.root}>
        {showCompletion ? (
          <div className={classes.completionArea}>
            <TextField
              inputProps={{ className: classes.completionTextArea }}
              disabled={true}
              multiline
              rowsMax={12}
              rows={12}
              value={generatedCompletion}
              fullWidth={true}
            />
            <Box className={classes.ratingBox}>
              <Typography component="legend">Rate completion</Typography>
              <Rating
                name={'completionRating'}
                precision={0.5}
                disabled={rateCompletionLoading}
                onChange={(event, newValue) => {
                  if (promptId) {
                    rateCompletion({
                      variables: {
                        rate: newValue ? newValue * 2 : null,
                        recordId: promptId,
                      },
                    })
                      .then()
                      .catch();
                  }
                }}
              />
            </Box>
            <span style={{ height: '16px', display: 'block' }}></span>
          </div>
        ) : (
          <div className={classes.completionArea}>
            <TextField
              inputProps={{ className: classes.completionTextArea }}
              placeholder={placeholder}
              multiline
              rowsMax={12}
              rows={12}
              onChange={(event) => setTextInput(event.target.value)}
              value={textInput}
              error={inputMaxLengthExceeded}
              helperText={
                inputMaxLengthExceeded
                  ? `Input length must not be longer than ${maxInputLength}`
                  : ''
              }
              fullWidth={true}
            />
            {inputMaxLengthExceeded ? (
              <></>
            ) : (
              <span style={{ height: '16px', display: 'block' }}></span>
            )}
            <Box className={classes.ratingBox}></Box>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSecondaryButtonClickHandler}
          variant={showCompletion ? 'contained' : 'outlined'}
          color={showCompletion ? 'secondary' : 'default'}
          className={classes.actionButton}
        >
          {showCompletion ? 'Try Again' : 'Cancel'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onPrimaryButtonClickHandler}
          className={classes.actionButton}
          disabled={!showCompletion && (isTextInputVoid || inputMaxLengthExceeded)}
        >
          {showCompletion ? 'Add to Project' : 'Submit'}
        </Button>
      </DialogActions>
    </>
  );
};

export default GenerateLyricsPage;
