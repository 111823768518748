import apiCoreService from 'api/core/apiCoreService';
import { AttachObjectType } from 'common/api/models';

const uploadAvatar = async (file: File): Promise<{ url: string }> => {
  const { data } = await apiCoreService.getStorageLink({ file_name: file.name });
  await apiCoreService.uploadFile({ link: data, file });
  const { data: url } = await apiCoreService.getAttachFileUrl({
    file_name: data.file_name,
    asset_type: 'PROFILE_PICTURE',
    attach: false,
    attach_object_type: AttachObjectType.PROFILE_PICTURE,
  });
  return { url };
};

export default uploadAvatar;
