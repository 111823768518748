import actionFn from 'common/utils/actionFn';

export enum ActionType {
  SET_FILTRER = '@RM/SET_FILTER',
  RESET_FILTER = '@RM/RESET_FILTER',
}

export type SetFilterAction = {
  type: ActionType.SET_FILTRER;
  payload: {
    name: 'search';
    value: string;
  };
};

export type ResetFilterAction = {
  type: ActionType.RESET_FILTER;
  payload: {
    name: 'search';
  };
};

export type Action = SetFilterAction | ResetFilterAction;

export const setFilter = actionFn<SetFilterAction>(ActionType.SET_FILTRER);

export const resetFilter = actionFn<ResetFilterAction>(ActionType.RESET_FILTER);
