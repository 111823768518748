import { RootState } from 'redux/root-reducer';
import ContentPermissions from 'common/interfaces/contentPermissions';

export const permissionsSelector = (state: RootState): ContentPermissions | null => {
  const hardPermissions = state.permissions.hardPermissions;
  const catalogId = state.filters.catalog?.value.id;
  const catalogs = state.permissions.catalogs;

  if (hardPermissions) return hardPermissions;

  if (catalogs && catalogId && catalogs.hasOwnProperty(catalogId))
    return catalogs[catalogId].permissions;

  return null;
};

export const guestPermissionsSelector = (state: RootState) =>
  state.permissions.guestPermissions;

export const permsByCatalogIdSelector = (catalogId: number) => (state: RootState) => {
  const hardPermissions = state.permissions.hardPermissions;
  const catalogs = state.permissions.catalogs;

  if (hardPermissions) return hardPermissions;

  if (catalogs && catalogId && catalogs.hasOwnProperty(catalogId))
    return catalogs[catalogId].permissions;

  return null;
};
