import React, { FC } from 'react';
import { Skeleton } from '@material-ui/lab';
import Typography from 'uikit/typography/Typography';
import storageCapacityShortener from 'common/components/sidebarNavigation/utils/storageCapacityShortener';
import { useTheme } from '@material-ui/core';
import { AccountFieldsFragment } from 'generated/graphql';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    capacitySection: {
      padding: theme.spacing(3),
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
    },
    capacityIndicator: {
      margin: theme.spacing(1, 0),
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
    },
  }),
  {
    name: 'StorageInfo',
  }
);

interface StorageInfoProps {
  isOpened: boolean;
  isLoading: boolean;
  account: AccountFieldsFragment;
}

const StorageInfo: FC<StorageInfoProps> = ({ isLoading, isOpened, account }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.capacitySection}>
      {isOpened &&
        (isLoading ? (
          <React.Fragment>
            <Skeleton />
            <Skeleton />
            <Skeleton
              variant={'rect'}
              width={50}
              height={20}
              style={{ marginTop: theme.spacing(1) }}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography variant={'body2'}>
              Using {storageCapacityShortener(account.usedStorage)} of &nbsp;
              {storageCapacityShortener(account.maxStorage)}
            </Typography>
            {account.usedStorage && account.maxStorage && (
              <div className={classes.capacityIndicator}>
                <div
                  style={{
                    width: `${(account.usedStorage / account.maxStorage) * 100}%`,
                    height: 4,
                    backgroundColor: theme.palette.primary.main,
                  }}
                />
              </div>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default StorageInfo;
