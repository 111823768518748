import * as React from 'react';
import { FastField, FieldProps, useFormikContext } from 'formik';
import AlbumSelect, { AlbumSelectProps } from 'common/components/albumSelect/AlbumSelect';
import { CollectionType } from 'common/models/Collection';

interface IFormContext {
  catalogId: number;
  collectionType: CollectionType;
}

type Props = {
  name: string;
  onAddClick?: () => void;
};

export default function AlbumSelectField(
  props: Omit<AlbumSelectProps, 'catalogId'> & Props
) {
  const { name, onAddClick, ...other } = props;
  const { values, setFieldValue } = useFormikContext<IFormContext>();
  const { catalogId, collectionType } = values;

  React.useEffect(() => {
    // field depends on catalogId and collection type
    // so, we should reset if some of this values change
    setFieldValue(name, undefined);
  }, [catalogId, setFieldValue, collectionType, name]);

  return (
    <FastField key={catalogId} name={props.name}>
      {({ field, meta, form }: FieldProps) => {
        const { name } = field;
        const isInvalid = !!meta.error && meta.touched;
        const errorMsg = isInvalid ? meta.error : <span>&nbsp;</span>;

        const handleChange = (value: string, id?: number) => {
          if (value === 'ADD_ITEM_VAL') {
            onAddClick && onAddClick();
          } else {
            form.setFieldValue(name, id);
          }
        };

        return (
          <AlbumSelect
            catalogId={catalogId}
            filter={(colls) =>
              colls.filter((coll) => coll.collectionType === collectionType)
            }
            {...other}
            value={field.value || ''}
            onBlur={field.onBlur}
            onChange={handleChange}
            error={isInvalid}
            helperText={errorMsg}
          />
        );
      }}
    </FastField>
  );
}
