import getAccessToken from 'common/services/tokenServices/getAccessToken';

import { BASE_URL } from './configs/constants';

const POST = async (endpoint: string, body?: BodyInit): Promise<Response> => {
  const token = await getAccessToken();
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });
  token && headers.append('Authorization', `Bearer ${token}`);
  const res = await fetch(`${BASE_URL}/${endpoint}`, {
    method: 'POST',
    headers,
    body,
  });
  if (res.ok) {
    return res;
  }
  throw res;
};

export default POST;
