import SharePaths from 'api/share/enums/sharePaths';
import ShareResponse from 'api/share/interfaces/shareResponse';
import patchCollectionData from 'api/share/utils/patchCollectionData';
import setAuthConfig from 'api/share/utils/setAuthConfig';
import constructPath from 'api/utils/constructPath';
import axios from 'common/api/axios';
import SharedData from 'modules/sharing/interfaces/sharedData';

const getSharedAssets = async (assetId: string): Promise<SharedData> => {
  const { data: rawData } = await axios.get<ShareResponse>(
    constructPath(SharePaths.GetSharedAssets, { assetId }),
    setAuthConfig()
  );

  const data = patchCollectionData(rawData);

  return {
    catalogId: data.catalog_id,
    user: {
      email: data.user.email,
      firstName: data.user.first_name,
      lastName: data.user.last_name,
      photoUrl: data.user.photo_url || null,
    },
    typeId: data.type_id,
    permissions: data.permissions,
    data: data.data,
  };
};

export default getSharedAssets;
