import React, { useEffect, FC, ReactElement } from 'react';

import Header from 'modules/app/components/Header';
import SidebarNavigation from 'common/components/sidebarNavigation/SidebarNavigation';
import useIsMobileUi from 'common/hooks/useIsMobileUi';
import Preloader from 'uikit/preloader/Preloader';

interface Layout {
  isLoading?: boolean;
  isSideBarVisible?: boolean;
}

const Layout: FC<Layout> = ({
  children,
  isLoading = false,
  isSideBarVisible = true,
}): ReactElement => {
  const isMobile = useIsMobileUi();
  const [openNav, setOpenNav] = React.useState(!isMobile);
  const openNavHandler = React.useCallback(() => setOpenNav(true), []);
  const closeNavHandler = React.useCallback(() => setOpenNav(false), []);

  useEffect(() => {
    setOpenNav(!isMobile);
  }, [isMobile]);

  return (
    <>
      <Header onOpenNav={openNavHandler} isHasMenu={isSideBarVisible} />
      <div style={{ display: 'flex' }}>
        {isLoading ? (
          <Preloader fillViewport />
        ) : (
          isSideBarVisible && (
            <SidebarNavigation
              isOpened={openNav}
              menuOpenHandler={openNavHandler}
              menuCloseHandler={closeNavHandler}
            />
          )
        )}
        {children}
      </div>
    </>
  );
};

export default Layout;
