import { AssetType as GQLAssetType } from 'generated/graphql';

export interface ICatalog {
  readonly id: string | number;
  readonly name: string;
}

export interface IMedia {
  readonly media_url: string;
  readonly size: number;
}

export interface ITypedNode {
  typeId: number;
}

export interface ITypedSnakeNode {
  type_id: number;
}

export type TypedNode = ITypedNode | ITypedSnakeNode;

export enum ContentType {
  BEATS_FREE = 1,
  BEATS_IMPORT = 2,
  PROJECT_BEAT = 3,
  PROJECT_IMPORT = 4,
  PROJECT_RECORDING = 5,
  PROJECT_MIX = 6,
  MASTER_FILE_CLEAN = 7,
  MASTER_FILE_EXPLICIT = 8,
  MASTER_STEM = 9,
  MASTER_360RA = 10,
  MASTER_DOCUMENT = 12,
  MASTER_CONTRACT = 13,
  PROJECT_FOLDER = 14,
  MASTER_META = 15,
  MASTER_FOLDER = 16,
  PROJECT_LYRICS = 17,
  PROJECT_META = 18,
  BEATS_FOLDER = 19,
  MASTER_FILE = 20,
  MASTER_MEDIA = 21,
  COLLECTION = 22,
  ALBUM_COVER = 23,
  RELEASES = 24,
  DROP_SUBMISSION = 25,
}

export enum FolderItem {
  BEAT = 'BEAT',
  CONTRACTS = 'CONTRACTS',
  DOCUMENTS = 'DOCUMENTS',
  MASTER_FILES = 'MASTER_FILES',
  MASTER_360RA = 'MASTER_360RA',
  IMPORTS = 'IMPORTS',
  LYRICS = 'LYRICS',
  MEDIA = 'MEDIA',
  META = 'META',
  MIXES = 'MIXES',
  RECORDINGS = 'RECORDINGS',
  STEM_FILES = 'STEM_FILES',
}

export const FolderTypesArray = [
  ContentType.PROJECT_FOLDER,
  ContentType.MASTER_FOLDER,
  ContentType.BEATS_FOLDER,
  ContentType.COLLECTION,
  ContentType.MASTER_CONTRACT,
] as const;

export const AlbumItemTypesArray = [ContentType.MASTER_FOLDER] as const;

export type AlbumItemType = typeof AlbumItemTypesArray[number];

export type FolderContentType = typeof FolderTypesArray[number];

export type NoFolderContentType = Exclude<ContentType, FolderContentType>;

export const ProjectAssetIds = [
  ContentType.PROJECT_BEAT,
  ContentType.PROJECT_IMPORT,
  ContentType.PROJECT_RECORDING,
  ContentType.PROJECT_MIX,
] as const;

export const MasterAssetIds = [
  ContentType.MASTER_FILE_CLEAN,
  ContentType.MASTER_FILE_EXPLICIT,
  ContentType.MASTER_STEM,
  ContentType.MASTER_DOCUMENT,
  ContentType.MASTER_CONTRACT,
  ContentType.MASTER_MEDIA,
  ContentType.MASTER_360RA,
] as const;

export const ASSET_TYPE_IDS = [
  ...ProjectAssetIds,
  ...MasterAssetIds,
  ContentType.BEATS_FREE,
  ContentType.BEATS_IMPORT,
  ContentType.MASTER_FILE,
  ContentType.ALBUM_COVER,
] as const;

export type ProjectAssetType = typeof ProjectAssetIds[number];

export type MasterAssetType = typeof MasterAssetIds[number];

export type AssetType = typeof ASSET_TYPE_IDS[number];

export type MasterVersion =
  | ContentType.MASTER_FILE_EXPLICIT
  | ContentType.MASTER_FILE_CLEAN;

export enum Category {
  All = 'All',
  AssetType = 'AssetType',
  Folder = 'Folder',
  FolderItem = 'FolderItem',
  AlbumItem = 'AlbumItem',
}

export const GQLAssetTypeMap: Record<GQLAssetType, ContentType> = {
  [GQLAssetType.IMPORT_BEAT]: ContentType.BEATS_IMPORT,
  [GQLAssetType.MASTER_CLEAN]: ContentType.MASTER_FILE_CLEAN,
  [GQLAssetType.MASTER_CONTRACT]: ContentType.MASTER_CONTRACT,
  [GQLAssetType.MASTER_DOCUMENT]: ContentType.MASTER_DOCUMENT,
  [GQLAssetType.MASTER_EXPLICIT]: ContentType.MASTER_FILE_EXPLICIT,
  [GQLAssetType.MASTER_MEDIA]: ContentType.MASTER_MEDIA,
  [GQLAssetType.MASTER_STEM]: ContentType.MASTER_STEM,
  [GQLAssetType.MASTER_360RA]: ContentType.MASTER_360RA,
  [GQLAssetType.PROJECT_BEAT]: ContentType.PROJECT_BEAT,
  [GQLAssetType.PROJECT_IMPORT]: ContentType.PROJECT_IMPORT,
  [GQLAssetType.PROJECT_MIX]: ContentType.PROJECT_MIX,
  [GQLAssetType.PROJECT_RECORDING]: ContentType.PROJECT_RECORDING,
  [GQLAssetType.TULLY_BEAT]: ContentType.BEATS_FREE,
  [GQLAssetType.ALBUM_COVER]: ContentType.ALBUM_COVER,
  [GQLAssetType.MASTER_360RA]: ContentType.MASTER_360RA,
  [GQLAssetType.DROP_SUBMISSION]: ContentType.DROP_SUBMISSION,
};

export type RestMaster = {
  id: number;
  fire_node_id: string;
  title: string;
  created_at: string;
  updated_at: string;
  lock: boolean | null;
  meta: any;
  catalog: number;
};

export type RestProject = {
  id: number;
  fire_node_id: string;
  title: string;
  created_at: string;
  collabId: string;
  updated_at: string;
  type: number;
  lock: boolean | null;
  metadata: any;
  catalog: number;
  is_archived: boolean;
};

export type Asset = {
  catalog: number;
  created_at: string;
  file: number;
  fire_node_id: string;
  id: number;
  master: number;
  meta: null;
  metadata: null;
  project: number | null;
  title: string;
  type: AssetType;
  updated_at: string;
};

export type StorageFile = {
  id: number;
  created_at: string;
  updated_at: string;
  storage_slug: string;
  file_path: string;
  crc32c: string | null;
  md5hash: string | null;
  content_type: string | null | undefined;
  media_created: string | null;
  media_updated: string | null;
  size: number | null;
  user: number | null;
};

// This type exists just to emphasize on relationship between StorageLink and FileUpload
export type StorageFileName = string;

export type StorageLink = {
  file_name: StorageFileName;
  expiry: string;
  upload_url: string;
};

export type StorageFileUpload = {
  file_name: StorageFileName;
  asset_type: 'PROJECT_ASSET' | 'MASTER_ASSET' | 'BEATS' | 'DROP_SUBMISSION';
  catalog_id: number;
  parent_id?: number; // Project or Master id
};

export enum AttachObjectType {
  ASSET = 1,
  CONTRACT = 2,
  PROFILE_PICTURE = 3,
  MASTER_ARTWORK = 4,
}

export type AttachBody = {
  file_name: string; // file name received from signed upload url api
  asset_type:
    | 'MASTER_ASSET'
    | 'BEATS'
    | 'PROJECT_ASSET'
    | 'PROFILE_PICTURE'
    | 'MASTER_ARTWORK';
  catalog_id?: number;
  parent_id?: number; // (optional) required in case of asset has parent.
  attach: boolean; // Attach to an Asset (True / False)
  attach_object_id?: number; // asset id where needs to be attached
  attach_object_type: AttachObjectType;
};

export type AttachRes = {
  signed_download_url: string;
};
