import EditorState from 'redux/roleEditor/interfaces/editorState';

const INIT_ROLE_EDITOR_STATE: EditorState = {
  role: null,
  catalogIndex: null,
  touched: false,
  operation: null,
  isSubmitting: false,
  submitResult: null,
  disabled: false,
};

export default INIT_ROLE_EDITOR_STATE;
