import apiCatalogsService from 'api/catalogs/apiCatalogsService';
import apiCoreService from 'api/core/apiCoreService';
import { MasterVersion } from './models';
import { useEventCallback } from 'common/hooks';
import { CustomizeMasterValues } from 'common/components/Modals/ModalMasterUpload/reducer';

export interface IMasterBlob {
  typeId: MasterVersion;
  file: File;
}

type Config = {
  onProgress(event: ProgressEvent, index: number): void;
};

export default function useAddMasterService<T extends IMasterBlob>() {
  const execute = async (
    input: {
      masterName: string;
      catalogId: number;
      files?: T[];
      customizeMaster: CustomizeMasterValues | null;
    },
    config?: Config
  ) => {
    const { catalogId, masterName, files, customizeMaster } = input;
    const master = await apiCatalogsService.createMaster(
      {
        title: masterName,
        meta: { customizeMaster: [customizeMaster] ? [customizeMaster] : null },
      },
      { catalogId }
    );

    if (!files || files.length === 0) {
      return { master };
    }

    const storageFiles = await Promise.all(
      files.map((file, index) => {
        return apiCoreService.addFile(
          {
            asset_type: 'MASTER_ASSET',
            catalog_id: catalogId,
            parent_id: master.id,
            file: file.file,
          },
          {
            onProgress(event) {
              if (config?.onProgress) {
                config.onProgress(event, index);
              }
            },
          }
        );
      })
    );

    const assets = await Promise.all(
      storageFiles.map(({ data }, index) => {
        return apiCatalogsService.createMasterAsset(
          {
            title: files[index].file.name,
            catalog: catalogId,
            type: files[index].typeId,
            file: data.id,
          },
          { catalogId, masterId: master.id }
        );
      })
    );

    return {
      master,
      assets,
    };
  };

  return useEventCallback(execute);
}
