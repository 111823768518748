import * as React from 'react';
import Header, { HeaderProps } from './components/Header';
import FormikHeader from './components/FormikHeader';

export interface DialogHeaderProps extends HeaderProps {
  listenFormik?: boolean;
  beforeTitleContent?: React.ReactElement;
}

const DialogHeader: React.FC<DialogHeaderProps> = (props) => {
  const { listenFormik = false, ...headerProps } = props;
  if (listenFormik) {
    return <FormikHeader {...headerProps} />;
  }

  return <Header {...headerProps} />;
};

export default DialogHeader;
