import { FC, useCallback, useState } from 'react';
import * as React from 'react';
import * as yup from 'yup';
import * as vsh from 'common/formik/validationSchemas';
import { DialogActions, DialogContent, Grid, Box, makeStyles } from '@material-ui/core';
import Button from 'uikit/button/Button';
import SubmitButton from 'common/formik/SubmitButton';
import FormicTextField from 'common/formik/FormicTextField';
import YearSelectField from 'common/formik/YearSelectField';
import CatalogField from 'common/formik/CatalogField';
import ArtworkField from 'common/formik/ArtworkField';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import { Formik, Form, FormikConfig, FormikHelpers } from 'formik';
import { SomePartial, RequiredFields, Overwrite } from 'common/utilityTypes';
import { CollectionType } from 'common/models/Collection';
import { HUGE_MAX_IMAGE_SIZE } from 'config/network';
import useCreateCollSubmit from 'common/components/Modals/createAlbumDialog/hooks/useCreateCollSubmit';
import useCreateAndAddSubmit from 'common/components/Modals/createAlbumDialog/hooks/useCreateAndAddSubmit';

export interface ICreateAlbumValues {
  // required
  collectionType: CollectionType;
  catalogId: number | undefined;
  title: string;
  artist: string;
  year: number | undefined;
  // optional
  artworkUrl: string | null;
  artworkFileName: string | null;
  artworkFileSize: number;
}

export type ValidatedValues = RequiredFields<
  ICreateAlbumValues,
  'collectionType' | 'catalogId' | 'title' | 'artist' | 'year'
>;

type Actions = FormikHelpers<ICreateAlbumValues>;

export type CreateAlbumDialogProps = Overwrite<
  FormikConfig<ICreateAlbumValues>,
  {
    onClose: () => void;
    onSubmit: (values: ValidatedValues, actions: Actions) => void | Promise<any>;
    initialValues?: Partial<ICreateAlbumValues>;
    autofocus?: 'title' | 'artist' | 'year';
  }
>;

const validationSchema = yup.object().shape({
  catalogId: vsh.catalogRequired,
  title: vsh.titleRequired,
  artist: vsh.artistNameRequired,
  year: vsh.albumYearRequired,
  artworkFileSize: yup.number().max(HUGE_MAX_IMAGE_SIZE, 'File too big'),
});

const useStyles = makeStyles(
  {
    root: {
      maxWidth: 480,
    },
  },
  { name: 'CreateAlbumDialog' }
);

const CreateAlbumDialog: FC<CreateAlbumDialogProps> = ({
  onClose,
  initialValues,
  validationSchema: schema = validationSchema,
  onSubmit,
  autofocus,
  ...formikProps
}) => {
  const classes = useStyles();
  const [artworkLoading, setArtworkLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    (values: ICreateAlbumValues, actions: Actions) => {
      onSubmit(values as ValidatedValues, actions);
    },
    [onSubmit]
  );

  const initValues: ICreateAlbumValues = {
    collectionType: CollectionType.Lp,
    catalogId: -1,
    title: '',
    artist: '',
    year: new Date().getFullYear(),
    artworkUrl: null,
    artworkFileName: null,
    artworkFileSize: 0,
    ...initialValues,
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={initValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        {...formikProps}
      >
        <Form>
          <DialogHeader
            title={
              initValues.collectionType === CollectionType.Lp
                ? 'Create Album'
                : 'Create EP'
            }
            onClose={onClose}
          />
          <DialogContent>
            <Box p={0.5}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CatalogField name="catalogId" dropdownFullWidth />
                </Grid>
                <Grid item xs={12}>
                  <FormicTextField
                    autoFocus={autofocus === 'title'}
                    name="title"
                    label="Title"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormicTextField
                    autoFocus={autofocus === 'artist'}
                    name="artist"
                    label="Artist"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <YearSelectField
                    autoFocus={autofocus === 'year'}
                    name="year"
                    label="Year"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArtworkField
                    artworkName="artworkFileName"
                    onLoadingChange={(loading) => setArtworkLoading(loading)}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined" dialogAction>
              Cancel
            </Button>
            <SubmitButton
              variant="contained"
              color="primary"
              type="submit"
              dialogAction
              disabled={artworkLoading}
            >
              Create
            </SubmitButton>
          </DialogActions>
        </Form>
      </Formik>
    </div>
  );
};

type CreateAlbumDialogContainerProps = SomePartial<CreateAlbumDialogProps, 'onSubmit'>;

export type CreateAlbumConfig<T> = {
  type: T;
  data: {
    props: Omit<CreateAlbumDialogContainerProps, 'onClose'>;
  };
  onCloseAdditionalAction?: () => void;
};

const CreateAlbumDialogContainer = (props: CreateAlbumDialogContainerProps) => {
  const onSubmit = useCreateCollSubmit(props.onClose);

  return <CreateAlbumDialog onSubmit={onSubmit} {...props} />;
};

type CreateCollAndAddMasterDialogProps = SomePartial<
  CreateAlbumDialogProps,
  'onSubmit'
> & { masterId: number };

export type CreateAndAddConfig<T> = {
  type: T;
  data: {
    props: Omit<CreateCollAndAddMasterDialogProps, 'onClose'>;
  };
};

export const CreateCollAndAddMasterDialog = (
  props: CreateCollAndAddMasterDialogProps
) => {
  const { masterId, ...other } = props;
  const onSubmit = useCreateAndAddSubmit(masterId, props.onClose);

  return <CreateAlbumDialog onSubmit={onSubmit} {...other} />;
};

export default CreateAlbumDialogContainer;
