import { ERROR_MESSAGES } from 'config/errorsConfig';
import { notify } from 'common/components/notifMessages/Notify';
import useStorageFreePlace from 'common/hooks/useStorageFreePlace/useStorageFreePlace';

interface UseHasPlaceToStoreRes {
  hasPlaceToStore: (files: File[]) => boolean;
}

const useHasPlaceToStore: () => UseHasPlaceToStoreRes = () => {
  const { freePlace } = useStorageFreePlace();

  const hasPlaceToStore = (files: File[]): boolean => {
    if (files.length === 0) return true;
    const filesSize = files.reduce((accum, file) => accum + file.size, 0);
    const hasPlace = freePlace > filesSize;

    if (!hasPlace) {
      notify.error(ERROR_MESSAGES.storeLimit);
    }

    return hasPlace;
  };

  return { hasPlaceToStore };
};

export default useHasPlaceToStore;
