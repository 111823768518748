import type { IContractParty } from 'common/api/contractModels';
import { ModalConfigActionTypes, modalOpen } from 'redux/modal/modalActions';
import { Middleware } from 'redux';
import {
  GetSplitsheetDocument,
  GetSplitsheetQuery,
  GetSplitsheetQueryVariables,
  SplitPartyMeta,
  UploadedPartyMeta,
  ContractWorkflowState as ContractState,
} from 'generated/graphql';
import * as menu from 'redux/menu/menuActions';
import { isGqlSplitSheet, isGqlUploadedContract } from 'common/api/utils';
import { isSharedSplitSheet, isSharedUploaded } from 'modules/sharing/utils';
import apolloClient from 'apollo/client';
import { IRestApprover } from 'modules/splitSheet/contracts/interfaces/IRestApprover';
import isClickAction from 'redux/menu/utils/isClickAction';
import isDriveContractContext from 'redux/menu/utils/isDriveContractContext';
import isSharedAssetContext from 'redux/menu/utils/isSharedAssetContext';
import MenuContext from 'redux/menu/types/menuContext';
import MenuOption from 'redux/menu/enums/menuOption';

const getSplitsheetUpdate = (id: number) => {
  return apolloClient.query<GetSplitsheetQuery, GetSplitsheetQueryVariables>({
    query: GetSplitsheetDocument,
    variables: { id },
    fetchPolicy: 'network-only',
  });
};

const getNextAction = async (context: MenuContext) => {
  if (isDriveContractContext(context)) {
    const [contract] = context.data;

    if (isGqlSplitSheet(contract)) {
      const { data } = await getSplitsheetUpdate(contract.id);
      const splitsheet = data?.getContract;
      return splitsheet
        ? modalOpen({
            type: ModalConfigActionTypes.SPLIT_SHEET,
            data: {
              title: splitsheet.title,
              aka: splitsheet.metadata?.aka || '',
              date: splitsheet.date,
              state: (splitsheet.state as unknown) as ContractState,
              approvers: splitsheet.parties
                .filter((party) => party?.role === 'APPROVER')
                .map(
                  (party) =>
                    ({
                      id: party.id,
                      name: party.name,
                      email: party.contact,
                      role_label: party.role_label,
                      affiliation: (party.metadata as SplitPartyMeta).affiliation,
                      ownership: (party.metadata as SplitPartyMeta).ownership,
                      accepted: party.accepted,
                      can_see_others: true,
                    } as IRestApprover)
                ),
            },
          })
        : undefined;
    }

    if (isGqlUploadedContract(contract)) {
      return modalOpen({
        type: ModalConfigActionTypes.UPLOAD_CONTRACT_INFO,
        data: {
          id: contract.id,
          title: contract.title,
          description: contract.metadata?.description || '',
          contractType: contract.type,
          file: {
            id: contract?.file?.id,
            fileName: contract?.file?.file_name,
            downloadUrl: contract?.file?.media_url,
          },
          catalogId: context.catalogId,
          masterId: contract.master?.id || 0,
          parties: contract.parties.map(
            (party) =>
              ({
                id: String(party.id),
                name: party.name,
                email: party.contact,
                partyType: party.role_label,
                advance: (party.metadata as UploadedPartyMeta).advance,
                royaltyLabel: (party.metadata as UploadedPartyMeta).royalty_label,
                royalty: (party.metadata as UploadedPartyMeta).royalty,
                recoup: (party.metadata as UploadedPartyMeta).recoup,
                recoupLabel: (party.metadata as UploadedPartyMeta).recoup_label,
                publishing: (party.metadata as UploadedPartyMeta).publishing,
              } as IContractParty)
          ),
        },
      });
    }
  }

  if (isSharedAssetContext(context)) {
    const [contract] = context.data;

    if (isSharedSplitSheet(contract)) {
      return modalOpen({
        type: ModalConfigActionTypes.SPLIT_SHEET,
        data: {
          ...contract,
          aka: contract.aka || '',
        },
      });
    }

    if (isSharedUploaded(contract)) {
      return modalOpen({
        type: ModalConfigActionTypes.UPLOAD_CONTRACT_INFO,
        data: {
          ...contract,
          masterId: contract.masterId || 0,
          description: contract.description || '',
        },
      });
    }
  }
};

const info: Middleware = (store) => (next) => async (action) => {
  if (isClickAction(action)) {
    const { option, context } = action.payload;
    if (option === MenuOption.VIEW) {
      const action = await getNextAction(context);

      if (action) {
        store.dispatch(action);
      }

      return next(menu.close());
    }
  }

  return next(action);
};

export default info;
