import apiCoreService from 'api/core/apiCoreService';
import apiShareService from 'api/share/apiShareService';
import { AttachBody } from 'common/api/models';

type AddFileInput = Omit<AttachBody, 'file_name'> & {
  file: File;
};

async function addFile(
  slug: string,
  input: AddFileInput,
  config?: {
    onProgress(event: ProgressEvent): void;
  }
) {
  const { file, ...fileUpload } = input;
  const { data: link } = await apiShareService.getStorageLink(slug, {
    file_name: file.name,
  });
  await apiCoreService.uploadFile(
    { link, file },
    { onUploadProgress: config?.onProgress }
  );

  return apiShareService.attachFile(slug, { file_name: link.file_name, ...fileUpload });
}

export default {
  addFile,
};
