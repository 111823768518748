import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IContractParty } from 'common/api/contractModels';
import {
  IUContractAction,
  setPartyRecoupAction,
  setPartyRecoupLabelAction,
} from 'common/components/Modals/contractAgreementFormDialog/contractAgreementFormState';
import Select from 'uikit/select/Select';
import React from 'react';
import TextField from 'uikit/textField/TextField';

const useStyles = makeStyles((theme: Theme) => ({
  inputData: {
    width: '100%',
  },
}));

interface IPartyRecoupSection {
  party: IContractParty;
  dispatch: React.Dispatch<IUContractAction>;
}

const PartyRecoupSection: React.FC<IPartyRecoupSection> = ({ party, dispatch }) => {
  const classes = useStyles();

  return (
    <>
      <Select
        dropdownFullWidth
        closeOnClick
        text={party.recoupLabel}
        containerStyle={{ width: '100%' }}
        variant={'label'}
      >
        {['Recoup, %', 'Recoup, $'].map((recoupType) => (
          <MenuItem
            value={recoupType}
            key={recoupType}
            onClick={() => dispatch(setPartyRecoupLabelAction(party.id, recoupType))}
          >
            {recoupType}
          </MenuItem>
        ))}
      </Select>

      <TextField
        value={(party.recoup || 0).toString()}
        onChange={(e) =>
          dispatch(setPartyRecoupAction(party.id, Number(e.target.value) || 0))
        }
        inputProps={{
          type: 'number',
          min: 0,
          max: 100000000,
          step: 1,
        }}
        className={classes.inputData}
      />
    </>
  );
};

export default PartyRecoupSection;
