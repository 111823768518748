const getTokenFromSearchParams = (value: string): string | undefined => {
  const params = value
    .substr(1)
    .split('&')
    .reduce((accum, val) => {
      const data = val.split('=');
      return {
        ...accum,
        [data[0]]: data[1],
      };
    }, {});
  return params['token'];
};

export default getTokenFromSearchParams;
