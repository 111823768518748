import * as RMActions from 'modules/roles/redux/actions';

type State = {
  filters: {
    search: null | string;
  };
};

const initState: State = {
  filters: {
    search: null,
  },
};

export function roleManagerReducer(state = initState, action: RMActions.Action) {
  if (action.type === RMActions.ActionType.SET_FILTRER) {
    return {
      filters: {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      },
    };
  }

  if (action.type === RMActions.ActionType.RESET_FILTER) {
    return {
      filters: {
        ...state.filters,
        [action.payload.name]: null,
      },
    };
  }

  return state;
}
