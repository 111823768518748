import React, { FC } from 'react';
import clsx from 'clsx';
import { fade, makeStyles } from '@material-ui/core/styles';
import useContainerAnimationStyles from 'uikit/preloader/hooks/useContainerAnimationStyles';
import useContainerProgressStyles from 'uikit/preloader/hooks/useContainerProgressStyles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      minWidth: 156,
      minHeight: 150,
    },
    fillViewport: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
    },
    fillParent: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: theme.zIndex.snackbar,
      overflow: 'hidden',
    },
    dim: {
      backgroundColor: fade(theme.palette.common.black, 0.5),
    },
    fog: {
      backgroundColor: fade(theme.palette.common.white, 0.5),
    },
    container: {
      height: 69,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    lines: {
      width: 62,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    line: {
      position: 'relative',
      width: 10,
      minHeight: 10,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 5,
    },
    lineContent: {
      position: 'absolute',
      left: 1,
      bottom: 1,
      width: 0,
      borderWidth: 3,
      borderRadius: 5,
      borderStyle: 'solid',
      maxHeight: 'calc(100% - 2px)',
    },
    lineContentFull: {
      height: '100%',
    },
    primary: {
      color: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    info: {
      marginLeft: theme.spacing(1),
      position: 'relative',
      top: -1,
    },
  }),
  { name: 'Preloader' }
);

export interface PreloaderProps {
  color?: 'primary' | 'secondary' | 'inherit';
  progress?: number;
  fillViewport?: boolean;
  fillParent?: boolean;
  dim?: boolean;
  fog?: boolean;
  className?: string;
}

const Preloader: FC<PreloaderProps> = ({
  color = 'primary',
  className,
  progress,
  fillViewport = false,
  fillParent = false,
  dim = false,
  fog = false,
}) => {
  const animationStyles = useContainerAnimationStyles();
  const progressStyles = useContainerProgressStyles();
  const additionalClasses = progress === undefined ? animationStyles : progressStyles;
  const classes = useStyles();
  const linesCount = 5;
  const borderColor = color !== 'inherit' ? classes[color] : false;
  return (
    <div
      className={clsx(
        classes.root,
        !!className && className,
        fillViewport && classes.fillViewport,
        fillParent && classes.fillParent,
        dim && classes.dim,
        fog && classes.fog
      )}
    >
      <div className={classes.container}>
        <div className={classes.lines}>
          {[...Array(linesCount)].map((_, i) => (
            <div
              className={clsx(
                classes.line,
                additionalClasses[`line${i + 1}`],
                borderColor
              )}
              key={`preloader_${i}`}
            >
              <div
                className={clsx(
                  classes.lineContent,
                  progress === undefined && classes.lineContentFull
                )}
                style={{ height: `${progress}%` }}
              />
            </div>
          ))}
        </div>
        {progress && <div className={clsx(classes.info, borderColor)}>{progress}%</div>}
      </div>
    </div>
  );
};

export default Preloader;
