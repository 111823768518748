import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import DELETE from 'common/services/httpServices/delete';
import constructPath from 'api/utils/constructPath';

interface DeleteAssetProps {
  assetId: number;
}

const deleteAsset: (props: DeleteAssetProps) => Promise<boolean> = async ({
  assetId,
}) => {
  return await DELETE(
    constructPath(CatalogsPaths.DeleteAsset, { assetId: assetId.toString() })
  );
};

export default deleteAsset;
