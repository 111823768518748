import {
  RemoveMasterFromCollectionMutationOptions,
  RemoveMasterFromCollectionDocument,
} from 'generated/graphql';
import apolloClient from 'apollo/client';

export function removeMasterFromCollectionMutation(
  options?: Omit<RemoveMasterFromCollectionMutationOptions, 'mutation'>
) {
  return apolloClient.mutate({
    ...options,
    mutation: RemoveMasterFromCollectionDocument,
  });
}
