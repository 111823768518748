import gqlTypesVersion from 'generated/graphqlTypesVersion';

export const NODE_NAME_MAX = 64;

export const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME || 'ampToken';

export const TERMS_AND_CONDITIONS_URL = 'https://tullyapp.com/terms-condition.html';
export const PRICING_URL = 'https://tullyapp.com/level-up-to-solo.html';

export const PERSIST_CACHE = process.env.REACT_APP_PERSIST_CACHE;

export const APOLLO_CACHE_KEY = 'amp-apollo-cache-persist';

export const APOLLO_CACHE_SIZE = 2097152; // 2 MiB

export const GQL_SCHEMA_VERSION = gqlTypesVersion;

export const GQL_SCHEMA_VERSION_KEY = 'amp-gql-schema-version';

export const ADVANCED_FILTERS_STORAGE_KEY = 'AdvancedSearchActiveFilter';
