import type { UserActions } from 'redux/user/userActions';
import { UserActionTypes } from 'redux/user/userActions';
import UserState from 'redux/user/interfaces/userState';
import INIT_USER_STATE from 'redux/user/configs/initUserState';

function userReducer(state = INIT_USER_STATE, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.Request:
    case UserActionTypes.Patch:
    case UserActionTypes.SetLoading:
      return { ...state, loading: true };
    case UserActionTypes.Success:
      return { loading: false, error: null, data: action.payload.data };
    case UserActionTypes.Failure:
      return { loading: false, data: null, error: action.error };
    default:
      return state;
  }
}

export default userReducer;
