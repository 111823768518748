const storageCapacityShortener = (capacity?: number | null) => {
  if (!capacity) return;
  if (capacity >= Math.pow(10, 12)) {
    return `${(capacity / Math.pow(10, 12)).toFixed(1)}TB`;
  }
  if (capacity >= Math.pow(10, 9)) {
    return `${(capacity / Math.pow(10, 9)).toFixed(1)}GB`;
  }
  if (capacity >= Math.pow(10, 6)) {
    return `${(capacity / Math.pow(10, 6)).toFixed(1)}MB`;
  }
  if (capacity >= Math.pow(10, 3)) {
    return `${(capacity / Math.pow(10, 3)).toFixed(1)}kB`;
  }
  return `${capacity.toFixed(1)}B`;
};

export default storageCapacityShortener;
