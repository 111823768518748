const DEFAULT_SELECTED_PROPS = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: '192px',
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
} as const;

export default DEFAULT_SELECTED_PROPS;
