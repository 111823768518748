import { uniqBy } from 'lodash';
import { FieldPolicy, FieldMergeFunction } from '@apollo/client/cache';
import { offsetLimitPagination } from '../pagination';

const mergePages = offsetLimitPagination().merge as FieldMergeFunction;

const CatalogMasters: FieldPolicy = {
  keyArgs: (args, context) => {
    const isNewMasterQuery = typeof context.variables?.newMasterId === 'number';

    if (args?.where && !isNewMasterQuery) {
      return `${context.fieldName}(${JSON.stringify(args)})`;
    }

    return context.fieldName;
  },
  merge(existing, incoming, options) {
    const isNewMasterQuery = typeof options.variables?.newMasterId === 'number';

    if (options.args?.where && isNewMasterQuery) {
      return uniqBy([...incoming, ...existing], '__ref');
    }

    if (options.args?.page) {
      return uniqBy(mergePages(existing, incoming, options), '__ref');
    }

    return incoming;
  },
};

export default CatalogMasters;
