import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useGetCurrentUserQuery, useGetUmRolesQuery } from 'generated/graphql';
import useGetGuestP9s from 'modules/app/hooks/useGetGuestP9s';
import mapGuestPermissions from 'modules/app/utils/mapGuestPermissions';
import * as P9s from 'redux/permissions/permissionsActions';
import maxPermissions from 'redux/permissions/utils/maxPermissions';
import getPerms from 'redux/permissions/utils/getPerms';
import ownerCatalogPerms from 'redux/permissions/utils/ownerCatalogPerms';
import catalogIndex from 'redux/permissions/utils/catalogIndex';

const useGetPermissions = () => {
  const dispatch = useDispatch();
  const { data: dataUser } = useGetCurrentUserQuery();
  const { data: dataRoles } = useGetUmRolesQuery();
  const { data: dataGuest } = useGetGuestP9s();
  const user = dataUser?.getCurrentUser;

  const onUserLoad = () => {
    if (!user?.isAdmin) {
      return;
    }

    dispatch(
      P9s.setHardPermissions({
        value: maxPermissions(),
      })
    );
  };

  const onGuestLoad = () => {
    if (!user?.isGuest || !dataGuest) {
      return;
    }

    const value = mapGuestPermissions(dataGuest);

    dispatch(
      P9s.setGuestPermissions({
        value,
      })
    );
  };

  const onRolesLoad = () => {
    if (!user || !dataRoles || user.isGuest || user.isAdmin) {
      return;
    }

    const roles = dataRoles?.umRoles?.results || [];
    const userCatalog = dataUser?.getCatalogs?.find(
      (catalog) => catalog && catalog.user.id === user.id
    );
    const permsList = getPerms(roles);
    const ownPermsList = ownerCatalogPerms(userCatalog?.id);
    const allPerms = ownPermsList.concat(permsList);
    const value = allPerms.length ? catalogIndex(allPerms) : null;

    dispatch(
      P9s.setUserPermissions({
        value,
      })
    );
  };

  useEffect(onUserLoad, [dataUser]);
  useEffect(onRolesLoad, [dataUser, dataRoles]);
  useEffect(onGuestLoad, [dataUser, dataGuest]);
};

export default useGetPermissions;
