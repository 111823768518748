import CatalogIndex from 'redux/permissions/types/catalogIndex';
import CatalogPerms from 'redux/permissions/interfaces/catalogPerms';

const catalogIndex = (catalogs: CatalogPerms[]) => {
  return catalogs.reduce<CatalogIndex>((acc, catalog) => {
    acc[catalog.catalogId] = catalog;
    return acc;
  }, {});
};

export default catalogIndex;
