import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .MuiIconButton-root': {
      cursor: 'pointer',
    },
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export const IconButtonGroup = (props) => <Box className={useStyles().root} {...props} />;
