import React, { FC, ReactElement, useState } from 'react';
import { DialogActions, DialogContent, Box, Grid } from '@material-ui/core';
import { Form, Formik, FormikConfig } from 'formik';
import { makeStyles } from '@material-ui/core';
import Button from 'uikit/button/Button';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import EditAlbumValues from '../interfaces/editAlbumValues';
import EditAlbumActions from '../types/editAlbumActions';
import ValidatedValues from '../types/validatedValues';
import albumValidationSchema from '../utils/albumValidationSchema';
import ArtworkField from 'common/formik/ArtworkField';
import SubmitButton from 'common/formik/SubmitButton';
import FormicTextField from 'common/formik/FormicTextField';
import TrackListField from 'common/formik/trackListField/TrackListField';
import YearSelectField from 'common/formik/YearSelectField';
import { Overwrite } from 'common/utilityTypes';
import Typography from 'uikit/typography/Typography';
import { useGetMastersByIdQuery } from 'generated/graphql';
import Preloader from 'uikit/preloader/Preloader';
import { notify } from 'common/components/notifMessages/Notify';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      display: 'flex',
    },
    form: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    trackListWrap: {
      [theme.breakpoints.up('md')]: {
        overflowY: 'auto',
      },
    },
    trackList: {
      [theme.breakpoints.up('md')]: {
        height: '100%',
      },
    },
  }),
  { name: 'EditAlbumDialogContent' }
);

export type EditAlbumDialogContentProps = Overwrite<
  FormikConfig<EditAlbumValues>,
  {
    onClose: () => void;
    onSubmit: (values: ValidatedValues, actions: EditAlbumActions) => void | Promise<any>;
  }
>;

const EditAlbumDialogContent: FC<EditAlbumDialogContentProps> = ({
  onClose,
  onSubmit,
  ...formikProps
}): ReactElement => {
  const classes = useStyles();
  const [artworkLoading, setArtworkLoading] = useState<boolean>(false);
  const handleSubmit = React.useCallback(
    (values: EditAlbumValues, actions: EditAlbumActions) => {
      onSubmit(values as ValidatedValues, actions);
    },
    [onSubmit]
  );
  // Additionally get info for masters. Only user for masters menu
  const mastersIds = formikProps.initialValues.trackOrder;
  const { loading, error, data } = useGetMastersByIdQuery({
    variables: {
      ids: mastersIds,
    },
  });

  const masters = data?.getMasters;
  const mastersById = {};
  if (masters) {
    for (const master of masters) {
      if (master) {
        mastersById[master.id] = master;
      }
    }
  }
  formikProps.initialValues.masters = mastersById;

  if (error) {
    notify.enqueueSnackbar(
      'Error occurred while loading collection info. Please try again',
      {
        variant: 'error',
      }
    );
    onClose();
  }
  return (
    <div className={classes.root}>
      {loading ? (
        <Preloader />
      ) : (
        <Formik
          {...formikProps}
          onSubmit={handleSubmit}
          validationSchema={albumValidationSchema}
        >
          <Form className={classes.form}>
            <DialogHeader onClose={onClose}>Edit Album</DialogHeader>
            <DialogContent>
              <Box overflow="hidden">
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={12} md={6} container spacing={1}>
                    <Grid item xs={12}>
                      <FormicTextField name="title" label="Title" fullWidth />
                    </Grid>
                    <Grid item xs={8}>
                      <FormicTextField name="artist" label="Artist" fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                      <YearSelectField name="year" label="Year" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <ArtworkField
                        artworkName="artworkFileName"
                        onLoadingChange={(loading) => setArtworkLoading(loading)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} container direction="column" spacing={0}>
                    <Grid item xs="auto">
                      <Box mb={0.5}>
                        <Typography variant="subtitle2">Tracklist Order</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs className={classes.trackListWrap}>
                      <TrackListField name="trackOrder" className={classes.trackList} />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" dialogAction>
                Cancel
              </Button>
              <SubmitButton
                variant="contained"
                color="primary"
                type="submit"
                dialogAction
                disabled={artworkLoading}
              >
                Save
              </SubmitButton>
            </DialogActions>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default EditAlbumDialogContent;
