import * as React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import LinearProgress from 'uikit/linearProgress/LinearProgress';
import Button from 'uikit/button/Button';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import { createProjectDialogReducer, init, InitArgs, ModalActions } from './reducer';
import CatalogSelect from 'common/components/catalogSelect/CatalogSelect';
import DNDArea from 'uikit/dragAndDropArea/DragAndDropArea';
import useEdiphyState from 'uikit/ediphy/hooks/useEdiphyState';
import clsx from 'clsx';
import Typography, { ITypographyProps } from 'uikit/typography/Typography';
import { DeleteIconOld } from 'common/icons/system';
import { CatalogName } from 'common/models/Catalog';
import useHasPlaceToStore from 'common/hooks/useHasPlaceToStore/useHasPlaceToStore';
import { useDispatch } from 'react-redux';
import { notify } from 'common/components/notifMessages/Notify';
import apiCoreService from 'api/core/apiCoreService';
import apiCatalogService from 'api/catalogs/apiCatalogsService';
import { ContentType } from 'common/api/models';
import EditableTitle from 'uikit/editableTitle/EditableTitle';
import { setFilter } from 'redux/filters/filtersActions';
import Filter from 'redux/filters/enums/filter';
import AmpService from 'common/api/AmpService';

const useStyles = makeStyles(
  (theme) => ({
    catalogSelect: {
      marginBottom: theme.spacing(2),
    },
    editableFileTile: {
      display: 'inline-block',
    },
    content: {},
    fileBox: {
      maxWidth: '100%',
      paddingBottom: theme.spacing(2),
    },
    item: {
      display: 'flex',
      minHeight: 32,
      marginTop: theme.spacing(2),
      alignItems: 'center',
    },
    itemGutterBottom: {
      paddingBottom: theme.spacing(0.5),
    },
  }),
  { name: 'CreateProjectDialog' }
);

export type ModalMasterUploadProps = {
  onClose: () => void;
  onCreate: (projectId: string) => void;
  config: InitArgs;
};

const CreateProjectDialog: React.FC<ModalMasterUploadProps> = (props) => {
  const { onClose, config, onCreate } = props;
  const { hasPlaceToStore } = useHasPlaceToStore();
  const [state, dispatch] = React.useReducer(createProjectDialogReducer, config, init);
  const reduxDispatch = useDispatch();
  const [ediphyProjectName, ediphyProjectNameProps] = useEdiphyState(
    state.projectName,
    (_, next) => {
      dispatch({
        type: 'SET_PROJECT_NAME',
        payload: next,
      });
    }
  );
  const [ediphyBeatTitle, ediphyBeatTitleProps] = useEdiphyState(
    state.beatTitle,
    (_, next) => {
      dispatch({
        type: 'SET_BEAT_TITLE',
        payload: next,
      });
    }
  );

  const isUploadingState = state.isUploadingState;
  const entry = state.file || undefined;
  const disableSubmit =
    isUploadingState || ediphyProjectName.editing || ediphyBeatTitle.editing;
  const classes = useStyles();

  const modalActions = React.useMemo<ModalActions>(
    () => ({
      close: () => onClose && onClose(),
      setError: (error: any) =>
        dispatch({
          type: 'SET_ERROR',
          payload: error,
        }),
      setProgress: (progress) =>
        dispatch({
          type: 'SET_PROGRESS',
          payload: progress,
        }),
      setUploading: (state) =>
        dispatch({
          type: 'SET_UPLOADING',
          payload: state,
        }),
    }),
    [dispatch, onClose]
  );

  const handleCatalogChange = React.useCallback(
    (entry: CatalogName) =>
      dispatch({
        type: 'SET_CATALOG',
        payload: entry,
      }),
    [dispatch]
  );

  const handleBeatTitleChange = React.useCallback(
    (beatTitle: string) => {
      dispatch({
        type: 'SET_BEAT_TITLE',
        payload: beatTitle,
      });
    },
    [dispatch]
  );

  const handleFileChange = React.useCallback(
    (files: FileList | null) => {
      const file = files ? Array.from(files)?.[0] : null;
      if (file) {
        dispatch({
          type: 'SET_FILE',
          payload: file,
        });
        handleBeatTitleChange(file.name);
      }
    },
    [dispatch, handleBeatTitleChange]
  );

  const handleFileRemove = () => () =>
    dispatch({
      type: 'REMOVE_FILE',
    });

  const handleSubmit = async () => {
    if (!hasPlaceToStore(state.file ? [state.file.file] : [])) {
      notify.enqueueSnackbar(`Error: There is not enough place in your storage`, {
        variant: 'error',
      });
      return;
    }

    const project = await apiCatalogService
      .createProject(
        {
          title: state.projectName,
          catalog: state.catalogId,
        },
        { catalogId: state.catalogId }
      )
      .catch(() => {
        notify.enqueueSnackbar(
          'There has been a problem while creating project. Please try again',
          { variant: 'error' }
        );
      });

    if (!project) {
      return;
    }

    if (state.file) {
      try {
        const onProgress = (event: ProgressEvent) => {
          const progress = Math.floor((event.loaded / event.total) * 100);
          modalActions.setProgress(progress);
        };
        modalActions.setUploading(true);

        const uploadResult = await apiCoreService.addFile(
          {
            file: state.file.file,
            asset_type: 'PROJECT_ASSET',
            catalog_id: state.catalogId,
            parent_id: project.id,
          },
          { onProgress }
        );
        const fileId = uploadResult.data.id;

        await apiCatalogService.createProjectAsset(
          {
            type: ContentType.PROJECT_BEAT,
            catalog: state.catalogId,
            title: state.beatTitle,
            file: fileId,
          },
          { catalogId: state.catalogId, projectId: project.id }
        );
      } catch (error) {
        notify.enqueueSnackbar(
          "There has been a problem processing your beat file and it wasn't added to your project",
          { variant: 'error' }
        );
      }
    }
    await AmpService.queryNewProject({
      catalogId: project.catalog,
      newProjectId: project.id,
    });

    reduxDispatch(
      setFilter({
        filter: Filter.catalog,
        value: { id: state.catalogId, name: state.catalogName },
      })
    );

    onCreate(project.id.toString());
    modalActions.close();
  };

  const common: ITypographyProps = {
    component: 'span',
  };

  return (
    <>
      <DialogHeader
        submitting={isUploadingState}
        onClose={onClose}
        title={state.projectName}
        EditableTitleProps={{
          typographyProps: common,
          ediphyProps: {
            ...ediphyProjectNameProps,
            ...common,
            oneLineText: true,
            disableIconHide: true,
          },
        }}
        editable={true}
      />
      <DialogContent className={classes.content}>
        <div className={classes.catalogSelect}>
          <CatalogSelect
            disabled={isUploadingState}
            onChange={handleCatalogChange}
            selectedId={state.catalogId}
            valuePersistence={false}
          />
        </div>
        {!state.file && (
          <DNDArea
            label={'Beat'}
            onChange={handleFileChange}
            inputProps={{ accept: state.accept }}
          />
        )}
        {entry && (
          <div className={classes.fileBox}>
            <div>
              <Box
                className={clsx(classes.item, {
                  [classes.itemGutterBottom]: !isUploadingState,
                })}
                flexWrap="nowrap"
              >
                <Box flex="1 1 auto" minWidth={0} maxHeight={32}>
                  <EditableTitle
                    disabled={isUploadingState}
                    typographyProps={common}
                    ediphyProps={{
                      ...ediphyBeatTitleProps,
                      ...common,
                      oneLineText: true,
                      disableIconHide: true,
                      outlined: false,
                    }}
                    variant={'subtitle2'}
                  />
                </Box>
                <Box flex="0 0 auto">
                  <Box display="flex" pl={1}>
                    {isUploadingState ? (
                      <Typography variant="body2">{entry.progress}%</Typography>
                    ) : (
                      <IconButton size="small" onClick={handleFileRemove()}>
                        <DeleteIconOld />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Box>
              {isUploadingState && (
                <Box>
                  <LinearProgress value={entry.progress} variant="determinate" />
                </Box>
              )}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          dialogAction
          disabled={isUploadingState}
        >
          Cancel
        </Button>
        <Button
          disabled={disableSubmit}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          dialogAction
        >
          Add Project
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateProjectDialog;
