import { Routes } from 'navigation/enums/routes';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import useHasAccessToDrive from 'common/hooks/useHasAccessToDrive';
import { useCallback, useMemo } from 'react';
import { catalogSelector } from 'redux/filters/filtersSelectors';

const useCatalogRoutesPermissions: () => void = () => {
  const locations: string[] = [Routes.Masters, Routes.Projects, Routes.Beats];

  const location = useLocation();

  // added to trigger hook on catalog change
  useSelector(catalogSelector);

  const history = useHistory();
  const { isHasBeats, isHasMasters, isHasProjects } = useHasAccessToDrive();

  const routesPermissions = useMemo(
    () => ({
      [Routes.Masters]: isHasMasters,
      [Routes.Projects]: isHasProjects,
      [Routes.Beats]: isHasBeats,
    }),
    [isHasBeats, isHasMasters, isHasProjects]
  );
  const goToFirstAvailable = useCallback(() => {
    const firstAvailablePath = Object.keys(routesPermissions).find(
      (key) => routesPermissions[key]
    );
    if (firstAvailablePath) history.push(firstAvailablePath);
  }, [history, routesPermissions]);

  if (locations.includes(location.pathname)) {
    if (!routesPermissions[location.pathname]) goToFirstAvailable();
  }
};

export default useCatalogRoutesPermissions;
