import React, { FC, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from 'uikit/typography/Typography';

const useStyles = makeStyles(
  (theme) => ({
    itemName: {
      color: theme.palette.secondary.main,
    },
  }),
  { name: 'SharingItemInfo' }
);

interface SharingItemInfoProps {
  itemName: string;
}

const SharingItemInfo: FC<SharingItemInfoProps> = ({ itemName }): ReactElement => {
  const classes = useStyles();

  return (
    <Typography variant="body2">
      You are sharing <span className={classes.itemName}>{itemName}</span>
    </Typography>
  );
};

export default SharingItemInfo;
