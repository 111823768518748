import { FC } from 'react';
import * as React from 'react';
import Button, { ButtonPropsExtended } from 'uikit/button/Button';
import { useFormikContext } from 'formik';
import { CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
  { name: 'SubmitButton' }
);

interface SubmitButtonProps extends ButtonPropsExtended {
  // Use this flag only when button is not
  // descendant of the form.
  submitOnClick?: boolean;
  checkDirty?: boolean;
  disabled?: boolean;
  showProgress?: boolean;
}

const SubmitButton: FC<SubmitButtonProps> = ({
  submitOnClick,
  checkDirty = false,
  disabled = false,
  showProgress = false,
  children,
  ...props
}) => {
  const { isSubmitting, isValid, submitForm, dirty } = useFormikContext();
  const notDirty = checkDirty && !dirty;
  const isDisabled = disabled || !isValid || isSubmitting || notDirty;
  const classes = useStyles();

  return (
    <Button
      type="submit"
      {...props}
      className={clsx(classes.root, props.className)}
      onClick={submitOnClick ? submitForm : undefined}
      disabled={isDisabled}
    >
      {children}
      {isSubmitting && showProgress && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
};

export default SubmitButton;
