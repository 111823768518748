export const BASE_URL = `${process.env.REACT_APP_BACKEND_HOST}/api`;
export const DISTRIBUTION_URL = process.env.REACT_APP_DISTRIBUTION_URL
  ? `${process.env.REACT_APP_DISTRIBUTION_URL}`
  : undefined;
export const USERS = 'users';
export const USERS_LOGIN = 'users/login';
export const PASSWORD_RESET_REQUEST = `${USERS}/password/reset_request`;
export const PASSWORD_CHANGE_REQUEST = `${USERS}/password/reset`;
export const ACCEPT_INVITE = `${USERS}/invite/accept`;
export const SIGN_UP = `${USERS}/`;

export enum NO_VALUE {
  CATALOG_ID = Infinity,
  PARENT_ID = Infinity,
}
