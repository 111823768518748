import FileUploadState from 'redux/fileUpload/types/fileUploadState';
import DriveItem from 'redux/selectedFile/interfaces/driveItem';
import SharingItem from 'redux/selectedFile/interfaces/sharingItem';
import isProjectState from 'redux/fileUpload/utils/isProjectState';
import FileBrowserItemType from 'common/enums/fileBrowserItemType';
import isMasterAssetState from 'redux/fileUpload/utils/isMasterAssetState';

const isBelongToParent = (
  state: FileUploadState,
  parent: DriveItem | SharingItem
): boolean => {
  if (isProjectState(state) && typeof state.projectId !== 'undefined') {
    return parent?.__itemname === FileBrowserItemType.Drive
      ? state.projectId === parent.projectId
      : state.projectId === parent.parentId;
  }

  if (isMasterAssetState(state) && typeof state.masterId !== 'undefined') {
    return parent?.__itemname === FileBrowserItemType.Drive
      ? state.masterId === parent.masterId
      : state.masterId === parent.parentId;
  }

  return false;
};

export default isBelongToParent;
