import { useAccountResourcesQuery } from 'generated/graphql';
import isResourceSecurityEnabled from 'common/components/Modals/shareDialog/utils/isResourceSecurityEnabled';

export interface UseShareResourceRes {
  isResourcesLoading: boolean;
  is2FAControlEnabled: boolean;
}

const useShareResource: () => UseShareResourceRes = () => {
  const {
    data: dataAccountResources,
    loading: isResourcesLoading,
  } = useAccountResourcesQuery();
  const is2FAControlEnabled = isResourceSecurityEnabled(dataAccountResources);

  return {
    isResourcesLoading,
    is2FAControlEnabled,
  };
};

export default useShareResource;
