import React, { FC } from 'react';

import ContentBox from 'uikit/contentBox/ContentBox';
import ContentContainer from 'uikit/contentContainer/ContentContainer';
import ContentHeader from 'uikit/contentHeader/ContentHeader';
import NoItems from 'uikit/noItems/NoItems';

const ErrorPage: FC = () => {
  return (
    <ContentBox>
      <ContentContainer>
        <ContentHeader noWrap>Oops, looks like something went wrong.</ContentHeader>
        <NoItems align="left">Please try again, or contact Tully Support.</NoItems>
      </ContentContainer>
    </ContentBox>
  );
};

export default ErrorPage;
