import { useRef, useState } from 'react';
import * as React from 'react';
import {
  Box,
  makeStyles,
  FormControl,
  FormGroup,
  FormControlLabel,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import LinearProgress from 'uikit/linearProgress/LinearProgress';
import Button from 'uikit/button/Button';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import {
  masterUploadModalReducer,
  init,
  Version,
  ModalSubmitHandler,
  ModalValues,
  ModalActions,
  InitArgs,
} from './reducer';
import CatalogSelect from 'common/components/catalogSelect/CatalogSelect';
import DNDArea from 'uikit/dragAndDropArea/DragAndDropArea';
import useEdiphyState from 'uikit/ediphy/hooks/useEdiphyState';
import clsx from 'clsx';
import Typography, { ITypographyProps } from 'uikit/typography/Typography';
import { IconButton } from '@material-ui/core';
import { DeleteIconOld } from 'common/icons/system';
import { ContentType } from 'common/api/models';
import Radio from 'uikit/radio/Radio';
import { CatalogName } from 'common/models/Catalog';
import getNewFilename from 'common/utils/filename/getNewFilename';
import useHasPlaceToStore from 'common/hooks/useHasPlaceToStore/useHasPlaceToStore';
import { mastersSectionContentSelector } from 'redux/filters/filtersSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { ModalConfigActionTypes, modalOpen } from 'redux/modal/modalActions';
import MastersSectionContent from 'redux/filters/enums/mastersSectionContent';
import { useGetCurrentUserQuery } from 'generated/graphql';

const useStyles = makeStyles(
  (theme) => ({
    catalogSelect: {
      marginBottom: theme.spacing(2),
    },
    editableFileTile: {
      display: 'inline-block',
    },
    content: {},
    fileBox: {
      maxWidth: '100%',
      paddingBottom: theme.spacing(2),
    },
    item: {
      display: 'flex',
      minHeight: 32,
      marginTop: theme.spacing(2),
      alignItems: 'center',
    },
    itemGutterBottom: {
      paddingBottom: theme.spacing(0.5),
    },
  }),
  { name: 'ModalMasterUpload' }
);

export type ModalMasterUploadProps = {
  onClose: () => void;
  onSubmit: ModalSubmitHandler;
  config: InitArgs;
};

const ModalMasterUpload: React.FC<ModalMasterUploadProps> = (props) => {
  const { onClose, onSubmit, config } = props;
  const { hasPlaceToStore } = useHasPlaceToStore();
  const [state, dispatch] = React.useReducer(masterUploadModalReducer, config, init);
  const [uploadingFileTitle, setUploadingFileTitle] = useState<string>('');
  const initName = useRef<string>(state.masterName);
  const [ediphy, ediphyProps] = useEdiphyState(state.masterName, (_, next) => {
    setUploadingFileTitle(initName.current === next ? '' : next);
    dispatch({
      type: 'SET_MASTER_NAME',
      payload: next,
    });
  });

  const { isUploadingState, isMax } = state;
  const entries = state.files || [];
  const isImport = state.masterId !== -1;
  const isEmptyValue = state.files.length === 0;
  const disableSubmit = (isImport && isEmptyValue) || isUploadingState || ediphy.editing;
  const isDisabled = isUploadingState || ediphy.editing;
  const isRadioDisabled = isDisabled || !!state.strictVersion;
  const classes = useStyles();
  const modalDispatch = useDispatch();
  const mastersContent = useSelector(mastersSectionContentSelector);
  const { data: dataUser } = useGetCurrentUserQuery();
  const user = dataUser?.getCurrentUser;

  const addCustomizeMaster = (type: MastersSectionContent): (() => void) => () => {
    const customazeValue = config.customizeMaster || {
      realityAudio: true,
      stemFiles: true,
      contracts: true,
      metadata: true,
      media: true,
      userId: user?.id,
      isAdmin: user?.isAdmin,
    };

    modalDispatch(
      modalOpen({
        type: ModalConfigActionTypes.MASTER_CUSTOMIZE,
        data: {
          catalogId: state?.catalogId,
          catalogName: state.catalogName,
          masterId: state.masterId,
          masterName: state.masterName,
          files: state?.files.map((a) => a.file),
          customizeMaster: customazeValue,
          // customizeAccess: customazeValue,
          isEdit: false,
        },
      })
    );
  };

  const modalActions = React.useMemo<ModalActions>(
    () => ({
      close: () => onClose && onClose(),
      setError: (error: any) =>
        dispatch({
          type: 'SET_ERROR',
          payload: error,
        }),
      setProgress: (index, progress) =>
        dispatch({
          type: 'SET_PROGRESS',
          payload: { index, progress },
        }),
      setUploading: (state) =>
        dispatch({
          type: 'SET_UPLOADING',
          payload: state,
        }),
    }),
    [dispatch, onClose]
  );

  const handleCatalogChange = React.useCallback(
    (entry: CatalogName) =>
      dispatch({
        type: 'SET_CATALOG',
        payload: entry,
      }),
    [dispatch]
  );

  const handleFilesChange = React.useCallback(
    (files: FileList | null) => {
      const file = files ? Array.from(files)?.[0] : null;
      if (file) {
        dispatch({
          type: 'PUSH_FILE',
          payload: file,
        });
      }
    },
    [dispatch]
  );

  const handleFileRemove = (index: number) => () =>
    dispatch({
      type: 'REMOVE_FILE',
      payload: { index },
    });

  const handleVersionChange = (index: number, typeId: Version) => () =>
    dispatch({
      type: 'SET_TYPEID',
      payload: { typeId, index },
    });

  const handleSubmit = () => {
    if (!hasPlaceToStore(state.files.map(({ file }) => file))) {
      return;
    }

    if (onSubmit) {
      const values: ModalValues = {
        masterId: state.masterId,
        masterName: state.masterName,
        catalogId: state.catalogId,
        catalogName: state.catalogName,
        files: state.files.map(({ file, typeId }) =>
          uploadingFileTitle === ''
            ? {
                file,
                typeId,
              }
            : {
                file: new File([file], getNewFilename(file.name, uploadingFileTitle)),
                typeId,
              }
        ),
        customizeMaster: state.customizeMaster,
      };

      onSubmit(values, modalActions);
    }
  };

  const common: ITypographyProps = {
    component: 'span',
  };

  return (
    <>
      <DialogHeader
        submitting={isUploadingState}
        onClose={onClose}
        title={state.masterName}
        EditableTitleProps={{
          typographyProps: common,
          ediphyProps: {
            ...ediphyProps,
            ...common,
            oneLineText: true,
            disableIconHide: true,
          },
        }}
        editable={!Boolean(config.maxFiles)}
      />
      <DialogContent className={classes.content}>
        <div className={classes.catalogSelect}>
          {!isImport && (
            <CatalogSelect
              disabled={isUploadingState}
              onChange={handleCatalogChange}
              selectedId={state.catalogId}
              valuePersistence={false}
            />
          )}
        </div>
        <DNDArea
          onChange={handleFilesChange}
          disabled={isMax}
          inputProps={{ accept: state.accept }}
        />
        {entries.length > 0 && (
          <div className={classes.fileBox}>
            {entries.map((entry, index) => (
              <div key={index}>
                <Box
                  className={clsx(classes.item, {
                    [classes.itemGutterBottom]: !isUploadingState,
                  })}
                  flexWrap="nowrap"
                >
                  <Box flex="1 1 auto" minWidth={0}>
                    <Typography variant="subtitle2">
                      {uploadingFileTitle === ''
                        ? entry.file.name
                        : getNewFilename(entry.file.name, uploadingFileTitle)}
                    </Typography>
                  </Box>
                  <Box flex="0 0 auto">
                    <Box display="flex" pl={1}>
                      {isUploadingState ? (
                        <Typography variant="body2">{entry.progress}%</Typography>
                      ) : (
                        <IconButton size="small" onClick={handleFileRemove(index)}>
                          <DeleteIconOld />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Box>
                {isUploadingState && (
                  <Box>
                    <LinearProgress value={entry.progress} variant="determinate" />
                  </Box>
                )}
                <FormControl disabled={isRadioDisabled} component="fieldset">
                  <FormGroup row>
                    <FormControlLabel
                      onChange={handleVersionChange(index, ContentType.MASTER_FILE_CLEAN)}
                      control={
                        <Radio
                          checked={entry.typeId === ContentType.MASTER_FILE_CLEAN}
                          color="primary"
                        />
                      }
                      label="Clean"
                    />
                    <FormControlLabel
                      onChange={handleVersionChange(
                        index,
                        ContentType.MASTER_FILE_EXPLICIT
                      )}
                      control={
                        <Radio
                          checked={entry.typeId === ContentType.MASTER_FILE_EXPLICIT}
                          color="primary"
                        />
                      }
                      label="Explicit"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            ))}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={addCustomizeMaster(mastersContent)}
          variant="outlined"
          dialogAction
        >
          Customize
        </Button>
        <Button
          disabled={disableSubmit}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          dialogAction
        >
          {isImport ? 'Import' : 'Add Master'}
        </Button>
      </DialogActions>
    </>
  );
};

export default ModalMasterUpload;
