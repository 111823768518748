import * as yup from 'yup';
import { IRestApproverInput } from 'modules/splitSheet/contracts/interfaces/IRestApproverInput';

export const RestApproverInputSchema = yup.object<IRestApproverInput>({
  name: yup.string().required(),
  email: yup.string().email().required(),
  role_label: yup.string().required(),
  affiliation: yup.string(),
  ownership: yup.number().required(),
  can_see_others: yup.bool().required(),
  tracking_id: yup.number().required(),
});
