import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC } from 'react';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      ...theme.mixins.containerMinHeight,
      padding: theme.spacing(0, 2, 2),
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0, 5, 5),
      },
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(0, 10, 10),
      },
    },
  }),
  { name: 'ContentContainer' }
);

interface ContentContainerProps {
  className?: string;
}

const ContentContainer: FC<ContentContainerProps> = ({ children, className }) => {
  const classes = useStyles();
  return <div className={clsx(classes.root, className)}>{children}</div>;
};

export default ContentContainer;
