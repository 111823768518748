import { ReactElement } from 'react';

import ActionPayload from 'common/interfaces/actionPayload';
import actionFn from 'common/utils/actionFn';

export enum FullScreenViewActionTypes {
  SetFullScreenViewContent,
}

export interface SetFullScreenViewContentAction {
  type: FullScreenViewActionTypes.SetFullScreenViewContent;
  payload: ActionPayload<{
    content: ReactElement;
    onCloseAdditionalAction?: () => void;
  } | null>;
}

export const setFullScreenViewOpened = actionFn<SetFullScreenViewContentAction>(
  FullScreenViewActionTypes.SetFullScreenViewContent
);

export const setFullScreenViewClosed = (): SetFullScreenViewContentAction => ({
  type: FullScreenViewActionTypes.SetFullScreenViewContent,
  payload: {
    value: null,
  },
});

export type FullScreenViewActions = SetFullScreenViewContentAction;
