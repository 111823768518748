import { FC, useEffect } from 'react';
import { PRICING_URL } from 'config/appConstants';

const NoAccess: FC = () => {
  useEffect(() => {
    window.location.replace(PRICING_URL);
  }, []);
  return null;
};

export default NoAccess;
