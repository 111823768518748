import CollValues from 'common/components/Modals/createAlbumDialog/interfaces/collValues';
import { CollectionInput } from 'generated/graphql';

const collValuesToCollectionInput = (values: CollValues): CollectionInput => {
  return {
    catalog_id: values.catalogId,
    title: values.title,
    artist_name: values.artist,
    year: values.year,
    collection_type: values.collectionType,
    master_positions: [],
  };
};

export default collValuesToCollectionInput;
