import AmpService from 'common/api/AmpService';
import useEdiphyState from 'uikit/ediphy/hooks/useEdiphyState';
import {
  reducer,
  setTitleAction,
} from 'common/components/Modals/contractAgreementFormDialog/contractAgreementFormState';
import { notify } from 'common/components/notifMessages/Notify';
import { ContractType, useUpdateContractMutation } from 'generated/graphql';
import React from 'react';
import clsx from 'clsx';
import { DialogContent, Grid, makeStyles, Theme } from '@material-ui/core';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import Typography from 'uikit/typography/Typography';
import IContractAgreementInfoProps from './interfaces/IContractAgreementInfoProps';
import ContractAgreementInfoParties from './components/ContractAgreementInfoParties';
import FileSubSection from 'common/components/Modals/contractAgreementFormDialog/components/FileSubSection';

const useStyles = makeStyles((theme: Theme) => ({
  contentWidth: {
    width: 768,
  },
  content: {
    paddingBottom: theme.spacing(5),
  },
  contentItem: {
    textAlign: 'left',
    marginBottom: theme.spacing(3),
  },
  contractType: {
    textTransform: 'capitalize',
  },
  itemLabel: {
    marginBottom: theme.spacing(0.5),
  },
}));

const ContractAgreementInfoDialog: React.FC<IContractAgreementInfoProps> = ({
  onClose,
  summary,
}) => {
  const classes = useStyles();
  const [updateContract] = useUpdateContractMutation();
  const [, dispatch] = React.useReducer<typeof reducer>(reducer, summary);
  const [ediphy, ediphyProps] = useEdiphyState(
    summary.title,
    (_, next) => {
      dispatch(setTitleAction(next));
    },
    100
  );

  const handleEditExit = React.useCallback(async () => {
    if (summary.id && ediphy.prev !== ediphy.value) {
      try {
        await updateContract({
          variables: {
            id: summary.id,
            title: ediphy.value,
            masterId: summary.masterId,
            description: summary.description,
            type:
              (summary.contractType as ContractType) || ContractType.UPLOADED_CONTRACT,
            fileId: summary.file.id || null,
          },
        });

        await AmpService.queryMaster({
          catalogId: summary.catalogId as number,
          masterId: summary.masterId,
        });

        notify.enqueueSnackbar('Contract Title has been successfully updated!', {
          variant: 'success',
        });
      } catch {
        notify.enqueueSnackbar("Can't update contract, please, reload page", {
          variant: 'error',
        });
      }
    }

    return true;
  }, [
    ediphy.prev,
    ediphy.value,
    summary.catalogId,
    summary.contractType,
    summary.description,
    summary.file.id,
    summary.id,
    summary.masterId,
    updateContract,
  ]);

  return (
    <>
      <DialogHeader
        title={summary.title}
        onClose={() => handleEditExit() && onClose()}
        EditableTitleProps={{ ediphyProps }}
        editable
      />
      <DialogContent className={clsx([classes.content, classes.contentWidth])}>
        <Grid container>
          <Grid item xs={6}>
            {!!summary.contractType &&
              summary.contractType !== ContractType.UPLOADED_CONTRACT && (
                <>
                  <Typography variant={'subtitle2'} className={classes.itemLabel}>
                    Contract Type
                  </Typography>
                  <p className={`${classes.contentItem} ${classes.contractType}`}>
                    {(summary.contractType as string)
                      .split('_')
                      .join(' ')
                      .toLowerCase()
                      .replace('agreement', '') || 'None'}
                  </p>
                </>
              )}

            {summary.description && (
              <>
                <Typography variant={'subtitle2'} className={classes.itemLabel}>
                  Description
                </Typography>
                <p className={classes.contentItem}>{summary.description}</p>
              </>
            )}
          </Grid>

          <Grid item xs={6}>
            {!!summary.file.fileName && <FileSubSection file={summary.file} />}
          </Grid>
        </Grid>

        {summary.parties.length > 0 && (
          <ContractAgreementInfoParties parties={summary.parties} />
        )}
      </DialogContent>
    </>
  );
};

export default ContractAgreementInfoDialog;
