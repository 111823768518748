import { ShareActions, ShareActionTypes } from 'redux/share/shareActions';

type ShareState = {
  refreshFn: Function | null;
  slug: string | null;
};

const initShareState = (): ShareState => ({ refreshFn: null, slug: null });

const shareReducer = (state = initShareState(), action: ShareActions): ShareState => {
  switch (action.type) {
    case ShareActionTypes.SET_REFRESH_FN:
      return { ...state, refreshFn: action.payload.refreshFn };
    case ShareActionTypes.SET_SLUG:
      return { ...state, slug: action.payload.slug };
    case ShareActionTypes.RESET:
      return initShareState();
    default:
      return state;
  }
};

export default shareReducer;
