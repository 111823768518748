import { ApolloClient, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import cache from 'apollo/cache/cache';
import { BASE_URL } from 'common/api/Constants';
import loadToken from 'common/services/tokenServices/loadToken';

const uri = process.env.REACT_APP_BACKEND_HOST
  ? `${process.env.REACT_APP_BACKEND_HOST}/graphql/`
  : '/graphql/';

const httpLink = createHttpLink({ uri });

const authLink = setContext((_, { headers }) => {
  const token = loadToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const restLink = new RestLink({ uri: BASE_URL });

const client = new ApolloClient({
  link: ApolloLink.from([authLink, restLink, httpLink]),
  cache,
});

export default client;
