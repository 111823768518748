import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import constructPath from 'api/utils/constructPath';
import { Asset } from 'common/api/models';
import POST from 'common/services/httpServices/post';
import { SomeRequired } from 'common/utilityTypes';

const createProjectAsset: (
  input: SomeRequired<Asset, 'title' | 'catalog' | 'type'>,
  params: {
    catalogId: number;
    projectId: number;
  }
) => Promise<Asset> = async (input, params) => {
  const { catalogId, projectId } = params;
  const endpoint = constructPath(CatalogsPaths.CreateProjectAsset, {
    catalogId: catalogId.toString(),
    projectId: projectId.toString(),
  });

  return await POST(endpoint, JSON.stringify(input)).then<Asset>((res) => res.json());
};

export default createProjectAsset;
