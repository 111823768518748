import { useCallback, useState } from 'react';

export interface UseShareAddUserRes {
  openAddUserModal: boolean;
  closeAddUserModalHandler: () => void;
}

const useShareAddUser: () => UseShareAddUserRes = () => {
  const [openAddUserModal, setOpenAddUserModal] = useState<boolean>(false);
  const closeAddUserModalHandler = useCallback(() => setOpenAddUserModal(false), []);

  return { closeAddUserModalHandler, openAddUserModal };
};

export default useShareAddUser;
