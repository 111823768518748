import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FileUploadEffects from 'modules/app/effects/FileUploadEffects';
import PrivateContent from 'modules/app/components/PrivateContent';
import PublicLayout from 'modules/app/components/PublicLayout';
import PublicRouter from 'navigation/routes/publicRouter/PublicRouter';
import loadToken from 'common/services/tokenServices/loadToken';
import useGAEffect from 'modules/app/hooks/useGAEffect';
import useRedirectURN from 'modules/auth/hooks/useRedirectURN';
import { RootState } from 'redux/root-reducer';
import { handleCallbackRedirectURN } from 'modules/auth/util';
import { logoutAction } from 'redux/login/loginActions';
import useIsTokenExpired from 'modules/app/hooks/useIsTokenExpired';
import { useLocation } from 'react-router';
import WebViewContent from 'modules/app/components/WebViewContent';
import { TOKEN_NAME } from 'config/appConstants';

const AppSwitch: FC = (): ReactElement => {
  const loginState = useSelector((state: RootState) => state.loginState);
  const redirectCallbackURN = useRedirectURN();
  const location = useLocation();

  const dispatch = useDispatch();

  const isTokenExpired = useIsTokenExpired();
  const isWebView = location.pathname.includes('web-view');
  useGAEffect();

  if (isTokenExpired) {
    localStorage.removeItem(TOKEN_NAME);
  }

  if (loginState.loggedIn && !isWebView) {
    if (redirectCallbackURN) {
      // OAuth redirection
      if (isTokenExpired) {
        dispatch(logoutAction());
      } else {
        handleCallbackRedirectURN(redirectCallbackURN, loadToken());
      }
      return <p>Performing a redirect...</p>;
    } else {
      return <PrivateContent />;
    }
  }
  if (!isWebView) {
    // User is not logged in
    return (
      <PublicLayout>
        <FileUploadEffects />
        <PublicRouter />
      </PublicLayout>
    );
  }

  return <WebViewContent />;
};

export default AppSwitch;
