import * as React from 'react';
import Button, { ButtonPropsExtended } from 'uikit/button/Button';
import { useFormikContext } from 'formik';

const Cancel: React.FC<ButtonPropsExtended> = (props) => {
  const { disabled, ...other } = props;
  const { isSubmitting } = useFormikContext();

  return (
    <Button {...other} disabled={disabled || isSubmitting}>
      Cancel
    </Button>
  );
};

export default Cancel;
