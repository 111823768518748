import React, { FC, ReactElement } from 'react';

import EditAlbumDialogContent, {
  EditAlbumDialogContentProps,
} from './components/EditAlbumDialogContent';
import useEditAlbumSubmit from './hooks/useEditAlbumSubmit';

type EditAlbumDialogProps = Omit<EditAlbumDialogContentProps, 'onSubmit'>;

const EditAlbumDialog: FC<EditAlbumDialogProps> = (props): ReactElement => {
  const onSubmit = useEditAlbumSubmit();

  return <EditAlbumDialogContent {...props} onSubmit={onSubmit} />;
};

export default EditAlbumDialog;
