import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { filtersSelector } from 'redux/filters/filtersSelectors';
import saveFilters from 'redux/filters/services/saveFilters';

const useFilterSaveEffect = () => {
  const filters = useSelector(filtersSelector);

  useEffect(() => saveFilters(filters), [filters]);
};

export default useFilterSaveEffect;
