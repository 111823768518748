import CorePaths from 'api/core/enums/corePaths';
import addAuthHeaders from 'api/utils/addAuthHeaders';
import { AxiosRequestConfig } from 'axios';
import axios from 'common/api/axios';
import { ContentType, StorageLink } from 'common/api/models';

const getStorageLink = async (
  input: { file_name: string; file_size?: number; asset_type?: ContentType },
  config?: AxiosRequestConfig
) =>
  axios.post<StorageLink>(CorePaths.GetStorageLink, input, await addAuthHeaders(config));

export default getStorageLink;
