import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import SetDialogOpenedProps from 'redux/dialog/interfaces/setDialogOpenedProps';
import ActionPayload from 'common/interfaces/actionPayload';
import actionFn from 'common/utils/actionFn';

export enum DialogActionTypes {
  SetDialogOpened = 'SetDialogOpened',
  SetDialogClosed = 'SetDialogClosed',
  ClearDialogContent = 'ClearDialogContent',
  SetPrevDialogOpened = 'SetPrevDialogOpened',
  CloseDialogByName = 'CloseDialogByName',
}

export interface SetDialogOpenedAction {
  type: DialogActionTypes.SetDialogOpened;
  payload: ActionPayload<SetDialogOpenedProps>;
}

export const setDialogOpened: (props: {
  width?: string;
  content: ReactElement;
  dialogName?: string;
}) => SetDialogOpenedAction = ({
  width,
  content,
  dialogName,
}): SetDialogOpenedAction => ({
  type: DialogActionTypes.SetDialogOpened,
  payload: {
    value: {
      isOpened: true,
      content: {
        element: content,
        customWidth: width || null,
        name: dialogName || uuidv4(),
      },
    },
  },
});

export interface SetDialogClosedAction {
  type: DialogActionTypes.SetDialogClosed;
  payload: ActionPayload<false>;
}

export const setDialogClosed = (): SetDialogClosedAction => ({
  type: DialogActionTypes.SetDialogClosed,
  payload: {
    value: false,
  },
});

export interface ClearDialogContentAction {
  type: DialogActionTypes.ClearDialogContent;
  payload: ActionPayload<[]>;
}

export const clearDialogContent = (): ClearDialogContentAction => ({
  type: DialogActionTypes.ClearDialogContent,
  payload: {
    value: [],
  },
});

export interface SetPrevDialogOpenedAction {
  type: DialogActionTypes.SetPrevDialogOpened;
}

export const setPrevDialog = (): SetPrevDialogOpenedAction => ({
  type: DialogActionTypes.SetPrevDialogOpened,
});

export interface CloseDialogByNameAction {
  type: DialogActionTypes.CloseDialogByName;
  payload: ActionPayload<string>;
}

export const closeDialogByName = actionFn<CloseDialogByNameAction>(
  DialogActionTypes.CloseDialogByName
);

export type DialogActions =
  | ClearDialogContentAction
  | CloseDialogByNameAction
  | SetDialogOpenedAction
  | SetPrevDialogOpenedAction
  | SetDialogClosedAction;
