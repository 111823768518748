import FullScreenViewState from 'redux/fullScreenView/interfaces/fullScreenViewState';
import INIT_FULL_SCREEN_VIEW_STATE from 'redux/fullScreenView/configs/initFullScreenViewState';
import * as FullScreenViewActions from './fullScreenViewActions';

const fullScreenViewReducer = (
  state: FullScreenViewState = INIT_FULL_SCREEN_VIEW_STATE,
  action: FullScreenViewActions.FullScreenViewActions
): FullScreenViewState => {
  const actionTypes = FullScreenViewActions.FullScreenViewActionTypes;

  switch (action.type) {
    case actionTypes.SetFullScreenViewContent:
      return {
        ...state,
        content: action.payload.value?.content || null,
        onCloseAdditionalAction: action.payload.value?.onCloseAdditionalAction,
      };
    default:
      return state;
  }
};

export default fullScreenViewReducer;
