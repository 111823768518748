const constructPath: (path: string, vars: Record<string, string | number>) => string = (
  path,
  vars
) => {
  let newPath = path;
  Object.keys(vars).forEach((key) => {
    newPath = newPath.replace(key, vars[key].toString());
  });
  return newPath;
};

export default constructPath;
