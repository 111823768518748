import { makeStyles } from '@material-ui/core/styles';

const useInviteGuestStyles = makeStyles(
  {
    paper: {
      width: 420,
    },
  },
  { name: 'ShareDialogAddUser' }
);

export default useInviteGuestStyles;
