import apolloClient from 'apollo/client';

const clearUserCache: () => void = () => {
  const catalogSelector = localStorage.getItem('catalogSelector') || '[]';
  localStorage.clear();
  localStorage.setItem('catalogSelector', catalogSelector);
  apolloClient.clearStore();
};

export default clearUserCache;
