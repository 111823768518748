import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import GET from 'common/services/httpServices/get';
import constructPath from 'api/utils/constructPath';

const getMasterName = async (masterId: number): Promise<string> => {
  const endpoint = constructPath(CatalogsPaths.GetMasterName, { masterId });
  const response = await GET(endpoint);
  const json = await response.json();
  return json.title;
};

export default getMasterName;
