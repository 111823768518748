import { ContentType } from 'common/api/models';
import ContentPermissions from 'common/interfaces/contentPermissions';

interface CheckIsAvailableMenuProps {
  contentTypes: ContentType[];
  permissions: ContentPermissions | null;
}

const checkIsAvailableSection: (props: CheckIsAvailableMenuProps) => boolean = ({
  contentTypes,
  permissions,
}) => {
  return permissions === null
    ? false
    : contentTypes.some((contentType) => permissions[contentType].VIEW);
};

export default checkIsAvailableSection;
