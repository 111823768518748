import { AcceptTypeString } from 'config/acceptTypes';

export type DropIdName = {
  id: number;
  name: string;
};

export type FileEntry = {
  progress: number;
  file: File;
};

export type SetUploading = {
  type: 'SET_UPLOADING';
  payload: boolean;
};

export type SetTrackName = {
  type: 'SET_TRACK_NAME';
  payload: string;
};

export type SetDrop = {
  type: 'SET_DROP';
  payload: DropIdName;
};

export type PushFile = {
  type: 'SET_FILE';
  payload: File;
};

export type RemoveFile = {
  type: 'REMOVE_FILE';
};

export type SetProgress = {
  type: 'SET_PROGRESS';
  payload: number;
};

export type SetError = {
  type: 'SET_ERROR';
  payload: any;
};

export type ModalAction =
  | SetUploading
  | SetTrackName
  | SetDrop
  | PushFile
  | RemoveFile
  | SetProgress
  | SetError;

export type ModalState = {
  trackName: string;
  dropId: number;
  dropName: string;
  file: FileEntry | undefined;
  isUploadingState: boolean;
  error: any;
  accept: string;
};

export type ModalActions = {
  setProgress(progress: number): void;
  setUploading(state: boolean): void;
  setError(error: any): void;
  close(): void;
};

const entry = (f: File): FileEntry => ({
  file: f,
  progress: 0,
});

export type InitArgs = {
  dropId: number;
  dropName: string;
};

export function init(initialArg: InitArgs): ModalState {
  return {
    trackName: '',
    dropId: initialArg.dropId,
    dropName: initialArg.dropName,
    file: undefined,
    isUploadingState: false,
    error: null,
    accept: AcceptTypeString.AUDIO,
  };
}

export function submitToDropDialogReducer(
  state: ModalState,
  action: ModalAction
): ModalState {
  if (action.type === 'SET_UPLOADING') {
    return { ...state, isUploadingState: action.payload };
  }

  if (action.type === 'SET_TRACK_NAME') {
    return { ...state, trackName: action.payload };
  }

  if (action.type === 'SET_DROP') {
    return { ...state, dropName: action.payload.name, dropId: action.payload.id };
  }

  if (action.type === 'SET_FILE') {
    return { ...state, file: entry(action.payload) };
  }

  if (action.type === 'REMOVE_FILE') {
    return { ...state, file: undefined };
  }

  if (action.type === 'SET_PROGRESS') {
    return {
      ...state,
      file: state.file ? { file: state.file?.file, progress: action.payload } : undefined,
    };
  }

  if (action.type === 'SET_ERROR') {
    return {
      ...state,
      error: action.payload,
    };
  }

  return state;
}
