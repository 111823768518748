import { Popover } from '@material-ui/core';
import ArrowIcon from '@material-ui/core/internal/svg-icons/ArrowDropDown';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import clsx from 'clsx';
import TextField from 'uikit/textField/TextField';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    textFieldRoot: {
      textOverflow: 'ellipsis',
      '&:not(.Mui-disabled):hover': {
        cursor: 'pointer',
      },
    },
    borderLess: {
      border: 'none',
      padding: 0,
      fontWeight: 600,
      height: 19,
      marginBottom: 5,
    },
    arrowIcon: {
      pointerEvents: 'none', // Don't block pointer events on the select under the icon.
      color: theme.palette.action.active,
      marginRight: theme.spacing(1),
      flex: '0 0 auto',
      '.Mui-disabled &': {
        color: theme.palette.action.disabled,
      },
      '$open &': {
        transform: 'rotateZ(180deg)',
      },
    },
    open: {},
  }),
  { name: 'Select' }
);

const usePaperStyles = makeStyles(() => ({
  paper: {
    width: (props: { width?: string }) => props.width,
  },
}));

export type SelectProps = {
  text?: string;
  className?: string;
  dropdownFullWidth?: boolean;
  closeOnClick?: boolean;
  disabled?: boolean;
  title?: string;
  placeholder?: string;
  error?: boolean;
  containerStyle?: React.CSSProperties;
  open?: boolean;
  onFieldClick?: () => void;
  onInputClick?: () => void;
  onClose?: () => void;
  disableFullWidth?: boolean;
  disableMinWidth?: boolean;
  helperText?: React.ReactNode;
  variant?: string;
};

const Select: React.FC<SelectProps> = ({
  className,
  title,
  text,
  placeholder,
  containerStyle,
  children,
  open: openProp,
  onFieldClick,
  onInputClick,
  onClose,
  helperText,
  disableFullWidth = false,
  disableMinWidth = false,
  disabled = false,
  dropdownFullWidth = false,
  closeOnClick = false,
  error = false,
  variant,
}) => {
  const classes = useStyles();
  const fieldRef = React.useRef<HTMLDivElement>();
  const isControlled = typeof openProp === 'boolean';

  const [openState, setOpenState] = React.useState(false);

  const handleClick = React.useCallback(() => {
    if (!disabled) {
      if (!isControlled) {
        setOpenState(true);
      }

      onInputClick && onInputClick();
    }
  }, [disabled, isControlled, onInputClick]);

  const handleClose = React.useCallback(() => {
    if (!disabled) {
      if (!isControlled) {
        setOpenState(false);
      }

      onClose && onClose();
    }
  }, [disabled, isControlled, onClose]);

  const open = fieldRef.current && isControlled ? !!openProp : openState;
  const rootInputPropsClass =
    variant === 'label' ? classes.borderLess : classes.textFieldRoot;

  return (
    <div
      className={clsx(classes.root, { [classes.open]: open }, className)}
      style={containerStyle || undefined}
    >
      <TextField
        disabled={disabled}
        error={error}
        disableMinWidth={disableMinWidth}
        fullWidth={!disableFullWidth}
        label={title}
        value={text}
        placeholder={placeholder}
        onClick={onFieldClick}
        helperText={helperText}
        InputProps={{
          inputProps: { type: '' },
          ref: fieldRef,
          classes: { root: rootInputPropsClass, input: rootInputPropsClass },
          readOnly: true,
          onClick: handleClick,
          endAdornment: <ArrowIcon className={classes.arrowIcon} />,
        }}
      />
      <Popover
        anchorEl={fieldRef.current}
        open={open}
        onClose={handleClose}
        onClick={closeOnClick ? handleClose : undefined}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        classes={usePaperStyles({
          width: dropdownFullWidth ? fieldRef.current?.clientWidth.toString() : undefined,
        })}
      >
        {children}
      </Popover>
    </div>
  );
};

export default Select;
