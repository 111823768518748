import getAccessToken from 'common/services/tokenServices/getAccessToken';

import { BASE_URL } from './configs/constants';

const PUT = async (endpoint: string, body?: BodyInit): Promise<Response> => {
  const token = await getAccessToken();
  const res = await fetch(`${BASE_URL}/${endpoint}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  });
  if (res.ok) {
    return res;
  }
  throw res;
};

export default PUT;
