import { ContentType, MasterVersion } from 'common/api/models';
import { AcceptTypeString } from 'config/acceptTypes';

export type Version = ContentType.MASTER_FILE_EXPLICIT | ContentType.MASTER_FILE_CLEAN;

type CatalogIdName = {
  id: number;
  name: string;
};

export type FileEntry = {
  progress: number;
  typeId: Version;
  file: File;
};

export type SetUploading = {
  type: 'SET_UPLOADING';
  payload: boolean;
};

export type SetMasterName = {
  type: 'SET_MASTER_NAME';
  payload: string;
};

export type SetCatalog = {
  type: 'SET_CATALOG';
  payload: CatalogIdName;
};

export type PushFile = {
  type: 'PUSH_FILE';
  payload: File;
};

export type RemoveFile = {
  type: 'REMOVE_FILE';
  payload: { index: number };
};

export type SetTypeId = {
  type: 'SET_TYPEID';
  payload: { index: number; typeId: Version };
};

export type SetProgress = {
  type: 'SET_PROGRESS';
  payload: { index: number; progress: number };
};

export type SetError = {
  type: 'SET_ERROR';
  payload: any;
};

export type CustomizeMasterValues = {
  realityAudio: boolean;
  stemFiles: boolean;
  contracts: boolean;
  metadata: boolean;
  media: boolean;
};

export type ModalAction =
  | SetUploading
  | SetMasterName
  | SetCatalog
  | PushFile
  | RemoveFile
  | SetTypeId
  | SetProgress
  | SetError;

export type ModalState = {
  maxFiles: number;
  isMax: boolean;
  isRenamingState: boolean;
  isUploadingState: boolean;
  catalogId: number;
  catalogName: string;
  masterName: string;
  files: FileEntry[];
  error: any;
  strictVersion: MasterVersion | null;
  masterId: number;
  accept: string;
  customizeMaster: CustomizeMasterValues | null;
};

export type ModalValues = {
  masterId: number;
  masterName: string;
  catalogId: number;
  catalogName: string;
  files: Omit<FileEntry, 'progress'>[];
  customizeMaster: CustomizeMasterValues | null;
};

export type ModalActions = {
  setProgress(index: number, progress: number): void;
  setUploading(state: boolean): void;
  setError(error: any): void;
  close(): void;
};

export type ModalSubmitHandler = {
  (values: ModalValues, actions: ModalActions): void;
};

const opposite: Record<Version, Version> = {
  [ContentType.MASTER_FILE_CLEAN]: ContentType.MASTER_FILE_EXPLICIT,
  [ContentType.MASTER_FILE_EXPLICIT]: ContentType.MASTER_FILE_CLEAN,
};

const entry = (f: File, v: Version): FileEntry => ({
  file: f,
  progress: 0,
  typeId: v,
});

const asFirst = (f: File) => [entry(f, ContentType.MASTER_FILE_CLEAN)];

const asSecond = (f: File, files: FileEntry[]) => [
  files[0],
  entry(f, opposite[files[0].typeId]),
];

const setTypeId = <T = FileEntry>(entry: T, v: Version): T => ({ ...entry, typeId: v });

const generateTitle = () => {
  const date = new Date();
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const MAX_FILES_COUNT = 2;

export type InitArgs = {
  id: number;
  name: string;
  masterId?: number;
  onUpload?: (masterId: string) => void;
  masterName?: string;
  maxFiles?: number;
  strictVersion?: MasterVersion;
  accept?: string;
  isSharing?: boolean;
  files?: ArrayLike<File> | null;
  customizeMaster?: CustomizeMasterValues | null;
  queueNewMaster?: boolean;
};

export type CustomizeMaster = {
  catalogId: number;
  catalogName: string;
  masterId: number;
  masterName: string;
  files?: ArrayLike<File> | null;
  customizeMaster: CustomizeMasterValues | null;
  customazeAccess?: CustomizeMasterValues | null;
  // master:
  onUpload?: (masterId: string) => void;
  isEdit: boolean;
};

const getInitFileEntries = (
  files?: ArrayLike<File> | null,
  strictVersion?: MasterVersion
): FileEntry[] => {
  const filesArray = Array.from(files || []);
  const [first, second] = filesArray;

  if (!filesArray.length) {
    return [];
  }

  if (strictVersion) {
    return first ? [entry(first, strictVersion)] : [];
  }

  return filesArray.length > 1
    ? [
        entry(first, ContentType.MASTER_FILE_CLEAN),
        entry(second, ContentType.MASTER_FILE_EXPLICIT),
      ]
    : [entry(first, ContentType.MASTER_FILE_CLEAN)];
};

export function init(initialArg: InitArgs): ModalState {
  const files = getInitFileEntries(initialArg.files, initialArg.strictVersion);
  const maxFiles = initialArg.maxFiles || MAX_FILES_COUNT;
  return {
    error: null,
    maxFiles,
    isMax: files.length >= maxFiles,
    isRenamingState: false,
    isUploadingState: false,
    catalogId: initialArg.id,
    catalogName: initialArg.name,
    masterName: initialArg.masterName || generateTitle(),
    masterId: initialArg.masterId || -1,
    files,
    strictVersion: initialArg.strictVersion || null,
    accept:
      (initialArg.accept && AcceptTypeString[initialArg.accept]) ||
      AcceptTypeString.AUDIO,
    customizeMaster: initialArg.customizeMaster || null,
  };
}

export function masterUploadModalReducer(state: ModalState, action: ModalAction) {
  if (action.type === 'SET_UPLOADING') {
    return { ...state, isUploadingState: action.payload };
  }

  if (action.type === 'SET_MASTER_NAME') {
    return { ...state, masterName: action.payload };
  }

  if (action.type === 'SET_CATALOG') {
    return { ...state, catalogName: action.payload.name, catalogId: action.payload.id };
  }

  if (action.type === 'PUSH_FILE') {
    if (state.isMax) {
      return state;
    }

    let nextFiles = [] as FileEntry[];
    if (state.strictVersion) {
      nextFiles = state.files.concat(entry(action.payload, state.strictVersion));
    } else {
      nextFiles =
        state.files.length === 0
          ? asFirst(action.payload)
          : asSecond(action.payload, state.files);
    }

    return {
      ...state,
      isMax: nextFiles.length === state.maxFiles,
      files: nextFiles,
    };
  }

  if (action.type === 'REMOVE_FILE') {
    const nextFiles = state.files.filter((_, i) => i !== action.payload.index);

    return {
      ...state,
      isMax: nextFiles.length === state.maxFiles,
      files: nextFiles,
    };
  }

  if (action.type === 'SET_TYPEID') {
    const { index, typeId } = action.payload;

    if (state.files.length === 1) {
      return {
        ...state,
        files: [setTypeId(state.files[0], typeId)],
      };
    }

    const [e1, e2] = state.files;

    return {
      ...state,
      files: [
        setTypeId(e1, index === 0 ? typeId : opposite[typeId]),
        setTypeId(e2, index === 1 ? typeId : opposite[typeId]),
      ],
    };
  }

  if (action.type === 'SET_PROGRESS') {
    return {
      ...state,
      files: state.files.map((entry, i) =>
        i === action.payload.index
          ? { ...entry, progress: action.payload.progress }
          : entry
      ),
    };
  }

  if (action.type === 'SET_ERROR') {
    return {
      ...state,
      error: action.payload,
    };
  }

  return state;
}
