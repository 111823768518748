import * as React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { fileUploadSelector } from 'redux/fileUpload/fileUploadSelectors';
import { FileUploadActionTypes } from 'redux/fileUpload/fileUploadActions';
import TransferSnackContent from 'common/components/transferSnack/components/TransferSnackContent';

export const TransferSnack = () => {
  const fileUpload = useSelector(fileUploadSelector);

  if (fileUpload.status === FileUploadActionTypes.IDLE) {
    return (
      <Box py={1.75} px={2}>
        Waiting for upload start
      </Box>
    );
  }

  return <TransferSnackContent data={fileUpload} />;
};
