import React from 'react';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as UploadCloudIcon } from 'assets/upload-cloud.svg';
import Typography from 'uikit/typography/Typography';
import isFileSizeValid from 'uikit/dragAndDropArea/utils/isFileSizeValid';
import useIsMobileUi from 'common/hooks/useIsMobileUi';
import { Overwrite } from 'common/utilityTypes';
import { notify } from 'common/components/notifMessages/Notify';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    area: (props: { areaHeight?: number }) => ({
      height: props.areaHeight || 112,
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[800],
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: theme.spacing(0.5, 0, 0),
      padding: theme.spacing(2, 0),
    }),
    em: {
      color: theme.palette.secondary.main,
    },
    icon: {},
    optional: {
      color: theme.palette.grey[200],
      display: 'inline-block',
    },
    label: {
      display: 'inline-block',
    },
  }),
  { name: 'DragAndDropArea' }
);

type DNDAreaProps = Overwrite<
  JSX.IntrinsicElements['div'],
  {
    onChange(files: FileList | null): void;
    disabled?: boolean;
    inputProps?: JSX.IntrinsicElements['input'];
    areaHeight?: number;
    label?: string;
    optional?: boolean;
    fileSizeLimit?: number;
  }
>;

const blockEvent = (e: React.SyntheticEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

const DragAndDropArea = (props: DNDAreaProps) => {
  const {
    disabled = false,
    onChange,
    inputProps,
    areaHeight,
    label,
    optional = false,
    fileSizeLimit = Infinity,
    ...divProps
  } = props;
  const isMobile = useIsMobileUi();
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const classes = useStyles({ areaHeight });

  const handleClick = React.useCallback(() => {
    if (!disabled) {
      inputRef?.current?.click();
    }
  }, [disabled]);

  const handleDrop = React.useCallback(
    (event: React.DragEvent) => {
      blockEvent(event);
      let areFilesValid = true;
      const validFileFormats = inputProps?.accept?.split(',');
      const files = event.dataTransfer.files;
      const filesArray = Array.from(files);

      if (!!validFileFormats) {
        areFilesValid = filesArray.every((file) => {
          const fileName = file.name;
          const fileExt = fileName.substr(fileName.lastIndexOf('.'));
          return (
            validFileFormats?.includes(file.type) || validFileFormats?.includes(fileExt)
          );
        });
      }

      if (!areFilesValid) {
        notify.enqueueSnackbar('Invalid File Type', { variant: 'warning' });
      }

      if (!isFileSizeValid(fileSizeLimit, filesArray)) {
        notify.enqueueSnackbar('Invalid File Size', { variant: 'warning' });
        areFilesValid = false;
      }

      if (onChange && !disabled && areFilesValid) onChange(files);
    },
    [inputProps, fileSizeLimit, onChange, disabled]
  );

  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.currentTarget.files;
      const isFileSizeCorrect =
        files && isFileSizeValid(fileSizeLimit, Array.from(files));
      if (!isFileSizeCorrect) {
        notify.enqueueSnackbar('Invalid File Size', { variant: 'warning' });
      }
      if (onChange && !disabled && isFileSizeCorrect) onChange(files);
      event.currentTarget.value = '';
    },
    [fileSizeLimit, onChange, disabled]
  );

  React.useEffect(() => {
    const onDragover = (e) => {
      e.preventDefault();
    };

    const onDrop = (e) => {
      e.preventDefault();
    };

    document.addEventListener('dragover', onDragover, false);
    document.addEventListener('drop', onDrop, false);

    return () => {
      document.removeEventListener('dragover', onDragover, false);
      document.removeEventListener('drop', onDrop, false);
    };
  }, []);

  return (
    <div className={classes.root} {...divProps}>
      {typeof label === 'string' ? (
        <Typography variant={'subtitle2'} className={classes.label}>
          {label}
        </Typography>
      ) : (
        label
      )}
      {optional && (
        <Typography variant={'body2'} className={classes.optional}>
          &nbsp;(optional)
        </Typography>
      )}
      {!disabled && (
        <div className={classes.area} onDrop={handleDrop} onClick={handleClick}>
          <UploadCloudIcon className={classes.icon} />
          <p>
            {isMobile ? (
              <span className={classes.em}>Tap to upload</span>
            ) : (
              <>
                Drag & drop files <span className={classes.em}>or browse to upload</span>
              </>
            )}
          </p>
        </div>
      )}
      <input
        onChange={handleInputChange}
        type="file"
        ref={inputRef}
        hidden
        {...inputProps}
      />
    </div>
  );
};

export default React.memo(DragAndDropArea);
