import ActionPayload from 'common/interfaces/actionPayload';
import actionFn from 'common/utils/actionFn';

export enum ExpandedActionTypes {
  SetExpandedMasterId,
  SetExpandedCollectionId,
}

export interface SetExpandedMasterIdAction {
  type: ExpandedActionTypes.SetExpandedMasterId;
  payload: ActionPayload<string | null>;
}

export const setExpandedMasterId = actionFn<SetExpandedMasterIdAction>(
  ExpandedActionTypes.SetExpandedMasterId
);

export interface SetExpandedCollectionIdAction {
  type: ExpandedActionTypes.SetExpandedCollectionId;
  payload: ActionPayload<string | null>;
}

export const setExpandedCollectionId = actionFn<SetExpandedCollectionIdAction>(
  ExpandedActionTypes.SetExpandedCollectionId
);

export type ExpandedActions = SetExpandedMasterIdAction | SetExpandedCollectionIdAction;
