import updateBase from './updateBase';

const MAX_LEN = 9999;

export default function uniqueNames(names: string[]): string[] {
  if (names.length > MAX_LEN) {
    throw new Error('too many items');
  }

  return names.reduce((acc, name) => {
    if (acc.indexOf(name) > -1) {
      let next = '';
      for (let i = 1; i < MAX_LEN; i += 1) {
        next = updateBase(name, (base) => `${base}(${i})`);
        if (acc.indexOf(next) < 0) {
          break;
        }
      }
      return acc.concat(next);
    } else {
      return acc.concat(name);
    }
  }, [] as string[]);
}
