import MenuContext from 'redux/menu/types/menuContext';
import MenuOptionsVisibility from 'redux/menu/types/menuOptionsVisibility';
import MenuOption from 'redux/menu/enums/menuOption';

export enum MenuActionTypes {
  OPEN = '@MENU/OPEN',
  CLOSE = '@MENU/CLOSE',
  CLOSED = '@MENU/CLOSED',
  CLICK = '@MENU/CLICK',
}

export interface MenuClickAction {
  type: MenuActionTypes.CLICK;
  payload: {
    context: MenuContext;
    option: MenuOption;
    onDeleted?: () => void;
    activePlayerAssetId?: number | null;
    closePlayer?: () => void;
  };
}

export const click = (payload: MenuClickAction['payload']): MenuClickAction => ({
  type: MenuActionTypes.CLICK,
  payload,
});

export interface MenuCloseAction {
  type: MenuActionTypes.CLOSE;
}

export const close = (): MenuCloseAction => ({
  type: MenuActionTypes.CLOSE,
});

export interface MenuClosedAction {
  type: MenuActionTypes.CLOSED;
}

export const closed = (): MenuClosedAction => ({
  type: MenuActionTypes.CLOSED,
});

export interface MenuOpenAction {
  type: MenuActionTypes.OPEN;
  payload: {
    element: HTMLElement;
    context: MenuContext;
    visibility: MenuOptionsVisibility;
  };
}

export const open = (payload: MenuOpenAction['payload']): MenuOpenAction => ({
  type: MenuActionTypes.OPEN,
  payload,
});

export type MenuActions =
  | MenuOpenAction
  | MenuClickAction
  | MenuCloseAction
  | MenuClosedAction;
