import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import UploadAssetDialogContent from './components/UploadAssetDialogContent';
import { fileUploadStatusSelector as getStatus } from 'redux/fileUpload/fileUploadSelectors';
import * as fileActions from 'redux/fileUpload/fileUploadActions';
import { ModalUploadAssetConfigAction } from 'redux/modal/modalActions';
import useHasPlaceToStore from 'common/hooks/useHasPlaceToStore/useHasPlaceToStore';
import { RootState } from 'redux/root-reducer';
import { FileUploadActionTypes } from 'redux/fileUpload/fileUploadActions';

interface UploadAssetDialogProps {
  config: ModalUploadAssetConfigAction;
  onClose: () => void;
}

const UploadAssetDialog: FC<UploadAssetDialogProps> = ({ config, onClose }) => {
  const dispatch = useDispatch();
  const status = useSelector(getStatus);
  const statusRef = React.useRef(status);
  const files = useSelector((state: RootState) => state.fileUpload.files);
  const { hasPlaceToStore } = useHasPlaceToStore();

  statusRef.current = status;

  const onSubmit = () => {
    if (!hasPlaceToStore(files || [])) {
      return;
    }
    dispatch(fileActions.start());
  };

  const onChange = (files: File[]) => {
    dispatch(
      fileActions.prepare({
        files,
        isSharing: config.data.isSharing || false,
        ...config.data.params,
      })
    );
  };

  const onReset = () => dispatch(fileActions.reset());

  React.useEffect(
    () => () => {
      if (statusRef.current === FileUploadActionTypes.PREPARING) {
        dispatch(fileActions.reset());
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <UploadAssetDialogContent
      onSubmit={onSubmit}
      onChange={onChange}
      onClose={onClose}
      onCancel={onClose}
      onReset={onReset}
      multiple={config.data.props.multiple}
      accept={config.data.props.accept}
    />
  );
};

export default UploadAssetDialog;
