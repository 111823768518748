import * as React from 'react';
import Typography, { ITypographyProps } from 'uikit/typography/Typography';
import Ediphy, { EdiphyProps } from 'uikit/ediphy/Ediphy';
import { makeStyles } from '@material-ui/core';

export type EditableTitleProps = {
  disabled?: boolean;
  ediphyProps: Omit<EdiphyProps, 'variant'>;
  typographyProps?: Partial<ITypographyProps>;
  beforeTitleContent?: React.ReactElement;
  variant: EdiphyProps['variant'];
};

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  notEditableTitle: {
    display: 'flex',
    alignItems: 'center',
  },
});

const EditableTitle = (props: EditableTitleProps) => {
  const {
    disabled = false,
    typographyProps,
    ediphyProps,
    variant,
    beforeTitleContent,
  } = props;
  const classes = useStyles();

  if (disabled) {
    return (
      <Typography
        variant={variant}
        classes={(({ notEditableTitle, ...classes }) => classes)(classes)}
        className={classes.notEditableTitle}
        {...typographyProps}
      >
        {beforeTitleContent}
        {ediphyProps.defaultValue}
      </Typography>
    );
  }

  return (
    <Ediphy
      {...ediphyProps}
      variant={variant}
      endAdornment={typographyProps?.endAdornment}
      startAdornment={typographyProps?.startAdornment}
    />
  );
};

export default EditableTitle;
