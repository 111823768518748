import { AcceptTypeString } from 'config/acceptTypes';

export type CatalogIdName = {
  id: number;
  name: string;
};

export type FileEntry = {
  progress: number;
  file: File;
};

export type SetUploading = {
  type: 'SET_UPLOADING';
  payload: boolean;
};

export type SetProjectName = {
  type: 'SET_PROJECT_NAME';
  payload: string;
};

export type SetBeatTitle = {
  type: 'SET_BEAT_TITLE';
  payload: string;
};

export type SetCatalog = {
  type: 'SET_CATALOG';
  payload: CatalogIdName;
};

export type PushFile = {
  type: 'SET_FILE';
  payload: File;
};

export type RemoveFile = {
  type: 'REMOVE_FILE';
};

export type SetProgress = {
  type: 'SET_PROGRESS';
  payload: number;
};

export type SetError = {
  type: 'SET_ERROR';
  payload: any;
};

export type ModalAction =
  | SetCatalog
  | SetUploading
  | SetProjectName
  | SetBeatTitle
  | PushFile
  | RemoveFile
  | SetProgress
  | SetError;

export type ModalState = {
  projectName: string;
  catalogId: number;
  catalogName: string;
  file: FileEntry | undefined;
  beatTitle: string;
  isUploadingState: boolean;
  error: any;
  accept: string;
};

export type ModalActions = {
  setProgress(progress: number): void;
  setUploading(state: boolean): void;
  setError(error: any): void;
  close(): void;
};

const entry = (f: File): FileEntry => ({
  file: f,
  progress: 0,
});

export type InitArgs = {
  catalogId: number;
  catalogName: string;
};

const generateTitle = () => {
  const date = new Date();
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export function init(initialArg: InitArgs): ModalState {
  return {
    projectName: generateTitle(),
    catalogId: initialArg.catalogId,
    catalogName: initialArg.catalogName,
    file: undefined,
    beatTitle: '',
    isUploadingState: false,
    error: null,
    accept: AcceptTypeString.AUDIO,
  };
}

export function createProjectDialogReducer(
  state: ModalState,
  action: ModalAction
): ModalState {
  if (action.type === 'SET_UPLOADING') {
    return { ...state, isUploadingState: action.payload };
  }

  if (action.type === 'SET_PROJECT_NAME') {
    return { ...state, projectName: action.payload };
  }

  if (action.type === 'SET_BEAT_TITLE') {
    return { ...state, beatTitle: action.payload };
  }

  if (action.type === 'SET_CATALOG') {
    return { ...state, catalogName: action.payload.name, catalogId: action.payload.id };
  }

  if (action.type === 'SET_FILE') {
    return { ...state, file: entry(action.payload) };
  }

  if (action.type === 'REMOVE_FILE') {
    return { ...state, file: undefined };
  }

  if (action.type === 'SET_PROGRESS') {
    return {
      ...state,
      file: state.file ? { file: state.file?.file, progress: action.payload } : undefined,
    };
  }

  if (action.type === 'SET_ERROR') {
    return {
      ...state,
      error: action.payload,
    };
  }

  return state;
}
