import { TOKEN_NAME } from 'config/appConstants';

const loadToken = (): string | null => {
  try {
    return localStorage.getItem(TOKEN_NAME) || null;
  } catch (err) {
    return null;
  }
};

export default loadToken;
