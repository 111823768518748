enum CatalogsPaths {
  CreateLyrics = 'catalogs/catalogId/projects/projectId/lyrics',
  CreateMaster = 'catalogs/catalogId/masters',
  CreateMasterAsset = 'catalogs/catalogId/masters/masterId/assets',
  CreateProject = 'catalogs/catalogId/projects',
  CreateProjectAsset = 'catalogs/catalogId/projects/projectId/assets',
  DeleteAsset = 'catalogs/assets/assetId',
  Distribution = 'catalogs/masters/masterId/distribution',
  GetLyrics = 'catalogs/lyrics/',
  GetMasterName = 'catalogs/masters/masterId',
  UpdateAsset = 'catalogs/assets/assetId',
  UpdateLyrics = 'catalogs/lyrics/lyricsId',
  UploadBeat = 'catalogs/catalogId/beats',
}

export default CatalogsPaths;
