import {
  CollectionFieldsFragment,
  CollectionListFieldsFragment,
} from 'generated/graphql';

const trackIdsOrdered = (
  album: CollectionFieldsFragment | CollectionListFieldsFragment
) => {
  if ('ordered_masters' in album) {
    return album.ordered_masters.map((master) => master.id);
  }
  return album.master_positions.map((master) => master.master_id);
};

export default trackIdsOrdered;
