import { AmpMasterMetaInput, useUpdateMasterMetaMutation } from 'generated/graphql';

interface UseCustomizeMasterRes {
  customize: () => Promise<void>;
}

export interface UseDistributeProps {
  id: number;
  customizeData: AmpMasterMetaInput;
}

const useCustomizeMaster: (props: UseDistributeProps) => UseCustomizeMasterRes = ({
  id,
  customizeData,
}) => {
  const inputData: AmpMasterMetaInput = { ...customizeData };
  const [updateMasterMeta] = useUpdateMasterMetaMutation();

  const updateMeta = async () => {
    try {
      await updateMasterMeta({
        variables: {
          id: id,
          data: inputData,
        },
      });
    } catch {
    } finally {
    }
  };

  return {
    customize: updateMeta,
  };
};

export default useCustomizeMaster;
