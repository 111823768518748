import { FolderItem } from 'common/api/models';
import ToolbarOption from 'redux/toolbar/enums/toolbarOption';
import ToolbarOptionsConfig from 'common/types/toolbarOptionsConfig';

const { SHARE, UPLOAD, CREATE, DOWNLOAD, EDIT } = ToolbarOption;

export const driveToolbarOptions: ToolbarOptionsConfig = {
  [FolderItem.BEAT]: [],
  [FolderItem.LYRICS]: [EDIT, SHARE],
  [FolderItem.META]: [EDIT, SHARE, DOWNLOAD],
  [FolderItem.MASTER_FILES]: [UPLOAD, SHARE, DOWNLOAD],
  [FolderItem.STEM_FILES]: [UPLOAD, SHARE, DOWNLOAD],
  [FolderItem.MASTER_360RA]: [UPLOAD, SHARE, DOWNLOAD],
  [FolderItem.CONTRACTS]: [CREATE, UPLOAD, SHARE],
  [FolderItem.DOCUMENTS]: [CREATE, UPLOAD, SHARE, DOWNLOAD],
  [FolderItem.MEDIA]: [UPLOAD, SHARE, DOWNLOAD],
  [FolderItem.MIXES]: [SHARE, UPLOAD],
  [FolderItem.RECORDINGS]: [SHARE, UPLOAD],
  [FolderItem.IMPORTS]: [SHARE, UPLOAD],
};

export const sharedToolbarOptions: ToolbarOptionsConfig = {
  [FolderItem.BEAT]: [],
  [FolderItem.LYRICS]: [],
  [FolderItem.META]: [],
  [FolderItem.MASTER_FILES]: [UPLOAD, DOWNLOAD],
  [FolderItem.STEM_FILES]: [UPLOAD, DOWNLOAD],
  [FolderItem.MASTER_360RA]: [UPLOAD, DOWNLOAD],
  [FolderItem.CONTRACTS]: [UPLOAD],
  [FolderItem.DOCUMENTS]: [UPLOAD, DOWNLOAD],
  [FolderItem.MEDIA]: [UPLOAD, DOWNLOAD],
  [FolderItem.MIXES]: [UPLOAD],
  [FolderItem.RECORDINGS]: [UPLOAD],
  [FolderItem.IMPORTS]: [UPLOAD],
};

export const PanelHeadings: Record<FolderItem, string> = {
  [FolderItem.BEAT]: 'Beat',
  [FolderItem.CONTRACTS]: 'Contracts',
  [FolderItem.DOCUMENTS]: 'Contracts',
  [FolderItem.MASTER_FILES]: 'Master Files',
  [FolderItem.IMPORTS]: 'Imports',
  [FolderItem.LYRICS]: 'Lyrics Preview',
  [FolderItem.MEDIA]: 'Media',
  [FolderItem.META]: 'Metadata',
  [FolderItem.MIXES]: 'Mixes',
  [FolderItem.RECORDINGS]: 'Recordings',
  [FolderItem.STEM_FILES]: 'Stems',
  [FolderItem.MASTER_360RA]: '360 Reality Audio',
};
