import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import GET from 'common/services/httpServices/get';

const getLyrics: (id: string) => Promise<{ content: string }> = async (id) => {
  const res = await GET(`${CatalogsPaths.GetLyrics}${id}`);
  const json: { content: string } = await res.json();
  return json;
};

export default getLyrics;
