import { Middleware } from 'redux';
import { RootState } from 'redux/root-reducer';
import * as menu from 'redux/menu/menuActions';
import { modalOpen, ModalConfigActionTypes } from 'redux/modal/modalActions';
import { ContentType, Category } from 'common/api/models';
import { CollectionType } from 'common/models/Collection';
import isClickAction from 'redux/menu/utils/isClickAction';
import MenuOption from 'redux/menu/enums/menuOption';
import FolderMenuContext from 'redux/menu/types/folderMenuContext';
import MenuContext from 'redux/menu/types/menuContext';

const { ADD_TO_LP, ADD_TO_EP } = MenuOption;

const MenuToCollMap = {
  [ADD_TO_LP]: CollectionType.Lp,
  [ADD_TO_EP]: CollectionType.Ep,
} as const;

const getFolderAction = (
  context: FolderMenuContext,
  option: keyof typeof MenuToCollMap
) => {
  if (context.typeId === ContentType.MASTER_FOLDER && context.data) {
    return modalOpen({
      type: ModalConfigActionTypes.ADD_TO_COLLECTION,
      data: {
        props: {
          master: context.data,
          collectionType: MenuToCollMap[option],
        },
      },
    });
  }
};

const getNextAction = (context: MenuContext, option: keyof typeof MenuToCollMap) => {
  if (context.category === Category.Folder) {
    return getFolderAction(context, option);
  }
};

export const addToCollection: Middleware<{}, RootState> = (store) => (next) => (
  action
) => {
  if (isClickAction(action)) {
    const { option, context } = action.payload;

    if (option === ADD_TO_EP || option === ADD_TO_LP) {
      const action = getNextAction(context, option);

      if (action) {
        store.dispatch(action);
      }

      return next(menu.close());
    }
  }

  return next(action);
};
