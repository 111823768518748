import React, { FC, lazy, ReactElement, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import NotFoundPage from 'uikit/notFoundPage/NotFoundPage';
import { Routes } from 'navigation/enums/routes';
import Preloader from 'uikit/preloader/Preloader';
import useCatalogRoutesPermissions from 'common/hooks/useCatalogRoutesPermissions';

const LazyMastersPage = lazy(() => import('modules/masters/pages/MastersPage'));
const LazyBeatsPage = lazy(() => import('modules/beats/pages/BeatsPage'));
const LazyProjectsPage = lazy(() => import('modules/projects/pages/ProjectsPage'));
const LazySharing = lazy(() => import('modules/sharing/Sharing'));
const LazyApproveSplitSheetPage = lazy(() =>
  import('modules/masters/pages/ApproveSplitSheetPage')
);

const GuestRouter: FC = (): ReactElement => {
  useCatalogRoutesPermissions();

  return (
    <Switch>
      <Route path={[Routes.Masters]}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyMastersPage />
        </Suspense>
      </Route>
      <Route path={[Routes.Beats]}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyBeatsPage />
        </Suspense>
      </Route>
      <Route path={[Routes.Projects]}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyProjectsPage />
        </Suspense>
      </Route>
      <Route path={[Routes.Shared, Routes.SharedInvite]}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazySharing />
        </Suspense>
      </Route>
      <Route path={Routes.ContractSignLink}>
        <Suspense fallback={<Preloader fillViewport />}>
          <LazyApproveSplitSheetPage />
        </Suspense>
      </Route>
      <Route
        exact
        path={[Routes.Home, Routes.Login, Routes.ResetPassword, Routes.NewPassword]}
      >
        <Redirect to={Routes.Masters} />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default GuestRouter;
