import * as React from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import Typography from 'uikit/typography/Typography';
import { SnackBox } from 'uikit/notifications/Notifications';

const WithSpinner = ({ children, ...props }) => (
  <Box display="flex" alignItems="center" component="span" {...props}>
    <Box display="inline-flex" pr={1} component="span">
      <CircularProgress disableShrink size={24} />
    </Box>
    {children}
  </Box>
);

export const downloading = (
  <WithSpinner>
    <Typography component="span" variant="body2">
      Downloading file...
    </Typography>
  </WithSpinner>
);

export const DownloadingFile = (props: { name: string }) => (
  <WithSpinner>
    <Typography component="span" variant="body2">
      Downloading {props.name}...
    </Typography>
  </WithSpinner>
);

export const CreatingRole = () => (
  <WithSpinner py={1}>
    <Typography component="span" variant="body2">
      Creating role
    </Typography>
  </WithSpinner>
);

export const Pending: React.FC = ({ children }) => (
  <WithSpinner py={1}>
    <Typography component="span" variant="body2">
      {children}
    </Typography>
  </WithSpinner>
);

export const Saving = () => <Pending>Saving</Pending>;

export const createPendingToast = (message: React.ReactNode) => (
  <SnackBox>
    <Pending>{message}</Pending>
  </SnackBox>
);

export { Saving as SavingRole };

export const generic = `Oops, looks like something went wrong.\n Please try again, or contact Tully Support.`;
