import FileUploadState from 'redux/fileUpload/types/fileUploadState';
import { FileUploadActionTypes } from 'redux/fileUpload/fileUploadActions';
import { MAX_UPLOAD_FILES } from 'config/network';

const INIT_FILE_UPLOAD_STATE: FileUploadState = {
  status: FileUploadActionTypes.IDLE,
  started: null,
  finished: null,
  autostart: false,
  maxFiles: MAX_UPLOAD_FILES,
  freeSlots: MAX_UPLOAD_FILES,
  error: null,
  files: null,
  entries: null,
  typeId: null,
  catalogId: null,
  isSharing: false,
};

export default INIT_FILE_UPLOAD_STATE;
