import { SharePermissions } from 'common/models/sharing';

// TODO: remove this filter when EDIT & DELETE are supported
//       by Shared View module
const temporaryFilterPerms = (perms: SharePermissions[]) =>
  perms.filter(
    (perm) => perm !== SharePermissions.EDIT && perm !== SharePermissions.DELETE
  );

export default temporaryFilterPerms;
