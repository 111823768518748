import apiCatalogsService from 'api/catalogs/apiCatalogsService';
import apiCoreService from 'api/core/apiCoreService';
import { MasterAssetType, ProjectAssetType } from './models';

export default {
  async uploadMasterAsset(params: {
    catalogId: number;
    masterId: number;
    typeId: MasterAssetType;
    file: File;
    onProgress?(event: ProgressEvent): void;
  }) {
    const { catalogId, masterId, typeId, file, onProgress } = params;

    try {
      const { data } = await apiCoreService.addFile(
        {
          asset_type: 'MASTER_ASSET',
          catalog_id: catalogId,
          parent_id: masterId,
          file,
        },
        {
          onProgress(event) {
            if (onProgress) onProgress(event);
          },
        }
      );

      return await apiCatalogsService.createMasterAsset(
        {
          title: file.name,
          catalog: catalogId,
          type: typeId,
          file: data.id,
        },
        {
          catalogId,
          masterId,
        }
      );
    } catch (error) {
      throw new Error(error?.message);
    }
  },

  async uploadProjectAsset(params: {
    catalogId: number;
    projectId: number;
    typeId: ProjectAssetType;
    file: File;
    onProgress?(event: ProgressEvent): void;
  }) {
    const { catalogId, projectId, typeId, file, onProgress } = params;

    try {
      const { data } = await apiCoreService.addFile(
        {
          asset_type: 'PROJECT_ASSET',
          catalog_id: catalogId,
          parent_id: projectId,
          file,
        },
        {
          onProgress(event) {
            if (onProgress) onProgress(event);
          },
        }
      );

      return await apiCatalogsService.createProjectAsset(
        {
          title: file.name,
          catalog: catalogId,
          type: typeId,
          file: data.id,
        },
        {
          catalogId,
          projectId,
        }
      );
    } catch (error) {
      throw new Error(error?.message);
    }
  },
};
