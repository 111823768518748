import shareContent from 'common/components/Modals/shareDialog/utils/shareContent';
import { GuestPerms, EmailPerms, SystemRecordPerms } from 'config/sharingConfig';
import { Middleware } from 'redux';
import { ContentType, Category } from 'common/api/models';
import { ModalConfigActionTypes, modalOpen } from 'redux/modal/modalActions';
import * as menu from 'redux/menu/menuActions';
import { DriveUtils, isSystemRecord } from 'common/api/utils';
import { PanelHeadings } from 'config/panelConfig';
import isClickAction from 'redux/menu/utils/isClickAction';
import isDriveContractContext from 'redux/menu/utils/isDriveContractContext';
import isDriveAssetContext from 'redux/menu/utils/isDriveAssetContext';
import FolderItemMenuContext from 'redux/menu/types/folderItemMenuContext';
import DriveAssetContext from 'redux/menu/interfaces/driveAssetContext';
import DriveContractContext from 'redux/menu/interfaces/driveContractContext';
import FolderMenuContext from 'redux/menu/types/folderMenuContext';
import AlbumItemContext from 'redux/menu/types/albumItemContext';
import MenuContext from 'redux/menu/types/menuContext';
import MenuOption from 'redux/menu/enums/menuOption';

const getFolderItemAction = (context: FolderItemMenuContext) => {
  return modalOpen({
    type: ModalConfigActionTypes.SHARE,
    data: {
      itemName: PanelHeadings[context.itemTypeId],
      contentType: context.typeId,
      ids: context.data.reduce<number[]>((acc, item) => {
        if (DriveUtils.getTypeId(item) === context.typeId) {
          return acc.concat(item.id);
        }

        return acc;
      }, []),
      onShare: shareContent,
      perms: {
        guest: GuestPerms[context.typeId].subfolder,
        email: EmailPerms[context.typeId].subfolder,
      },
    },
  });
};

const getAssetAction = (context: DriveAssetContext) => {
  const [asset] = context.data;
  return modalOpen({
    type: ModalConfigActionTypes.SHARE,
    data: {
      itemName: asset.title,
      contentType: context.typeId,
      ids: [asset.id],
      onShare: shareContent,
      perms: {
        guest: GuestPerms[context.typeId].entity,
        email: EmailPerms[context.typeId].entity,
      },
    },
  });
};

const getContractAction = (context: DriveContractContext) => {
  const [contract] = context.data;
  return modalOpen({
    type: ModalConfigActionTypes.SHARE,
    data: {
      itemName: contract.title || 'Contract',
      contentType: ContentType.MASTER_CONTRACT,
      ids: [contract.id],
      onShare: shareContent,
      perms: {
        guest: isSystemRecord(contract)
          ? SystemRecordPerms
          : GuestPerms[ContentType.MASTER_CONTRACT].entity,
        email: isSystemRecord(contract)
          ? SystemRecordPerms
          : EmailPerms[ContentType.MASTER_CONTRACT].entity,
      },
    },
  });
};

const getFolderAction = (context: FolderMenuContext) => {
  if (context.typeId !== ContentType.BEATS_FOLDER) {
    return (
      context.data &&
      modalOpen({
        type: ModalConfigActionTypes.SHARE,
        data: {
          itemName: context.data.title,
          contentType: context.typeId,
          ids: [context.data?.id],
          onShare: shareContent,
          perms: {
            guest: GuestPerms[context.typeId].entity,
            email: EmailPerms[context.typeId].entity,
          },
        },
      })
    );
  }

  return modalOpen({
    type: ModalConfigActionTypes.SHARE,
    data: {
      itemName:
        context.childTypeId === ContentType.BEATS_FREE ? 'Tully Beats' : 'Imported',
      contentType: context.childTypeId,
      ids: context.data.reduce<number[]>((acc, item) => {
        if (item.type_id === context.childTypeId) {
          return acc.concat(item.id);
        }
        return acc;
      }, []),
      onShare: shareContent,
      perms: {
        guest: GuestPerms[context.childTypeId].subfolder,
        email: EmailPerms[context.childTypeId].subfolder,
      },
    },
  });
};

const getAlbumItemAction = (context: AlbumItemContext) => {
  if (context.typeId === ContentType.MASTER_FOLDER && context.data) {
    return modalOpen({
      type: ModalConfigActionTypes.SHARE,
      data: {
        itemName: context.data.title,
        contentType: context.typeId,
        ids: [context.data.id],
        onShare: shareContent,
        perms: {
          guest: GuestPerms[context.typeId].entity,
          email: EmailPerms[context.typeId].entity,
        },
      },
    });
  }
};

const getNextAction = (context: MenuContext) => {
  if (context.kind === 'SHARED') {
    return;
  }
  if (context.category === Category.Folder) {
    return getFolderAction(context);
  }

  if (context.category === Category.FolderItem) {
    return getFolderItemAction(context);
  }

  if (isDriveContractContext(context)) {
    return getContractAction(context);
  }

  if (isDriveAssetContext(context)) {
    return getAssetAction(context);
  }

  if (context.category === Category.AlbumItem) {
    return getAlbumItemAction(context);
  }
};

const clickShare: Middleware = (store) => (next) => (action) => {
  if (isClickAction(action)) {
    const { context, option } = action.payload;

    if (option === MenuOption.SHARE) {
      const action = getNextAction(context);

      if (action) {
        store.dispatch(action);
      }

      return next(menu.close());
    }
  }

  return next(action);
};

export default clickShare;
