import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { ReactComponent as PlayOldSvg } from 'assets/system/playOld.svg';
import { ReactComponent as PlaySvg } from 'assets/system/play.svg';
import { ReactComponent as PauseOldSvg } from 'assets/system/pauseOld.svg';
import { ReactComponent as PauseSvg } from 'assets/system/pause.svg';
import { ReactComponent as CloseSvg } from 'assets/system/close.svg';
import { ReactComponent as MoreSvg } from 'assets/system/options_dots.svg';
import { ReactComponent as ArrowDownSvg } from 'assets/system/arrow-down.svg';
import { ReactComponent as DistrokidSvg } from 'assets/system/distrokid.svg';
import { ReactComponent as CalendarSvg } from 'assets/system/calendar.svg';
import { ReactComponent as SelectorSvg } from 'assets/system/selector.svg';
import { ReactComponent as CoverUploaderSvg } from 'assets/system/coverUploader.svg';
import { ReactComponent as PrevSvg } from 'assets/system/prev.svg';
import { ReactComponent as NextSvg } from 'assets/system/next.svg';
import { ReactComponent as ArrowUpSvg } from 'assets/system/arrow-up.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/system/arrow-left.svg';
import { ReactComponent as ArrowRightSvg } from 'assets/system/arrow-right.svg';
import { ReactComponent as InfoSvg } from 'assets/system/info.svg';
import { ReactComponent as InfoWithCircleSvg } from 'assets/system/infoWithCircle.svg';
import { ReactComponent as DeleteOldSvg } from 'assets/system/deleteOld.svg';
import { ReactComponent as DeleteSvg } from 'assets/system/delete.svg';
import { ReactComponent as DownloadOldSvg } from 'assets/system/downloadOld.svg';
import { ReactComponent as DownloadSvg } from 'assets/system/download.svg';
import { ReactComponent as EditSvg } from 'assets/system/edit.svg';
import { ReactComponent as ShareOldSvg } from 'assets/system/shareOld.svg';
import { ReactComponent as ShareSvg } from 'assets/system/share.svg';
import { ReactComponent as UploadSvg } from 'assets/system/upload.svg';
import { ReactComponent as PlusSvg } from 'assets/system/plus.svg';
import { ReactComponent as LockSvg } from 'assets/system/lock.svg';
import { ReactComponent as CopySvg } from 'assets/system/copy.svg';
import { ReactComponent as RefreshSvg } from 'assets/system/refresh.svg';
import { ReactComponent as RemoveSvg } from 'assets/system/removeOld.svg';
import { ReactComponent as SearchSvg } from 'assets/system/search.svg';
import { ReactComponent as UserSvg } from 'assets/system/user.svg';
import { ReactComponent as UserGearSvg } from 'assets/system/usergear.svg';
import { ReactComponent as PreviewSvg } from 'assets/system/preview.svg';
import { ReactComponent as PreviewUndoSvg } from 'assets/system/preview-undo.svg';
import { ReactComponent as GearSvg } from 'assets/system/gear.svg';
import { ReactComponent as MenuSvg } from 'assets/system/menu.svg';
import { ReactComponent as TooltipSvg } from 'assets/system/support.svg';
import { ReactComponent as FilePreviewSvg } from 'assets/content-types-balls/preview.svg';
import { ReactComponent as VolumeSvg } from 'assets/system/volume.svg';
import { ReactComponent as Next10Svg } from 'assets/system/next10.svg';
import { ReactComponent as Prev10Svg } from 'assets/system/prev10.svg';
import { ReactComponent as OpenAIIconSvg } from 'assets/system/openAI.svg';
import { ReactComponent as DistributionSvg } from 'assets/navigation/releases.svg';
import { ReactComponent as ConfigurateSvg } from 'assets/navigation/settings.svg';
import { ReactComponent as IconGenerateLyricsSvg } from 'assets/system/icon_brainstorm.svg';
import { ReactComponent as IconRewriteLyricsSvg } from 'assets/system/icon_ghostwriter.svg';
import { ReactComponent as IconLyriqInfoSvg } from 'assets/system/icon_lyric_ai.svg';
import { ReactComponent as IconOpenAIWebSvg } from 'assets/system/lyricAIWeb.svg';

import PlayingGif from 'assets/system/playing.gif';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '& path:not(.no-current), & path[fill]:not(.no-current)': {
        fill: 'currentColor',
        transition: theme.transitions.create(['color']),
      },
    },
  });

const copySvgIcon = (
  component: React.ElementType,
  other?: any
): React.ComponentType<SvgIconProps<React.ElementType>> => {
  const Icon = Object.create(SvgIcon);

  Icon.defaultProps = {
    component,
    ...other,
  };

  return withStyles(styles)(Icon);
};

export const PlayOldIcon = copySvgIcon(PlayOldSvg, { viewBox: '4 4 24 24' });

export const PlayIcon = copySvgIcon(PlaySvg, { viewBox: '0 0 42 42' });

export const DistrokidIcon = copySvgIcon(DistrokidSvg, { viewBox: '0 0 30 30' });

export const PauseOldIcon = copySvgIcon(PauseOldSvg, { viewBox: '-6 -6 24 24' });

export const PauseIcon = copySvgIcon(PauseSvg, { viewBox: '0 0 42 42' });

export const CloseIcon = copySvgIcon(CloseSvg, { viewBox: '1 -0.5 24 24' });

export const SelectorIcon = copySvgIcon(SelectorSvg);

export const PlayingIcon = () => (
  <img
    src={PlayingGif}
    style={{ width: '1em', height: '1em', fontSize: '2.4rem' }}
    alt="playing"
  />
);

export const VolumeIcon = copySvgIcon(VolumeSvg);

export const CalendarIcon = copySvgIcon(CalendarSvg);

export const MenuIcon = copySvgIcon(MenuSvg);

export const MoreVertIcon = copySvgIcon(MoreSvg);

export const ExpandMoreIcon = copySvgIcon(ArrowDownSvg);

export const DistributionIcon = copySvgIcon(DistributionSvg);

export const ConfigurateIcon = copySvgIcon(ConfigurateSvg);

export const ExpandLessIcon = copySvgIcon(ArrowUpSvg);

export const ChevronRight = copySvgIcon(ArrowRightSvg);

export const CoverUploaderIcon = copySvgIcon(CoverUploaderSvg);

export const ChevronLeft = copySvgIcon(ArrowLeftSvg);

export const InfoIcon = copySvgIcon(InfoSvg);

export const InfoWithCircleIcon = copySvgIcon(InfoWithCircleSvg);

export const DeleteIconOld = copySvgIcon(DeleteOldSvg);

export const DeleteIcon = copySvgIcon(DeleteSvg, { viewBox: '0 0 20 20' });

export const DownloadIconOld = copySvgIcon(DownloadOldSvg);

export const DownloadIcon = copySvgIcon(DownloadSvg, { viewBox: '0 0 20 20' });

export const EditIcon = copySvgIcon(EditSvg);

export const ShareIconOld = copySvgIcon(ShareOldSvg);

export const ShareIcon = copySvgIcon(ShareSvg, { viewBox: '0 0 20 20' });

export const UploadIcon = copySvgIcon(UploadSvg);

export const PrevIcon = copySvgIcon(PrevSvg, { viewBox: '0 0 30 30' });

export const NextIcon = copySvgIcon(NextSvg, { viewBox: '0 0 30 30' });

export const PlusIcon = copySvgIcon(PlusSvg);

export const LockIcon = copySvgIcon(LockSvg);

export const CopyIcon = copySvgIcon(CopySvg);

export const RefreshIcon = copySvgIcon(RefreshSvg);

export const SearchIcon = copySvgIcon(SearchSvg);

export const UserIcon = copySvgIcon(UserSvg);

export const UserGearIcon = copySvgIcon(UserGearSvg);

export const RemoveIcon = copySvgIcon(RemoveSvg);

export const PreviewIcon = copySvgIcon(PreviewSvg);

export const Prev10Icon = copySvgIcon(Prev10Svg);

export const Next10Icon = copySvgIcon(Next10Svg);

export const PreviewUndoIcon = copySvgIcon(PreviewUndoSvg);

export const TooltipIcon = copySvgIcon(TooltipSvg, { viewBox: '1 0 24 24' });

export const GearIcon = copySvgIcon(GearSvg, { viewBox: '-4 -4 32 32' });

export { RemoveIcon as ClearIcon };

export const FilePreviewIcon = copySvgIcon(FilePreviewSvg, { viewBox: '0 0 32 32' });

export const OpenAIIcon = copySvgIcon(OpenAIIconSvg);

export const IconGenerateLyrics = copySvgIcon(IconGenerateLyricsSvg, {
  viewBox: '0 0 117 117',
});

export const IconRewriteLyrics = copySvgIcon(IconRewriteLyricsSvg, {
  viewBox: '0 0 127 110',
});

export const IconLyriqInfo = copySvgIcon(IconLyriqInfoSvg, {
  viewBox: '0 0 316 305',
});

export const IconOpenAIWeb = copySvgIcon(IconOpenAIWebSvg, {
  viewBox: '0 0 100.000000 111.000000',
});
