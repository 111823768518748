import { getEpTitle, Master } from 'common/models/Master';
import { CollectionType } from 'common/models/Collection';
import { useCatalogNamesQuery } from 'generated/graphql';
import useCreateAndAddMasterDialog from 'common/components/Modals/addToCollectionDialog/hooks/useCreateAndAddMasterDialog';

const useCreateWithMaster = (master: Master, collectionType: CollectionType) => {
  const openDialog = useCreateAndAddMasterDialog();
  const title = collectionType === CollectionType.Ep ? getEpTitle(master) : '';
  const catalogId = master.catalog_id;
  const artistName = useCatalogNamesQuery().data?.catalogs.find(
    (catalog) => catalog.id === catalogId
  )?.name;

  return () =>
    openDialog({
      props: {
        masterId: master.id,
        autofocus: 'title',
        initialValues: {
          collectionType,
          artist: artistName,
          catalogId,
          title,
        },
      },
    });
};

export default useCreateWithMaster;
