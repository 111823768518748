export enum Routes {
  ArtistSettings = '/artist-settings',
  Feed = '/feed',
  Home = '/',
  Shared = '/shared',
  SharedInvite = '/shared/invite',
  Login = '/login',
  Logout = '/logout',
  Legal = '/legal',
  InviteConfirmationPage = '/invite-confirmation/:slug',
  InviteDecline = '/invite-decline/:slug',
  SignUpPromo = '/amptrial/:promocode',
  ResetPassword = '/reset-password',
  NewPassword = '/reset-password/:slug',
  ContractSignLink = '/contract/sign-link/',
  Beats = '/beats',
  Projects = '/projects',
  Releases = '/releases',
  Management = '/management',
  AdvanceSearch = '/advance-search',
  Drops = '/drops',
  Settings = '/settings',
  SplitSheet = '/split-sheet',
  SplitSheetItem = '/split-sheet/:id',
  Support = '/support',
  ManagementUsers = '/management/users',
  ManagementUserDetails = '/management/users/:userId',
  ManagementRoles = '/management/roles',
  ManagementEditRole = '/management/roles/edit/:roleId',
  ManagementCopyRole = '/management/roles/copy/:roleId',
  ManagementNewRole = '/management/roles/new',
  NotFound = '/not-found',
  Masters = '/masters',
  WebViewError = '/web-view-error',
  WebViewFeed = '/web-view-feed',
  WebViewDistribution = '/web-view-distribution',
  WebViewReleases = '/web-view-releases',
}
