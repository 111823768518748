const SUCCESS_MESSAGES = {
  assetDeleted: 'was successfully deleted',
  masterFolderDeleted: 'master was successfully deleted',
  collectionDeleted: 'collection was successfully deleted',
  allFilesImported: 'All files have been successfully imported!',
  resetPassword: 'Your password has been successfully updated.',
  successResendInvitation: 'Invitation was successfully sent',
  userRolesUpdated: 'User updated',
};

export default SUCCESS_MESSAGES;
