import { Middleware } from 'redux';
import { RootState } from 'redux/root-reducer';
import isEndAction from 'redux/fileUpload/utils/isEndAction';
import { FileUploadActionTypes } from 'redux/fileUpload/fileUploadActions';
import { notify } from 'common/components/notifMessages/Notify';
import getSuccessMessage from 'redux/fileUpload/utils/getSuccessMessage';

const fileUploadEndNotify: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (isEndAction(action)) {
    const state = store.getState().fileUpload;
    if (state.status === FileUploadActionTypes.UPLOADING) {
      const isAllUploaded = action.payload.loaded === state.files.length;
      const variant = isAllUploaded ? 'success' : 'error';
      const message = isAllUploaded
        ? getSuccessMessage(action.payload.loaded)
        : `${action.payload.loaded} from ${state.files.length} was imported`;

      notify.enqueueSnackbar(message, { variant });
    }
  }

  return next(action);
};

export default fileUploadEndNotify;
