import * as React from 'react';
import clsx from 'clsx';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  createSvgIcon,
} from '@material-ui/core';

const RadioButtonUnchecked = createSvgIcon(
  <path d="M20 12C20 16.42 16.41 20 12 20C7.58 20 4 16.42 4 12C4 7.59 7.58 4 12 4C16.41 4 20 7.59 20 12Z" />,
  'RadioButtonUnchecked'
);

const RadioButtonChecked = createSvgIcon(
  <path
    className="no-current"
    fill="#fff"
    d="M19 12C19 15.87 15.86 19 12 19C8.14 19 5 15.87 5 12C5 8.14 8.14 5 12 5C15.86 5 19 8.14 19 12Z"
  />,
  'RadioButtonChecked'
);

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      '&$checked $layer': {
        transform: 'scale(0.5)',
        transition: theme.transitions.create('transform', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.shortest,
        }),
      },
    },
    layer: {
      left: 0,
      position: 'absolute',
      transform: 'scale(1)',
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.shortest,
      }),
    },
    checked: {},
  });

export type RadioButtonIconProps = WithStyles<typeof styles> & {
  checked?: boolean;
  fontSize?: 'small' | 'default';
};

function RadioButtonIcon(props: RadioButtonIconProps) {
  const { checked, classes, fontSize } = props;

  return (
    <div className={clsx(classes.root, { [classes.checked]: checked })}>
      <RadioButtonUnchecked fontSize={fontSize} />
      <RadioButtonChecked fontSize={fontSize} className={classes.layer} />
    </div>
  );
}

export default withStyles(styles, { name: 'RadioButtonIcon' })(RadioButtonIcon);
