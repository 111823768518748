import * as React from 'react';

export function ownerDocument(node) {
  return (node && node.ownerDocument) || document;
}

export default function useCaptureClickAway(
  nodeRef: React.RefObject<HTMLElement>,
  onClickAway: () => void
) {
  React.useEffect(() => {
    const doc: Document = ownerDocument(nodeRef.current);

    const listener = (event: MouseEvent) => {
      let insideDOM;

      if (!nodeRef.current || !event.target) {
        return;
      }
      // If not enough, can use https://github.com/DieterHolvoet/event-propagation-path/blob/master/propagationPath.js
      if (event.composedPath) {
        insideDOM = event.composedPath().indexOf(nodeRef.current) > -1;
      } else {
        // TODO v6 remove dead logic https://caniuse.com/#search=composedPath.
        const doc = ownerDocument(nodeRef.current);
        insideDOM =
          !doc.documentElement.contains(event.target) ||
          nodeRef.current.contains(event.target as Node);
      }

      if (!insideDOM) {
        event.preventDefault();
        event.stopPropagation();
        onClickAway();
      }
    };

    doc.addEventListener('click', listener, true);

    return () => {
      doc.removeEventListener('click', listener, true);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
