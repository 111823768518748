import { makeStyles } from '@material-ui/core';
import React from 'react';

type Props = {
  label: string;
  color?: string;
};

const useStyles = makeStyles(
  (theme) => ({
    label: {
      textTransform: 'capitalize',
      display: 'inline-block',
    },
    indicator: {
      marginRight: 8,
      width: 16,
      height: 16,
      borderRadius: 8,
      display: 'inline-block',
      verticalAlign: 'sub',
    },
  }),
  { name: 'StatusLabel' }
);

const StatusLabel: React.FC<Props> = ({ label, color = '#eeeeee' }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.indicator} style={{ backgroundColor: color }} />
      <p className={classes.label}>{label}</p>
    </>
  );
};

export default StatusLabel;
