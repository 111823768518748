import MasterCustomizeTypes from '../emun/masterCustomizeTypes';

const SUB_BUTTON_DESCRIPTIONS = {
  [MasterCustomizeTypes.realityAudio]: 'Add a subfolder for 360RA mp4 files',
  [MasterCustomizeTypes.stemFiles]: 'Add subfolder for all your session audio files',
  [MasterCustomizeTypes.contracts]: 'Import and create split sheet contractts',
  [MasterCustomizeTypes.metadata]: 'Add a subfolder for audio metadata',
  [MasterCustomizeTypes.media]: 'Add a subfolder for images and video',
};

export default SUB_BUTTON_DESCRIPTIONS;
