import { CSSProperties } from 'react';
import createMuiTypography, {
  Typography,
} from '@material-ui/core/styles/createTypography';
import { Palette } from '@material-ui/core/styles/createPalette';
import { VariantExt } from 'theme/module';

function createTypography(): Typography {
  const fontFamily = '"Sarabun", "Roboto", "Helvetica", "Arial", sans-serif';
  const fontWeightLight = 300;
  const fontWeightRegular = 400;
  const fontWeightMedium = 500;
  const fontWeightSemiBold = 600;
  const fontWeightBold = 700;

  const typography = createMuiTypography({} as Palette, {
    htmlFontSize: 10,
    fontSize: 14,
    fontFamily,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
  });

  // "Lite" version of original function used in material-ui.
  const buildVariant = (
    fontWeight: number,
    size: number,
    lineHeight: number
  ): CSSProperties => ({
    fontFamily,
    fontWeight,
    fontSize: typography.pxToRem(size),
    lineHeight,
  });

  const variants = {
    h1: buildVariant(fontWeightBold, 96, 1.25),
    h2: buildVariant(fontWeightBold, 48, 1.33333333333),
    h3: buildVariant(fontWeightBold, 36, 1.33333333333),
    h4: buildVariant(fontWeightBold, 24, 1.5),
    h5: buildVariant(fontWeightBold, 20, 1.2),
    h6: buildVariant(fontWeightBold, 18, 1.33333333333),
    subtitle1: buildVariant(fontWeightSemiBold, 16, 1.5),
    subtitle2: buildVariant(fontWeightSemiBold, 14, 1.42857142857),
    body1: buildVariant(fontWeightRegular, 18, 1.33333333333),
    body2: buildVariant(fontWeightRegular, 14, 1.42857142857),
    caption: buildVariant(fontWeightRegular, 12, 1.33333333333),
    // button variant is used by Mui components, but in design
    // there button1 and button2 variants
    // As button1 will be our main, please, keep them in sync.
    button: {
      ...buildVariant(fontWeightBold, 15, 1.06666666667),
      textTransform: 'none',
    },
    button1: {
      ...buildVariant(fontWeightBold, 18, 1.33333333333),
      textTransform: 'none',
    },
    button2: {
      ...buildVariant(fontWeightBold, 15, 1.06666666667),
      textTransform: 'none',
    },
    overline: buildVariant(fontWeightRegular, 12, 2.66),
  };

  const variantNames = Object.keys(variants) as VariantExt[];

  return Object.assign(typography, variants, {
    variantNames,
  });
}

export default createTypography();
