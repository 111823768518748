import React, { FC, ReactElement } from 'react';
import { Router } from 'react-router';
import { withErrorBoundary } from 'react-error-boundary';
import AppSwitch from 'modules/app/components/AppSwitch';
import PublicError from 'modules/app/components/PublicError';
import RootProviders from 'modules/app/components/RootProviders';
import appHistory from 'common/appHistory';
import UploadingTray from 'common/components/uploadingTray/UploadingTray';
import Modals from 'common/components/Modals/Modals';
import Dialog from 'common/components/dialog/Dialog';
import clearUserCache from 'common/utils/clearUserCache';

const App: FC = (): ReactElement => (
  <Router history={appHistory}>
    <RootProviders>
      <UploadingTray />
      <Modals />
      <Dialog />
      <AppSwitch />
    </RootProviders>
  </Router>
);

export default withErrorBoundary(App, {
  FallbackComponent: PublicError,
  onError: clearUserCache,
});
