import loadToken from 'common/services/tokenServices/loadToken';
import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import menuEnhancer from 'redux/menu/middleware/menuMiddleware';
import toolbarMiddleware from 'redux/toolbar/middleware/toolbarMiddleware';
import rootReducer from 'redux/root-reducer';
import rootSaga from 'redux/root-saga';
import fileUploadStart from 'redux/fileUpload/middleware/fileUploadStart';
import fileUploadEndReset from 'redux/fileUpload/middleware/fileUploadEndReset';
import fileUploadEndCloseDialog from 'redux/fileUpload/middleware/fileUploadEndCloseDialog';
import fileUploadEndNotify from 'redux/fileUpload/middleware/fileUploadEndNotify';
import loadFilters from 'redux/filters/services/loadFilters';
import composeEnhancers from 'redux/composeEnhancers';

const sagaMiddleware = createSagaMiddleware();

const token = loadToken();

const mainEnhancer = applyMiddleware(
  ReduxThunk,
  sagaMiddleware,
  toolbarMiddleware,
  fileUploadStart,
  fileUploadEndNotify,
  fileUploadEndReset,
  fileUploadEndCloseDialog
);

const store = createStore(
  rootReducer,
  {
    loginState: {
      loggedIn: !!token,
    },
    filters: loadFilters(),
  },
  composeEnhancers(mainEnhancer, menuEnhancer)
);

sagaMiddleware.run(rootSaga);

export default store;
