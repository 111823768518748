import constructPath from 'api/utils/constructPath';
import CatalogsPaths from 'api/catalogs/enums/catalogsPaths';
import PATCH from 'common/services/httpServices/patch';
import { AssetType } from 'generated/graphql';
import { Asset, GQLAssetTypeMap } from 'common/api/models';

interface UpdateAssetProps {
  assetId: number;
  title: string;
  type: AssetType;
}

const updateAsset: (props: UpdateAssetProps) => Promise<Asset> = async ({
  assetId,
  title,
  type,
}) => {
  const res = await PATCH(
    constructPath(CatalogsPaths.UpdateAsset, { assetId: assetId.toString() }),
    JSON.stringify({
      title,
      type: GQLAssetTypeMap[type],
    })
  );
  return res.json();
};

export default updateAsset;
