import SharePaths from 'api/share/enums/sharePaths';
import setAuthConfig from 'api/share/utils/setAuthConfig';
import constructPath from 'api/utils/constructPath';
import axios from 'common/api/axios';
import { AttachBody } from 'common/api/models';

const attachFile = async (slug: string, input: AttachBody) => {
  const endpoint = constructPath(SharePaths.AttachFile, { slug });
  const finalConfig = setAuthConfig();

  // returns URL to attached file;
  return axios.post<string>(endpoint, input, finalConfig);
};

export default attachFile;
