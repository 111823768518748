import { Button as MuiButton, ButtonProps, fade, capitalize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import omit from 'lodash/omit';
import clsx from 'clsx';

// NOTE: you can add new color that is supported by theme palette
type ColorExtended = ButtonProps['color'] | 'error' | 'warning';

export interface ButtonPropsExtended extends Omit<ButtonProps, 'color'> {
  color?: ColorExtended;
  dialogAction?: boolean;
  dialogActionBase?: boolean;
}

const isMuiColor = (color: ColorExtended): color is ButtonProps['color'] => {
  switch (color) {
    case 'inherit':
    case 'default':
    case 'primary':
    case 'secondary':
      return true;
    default:
      return false;
  }
};

const customClasses = [
  'containedError',
  'containedWarning',
  'textError',
  'textWarning',
  'outlinedError',
  'outlinedWarning',
  'dialogAction',
  'dialogActionBase',
] as const;

const getVariantClass = (variant = '', color = '') => `${variant}${capitalize(color)}`;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 70,
      minHeight: 50,
      padding: theme.spacing(1, 0),
    },
    text: {
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: fade(
          theme.palette.text.primary,
          theme.palette.action.hoverOpacity
        ),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          backgroundColor: 'transparent',
        },
      },
    },
    contained: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
    },
    outlined: {
      paddingTop: theme.spacing(1) - 1,
      paddingBottom: theme.spacing(1) - 1,
      paddingLeft: theme.spacing(2) - 1,
      paddingRight: theme.spacing(2) - 1,
    },
    iconSizeSmall: {
      '& > *:first-child': {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    iconSizeMedium: {
      '& > *:first-child': {
        /* marginTop: theme.typography.pxToRem(-2),
         * marginBottom: theme.typography.pxToRem(-2), */
        fontSize: theme.typography.pxToRem(16),
      },
    },

    startIcon: {
      display: 'flex',
      marginRight: 8,
      marginLeft: 0,
      '&$iconSizeSmall': {
        marginLeft: 0,
      },
    },
    endIcon: {
      display: 'flex',
      marginRight: 0,
      marginLeft: 8,
      '&$iconSizeSmall': {
        marginRight: 0,
      },
    },
    textSecondary: {
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: fade(
          theme.palette.text.primary,
          theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          backgroundColor: 'transparent',
        },
      },
    },
    textPrimary: {
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: fade(
          theme.palette.text.primary,
          theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          backgroundColor: 'transparent',
        },
      },
    },
    // 'MuiButton-label': {
    //   display: 'flex',
    //   justifyContent: 'space-between'
    // },
    // Custom classes
    containedError: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    containedWarning: {
      color: theme.palette.warning.contrastText,
      backgroundColor: theme.palette.warning.main,
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
      },
    },
    textError: {
      compose: '$text',
      color: theme.palette.error.main,
    },
    textWarning: {
      compose: '$text',
      color: theme.palette.warning.main,
    },
    outlinedError: {
      color: theme.palette.error.main,
      border: `1px solid ${fade(theme.palette.error.main, 0.5)}`,
      '&:hover': {
        border: `1px solid ${theme.palette.error.main}`,
        backgroundColor: fade(
          theme.palette.error.main,
          theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&$disabled': {
        border: `1px solid ${theme.palette.action.disabled}`,
      },
    },
    outlinedWarning: {
      color: theme.palette.warning.main,
      border: `1px solid ${fade(theme.palette.warning.main, 0.5)}`,
      '&:hover': {
        border: `1px solid ${theme.palette.warning.main}`,
        backgroundColor: fade(
          theme.palette.warning.main,
          theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&$disabled': {
        border: `1px solid ${theme.palette.action.disabled}`,
      },
    },
    disabled: {},
    dialogAction: {
      ...theme.mixins.dialog.actionsButton,
    },
    dialogActionBase: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
        flexBasis: '50%',
      },
    },
  }),
  { name: 'UikButton' }
);

const SubButton = React.forwardRef<HTMLButtonElement, ButtonPropsExtended>(
  function Button(props, ref) {
    const {
      color,
      variant = 'text',
      dialogAction = false,
      dialogActionBase = false,
      classes: classesProp,
      ...other
    } = props;
    const classes = useStyles({ classes: classesProp });
    const buttonClasses = React.useMemo(() => {
      const muiClasses = omit(classes, customClasses);
      const root = clsx(muiClasses.root, {
        [classes[getVariantClass(variant, color)]]: !isMuiColor(color),
        [classes.dialogAction]: dialogAction,
        [classes.dialogActionBase]: dialogActionBase,
      });

      return { ...muiClasses, root };
    }, [classes, color, variant, dialogAction, dialogActionBase]);
    return (
      <MuiButton
        classes={buttonClasses}
        variant={variant}
        color={isMuiColor(color) ? color : undefined}
        {...other}
        ref={ref}
      />
    );
  }
);

export default SubButton;
