import { BaseEditor, Transforms } from 'slate';
import TextSize from 'modules/projectLyricsEditor/constants/textSize';
import { ReactEditor } from 'slate-react';

const useAddPlainText = (editor: BaseEditor & ReactEditor, text: string) => {
  return () => {
    const lines = text.split('\n');
    lines.forEach((line) => {
      Transforms.insertNodes(
        editor,
        {
          type: 'paragraph',
          children: [
            {
              text: line.trim(),
              // @ts-ignore
              size: TextSize.MEDIUM,
              bold: false,
              italic: false,
              underline: false,
            },
          ],
        },
        { at: [editor.children.length] }
      );
    });
  };
};

export default useAddPlainText;
