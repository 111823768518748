import { useState, useCallback, useEffect } from 'react';

export type State = {
  files: File[];
  previews: string[];
};

export type InitState = Partial<State>;

export function useFilePreview(initState?: InitState) {
  const [state, setState] = useState<State>({
    files: [],
    previews: [],
    ...initState,
  });

  const previews = state.previews;

  const setFiles = useCallback(
    (files: File[]) =>
      setState({
        files,
        previews: files.map((file) => URL.createObjectURL(file)),
      }),
    [setState]
  );

  const resetFiles = useCallback(
    () =>
      setState({
        files: [],
        previews: [],
      }),
    [setState]
  );

  const setPreviews = useCallback(
    (previews: string[]) =>
      setState({
        files: [],
        previews,
      }),
    [setState]
  );

  useEffect(
    () => () => {
      for (const url of previews) {
        URL.revokeObjectURL(url);
      }
    },
    [previews]
  );

  return [state, setFiles, resetFiles, setPreviews] as const;
}
