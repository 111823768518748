export enum CommonActionTypes {
  CloseFileBrowserAndUnselectFile = 'CloseFileBrowserAndUnselectFile',
}

export type CloseFileBrowserAndUnselectFileAction = {
  type: CommonActionTypes.CloseFileBrowserAndUnselectFile;
};

export const closeFileBrowserAndUnselectFileAction = () => ({
  type: CommonActionTypes.CloseFileBrowserAndUnselectFile,
});

export type CommonActions = CloseFileBrowserAndUnselectFileAction;
