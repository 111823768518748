import { PartyRecord } from 'common/components/Modals/contractAgreementFormDialog/contractAgreementFormState';
import { UploadedContractPartyFieldsFragment } from 'generated/graphql';

const toPartyRecord = (
  party: UploadedContractPartyFieldsFragment
): PartyRecord | undefined => {
  if (party.metadata.__typename !== 'UploadedPartyMeta') return;

  return {
    id: party.id.toString(),
    name: party.name,
    email: party.contact,
    role: party.role_label,
    advance: party.metadata.advance || 0,
    royaltyLabel: party.metadata.royalty_label || '',
    royalty: party.metadata.royalty || 0,
    recoup: party.metadata.recoup || 0,
    recoupLabel: party.metadata.recoup_label || '',
    publishing: party.metadata.publishing || 0,
  };
};

export default toPartyRecord;
