import validateEmail from 'common/utils/validateEmail';
import moment from 'moment';
import {
  IApproverData,
  IReviewerData,
  ISplitSheetData,
} from 'modules/splitSheet/redux/model';
import { IRestSplitSheet } from 'modules/splitSheet/contracts/interfaces/IRestSplitSheet';
import { ContractWorkflowState as ContractState } from 'generated/graphql';
import { IRestApproverInput } from 'modules/splitSheet/contracts/interfaces/IRestApproverInput';
import { IRestReviewerInput } from 'modules/splitSheet/contracts/interfaces/IRestReviewerInput';
import { RestApproverInputSchema } from 'modules/splitSheet/contracts/schemas/RestApproverInputSchema';
import { RestReviewerInputSchema } from 'modules/splitSheet/contracts/schemas/RestReviewerInputSchema';

export const populateStateFromApiData = (apiData: IRestSplitSheet): ISplitSheetData => {
  const approvers: IApproverData[] = apiData.approvers.map((approver) => ({
    id: `${approver.id}`,
    name: approver.name,
    email: {
      address: approver.email,
      isValid: approver.email ? validateEmail(approver.email) : true,
    },
    role: approver.role_label,
    affiliation: approver.affiliation,
    ownership: approver.ownership,
    accepted: approver.accepted,
    canViewAll: approver.can_see_others,
    trackingId: approver.id,
  }));

  return {
    songTitle: apiData.title,
    aka: apiData.aka,
    date: moment(apiData.date),
    approvers,
    reviewers: apiData.reviewers.map((reviewer) => ({
      id: `${reviewer.id}`,
      name: reviewer.name,
      email: {
        address: reviewer.email,
        isValid: reviewer.email ? validateEmail(reviewer.email) : true,
      },
      canViewAll: reviewer.can_see_others,
      trackedApprover:
        approvers.find((approver) => approver.trackingId === reviewer.following_id) ||
        null,
    })),
    state: apiData.state,
    master_id: apiData.master_id,
    splitSheetId: apiData.id,
  };
};

export const areApproversValid = (approvers: IApproverData[]): boolean =>
  approvers
    .map(toApproverInput)
    .every((input) => RestApproverInputSchema.isValidSync(input));

export const areReviewersValid = (reviewers: IReviewerData[]) =>
  reviewers
    .map(toReviewerInput)
    .every((input) => RestReviewerInputSchema.isValidSync(input));

export const isEmailsUnique = (splitSheetData: ISplitSheetData) => {
  const notUniqueEmails: string[] = [];
  let allEmails: string[] = [];
  allEmails = allEmails.concat(
    splitSheetData.approvers.map((approver) => approver.email.address)
  );
  allEmails = allEmails.concat(
    splitSheetData.reviewers.map((reviewer) => reviewer.email.address)
  );
  allEmails = allEmails.filter((email) => email !== '');
  allEmails.forEach((emailEntry) => {
    let countEntries = 0;
    allEmails.forEach((email) => email === emailEntry && countEntries++);
    if (countEntries > 1 && !notUniqueEmails.includes(emailEntry))
      notUniqueEmails.push(emailEntry);
  });
  return [notUniqueEmails.length === 0, notUniqueEmails];
};

export const isSplitSheetEditable = (splitSheetState: ContractState) =>
  splitSheetState !== ContractState.ACCEPTED &&
  splitSheetState !== ContractState.DECLINED;

export const toApproverInput = (data: IApproverData): IRestApproverInput => ({
  name: data.name,
  email: data.email.address,
  role_label: data.role,
  ownership: data.ownership,
  can_see_others: data.canViewAll,
  affiliation: data.affiliation,
  tracking_id: data.trackingId,
});

export const toReviewerInput = (data: IReviewerData): IRestReviewerInput => ({
  name: data.name,
  email: data.email.address,
  can_see_others: data.canViewAll,
  following_id: data.trackedApprover?.trackingId,
});
