import apiCatalogsService from 'api/catalogs/apiCatalogsService';
import { saveAs } from 'file-saver';
import { ASSET_TYPE_IDS } from './models';
import { ContentType, AssetType } from './models';
import apolloClient from 'apollo/client';
import cache from 'apollo/cache/cache';
import {
  GetMasterQuery,
  GetMasterDocument,
  GetMasterQueryVariables,
  MasterFieldsFragmentDoc,
  MasterFieldsFragment,
  GetNewMasterQuery,
  GetNewMasterQueryVariables,
  GetNewMasterDocument,
  GetNewProjectQuery,
  GetNewProjectQueryVariables,
  GetNewProjectDocument,
  GetMasterTitleDocument,
  GetProjectTitleDocument,
  GetCollectionTitleDocument,
  GetAssetTitleDocument,
  DeleteCollectionDocument,
} from 'generated/graphql';
import { notify } from 'common/components/notifMessages/Notify';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import DELETE from 'common/services/httpServices/delete';
import GET from 'common/services/httpServices/get';
import PATCH from 'common/services/httpServices/patch';
import { LyricsFields } from 'gql/fragments/ProjectFields';

interface IDownloadAllItem {
  download_url?: string;
  title: string;
}

export default class AmpService {
  public static async getMetadata(endpoint: string) {
    const res = await GET(endpoint);
    return await res.blob();
  }

  public static download = (
    {
      contentType,
      id,
      url,
      name,
    }: {
      contentType: ContentType;
      id: number;
      url?: string;
      name: string;
    },
    callback?: () => void
  ) => {
    if (contentType === ContentType.PROJECT_LYRICS) {
      apiCatalogsService
        .getLyrics(`${id}`)
        .then((parsed) => {
          const blob = new Blob([parsed.content], { type: 'text/plain;charset=utf-8' });
          saveAs(blob, name);
          callback && callback();
        })
        .catch(() => {
          alert('Failed Download');
          callback && callback();
        });
      return;
    }
    if (contentType === ContentType.PROJECT_META) {
      AmpService.getMetadata(`catalogs/projects/metadata/${id}`)
        .then((blob) => {
          saveAs(blob, name);
          callback && callback();
        })
        .catch(() => {
          alert('Failed Download');
          callback && callback();
        });
      return;
    }
    if (contentType === ContentType.MASTER_META) {
      AmpService.getMetadata(`catalogs/masters/metadata/${id}`)
        .then((blob) => {
          saveAs(blob, name);
          callback && callback();
        })
        .catch(() => {
          alert('Failed Download');
          callback && callback();
        });
      return;
    }
    if (url) {
      const xhr = new window.XMLHttpRequest();
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          saveAs(xhr.response, name);
        } else {
          alert('Failed Download');
        }
        callback && callback();
      };
      xhr.send();
    }
  };
  public static downloadAll = (
    {
      items,
      zipName,
    }: {
      items: IDownloadAllItem[];
      zipName: string;
    },
    callback?: () => void
  ) => {
    const zip = new JSZip();
    let count = 0;
    const downloadUrlsBool = [] as boolean[];

    items.forEach((item) => {
      downloadUrlsBool.push(!!item.download_url);
      JSZipUtils.getBinaryContent(item.download_url, (_, data) => {
        const name = item.title.substr(0, item.title.lastIndexOf('.'));
        const ext = item.title.substr(item.title.lastIndexOf('.'));
        const fileName = `${name}(${count})${ext}`;

        if (!!item.download_url) zip.file(fileName, data, { binary: true });

        count++;
        if (count === items.length && !!item.download_url) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, zipName);
          });
        }
      });
    });
    callback && callback();

    if (downloadUrlsBool.every((val) => val === false)) {
      notify.enqueueSnackbar('File is still processing', {
        variant: 'error',
      });
    }
  };

  public static async deleteWithEvict(
    opeeration: () => Promise<boolean>,
    subjectToEvict: any
  ) {
    const success = await opeeration();
    if (success && subjectToEvict) {
      cache.evict({
        id: cache.identify(subjectToEvict),
      });
    }

    return success;
  }

  public static getEntityURL(contentType: number, id: number | string) {
    switch (contentType) {
      case ContentType.MASTER_FOLDER: {
        return `catalogs/masters/${id}`;
      }
      case ContentType.PROJECT_FOLDER: {
        return `catalogs/projects/${id}`;
      }
      case ContentType.PROJECT_LYRICS: {
        return `catalogs/lyrics/${id}`;
      }
      case ContentType.MASTER_CONTRACT: {
        return `contracts/${id}/`;
      }
    }

    if (ASSET_TYPE_IDS.includes(contentType)) {
      return `catalogs/assets/${id}`;
    }

    throw new Error(`Delete for ${contentType} not implemented`);
  }

  public static async deleteDriveItem(
    contentType: number,
    id: number,
    subjectToEvict?: any
  ): Promise<boolean> {
    if (contentType === ContentType.COLLECTION) {
      return AmpService.deleteWithEvict(async () => {
        try {
          await apolloClient.mutate({
            mutation: DeleteCollectionDocument,
            variables: { id },
          });

          return true;
        } catch (error) {
          notify.enqueueSnackbar(`${error}`, { variant: 'error' });
          return false;
        }
      }, subjectToEvict);
    }

    const endpoint = AmpService.getEntityURL(contentType, id);

    return AmpService.deleteWithEvict(() => DELETE(endpoint), subjectToEvict);
  }

  public static getTitleQueryDoc(typeId: ContentType) {
    if (typeId === ContentType.MASTER_FOLDER) {
      return GetMasterTitleDocument;
    }

    if (typeId === ContentType.PROJECT_FOLDER) {
      return GetProjectTitleDocument;
    }

    if (typeId === ContentType.COLLECTION) {
      return GetCollectionTitleDocument;
    }

    if (ASSET_TYPE_IDS.includes(typeId as AssetType)) {
      return GetAssetTitleDocument;
    }

    throw new Error(`Can't update title for type ${typeId}`);
  }

  public static async updateName(input: {
    id: number;
    title: string;
    typeId: ContentType;
  }) {
    const { id, title, typeId } = input;

    const getEndpoint = () => {
      if (typeId === ContentType.MASTER_FOLDER) {
        return `catalogs/masters/${id}`;
      }

      if (typeId === ContentType.PROJECT_FOLDER) {
        return `catalogs/projects/${id}`;
      }

      if (ASSET_TYPE_IDS.includes(typeId as AssetType)) {
        return `catalogs/assets/${id}`;
      }

      throw new Error(`Can't find "update endpoit" for type ${typeId}`);
    };

    const endpoint = getEndpoint();
    const titleQueryDoc = AmpService.getTitleQueryDoc(typeId);

    await PATCH(
      endpoint,
      JSON.stringify({
        title,
        type: typeId === ContentType.PROJECT_FOLDER ? 0 : typeId,
      })
    );

    return apolloClient.query({
      query: titleQueryDoc,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  }

  public static async queryMaster(variables: GetMasterQueryVariables) {
    return apolloClient.query<GetMasterQuery, GetMasterQueryVariables>({
      query: GetMasterDocument,
      variables,
      fetchPolicy: 'network-only',
    });
  }

  public static async queryNewMaster(variables: GetNewMasterQueryVariables) {
    return apolloClient.query<GetNewMasterQuery, GetNewMasterQueryVariables>({
      query: GetNewMasterDocument,
      variables,
      fetchPolicy: 'network-only',
    });
  }

  public static async queryMasterById(masterId: number) {
    const master = cache.readFragment<MasterFieldsFragment>({
      id: cache.identify({ __typename: 'AMPMaster', id: masterId }),
      fragment: MasterFieldsFragmentDoc,
      fragmentName: 'MasterFields',
    });

    if (!master) {
      throw new Error(`No master with id: ${masterId}`);
    }

    return AmpService.queryMaster({
      catalogId: master.catalog_id,
      masterId: master.id,
    });
  }

  public static async queryNewProject(variables: GetNewProjectQueryVariables) {
    return apolloClient.query<GetNewProjectQuery, GetNewProjectQueryVariables>({
      query: GetNewProjectDocument,
      variables,
      fetchPolicy: 'network-only',
    });
  }

  public static updateProjectLyrics(lyricsId: number, lyrics: string) {
    const id = cache.identify({
      __typename: 'AMPLyrics',
      id: lyricsId,
    });

    cache.modify({
      id: id,
      fields: {
        id(cashedId) {
          return lyricsId;
        },
        content(cashedContent) {
          return lyrics;
        },
      },
    });
  }

  public static addNewLyricsToProject(
    projectId: number,
    lyricsId: number,
    lyrics: string,
    createdAt: string
  ) {
    const projectApolloId = cache.identify({
      __typename: 'AMPProject',
      id: projectId,
    });

    cache.modify({
      id: projectApolloId,
      fields: {
        lyrics(cashedLyrics = [], { readField }) {
          const newLyricsFragment = cache.writeFragment({
            data: {
              __typename: 'AMPLyrics',
              id: lyricsId,
              content: lyrics,
              created_at: createdAt,
            },
            fragment: LyricsFields,
          });
          return [...cashedLyrics, newLyricsFragment];
        },
      },
    });
  }
  public static areCatalogMastersLoaded(catalogId: number) {
    const catalogIdentifier = cache.identify({
      __typename: 'Catalog',
      id: catalogId,
    });
    const cacheData = cache.extract();
    return (
      catalogIdentifier &&
      cacheData[catalogIdentifier] &&
      cacheData[catalogIdentifier]?.masters
    );
  }
}
