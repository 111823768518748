import SharingItem from 'redux/selectedFile/interfaces/sharingItem';
import { notify } from 'common/components/notifMessages/Notify';
import getSubfolderName from 'common/utils/getMasterSubfolderName';
import getFileItems from 'common/utils/getFileItems';
import downloadFiles from 'common/services/downloadServices/downloadFiles';
import { ContentType } from 'common/api/models';
import getMasterFilesFileItems from 'common/utils/getMasterFilesFileItems';
import { ISharedAsset } from '../api/models';

const downloadSubfolder = async (context: SharingItem) => {
  const archiveName = `${context.parentTitle} (${getSubfolderName(context.typeId)})`;
  const toastId = notify.pending(`Downloading ${archiveName}...`);

  function getFiles() {
    switch (context.typeId) {
      case ContentType.MASTER_FILE:
        return getMasterFilesFileItems(context.items as ISharedAsset[]);
      default:
        return getFileItems(context.items as ISharedAsset[]);
    }
  }

  await downloadFiles(getFiles(), archiveName);

  notify.closeSnackbar(toastId);
};

export default downloadSubfolder;
