import { DialogContent, makeStyles, Theme } from '@material-ui/core';
import DialogHeader from 'uikit/dialogHeader/DialogHeader';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { ContractWorkflowState as ContractState } from 'generated/graphql';
import StatusLabel from 'uikit/statusLabel/StatusLabel';
import Typography from 'uikit/typography/Typography';
import { IRestSplitSheet } from 'modules/splitSheet/contracts/interfaces/IRestSplitSheet';

function getTitle(state: ContractState): string {
  switch (state) {
    case ContractState.DRAFT:
      return 'Draft';
    case ContractState.REVIEW:
      return 'Review';
    case ContractState.ACCEPTED:
      return 'Approved';
    case ContractState.DECLINED:
      return 'Declined';
    default:
      return 'Unknown';
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  contentWidth: {
    width: 661,
  },
  content: {
    paddingBottom: theme.spacing(5),
  },
  dataRow: {
    display: 'flex',
  },
  basicDataEntry: {
    paddingRight: 8,
    overflowWrap: 'break-word',
  },
  tableRow: {
    composes: '$dataRow',
    marginBottom: -1,
    padding: theme.spacing(1, 0),
    borderTop: '1px solid #eeeeee',
    borderBottom: '1px solid #eeeeee',
  },
  approverIndicator: {
    width: 15,
    height: 15,
    marginRight: 5,
    borderRadius: 7,
  },
  indicatorApproved: {
    composes: '$approverIndicator',
    backgroundColor: theme.palette.primary.main,
  },
  indicatorReview: {
    composes: '$approverIndicator',
    backgroundColor: '#ff9e0d',
  },
}));

export type ModalSplitSheetProps = {
  onClose: () => void;
  summary: Pick<IRestSplitSheet, 'title' | 'aka' | 'date' | 'state' | 'approvers'>;
};

const ModalSplitSheet: React.FC<ModalSplitSheetProps> = ({ onClose, summary }) => {
  const classes = useStyles();

  return (
    <>
      <DialogHeader
        onClose={onClose}
        className={classes.contentWidth}
        title={`${summary.title} Split Sheet`}
      />
      <DialogContent className={clsx([classes.content, classes.contentWidth])}>
        <div className={classes.dataRow}>
          <div className={classes.basicDataEntry} style={{ width: '30%' }}>
            <Typography variant={'subtitle2'}>Song Title</Typography>
            <Typography variant={'body2'}>{summary.title}</Typography>
          </div>
          <div className={classes.basicDataEntry} style={{ width: '30%' }}>
            <Typography variant={'subtitle2'}>AKA</Typography>
            <Typography variant={'body2'}>{summary.aka}</Typography>
          </div>
          <div className={classes.basicDataEntry} style={{ width: '20%' }}>
            <Typography variant={'subtitle2'}>Date</Typography>
            <Typography variant={'body2'}>
              {moment(summary.date).format('MM/DD/YYYY')}
            </Typography>
          </div>
          <div style={{ width: '20%' }}>
            <Typography variant={'subtitle2'}>Status</Typography>
            <StatusLabel
              label={getTitle(summary.state)}
              color={
                (summary.state === ContractState.DRAFT && '#eeeeee') ||
                (summary.state === ContractState.REVIEW && '#ff9e0d') ||
                (summary.state === ContractState.ACCEPTED && '#15d291') ||
                (summary.state === ContractState.DECLINED && '#f11f0c') ||
                undefined
              }
            />
          </div>
        </div>
        <Typography variant={'subtitle1'} style={{ marginTop: 20, marginBottom: 20 }}>
          Approvers and Splits
        </Typography>
        <div className={classes.dataRow}>
          <Typography variant={'subtitle2'} style={{ width: '26%' }}>
            Name
          </Typography>
          <Typography variant={'subtitle2'} style={{ width: '26%' }}>
            Role
          </Typography>
          <Typography variant={'subtitle2'} style={{ width: '14%' }}>
            Affiliation
          </Typography>
          <Typography variant={'subtitle2'} style={{ width: '16%' }}>
            Ownership, %
          </Typography>
          {summary.state !== ContractState.DRAFT && (
            <Typography variant={'subtitle2'} style={{ width: '18%' }}>
              Status
            </Typography>
          )}
        </div>
        {summary.approvers.map((approver, index) => (
          <div key={index} className={classes.tableRow}>
            <Typography variant={'body2'} style={{ width: '26%' }}>
              {approver.name}
            </Typography>
            <Typography variant={'body2'} style={{ width: '26%' }}>
              {approver.role_label}
            </Typography>
            <Typography variant={'body2'} style={{ width: '14%' }}>
              {approver.affiliation}
            </Typography>
            <Typography variant={'body2'} style={{ width: '16%' }}>
              {approver.ownership}
            </Typography>
            {summary.state !== ContractState.DRAFT && (
              <div style={{ width: '18%', display: 'flex', alignItems: 'center' }}>
                {approver.accepted ? (
                  <>
                    <div className={classes.indicatorApproved} />
                    Approved
                  </>
                ) : (
                  <>
                    <div className={classes.indicatorReview} />
                    Review
                  </>
                )}
              </div>
            )}
          </div>
        ))}
      </DialogContent>
    </>
  );
};

export default ModalSplitSheet;
