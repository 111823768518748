import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root-reducer';

export { default as useCaptureClickAway } from './useCaptureClickAway';
export { default as useFilePicker } from './useFilePicker';
export { useFilePreview } from './useFilePreview';

export function useEventCallback<T extends (...args: any[]) => any>(fn: T): T {
  const ref: any = React.useRef(fn);

  // we copy a ref to the callback scoped to the current state/props on each render
  React.useEffect(() => {
    ref.current = fn;
  });

  return React.useCallback((...args: any[]) => ref.current.apply(void 0, args), []) as T;
}

export function useSelectedFile() {
  return useSelector((state: RootState) => state.selectedFile);
}
