import { useDispatch } from 'react-redux';
import useAddToCollService from 'common/hooks/useAddToCollService/useAddToCollService';
import { ValidatedValues as AddToFormValues } from 'common/components/Modals/addToCollectionDialog/types/validatedValues';
import * as Modal from 'redux/modal/modalActions';

const useAddToCollSubmit = () => {
  const dispatch = useDispatch();
  const addToColl = useAddToCollService();
  return async (values: AddToFormValues) => {
    await addToColl(values);
    dispatch(Modal.modalClose());
  };
};

export default useAddToCollSubmit;
