import { useSnackbar } from 'notistack';
import { useAttachAlbumArtworkMutation, useCreateAlbumMutation } from 'generated/graphql';
import { useCallback } from 'react';
import { ValidatedValues as CreateFormValues } from 'common/components/Modals/createAlbumDialog/CreateAlbumDialog';
import createAlbumOptions from 'common/components/Modals/createAlbumDialog/utils/createAlbumOptions';
import { CollectionType } from 'common/models/Collection';

const useCreateCollService = () => {
  const notify = useSnackbar();
  const [createCollection] = useCreateAlbumMutation();
  const [attachArtwork] = useAttachAlbumArtworkMutation();

  return useCallback(
    async (values: CreateFormValues) => {
      try {
        const { data } = await createCollection(createAlbumOptions(values));

        if (values.artworkFileName && data?.createCollection?.id) {
          await attachArtwork({
            variables: {
              id: data.createCollection.id,
              fileName: values.artworkFileName,
              attach: true,
            },
          });
        }

        const title = values.collectionType === CollectionType.Lp ? 'album' : 'EP';
        notify.enqueueSnackbar(`New ${title} successfully created`, {
          variant: 'success',
        });

        return data?.createCollection;
      } catch (error) {
        notify.enqueueSnackbar(`${error}`, {
          variant: 'error',
        });
      }
    },
    [createCollection, notify, attachArtwork]
  );
};

export default useCreateCollService;
