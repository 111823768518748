import React, { FC } from 'react';
import CustomizeMasterContent from './components/CustomizeMasterContent';
import { CustomizeMaster } from '../ModalMasterUpload/reducer';

type CustomizeMasterDialogProps = {
  config: CustomizeMaster;
};

const CustomizeMasterDialog: FC<CustomizeMasterDialogProps> = (props) => {
  return (
    <CustomizeMasterContent
      title="Customize your Master contents"
      listTitle="Choose the subfolders you want to add to your Master.
      Your config will be saved as your system default"
      submitLabel="Customize"
      isEdit={true}
      {...props}
    />
  );
};

export default CustomizeMasterDialog;
