import FileUploadState from 'redux/fileUpload/types/fileUploadState';
import MasterAssetState from 'redux/fileUpload/interfaces/masterAssetState';
import { FileUploadActionTypes } from 'redux/fileUpload/fileUploadActions';
import { MasterAssetIds, MasterAssetType } from 'common/api/models';

const isMasterAssetState = (state: FileUploadState): state is MasterAssetState =>
  state.status !== FileUploadActionTypes.IDLE &&
  MasterAssetIds.includes(state.typeId as MasterAssetType);

export default isMasterAssetState;
