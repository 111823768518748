import maxPermissions from 'redux/permissions/utils/maxPermissions';
import CatalogPerms from 'redux/permissions/interfaces/catalogPerms';

const ownerCatalogPerms = (catalogId?: number): CatalogPerms[] => {
  if (!catalogId) return [];

  return [
    {
      catalogId,
      permissions: maxPermissions(),
    },
  ];
};

export default ownerCatalogPerms;
