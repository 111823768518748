import { useEffect } from 'react';
import promptBeforeUnload from './utils/promptBeforeUnload';
import * as Modal from 'redux/modal/modalActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function useConfirmExit(
  enabled = false,
  options?: Modal.ConfirmDialogConfigAction['data']['props']
) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let blocked = false;
    if (enabled) {
      blocked = true;
      window.addEventListener('beforeunload', promptBeforeUnload);
    }

    const unblock = history.block((location) => {
      if (enabled) {
        dispatch(
          Modal.modalOpen({
            type: Modal.ModalConfigActionTypes.CONFIRM,
            data: {
              props: {
                body: 'You have unsaved changes. If you proceed all changes will be lost',
                submitLabel: 'Continue',
                submitColor: 'error',
                ...options,
                onConfirm: () => {
                  unblock();
                  history.push(location.pathname);
                  options?.onConfirm && options.onConfirm();
                  dispatch(Modal.modalClose());
                },
              },
            },
          })
        );
        // Return false from a transition hook to cancel the transition.
        // https://github.com/ReactTraining/history/blob/6104a6a2e40ae17a47a297621afff9a6cb184bfc/modules/createTransitionManager.js#L42
        return false;
      }
    });

    return () => {
      if (blocked) {
        window.removeEventListener('beforeunload', promptBeforeUnload);
      }

      unblock();
    };
  }, [dispatch, history, enabled, options]);
}
