import HeaderMenuItem from 'common/components/headerMenu/interfaces/headerMenuItem';
import HeaderMenuActionTypes from 'common/components/headerMenu/enums/HeaderMenuActionTypes';

const HEADER_MENU_ITEMS: HeaderMenuItem[] = [
  {
    title: 'Account',
    actionType: HeaderMenuActionTypes.ArtistProfile,
  },
  {
    title: 'Users',
    actionType: HeaderMenuActionTypes.Users,
  },
  {
    title: 'Roles',
    actionType: HeaderMenuActionTypes.Roles,
  },
  {
    title: 'Log Out',
    actionType: HeaderMenuActionTypes.LogOut,
    isSeparated: true,
  },
];

export default HEADER_MENU_ITEMS;
